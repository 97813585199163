/**
 * @description 2023年 母亲节活动祝福墙（弹幕墙）
 * @returns route: /front/activities/barrage-wall
 * @description 因在安卓真机上无法自动播放音乐必须手动交互一次，故采用组件的方式使用
*/

import React, { useState, useEffect, createRef, useRef } from 'react';
import { Toast, TextArea, Button } from 'antd-mobile';
import { Header } from 'components';
import BulletScreen, { StyledBullet } from 'library/rc-bullets';
import Library from 'library';
import { getRandomTheme, getTextSize } from './config';
import Service from '../service';
import './index.scss';

let currScreen = null;
let mockingInter = 0;
let _catchBarrageId = '';
let myblessingIdRef = undefined;
export default (props) => {
    
    const [bullet, setBullet] = useState('');
    const [catchBarrage, setCatchBarrage] = useState()
    const [audioVisible, setAudioVisible] = useState(false)
    const audioRef = createRef()

    useEffect(()=>{
        if(!currScreen) {
            currScreen = new BulletScreen('.screen');
        }
        loadData()
        onControlAudio()
        return ()=> {
            mockingInter && clearInterval(mockingInter);
            currScreen = null;
            _catchBarrageId = '';
            myblessingIdRef = undefined;
            console.log('卸载组件')
        }
    },[])

    const loadData = async ()=> {
        const res = await Service.getBlessingsList({})
        if(res) {
            const result = res.data || []
            myblessingIdRef = result.find(item => item.status == 1)?.blessingId
            let index = 0
            mockingInter = setInterval(() => {
                if(index < result.length) {
                    const randomTxt = result[index]
                    index++;
                    handleSend(randomTxt);
                }else {
                    clearInterval(mockingInter);
                }
                
            }, 1500);
        }
    }

    const onMsgClick = (e) => {
        console.log(e)
        setCatchBarrage(e.id)
        _catchBarrageId = e.id;
        if(e.id) {
           const bulletDev = document.getElementById(e.id)
           bulletDev.style.zIndex = 4
           
           const spanDom = bulletDev.lastChild;
           spanDom.style.transform = 'scale(1.2)'
           let imgDom = spanDom
           while(imgDom && imgDom.children?.length > 0 && imgDom.nodeName !== 'IMG') {
                imgDom = imgDom.lastChild
           }
           if(imgDom.nodeName === 'IMG') {
                imgDom.style.display = 'block'
           }
           
        }
       
    }

    //点击蒙层恢复元素样式
    const resetMsgClick = (e)=> {
        
        let bulletDev = document.getElementById(catchBarrage || _catchBarrageId)
        if(!bulletDev) return;
        bulletDev.style.zIndex = ''
        
        let spanDom = bulletDev.lastChild;
        spanDom.style.transform = ''
        let imgDom = spanDom
        while(imgDom && imgDom.children?.length > 0 && imgDom.nodeName !== 'IMG') {
            imgDom = imgDom.lastChild
        }
        
        if(imgDom.nodeName === 'IMG' && imgDom.className === 'nozan') {
            imgDom.style.display = 'none'
        }
        if(!catchBarrage) {
            bulletDev.style.animationPlayState = 'running';
        }
        setCatchBarrage('')
        _catchBarrageId = ''
    }

    const onZanChange = (msg)=> {
        //此处方法指向有疑问？？catchBarrage变成了undefined
        resetMsgClick()
        myblessingIdRef = msg?.blessingId
        Service.postThumbsUp({blessingId: msg?.blessingId},false)
    }

    const handleInput = (value) => {
        console.log(value);
    
        setBullet(value);
    };

    const handleSend = (mssg = {}, opts = {}) => {
        if (bullet || mssg) {
            console.log('start send');
            const currAnimteFun = 'linear';
            const theme = getRandomTheme();
            const size = getTextSize()

            let newOpts = Object.assign(
              {
                onStart: ()=>{},
                onEnd: ()=>{},
                onClick: onMsgClick,
                loopCount: 'infinite',
                animateTimeFun: currAnimteFun,
                duration: 12 //动画时常
              },
              opts
            );
            console.log({ opts });
            // <StyledBullet head={''} 
            //     style={buStyle}
            //     msg={<BulletItem  bullet={bullet} mssg={mssg} size={size}/>}
            // />
            const buStyle = {...theme,fontSize: size, border:'none'}
            currScreen.push({
                    head: '',
                    style: buStyle,
                    msg: <BulletItem  
                            bullet={bullet} 
                            mssg={mssg}  
                            size={size} 
                            onZanChange={onZanChange}
                        />
                },
              newOpts
            );
            if (Object.keys(mssg).length === 0) {
              setBullet('');
            }
          }
    }

    // 音乐方法控制
    const onControlAudio = ()=> {
        setAudioVisible(!audioVisible)
        if(!audioVisible) {
            audioRef.current.play();
        }else {
            audioRef.current.pause();
        }
    }

    return (
        <div className='barrage-ball'>
            <Header midText={'祝福墙'} bgColor={'transparent'} onBack={props.onBack}/>
            <div className='barrage-ball__body'>
                { 
                    catchBarrage && 
                    <div className='draw-mask' 
                        onClick={resetMsgClick}
                    ></div> 
                }
                <section className="screen"></section>
                <audio src={require('images/mothersDay/mothers-day.mp3')} loop ref={audioRef}/>
                <div className={`loop-audio${audioVisible?' rotate':''}`} onClick={onControlAudio}>
                    <img src={require('images/mothersDay/audio.png')} alt="" />
                </div>
                <div className='bottom'>
                    <TextArea
                        autoSize={{ minRows: 1, maxRows: 5 }}
                        rows={1}
                        value={bullet}
                        placeholder="请输入内容"
                        onChange={handleInput}
                    />
                    <Button color="success" onClick={handleSend.bind(this, {}, {})}> 发送 </Button>
                </div>
            </div>
        </div>
    )
}

const BulletItem = (props) => {
    const {
        bullet,
        mssg = {},
        size,
        onZanChange
    } = props

    const [giveLove, setGiveLove] = useState(false)

    const onZanClick = (e)=> {
        if(e) { e.stopPropagation() }
        console.log('点赞')
        if(myblessingIdRef) {
            Toast.show('点赞次数已用完！')
        }else {
            setGiveLove(true)
            onZanChange && onZanChange(mssg)
        }
        
    }

    const onToastShow = ()=> {
        Toast.show('已点赞！')
    }

    return (
        <div className='bullet-item'>
            <div>{bullet || mssg.blessingWords}</div>  
            <span>
            {
                (mssg.status == 1 || giveLove) ?
                <img className='zan' src={require('images/mothersDay/ballagewall-zan.png')} alt="" style={{ height: size}} onClick={onToastShow}/>
                :
                <img className='nozan' src={require('images/mothersDay/ballagewall-nozan.png')} alt="" style={{ height: size, display: 'none'}} onClick={onZanClick}/>
            }
            </span>
        </div>
    )
}