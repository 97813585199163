import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Services from 'services';
import Scroll from "./components/scroll";
import styles from "./modal.module.scss";



export const Modal = () => {
    const [pageNum, setPageNum] = useState(1);
    const [newstate, setnewstate] = useState([]);
    const [loadingState, setloadingState] = useState(0);
    const [myPopularity, setmyPopularity] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const { activityCode } = useParams();
    useEffect(() => {
        getList(pageNum)
    }, []);
    const getList = async (page) => {
        if (isLoad) {
            return;
        }

        let res = await Services('MainPage', { code: 101217 }, { activityCode, pageNum: page, pageSize: 10 });
        if (res.status > 0) {
            let arr = res.data.jktActivityRewardLogList;
            let myPopularity = res.data.myPopularity;
            setnewstate([...newstate, ...arr]);
            if (arr.length < 10) {
                setIsLoad(true)
            }
            if (typeof myPopularity == "number" && myPopularity > 9999) {
                setmyPopularity((myPopularity / 10000).toFixed(2) + "万")
            } else {
                setmyPopularity(myPopularity);
            }
            setloadingState(2);
        } else {
            setloadingState(2);
        }
    };



    let isDebounce = false;

    const handlePullUp = useCallback(async () => {
        if (isLoad) return;
        setloadingState(0);
        if (isDebounce === true) {
            return;
        }
        else {
            let page = pageNum + 1;
            setPageNum(page);
            isDebounce = true;
            await getList(page);
        }
    }, [newstate, isLoad]);

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div style={{ width: "100%" }} className={styles.background}>
                    <Scroll
                        myPopularity={myPopularity}
                        prop={newstate}
                        onPullup={handlePullUp}
                        loading={loadingState}>
                        <div className={styles.feedListScoped}>
                            {newstate.map((item, idx) =>
                                <a>
                                    <div className={styles.feedItemContainer} key={idx}>
                                        <section className={styles.imgContainer}>
                                            <img src={item.imagePath} className={styles.lazyWrapper} alt="" />
                                            <span>
                                                <div>{item.loginName || ""}</div>
                                                <div>{item.createTime || ""}</div>
                                            </span>
                                        </section>
                                        <article>+{item.amount}人气值</article>
                                    </div>
                                </a>
                            )}
                        </div>

                    </Scroll>
                </div>
            </div>
        </div>
    )

}