import React from 'react';
import { ActivityTime } from './component'
import styles from './rule.module.scss';
import { useHistory } from 'react-router-dom';

export const Rule = () => {
    const history = useHistory();
    const { rewardPoint, dayInviteClientPointLimit } = history.location.state || {};
    return <div className={styles.container}>
        <div className={styles.content} >
            <div className={styles.text}>1.活动参与方式：注册成为G优会员，即可参与G优超级分享官活动，分享专属邀请海报给好友，好友使用未安装过绿地G优APP的手机下载并注册登录，邀请人即可获得{rewardPoint || 0}邀请积分。</div>
            <div className={styles.text}>2.活动期间，每位用户每天邀请会员人数不限，但每日可获得的邀请积分上限是{dayInviteClientPointLimit || 5000}积分，积分可在绿地G优APP的积分商城中兑换精美礼品。</div>
            <div className={styles.text}>3.绿地吉客智能有限公司可根据本活动实际情况对活动规则进行优化或调整。若发现活动中参与用户存在不正当行为(包括作弊、扰乱系统、网络攻击等)，绿地吉客智能有限公司有权取消其活动所得并终止其参与资格。</div>
        </div>
    </div>;
}
