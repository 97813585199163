import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import loginBg from './images/login-bg@2x.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Actions } from 'reduxs';
import Services from 'services';
import Library from 'library';
export * from './success';

let SwiperNc = null;

const { Platform, Util } = Library;
export const Register = () => {
    const [isAgree, setIsAgree] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    // const [isNcShow, setIsNcShow] = useState(false);
    const [registerStatus, setRegisterStatus] = useState(null);
    // const NCRef = useRef();


    const redirect = location.state && location.state.redirect;
    useEffect(() => {
        const caseId = Util.url.param('caseId');
        if (caseId) {
            history.replace('/front/member/getCard?caseId=' + caseId);
            return;
        }
        isRegisted();
    }, []);

    const isRegisted = async () => {

        const result = await Services('GuMember', 'IsRegisted', {});
        if (result.status > 0) {
            if (result.data && result.data.msg) {
                if (Platform.name() === 'Wap') {
                    history.replace(redirect || '/front/member/regSuccess');
                } else {
                    window.location.href = redirect || '/front/member/card';
                }
            } else {
                setRegisterStatus(true);
            }
        } else {
            if (Platform.name() === 'Wap') {
                setRegisterStatus(true);
            }
        }
    }

    const countDown = useSelector((state) => {
        return state.sms.toJS().couponCounter;
    });

    const sendSms = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('DynamicCode', 'GetDynamicCode', {
                    mobile,
                    ...res
                });
                if (result.status > 0) {
                    Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
                }
            }
        }

    }



    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.sms}> {lastSecond} 秒 </button>;
        } else {
            return <button onClick={sendSms} className={styles.sms}>获取</button>;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [countDown]);

    const submit = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (!code) {
            Actions.alert.show('请输入短信验证码');
            return;
        }
        if (!isAgree) {
            Actions.alert.show('请先同意协议');
            return;
        }
        const params = { mobile, dynamicCode: code };
        const caseId = Util.url.param('caseId');
        if (caseId) {
            params.caseId = caseId;
        }
        const result = await Services('GuMember', 'RegistOrLogin', params);
        if (result.status > 0) {
            // 案场直接跳下载app页面
            if (caseId) {
                history.replace('/front/member/getCardSuccess', { caseId });
                return;
            }
            if (Platform.name() === 'Wap') {
                history.replace(redirect || '/front/member/regSuccess');
            } else {
                history.push(redirect || '/front/member/card', {
                    mobile,		//用户手机号
                    ...result.data
                });
            }

        }


    };

    useEffect(() => {
        initNC();
    }, []);

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            // setSwiperCaptcha(e.SwiperCaptcha);
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    }

    // useEffect(() => {
    //     NCRef.current = async (data) => {
    //         console.log(mobile);
    //         // 短信验证码
    //         const { code } = data;
    //         if (code === 0) {
    //             const result = await Services('DynamicCode', 'GetDynamicCode', {
    //                 mobile,
    //                 ...data
    //             });
    //             if (result.status > 0) {
    //                 Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
    //             }
    //         }

    //     }
    // }, [mobile]);

    if (!registerStatus) {
        return false;
    }
    return <div className={styles.container}>

        <div className={styles.headers}>
            <img src={loginBg} alt='头图' />
        </div>
        <div className={styles.form}>

            <div className={styles.inputWrap}>
                <p><label>手机</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setMobile(event.target.value);
                        }} type='tel' value={mobile} placeholder='请输入' maxLength='11' />
                    </div>
                </div>
            </div>

            <div className={styles.inputWrap}>
                <p><label>验证码</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setCode(event.target.value);
                        }} type='text' val={code} placeholder='请输入' maxLength='6' />
                    </div>
                    {sendSmsRender()}

                </div>
            </div>

            <div className={styles.agreeWrap} onClick={() => {
                setIsAgree(!isAgree);
            }}>
                <i className={`${styles.agreeIcon} ${isAgree ? '' : styles.notAgreeIcon}`}></i>
                <p>我已阅读并同意<Link to='/front/member/register/protocol'>《G优尊享会员服务免责条款》</Link></p>
            </div>
            <div onClick={submit} className={styles.button}>注册/登录并激活会员</div>
        </div>
        {/* {isNcLoaded ? <SwiperNc visible={isNcShow} success={(e) => {
            NCRef.current(e);
        }} hide={() => {
            setIsNcShow(false)
        }} /> : false} */}
    </div>;
}