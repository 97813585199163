import React, { useEffect, useState,useRef } from 'react';
import styles from './nucalmModal.module.scss';
export const NucalmModal = (props)=>{
    const displayButtons = [{
        name: '取消', click: () => {
            props.onCancel && props.onCancel();
        },
        className: styles.cancel,
    },{
        name: '确定', click: () => {
            props.onOK && props.onOK();
        },
        className: styles.confirm,
    }];
    return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
        <div className={styles.modalWrap}>
            <div className={styles.modalContent}>{props.content || ''}</div>
            <div className={styles.modalButton}>
                {displayButtons.map((item, key) => {
                    return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                })}
            </div>
        </div>
    </div>
}
