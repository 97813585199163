/**
 * 每日签到 碎片记录
 */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styles from './patches.module.scss';
import Library from 'library';
import Services from "../../../services";
const { JsBridge,Hooks } = Library;
export const Patches = (props) => {
    const [index, setIndex] = useState(0);
    const [tabs, setTabs] = useState([{name:'全部',type:null},{name:'获取',type:0},{name:'兑换',type:1}]);
    const navTab = useRef();
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    const divObj = navTab.current;
    useEffect(() => {
        if (divObj) {
            let width = divObj.clientWidth;
            if (index <= 2) {
                divObj.scrollTo(0, 0);
            } else if (index >= tabs.length - 3) {
                let dis = (width / 375) * 50 * (tabs.length);
                divObj.scrollTo(Math.max(dis, 0), 0);
            } else {
                let x = (width / 375) * 50 * (index - 1);
                divObj.scrollTo(Math.max(x, 0), 0);
            }
        }
    }, [index, divObj, tabs]);


    const handleChangeIndex = index => {
        if (index < tabs.length) {
            setIndex(index);
        }
    };

    return <div className={styles.container}>
        <div className={styles.nav} ref={navTab}>
            <ul className={styles.tabs}>
                {tabs.map((item, key) => {
                    let isActive = index.toString() === key.toString();
                    return <li
                        onClick={() => {
                            setIndex(key);
                        }} key={key} className={styles.item}>
                        <a className={`${styles.text} ${isActive?styles.active:''}`}>{item.name}</a>
                        {isActive?<div className={styles.activeBar}/>:null}
                    </li>;
                })}
            </ul>
        </div>
        <SwipeableViews
            index={index}
            className={styles.swiper}
            onChangeIndex={handleChangeIndex}
        >
            {tabs.map((item, key) => {
                return <List item={item} key={key} />
            })}
        </SwipeableViews>
    </div>;
}

const List = (props) => {
    const [state, setState] = useState({ arrSource: [], pageNum: 1, isLoading: false, isLoadingAll: false });
    useEffect(() => {
        getData(true);
    }, []);

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        let { clientHeight, scrollHeight, scrollTop } = pageView.current;
        if (scrollTop + clientHeight >= scrollHeight && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            getData();
            timer.current = setTimeout(() => {
                isDebounce = false;
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    Hooks.useEventListener('scroll', handler, pageView.current);

    const getData = async (refresh) => {
        setState({ ...state, isLoading: true });
        let currentPageNum = refresh ? 1 : state.pageNum + 1;
        let params = { pageNum: currentPageNum, pageSize: 10,fragmentType:props.item.type };
        //调接口获取
        const result = await Services('MainPage', { code: '101210' },params)
        JsBridge.print(result);
        if (result && result.status > 0) {
            let {jktActivityRewardLogList:arr = [],count = 1} = result.data;
            let arrSource = state.arrSource;
            if (currentPageNum === 1) {
                arrSource = arr;
            } else {
                arrSource = [...arrSource, ...arr];
            }
            setState({ ...state, arrSource: arrSource, pageNum: currentPageNum, isLoading: false, isLoadingAll: arrSource.length >= count });
        } else {
            setState({ ...state, isLoading: false });
        }


    }

    return <ul className={styles.wrap} ref={pageView}>
        {state.arrSource.map((item, index) => {
            return <li className={styles.cell} key={index}>
                <div className={styles.cellContent}>
                    <p className={styles.title}>{item.description}</p>
                    <p className={styles.date}>{item.updateTime}</p>
                </div>
                <div className={styles.fill}/>
                <p className={`${styles.num} ${item.eventType==='1'?styles.minus:''}`}>{`${item.eventType ==='1'?'-':'+'}${item.amount}`}</p>
            </li>
        })}
    </ul>
}

