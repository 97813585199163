/**
 * 分享出去界面后点击事件的拦截器
*/

import React, { useState, useEffect, useMemo, Fragment } from 'react';
import gotoBrowserImg from 'images/goto-browser.png';
import Library from 'library';
import './index.scss';
const { Platform } = Library;
const appSchema = 'gktapp://com.gkewang';

export default (props)=> {
    // 获取链接携带参数
    const params = Library.Util.url.paramsToObj();

    const { toRoute = '/front/activities/double11' } = props
    const [maskShow, setMaskShow] = useState(false);
    const targetUrl = `${process.env.REACT_APP_GKT_LINK}${toRoute}?${Library.Util.url.objectToQueryParams(params)}`;

    const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

    const openApp = (e) => {
        e.stopPropagation();
        console.log('打开启动app')
        if (Platform.check.isWechat()) {
            setMaskShow(!maskShow);
        }else if(Platform.check.isH5()) {
            window.location.href = launchUrl;
            setTimeout(() => {
                window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
            }, 3000);
        }else {
            window.location.href = launchUrl;
            setTimeout(() => {
                window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
            }, 3000);

        }
    }
    if(process.env.NODE_ENV == 'development') {
        return null
    }
    return (
        <Fragment>
            {
                Platform.check.isApp() ? null:
                <div className='interceptor' onClick={openApp}>
                    {
                        maskShow && 
                        <div className='mask' onClick={() => setMaskShow(false)}>
                            <img src={gotoBrowserImg} alt="" />
                        </div>
                    }
                </div>
            }
        </Fragment>
        
    )
}