
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import sloganImage from 'images/GPremium-brand.svg';
import mallIcon from 'images/member/me-icon-list@2x.png';
import { Actions } from 'reduxs';
import Services from 'services';

const global = {
    getImageUrl: (url) => {
        return 'https://icon.gkewang.com/gu/weapp' + url;
    }
}
export const Home = (props) => {
    const [user, setUser] = useState({});
    const history = useHistory();
    const getUserInfo = async () => {
        const result = await Services('MyPage', 'Init');
        if (result.status > 0) {
            setUser(result.data.msg);
        }
    }
    useEffect(() => {
        getUserInfo();
    }, []);
    if (user) {
        return <div className={styles.userContainer}>
            <div className={styles.topView}>
                <img className={styles.headImg} src={user.imagePath} />
                <div className={styles.headText}>
                    <span className={styles.name}>{user.loginName}</span>
                    <img className={styles.tip} src={global.getImageUrl('/user/my/me-slogan4.svg')} />
                </div>
            </div>

            <CardRender user={user} />

            <div className={styles.itemBg}>
                <div className={styles.item} onClick={async () => {
                    window.location.href = '/taro/subPages/user/mall/index';
                }}
                >
                    <img src={mallIcon} className={styles.icon} />
                    <span className={styles.text}>积分商城</span>
                    <img src={global.getImageUrl('/user/general-btn-arrow-more.svg')} className={styles.arrow} />
                </div>
                <div className={styles.line} />
                <div className={styles.item} onClick={async () => {
                    history.push('/front/isRegisted/coupon')
                }}
                >
                    <img src={global.getImageUrl('/user/my/me-icon-coupon.svg')} className={styles.icon} />
                    <span className={styles.text}>优惠券</span>
                    <img src={global.getImageUrl('/user/general-btn-arrow-more.svg')} className={styles.arrow} />
                </div>
            </div>

            <div className={styles.itemBg}>
                <div className={styles.item} onClick={async () => {
                    // history.push('/front/member/privilege');
                    const { levelCode = 1 } = user;
                    let splider = 'one';
                    if (levelCode === 2) {
                        splider = 'two';
                    } else if (levelCode === 3) {
                        splider = 'three';
                    }
                    window.location.href = `/taro/subPages/user/mycard/detail/index?id=${splider}&current=${levelCode}`;

                }}
                >
                    <img src={global.getImageUrl('/user/my/me-icon-privilege.svg')} className={styles.icon} />
                    <span className={styles.text}>会员特权</span>
                    <img src={global.getImageUrl('/user/general-btn-arrow-more.svg')} className={styles.arrow} />
                </div>
                <div className={styles.line} />
                <div className={styles.item} onClick={() => {
                    history.push('/front/member/aboutus');
                }}
                >
                    <img src={global.getImageUrl('/user/my/me-icon-about.svg')} className={styles.icon} />
                    <span className={styles.text}>关于我们</span>
                    <img src={global.getImageUrl('/user/general-btn-arrow-more.svg')} className={styles.arrow} />
                </div>
            </div>
            <div className={styles.slogan}>
                <img src={sloganImage} />
            </div>
        </div>
    } else {
        return false;
    }
}

const CardRender = (props) => {
    const { user } = props;
    const history = useHistory();
    const { guCardNo, levelCode = 1 } = user;
    let levelName = '尊享会员'
    let styleVip = '';
    let qrCode = global.getImageUrl("/user/experience-qr-b@2x.png");
    if (levelCode === 2) {
        levelName = '荣耀会员'
        styleVip = styles.rongyao;
        qrCode = global.getImageUrl("/user/experience-qr@2x.png");
    } else if(levelCode === 3) {
        levelName = '王者会员'
        styleVip = styles.wangzhe;
        qrCode = global.getImageUrl("/user/vip-btn-qr@2x.png");
    }
    return <div className={`${styles.card} ${styleVip}`} onClick={async () => {
        // history.push(`/front/isRegisted/card`);
        window.location.href = '/taro/subPages/user/mycard/index';
    }}
    >
        <img onClick={async (e) => {
            e.stopPropagation();
            history.push('/front/member/qrcode/' + guCardNo);
        }} src={qrCode} className={styles.qrCode} />
        <div className={styles.descride}>
            <div className={styles.textView}>
                <span className={`${styles.text2} ${styleVip}`}>{levelName}</span>
                <span className={`${styles.text3} ${styleVip}`}>绿地控股集团</span>
            </div>

            <div className={styles.textView}>
                <span className={`${styles.text4} ${styleVip}`}>NO.{guCardNo}</span>
                {/*<div className={styles.centerRow} onClick={async (e) => {*/}
                {/*    e.stopPropagation();*/}
                {/*    history.push(`/front/member/${isVip === 'vip' ? 'vipDesc' : 'tempDesc'}`)*/}
                {/*}}*/}
                {/*>*/}
                {/*    <span className={`${styles.text3} ${vipStyle}`}>会员说明</span>*/}
                {/*    <img className={styles.iconQuestion} src={global.getImageUrl('/user/vip-icon-btn-question@2x.png')} />*/}
                {/*</div>*/}
            </div>
        </div>
    </div>
}
