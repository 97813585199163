/**
 * @description 元宇宙每日签到
*/

import React, { useState, useEffect } from 'react';
import Library from 'library';
import './index.scss';

const {JsBridge, Util} = Library;

export default (props) => {
    const { dataSource=[] } = props
    
    const [signDays, setSignDays] = useState([])

    useEffect(()=>{
        const times = dataSource.filter(item => item.time).map(item => item.time);
        setSignDays(times)
    },[dataSource])

    const onJumpClick = () => {
        // 访问元宇宙
        JsBridge.startUnity3d({
            sceneName: 'shop'
        })
    }


    return (
        <div className='unity-sign'>
            <h1>每日签到 
                <span>已连续签到<span>{signDays.length}</span>天</span>
            </h1>
            <div className='sign-list'> 
            {
                dataSource.map((item,index)=> {
                    const finish = index < signDays.length;
                    return (
                        <div className={`signItem`} key={index}>
                            {
                               item.awards &&
                               item.awards.map((m,i)=> {
                                    return (
                                        <div key={i} className={finish ? 'finish': ''}>
                                            <div className='img'>
                                                <img src={m.awardUrl}/>
                                                {
                                                    finish &&
                                                    <img className='finish-img' src={require('images/pointTask/sign_finish.png')}/>
                                                }
                                            </div>
                                            <div className='award-desc'>
                                                <span>{m.awardName}</span>
                                            </div>
                                        </div>
                                    )   
                               })
                            }
                            
                            <span>{index+1}天</span>
                        </div>
                    )
                })
            }
            </div>
           { dataSource && dataSource.length ? <div className='go-unity' onClick={onJumpClick}>去元宇宙签到</div>: null} 
        </div>
    )
}