import React, {useEffect, useRef, useState, useContext, createContext} from 'react';
import ShareMask from "../../../components/ShareMask";
import EggJiFenDialog from './views/eggJiFenDialog' //积分兑换
import EggDialog from './views/eggDialog' //兑换框.暂无传说款/暂无史诗款
import EggAirDropDialog from "./views/eggAirDropDialog";//惊喜空投
import Library from "../../../library";
import styles from './petEggHome.module.scss';
import egg_headkv from '../../../images/egg_headkv.png';//上面的大背景
import egg_zhutu_gongkai from 'images/egg_zhutu_gongkai.png' //公开售
import egg_bt_chakanxiangqing from '../../../images/egg_bt_chakanxiangqing.png';
import egg_bt_huodong from '../../../images/egg_bt_huodong.png';
import egg_bt_shiming from '../../../images/egg_bt_shiming.png';
import egg_bt_lijibaoming_liang from '../../../images/egg_bt_lijibaoming.png'; //立即报名 亮色 大按钮
import egg_bt_lijibaoming_hui from 'images/egg_bt_lijibaoming_hui.png';//立即报名 灰色 大按钮
import egg_rectangle from 'images/egg_rectangle.png';//立即报名的向右的按钮

import egg_head_zhuli_add from '../../../images/egg_head_zhuli_add.png';
import egg_bt_yes from '../../../images/egg_bt_yes.png';// 亮色 小按钮
import egg_bt_hui from '../../../images/egg_bt_hui.png';// 灰色 小按钮
import egg_bg_choujuan from '../../../images/egg_bg_choujuan.png';
import egg_bg_kuang from '../../../images/egg_bg_kuang.png';
import egg_logo from '../../../images/egg_logo.png';
import egg_right_btn from '../../../images/egg_right_btn.png'
import EggStockRecordPop from "./views/eggStockRecordPop";
import EggBaomingDialog from "./views/eggBaomingDialog";
import EggRuleDialog from "./views/eggRuleDialog";
// import EggBaomingContentComponent from "./views/eggBaomingContentComponent";
import Services from "../../../services";
import {getYMRTimeString, isArray, isEmptyArray, isEmptyObject, isEmptyString} from "../../../library/util";
import {Toast} from "antd-mobile";
import {EggBaomingContentComponent} from "./views/eggBaomingContentComponent";

const {JsBridge} = Library;

const Context = createContext(null);
// const currentState = 0;// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
// const currentOperate = 0 //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 100=影藏款直接领取
let isLoadJieDuan = false;//是否加载过阶段数据
export const PetEggHome = () => {

    const [userInfo, setUserInfo] = useState({}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名
    const [jieduanInfo, setJieduanInfo] = useState({});
    const {currentState, currentOperate} = jieduanInfo;
    const [baoMingShow, setBaoMingShow] = useState(false);


    useEffect(() => {
        getUserInfo();
    }, [])

    const refreshData = () => { //刷新数据 主要是登录之后要刷新
        isLoadJieDuan = false;
        getUserInfo();
    }
    const getUserInfo = () => { //获取用户信息
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            setUserInfo(res);
            if (res.isLogin) { //登录
                getTimeConfig(res.clientId);
            } else { //未登录
                getTimeConfig(null);
            }
        })
    }
    const getTimeConfig = (clientId) => { //各个时间段的配置 clientId
        let param = {};
        if (isEmptyString(clientId)) {
            param = {};
        } else {
            param = {'clientId': clientId};
        }
        Services('MainPage', {code: 600681}, param).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                isLoadJieDuan = true;
                setJieduanInfo(data);
            }
        });
    }

    const goBaoMingClick = () => { //去报名
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData();
            });
            return;
        }
        if (isEmptyString(userInfo.ID)) { //未实名
            // Toast.show('您当前未实名,请先实名!')
            JsBridge.addListener().then(() => { //实名认证回来
                refreshData();
                // alert('renzehn')
            });
            JsBridge.navigate('Verify');
            return;
        }
        let param = {
            clientId: userInfo.clientId,
            randomStr: jieduanInfo.randomStr,
        }
        Services('MainPage', {code: '600685'}, param).then((res) => {
            if (res.status > 0) {
                Toast.show('报名成功!')
                refreshData && refreshData();
                setBaoMingShow(!baoMingShow);
            }
        });
    }

    return (
        <Context.Provider value={{userInfo, jieduanInfo}}>
            <div className={styles.container}>
                <HeaderComponent refreshData={refreshData} goBaoMingClick={goBaoMingClick}/>
                {(currentState === 0 || currentState === 1) ?
                    <MopedComponent refreshData={refreshData} goBaoMingClick={goBaoMingClick}/> : null}
                {currentState < 7 ? <WealComponent refreshData={refreshData} goBaoMingClick={goBaoMingClick}/> : null}
                <BottomLogoComponent/>
                <JingXiKongTouComponent/>
                < EggBaomingDialog maskVisible={baoMingShow} setMaskVisible={setBaoMingShow}/>
            </div>
        </Context.Provider>
    )
}

//头部视图
const HeaderComponent = (props) => {
    const [showRuleShow, setShowRuleShow] = useState(false);
    const [maskVisible, setMaskVisible] = useState(false); //分享
    const {userInfo, jieduanInfo} = useContext(Context);
    let {currentState, currentOperate, remainingPurch, stock} = jieduanInfo;
    const refreshData = () => {
        if (props.refreshData) {
            props.refreshData();
        }
    }


    const goBack = () => {
        JsBridge.back()
    }

    const huodongRule = () => {
        setShowRuleShow(!showRuleShow)
    }
    const clickShare = (type) => {
        setMaskVisible(!maskVisible);
    }

    const shiMingRenZhen = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                refreshData();
            });
            return;
        }
        JsBridge.addListener().then(() => { //实名认证回来
            if (props.refreshData) {
                props.refreshData();
            }
        });
        JsBridge.navigate('Verify');
    }
    const lookDetail = () => { //查看详情
        goApeWorldSaleDetail(userInfo, jieduanInfo, 0, props.refreshData);
    }

    const activityStatesView = () => {
        let array = [
            {title: '活动报名', time: '00:00', id: 0},
            {title: '第一轮中签公布', time: '00:00', id: 1},
            {title: '第二轮中签公布', time: '00:00', id: 2},
            {title: '原价公开售', time: '00:00', id: 3}];
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            if (i === 0) {
                item.time = jieduanInfo?.regTimeStr || '00:00';
            } else if (i === 1) {
                item.time = jieduanInfo?.lotteryTimeStr || '00:00';
            } else if (i === 2) {
                item.time = jieduanInfo?.secondLotteryTimeStr || '00:00';
            } else if (i === 3) {
                item.time = jieduanInfo?.publicSaleTimeStr || '00:00';
            }
        }
        return array.map((item, index) => {
            return <div key={index.toString()} className={styles.title_time_father}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.time}>{item.time}</div>
            </div>
        })
    }

    const publicSaleView = () => { //公开售卖
        let stockTem = parseInt(stock) || 0;
        const goBuy = () => { //去购买
            if (currentState === 8) { //活动已结束
                return;
            }
            if (currentState === 7) { //公开售
                goApeWorldSaleDetail(userInfo, jieduanInfo, 0, props.refreshData)
            }
        }
        let img_src = egg_bt_lijibaoming_liang;
        let spanStyle = styles.btn_0;
        let name = '立即购买';

        if (currentState === 7) {
            if (stockTem > 0) {
                img_src = egg_bt_lijibaoming_liang;
                spanStyle = styles.btn_0
                name = '立即购买';
            } else {
                if (!userInfo.isLogin) {
                    img_src = egg_bt_lijibaoming_liang;
                    spanStyle = styles.btn_0
                    name = '立即购买';
                } else {
                    if (isLoadJieDuan && stockTem === 0) {
                        img_src = egg_bt_lijibaoming_hui;
                        spanStyle = styles.btn_0_hui;
                        name = '已售罄';
                    } else {
                        img_src = egg_bt_lijibaoming_liang;
                        spanStyle = styles.btn_0
                        name = '立即购买';
                    }
                }
            }
        } else if (currentState === 8) {
            img_src = egg_bt_lijibaoming_hui;
            spanStyle = styles.btn_0_hui;
            name = '活动已结束';
        }

        return (
            <div className={styles.gongkai_father}>
                <img src={egg_zhutu_gongkai} alt=""/>
                <div className={styles.content} onClick={() => goBuy()}>
                    <img src={img_src}
                         className={styles.goumai_btn}/>
                    <div className={styles.btn_title}>
                        <span
                            className={spanStyle}>{name} </span>
                    </div>
                </div>
            </div>
        )
    }

    // const currentState = 0;// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段()  8=活动结束
    return (
        <>
            <div className={styles.header_component}>
                <img src={egg_headkv} className={styles.img_bg}/>
                <div className={styles.back_share_father}>
                    <div className={styles.back_btn} onClick={() => goBack()}></div>
                    <div className={styles.share} onClick={() => clickShare(0)}></div>
                </div>

                <div className={styles.rule_shiming_father}>
                    <div className={styles.huodong_father} onClick={() => huodongRule()}>
                        <img src={egg_bt_huodong} alt=""/>
                        <div className={styles.title}>活动规则</div>
                    </div>

                    {isEmptyString(userInfo.ID) ?
                        <div className={styles.huodong_father} onClick={() => shiMingRenZhen()}>
                            <img src={egg_bt_shiming} alt=""/>
                            <div className={styles.title_shiming}>实名认证</div>
                        </div> : null}
                </div>
                <div className={styles.bottom_detail_jieduan_daojishi_father}>
                    <div className={styles.look_detail_father} onClick={() => lookDetail()}>
                        <span>查看详情</span>
                        <img src={egg_rectangle} alt=""/>
                    </div>
                    {/*// // const currentState = 0;// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束*/}
                    <div className={styles.activity_jieduan_father}>
                        <div className={styles.yuanquan_father}>
                            <span
                                className={(currentState > 0) ? styles.yuanquan_span_shixing : styles.yuanquan_span}/>
                            <span className={styles.yuanquan_line}/>
                            <span
                                className={(currentState > 2) ? styles.yuanquan_span_shixing : styles.yuanquan_span}/>
                            <span className={styles.yuanquan_line}/>
                            <span
                                className={(currentState > 4) ? styles.yuanquan_span_shixing : styles.yuanquan_span}/>
                            <span className={styles.yuanquan_line}/>
                            <span
                                className={(currentState > 6) ? styles.yuanquan_span_shixing : styles.yuanquan_span}/>
                        </div>
                        <div className={styles.jieduan_title_father}>
                            {activityStatesView()}
                        </div>
                    </div>
                    {
                        currentState < 7 ? <EggBaomingContentComponent userInfo={userInfo} jieduanInfo={jieduanInfo}
                                                                       refreshData={refreshData}/> : null
                    }

                    {(currentState === 7 || currentState === 8) ? publicSaleView() : null}
                </div>
            </div>
            <EggRuleDialog maskVisible={showRuleShow} setMaskVisible={setShowRuleShow} type={0}/>
            <ShareMask maskVisible={maskVisible} type={0} closeMask={() => setMaskVisible(!maskVisible)}/>
        </>
    )
}

//助力模块  抽劵翻倍
const MopedComponent = (props) => {
    const [showChouQianView, setShowChouQianView] = useState(false)
    const [showShare, setShowShare] = useState(false)
    const [showRule, setShowRule] = useState(false)
    const {userInfo, jieduanInfo} = useContext(Context);
    let {isLogin} = userInfo;
    let {currentState, currentOperate} = jieduanInfo;

    //  currentState// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
    let btnTitle_dh = '敬请期待'; //兑换的按钮
    let btnTitle_qd = '敬请期待'; //签到的按钮
    let btnTitle_j5 = '敬请期待'; //加5的按钮
    let btnTitle_j2 = '敬请期待'; //加2的按钮
    let img = egg_bt_hui;
    let style = styles.span0_hui;
    if (isLogin) {

    } else { //未登录
        if (currentState === 0) {
            btnTitle_dh = '敬请期待';
            btnTitle_qd = '敬请期待';
            btnTitle_j5 = '敬请期待';
            btnTitle_j2 = '敬请期待';
            img = egg_bt_hui;
            style = styles.span0_hui;
        } else if (currentState === 1 || currentState === 2) {
            btnTitle_dh = '去兑换';
            btnTitle_qd = '去签到';
            btnTitle_j5 = '立即领取';
            btnTitle_j2 = '立即领取';
            img = egg_bt_yes;
            style = styles.span0;
        }
    }

    let arr = [
        {
            title: '拥有传说款数字藏品 ',
            caption: '抽签券+5',
            btnTitle: btnTitle_j5,
            id: 0,
            collectionType: '2',
            buttonState: '1',
            holdState: '0',
            img: currentState === 0 ? egg_bt_hui : egg_bt_yes,
            style: currentState === 0 ? styles.span0_hui : styles.span0,
        },
        {
            title: '拥有史诗款数字藏品 ',
            caption: '抽签券+2',
            btnTitle: btnTitle_j2,
            id: 1,
            collectionType: '2',
            buttonState: '1',
            holdState: '0',
            img: currentState === 0 ? egg_bt_hui : egg_bt_yes,
            style: currentState === 0 ? styles.span0_hui : styles.span0,
        }
    ];


    //积分兑换抽签劵+1 签到领取加1
    let arr0 = [
        {
            title: '积分兑换抽签券+1',
            secondTitle: '100积分兑换一张抽签劵,\n限10次,已兑换0/10',
            btnTitle: btnTitle_dh,
            img: img,
            id: 0,
            style: style
        },
        {
            title: '签到领取+1',
            secondTitle: '用户每日登录APP签到可领',
            btnTitle: btnTitle_qd,
            img: img,
            id: 1,
            style: style
        }
    ];

    let [dataSouece, setDataSouece] = useState({
        infoVOList: [],
        signStatus: '0',//签到状态 0=未签到 1=已签到
        num: '',//可以兑换的积分数量
        desCollection: arr,
        duihuanArr: arr0,
        lotterieSum: ''
    }); //抽劵

    useEffect(() => {
        getRedeemData();
    }, [userInfo, jieduanInfo])

    const duihuanSuccess = () => { //积分兑换成功 需要刷新数据
        refreshData && refreshData();
    }

    const getRedeemData = () => {
        if (userInfo.isLogin) { //userInfo.clientId
            Services('MainPage', {code: 600682}, {'clientId': userInfo.clientId}).then((res) => {
                if (res.status > 0) {
                    let data = res.data;
                    let desCollection = data.desCollection;
                    if (!isEmptyArray(desCollection)) {
                        for (let i = 0; i < desCollection.length; i++) {
                            let item = desCollection[i];
                            item.id = i;
                            let btnState = parseInt(item?.buttonState) || 0;
                            if (btnState === 1) {
                                item.btnTitle = '立即领取';
                                item.img = egg_bt_yes;
                                item.style = styles.span0;
                            } else if (btnState === 2) { //已经领取了
                                item.btnTitle = '已领取';
                                item.img = egg_bt_hui;
                                item.style = styles.span0_hui;
                            } else if (btnState === 3) {
                                item.btnTitle = '未持有';
                                item.img = egg_bt_hui;
                                item.style = styles.span0_hui;
                            } else { // buttonState: '0', //0=敬请期待 1=立即领取 2=查看详情 3=未持有
                                item.btnTitle = '敬请期待'
                                item.img = egg_bt_hui;
                                item.style = styles.span0_hui;
                            }
                        }
                        data.desCollection = desCollection;
                    }

                    for (let i = 0; i < arr0.length; i++) {
                        let item = arr0[i];
                        if (i === 0) {
                            item.secondTitle = `100积分兑换一张抽签劵,\n限10次,已兑换${data.num}/10`
                            if (currentState === 0) {
                                item.btnTitle = '敬请期待'
                                item.img = egg_bt_hui;
                                item.style = styles.span0_hui;
                            } else {
                                item.btnTitle = '去兑换';
                                item.img = egg_bt_yes
                                item.style = styles.span0;
                                let num = parseInt(data.num) || 0;
                                if (num >= 10) {
                                    item.btnTitle = '已兑换'
                                    item.img = egg_bt_hui;
                                    item.style = styles.span0_hui;
                                }
                            }
                        } else {
                            if (currentState === 0) {
                                item.btnTitle = '敬请期待'
                                item.img = egg_bt_hui;
                                item.style = styles.span0_hui;
                            } else {
                                item.btnTitle = '去签到';
                                item.img = egg_bt_yes
                                item.style = styles.span0;
                                let signStatus = parseInt(data.signStatus) || 0;
                                if (signStatus > 0) {
                                    item.btnTitle = '已签到'
                                    item.img = egg_bt_hui;
                                    item.style = styles.span0_hui;
                                }
                            }
                        }
                        data.duihuanArr = arr0;
                    }
                    setDataSouece(data)
                }
            });
        } else { //未登录
//积分兑换抽签劵+1 签到领取加1
            let btnTitle_dh_0 = '敬请期待'; //兑换的按钮
            let btnTitle_qd_0 = '敬请期待'; //签到的按钮
            let btnTitle_j5_0 = '敬请期待'; //加5的按钮
            let btnTitle_j2_0 = '敬请期待'; //加2的按钮

            let img_0 = egg_bt_hui;
            let style_0 = styles.span0_hui;
            if (currentState === 0) {
                btnTitle_dh_0 = '敬请期待';
                btnTitle_qd_0 = '敬请期待';
                btnTitle_j5_0 = '敬请期待';
                btnTitle_j2_0 = '敬请期待'
                img_0 = egg_bt_hui;
                style_0 = styles.span0_hui;
            } else if (currentState === 1 || currentState === 2) {
                btnTitle_dh_0 = '去兑换';
                btnTitle_qd_0 = '去签到';
                btnTitle_j5_0 = '立即领取';
                btnTitle_j2_0 = '立即领取';
                img_0 = egg_bt_yes;
                style_0 = styles.span0;
            }

            let arr0New = [
                {
                    title: '积分兑换抽签券+1',
                    secondTitle: '100积分兑换一张抽签劵,\n限10次,已兑换0/10',
                    btnTitle: btnTitle_dh_0,
                    img: img_0,
                    id: 0,
                    style: style_0
                },
                {
                    title: '签到领取+1',
                    secondTitle: '用户每日登录APP签到可领',
                    btnTitle: btnTitle_qd_0,
                    img: img_0,
                    id: 1,
                    style: style_0
                },
            ];

            let arrNew = [
                {
                    title: '拥有传说款数字藏品 ',
                    caption: '抽签券+5',
                    btnTitle: btnTitle_j5_0,
                    id: 0,
                    collectionType: '2',
                    buttonState: '1',
                    holdState: '0',
                    img: img_0,
                    style: style_0,
                },
                {
                    title: '拥有史诗款数字藏品 ',
                    caption: '抽签券+2',
                    btnTitle: btnTitle_j2_0,
                    id: 1,
                    collectionType: '2',
                    buttonState: '1',
                    holdState: '0',
                    img: img_0,
                    style: style_0,
                }
            ];
            setDataSouece({
                ...dataSouece,
                duihuanArr: arr0New,
                desCollection: arrNew,
            })
        }
    }

    const yaoqingHaoyou = () => { //邀请好友
        if (currentState === 0) {
            return;
        }

        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData && refreshData();
            });
            return
        }
        // 去报名 1=报名阶段
        if (currentState === 1) { //报名期间
            if (currentOperate === 0) { //0:未报名
                props.goBaoMingClick && props.goBaoMingClick();
                return;
            }
        }


        if (currentState !== 0) {
            setShowShare(!showShare)
        }
    }

    const friendListClick = () => { //也是邀请好友
        yaoqingHaoyou();
    }
    const refreshData = () => {
        props.refreshData && props.refreshData();
    }
    const goBaoMingClick = () => {
        props.goBaoMingClick && props.goBaoMingClick();
    }

    const friendList = () => {
        let {infoVOList} = dataSouece;
        if (!isEmptyArray(infoVOList)) {
            if (infoVOList.length > 10) {
                let arrayNew = infoVOList.splice(0, 10); //只取10个
                return arrayNew.map((item, index) => {
                    return <img src={item.imagePath} alt="" key={index} className={styles.icon_header_pic}/>
                })
            } else { //小于10个要补起
                let num = 10 - infoVOList.length;
                for (let i = 0; i < num; i++) {
                    infoVOList.push({
                        imagePath: egg_head_zhuli_add,
                    })
                }
                return infoVOList.map((item, index) => {
                    return <img src={item.imagePath} alt="" key={index} className={styles.icon_header_pic}/>
                })
            }
        } else {
            let arr1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            return arr1.map((item, index) => {
                // return <img src={'https://img.satrip.com/GALLERY/PRODUCTIMG/JPN0722A/晒谷子.jpg.ico'} alt="" key={index} className={styles.icon_header_pic}/>
                return <img src={egg_head_zhuli_add} alt="" key={index} className={styles.icon_header_pic}/>
            })
        }
    }

    return (
        <>
            <div className={styles.zhuli_father}>
                <span className={styles.zhuli_title_span}>邀10个好友助力获更多抽签券</span>
                <span className={styles.zhuli_rule_span} onClick={() => setShowRule(!showRule)}>规则详情></span>
                <span className={styles.zhuli_title1_span}>新用户助力x2！每成功邀1人最高可得12张抽签券</span>
                <div className={styles.friends_father}
                     onClick={() => friendListClick()}>{friendList()}</div>
                <div className={styles.btn_baoming_father0}
                     onClick={() => yaoqingHaoyou()}>
                    <div className={styles.btn_baoming_father1}>
                        <img src={currentState === 0 ? egg_bt_lijibaoming_hui : egg_bt_lijibaoming_liang}/>
                        <div className={styles.btn_baoming_title0}>
                            <span
                                className={currentState === 0 ? styles.span0_hui : styles.span0}>{currentState === 0 ? '敬请期待' : '邀请好友'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <RedeemCodeComponent dataSouece={dataSouece} refreshData={refreshData} duihuanSuccess={duihuanSuccess}
                                 goBaoMingClick={goBaoMingClick}/>
            <EggStockRecordPop maskVisible={showChouQianView} setMaskVisible={setShowChouQianView} userInfo={userInfo}/>
            <ShareMask maskVisible={showShare} type={1} closeMask={() => setShowShare(!showShare)}/>
            <EggRuleDialog maskVisible={showRule} setMaskVisible={setShowRule} type={1}/>
        </>
    )
}

//积分兑换,签到 用户持有制定藏品抽签抽签劵翻倍
const RedeemCodeComponent = ({dataSouece, refreshData, duihuanSuccess, goBaoMingClick}) => {
    const [showDuiHuanView, setShowDuiHuanView] = useState(false);
    const {userInfo, jieduanInfo} = useContext(Context);
    let {currentState, currentOperate} = jieduanInfo;

    const duihuanQiandaoClick = (index) => { //兑换 签到
        if (currentState === 0) { //活动未开始
            return;
        }
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData && refreshData();
            });
            return;
        }

        if (index === 0) {  //兑换
            if (currentState === 1) { //报名期间
                if (currentOperate === 0) { //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 100=影藏款直接领取
                    goBaoMingClick && goBaoMingClick();
                    return;
                }
            }
            let num = parseInt(dataSouece.num) || 0; //只能兑换10个
            if (num >= 10) {
                return;
            }
            setShowDuiHuanView(!showDuiHuanView);
        } else { //签到
            if (currentState === 1) { //报名期间
                if (currentOperate === 0) { //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 100=影藏款直接领取
                    goBaoMingClick && goBaoMingClick();
                    return;
                }
            }

            let signStatus = parseInt(dataSouece.signStatus) || 0;
            if (signStatus === 1) { //已签到
                return;
            }
            JsBridge.addListener().then((res) => {
                duihuanSuccess && duihuanSuccess(); //签到回来也需要刷新
            });
            JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/dailySignin/index`});
            clearAllTimer();
        }
    }

    const lingquChoujiangJuan = (index) => { //领取抽奖券
        if (currentState === 0) {
            return;
        }
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData && refreshData();
            });
            return;
        }

        let item = dataSouece.desCollection[index];
        let btnState = parseInt(item?.buttonState) || 0;
        if (btnState === 3 || btnState === 0 || btnState === 2) { //0=敬请期待 1=立即领取 2=已领取(抽劵没有查看详情 福利大发送是查看详情 ) 3=未持有
            return;
        }
        // const currentState = 0;// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
// const currentOperate = 0 //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 100=影藏款直接领取
        if (currentState === 1 && currentOperate === 0) { //报名阶段未报名 去报名
            goBaoMingClick && goBaoMingClick();
            return;
        }
        let param = {
            clientId: userInfo.clientId,
            getType: item.collectionType,
            activityCode: 'APEW1001',
        }
        Services('MainPage', {code: '600686'}, param).then((res) => {
            if (res.status > 0) {
                Toast.show('领取成功')
                duihuanSuccess && duihuanSuccess();
            }
        });
    }

    const redeemSignView = () => { //积分兑换抽签劵+1 签到领取加1
        // JsBridge.print('------duihuanArr--------', JsBridge.print(JSON.stringify(dataSouece.duihuanArr)))
        return dataSouece.duihuanArr.map((item, index) => {
            return (
                <div className={styles.duihuan_father_top} key={index.toString()}>
                    <span className={styles.span_des}>{item.title}</span>
                    <span className={styles.span_second_des}>{item.secondTitle}</span>
                    <div className={styles.btn_duihuan_father} onClick={() => duihuanQiandaoClick(index)}>
                        <img src={item.img} alt=""/>
                        <div className={styles.btn_duihuan__des_father}>
                            <span
                                className={item.style}>{item.btnTitle}</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const chouJuanView = () => {
        return dataSouece.desCollection.map((item, index) => {
            return (
                <div className={styles.choujuanfanbei_list} key={index.toString()}>
                    <img src={egg_bg_choujuan} alt=""/>
                    <div className={styles.choujuanfanbei_list_content}>
                        <div className={styles.choujuanfanbei_list_content_left}>
                            <span className={styles.span_des}>+</span>
                            <span className={styles.span_des1}>{item.id === 0 ? 5 : 2}</span>
                        </div>
                        <div className={styles.span_title_father}>
                            <span className={styles.span_title}>{item.title}</span>
                            <span className={styles.span_title1}>{item.caption}</span>
                        </div>
                        <div className={styles.btn_father} onClick={() => lingquChoujiangJuan(index)}>
                            <img src={item.img} alt=""/>
                            <div className={styles.btn_txt_father}>
                                <span
                                    className={item.style}>{item.btnTitle}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            <div className={styles.duihuan_father}>
                {redeemSignView()}
            </div>
            <div className={styles.choujuanfanbei_father}>
                <span className={styles.span_title}>用户持有指定藏品抽签券翻倍！</span>
                <span className={styles.span_des}>未持有指定藏品的用户快找好友转赠吧</span>
            </div>
            <div className={styles.choujuanfanbei_list_father}>
                {chouJuanView()}
            </div>
            < EggJiFenDialog maskVisible={showDuiHuanView} setMaskVisible={setShowDuiHuanView} userInfo={userInfo}
                             duihuanSuccess={duihuanSuccess} dataSouece={dataSouece}/>
        </>
    )
}

//福利大放送
const WealComponent = (props) => {
    const [fuliMaskVisible, setFuliMaskVisible] = useState(false) //福利大发送
    const {userInfo, jieduanInfo, remainingPurch} = useContext(Context);
    let {currentState, currentOperate, stock} = jieduanInfo;

    let {isLogin} = userInfo;
    //  currentState// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
    let btnTitle_fl0 = '敬请期待'; //福利1
    let btnTitle_fl1 = '敬请期待'; //福利2
    let img = egg_bt_hui;
    let style = styles.span0_hui;
    if (isLogin) {

    } else { //未登录
        if (currentState === 0) {
            btnTitle_fl0 = '敬请期待';
            btnTitle_fl1 = '敬请期待';
            img = egg_bt_hui;
            style = styles.span0_hui;
        } else if (currentState === 1 || currentState === 2) {
            btnTitle_fl0 = '敬请期待';
            btnTitle_fl1 = '敬请期待';
            img = egg_bt_hui;
            style = styles.span0_hui;
        } else if (currentState === 3 || currentState === 4 || currentState === 5 || currentState === 6) {
            btnTitle_fl0 = '立即领取';
            btnTitle_fl1 = '查看详情';
            img = egg_bt_yes;
            style = styles.span0;
        }
    }


    let arr = [
        {
            title: '绿地Ape World x1 ',
            caption: '限持有隐藏款用户领取\n请在10月12日10点前完成领取，过期作废',
            btnTitle: btnTitle_fl0,
            id: 0,
            holdState: '0',
            collectionType: '0',
            buttonState: '0', //0=敬请期待 1=立即领取 2=查看详情 3=未持有
            img: img,
            style: style
        },
        {
            title: '惊喜空投！绿地Ape World 套组',
            caption: '限持有无聊猿典藏款用户领取，平台将在\n10月9日10:00直接发放至用户账户',
            btnTitle: btnTitle_fl1,
            id: 1,
            holdState: '0',
            collectionType: '0',
            buttonState: '0',
            img: img,
            style: style
        },
    ];

    const [fuliData, setFuliDataSource] = useState(arr);

    useEffect(() => {
        getWealData();
    }, [userInfo, jieduanInfo])

    const getWealData = () => {
        if (userInfo.isLogin) {
            Services('MainPage', {code: 600683}, {'clientId': userInfo.clientId}).then((res) => {
                if (res.status > 0) {
                    let welfareCollection = res.data.welfareCollection;
                    for (let i = 0; i < welfareCollection.length; i++) {
                        let item = welfareCollection[i];
                        let btnState = parseInt(item?.buttonState) || 0;
                        if (btnState === 1) {
                            item.btnTitle = '立即领取';
                            item.img = egg_bt_yes;
                            item.style = styles.span0;
                        } else if (btnState === 2) {
                            item.btnTitle = '查看详情';
                            item.img = egg_bt_yes;
                            item.style = styles.span0;
                        } else if (btnState === 3) {
                            item.btnTitle = '未持有';
                            item.img = egg_bt_hui;
                            item.style = styles.span0_hui;
                        } else { // buttonState: '0', //0=敬请期待 1=立即领取 2=查看详情 3=未持有
                            item.btnTitle = '敬请期待'
                            item.img = egg_bt_hui;
                            item.style = styles.span0_hui;
                        }
                    }
                    setFuliDataSource(welfareCollection);
                }
            });
        } else { //未登录
            let btnTitle_fl00 = '敬请期待'; //福利1
            let btnTitle_fl11 = '敬请期待'; //福利2
            let img_0 = egg_bt_hui;
            let style_0 = styles.span0_hui;
            //未登录
            if (currentState === 0) {
                btnTitle_fl00 = '敬请期待';
                btnTitle_fl11 = '敬请期待';
                img_0 = egg_bt_hui;
                style_0 = styles.span0_hui;
            } else if (currentState === 1 || currentState === 2) {
                btnTitle_fl00 = '敬请期待';
                btnTitle_fl11 = '敬请期待';
                img_0 = egg_bt_hui;
                style_0 = styles.span0_hui;
            } else if (currentState === 3 || currentState === 4 || currentState === 5 || currentState === 6) {
                btnTitle_fl00 = '立即领取';
                btnTitle_fl11 = '查看详情';
                img_0 = egg_bt_yes;
                style_0 = styles.span0;
            }


            let arrNew = [
                {
                    title: '绿地Ape World x1 ',
                    caption: '限持有隐藏款用户领取\n请在10月12日10点前完成领取，过期作废',
                    btnTitle: btnTitle_fl00,
                    id: 0,
                    holdState: '0',
                    collectionType: '0',
                    buttonState: '0', //0=敬请期待 1=立即领取 2=查看详情 3=未持有
                    img: img_0,
                    style: style_0
                },
                {
                    title: '惊喜空投！绿地Ape World 套组',
                    caption: '限持有无聊猿典藏款用户领取，平台将在\n10月9日10:00直接发放至用户账户',
                    btnTitle: btnTitle_fl11,
                    id: 1,
                    holdState: '0',
                    collectionType: '0',
                    buttonState: '0',
                    img: img_0,
                    style: style_0
                },
            ];
            setFuliDataSource(arrNew);
        }
    }

    const fuliClick = (index) => { //buttonState: '0', //0=敬请期待 1=立即领取 2=查看详情 3=未持有
        if (currentState === 0 || currentState === 1 || currentState === 2) {
            return;
        }
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                props.refreshData && props.refreshData();
            });
            return
        }

        let item = fuliData[index];
        let buttonState = item['buttonState'];
        let btnState = parseInt(buttonState) || 0;
        if (btnState === 1) { //立即领取
            jieduanInfo.currentOperate = 100;
            goApeWorldSaleDetail(userInfo, jieduanInfo, 0, props.refreshData);
        } else if (btnState === 2) { //查看我的藏品
            goApeWorldSaleDetail(userInfo, jieduanInfo, 1, props.refreshData);
        }
    }

    const fuliView = () => {
        return fuliData.map((item, index) => {
            return (
                <div className={styles.fuli_list_father} key={index.toString()}>
                    <img src={egg_bg_kuang} alt=""/>
                    <div className={styles.fuli_list_content}>
                        <div className={styles.span_title_father}>
                            <span className={styles.span_title}>{item.title}</span>
                            <span className={styles.span_title1}>{item.caption}</span>
                        </div>
                        <div className={styles.btn_father} onClick={() => fuliClick(index)}>
                            <img src={item.img} alt=""/>
                            <div className={styles.btn_txt_father}>
                                <span className={item.style}>{item.btnTitle}</span>
                            </div>
                        </div>
                    </div>

                </div>
            )
        })
    }
    return (
        <>
            <div className={styles.span_fuli_title}>福利大放送</div>
            <div>
                {fuliView()}
            </div>
            <EggDialog maskVisible={fuliMaskVisible} setMaskVisible={setFuliMaskVisible}/>
        </>
    )
}

const BottomLogoComponent = (props) => {
    return (
        <div className={styles.logo_father}>
            <img src={egg_logo} className={styles.egg_logo}/>
        </div>
    )
}

const JingXiKongTouComponent = () => { //惊喜空投的弹窗 20221017 结束
    const [jiXiVisible, setJiXiVisible] = useState(false) //惊喜空投
    const jingxi_air = 'jingxi_air';
    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        let date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate();
        if (year !== 2022) {
            return
        }
        //是今年
        if (month !== 9 && month !== 10) {
            return;
        }
        //九月 10月
        if (month === 10 && day > 16) { //结束日
            return;
        }
        let randonString = getYMRTimeString();
        const key = localStorage.getItem(jingxi_air)
        if (isEmptyString(key)) { //首次
            localStorage.setItem(jingxi_air, randonString)
            setJiXiVisible(true);
        } else {
            if (key !== randonString) { //不同天
                localStorage.setItem(jingxi_air, randonString)
                setJiXiVisible(true);
            } else { //同一天
                setJiXiVisible(false);
            }
        }
    }

    return (
        <>
            <EggAirDropDialog maskVisible={jiXiVisible} setMaskVisible={setJiXiVisible}/>
        </>
    )
}

const goApeWorldSaleDetail = (userInfo, jieduanInfo, type, refreshData) => { //type=0去详情 1=我的藏品
    const {isLogin} = userInfo;
    const {currentState, currentOperate, remainingPurch, stock} = jieduanInfo;

    JsBridge.addListener().then(() => { //查看详情回来
        if (refreshData) {
            refreshData();
        }
    });

    if (!isLogin) {
        if (type === 0) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/apeWorldSaleDetail?currentState=${currentState}&currentOperate=${currentOperate}&remainingPurch=${remainingPurch}&stock=${stock}&isLogin=0`;
            JsBridge.push('CustomWeb', {uri: uri});
            clearAllTimer();
        } else if (type === 1) { //我的藏品列表
            Toast.show('您当前未登录,请先登录');
        }
    } else { //登录了
        if (type === 0) {
            Services('MainPage', {code: 600066}, {source: 'app'}).then((res) => {
                const {status, data} = res;
                if (status > 0) {
                    let token = data.token;
                    if (!isEmptyString(token)) {
                        let uri = `${process.env.REACT_APP_NFT_LINK}/apeWorldSaleDetail?currentState=${currentState}&currentOperate=${currentOperate}&remainingPurch=${remainingPurch}&t=${token}&stock=${stock}&isLogin=1`;
                        JsBridge.push('CustomWeb', {uri: uri});
                        clearAllTimer();
                    }
                }
            });
        } else if (type === 1) {
            Services('MainPage', {code: 600066}, {source: 'app'}).then((res) => {
                const {status, data} = res;
                if (status > 0) {
                    let token = data.token;
                    if (!isEmptyString(token)) {
                        let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?t=${token}`;
                        JsBridge.push('CustomWeb', {uri: uri});
                        clearAllTimer();
                    }
                }
            });
        }
    }
}
const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
}
