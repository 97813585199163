import Yifuli from './home';
import InviteRecord from './inviteRecord';
import YFLInviteSuccess from './yflInviteSuccess';
import YFLInvite from './yflInviteRegister';

export default {
  Yifuli,
  InviteRecord,
  YFLInviteSuccess,
  YFLInvite
}