import { Hotel } from './hotel';
import { Travel } from './travel';
import { YSJDetail } from './kangyang/ysjdetail';
import { KangYangJu } from './kangyang/kangyangju';
import { IntroduceMore } from './kangyang/introduce-more';
import { BaseDetail } from './kangyang/basedetail';
import { EditAddress } from './editAddress';
import JsBridge from './jsbridge';
import AddressList from './address/addressList';
import AddressEdit from './address/addressEdit';
import ActivityOrder from './activityOrder';

export const Routes = [
    {
        path: '/front/guApp/hotel',
        component: Hotel,
        title: '酒店',
    },
    {
        path: '/front/guApp/travel',
        component: Travel,
        title: '旅游'
    },
    {
        path: '/front/guApp/kangyang/ysjDetail',
        component: YSJDetail,
        title: '颐尚居'
    },
    {
        path: '/front/guApp/kangyang/kangyangju',
        component: KangYangJu,
        title: '康养'
    },
    {
        path: '/front/guApp/kangyang/introduce-more',
        component: IntroduceMore,
        title: '颐尚居服务详情'
    },
    {
        path: '/front/guApp/kangyang/basedetail',
        component: BaseDetail,
        title: '基地详情'
    },
    {
        path: '/front/guApp/jsbridge',
        component: JsBridge,
        title: '桥接demo'
    },
    {
        path: '/front/guApp/editAddress',
        component: EditAddress,
        title: '填写地址'
    },
    {
        path: '/front/guapp/address/addressList',
        component: AddressList,
        title: '收货地址'
    },
    {
        path: '/front/guapp/address/addressEdit',
        component: AddressEdit,
        title: '编辑收货地址'
    },
    {
        path: '/front/guapp/activityOrder',
        component: ActivityOrder,
        title: '确认订单'
    }
];
