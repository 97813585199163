/**
 * @description nft超级分享官相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const { JsBridge } = Library;

const activityCode = 'INVITECLIE2';

class Service {

    /**
    * @description 初始化接口
    * @interface 600828
    * @param {activityCode: 活动编码} params
    * @param { 是否显示加载loading } showLoading
    * @returns {
    * 
   */
    static getInitData = async (showLoading = true) => {
        const code = '600828'
        try {
            const params = {
                activity: activityCode,
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.data||res.data };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 邀新列表接口
     * @interface 101340
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params={})=>{
        const code = '101340'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 100,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取奖励接口
     * @interface 600829
     * @param {activity: 活动编码 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static receivePrize = async (params={}, showLoading=true)=>{
        const code = '600829'
        try {
            const body = {
                activity: activityCode,
                ...params
            }
            
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    

}

Service.activityCode = activityCode;
export default Service;