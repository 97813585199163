import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import Services from '../../services';


const navMap = ['汇总', '地区统计表'];


export default (props) => {
    const [data, setData] = useState(null);
    const [nav, setNav] = useState(0);
    const history = useHistory();
    const isStaff = useSelector((state) => {
        return state.user.toJS().isStaff;
    });

    useEffect(() => {
        if (!isStaff) {
            history.goBack();
        } else {
            getDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [couponList, setCouponList] = useState([]);

    useEffect(() => {
        const list = [];
        data && data.list.forEach((item) => {
            if (!list[item.biz_type]) {
                list[item.biz_type] = { list: [], name: item.couponTypeName };
            }
            let amountDisplay = item.biz_type !== 2 ? (item.amount || '') + '元' : (item.amount || '') + '%';

            if (item.biz_type === 1 && item.amount <= 0) {
                amountDisplay = '礼品兑换';
            }
            item.amountDisplay = amountDisplay
            list[item.biz_type].list.push(item);
        });

        setCouponList(list.map((item, key) => {
            const total = { count1: 0, count2: 0, totalAmount: 0, amountDisplay: '合计' };
            item.list.forEach((item2, key2) => {
                total.count1 += item2.count1;
                total.count2 += item2.count2;
                total.totalAmount += item2.totalAmount;
            });
            if (item.list.length > 1) {
                item.list.push(total);
            }
            return item;
        }));

    }, [data]);


    const getDetails = async () => {
        const result = await Services('Coupon', 'Statistic', { activityId: 2 });
        if (result) {
            setData(result.data);
        }
    };
    if (data) {
        return <div className={styles.analyse}>
            <div className={styles.analyseNav}>
                <ul>
                    {navMap.map((item, key) => {
                        return <li key={key} onClick={() => { setNav(key) }}>
                            <span className={key === nav ? styles.navActive : styles.navEmpty} >{item}</span>
                        </li>;
                    })}
                </ul>
            </div>
            <div className={styles.analyseContainer}>
                {nav === 0 ? <><div className={styles.table}>
                    <div className={styles.thead} >
                        <div className={styles.tr} >
                            <div className={styles.th}>类目</div>
                            <div className={styles.th}>数量</div>
                        </div>
                    </div>
                    <div className={styles.tbody} >
                        <div className={styles.tr} >
                            <div className={styles.th} >消费券礼包总领取份数</div>
                            <div className={styles.td} >{data.totalCount}</div>
                        </div>
                        {/* <div className={styles.tr} >
                            <div className={styles.th} >消费券礼包当日新增领取人数</div>
                            <div className={styles.td} >{data.todayCount}</div>
                        </div> */}
                    </div>
                </div>

                    <div className={styles.table} >
                        <div className={styles.thead} >
                            <div className={styles.tr} >
                                <div className={styles.th3} >分类</div>
                                <div className={styles.th2} >消费券“使用”<br />页面打开人数</div>
                                <div className={styles.th} >已核销券<br />（张）</div>
                                <div className={styles.th} >已核销券<br />（金额）</div>
                            </div>
                        </div>
                        <div className={styles.tbody} >
                            {couponList.map((item, key) => {

                                return item.list.sort((a, b) => b.amount - a.amount).map((item2, key2) => {


                                    return <div key={key2} className={key2 > 0 ? `${styles.tr} ${styles.trMin}` : `${styles.tr} ${styles.trFirst}`} >
                                        {key2 > 0 ? <div className={styles.th3} ></div> : <div className={styles.th3} >{item.name}</div>}
                                        <div className={styles.td2} >{item2.count1}</div>
                                        <div className={styles.td} >{item2.count2}</div>
                                        <div className={styles.td} >{item2.totalAmount}</div>
                                    </div>;
                                });
                            })}
                        </div>
                    </div></> : false}


                {nav === 1 ? <><div className={styles.table} >
                    <div className={styles.thead} >
                        <div className={styles.tr} >
                            <div className={styles.th} >省/直辖市</div>
                            <div className={styles.th} >消费券礼包<br />领取份数</div>
                            <div className={styles.th} >比例</div>
                        </div>
                    </div>
                    <div className={styles.tbody} >
                        {data.list2 && data.list2.filter(a => a.province !== '未知').sort((a, b) => b.count - a.count).map((item, key) => {
                            return <div key={key} className={styles.tr} >
                                <div className={styles.th} >{item.province}</div>
                                <div className={styles.td} >{item.count}</div>
                                <div className={styles.td} >{parseFloat(item.count / data.totalCount * 100).toFixed(2)}%</div>
                            </div>;
                        })}
                    </div>
                </div>
                    <div className={styles.table} >
                        <div className={styles.thead} >
                            <div className={styles.tr} >
                                <div className={styles.th} >城市</div>
                                <div className={styles.th} >所属省/市</div>
                                <div className={styles.th} >消费券礼包<br />领取份数</div>
                                <div className={styles.th} >比例</div>
                            </div>
                        </div>
                        <div className={styles.tbody} >
                            {data.list3 && data.list3.filter(a => a.province !== '未知').sort((a, b) => b.count - a.count).map((item, key) => {
                                return <div key={key} className={styles.tr} >
                                    <div className={styles.th} >{item.city}</div>
                                    <div className={styles.td} >{item.province}</div>
                                    <div className={styles.td} >{item.count}</div>
                                    <div className={styles.td} >{parseFloat(item.count / data.totalCount * 100).toFixed(2)}%</div>
                                </div>;
                            })}
                        </div>
                    </div></> : false}


                {nav === 2 ? <><div className={styles.table} >
                    <h3>各事业部购房券核销表</h3>
                    <div className={styles.thead} >
                        <div className={styles.tr} >
                            <div className={styles.th} >事业部</div>
                            <div className={styles.th} >核销数</div>
                            <div className={styles.th} >核销金额</div>
                        </div>
                    </div>
                    <div className={styles.tbody} >
                        {(data.listDepartment || []).sort((a, b) => b.num - a.num).map((item, key) => {
                            return <div key={key} className={styles.tr} >
                                <div className={styles.th} >{item.department}</div>
                                <div className={styles.td} >{item.num}</div>
                                <div className={styles.td} >{item.totalAmount}</div>
                            </div>;
                        })}
                    </div>
                </div>

                    <div className={styles.table} >
                        <h3>各事业部购房券（礼品兑换）核销表</h3>
                        <div className={styles.thead} >
                            <div className={styles.tr} >
                                <div className={styles.th} >事业部</div>
                                <div className={styles.th} >核销数</div>
                            </div>
                        </div>
                        <div className={styles.tbody} >
                            {(data.listDepartmentGift || []).sort((a, b) => b.num - a.num).map((item, key) => {
                                return <div key={key} className={styles.tr} >
                                    <div className={styles.th} >{item.department}</div>
                                    <div className={styles.td} >{item.num}</div>
                                </div>;
                            })}
                        </div>
                    </div>

                    <div className={styles.table} >
                        <h3>各项目购房券核销表</h3>
                        <div className={styles.thead} >
                            <div className={styles.tr} >
                                <div className={styles.th} >项目</div>
                                <div className={styles.th} >核销数</div>
                                <div className={styles.th} >核销金额</div>
                            </div>
                        </div>
                        <div className={styles.tbody} >
                            {(data.listProject || []).sort((a, b) => b.num - a.num).map((item, key) => {
                                return <div key={key} className={styles.tr} >
                                    <div className={styles.th} >{item.project_name}</div>
                                    <div className={styles.td} >{item.num}</div>
                                    <div className={styles.td} >{item.totalAmount}</div>
                                </div>;
                            })}
                        </div>
                    </div>

                    <div className={styles.table} >
                        <h3>各项目购房券（礼品兑换）核销表</h3>
                        <div className={styles.thead} >
                            <div className={styles.tr} >
                                <div className={styles.th} >项目</div>
                                <div className={styles.th} >核销数</div>
                            </div>
                        </div>
                        <div className={styles.tbody} >
                            {(data.listProjectmentGift || []).sort((a, b) => b.num - a.num).map((item, key) => {
                                return <div key={key} className={styles.tr} >
                                    <div className={styles.th} >{item.project_name}</div>
                                    <div className={styles.td} >{item.num}</div>
                                </div>;
                            })}
                        </div>
                    </div>
                </> : false}

            </div>
        </div>;
    } else {
        return false;
    }

};
