/**
 * @description 2024超级分享官-邀新专区
 * @returns /front/activities/regularShare/invite-zone
*/
import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { Header, RulesModal } from 'components';
import Library from 'library';
import Service from './service';
import ActivityShareModal from 'components/activityShareModal';
import Interceptor from '../component/interceptor';
import ShopRecommend from '../component/shopRecommend';
import Styles from './index.module.scss';
const { JsBridge, Util } = Library;

const InviteZone = (props)=> {
    const [activityInfo, setActivityInfo] = useState({})

    useEffect(()=>{
        refeshData(true);
    },[])
    
    const refeshData = async (showLoading = false)=> {
        const result = await Service.getInitData(showLoading)
        
        if(result?.data) {
            setActivityInfo(result.data)
        }
        return result?.data

    }
    // 活动规则
    const onRuleClick =()=>{
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(90deg, #FF431F 0%, #FF1858 100%)'},
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }
    // 邀新记录
    const onInviterRecord = () => {
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/regularShare/invite-record`});
        }else {
            props.history.push('/front/activities/regularShare/invite-record')
        }
    }

    // 邀新送积分
    const onInvite = ()=> {
        const cuttentTime = activityInfo?.systemTime ? new Date(activityInfo?.systemTime?.replace(/-/g, '/')) : new Date();
        const isEnd = cuttentTime.getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        const param = {
            ic: activityInfo.inviteCode,
            ac: Service.activityCode
        }
        const shareUrl = `${window.location.origin}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
        ActivityShareModal.open({
            shareUrl,
            qrcodeConfig: {size: 65},
            children: <img className={Styles.shareInvite} src={require('images/invite-zone/shareBg.png')} alt="bg" />,
        })
        
    }

    const flows = [{title: '邀请新用户', img: require('images/invite-zone/user.png')},
                    {title: '新用户注册登陆', img: require('images/invite-zone/register.png')},
                    {title: `可得${activityInfo.givePoint ?? 0}积分`, img: require('images/invite-zone/point.png')},];
    return (
        <div className={Styles.invite_zone}>
            {
                JsBridge.hasWebViewBridge() && 
                <Header bgColor={'transparent'} theme={'light'} />
            }
            <div className={Styles.body_wraper}>
                <div className={Styles.bgTop}>
                    <div className={Styles.activityBtns}>
                        <div onClick={onRuleClick}>规则</div>
                        <div onClick={onInviterRecord}>邀新记录</div>
                    </div>
                </div>
                <div className={Styles.bgCenter}>
                    <div className={Styles.inner_title}>
                        {`邀请新用户成功得${activityInfo.givePoint ?? 0}积分`}
                    </div>
                    <div className={Styles.flows}>
                        <div className={Styles.dashed_box} >
                        {
                            Array(flows.length - 1).fill(1).map((_,k) => {
                                return (
                                    <div className={Styles.dashed_line} key={k}></div>
                                )
                            })
                        }
                        </div>
                        {
                            flows.map((flow,k) => {
                                return (
                                    <div className={Styles.flow} key={k}>
                                        <img src={flow.img} alt="" />
                                        <span>{flow.title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={Styles.inviteBtn} onClick={onInvite}>
                        <img src={require('images/invite-zone/invite-now.png')} alt="" />
                    </div>
                </div>
                {/* 邀新领好礼 */}
                <ShopRecommend
                    dataSource={activityInfo} 
                    onRefesh={(loading)=>{
                        refeshData(loading)
                    }}
                />
                {/* 底部logo */}
                <div className={Styles.footer_logo}>
                    <img src={require('images/invite-zone/bottom-logo.png')} alt="" />
                </div>
                <Interceptor />
            </div>
        </div>
    )
}

export {
    InviteZone
}
 
