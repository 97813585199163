import React, { useEffect, useRef, useState} from 'react';
import styles from './gather.module.scss';
import img_jihe from "./img/waic-jihe.png";
import Services from "../../../services";
import lijiPay from './img/waic-liji-pay.png'; //立即购买
import jijiangPay from './img/waic-jijiang-sale.png';//即将开售
import activityFinish from './img/waic-activity-finish.png';//活动结束
import todayFinish from './img/waic-today-finish.png'; //今日已售罄
import daojishiPay from './img/waic-daojishi.png';//倒计时
import renzhenIcon from './img/icon_renzhen.png';
import Library from "../../../library";
const {JsBridge} = Library;

export const Gather = () => {
    const [show, setShow] = useState(false);
    const [ruleTitle, setRuleTitle] = useState('');
    const [ruleContent, setRuleContent] = useState('');
    const [isVerify, setIsVerify] = useState(false);
    let currentTimeStamp = useRef(null).current;
    const timeId = useRef();

    const [saleObj, setSaleObj] = useState({
        period: "",
        availableStatus: -1, //0=立即购买 1=即将开售 2=活动结束 3=今日已售罄 4=倒计时
        startTime: "2022-08-20 00:00:10",
        endTime: "",
        detailsPageUrl: "",
        systemTime: "2022-08-20 00:00:00",
        productType: "0",
        salesTime: ""
    });
    const [count, setCount] = useState({
        hours: '00',
        minutes: '00',
        seconds: '00'
    });

    useEffect(() => {
        onDidFocus();
        handleLogin();
        getProtocol();
    }, [])

    const handleLogin = () => {
        JsBridge.getCurrentUserInfo().then((res) => {
            if (res && res.clientId) { //登录
                loadData();
                if (!isVerify) {
                    JsBridge.verifyRealName('购买数字藏品，请提前完成实名认证。').then((res) => {
                        if (res.result) {
                            setIsVerify(res.result);
                        }
                    });
                }
            } else { //非登录
                if (!isVerify) {
                    JsBridge.verifyRealName('购买数字藏品，请提前完成实名认证。').then((res) => {
                        if (res.result) {
                            setIsVerify(res.result);
                        }
                    });
                }
            }
        })
    }


    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            handleLogin();
            onDidFocus();
        });
    }

    useEffect(() => {
            if (saleObj.availableStatus === 4) {
                timeId.current = setInterval(() => {
                    if (currentTimeStamp) {
                        currentTimeStamp = currentTimeStamp + 1000;
                    }
                    let obj = countDown(saleObj.systemTime, saleObj.startTime, () => {
                        timeId.current && clearInterval(timeId.current);
                        let obj = saleObj;
                        obj.availableStatus = 0;
                        setSaleObj(obj);
                    });
                    setCount({
                        hours: obj.hour,
                        minutes: obj.minute,
                        seconds: obj.second,
                    });
                }, 1000)
            } else {
                timeId.current && clearInterval(timeId.current);
            }
            return () => {
                timeId.current && clearInterval(timeId.current);
            }
        }
        ,
        [saleObj]
    )

    const countDown = (currentTime, startTime, callBack) => {
        if (!currentTime || !startTime) {
            callBack && callBack()
            return {
                hour: '00',
                minute: '00',
                second: '00'
            }
        }

        if (currentTimeStamp == null) {
            const current = Date.parse(currentTime.replace(/-/g, '/'))
            currentTimeStamp = current
        }
        const endTime = Date.parse(startTime.replace(/-/g, '/'));
        const distance = endTime - currentTimeStamp;
        if (distance <= 0) {
            callBack && callBack()
            return {
                hour: '00',
                minute: '00',
                second: '00'
            }
        }
        const hour = Math.floor((distance / 1000 / 60 / 60))
        const minute = Math.floor((distance / 1000 / 60) % 60)
        const second = Math.floor((distance / 1000) % 60)
        console.log(distance)
        return {
            hour: hour < 10 ? `0${hour}` : hour,
            minute: minute < 10 ? `0${minute}` : minute,
            second: second < 10 ? `0${second}` : second
        }
    }

    const loadData = async () => { //必须要登录
        const res = await Services('MainPage', {code: '600069'}, {})
        if (res.status > 0) {
            const {ktNftAggregateList} = res.data;
            let productType = '0';
            if (ktNftAggregateList && ktNftAggregateList.length > 0) {
                for (let i = 0; i < ktNftAggregateList.length; i++) {
                    let item = ktNftAggregateList[i];
                    if (item.productType.toString() === productType) {
                        setSaleObj(item)
                        break;
                    }
                }
            }
        }
    }

    const getProtocol = async () => { //获取协议
        const res = await Services('MainPage', {code: '101193'}, {
            activityCode: 'NFTPURCHAS',
            codeType: 'activityRule',
            code: 'nftPurchas88Rule'
        })
        if (res.status > 0) {
            const {data} = res
            setRuleContent(data.activityRule.otherSign)
            setRuleTitle(data.activityRule.codeAlias)
        }
    }

    const clickRule = () => {
        setShow(!show)
    }
    const verify = () => { //实名认证
        JsBridge.verifyRealName('购买数字藏品，请提前完成实名认证。').then((res) => {
            if (res.result) {
                setIsVerify(res.result);
            }
        });
    }

    const goBack = () => {
        JsBridge.back();
    }

    const nextStep = () => {
        let availableStatus = saleObj.availableStatus;
        if (availableStatus === 0) { //立即购买
            JsBridge.verifyRealName('购买数字藏品，请提前完成实名认证。').then((res) => {
                if (res.result) {
                    if (saleObj.detailsPageUrl) {
                        JsBridge.push('CustomWeb', {uri: saleObj.detailsPageUrl});
                    }
                }
            });
        }
    }

    const btnStatus = () => {
        let availableStatus = saleObj.availableStatus;
        if (availableStatus === 0) {
            return lijiPay;
        } else if (availableStatus === 1) {
            return jijiangPay;
        } else if (availableStatus === 2) {
            return activityFinish;
        } else if (availableStatus === 3) {
            return todayFinish;
        } else if (availableStatus === 4) {
            return daojishiPay;
        }
    }
    let availableStatus = saleObj.availableStatus;
    return (
        <div className={styles.container}>
            <img className={styles.pic} src={img_jihe} alt=""/>
            <div className={styles.back_btn} onClick={() => goBack()}></div>
            <div className={styles.pic_father}>
                <div className={styles.top_father}>
                    <div className={styles.top_father_left_rule}
                         onClick={() => clickRule()}/>
                    {isVerify ? <div/> : <div className={styles.top_father_right_renzhen}
                                              onClick={() => verify()}>
                        <img src={renzhenIcon}/>
                        <span>实名认证</span>
                    </div>}
                </div>
            </div>
            <Rule ruleTitle={ruleTitle} ruleContent={ruleContent} show={show} handleClose={() => setShow(false)}/>
            {
                availableStatus === 4 ?
                    <div className={styles.button_father_daojishi}>
                        <div className={styles.button_father_daojishi1}>
                            <span>{`${count.hours}:${count.minutes}:${count.seconds} 开售`}</span>
                        </div>
                    </div> : <div className={styles.button_father} onClick={() => nextStep()}>
                        <img src={btnStatus()}/>
                    </div>
            }
        </div>
    )
}

// 活动活动
const Rule = ({ruleTitle, ruleContent, show, handleClose}) => {
    if (!show) return null;
    return (
        <div className={styles.mask_rule}>
            <div className={styles.content}>
                <div className={styles.title}>{ruleTitle}</div>
                <div className={styles.text}>{ruleContent}</div>
                <div className={styles.close} onClick={handleClose}>
                    <span className={styles.mask_rule_kown}>我知道了</span>
                </div>
            </div>
        </div>
    )
}

