/**
 * @title 活动 领奖记录：
 * @route /front/activities/awardRecord?activityCode=child2023&apiCode=123456
*/

import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'antd-mobile';
import { Header, ListView } from 'components';
import services from 'services';
import Library from "library";
import styles from './styles.module.scss';

const { JsBridge, Util } = Library;

/**
 * 获取领取奖励的记录列表
 * @param {String} body.activityCode 活动code
 * @param {String} body.code 接口 code
 * ***/
const fetchRecordList = async (params = {
  activityCode: '',
  code: '',
  pageNum: 1,
}) => {
  const { code, ...body } = params;
  try {
    const res = await services('MainPage', { code }, body, true);
    if (res.status > 0) {
        return res;
    }else {
        throw res.message || '请求失败'
    }
  } catch (error) {
      !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
  }
}


export default (props) => {
  // 从 url 中获取 apiCode, activityCode
  const { apiCode, activityCode, idf } = React.useMemo(Util.url.paramsToObj,[props.history.location.search]);
    const currentPage = useRef(1)
    const [dataList, setDataList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)

    const navigateTo = (path = '/') => {
      if (window.JsBridge.hasWebViewBridge()) {
        JsBridge.push('CustomWeb', { uri: window.location.origin + path });
      } else {
        props.history.push(path);
      }
    }

    useEffect(() => {
      onRefresh();
    }, []);

    const onDidFocus = () => {
      JsBridge.addListener().then(() => {
        onRefresh();
      });
    }

    const onRefresh = async () => {
      currentPage.current = 1;
      setIsLoading(true);
      const res = await fetchRecordList({ code: apiCode, activityCode });
      setIsLoading(false);
      if (res?.status === 1 && res?.data) {
        const { list = [] } = res.data;
        setDataList(list);
        setHasMore(list.length >= 10);
      }
    }

    const loadMore = async () => {
      const res = await fetchRecordList({ code: apiCode, activityCode, pageNum: currentPage.current + 1 });
      if (res?.status === 1 && res?.data) {
        const { list = []} = res.data;
        setDataList([...dataList, ...list]);
        setHasMore(list.length >= 10)
        currentPage.current = currentPage.current + 1;
      }
    }

    const addOrChangeAddress = (item) => {
      if(item.remark == 'wanmi') {
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-C/order/order-list/index?status=ALL-ORDER&isFromApp=1`})
      } else if(item.address) {
        const param = Util.url.objectToQueryParams(item)
        navigateTo(`/front/activities/lottery/orderDetail?${param}`);
      } else {
        const param = Util.url.objectToQueryParams({
          idf,
          activityCode,
          req: item.id,
          name: item.prizeName,
          imgUrl: item.prizeImgUrl
        });
        navigateTo(`/front/guapp/activityOrder?${param}`);
        onDidFocus();
      }
    }

    const renderItem = (item)=> {

        return (
            <div className={styles.record_itemContainer}>
                <div className={styles.itemLeft}>
                    <div className={styles.itemTop}>
                        <div className={styles.itemTopTitle}>
                            {`${item?.prizeName}`}
                        </div>
                    </div>
                    <div className={styles.itemTime}>
                        {`${item?.createTime}`}
                    </div>
                </div >
                {
                  item?.prizeType === 3 && 
                    <div className={styles.itemAddressBtn}>
                      {
                        item.remark == 'wanmi' ?
                        <div className={styles.itemAddressBtnTitle} onClick={() => {addOrChangeAddress(item)}}>
                            {`查看领取奖品详细信息`}
                        </div>:
                        <div className={styles.itemAddressBtnTitle} onClick={() => {addOrChangeAddress(item)}}>
                          {item.address ? `查看领取奖品详细信息` : `填写地址领取奖品`}
                      </div>
                      }
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={styles.receive_record_page}>
            <Header midText={'领取记录'} shadow />
            <div className={styles.container}>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    onRefresh={onRefresh}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}