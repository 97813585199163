import React, { Fragment, useMemo } from "react";
import styles from './styles.module.scss';

export default (props) => {

  const {activityInfo={}} = props

  const Steps = useMemo(()=>{
    const item1 = {
        stepCount: 20,
        productId: activityInfo.lowLevelProduct,
        status: activityInfo.lowLevelStatus,
        stock: activityInfo.lowLevelCount,
        type: 'lowLevelProduct'
    }
    const item2 = {
        stepCount: 50,
        productId: activityInfo.highLevelProduct,
        status: activityInfo.highLevelStatus,
        stock: activityInfo.highLevelCount,
        type: 'highLevelProduct',
    }
    return [item1,item2];
  },[activityInfo])

  const progress = useMemo(()=>{
      const count = activityInfo.thumbsUpAmount || 0
      const totle = Steps[Steps.length - 1]
      if(totle.stepCount > count) {
          return count / totle.stepCount * 100
      }
      return 100
  },[activityInfo.thumbsUpAmount])

  return (
    <div className={styles.container}>
      <div className={styles.progressBar}>
        <div className='bar' style={{ width: `${progress}%` }} />
        {
          Steps.map((step,k)=>{
            return (
              <Fragment key={k}>
                {
                  (activityInfo.thumbsUpAmount||0) < step.stepCount ?
                  <div className={`plan plan${k}`}>
                    <span>{activityInfo.thumbsUpAmount||0}/{step.stepCount}</span>
                  </div>
                  :
                  <>
                  {
                    step.status ?
                    <div className={`disable disable${k}`}>
                      <span>已领取</span>
                    </div>:
                    <div className={`btn btn${k}`}>
                      <span onClick={()=>props.onReceive(step)}>立即领取</span>
                    </div>
                  }
                  </>
                }
              </Fragment>
            )
          })
        }
      </div>
    </div>
  )
}