
import styles from './vote.module.scss';
import React from 'react';
export const Rules = () => {
    return <div className={styles.rulesContainer}>
        <div className={styles.ruleContent}>
            <p><span>投票参与规则：</span></p>
            <p><span>①活动期间，每人每天可投3票；</span></p>
            <p><span>②由绿地G优项目确定获奖人并启用口号；</span></p>
            <p><span>③口号启用时将公布获奖名单并通知获奖人员、发放奖品；</span></p>
            <p><span>④请大家理性投票，谢绝舞弊行为。</span></p>
            <p><span>&nbsp;</span></p>
            <p><span>活动投票时间：2021年5月24日-2021年5月28日</span></p>
            <p><span>最终结果公布时间：2021年5月31日</span></p>
            <p><span>&nbsp;</span></p>
            <p><span>*请相关获奖用户留意微信公众号“绿地G优尊享会”后台发布的获奖通知，谢谢配合！</span></p>
        </div>
    </div>
}