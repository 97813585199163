
import React, { useEffect, useState } from "react";
import Library from 'library';
import services from 'services';
import { Actions } from 'reduxs';
import picClose from 'images/kangyang/general-btn-ovalclose@2x.png';
import styles from './index.module.scss';
const { Fetch, Platform, JsBridge, Util } = Library;

export const IntentionCommit = (props) => {
    const [mobile, setMobile] = useState('');
    const [isFoucs, setIsFocus] = useState(false);
    //设置input对应值
    const changeFieldValue = (event) => {
        let value = event && event.target.value;
        setMobile(value);
    };
    useEffect(() => {
        if (Platform.name() === 'GuApp' && props.visible && mobile.length === 0) {
            getMobile();
        }
    }, [props.visible]);

    const getMobile = async () => {
        const result = await JsBridge.runServices('User', 'Center', [{}]);
        if (result.status > 0) {
            let { account } = result.data;
            setMobile(account);
        }
    }

    return <div className={styles.intentionContainer}
        style={{ display: props.visible ? 'flex' : 'none' }}
    >
        <div className={styles.mask} onClick={() => {
            props.onHide && props.onHide();
        }} />
        <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.titleBG}>
                    <a className={styles.title}>提交意向</a>
                </div>
                <img className={styles.close} src={picClose} onClick={() => {
                    props.onHide && props.onHide(false);
                }} />
            </div>
            <div className={styles.view}>
                <a className={styles.text1}>我对康养居有兴趣，愿意进一步了解情况</a>
                <a className={styles.text2}>提交意向后，我们近期将会通过电话联系您</a>
                <input className={styles.input}
                    type='tel'
                    maxLength={11}
                    value={isFoucs ? mobile : Util.format.mobileSecurity(mobile)}
                    placeholder="请输入手机号码"
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onChange={(event) => changeFieldValue(event)}
                />
                <button className={styles.buttonCommit} onClick={async () => {
                    if (!Util.validate.mobile(mobile)) {
                        Actions.alert.show('请输入正确的手机号');
                        return;
                    }
                    let result = Platform.check.isApp() ? await services('MainPage', { code: 101150 }, {
                        userCode: mobile,
                        intentionChannel: 'kangyang'
                    }) : await services('KangYang', 'IntentionCommit', {
                        userCode: mobile,
                        intentionChannel: 'kangyang'
                    });
                    if (result.status > 0) {
                        props.onHide && props.onHide(true);
                    }

                }}>确定</button>
            </div>
        </div>
    </div>
}