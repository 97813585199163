import styles from './index.module.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';

export const Loading = (props) => {
    const loading = useSelector((state) => {
        return state.loading;
    });
    return <div className={styles.loading} style={{
        display: loading.status === 'COMPLETE'
            ? 'none'
            : 'block'
    }}>
        <div className={styles.mask}></div>
        <div className={styles.loadingWrapper}>
            <ReactLoading type='bars' color='white' />
        </div>
        <h2 className={styles.loadingText}>{loading.text}</h2>
    </div>;
};
