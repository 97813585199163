import React, {useEffect, useState,} from 'react';
import styles from './yuYueDialog.module.scss';
import {Mask} from "antd-mobile";
import {useHistory} from "react-router-dom";
import {isEmptyString} from "../../../../library/util";

export const YuYueDialog = ({maskVisible, setMaskVisible, appointActivityOrderId, participateTime,isHome,userAccount}) => { //预约记录的弹窗
    const history = useHistory();
    const [date, setDate] = useState('');

    useEffect(() => {
        if (!isEmptyString(participateTime)) { //"participateTime": "2023-03-10 00:00:00.000",
            let startDateString = participateTime.replace(/-/g, '/');
            if (startDateString.indexOf('.') > -1) {
                startDateString = (startDateString.split('.'))[0];
            }
            let startTimeDate = new Date(startDateString);
            let M0 = (startTimeDate.getMonth() + 1) < 10 ? `0${(startTimeDate.getMonth() + 1)}` : `${(startTimeDate.getMonth() + 1)}`,
                D0 = startTimeDate.getDate() < 10 ? `0${(startTimeDate.getDate())}` : `${(startTimeDate.getDate())}`;
            let dateString = `${M0}月${D0}日`;
            setDate(dateString);
        }
    }, [participateTime])

    const btnClick = (index) => {
        setMaskVisible(!maskVisible);
        if (index === 1) {
            let timer = setTimeout(() => {
                clearTimeout(timer);
                let orderType = 1;
                history.push(`/front/member/appointment/appointmentSucceed`, {appointActivityOrderId, orderType,isHome,userAccount});
            }, 1000)
        }
    }

    return (
        <Mask visible={maskVisible} opacity={0.7}>
            <div className={styles.yy_dialog_container}>
                <div className={styles.yy_dialog_content}>
                    <p className={styles.yy_dialog_title}>预约提示</p>
                    <p className={styles.yy_dialog_line}>{`您已预约${date}参加活动，若行程有变请前往预约详情取消后再次预约。`}</p>
                    <div className={styles.yy_dialog_content_btns_container}>
                        <div className={`${styles.btn} ${styles.cancel}`} onClick={() => btnClick(0)}>取消</div>
                        <div className={`${styles.btn} ${styles.sure}`} onClick={() => btnClick(1)}>预约详情</div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}
