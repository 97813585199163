import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";

export async function handelVipData(code, levelId) {
    const result = await Services('MainPage', {code: code}, {levelId: levelId}, false);
    if (result.status > 0) {
        const {guHomeCustomerRightVOList = []} = result.data;
        let titles = [];
        for (const item of guHomeCustomerRightVOList) {
            if (!isEmptyString(item.rightsClass)) {
                if (titles.indexOf(item.rightsClass) === -1) {//不存在
                    titles.push(item.rightsClass);
                }
            }
        }

        let obj = [];
        for (const title of titles) {
            obj.push({rightsClass: title, list: []})
        }
        for (let i = 0; i < guHomeCustomerRightVOList.length; i++) {
            let item = guHomeCustomerRightVOList[i];
            for (let j = 0; j < obj.length; j++) {
                let item1 = obj[j];
                if (item.rightsClass === item1.rightsClass) {
                    item1.rightsClassSort = item.rightsClassSort;
                    item1.list.push({
                        rightsName: item.rightsName,
                        rightsBrief: item.rightsBrief,
                        isAppShow: item.isAppShow,
                        rightsClassSort: item.rightsClassSort,
                        rightsSort: item.rightsSort,
                        rightsImgUrl: item.rightsImgUrl,
                        rightsType:item.rightsType,
                    });
                }
            }
        }
        obj = obj.sort((a, b) => a.rightsClassSort - b.rightsClassSort);
        for (let i = 0; i < obj.length; i++) {
            let item = obj[i];
            item.list.sort((x, y) => x.rightsSort - y.rightsSort);
        }
        return obj;
    }
}
