import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Library from 'library';
import styles from './index.module.scss';
import successImage from 'images/icon/success.png';


export const TempSuccess = () => {
    const location = useLocation();
    const history = useHistory();

    const { guValidTime } = location.state || {};
    console.log(guValidTime);
    return <div className={styles.container}>
        <div className={styles.successImage}>
            <img src={successImage} alt='成功' />
        </div>
        <h1>领取成功</h1>
        <p>恭喜您成为绿地G优会员，您可前往绿地旗下各产业板块咨询升级标准，达标后您将自动升级为G优尊享会员。</p>
        <button className={styles.button} onClick={() => {
            history.goBack();
        }}>确定</button>
    </div>;
};
