import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Mask, Toast } from 'antd-mobile'
import styles from './shareModal.module.scss'
import title from '../imgs/share-title.png'
import close from '../imgs/modal-close.png'
import game from '../imgs/share-game.png'
import fuzhi from '../imgs/copy-link.png'
import download from '../imgs/download.png'
import btn from '../imgs/share-btn.png'
import btnR from '../imgs/btn-r.png'
import btnL from '../imgs/btn-l.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QRCode from "qrcode.react"

const ShareModal = ({onClose, prizeUrl, prizeName}) => {
  const [visible, setVisible] = useState(true)
  const shareLink = `${process.env.REACT_APP_GKT_LINK}/front/activities/unityPointsLottery/share`

  const cancel = () => {
    setVisible(false)
    onClose && onClose()
  }

  return <Mask visible={visible} className={styles.mask_container}>
    <div className={styles.modal_container}>
      <div className={styles.title}><img src={title} alt="" /></div>
      <div className={styles.content}>
        <img src={close} alt="" className={styles.close} onClick={cancel}/>
        <div className={styles.share_left}>
          <img className={styles.game_bg} src={game} alt="" />
          <div className={styles.prize_name}>{prizeName}</div>
          <div className={styles.prize_img}><img src={prizeUrl} alt="" /></div>
          <div className={styles.btn_box}>
            <img src={btnR} alt="" />
            <img src={btn} alt="" />
            <img src={btnL} alt="" />
          </div>
        </div>
        <div className={styles.share_right}>
          <div className={styles.code_box}>
            <QRCode size={136} value={shareLink}/>
          </div>
          <span>截图长按识别二维码快来参加活动吧！</span>
          <div className={styles.share_type}>
            <CopyToClipboard text={shareLink}
              onCopy={async () => {
              Toast.show('复制成功', 1000)
            }}>
              <div className={styles.type_item}>
                <img src={fuzhi} alt="" />
                <span>复制链接</span>
              </div>
            </CopyToClipboard>
            {/* <div className={styles.type_item}>
              <img src={download} alt="" />
              <span>截图保存图片</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </Mask>
}

ShareModal.open = ({prizeName, prizeUrl}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ShareModal 
    onClose={close}
    prizeName={prizeName}
    prizeUrl={prizeUrl}
  />, div);
}

export default ShareModal