/**
 * @description 家办权益
 * @returns /front/member/appointment/vipCardHome
 */
import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Swiper} from 'antd-mobile'
import ShareModal from './views/shareModal';
import Library from "../../../library";
import styles from "./vipCardHome.module.scss";
import {VipCardHZContent} from "./views/vipCardHZContent";

import bg_bj from './images/bg_bj.png'
import bg_hz from './images/bg_hz.png'
import bg_qy2 from './images/bg_qy2.png'
import bg_sj from './images/bg_sj.png'

// import bg_bj1 from './images/bg_bj1.png'
// import bg_hz1 from './images/bg_hz1.png'
// import bg_qy1 from './images/bg_qy1.png'
// import bg_sj1 from './images/bg_sj1.png'

import bg_kuang from './images/icon_kuang.png'
import NoticeUtil from "../../../library/util/NoticeUtil";
import {VipCardQYContent} from "./views/vipCardQYContent";
import {VipCardBJContent} from "./views/vipCardBJContent";
import {VipCardSJContent} from "./views/vipCardSJContent";
import {MemberRuleDialog} from "./views/memberRuleDialog";
import {TelDialog} from "../../../components/telDialog";

import {isEmptyArray, isEmptyObject} from "../../../library/util";
import {vipLevel} from "../utils";
import suo_img from "./images/icon_suo_qy.png";

const kClickCardLevel = 'kClickCardLevel';
const kVipLevel = 'kVipLevel';
const Context = createContext(null);
const {JsBridge,} = Library;

const images = [
    {
        card: require('./images/pic_shuijing.png'),
        bg: bg_sj,
        // bg: bg_sj1,
        id: 0,
        suo_img: require('./images/icon_suo_sj.png'),
        logoBgColor: 'linear-gradient(90deg, #16517D 0%, #D7EAF9 100%)',
        txtColor: '#16517D'
    },
    {
        card: require('./images/pic_bianjing.png'),
        bg: bg_bj,
        // bg: bg_bj1,
        id: 1,
        suo_img: require('./images/icon_suo_bj.png'),
        logoBgColor: 'linear-gradient(90deg, #A3A3A3 0%, #DFDFDF 100%)',
        txtColor: '#606060'
    },
    {
        card: require('./images/pic_heizuan.png'),
        bg: bg_hz,
        // bg: bg_hz1,
        id: 2,
        suo_img: require('./images/icon_suo_hz.png'),
        logoBgColor: 'linear-gradient(270deg, rgba(250,226,162,0.02) 0%, rgba(244,198,134,0.34) 99%)',
        txtColor: '#E8CEA0'
    },
    {
        card: require('./images/pic_qingyu.png'),
        bg: bg_qy2,
        // bg: bg_qy1,
        id: 3,
        suo_img: require('./images/icon_suo_qy.png'),
        logoBgColor: 'linear-gradient(90deg, #9BD5C1 0%, #99D3C0 100%)',
        txtColor: '#20674E'
    },
];


export const VipCardHome = () => {
    const [selIndex, setSelIndex] = useState(0) //0=水晶 1=白金 2=黑砖 3=青玉
    const [userInfo, setUserInfo] = useState({})
    const [vipLevelIndex, setVipLevelIndex] = useState(-1)
    const levelIdQY = useRef(-1);
    const levelIdHZ = useRef(-1);
    const levelIdBJ = useRef(-1);
    const levelIdSJ = useRef(-1);

    useEffect(() => {
        const params = decodeURIComponent(window.location.search.replace('?', ''));
        // const params = '{"customerLevelInfoVoList":[{"customerLevelId":1,"levelName":"水晶会员","levelBadgeImg":"https://mall-images.obs.cn-east-2.myhuaweicloud.com/88e55ee24c8191865508dbcb67c487cf.png"},{"customerLevelId":2,"levelName":"白金会员","levelBadgeImg":"https://mall-images.obs.cn-east-2.myhuaweicloud.com/dacb1f1a681dd588ea9566060de5e951.png"},{"customerLevelId":3,"levelName":"黑钻会员","levelBadgeImg":"https://mall-images.obs.cn-east-2.myhuaweicloud.com/375c43cc91c93acc75e16616aaad234c.png"},{"customerLevelId":4,"levelName":"青玉会员","levelBadgeImg":"https://mall-images.obs.cn-east-2.myhuaweicloud.com/106cc55b5df43430acc301aabb64160b.png"}],"birthday":"1992-12-26","occupation":"","contactName":"","companyName":"","occupationName":"","eduDegreeName":"","realName":"李苗苗","isBindingWeChat":0,"relationName":"","eduDegree":"","companyAddress":"","loginName":"138****3503","inviteCode":"无","ID":"3****************1","validity":false,"cellPhone":"","account":"13854163503","isLogin":true,"clientId":"9325eb0d0fe649fc85f6ca1857ea48d9"}'
        let userInfo = JSON.parse(params || '{}');
        loadData(userInfo);
        NoticeUtil.addListener(kClickCardLevel, cardLevelChange);
        NoticeUtil.addListener(kVipLevel, vipLevelHandle);
        return () => {
            NoticeUtil.removeListener(kClickCardLevel, cardLevelChange)
            NoticeUtil.removeListener(kVipLevel, vipLevelHandle)
        }
    }, [])

    const loadData = (userInfo) => {
        if (!isEmptyObject(userInfo)) {
            let customerLevelInfoVoList = userInfo.customerLevelInfoVoList || [];
            console.log('userInfo---', userInfo)
            for (const item of customerLevelInfoVoList) {
                if (item.levelName === vipLevel.qy.name) {
                    levelIdQY.current = item.customerLevelId;
                } else if (item.levelName === vipLevel.hz.name) {
                    levelIdHZ.current = item.customerLevelId;
                } else if (item.levelName === vipLevel.bj.name) {
                    levelIdBJ.current = item.customerLevelId;
                } else if (item.levelName === vipLevel.sj.name) {
                    levelIdSJ.current = item.customerLevelId;
                }
            }
            setUserInfo(userInfo);
        }
    }

    const vipLevelHandle = (index) => {
        setVipLevelIndex(index)
    }

    const cardLevelChange = (index) => {
        setSelIndex(index);
        // console.log('cardLevelChange---',index);
    }

    const itemClick = (type, item) => {
        if (vipLevelIndex > -1 && type == vipLevelIndex && item.rightsType == '3') {
            JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/family/hotelTravel/memberLevel`})
        }
    }

    const contentView = () => {//0=水晶 1=白金 2=黑砖 3=青玉
        if (isEmptyObject(userInfo)) {
            return null;
        }
        // return null
        return (
            <div>
                <div style={{display: selIndex === 0 ? 'block' : 'none'}}>
                    <VipCardSJContent focusStatus={selIndex === 0} levelId={levelIdSJ.current} userInfo={userInfo}
                                      itemClick={itemClick}/>
                </div>

                <div style={{display: selIndex === 1 ? 'block' : 'none'}}>
                    <VipCardBJContent focusStatus={selIndex === 1} levelId={levelIdBJ.current} userInfo={userInfo}
                                      itemClick={itemClick}/>
                </div>

                <div style={{display: selIndex === 2 ? 'block' : 'none'}}>
                    <VipCardHZContent focusStatus={selIndex === 2} levelId={levelIdHZ.current} userInfo={userInfo}
                                      itemClick={itemClick}/>
                </div>

                <div style={{display: selIndex === 3 ? 'block' : 'none'}}>
                    <VipCardQYContent focusStatus={selIndex === 3} levelId={levelIdQY.current} userInfo={userInfo}
                                      itemClick={itemClick}/>
                </div>
            </div>
        )
    }

    let backgroundImage = images[selIndex]['bg']
    return (
        <Context.Provider value={{userInfo}}>
            <div className={styles.vip_card_container} style={{backgroundImage: `url(${backgroundImage})`}}>
                <VipCardHeader/>
                {contentView()}
                {
                    vipLevelIndex === selIndex ? null : <TelComponent/>
                }
            </div>
        </Context.Provider>
    )
}

const VipCardHeader = (props) => { //头部
    const [maskVisible, setMaskVisible] = useState(false);
    const [defIndex, setDefIndex] = useState(-1);
    const [isVip, setIsVip] = useState(false);
    const {userInfo} = useContext(Context);
    const indexRef = useRef(0);
    const [curIndex, setCurIndex] = useState(0);

    useEffect(() => {
        !isEmptyObject(userInfo) && loadData();
    }, [userInfo])

    const loadData = () => {
        let itemTem = null;
        if (!isEmptyArray(userInfo.customerLevelInfoVoList)) {
            for (let i = 0; i < userInfo.customerLevelInfoVoList.length; i++) {
                let item = userInfo.customerLevelInfoVoList[i];
                if (item.customerLevelId === userInfo.customerLevelId) {
                    itemTem = item;
                    break;
                }
            }
        }

        if (itemTem) {
            let selIndexTem = -1;
            if (itemTem.levelName === vipLevel.sj.name) {
                selIndexTem = 0;
            } else if (itemTem.levelName === vipLevel.bj.name) {
                selIndexTem = 1;
            } else if (itemTem.levelName === vipLevel.hz.name) {
                selIndexTem = 2;
            } else if (itemTem.levelName === vipLevel.qy.name) {
                selIndexTem = 3;
            }
            if (selIndexTem > -1) {
                setIsVip(true)
                setDefIndex(selIndexTem);
                NoticeUtil.emit(kVipLevel, selIndexTem);
                onIndexChange(selIndexTem);
            }
        } else {
            setDefIndex(0);
            setIsVip(false)
        }
    }

    const goBack = () => {
        JsBridge.back()
    }

    // 分享
    const onShareMini = () => {
        const host = process.env.NODE_ENV == 'development' ? window.location.origin : process.env.REACT_APP_GKT_LINK;
        const shareUrl = `${host}/front/activities/boardroom`
        ShareModal.open({shareUrl})
    }

    const onIndexChange = (index) => {
        console.log('onIndexChange---', index)
        // console.log('NoticeUtil---',NoticeUtil)
        NoticeUtil.emit(kClickCardLevel, index);
        indexRef.current = index;
        setCurIndex(index);
        // console.log('setCurIndex---',index)
    }

    const items = () => { //0=青玉 1=黑砖 2=白金 3=水晶 customerLevelId
        let list = userInfo.customerLevelInfoVoList || [];
        // console.log('items---userInfo--',userInfo)
        if (isEmptyArray(list)) {
            return null;
        }
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (i === 3) {
                item.suo_img = require('./images/icon_suo_qy.png');
                item.logoBgColor = 'linear-gradient(90deg, #9BD5C1 0%, #99D3C0 100%)';
                item.txtColor = '#20674E';
            } else if (i === 2) {
                item.suo_img = require('./images/icon_suo_hz.png');
                item.logoBgColor = 'linear-gradient(270deg, rgba(250,226,162,0.02) 0%, rgba(244,198,134,0.34) 99%)';
                item.txtColor = '#E8CEA0';
            } else if (i === 1) {
                item.suo_img = require('./images/icon_suo_bj.png');
                item.logoBgColor = 'linear-gradient(90deg, #A3A3A3 0%, #DFDFDF 100%)';
                item.txtColor = '#606060';
            } else if (i === 0) {
                item.suo_img = require('./images/icon_suo_sj.png');
                item.logoBgColor = 'linear-gradient(90deg, #16517D 0%, #D7EAF9 100%)';
                item.txtColor = '#16517D';
            }
        }


        console.log('---indexRef---', indexRef.current)
        return list.map((item, index) => {
            return (
                <Swiper.Item key={index}>
                    <div
                        className={curIndex === index ? `${styles.content} ${styles.content_bg}` : `${styles.content}`}>
                        <img src={item.levelBadgeImg} className={styles.vip_card_img}/>
                        <div
                            className={(defIndex === 1 && defIndex === index) ? `${styles.logo_container} ${styles.hz_boder}` : styles.logo_container}
                            style={{background: item.logoBgColor}}>
                            {
                                (isVip && defIndex === index) ? null : <img src={item.suo_img}/>
                            }
                            <p className={styles.logo_suo_name} style={{color: item.txtColor}}>{
                                (isVip && defIndex === index) ? '当前等级' : '未解锁'
                            }</p>
                        </div>
                        <p className={styles.vip_time}
                           style={{color: item.txtColor}}>{(isVip && defIndex === index) ? `有效期${userInfo.levelValidityStart || ''}-${userInfo.levelValidityEnd || ''}` : ''}</p>
                    </div>
                </Swiper.Item>
            )
        })
    }

    return (
        <div className={styles.vip_card_header}>
            <div className={styles.vip_card_top_container}>
                <div className={styles.back_btn} onClick={() => goBack()}></div>
                <img className={styles.share_btn} src={require('./images/share-fill.png')} onClick={onShareMini}/>
            </div>
            <div className={styles.vip_card_swiper_container}>
                {
                    defIndex > -1 ? < Swiper slideSize={88}
                        // allowTouchMove={false}
                                             stuckAtBoundary={false}
                                             defaultIndex={defIndex}
                                             onIndexChange={(index) => onIndexChange(index)}
                                             indicator={() => null}>
                        {items()}
                    </Swiper> : null
                }
            </div>
            <MemberRuleDialog maskVisible={maskVisible} setMaskVisible={setMaskVisible}/>
        </div>
    )
}

const TelComponent = () => {

    const [isVisible, setIsVisible] = useState(false);
    return (
        <>
            <div className={styles.botBtn} onClick={() => {
                setIsVisible(!isVisible);
            }}>咨询详情 了解更多
            </div>
            <TelDialog isVisible={isVisible} setIsVisible={setIsVisible}/>
        </>
    )
}
