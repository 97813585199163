import React from 'react';
import  styles  from './index.module.scss';
import firstBg from './image/firstBg.png';
import fourBg from './image/fourBg.png';
import oneImg from './image/oneImg.png';
export default () => {
    return <div className={styles.companyDescContainer}>
              <div className={styles.firstPar}>
                  <img src={firstBg} className={styles.firstBg} />
              </div>
              <div className={styles.secondPar}>
                 <div className={styles.firstLine}></div>
                 <div className={styles.wrapInnerText1}>
                      <div className={styles.firstTitleText}>
                          <span className={styles.text1}>关于联奇云</span>
                          <span className={styles.text2}>About Lianqi Cloud</span>
                      </div>
                      <div className={styles.wrappAllSmallText}>

                           <div className={styles.innerTextPart}>
                               <span className={styles.specialTitleText}>杭州联奇云信息科技有限公司</span><span>（以下简称“联奇云”）是绿地数字科技有限公司（以下简称“绿地数科”）旗下科技子公司，致力于为大型集团客户提供数字化转型的技术支撑。</span>
                           </div>
                           
                           <div className={styles.innerTextPart}>
                               公司依托<span className={styles.specialText1}>世界500强绿地集团</span>的多元化布局，围绕绿地数科生态内的使用权交易、供应链金融和会员积分权益等数字化平台资源，以<span className={styles.specialText1}>云计算技术</span>为基础，通过建设和整合SaaS系统平台，结合行业特性，为客户提供数字化相关的咨询与集成，应用开发和系统运维外包服务。
                           </div>

                           <div className={styles.innerTextPart}>联奇云以“联奇+云”作为公司名称，概括了公司的发展战略及企业文化。公司的愿景是成为云计算时代行业领先的新型集成服务商，遵循<span className={styles.specialText1}>“联手共创，守正出奇”</span>的企业经营理念，以专业和创新作为服务特色。公司经营管理核心团队均来自于国内外知名科技公司和金融机构，在相关领域有深厚积累，期待与广大客户及合作伙伴携手开拓创新，共同迈进数字化新时代。
                           </div>



                      </div>

                      
                    

                 </div>

              </div>

              <div className={`${styles.firstTitleText} ${styles.firstTitleTextAdd}`} style={{marginLeft:20}}>
                    <span className={styles.text1}>主要业务</span>
                    <span className={styles.text2}>Main business lines</span>
              </div>
              <div className={styles.fourImgWrap}>
                    <img src={fourBg} className={styles.fourBg}/>
              </div>


                <div className={styles.oneImgWrap}>
                    <img src={oneImg} className={styles.oneImg}/>
                </div>

            

           </div>;
}