import React, {createContext, useEffect, useRef, useState} from 'react';
import styles from "./vipCardContent.module.scss";
import NoticeUtil from "../../../../library/util/NoticeUtil";
import Library from "../../../../library";
import {isEmptyArray} from "../../../../library/util";
import {handelVipData} from "../utils";

const {JsBridge} = Library;
export const VipCardHZContent = (props) => {
    const titleColor = '#F8DC9E';
    const textColor = '#5B2D02';
    const initStatus = useRef(false);
    const {focusStatus, levelId, userInfo} = props;
    const [data, setData] = useState([]);
    useEffect(() => {
        if (initStatus.current === false && focusStatus && levelId > -1) {
            loadData().then();
            initStatus.current = true;
        }
    }, [initStatus.current, focusStatus])
    const loadData = async () => {
        let result = await handelVipData(500010, levelId);
        if(result) {
            setData(result);
        }
    }
    const offLineComponent = () => { //线下活动
        return (
            <div className={styles.vip_card_content_offline}>
                <div className={styles.vip_card_content_offline_top}>
                    <div className={styles.vip_card_content_line0}/>
                    <div className={`${styles.vip_card_content_des} ${styles.hz}`}>线下活动</div>
                </div>
                <div className={styles.vip_card_content_offline_bottom}
                     style={{background: 'linear-gradient(270deg, #D2A976 0%, #FFE5B9 100%)'}}>
                    <img src={require('../images/icon_huodon_hz.png')}
                         className={styles.vip_card_content_offline_logo}/>
                    <p style={{color: '#5B2D02'}}>{`    投资讲座｜高端展览和沙龙\n艺术品鉴｜经济论坛｜客户答谢会\n      社交下午茶｜外部参访`}</p>
                    <div className={styles.vip_card_content_offline_right_btn}
                         onClick={() => JsBridge.push('Web', {uri: window.location.origin + `/front/member/appointment/activitySelection?userAccount=${userInfo.account}`})}
                    >预约
                    </div>
                </div>
            </div>
        )
    }


    const childComponents = (item) => {
        if (!isEmptyArray(item.list)) {
            return item.list.map((item, index) => {
                return (
                    <div className={styles.vip_card_content_server_item} key={index.toString()}
                         style={{marginLeft: (index % 2 !== 0) ? '4vw' : 0,}}
                         onClick={()=>{props.itemClick && props.itemClick(2,item) }}>
                        <img className={styles.vip_card_server_item_img} src={item.rightsImgUrl}/>
                        {
                            item.isAppShow === 1 ? <div className={styles.vip_card_content}>
                                <p className={styles.vip_card_server_item_title}
                                   style={{color: textColor}}>{item.rightsName}</p>
                                <p className={styles.vip_card_server_item_des}
                                   style={{color: textColor}}>{item.rightsBrief}</p>
                            </div> : null
                        }
                    </div>
                )
            })
        }
    }

    const otherComponents = () => {
        return data.map((item, index) => {
            return (
                <div className={styles.vip_card_content_server} key={index.toString()}>
                    <div className={styles.vip_card_content_offline_top}>
                        <div className={styles.vip_card_content_line0}/>
                        <div className={`${styles.vip_card_content_des}`}
                             style={{color: titleColor}}>{item.rightsClass}</div>
                    </div>
                    <div className={styles.vip_card_content_server_container}>
                        {childComponents(item)}
                    </div>
                </div>
            )
        })
    }


    return (
        <div className={styles.vip_card_content_container}
             // style={{background: 'linear-gradient(180deg, rgba(29,31,35,1.25) 0%, #737373 100%)'}}
        >
            {offLineComponent()}
            {otherComponents()}
        </div>
    )
}


