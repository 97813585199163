import React from 'react';
import Home from './home';
import Verify from './verify';
import VerifyItem from './verifyItem';
import Record from './record';
import Detail from './detail';
import Login from './login';
import Analyse from './analyse';
import Analyse55 from './analyse55';

export const Routes = [{
    exact: true,
    path: '/front/admin/index/home',
    component: () => {
        return <Home />;
    },
    title: '绿地G优五一活动核销平台'
}, {
    path: '/front/admin/index/verify',
    component: () => {
        return <Verify />;
    },
    title: '消费券核销'
}, {
    path: '/front/admin/index/verify/item',
    component: () => {
        return <VerifyItem />;
    },
    title: '消费券核销'
}, {
    path: '/front/admin/index/record',
    component: () => {
        return <Record />;
    },
    title: '核销记录'
}, {
    path: '/front/admin/index/detail',
    component: () => {
        return <Detail />;
    },
    title: '核销详情'
}, {
    path: '/front/admin/index/login',
    component: () => {
        return <Login />;
    },
    title: '绿地G优五一活动核销平台'
}, {
    path: '/front/admin/index/analyse',
    component: () => {
        return <Analyse />;
    },
    title: '绿地G优五一活动数据统计'
}, {
    path: '/front/admin/index/analyse55',
    component: Analyse55,
    title: '绿地G优五五折扣节数据统计'
}]