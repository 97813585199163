/**
 * @description 碳积分种树小游戏
 * @returns {/front/activities/carbonIntegralHome}
*/
import React, {useEffect, useRef, useState} from 'react'
import {Toast} from "antd-mobile";
import {ListView} from "components";
import Services from "services";
import Library from "library";
import {CARBONPOINT} from "library/util/constant";
import {isIOS} from "library/util";
import NoticeUtil from "library/util/NoticeUtil";
import clickThrottle from "library/util/clickThrottle";
import styles from './styles.module.scss'
import EnergyBall from "../components/energyBall";
import TaskRecord from "../components/taskRecord";
import GuidePopup from "../components/guidePopup";
import ShiMingModal from "../components/shiMingModal";
import ExchangeSuccModal from "../components/exchangeSuccModal";
import ShareModal from "../components/shareModal";


const {JsBridge, Util} = Library;
export const TaskType = { //任务类型
    SIGN_IN: 'SIGN_IN', //签到
    BUY_GOODS: 'BUY_GOODS',//购买商品
    STEP_NUMBER: 'STEP_NUMBER',//步数
    META_VISIT: 'META_VISIT',//元宇宙访问时长
    TRADE_IN: 'TRADE_IN',//以旧换新
};

const CarbonIntegralHome = () => {

    useEffect(() => {
        if (isIOS()) {
            getUserStep().then();
        }
    }, [])

    const getUserStep = async () => {
        const date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        let time = `${year}-${month}-${day} 00:00:00`;

        let user = await JsBridge.getDetailCurrentUserInfo();
        console.log('user---', user.account); //特殊处理账户
        if (user && user.account === '13164690896') {
            return;
        }
        let res = await JsBridge.getUserStepCount({startTime: time});
        if (res.status > 0 && res.step >= 0) {
            console.log('step---', res.step);
            Services('MainPage', {code: 600856}, {stepNum: res.step, time: time}, true).then();
        }
    }

    return <div className={styles.carbon_integral_home}>
        <TopComponent/>
        <BottomComponent/>
    </div>
}

const BottomComponent = () => {

    const [dataList, setDataList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [available, setAvailable] = useState(0);//拥有的碳积分
    const [carbonCertificate, setCarbonCertificate] = useState(0);//拥有的碳证书
    useEffect(() => {
        loadData().then();
        onDidFocus();
        NoticeUtil.addListener('kTopBallRefresh', () => {
            loadData().then();
        })
    }, [])


    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            loadData().then();
            onDidFocus();
        });
    }

    const loadData = async () => {
        // let res = await getData();
        let res = await Services('MainPage', {code: 600859}, {activityCode: CARBONPOINT});
        // console.log('600859--', res);
        if (!!res && res?.status === 1 && !!res?.data) {
            let {rsps = [], available = 0, carbonCertificate} = res.data;
            setIsLoading(false);
            setDataList(rsps);
            setAvailable(available);
            setCarbonCertificate(carbonCertificate);
        }
    }


    const itemClick = (item) => {
        let isCan = btnStatus(item);
        if (!isCan) {
            return;
        }
        const {carbonPoints = 0, goodsNum = 0, goodsType} = item;
        if (available < carbonPoints) {
            Toast.show('您的碳积分不足');
            return;
        }
        //goodsType:3 实物 8 数字藏品 10碳证书
        if (goodsType === 3) {
            shiWuExchange(item);
        } else if (goodsType === 8) {
            colExchange(item);
        } else if (goodsType === 10) {
            carbonExchange(item);
        }
    }

    const shiWuExchange = (item) => {//实物兑换
        const {goodsName, imgUrl, carbonPoints = 0, goodsId = 0, id = 0} = item;
        const param = Util.url.objectToQueryParams({
            idf: CARBONPOINT,
            activityCode: CARBONPOINT,
            name: goodsName,
            imgUrl: imgUrl,
            carbonPoints: carbonPoints,
            productId: id,
        });
        JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/guapp/activityOrder?${param}`});
    }

    const colExchange = (item) => {//藏品兑换
        const {goodsId = 0, carbonPoints = 0} = item;
        // JsBridge.push('CustomWeb', {uri: `http://192.168.110.82:3001/enviroProDetail?collectionId=${goodsId}&carbonPoints=${carbonPoints}`});
        JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_NFT_LINK}/enviroProDetail?collectionId=${goodsId}&carbonPoints=${carbonPoints}`});
    }

    const carbonExchange = async (item) => {//碳证书兑换
        const user = await JsBridge.getDetailCurrentUserInfo();
        if (!user.ID) {  //是否实名
            ShiMingModal.open();
            return;
        }
        const {goodsId = 0, carbonPoints = 0} = item;
        let params = {
            activityCode: CARBONPOINT,
            collectionId: goodsId,
            points: carbonPoints,
        }
        let res = await Services('MainPage', {code: 600860}, params);
        console.log('600860--', res);
        if (!!res && res?.status === 1) {
            ExchangeSuccModal.open();
        }
    }

    const btnTitle = (item) => {  //goodsType:3 实物 8 数字藏品 10碳证书
        const {carbonPoints = 0, goodsNum = 0, goodsType} = item;
        if (goodsNum === 0) {
            return '已抢光';
        }
        if (goodsType === 10) {
            return `${(carbonPoints / 1000)}kg`;
        } else {
            if (carbonCertificate) {//有碳证书
                return `${(carbonPoints / 1000)}kg`;
            } else {//无碳证书
                return '请先兑换碳证';
            }
        }
    }

    const btnStatus = (item) => {  //goodsType:3 实物 8 数字藏品 10碳证书
        const {goodsNum = 0, goodsType, carbonPoints} = item;
        if (goodsNum === 0) {
            return false;
        }

        if (available < carbonPoints) {
            return false;
        }

        if (goodsType === 10) {
            return true;
        } else {
            if (carbonCertificate) {//有碳证书
                return true;
            } else {//无碳证书
                return false;
            }
        }
    }

    const renderItem = (item) => {
        const {goodsName, imgUrl, carbonPoints = 0, goodsNum = 0} = item;
        return <div className={styles.item}>
            <img src={imgUrl} alt=""/>
            <p className={styles.name}>{goodsName}</p>
            <p className={`${styles.btn} ${btnStatus(item) ? '' : styles.disable}`}
               onClick={() => itemClick(item)}>{btnTitle(item)}</p>
        </div>
    }

    return (
        <div className={styles.bottom_container}>
            <div className={styles.header}>
                <p className={styles.cd1}>兑换奖品</p>
                <div className={styles.cd2}>
                    <img src={require('../imgs/icon_tjf.png')} alt=""/>
                    <p>{available / 1000}kg</p>
                </div>
                <p className={styles.cd3}
                   onClick={() => {
                       JsBridge.push('Web', {uri: `${window.location.origin}/front/activities/carbonIntegralHome/exchangeRecord`});
                   }}>兑换记录></p>
            </div>

            <div className={styles.list}>
                <ListView
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={false}
                    isLoading={isLoading}
                    onRefresh={async () => {
                        await loadData(true)
                    }}
                    // loadMore={async () => await loadData(false)}
                />
            </div>
        </div>
    )
}

const TopComponent = () => {
    const [totalCarbonPoints, setTotalCarbonPoints] = useState(0)//总碳积分
    const sysTimeRef = useRef('');//系统时间
    const [carbonResult, setCarbonResult] = useState({})//碳积分达成任务
    const [growthItem, setGrowthItem] = useState(null);

    useEffect(() => {
        loadSetting().then();
        getSignData();
        onDidFocus();
    }, [])

    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            loadSetting().then();
            onDidFocus();
        });
    }

    const getSignData = () => {
        Services('MainPage', {code: 600854}, {activityCode: CARBONPOINT}, true);
    }

    const loadSetting = async () => {
        // let res = await getData1();
        let res = await Services('MainPage', {code: 600851}, {activityCode: CARBONPOINT}, true);
        console.log('600851---', res)
        if (res.status > 0) {
            const {totalCarbonPoints = 0, systemTime = '', carbonResult = [], growthValues = []} = res.data;
            setTotalCarbonPoints(totalCarbonPoints);
            setCarbonResult(carbonResult);
            // setCarbonResult({
            //     "SIGN_IN": [
            //         {
            //             "id": 1,
            //             "taskType": "SIGN_IN",
            //             "taskName": "签到",
            //             "effectiveTime": '2023-12-14 16:09:21',
            //             "carbonPoints": 100
            //         }
            //     ],
            //     "BUY_GOODS": [
            //         {
            //             "id": 1,
            //             "taskType": "BUY_GOODS",
            //             "taskName": "购买商品",
            //             "effectiveTime": '2023-12-07 16:09:21',
            //             "carbonPoints": 1000
            //         }
            //     ],
            //     "STEP_NUMBER": [
            //         {
            //             "id": 1,
            //             "taskType": "STEP_NUMBER",
            //             "taskName": "步数统计",
            //             "effectiveTime": '2023-12-07 16:09:21',
            //             "carbonPoints": 1000
            //         }
            //     ],
            //     "META_VISIT": [
            //         {
            //             "id": 1,
            //             "taskType": "META_VISIT",
            //             "taskName": "元宇宙浏览",
            //             "effectiveTime": '2023-12-26 10:09:21',
            //             "carbonPoints": 1000
            //         }
            //     ],
            //     "TRADE_IN": [
            //         {
            //             "id": 1,
            //             "taskType": "TRADE_IN",
            //             "taskName": "藏品以久换新",
            //             "effectiveTime": '2023-12-26 11:09:21',
            //             "carbonPoints": 500,
            //             completeStatus: 3,
            //         }
            //     ],
            // })
            sysTimeRef.current = systemTime;
            let itemTem = null;
            for (const item of growthValues) {
                if (item.startValue <= totalCarbonPoints && totalCarbonPoints <= item.endValue) {
                    itemTem = item;
                    break;
                }
            }
            if (itemTem) {
                setGrowthItem(itemTem);
            } else {//没有匹配上
                if (growthValues.length) {
                    let lastItem = growthValues[growthValues.length - 1];
                    if (totalCarbonPoints > lastItem.endValue) {
                        setGrowthItem({...lastItem, isMax: true});
                    }
                }
            }
        }
    }

    const glClick = () => {//攻略
        GuidePopup.show();
    }

    const rwClick = () => {
        TaskRecord.open();
    }

    const clickShare = () => {
        ShareModal.open({activityCode: CARBONPOINT, inviteCode: ''});
    }

    const ComsterHeader = () => {
        return <div className={styles.top_father}>
            <div className={styles.back_btn} onClick={() => JsBridge.back()}>
                <img src={require('../imgs/icon_fh.png')} alt=""/>
            </div>
            <div className={styles.share_btn} onClick={() => clickShare()}>
                <img src={require('../imgs/icon_fx.png')} alt=""/>
            </div>
        </div>
    }

    const Bolls = () => {//任务球 签到  购买商品  步数达标  元宇宙浏览  藏品以旧换新
        let array = [{isExist: false}, {isExist: false}, {isExist: false}, {isExist: false}, {isExist: false}];
        for (const key in carbonResult) {
            let arrTem = carbonResult[key] || [];
            let index = -1;
            if (key === TaskType.SIGN_IN) {//签到
                if (arrTem.length > 0) {
                    index = 0;
                }
            } else if (key === TaskType.BUY_GOODS) { //购买商品
                if (arrTem.length > 0) {
                    index = 1;
                }
            } else if (key === TaskType.STEP_NUMBER) { //步数
                if (arrTem.length > 0) {
                    index = 2;
                }
            } else if (key === TaskType.META_VISIT) { //元宇宙访问时长
                if (arrTem.length > 0) {
                    index = 3;
                }
            } else if (key === TaskType.TRADE_IN) { //以旧换
                if (arrTem.length > 0) {
                    index = 4;
                }
            }
            if (index > -1) {
                let lastItem = arrTem[0];
                // console.log('last--', JSON.stringify(lastItem));
                if (lastItem.taskType === TaskType.META_VISIT) {
                    lastItem.taskName = '元宇宙访问';
                }
                lastItem.isExist = true;
                lastItem.systemTime = sysTimeRef.current;
                array[index] = lastItem;
            }
        }
        return <div className={`${styles.bolls} ${growthItem?.growthLevel > 3 ? styles.step45 : ''}`}>
            {
                array.map((item, index) => {
                    return <div key={index.toString()}><EnergyBall item={item} refreshData={() => {
                        loadSetting().then();
                        NoticeUtil.emit('kTopBallRefresh')
                    }
                    }/></div>
                })
            }
        </div>
    }

    const calPropor = () => {
        let propor = totalCarbonPoints / (growthItem?.endValue);
        return propor > 1 ? 1 : propor
    }

    const getName = () => {
        if (growthItem?.isMax) {
            return <p
                className={styles.value}>{`${growthItem?.growthLevelName || ''}:${totalCarbonPoints}g`}</p>
        }
        return <p
            className={styles.value}>{`${growthItem?.growthLevelName || ''}:${totalCarbonPoints}/${growthItem?.endValue || 0}g`}</p>
    }

    return <div className={styles.header_container}>
        <ComsterHeader/>
        <TreeLevel growthItem={growthItem}/>
        <Bolls/>
        <img src={require('../imgs/icon_gl.png')} alt="" className={styles.gl} onClick={() => glClick()}/>
        <img src={require('../imgs/icon_rw.png')} alt="" className={styles.rw} onClick={() => rwClick()}/>
        <div className={styles.level_container}>
            <div className={styles.pro_container}>
                <div className={styles.pro} style={{width: `${(calPropor()) * 100}%`}}/>
                {getName()}
            </div>
            <p className={styles.lv}>Lv{growthItem?.growthLevel || 0}</p>
        </div>
    </div>
}

const TreeLevel = ({growthItem}) => {
    const [clickImg, setClickImg] = useState(false)

    const imageClick = () => {
        if (!clickThrottle()) {
            return;
        }
        setClickImg(true);
        let timer = setTimeout(() => {
            clearTimeout(timer)
            setClickImg(false);
        }, 2000)
    }

    let level = growthItem?.growthLevel || 0;
    let imgSrc = '';
    let treeStyle = '';
    if (level === 1) {
        imgSrc = clickImg ? require('../imgs/step1.gif') : require('../imgs/step1_j.png');
        treeStyle = styles.tree1;
    } else if (level === 2) {
        imgSrc = clickImg ? require('../imgs/step2.gif') : require('../imgs/step2_j.png');
        treeStyle = styles.tree2;
    } else if (level === 3) {
        imgSrc = clickImg ? require('../imgs/step3.gif') : require('../imgs/step3_j.png');
        treeStyle = styles.tree2;
    } else if (level === 4) {
        imgSrc = clickImg ? require('../imgs/step4.gif') : require('../imgs/step4_j.png');
        treeStyle = styles.tree2;
    } else if (level === 5) {
        imgSrc = clickImg ? require('../imgs/step5.gif') : require('../imgs/step5_j.png');
        treeStyle = styles.tree2;
    }
    return <img src={imgSrc} alt="" className={treeStyle}
                onClick={() => imageClick()}/>;


}

export default CarbonIntegralHome
