import React, {useEffect, useRef, useState, useContext, createContext} from 'react';
import styles from './cartoonHome.module.scss';
import Library from "../../../library";
import cartoon_bg from "../../../images/cartoon_bg.png";
import {isArray, isEmptyString} from "../../../library/util";
import egg_bt_huodong from "../../../images/cartoon_btn_guize.png";
import egg_bt_shiming from "../../../images/cartoon_btn_shiming.png";
import cartoon_yaoxin from "../../../images/cartoon_yaoxin.png";
import cartoon_yaoxin_hui from "../../../images/cartoon_yaoxin_hui.png";

import cartoon_pm_jl from "../../../images/cartoon_pm_jl.png";
import cartoon_pm_jl_hui from "../../../images/cartoon_pm_jl_hui.png";
import cartoon_logo from "../../../images/cartoon_logo.png";
import CartoonRuleDialog from "./views/cartoonRuleDialog";
import Services from "../../../services";
import CartoonShareDialog from "./views/cartoonShareDialog";
import NoticeUtil from "../../../library/util/NoticeUtil";
import {Toast} from "antd-mobile";

const kBuyNotification = 'kBuyNotification';
const {JsBridge} = Library;
const Context = createContext(null);
const activityCode = 'CR2023';
export const CartoonHome = () => {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        getUserInfo();
    }, [])

    const getUserInfo = () => { //获取用户信息
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            setUserInfo(res);
        })
    }
    const refreshData = () => {
        getUserInfo();
    }

    return (
        <Context.Provider value={{userInfo}}>
            <div className={styles.cartoon_container}>
                <CartoonHeader refreshData={refreshData}/>
                <CartoonBuyAndGetComponent/>
                <CartoonGradeComponent/>
                <CartoonBottomComponent/>
            </div>
        </Context.Provider>
    )
}

const CartoonHeader = (props) => { //头部
    const [showRuleVisible, setShowRuleVisible] = useState(false);
    const [showShareVisible, setShowShareVisible] = useState(false); //分享
    const {userInfo} = useContext(Context);
    const [data, setData] = useState({});

    useEffect(() => {
        loadData();
        NoticeUtil.addListener(kBuyNotification, addListener)
        return () => {
            NoticeUtil.removeListener(kBuyNotification, addListener);
        }
    }, [])

    const loadData = () => {
        let params = {'activityCode': activityCode};
        Services('MainPage', {code: 300033}, params).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                setData(data);
            }
        });
    }

    const addListener = () => {
        loadData();
        refreshData();
    }

    const refreshData = () => {
        if (props.refreshData) {
            props.refreshData();
        }
    }

    const goBack = () => {
        JsBridge.back()
    }

    const huodongRule = () => {
        setShowRuleVisible(!showRuleVisible);
    }
    const clickShare = () => { //立即邀新
        // if (data && data.status) {
        //     setShowShareVisible(!showShareVisible);
        // }
    }

    const shiMingRenZhen = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                refreshData();
            });
            return;
        }
        JsBridge.addListener().then(() => { //实名认证回来
            if (props.refreshData) {
                props.refreshData();
            }
        });
        JsBridge.navigate('Verify');
    }

    const inviteRecordClick = () => { //邀新记录
        if (data && data.status) {
            JsBridge.push('Web', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/cartoon/inviteRecord`});
        }
    }

    const inviteRankClick = () => { //邀新排行榜
        JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/cartoon/inviteRankList`});
    }

    let yxDes = '购买盲盒后开通';
    let isCanYY = false;//是否能邀新
    if (data && data.status) {
        yxDes = `邀新成功${data?.inviteCount}人`;
        isCanYY = true;
    } else {
        yxDes = '';
        isCanYY = false;
    }
    let isCanYY1 = false;

    return (
        <>
            <div className={styles.header_container}>
                <img src={cartoon_bg} className={styles.img_bg}/>
                <div className={styles.back_share_father}>
                    <div className={styles.back_btn} onClick={() => goBack()}></div>
                    <div/>
                </div>
                <div className={styles.rule_shiming_father}>
                    <div className={styles.huodong_father} onClick={() => huodongRule()}>
                        <img src={egg_bt_huodong} alt=""/>
                        <div className={styles.title}>活动规则</div>
                    </div>

                    {isEmptyString(userInfo.ID) ?
                        <div className={styles.huodong_father} onClick={() => shiMingRenZhen()}>
                            <img src={egg_bt_shiming} alt=""/>
                            <div className={styles.title_shiming}>实名认证</div>
                        </div> : null}
                </div>
                <div className={styles.yaoxin_pm_father}>
                    <div className={styles.header_yaoxin} onClick={() => clickShare()}>
                        <img src={isCanYY1 ? cartoon_yaoxin : cartoon_yaoxin_hui} alt=""/>
                        <div className={styles.header_yaoxin_des_father}>
                            <div className={styles.lijiyx} style={{color: isCanYY1 ? '' : '#767676'}}>立即邀新</div>
                            <div className={styles.yijinyx} style={{color: isCanYY1 ? '' : '#767676'}}>{yxDes}</div>
                        </div>
                    </div>
                    <div className={styles.header_pl_jl} onClick={() => inviteRecordClick()}>
                        <img src={isCanYY ? cartoon_pm_jl : cartoon_pm_jl_hui} alt=""/>
                        <div className={styles.header_pl_jl_des_father}>
                            <div className={styles.yaoxinjl} style={{color: isCanYY ? '' : '#767676'}}>邀新记录</div>
                            <div className={styles.jianglixx} style={{color: isCanYY ? '' : '#767676'}}>奖励信息</div>
                        </div>
                    </div>

                    <div className={styles.header_pl_jl_right}>
                        <img src={cartoon_pm_jl} alt=""/>
                        <div className={styles.header_pl_jl_right_des_father} onClick={() => inviteRankClick()}>
                            <div className={styles.yaoxinpm}>购买排名</div>
                            <div className={styles.jiangping}>前十名有奖品</div>
                        </div>
                    </div>
                </div>
                <CartoonRuleDialog maskVisible={showRuleVisible} setMaskVisible={setShowRuleVisible}/>
                <CartoonShareDialog maskVisible={showShareVisible}
                                    closeMask={() => setShowShareVisible(!showShareVisible)}/>
            </div>
        </>
    )
}

const CartoonBuyAndGetComponent = () => { //购买和领取列表
    const {userInfo} = useContext(Context);
    const [data, setData] = useState({
        inviteBuyDesc1: '',
        collectionPrice: 0,
        inviteDesc: '',
        collectionId: '',
        list: [],
        collectionDesc: '',
        collectionPicUrl: '',
        inviteBuyDesc2: '',
        collectionName: '',
        sysTime: '',//当前时间
        endTime: '',//活动结束时间

    });

    useEffect(() => {
        loadData();
        onDidFocus();
    }, []);

    const loadData = () => {
        let params = {'activityCode': activityCode};
        Services('MainPage', {code: 300034}, params).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                setData(data);
            }
        });
    }
    const onDidFocus = () => {
        JsBridge.addListener().then(() => { //领取回来
            loadData();
            NoticeUtil.emit(kBuyNotification);
            onDidFocus();
        });
    }

    const btnClick = (item) => { // myCollection?code='05'
        if (item.status === 2) {//查看详情
            let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?code=05`;
            JsBridge.push('CustomWeb', {uri: uri});
        } else if (item.status === 3) {//立即领取
            let params = {'code': item.code || 0};
            Services('MainPage', {code: 300038}, params).then((res) => {
                if (res.status > 0) {
                    let data = res.data;
                    const {code = 0, req = ''} = data;
                    if (!isEmptyString(req)) {
                        let uri = `${process.env.REACT_APP_NFT_LINK}/salePaySuccess?code=${code}&req=${req}`;
                        JsBridge.push('Web', {uri: uri});
                    } else {
                        Toast.show('获取req为空!')
                    }
                }
            });
        }
    }

    const goBuy = () => { //立即购买
        if (!isEmptyString(data.collectionId)) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/apeWorld/cartoonSaleDetail?collectionId=${data.collectionId}`;
            JsBridge.push('CustomWeb', {uri: uri});
        }
    }

    const listComponent = () => {
        let arr = data.list;

        let sysTime = data.sysTime;
        let endTime = data.endTime;
        let isGQ = false;
        if (!isEmptyString(sysTime) && !isEmptyString(endTime)) {
            let startDateTimeStamp = Date.parse(sysTime.replace(/-/g, '/'))
            let endDateTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
            if (startDateTimeStamp > endDateTimeStamp) {
                isGQ = true;
            } else {
                isGQ = false;
            }
        }

        return arr.map((item, index) => {
            let btnTitle = '未持有';
            let btnColor = '#AAAAAA';
            let isCanClick = true;
            if (item.status === 0) {
                btnTitle = '未持有';
                btnColor = '#CECECE';
                isCanClick = false;
            } else if (item.status === 1) {
                btnTitle = '已领取';
                btnColor = '#AAAAAA';
                isCanClick = false;
            } else if (item.status === 2) {
                btnTitle = '查看详情';
                btnColor = '';
                isCanClick = true;
            } else if (item.status === 3) {
                btnTitle = '立即领取';
                btnColor = '';
                isCanClick = true;

                let isReceive = data.isReceive;// isReceive  "0":立即领取置灰  "1"立即领取高亮
                if (isReceive === '0') {
                    btnColor = '#AAAAAA';
                    isCanClick = false;
                } else if (isReceive === '1') {
                    btnColor = '';
                    isCanClick = true;
                }

                if (isGQ) { //过期了
                    btnTitle = '已过期';
                    btnColor = '#AAAAAA';
                    isCanClick = false;
                }
            }


            return <div className={styles.list_component} key={index.toString()}>
                <div className={styles.list_component_container}>
                    <span className={styles.list_component_title}>{item.name}</span>
                    <span className={styles.list_component_title}>x{item.count}</span>
                    <div className={styles.list_component_des}>{item.desc}</div>
                    <div className={styles.list_component_look_detail}
                         style={{background: btnColor}}
                         onClick={() => isCanClick ? btnClick(item) : null}>{btnTitle}</div>
                </div>
            </div>
        })
    }


    return (
        <div className={styles.cartoon_mid_container}>
            <div className={styles.buy_get_component}>
                <img src={require('../../../images/cartoon_pic_list.png')} className={styles.buy_get_component_img}/>
                <div className={styles.buy_get_component_content}>
                    <img src={data.collectionPicUrl} className={styles.pro_img}/>
                    <div className={styles.pro_component}>
                        <div className={styles.pro_component_title}>{data.collectionName}</div>
                        <div className={styles.pro_component_des}>{data.collectionDesc}</div>
                        <div className={styles.pro_component_dl_money_com}>
                            <span className={styles.pro_component_dl}>¥</span>
                            <div className={styles.pro_component_money}>{data.collectionPrice}</div>
                            <span className={styles.pro_component_gobuy} onClick={() => goBuy()}>立即购买</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.conn_container}>
                <img src={require('../../../images/pic_logo.png')}/>
                <img src={require('../../../images/pic_logo.png')}/>
            </div>
            <div className={styles.mh_com}>
                <div className={styles.mh_des_com}>
                    <span>多种方式</span>
                    <span style={{color: '#FFE432'}}>免费</span>
                    <span>得盲盒！</span>
                </div>
                <div className={styles.mh_des0_com}>
                    {/*<div className={styles.mh_des0_com_0}>*/}
                    {/*    <div className={styles.mh_des0_com_0_content}>*/}
                    {/*        <div>成功邀新<span style={{color: '#FFE432'}}>6</span>名新用户，免费获得1个盲盒，以此类推！*/}
                    {/*        </div>*/}
                    {/*        <span className={styles.mh_des0_com_0_content_des}>("邀新记录"里查看）</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={styles.mh_des0_com_1}>
                        <div className={styles.mh_des0_com_1_content}>
                            <div>邀请的新用户首次购买盲盒，<span style={{color: '#FFE432'}}>邀请人和被邀请人均可免费获得1个盲盒。</span>
                            </div>
                            <span className={styles.mh_des0_com_1_content_des}>("邀新记录"里查看）</span>
                        </div>
                    </div>
                </div>
                {listComponent()}
                {/*<div className={styles.mh_des_bottom}>邀新人 得盲盒 邀新越多奖励越多!</div>*/}
                <div className={styles.mh_des_bottom}></div>
            </div>
        </div>
    )
}

const CartoonGradeComponent = () => { //等级
    const {userInfo} = useContext(Context);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, [userInfo]);

    const loadData = () => {
        let params = {'activityCode': activityCode};
        Services('MainPage', {code: 300035}, params).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                if (isArray(data)) {
                    setData(data);
                }
            }
        });
    }

    const shClick = () => { //立即升级
        let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?code=05`;
        JsBridge.push('CustomWeb', {uri: uri});
        JsBridge.addListener().then(() => { //升级回来
            loadData();
        });
    }

    const listCartoons = () => {
        return data.map((item, index) => {
            return <div className={styles.cartoon_list_com} style={{marginTop: index < 3 ? '-20px' : ''}}
                        key={index.toString()}>
                <img src={item.imageBg}
                     style={{filter: item.num === 0 ? 'grayscale(80%)' : ''}}/>
                <div className={styles.cartoon_list_title}>{item.collectionName}<span
                    style={{color: '#FFE432'}}>-{item.level}级</span></div>
                <div className={styles.cartoon_list_has_com}>{item.num > 0 ? `拥有${item.num}张` : '未拥有'}</div>
            </div>
        })
    }

    return (
        <div className={styles.cartoon_grade_container}>
            <img src={require('../../../images/cartoon_list_tou.png')}/>
            <div className={styles.cartoon_grade_content}>
                {listCartoons()}
                <div className={styles.cartoon_grade_com_sj} onClick={() => shClick()}>
                    <div className={styles.cartoon_grade_sj}>立即升级</div>
                </div>
                <div className={styles.cartoon_grade_sj_des_com}>
                    <img src={require('../../../images/cartoon_grade_sj_des.png')}/>
                </div>
                <div className={styles.cartoon_grade_dz_des_com}>
                    <div className={styles.cartoon_grade_dz_title_com}>集共创漫画藏品 得<span>购数字藏品返积分福利</span></div>
                    <div className={styles.cartoon_grade_dz_content_com}>·集齐6款，享永久空投*+藏品购买金额返还50%等价积分
                        （*享永久新品免费空投，每种款式一件）
                    </div>
                    <div className={styles.cartoon_grade_dz_content0_com}>·集5款，藏品购买金额返还50%等价积分</div>
                    <div className={styles.cartoon_grade_dz_content0_com}>·集4款，藏品购买金额返还30%等价积分</div>
                    <div className={styles.cartoon_grade_dz_content0_com}>·集3款，藏品购买金额返还10%等价积分</div>
                </div>
            </div>
        </div>
    )
}
const CartoonBottomComponent = () => { //底部logo
    return (
        <div className={styles.logo_father}>
            <img src={cartoon_logo} className={styles.egg_logo}/>
        </div>
    )
}
