/**
 * @description 点赞记录/邀新记录
 * @example {
 *  <RecordPopup visible={visible} type={'give'} close={()=>{}}/>
 * }
 * @param {type: give: 点赞; invite:邀新, prize: 领奖}
*/

import React, { useState, useRef, useMemo, useEffect } from 'react'
import { Popup } from 'antd-mobile';
import { ListView } from 'components';
import Service from '../../service';
import Styles from './index.module.scss';

const RecordPopup = (props) => {
    const { 
        visible, 
        close,
        type, //give: 点赞；invite:邀新
    } = props;

    const title = useMemo(()=>{
        let text = '邀新记录'
        if(type === 'give') {
            text = '点赞记录'
        }else if(type === 'invite') {

        }
        return text
    },[type])

    const [isLoad, setIsLoad] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [listCount, setListCount] = useState(0);
    const currentPage = useRef(1);
    
    useEffect(()=>{
        if(visible){
            setDataList([])
            setListCount(0)
            loadData()
        }
    },[visible])

    const loadData = async ()=> {
        currentPage.current = 1
        let result;
        if(type === 'give') {
            result = await Service.getThumbsUpList()
            if(result) {
                const {count=0, list=[]} = result.data
                setListCount(count)
                setDataList(list);
                setHasMore(list.length >= 20)
            }
        }else if(type === 'invite') {
            result = await Service.getInviteList({pageNum: currentPage.current })
            if(result) {
                const { successCount=0,list=[] } = result.data
                setListCount(successCount)
                setDataList(list);
            }
        } 
        
    }

    const loadMore = async ()=> {
        if(type === 'give') {
            const result = await Service.getThumbsUpList({pageNum: `${currentPage.current + 1}`})
            if(result) {
                const {count=0, list=[]} = result.data
                setListCount(count)
                setDataList([...dataList, ...list])
                setHasMore(list.length >= 20)
                currentPage.current = currentPage.current + 1
            }
        }
    }

    const renderItem = (item) => {
        let el = null;
        if(type === 'give') {
            el = <GiveItem item={item}/>
        }else if(type === 'invite') {
            el = <InviteItem item={item}/>
        }
        return el;
    }

    return (
        <Popup
            visible={visible}
            getContainer={null}
            showCloseButton
            onMaskClick={close}
            onClose={close}
            bodyStyle={{ borderRadius: "10px 10px 0 0" }}
        >
            <div className={Styles.popup_container}>
                <div className={Styles.popup_title}>
                    <span>{title}</span>
                </div>
                <div className={Styles.popup_counts}> ——已成功{`${type === 'give' ? '点赞': '邀新'}`}{listCount}个——</div>
                <div className={Styles.popup_user_info}>
                    <ListView 
                        dataSource={dataList}
                        emtyTop={20}
                        renderItem={renderItem}
                        hasMore={hasMore}
                        onRefresh={()=>{
                            loadData(false)
                        }}
                        loadMore={loadMore}
                    />
                </div>
            </div>
        </Popup>
    )
}

// 点赞记录item
const GiveItem = ({item = {}})=> {
    return (
        <div className={Styles.m_give_item}>
            <div className={Styles.m_give_item__left}>
                <img src={item.imagePath} alt="" />
                <span>{item.mobile}</span>
            </div>
            <div className={Styles.m_give_item__right}>
                {item.createTime?.slice(5)}
            </div>
        </div>
    )
}

//邀新记录item
const InviteItem = ({item = {}})=> {

    return (
        <div className={Styles.m_invite_item}>
            <div className={Styles.item__left}>
                <img src={item.imagePath} alt="" />
                <div>
                    <span>{item.mobile}</span>
                    <span>{item.createTime}</span>
                </div>
                
            </div>
            <div className={Styles.item__right}>
                <div className={item.inviteStatus?.includes('失败') ? Styles.black_txt: {}}>{ item.inviteStatus }</div>
               { item.reason && <span>{item.reason}</span> } 
            </div>
        </div>
    )
}

export default RecordPopup;