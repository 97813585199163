import React, { useEffect, useState } from "react";
import styles from './DrawTimesRecord.module.scss';
import Services from "../../../../services";
import no_data from '../../../../images/noCollection.png'
import { PullToRefresh, InfiniteScroll, List, NavBar, Empty } from 'antd-mobile'
import Header from "components/header";

export const DrawTimesRecord = () => {
  const [pageNum, setPageNum] = useState(1)
  const [dataList, setDataList] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  useEffect(() => {
    loadData();
  }, []);
  let loadData = async () => {
    setPageNum(1)
    setIsLoading(true)
    const res = await Services('MainPage', { code: '300027' }, {
      activityCode: "NY2023",
      // userCode: "13764899253",
      pageNum: `1`,
      pageSize: "10"
    });
    setIsLoading(false)
    if (!!res && res?.status == 1 && !!res?.data) {
      let { list = [] } = res.data
      setDataList(list)
      setHasMore(list.length >= 10)
    }
  }
  let loadMore = async () => {
    const res = await Services('MainPage', { code: '300027' }, {
      activityCode: "NY2023",
      // userCode: "13764899253",
      pageNum: `${pageNum + 1}`,
      pageSize: "10"
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      let { list = [] } = res.data
      setDataList([...dataList, ...list])
      setHasMore(list.length >= 10)
      setPageNum(pageNum + 1)
    }
  }

  return (
    <div className={styles.root}>
      <Header midText={`次数获取记录`} bgColor={'#fff'} />
      <div className={styles.container}>
        {!isLoading && (!dataList || dataList?.length == 0) &&
          <Empty style={{ width: "100%", paddingTop: ' 30%' }} image={<img src={no_data} />} description="暂无数据" />
        }
        {!!dataList && !!dataList.length &&
          <PullToRefresh onRefresh={loadData}>
            <div className={styles.listContainer}>
              {dataList?.map((item, index) => (
                <div className={styles.itemContainer} key={`${index} - ${JSON.stringify(item)}`}>
                  <div className={styles.itemLeft}>
                    <div className={styles.itemTop}>
                      <div className={styles.itemTopTitle}>
                        {`${item?.actionDesc}`}
                      </div>
                      <div className={styles.itemTopSubTitle}>
                        {`${item?.loginName ? `(${item?.loginName})` : ''}`}
                      </div>
                    </div>
                    <div className={styles.itemTime}>
                      {`${item?.actionTime}`}
                    </div>
                  </div >
                  <div className={styles.itemRight}>
                    {`+1`}
                  </div>
                </div>
              ))}
            </div>
            {!isLoading &&
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            }
          </PullToRefresh>
        }
      </div>
    </div>
  )
}