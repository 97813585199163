import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import backDark from 'images/icon/back-svg-black.svg';
import back from 'images/icon/back-svg-white.svg';
import Library from 'library';
const { JsBridge, Platform } = Library;

const Header = ({
  theme='dark',
  leftImg, 
  midText, 
  rightText='', 
  rightImg, 
  rightClick, 
  bgColor,
  leftImgStyle, 
  shadow,
  onBack
}) => {
  const history = useHistory();

  const [height, setHeight] = useState(20);
  
  useEffect(() => {
    JsBridge.getStatusBarHeight().then(({height}) => {
      setHeight(height);
    });
  }, []);
  
  if(Platform.check.isMiniprogram()) {
    return null;
  }

  return <div className='common-header' 
            style={ bgColor === 'transparent'? {}: {height: 44 + height}}
         >
    <div className={`container ${shadow ? 'shadow': ''}`} 
      style={{
        paddingTop: 10 + height, 
        background: bgColor || '#FFFFFF', 
        marginLeft: bgColor === 'transparent'&&JsBridge.hasWebViewBridge() ? height>20 ? 0: -60 :0
      }}>
      <div className="left" onClick={() => {
        if(onBack) {
          //重写返回方法
          onBack()
        }else {
          if (JsBridge.hasWebViewBridge()) {
              JsBridge.back()
          }else {
              history.goBack();
          }
        }
          
      }}>
        <img src={leftImg || (theme=='dark' ? backDark: back)} style={leftImgStyle} />
      </div>
      <div className={`middle ${theme}`}>{midText}</div>
      <div className={`right ${theme}`} onClick={() => {rightClick && rightClick()}}>
        {rightText ? rightText: rightImg ? <img src={rightImg} alt="" />: ''}
      </div>
    </div>
  </div>
}

export default Header;
