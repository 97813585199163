/**
 * @description 2024 G优超级分享官-邀新专区相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const { JsBridge } = Library;

const inviteCode = 'inviteZone2024';
const activityCode = 'MoutaiSale';

class Service {

    /**
     * @description inviteZone2024邀新初始化接口
     * @interface 600871
     * @param {activityCode: 活动编码} params
     * @param { 是否显示加载loading } showLoading
     * @returns {} 
    */
    static getInventData = async (showLoading = true) => {
        const code = '600871'
        try {
            const params = {
                activityCode: inviteCode,
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage' : 'Common'
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.data || res.data };
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
    * @description 茅台活动初始化接口
    * @interface 600910
    * @param {activityCode: 活动编码} params
    * @param { 是否显示加载loading } showLoading
    * @returns {activityStatus: 0-活动进行中,1-活动已失效,2-已开奖, 3-开奖中; cycle: 期数, winNum: 中奖券码}
    * 
   */
    static getInitData = async (showLoading = true) => {
        const code = '600910'
        try {
            const params = {
                activityCode: activityCode,
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage' : 'Common'
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.data || res.data };
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }




    /**
     * @description 邀新记录列表接口
     * @interface 600913
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params = {}) => {
        const code = '600913'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 转赠记录列表接口
     * @interface 600913
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getTranRecordList = async (params = {}) => {
        const code = '600912'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
    * @description 抽奖券记录列表接口
    * @interface 600914
    * @param {activityCode:活动编码, pageNum, pageSize } params
    * @returns
    */
    static getRecordList = async (params = {}) => {
        const code = '600914'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 领取接口
     * @interface 600915
     * @param {activity: 活动编码 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static receivePrize = async (params = {}, showLoading = true) => {
        const code = '600915'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            console.log('res',res)
            if (res?.status>0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
    * @description 获取开奖记录
    * @interface 600916
    * @param {activity: 活动编码 } params
    * @param { 是否显示加载loading } showLoading
    * @returns
    */
    static lotteryRecord = async (params = {}, showLoading = true) => {
        const code = '600916'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }

            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 转赠入场券接口
     * @interface 600911
     * @param {activity: 活动编码 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
     */
    static tranTicket = async (params = {}, showLoading = true) => {
        const code = '600911'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }

            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.msg || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
}

Service.activityCode = activityCode;
Service.inviteCode = inviteCode;

export default Service;