import React, { useEffect, useState } from 'react';
import styles from "./CarnivalGuide.module.scss";
import gotoBrowserImg from 'images/goto-browser.png';
import bgImage from "../../../../images/pic_xiazai.png";
import Library from 'library';
const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

export const CarnivalGuide = () => {
    const {
        code = '',
    } = Library.Util.url.paramsToObj();
    const [maskShow, setMaskShow] = useState(false);
    const params = {}
    const targetUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/carnival/home`;
    const webTem = 'CustomWeb';
    const luanchUrl = `${appSchema}/screen/${webTem}?uri=${encodeURIComponent(`${targetUrl}?${Library.Util.url.objectToQueryParams(params)}`)}`;


    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setMaskShow(true);
        } else {
            window.location.href = luanchUrl;
            setTimeout(() => {
                window.location.href = downloadUrl;
            }, 3000);
        }
    }

    return <div className={styles.activity_page}>
        <div className={styles.imageContent}>
            <img src={bgImage} alt="" />
            <div className={styles.btn_box} onClick={() => openApp()}>下载/打开APP</div>
        </div>
        
        {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
            <img src={gotoBrowserImg} alt="" />
        </div>}
    </div>
}

