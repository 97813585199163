import React, { useState, useEffect } from 'react';
import Modal from './modal';
import style from './legal.module.scss';
import purchase from './images/gf@2x.png';
import adviser from './images/gw@2x.png';
import activity from './images/hd@2x.png';
import serve from './images/serve@2x.png';
import especially from './images/especially.png';
import guests from './images/guests.png';
import upgrade from './images/upgrade.png';
import discount from './images/discount.png';
import activityRite from './images/activityRite.png';
import maintain from './images/maintain.png';
import insurance from './images/insurance.png';
import byCar from './images/byCar.png';
import rebate from './images/rebate.png';
import delay from './images/delay.png';
import welcome from './images/welcome.png';
import visa from './images/visa.png';
import travel from './images/travel.png';
import courtesy from './images/courtesy.png';
import shopping from './images/shopping.png'; //购物折扣
import coupon from './images/coupon.png';  // 理财积分券
import doctor from './images/doctor.png'; // 视频医生
import header from './images/header.png';
import memberDay from './images/memberDay.png';
import vipBirthday from './images/vipBirthday.png';
import vip from './images/vip.png';
import vipExplain from './images/vipExplain.png';
import green from './images/green.png';

const PageConfig = {
    data: [{
        title: '房产',
        children: [
            {
                url: purchase,
                title: '购房优惠',
                text: '购买绿地住宅或非住宅产品，享会员专属额外优惠。'
            }, {
                url: adviser,
                title: "置业顾问",
                text: '专业咨询顾问一对一为您解答购房疑问'
            }, {
                url: serve,
                title: '看房服务',
                text: '专业人员一对一全程服务'
            }, {
                url: activity,
                title: '线下活动',
                text: '可优先免费参与线下福利活动，享受项目优惠。'
            }
        ]
    }, {
        title: '酒店',
        children: [
            {
                url: discount,
                title: '预订折扣',
                text: '通过直销渠道预订绿地酒店，享会员专属优惠折扣。'
            }, {
                url: upgrade,
                title: '房型升级',
                text: '入住绿地酒店，酒店房型可免费升高一级。'
            }, {
                url: guests,
                title: "迎宾礼遇",
                text: '入住可得会员专属欢迎软饮料，欢迎水果/小点。'
            }, {
                url: especially,
                title: '入住福利',
                text: '会员生日当天入住获得1磅蛋糕，享会员专属就餐和自助晚餐优惠折扣。'
            },
        ]
    }, {
        title: '汽车',
        children: [
            {
                url: byCar,
                title: '购车礼',
                text: '购车享4S店会员专属VIP大礼包'
            }, {
                url: insurance,
                title: '车险礼',
                text: '购车险享会员专属折扣，赠保养。'
            }, {
                url: maintain,
                title: "保养礼",
                text: '享上门接车，绿色通道，保养工时折扣等会员专属服务。'
            }, {
                url: activityRite,
                title: '活动礼',
                text: '免费参与4S店马术、高尔夫、演唱会、自驾游等会员活动。'
            },
        ]
    }, {
        title: '旅游',
        children: [
            {
                url: travel,
                title: '旅游折扣',
                text: '购买上航旅游指定产品，享会员专属优惠折扣。'

            }, {
                url: courtesy,
                title: "升级礼遇",
                text: '会员等级提升即可获得上航旅游产品优惠券'
            }, {
                url: visa,
                title: '签证服务',
                text: '会员专属客服，享优先服务通道。'
            }
        ]
    }, {
        title: '康养',
        children: [
            {
                url: rebate,
                title: '预订折扣',
                text: '预订绿地康养居酒店及康养旅居线路，享会员专属折扣。'
            }, {
                url: delay,
                title: "延迟退房",
                text: '入住康养居酒店享延迟退房服务'
            }, {
                url: welcome,
                title: '欢迎礼遇',
                text: '入住康养居酒店可得会员专属欢迎软饮料'
            }
        ]
    }],
    list: [
        {
            children: [
                {
                    title: '购物折扣',
                    url: shopping,
                    titleText: '购物',

                    text: '购买G优商城指定商品，享会员专属优惠折扣。'
                }, {
                    title: '理财积分券',
                    url: coupon,
                    titleText: '理财',
                    text: '可免费领取会员专属大额理财积分券，购理财产品，返高额积分。'
                }, {
                    title: '视频医生',
                    url: doctor,
                    titleText: '医疗',
                    text: '和缓名医提供7*24小时家庭医生视频服务，一键免费咨询。'
                }
            ]
        }
    ],
    icon: [
        {
            title: '特别礼遇',
            children: [
                {
                    url: memberDay,
                    title: '会员日大礼包',
                    text: 'G优会员日当天可获得会员专属福利礼包'
                }, {
                    url: vipBirthday,
                    title: '生日大礼包',
                    text: '会员生日当日可获得生日专属福利礼包'
                }, {
                    url: vip,
                    title: '贵宾礼遇',

                    text: '可免费获得梅赛德斯奔驰文化中心贵宾包厢门票和迪士尼贵宾礼遇门票，免费参与绿地申花球迷活动。'
                }
            ]
        }
    ]
}

const Legal = (props) => {
    const [sonData, setsonData] = useState(null)

    const handleClick = (item) => {
        setsonData(item)
    }
    const close = () => {
        setsonData(null);
    }
    return <div className={style.container}>
        <div className={style.header}>
            <img src={header} className={style.headerImg} alt="" />
        </div>
        <div className={style.content}>
            <div className={style.contentText}>
                <div className={style.greenText}>
                    <div className={style.round}></div>
                    覆盖绿地八大业务板块
                    <div className={style.round}></div>
                </div>
                <div className={style.lineBox}>
                    <div className={style.line}></div>
                </div>
            </div>
            <div className={style.iconBox}>
                {
                    PageConfig.data.map((item, index) => {
                        return <div className={style.iconView} key={`children_${index}`}>
                            <div className={style.textLine}>
                                <div className={style.titleCenter}>
                                    <div className={style.toRight}></div>
                                    <span className={style.iconTitle}>{item.title}</span>
                                    <div className={style.toLeft}></div>
                                </div>
                            </div>
                            <div className={style.icon}>
                                {item.children.map((cItem, cIndex) => {
                                    return <div key={`children_${index}_${cIndex}`} className={style.imgBox} onClick={() => handleClick(cItem)}>
                                        <img className={style.imgIcon} src={cItem.url} alt="" />
                                        <p className={style.imgFont}>{cItem.title}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    })
                }
                {
                    PageConfig.list.map((item, index) => {
                        return <div className={style.threeTop} key={`list_${index}`}>
                            <div className={style.threeLine}>
                                {
                                    item.children.map((cItem, cIndex) => {
                                        return <div className={style.titleCenter} key={`list_${index}_${cIndex}`}>
                                            <div className={style.toRight}></div>
                                            <span className={style.iconTitle}>{cItem.titleText}</span>
                                            <div className={style.toLeft}></div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className={style.icon}>
                                {
                                    item.children.map((cItem, cIndex) => {
                                        return <div className={style.imgBox} onClick={() => handleClick(cItem)} key={`list_${index}_${cIndex}`}>
                                            <img className={style.imgIcon} src={cItem.url} alt="" />
                                            <p className={style.imgFont}>{cItem.title}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
                {
                    PageConfig.icon.map((item, index) => {
                        return <div className={style.iconView} key={`list_${index}`}>
                            <div className={style.wellLine}>
                                <div className={style.titleCenter}>
                                    <div className={style.toRight}></div>
                                    <span className={style.iconTitle}>{item.title}</span>
                                    <div className={style.toLeft}></div>
                                </div>
                            </div>
                            <div className={style.icon}>
                                {item.children.map((cItem, cIndex) => {
                                    return <div className={style.wellBox} onClick={() => handleClick(cItem)} key={`list_${index}_${cIndex}`}>
                                        <img className={style.imgIcon} src={cItem.url} alt="" />
                                        <p className={style.imgFont}>{cItem.title}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    })
                }
            </div>
            <div className={style.shadow}></div>
            <div className={style.vip}>
                <img src={vipExplain} className={style.vipExplain} alt="" />
            </div>
            <div className={style.greenLand}>
                <div className={style.greenBox}>
                    <img className={style.greenLandImg} src={green} alt="" />
                </div>
                <div className={style.gy}>
                    {/* <p className={style.gyText}>会员权益最终解释权归绿地G优所有</p> */}
                </div>
            </div>
        </div>
        <Modal sonData={sonData} close={close}></Modal>
    </div>
}

export default Legal