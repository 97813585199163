import React, { useState, useEffect, useRef } from 'react'
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import { useHistory } from 'react-router-dom';
import styles from './join.module.scss'
import inviteBg from './images/inviteBg@2x.png';
const { JsBridge, Util } = Library;
let SwiperNc = null;
export default (props) => {
    const [mobile, setMobile] = useState('');
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const history = useHistory();
    const contentRef = useRef();
    const { inviteCode = '', activityCode = '' } = Util.url.paramsToObj();//teamStatus:0:加入战队，3:邀请助力
    useEffect(() => {
        initNC();
    }, [])

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };


    const bindClient = async () => {
        if (!Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res && mobile && mobile.replace(/\s/g, '').length >= 11) {
                let result = await Services('BindClient', 'BindMemberDay', { inviteCode, activityCode, mobile, ...res });
                if (result.status > 0) {
                    history.replace(`/front/activities/memberDay/success`)
                }
            }
        }
    }

    return <div className={`${styles.container} ${styles.background1}`}>
        <div className={styles.blurBg}></div>
        <div className={styles.content} ref={contentRef}>
            <img src={inviteBg} className={styles.img} alt='背景图' />
            <label className={styles.tel}>
                <input type="tel" maxLength={11} value={mobile} className={styles.telInput} placeholder='输入手机号，为好友助力'
                    onFocus={() => {
                        contentRef.current.scrollTo({ top: 1000, behavior: 'smooth' });
                    }}
                    onChange={(event) => setMobile(event.currentTarget.value)} />
            </label>
        </div>
        <div className={styles.safe}>
            <div className={styles.btnWrapper}>
                <div className={styles.inviteButton} onClick={() => {
                    bindClient();
                }}>立即助力</div>
            </div>
        </div>
    </div>
}
