/**
 * @description 商品推荐模块
*/

import React, { useState, useEffect, Fragment } from 'react';
import Library from 'library';
import Service from '../../service';
import Styles from './index.module.scss';
const { JsBridge } = Library;

const defaultData = [
    {
        cateId: '0',
        cateName: '双旦活动',
        goodsSimplifyList: [
            {
                marketPrice: '213',
                maxDeductPoint: '122',
                goodsImg: 'https://img2.baidu.com/it/u=1133371837,3614664115&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
                goodsInfoName: 'Moschino梦仙奴泰迪两瓶装',
                goodsInfoId: '',
                discount: '0.7'
            }
        ]
    }
]

const ShopRecommend = (props) => {

    const [listArray, setListArray] = useState([]);

    useEffect(() => {
        if (process.env.NODE_ENV == 'development') {
            const datas = defaultData.map(group => {
                const content = Array(6).fill().reduce((pre, _) => {
                    return [...pre, ...group.goodsSimplifyList]
                }, [])
                group.goodsSimplifyList = content
                return group
            })

            setListArray(datas);
        }

        Service.getRecommendShopList().then(res => {
            console.log('getRecommendShopList', res)
            if (res) {
                setListArray(res?.data);
            }

        })
    }, []);

    return (
        <div className={Styles.shop_recomend} >
            {
                listArray.map((tab, k) => {
                    return (
                        <Fragment key={k}>
                            <div className={Styles.header}>
                                <img src={require('images/newYear/remmend_header.png')} />
                            </div>
                            <div className={Styles.shop_recomend_content}>
                                {
                                    tab.goodsSimplifyList &&
                                    tab.goodsSimplifyList.map((item, idx) => {
                                        return (<ShopItem key={idx} item={item} />)
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                })
            }
        </div>
    )
}

const ShopItem = (props) => {
    const { item = {} } = props

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        const skuId = data.goodsInfoId
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
    }

    const originalPrice = Number(item.marketPrice);
    const pointPrice = Number(item.maxDeductPoint || 0) / 100;
    //最终价
    const actualPrice = Math.max(Number(((originalPrice * (item.discount || 1)) - pointPrice).toFixed(2)), 0);

    return (
        <div className={Styles.shop_item} onClick={() => onShopItemClick(item)}>
            <div className={Styles.shop_item_in}>
                <div className={Styles.left}>
                    <div className={Styles.conent}>
                        <div className={Styles.tag}> {'购买返3倍积分'}</div>
                        <img src={item.goodsImg} alt="" />
                    </div>
                </div>
                <div className={Styles.right}>
                    <p>{item.goodsInfoName}</p>
                    <div className={Styles.priceLine}>
                        <div>
                            <div>¥<span>{originalPrice}</span></div>
                            <div>原价</div>
                        </div>
                        <div>
                            <div>¥<span>{pointPrice}</span></div>
                            <div>积分可抵</div>
                        </div>
                    </div>
                    <div className={Styles.finalPrice}>
                        <div>
                            <span>到手价</span>
                            <span>{actualPrice}</span>
                        </div>
                        <div>去抢购</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopRecommend;