/**
 * @description 商品推荐模块
*/

import React, { useState, useEffect, useMemo, Fragment } from 'react';
import Library from 'library';
import Service from '../../service';
import Styles from './index.module.scss';
const { JsBridge } = Library;

const ShopRecommend = () => {

    const [listArray, setListArray] = useState([]);

    useEffect(() => {
        Service.getRecommendShopList().then(res => {
            console.log('getRecommendShopList', res)
            if (res) {
                setListArray(res?.data);
            }

        })
    }, []);

    return (
        <div className={Styles.shop_recomend} >
            {
                listArray.map((tab, k) => {
                    return (
                        <Fragment key={k}>
                            <div className={Styles.header}>
                                <img src={require('images/double11/reemmend_header.png')} />
                            </div>
                            <div className={Styles.shop_recomend_content}>
                                {
                                    tab.goodsSimplifyList &&
                                    tab.goodsSimplifyList.map((item, idx) => {
                                        return (<ShopItem key={idx} item={item} />)
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                })
            }
        </div>
    )
}

const ShopItem = (props) => {
    const { item = {} } = props

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        const skuId = data.goodsInfoId
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
    }

    const originalPrice = Number(item.marketPrice);
    const pointPrice = Number(item.maxDeductPoint || 0) / 100;
    //最终价
    const actualPrice = Math.max(Number(((originalPrice * (item.discount || 1)) - pointPrice).toFixed(2)), 0);

    return (
        <div className={Styles.goods_item} onClick={() => onShopItemClick(item)}>
            <div className={Styles.item_box}>
                <div className={Styles.top_content}>
                    <img className={Styles.goodsImg} src={item.goodsImg} alt="" />
                    <div className={Styles.priceLine}>
                        <div>
                            <div>原价</div>
                            <div>{originalPrice.toFixed(2)}</div>
                        </div>
                        <div>
                            <div>积分抵</div>
                            <div>{pointPrice.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
                <div className={Styles.bottom_content}>
                    <p>{item.goodsInfoName}</p>
                    <div className={Styles.finalPrice}>
                        <span>双11到手价：</span>
                        <span>{actualPrice.toFixed(2)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopRecommend;