import React, { useEffect, useState } from 'react';
import styles from './inviter.module.scss';
import { useHistory } from 'react-router-dom';
import {Slogan} from 'components/slogan';
import { InviteCard, ActivityTime } from './component'
import Library from 'library';
import Services from 'services';
// import { umengTrack_invite } from 'library/jsbridge/config'
const { Platform, JsBridge } = Library;

const trackEvent = (key) => {
    window._czc && window._czc.push(["_trackEvent", '日常分享活动', '点击', key, 1]);
}

export const Inviter = () => {
    const history = useHistory();
    const [status, setStatus] = useState('hide');
    const [isLogin, setLogin] = useState(false);
    const [productList, setProductList] = useState([]);
    const [userInfo, setUserInfo] = useState({
        inviteNums: 0,
        rankNow: 0,
        myPoint: 0,
        mobile: '',
        inviteCode: '',
        myShareImage: '',
        rewardPoint: 0,
        dayInviteClientPointLimit: 0,
    });
    let isLoginComponent;
    const [rankInfos, setRanksInfo] = useState([]);

    let isArrived = false;
    let isFirstSet = false;
    useEffect(() => {
        const { pathname } = history.location;
        if (pathname === '/front/activities/regularShare/inviter') {
            userIsRegistered();
        }

    }, [history.location.pathname]);
    const userIsRegistered = async () => {
        const result = Platform.check.isApp() ? await Services('MainPage', { code: '101148' }, { pageNum: 1, pageSize: 100, activity: 'INVITECLIE' }) : await Services('GuMember', 'IsRegisted', { activity: 'INVITECLIE' });
        const { status } = result;

        if (status > 0) {
            const { msg, data } = result.data;
            if (msg === 1 || Platform.check.isApp()) {
                setLogin(true);
                setUserInfo({
                    inviteNums: data.inviteNums,
                    rankNow: data.rankNow,
                    myPoint: data.myPoint,
                    mobile: data.mobile,
                    inviteCode: data.inviteCode,
                    myShareImage: data.myShareImage,
                    rewardPoint: data.inviteNewClientPoint,
                    dayInviteClientPointLimit: data.dayInviteClientPointLimit,
                })
                setProductList(data.productList);
            }
        }
    };

    if (!isLogin) {
        isLoginComponent = <div className={`${styles.loginBtn} ${styles.btn}`} onClick={() => {
            jumpToLogin(false);
        }}><span className={styles.btnText}>登录查看</span></div >
    } else {
        isLoginComponent = <>
            <div className={styles.userInfo}>{'用户' + Library.Util.format.mobileSecurity(userInfo.mobile)}</div>
            <div className={styles.userInviteNum}>
                <div className={styles.inviteNumber}>
                    <div className={styles.userTitle}>成功邀请好友</div>
                    <div className={styles.userCount}>{userInfo.inviteNums}</div>
                </div>
                <div className={styles.userPercent}>
                    <div className={styles.userTitle}>我的积分</div>
                    <div className={styles.userCount}>{userInfo.myPoint}</div>
                </div>
            </div>
        </>
    }

    const jumpToLogin = (isShare) => {
        if (!isLogin) {
            history.push('/front/activities/regularShare/inviter/login', {
                isShare: isShare,
                myShareImage: userInfo.myShareImage
            });//没有登陆登陆
        } else {
            trackEvent('点击分享按钮');
            history.push('/front/activities/regularShare/inviter/shareImage/' + userInfo.inviteCode||'xxxx', {
                myShareImage: userInfo.myShareImage,
                rewardPoint: userInfo.rewardPoint
            });
        }
    };

    const getRules = () => {
        history.push('/front/activities/regularShare/rule', { rewardPoint: userInfo.rewardPoint, dayInviteClientPointLimit: userInfo.dayInviteClientPointLimit || 100 });
    };

    let Modal = () => {
        return <div className={`${styles.modal} ${status === 'show' ? styles.show : styles.hide}`}>
            <div className={styles.modalWrap}>
                <div className={styles.modalTitle}>温馨提示</div>
                <div className={styles.modalContent}>请前往“绿地G优”APP使用积分兑换此产品</div>
                <div className={styles.modalButton}>
                    <div className={styles.cancel} onClick={() => setStatus('hide')}>取消</div>
                    <div className={styles.open} onClick={() => {
                        setStatus('hide');
                        window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
                    }}>打开APP</div>
                </div>
            </div>
        </div>
    };

    return <div className={styles.container}>
        <div className={styles.pic}>
            <div className={styles.rules} onClick={getRules}>活动规则</div>
            <div className={styles.msg}>邀新赚{userInfo.rewardPoint}积分，好礼兑不停</div>
            {/*<div className={styles.time}>活动时间：<ActivityTime isJump={true}/></div> */}
        </div>
        <div className={`${styles.inviteBox} ${styles.clearfix}`}>
            <div className={`${styles.top} ${styles.shadow}`}>

                <InviteCard rewardPoint={userInfo.rewardPoint} buttonText='立即邀请' onClick={() => { jumpToLogin(true) }} />
            </div>
            <div className={`${styles.myInvite} ${styles.shadow}`}>
                <div className={styles.title}>我的邀请</div>
                <div className={styles.yaoqing_record}
                     onClick={()=>{
                         JsBridge.open('/front/activities/dailySignin/inviteRecord?activityCode=INVITECLIE');
                     }}>{'邀请记录>>'}</div>
                {isLoginComponent}
            </div>
            {Platform.check.isApp() ? <>
                <div className={styles.mallExchange}>
                    积分兑换
                </div>
                <div className={styles.exchangeItemWrap}>
                    {productList.length > 0 && productList.map((item, index) => {
                        return <div key={index} className={styles.productWrap}
                            onClick={() => {
                                if (Platform.check.isApp()) {
                                    JsBridge.open(`/taro/subPages/user/mall/product/index?goodsId=${item.productId}&goodsType=${item.merchant === '1' ? 'limit' : 'hot'}`);
                                } else {
                                    setStatus('show')
                                }
                            }}>
                            <div className={styles.thumbnail}>
                                <img src={item.url} className={styles.img} alt='商品图片' />
                            </div>
                            <div className={styles.ItemContent}>
                                <span className={styles.title}>{item.productName}</span>
                                <div className={styles.bottomView}>
                                    <span className={styles.text1}>{item.pointCost}</span>
                                    <span className={styles.unit}>积分</span>
                                    {item.moneyCost ? <span className={styles.text2}>￥{item.moneyCost}</span> : null}
                                </div>
                            </div>
                        </div>
                    })}
                </div></>
                : <div className={`${styles.myInvite} ${styles.shadow}`}>
                    <div className={styles.hot}>热门兑换</div>
                    <div className={styles.picList} onClick={() => {
                        setStatus('show')
                    }}>
                    </div></div>}
            <Slogan className={styles.slogan} />
            <Modal />
            <div className={styles.footer}>
                <div className={styles.footerWrap}>
                    <div className={styles.footerButton} onClick={() => {
                        jumpToLogin && jumpToLogin();
                    }}>
                        <p>立即邀请</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
