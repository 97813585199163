/**
 * @description 点赞数量领取进度模块
*/
import React, { useMemo } from 'react';
import { Toast } from 'antd-mobile';
import Service from '../../service';
import ReceiveModal from '../receiveModal'
import './index.scss';
import Library from 'library';
const { JsBridge } = Library;


const LikesProgress = (props)=> {

    const { 
        activityInfo={},
        refreshActivityInfo
    } = props

    // 领取奖励
    const onReceiveGive = async (st)=> {
        console.log('领取奖励')
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        const result = await Service.receiveGift({type: st.type});
        JsBridge.print(result);
        if(result) {
            ReceiveModal.open({callBack: null, prizeInfo: result.data});
            setTimeout(()=>{
                refreshActivityInfo && refreshActivityInfo()
            },500)
            
        }
        
    }  
    const Steps = useMemo(()=>{
        const item1 = {
            stepCount: 20,
            productId: activityInfo.lowLevelProductId,
            status: activityInfo.lowLevelStatus,
            stock: activityInfo.lowLevelCount,
            type: 'lowLevelProduct'
        }
        const item2 = {
            stepCount: 40,
            productId: activityInfo.middleLevelProductId,
            status: activityInfo.middleLevelStatus,
            stock: activityInfo.middleLevelCount,
            type: 'middleLevelProduct',
        }
        const item3 = {
            stepCount: 60,
            productId: activityInfo.highLevelProductId,
            status: activityInfo.highLevelStatus,
            stock: activityInfo.highLevelCount,
            type: 'highLevelProduct',
        }
        return [item1,item2,item3];
    },[activityInfo])

    const progress = useMemo(()=>{
        const count = activityInfo.thumbsUpAmount || 0
        const totle = Steps[Steps.length -1]
        if(totle.stepCount > count) {
            return count / totle.stepCount * 100
        }
        return 100
    },[activityInfo.thumbsUpAmount])

    return (
        <div className='likes-progress'>
            <img className='shop-img' src={require('images/mothersDay/pic_flo.png')} alt="" />
            <div className='task-progress'>
                <div className='progress-view'>
                    <div className='progress-inner' style={{width: progress + '%'}}></div>
                </div>
                {
                    Steps.map((st,i)=> {
                        return (
                            <div className='grad' key={i}>
                                {
                                    activityInfo.thumbsUpAmount >= st.stepCount && !st.status ?
                                    <div className={'recive-btn'} onClick={()=>onReceiveGive(st)}>
                                        <span>{'领取'}</span>
                                    </div>:
                                    <div className='progress-dot'>
                                       {
                                        st.status ?
                                        <span className='recive'>{'已领取'}</span>
                                        :
                                        <span>{`${activityInfo.thumbsUpAmount||0}/${st.stepCount}`}</span>
                                       } 
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LikesProgress;