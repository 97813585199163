import React from "react";
import './worldCupDoubleRulesModal.scss';
import { Mask } from 'antd-mobile';

const DoubleRulesModal = ({doubleRulesModalVisible, onClose, pointsRules}) => {
  if(!pointsRules) return null;
  return <Mask visible={doubleRulesModalVisible} className="world-cup-double-rules-mask">
    <div className="world-cup-rules-modal">
      <div className="world-cup-rules-title">
        <span>连胜系数规则</span>
      </div>
      <div className="world-cup-rules-content">
        <div className="rules-table-title">
          <span>连续猜中场数</span>
          <span>连胜系数</span>
        </div>
        {pointsRules.length > 0 && pointsRules.map((item, index) => {
          return <div className="rules-table-content" key={`rules-${index}`}>
            <div className="rules-table-item">
              <span>{item.continueWinGamesCounts}</span>
              <span>{`${item.pointCoefficient}${index === 0 || index === pointsRules.length-1 ? '（系统自动结算）': ''}`}</span>
            </div>
          </div>
        })}
      </div>
      <div className="rules-modal-btn" onClick={onClose}>我知道了</div>
    </div>
  </Mask>
}

export default DoubleRulesModal;