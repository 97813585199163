import CarbonIntegralHome from "./home"
import ExchangeRecord from "./components/exchangeRecord"
import EnviroPro from './enviroPro'
import InviteSuccess from './inviteSuccess'
export default {
  CarbonIntegralHome,
  ExchangeRecord,
  EnviroPro,
  InviteSuccess,
}
