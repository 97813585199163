import React from "react";
import styles from './styles.module.scss';

export default ({
  img = '',
  text = '',
  count = 20,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <span className={styles.small}>{text}</span>
    </div>
    <div className={styles.box}>
      <img src={img} />
      <p>集赞{count}得</p>
    </div>
  </div>
)