import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Config } from './config';
import styles from './home.module.scss';
import pointOne from './images/pointOne.png';
import pointTwo from './images/pointTwo.png';
import pointThree from './images/pointThree.png';
import pointFour from './images/pointFour.png';
import pointFive from './images/pointFive.png';
import headOne from './images/headOne.png';
import headTwo from './images/headTwo.png';
import headThree from './images/headThree.png';
import { Modal } from './components/modal';
import { CreateTeam } from './components/createTeam';
import rect from "./images/rect.png";
import head from "./images/head.png";
import { End } from "./end";
import { useMemo } from 'react';
import icon3 from './images/icon3.png';
const { JsBridge } = Library;
let SwiperNc = null;
export const Home = (props) => {
    const history = useHistory();
    const { activityCode } = useParams();
    const [disable, setDisable] = useState(false);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const [visible, setVisible] = useState(false);
    const [createVisible, setCreateVisible] = useState(false);
    const [state, setParams] = useState({
        startTime: "",
        endTime: "",
        currentTime:'',
        nextActivityCode:'',
        amount: 0,
        teamNums: 3,
        imagePath: head,
        teamInviteCode: 'a1',
        supportList: [],
        captain: "0",
        teamName: "",
        teamStatus: "",
        jktActivity: {  //活动信息
            "id": 40,
            "title": "迪士尼助力活动",
            "type": 0,
            "code": "DISNEYTEAM",
            "imageUrl": "5e2c2ec6-50ad-4960-a996-7e39a370a729.png",
            "thumbnailUrl": "65643bfd-272f-46ea-8e55-cd2202379407.png",
            "link": "www.baidu.com",
            "amount": 1,
            "status": 0,
            "startTime": "2021.08.01 00:00:00",
            "endTime": "2021-10-01 00:00:00",
            "createTime": "2021-08-10 09:59:11",
            "updateTime": "2021-08-10 09:59:11",
            "openSwitch": 0,
            "platform": 1
        },
        activityCode: "DISNEYTEAM",
        jktActivityTeam: {  //
            "createTime": "2021-08-06 14:06:09",
            "updateTime": "2021-08-09 17:46:42",
            "remark": null,
            "page": 1,
            "rows": 0,
            "startPage": 0,
            "endPage": 0,
            "id": "-1",
            "teamName": "",
            "teamInviteCode": "",
            "activityCode": "DISNEYTEAM",
            "leaderClientId": "",
            "leaderLoginName": "",
            "amount": 0,
            "teamStatus": "02"
        },
        totalTeamNums: 5,
        inviteCode: '',
        rankList: [],
        myTeamList: [],
        myTeamRank: 3,
        rule: '',
        awardsName: ''
    });
    const info = [{ head: "实名组建团队", title: "您还未实名，实名认证方可组建战队", confirm: "去实名" }, { head: "完成组建团队", title: "完成组建后不可更改，确定组建？", confirm: "确定组建" }];
    const [infoIndex, setInfoIndex] = useState(0);
    const [teamMember, setTeamMember] = useState([]);
    const [stateTime,setStateTime] = useState({showCountDown:false,
        hour:'00',
        minute:'00',
        seconds:'00'})
    const stateRef = useRef();
    const marqueeRef = useRef();
    const isInit = useRef(false);
    const setState = (params) => {
        let mergeState = {};
        Object.assign(mergeState, state, params);
        setParams(mergeState);
        stateRef.current = mergeState;
    };

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `/front/activities/disney/home/${activityCode}`) {
            getData();
        }
    }, [location]);
    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };
    const createTeam = async () => {
        const result = await Services('MainPage', { code: '300420' }, { activityCode: activityCode || "" });
        if (result.data && result.data.isSuccess === true) {
            await getData();
        }
    };
    const buildTeam = async () => {
        await Services('MainPage', { code: '300426' }, { teamId: state.jktActivityTeam.id || "" });
        getData();
    };
    const goVerify = () => {
        JsBridge.addListener().then(() => {
            getData();
        });
        JsBridge.navigate('Verify');
    };

    const getPic = (name) => {
        let p = Config?.[activityCode]?.[name] || Config?.['DISNEYTEAM']?.[name];
        return p;
    };

    const abs = (amount) => {
        if (amount >= 10000) {
            return (amount / 10000).toFixed(2) + '万';
        } else {
            return amount + '';
        }
    };
    let flag = state?.supportList?.filter((item) => {
        return item.status === 1 || item.todayStatus === 1;
    })?.length > 0;
    let isRealName = !(teamMember.filter((item) => {
        return item.isRealName === '0';
    }).length > 0);
    let init = state?.myTeamList?.length === 0 ? 5 : 0;
    const timerRef = useRef();
    const timeStateRef = useRef(null);
    const setDate = (data) => {
        let { currentTime, nextActivityCode='', endTime } = data;
        // endTime = '2021.10.22 15:25:00';
        let strEndTime = endTime.replace(/\./g, '/');
        let strServerTime = currentTime.replace(/-/g, '/');
        let now = new Date();
        let serverTime = new Date(strServerTime);
        let endDate = new Date(strEndTime);
        timeStateRef.current = {offset: serverTime.getTime() - now.getTime(),endTime:endDate.getTime(),nextActivityCode}
        if (serverTime.getTime() > endDate.getTime()) {
            setDisable(true);
        } else if (strEndTime.substr(0,10) === strServerTime.substr(0,10)) {
            //当天开始计时器
            clearInterval(timerRef.current);
            timerRef.current = setInterval(startCountDown,500);
        }
    }

    const startCountDown = () => {
        let now = new Date();
        let {offset,endTime,nextActivityCode} = timeStateRef.current;
        let remainTime = endTime - now.getTime() + offset;
        if (remainTime > 0 && remainTime < 5 * 60 * 1000) {
            let minute = parseInt(remainTime/60000,10);
            let strMinute = '';
            if (minute >= 10) {
                strMinute = ''+minute;
            } else {
                strMinute = '0'+minute;
            }

            let seconds = parseInt((remainTime/1000)%60,10);
            let strSeconds = '';
            if (seconds >= 10) {
                strSeconds = ''+seconds;
            } else {
                strSeconds = '0'+seconds;
            }
            setStateTime({hour:'00',minute:strMinute,seconds:strSeconds,showCountDown:true})
        } else if (remainTime <= 0) {
            setDisable(true);
            clearInterval(timerRef.current);
        }
    }
    const getData = async () => {
        const result = await Services('MainPage', { code: '300424' }, { activityCode });
        JsBridge.print(JSON.stringify(result));
        if (result.status > 0) {
            isRealName = true; // 每次控制初始化实名（默认都是实名）
            setDate(result.data);
            const _teamMember = [];
            const _myTeamList = result.data.myTeamList;
            for (let i = 0; i < 5; i++) {
                if (_myTeamList.length <= 0) {
                    _teamMember.push(1);
                } else if (_myTeamList[i]) {
                    _teamMember.push(_myTeamList[i]);
                } else {
                    _teamMember.push(0);
                }
            }
            setState(result.data);
            setTeamMember(_teamMember);
            isInit.current = true;
            return;
        }
    };

    const support = async (teamId) => {
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('MainPage', { code: '300427' }, { activityCode, teamId: teamId, ...res });
                if (result.status > 0) {
                    getData();
                }
            }
        }

    }

    useEffect(() => {
        document.body.style.background = '#57379E';
        initNC();
        getData();
        return () => {
            document.body.style.background = 'transparent';
            clearInterval(timerRef.current);
        };
    }, []);



    const renderSentai = () => {
        return <div className={styles.contentBackground}>
            <div className={styles.titleBG}>
                <p className={styles.title}>战队排行榜</p>
            </div>
            <div className={styles.contentFirst}>
                <div className={styles.contentSecond}>
                    <div className={styles.contentline}>
                    </div>
                    <div className={styles.contentblank}>
                        <div className={styles.contentInner}>
                            <div className={styles.contentSentai}>
                                <div className={styles.rankFour}>
                                    <div className={styles.wrap}>
                                        <div className={styles.wrapSecond}>
                                            <div className={styles.profile}>
                                                <img src={state.rankList[3] && state.rankList[3].imagePath || head} alt="profile" />
                                            </div>
                                            <div className={styles.point}>
                                                <img src={pointFour} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.wrapWord}>
                                        <div className={styles.wordUp}>{state.rankList[3] && state.rankList[3].teamName || ""}</div>
                                        <div className={styles.wordDown}>{state.rankList[3] && abs(state.rankList[3].amount) || ""}</div>
                                    </div>
                                </div>
                                <div className={styles.rankTwo}>
                                    <div className={styles.wrap}>
                                        <div className={styles.wrapSecond}>
                                            <div className={styles.profile}>
                                                <img src={state.rankList[1] && state.rankList[1].imagePath || head} alt="profile" />
                                            </div>
                                            <div className={styles.head}>
                                                <img src={headTwo} />
                                            </div>
                                            <div className={styles.point}>
                                                <img src={pointTwo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.wrapWord}>
                                        <div className={styles.wordUp}>{state.rankList[1] && state.rankList[1].teamName || ""}</div>
                                        <div className={styles.wordDown}>{state.rankList[1] && abs(state.rankList[1].amount) || ""}</div>
                                    </div>
                                </div>
                                <div className={styles.rankOne}>
                                    <div className={styles.wrap}>
                                        <div className={styles.wrapSecond}>
                                            <div className={styles.profile}>
                                                <img src={state.rankList[0] && state.rankList[0].imagePath || head} alt="profile" />
                                            </div>
                                            <div className={styles.head}>
                                                <img src={headOne} />
                                            </div>
                                            <div className={styles.point}>
                                                <img src={pointOne} />
                                            </div>
                                            <div className={styles.wrapWord}>
                                                <div className={styles.wordUp}>{state.rankList[0] && state.rankList[0].teamName || ""}</div>
                                                <div className={styles.wordDown}>{state.rankList[0] && abs(state.rankList[0].amount) || ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.rankThree}>
                                    <div className={styles.wrap}>
                                        <div className={styles.wrapSecond}>
                                            <div className={styles.profile}>
                                                <img src={state.rankList[2] && state.rankList[2].imagePath || head} alt="profile" />
                                            </div>
                                            <div className={styles.head}>
                                                <img src={headThree} />
                                            </div>
                                            <div className={styles.point}>
                                                <img src={pointThree} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.wrapWord}>
                                        <div className={styles.wordUp}>{state.rankList[2] && state.rankList[2].teamName || ""}</div>
                                        <div className={styles.wordDown}>{state.rankList[2] && abs(state.rankList[2].amount) || ""}</div>
                                    </div>

                                </div>
                                <div className={styles.rankFive}>
                                    <div className={styles.wrap}>
                                        <div className={styles.wrapSecond}>
                                            <div className={styles.profile}>
                                                <img src={state.rankList[4] && state.rankList[4].imagePath || head} alt="profile" />
                                            </div>
                                            <div className={styles.point}>
                                                <img src={pointFive} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.wrapWord}>
                                        <div className={styles.wordUp}>{state.rankList[4] && state.rankList[4].teamName || ""}</div>
                                        <div className={styles.wordDown}>{state.rankList[4] && abs(state.rankList[4].amount) || ""}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.contentInnerBg}></div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderMy = () => {
        return <div className={styles.contentBackground}>
            <div className={styles.titleBG}>
                <p className={styles.title}>我的战队</p>
            </div>
            <div className={`${styles.contentFirst} ${styles.contentMy}`}>
                <div className={styles.contentSecond}>
                    {state.teamStatus !== 3 ? "" : <div className={styles.secondWord}><span >战力值: {abs(state.jktActivityTeam.amount) || 0}</span> <span>名次: {state.myTeamRank || 0}</span></div>}
                    <div className={styles.contentline}>
                    </div>
                    <div className={`${styles.contentblank} ${init === 5 ? styles.myBlankFirst : state.teamStatus !== 3 && state.captain === "1" ? styles.myBlank : styles.myBlankSecond}`}>
                        <div className={styles.myTeam}>
                            {init === 5 ? "" : <div className={styles.teamFirst}><div className={styles.teamName}>{state.teamName || ''}</div>{state.captain === '1' && state.teamStatus !== 3 ? <div className={styles.teamEdit} onClick={() => {
                                if (disable) {
                                    return;
                                }
                                JsBridge.addListener().then(()=>{
                                    getData();
                                });
                                JsBridge.navigate('CustomWeb',{uri:`${window.location.origin}/front/activities/disney/editTeam?teamId=${state.jktActivityTeam.id || ""}&activityCode=${state.activityCode}`})
                                // history.push(activityCode + '/editTeam', { teamId: state.jktActivityTeam.id || "", activityCode: state.activityCode });
                            }}>编辑战队</div> : ""}</div>}
                            <div className={styles.teamSecond}>
                                {teamMember.map((item, index) => {
                                    if (item === 0 || item === 1) {
                                        return <div className={styles.inviteTeam} onClick={() => {
                                            if (disable) {
                                                return;
                                            }
                                            if (item === 0) {
                                                history.push(activityCode + '/share', { type: state.teamStatus !== 3 ? 1 : 2, inviteCode: state.inviteCode, teamInviteCode: state.teamInviteCode, teamStatus: state.teamStatus, activityCode, tickets: state.tickets });
                                            }
                                        }}>
                                            <div className={styles.inviteCircle}>+</div>
                                            <div className={styles.inviteblank} />
                                            <div className={styles.inviteName}>{item === 1 ? "虚位以待" : "招募队友"}</div>
                                        </div>
                                    } else {
                                        return <div className={styles.inviteTeam}>
                                            <div className={`${styles.inviteCircle} ${styles.inviteCircleHas}`}>
                                                <img src={item.imagePath} />
                                                {item.captain === "1" ? <div className={styles.captain}><span>队长</span></div> : ""}
                                                {item.isRealName === "1" ? "" : <div className={styles.inviteValidate}><span>未实名</span></div>}
                                            </div>
                                            {item.isMe === "1" ? <div className={styles.inviteTitle}>我</div> : <div className={styles.inviteblank} />}
                                            {<div className={styles.inviteNameTitle}>{item.realName}</div>}
                                            {item.isRealName === "0" && item.isMe === "1" ?
                                                <div className={styles.certification} onClick={() => {
                                                    setInfoIndex(0);
                                                    setVisible(true);
                                                }}><div>去实名</div></div> : ""}
                                        </div>
                                    }
                                })}
                            </div>
                            {state.captain === "1" ? init === 5 ? "" : state.teamStatus === 3 ? "" : state.myTeamList.length === 5 && isRealName === true ? <div className={styles.teamThird}>
                                <div className={`${styles.teamFinishNow} ${disable ? styles.disable : ''}`} onClick={() => {
                                    if (disable) {
                                        return
                                    }
                                    setInfoIndex(1);
                                    setVisible(true)
                                }}>完成战队组建</div>
                            </div> : <div className={styles.teamThird} >
                                <div className={styles.teamFinish}>完成战队组建</div>
                            </div> : ""}
                            {state.teamStatus !== 3 ? <div className={styles.teamfouth}>
                                <div className={styles.teamTip}>队员需实名认证方可加入战队</div>
                            </div> : ""}
                        </div>
                        <div className={styles.contentInnerBg}></div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderSupportList = ()=>{
        return state.supportList.length > 0
                ? <div className={styles.contentBackground}>
                    <div className={styles.titleBG}>
                        <p className={styles.title}>我的助力</p>
                    </div>
                    <div className={styles.mycontentFirst}>
                        <div className={styles.mycontentSecond}>
                            <div className={styles.contentline}>
                            </div>
                            {state.supportList.map((item, index) => {
                                let { amount, imagePath, loginName, status, teamId, teamName } = item;
                                let phone = loginName;
                                if (phone.length === 11) {
                                    phone = loginName.replace(/(\d{3})(\d{4})/g, '$1****')
                                }
                                return <div className={styles.item}>
                                    <img className={styles.img} src={imagePath} />
                                    <div className={styles.textContent}>
                                        <p className={styles.text1}>{teamName}的战队</p>
                                        <p className={styles.text2}>人气值：{abs(amount || 0)}</p>
                                    </div>
                                    <button className={`${styles.btnHelp} ${disable || flag || status === 1 ? styles.disable : ''}`} onClick={() => {
                                        if (disable || flag || status === 1) {
                                            return;
                                        }
                                        support(teamId);
                                    }}>{status === 1 ? '已助力' : '为他助力'}</button>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                : null
    }

    const renderBottomButton = ()=>{
        return <div className={styles.bottomBtn}>
            <div className={styles.bottomContent}>
                <img className={styles.headImg}
                     src={state.imagePath} />
                <div className={styles.textContent} onClick={() => {
                    if (typeof state.jktActivityTeam.id === "number") {
                        history.push(activityCode + '/modal', { teamId: state.jktActivityTeam.id });
                    }
                }}>
                    {state.teamStatus !== 3 ? <p className={styles.text1}>组建战队</p> : <p className={styles.text1}>{state.teamName || ""}</p>}
                    <div className={styles.Popularity}>
                        {state.teamStatus !== 3 ? <p className={styles.text2}>赢迪士尼vip门票</p> : <><p className={styles.text3}>战力值：{abs(state.jktActivityTeam.amount || 0)}</p><div className={styles.imgUp} /></>}
                    </div>
                </div>
                <div className={styles.fill} />
                {init === 5 ? <button className={`${styles.btn} ${disable ? styles.disable : ''}`} onClick={() => {
                    if (disable || !isInit.current) {
                        return;
                    }
                    if (state.teamName === "") {
                        setInfoIndex(0);
                        setVisible(true);
                        return;
                    }
                    setCreateVisible(true);
                }}>组建战队
                </button> : <button className={`${styles.btn} ${disable ? styles.disable : ''}`} onClick={() => {
                    if (disable) {
                        return;
                    }
                    history.push(activityCode + '/share', { type: state.teamStatus !== 3 ? 1 : 2, inviteCode: state.inviteCode, teamInviteCode: state.teamInviteCode, teamStatus: state.teamStatus, activityCode, tickets: state.tickets });
                }}>{state.teamStatus !== 3 ? '邀好友组队' : '邀好友助力'}
                </button>}
            </div>
        </div>
    }

    //邀请攻略
    const StrategyRender = () => {
        return <div className={styles.contentBackground}>
            <div className={styles.contentFirstElement}>
                {stateTime.showCountDown || disable?<div className={styles.timeCountBg}>
                    <div className={styles.endTimeTitle}>
                        <div className={styles.leftItem}/>
                        <div className={styles.text}>{disable?'本期活动已结束':'距活动结束还剩'}</div>
                        <div className={styles.rightItem}/>
                    </div>
                    {!disable?<div className={styles.timeBg}>
                        <div className={styles.timeNum}>{stateTime.hour}</div>
                        <div className={styles.maohao}>:</div>
                        <div className={styles.timeNum}>{stateTime.minute}</div>
                        <div className={styles.maohao}>:</div>
                        <div className={styles.timeNum}>{stateTime.seconds}</div>
                    </div>:<div className={styles.endText}>下期更精彩，敬请期待！</div>}
                </div>:false}
                <img src={rect} />
            </div>
        </div>;
    };
    // if (disable) {
    //     return <End />
    // }
    return <div className={styles.container}>
        {state.awardsName?<div className={styles.contentTime}>
            <img src={icon3}/>
            <div><marquee direction='left' scrollamount={2} truespeed={true} scrolldelay={30}>{state.awardsName}</marquee></div>
        </div>:false}
        <div className={styles.content}>
            <img className={styles.pic} src={getPic('disney')} mode='widthFix' />
            {StrategyRender()}
            {renderSentai()}
            {renderMy()}
            {renderSupportList()}
            {renderBottomButton()}
            <div className={styles.rule} onClick={() => {
                history.push(activityCode + '/rule', { rule: state.rule });
            }}>
                <p className={styles.text}>活动规则</p>
            </div>
        </div>

        <Modal
            visible={visible}
            content={info[infoIndex]}
            onOK={() => {
                if (infoIndex === 0) {
                    goVerify();
                } else {
                    buildTeam();
                }
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}
        />
        <CreateTeam
            visible={createVisible}
            teamName={state.teamName}
            onOK={async () => {
                await createTeam();
                setCreateVisible(false);
            }}
            onCancel={() => {
                setCreateVisible(false);
            }}
        />
    </div>
};


