import JsBridge from 'library/jsbridge';
import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import styles from './jsbridge.module.scss';
const hotelTopImg = 'https://www.gkewang.com/image/qrcode.png';

export default () => {
    const [bridgeStatus, setBridgeStatus] = useState(0);
    const [titleCount, setTitleCount] = useState(0);
    const [showImage, setShowImage] = useState(null);
    const [showCanvas, setShowCanvas] = useState(false);
    const [isSetLeftClick, setIsSetLeftClick] = useState(false);
    const shareImageRef = useRef();
    useEffect(() => {
        JsBridge.ready().then(() => {
            setBridgeStatus(1);
        });
    }, []);

    const changeTitle = () => {
        JsBridge.setTitle('测试标题' + titleCount);
        setTitleCount(titleCount + 1);
    }

    const setStatusBarLight = () => {
        JsBridge.setStatusBar('setBarStyle', ['light-content']);
    }

    const setStatusBarDark = () => {
        JsBridge.setStatusBar('setBarStyle', ['dark-content']);
    }

    const getStatusBarHeight = async () => {
        const result = await JsBridge.getStatusBarHeight();
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)])
    }

    const openModal = async () => {
        const result = await JsBridge.runAction('commonModal', 'show', ['打开弹窗', '温馨提示', [{ name: '取消' }, { name: '确定' }]]);
        JsBridge.runAction('commonModal', 'hide');
        JsBridge.runAction('alert', 'error', [`用户选择按钮为：“${result}”`]);
    }

    const getUserInfo = async () => {
        const result = await JsBridge.runServices('User', 'Center', [{}]);
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const setHeaderLeftClick = async () => {
        if (isSetLeftClick) {
            JsBridge.runAction('alert', 'error', ['恢复了返回按钮，可以返回']);
            const result = await JsBridge.setHeaderLeftClick();
            JsBridge.close();
        } else {
            JsBridge.runAction('alert', 'error', ['已经修改返回按钮，现在点击已经无法返回']);
            const setLeftClick = async () => {
                const result = await JsBridge.setHeaderLeftClick();
                JsBridge.runAction('alert', 'error', ['点击了返回按钮']);
                setLeftClick();
            }
            setLeftClick();
        }
        setIsSetLeftClick(!isSetLeftClick);
    }

    const openNewPage = async () => {
        JsBridge.open(window.location.href);
    }

    const backToTop = async () => {
        JsBridge.navigate('tabMall');
        // JsBridge.backToTop();
    }

    const closeCurrentPage = async () => {
        JsBridge.close();
    }

    const tel = async () => {
        JsBridge.tel('10086');
    }

    const getDeviceInfo = async () => {
        const result = await JsBridge.getDeviceInfo('getDeviceInfo');
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const getNetInfo = async () => {
        const result = await JsBridge.getNetInfo();
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const share = async (platform) => {
        let isInstalled;
        if (platform === 0) {
            isInstalled = await JsBridge.canOpenLink('Sinaweibo://')
        } else if (platform === 1 || platform === 2) {
            isInstalled = await JsBridge.canOpenLink('weixin://')
        } else {
            isInstalled = await JsBridge.canOpenLink('mqq://')
        }
        if (isInstalled) {
            const result = await JsBridge.share({
                text: '测试分享文字',
                icon: 'https://icon.gkewang.com/jkt/advertisement/8a78c84a-c355-414e-a0a6-54b0dec7de2f.jpg?imageView2/1/w/50/h/50',
                link: window.location.href,
                title: '测试分享标题',
                platform: platform
            });
        } else {
            JsBridge.runAction('alert', 'error', ['请先安装应用']);
        }
    }

    const shareBoard = async () => {
        const result = await JsBridge.shareBoard({
            text: '测试分享文字',
            icon: 'https://icon.gkewang.com/jkt/advertisement/8a78c84a-c355-414e-a0a6-54b0dec7de2f.jpg?imageView2/1/w/50/h/50',
            link: window.location.href,
            title: '测试分享标题',
            platform: [0, 1, 2, 3, 4]
        });
    }

    const requestPermission = async (name) => {
        const result = await JsBridge.requestPermission(name);
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const imagePicker = async () => {
        const result = await JsBridge.imagePicker({
            width: 310,
            height: 310,
            cropping: true,
            cropperCircleOverlay: true,
            showCropGuidelines: false,
            showCropFrame: false
        }).catch(e => false);
        if (result) {
            // JsBridge.runAction('alert', 'error', [`图片地址为：${result.image && result.image.path}`]);

            const result2 = await JsBridge.runAction('commonModal', 'show', ['图片获取成功，是否要将图片保存为头像？', '温馨提示', [{ name: '取消' }, { name: '确定' }]]);
            JsBridge.runAction('commonModal', 'hide', []);
            if (result2 === '确定') {
                const { image } = result;
                alert(JSON.stringify(result));
                let params = {
                    file:
                    {
                        data: wrap(image.path.replace('file:///', '')),
                        imageType: image.mime,
                        filename: 'avatar.' + image.mime.replace('image/', ''),
                        name: 'avatar',
                        type: image.mime
                    }
                };
                const result3 = await JsBridge.runAction('user', 'postAvatar', [params]);
                if (result3.status > 0) {
                    await JsBridge.runAction('user', 'getAvatar');
                    JsBridge.runAction('alert', 'error', ['头像上传成功']);
                }
            }
        }
    }

    const camera = async () => {
        const result = await JsBridge.camera({
            width: 310,
            height: 310,
            cropping: true,
            cropperCircleOverlay: true,
            showCropGuidelines: false,
            showCropFrame: false
        }).catch();
        if (result) {
            const result2 = await JsBridge.runAction('commonModal', 'show', ['图片获取成功，是否要将图片保存为头像？', '温馨提示', [{ name: '取消' }, { name: '确定' }]]);
            JsBridge.runAction('commonModal', 'hide', []);
            if (result2 === '确定') {
                const { image } = result;
                let params = {
                    file:
                    {
                        data: wrap(image.path.replace('file:///', '')),
                        imageType: image.mime,
                        filename: 'avatar.' + image.mime.replace('image/', ''),
                        name: 'avatar',
                        type: image.mime
                    }
                };
                const result3 = await JsBridge.runAction('user', 'postAvatar', [params]);
                if (result3.status > 0) {
                    await JsBridge.runAction('user', 'getAvatar');
                    JsBridge.runAction('alert', 'error', ['头像上传成功']);
                }
            }
        }
    }

    const downloadFile = async () => {
        const result = await JsBridge.downloadFile({
            url: hotelTopImg,
            fileType: 'jpeg'
        }).catch();
        if (result) {
            const res = await JsBridge.saveImageToPhotosAlbum(result.tempFilePath).catch(ex => false);
            if (res) {
                JsBridge.runAction('alert', 'error', ['已经成功保存到相册']);
            } else {
                JsBridge.runAction('alert', 'error', ['图片保存失败']);
            }
        }
    }

    // 生成分享图片
    const createShareImage = (current) => {
        html2canvas(current, {
            allowTaint: true,
            useCORS: true
        }).then(async (canvas) => {
            const result = canvas.toDataURL('image/jpeg', 0.8);
            // setShowImage(result);
            // return;

            let res = await JsBridge.saveImgBase64(result).catch(ex => false);
            if (!res) {
                JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
            }
            res = await JsBridge.saveImageToPhotosAlbum(res.tempFilePath).catch(ex => false);
            if (!res) {
                JsBridge.runAction('alert', 'error', ['图片存入到相册失败']);
            }
            JsBridge.runAction('alert', 'error', ['已经成功保存到相册']);
        });
    }

    const wrap = (path) => {
        const prefix = path.startsWith('content://') ? 'RNFetchBlob-content://' : 'RNFetchBlob-file://'
        return prefix + path
    }

    const geolocation = async () => {
        const result = await JsBridge.geolocation()
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const getAppInfo = async () => {
        const result = await JsBridge.getAppInfo()
        JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
    }

    const isWxInstall = async () => {
        JsBridge.link('weixin://');
    }

    const pay = async () => {
        JsBridge.pay({
            orderInfo: {
                orderSn: '20210210003800717', //订单号
                serviceTimestamp: '1617326893271', //服务器时间戳
                expired: '1617326893271',//订单过期时间戳
                amount: '100', //订单金额（元）
                resource: 'insurance' //订单来源 电商平台：mall    保险：insurance
            },
            paymentList: [{
                payCode: 'Wechat',
                payName: '微信支付'
            }, {
                payCode: 'Alipay',
                payName: '支付宝'
            }]

        })
    }
    useEffect(() => {
        if (showCanvas && shareImageRef && shareImageRef.current) {
            setTimeout(() => {
                createShareImage(shareImageRef.current);
            }, 1000)
        }
    }, [showCanvas, shareImageRef]);

    return <div style={showCanvas ? { overflow: 'hidden', position: 'fixed', height: '100%', width: '100%' } : {}} className={styles.container} onTouchStart={() => {
        console.log('on touch start');
    }}>
        <h1>JsBridge Demo</h1>
        <wx-open-launch-app
            id="launch-btn"
            appid="wxe87950ee4eecd239"
            extinfo="{appId:wxe87950ee4eecd239}"
        >
            <script type="text/wxtag-template">
                <button class="btn">App内查看</button>
            </script>
        </wx-open-launch-app>
        <p>状态：
            {bridgeStatus > 0 ? <span>完成</span> : <span>准备中</span>}
        </p>

        <p>界面 </p>
        <button onClick={changeTitle}>修改页面标题</button>
        <button onClick={getStatusBarHeight}>获取状态栏高度</button>
        <button onClick={setStatusBarLight}>状态栏调亮</button>
        <button onClick={setStatusBarDark}>状态栏调暗</button>
        <button onClick={setHeaderLeftClick}>修改导航栏返回按钮</button>
        <button onClick={getUserInfo}>请求接口（获取用户信息）</button>
        <button onClick={openModal}>调用Action（拉起模态窗）</button>


        <p>路由</p>
        <button onClick={openNewPage}>打开页面</button>
        <button onClick={backToTop}>返回首页</button>
        <button onClick={closeCurrentPage}>关闭页面</button>

        <p>设备</p>
        <button onClick={tel}>拨打电话</button>
        <button onClick={getDeviceInfo}>获取设备信息</button>
        <button onClick={getAppInfo}>获取版本信息</button>
        <button onClick={getNetInfo}>获取网络信息</button>
        <button onClick={geolocation}>获取地理位置</button>

        <p>分享</p>
        <button onClick={share.bind(this, 1)}>微信分享</button>
        <button onClick={share.bind(this, 2)}>朋友圈分享</button>
        <button onClick={share.bind(this, 4)}>QQ分享</button>
        <button onClick={share.bind(this, 5)}>QQ空间分享</button>
        <button onClick={share.bind(this, 0)}>新浪分享</button>
        <button onClick={shareBoard}>分享面板</button>


        <p>权限请求</p>
        <button onClick={requestPermission.bind(this, 'requestAlbum')}>相册权限</button>
        <button onClick={requestPermission.bind(this, 'requestPhotoStorage')}>照相机权限</button>
        <button onClick={requestPermission.bind(this, 'requestGeolocation')}>地理位置权限</button>
        <button onClick={isWxInstall.bind(this)}>打开微信</button>

        <p>媒体</p>
        {showImage ? <div className={styles.image}>
            <img src={showImage} />
            <p>{showImage}</p>
        </div> : false}

        <button onClick={imagePicker}>拍照/相册选取图片</button>


        <button onClick={camera}>相机获取图片</button>

        <button onClick={downloadFile}>图片保存到相册</button>

        <button onClick={() => {
            setShowCanvas(true);
        }}>canvas保存到相册</button>
        {showCanvas ? <div className={styles.modal}>
            <div className={styles.canvasExample} ref={shareImageRef} >
                <h1>这里是canvas的示例</h1>
                <p>这里的内容会通通被保存到canvas里</p>
                <p>然后生成图片，显示在img标签里。</p>
                <p>最后通过桥接，被保存到相册当中</p>
            </div>
            <button onClick={() => {
                setShowCanvas(false);
            }}>关闭</button>
        </div> : false}
        <p>商汤活体识别</p>
        <button onClick={async () => {
            const result = await JsBridge.startLiveness();
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>活体识别</button>
        <button onClick={async () => {
            const result = await JsBridge.startBankCard();
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>银行卡</button>
        <button onClick={async () => {
            const result = await JsBridge.startIdCard(0);
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>身份证正面</button>
        <button onClick={async () => {
            const result = await JsBridge.startIdCard(1);
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>身份证反面</button>

        <p>百度活体识别</p>
        <button onClick={async () => {
            const result = await JsBridge.startLiveness('baidu');
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>活体识别</button>
        <button onClick={async () => {
            const result = await JsBridge.startBankCard('baidu');
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>银行卡</button>
        <button onClick={async () => {
            const result = await JsBridge.startIdCard(0, 'baidu');
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>身份证正面</button>
        <button onClick={async () => {
            const result = await JsBridge.startIdCard(1, 'baidu');
            JsBridge.runAction('alert', 'error', [JSON.stringify(result)]);
        }}>身份证反面</button>

        <button onClick={pay}>收银台</button>

    </div>;
}