import React, {useState, useEffect} from 'react';
import './style.scss';
import PriceModal from '../worldCupIndex/worldCupPriceModal';
import back from '../../../../images/icon/back-black@2x.png';
import img1 from '../images/icon_biaoqian_1.png';
import img2 from '../images/icon_biaoqian_2.png';
import img3 from '../images/icon_biaoqian_3.png';
import img4 from '../images/icon_biaoqian_4.png';
import img5 from '../images/icon_biaoqian_5.png';
import huangguanD from '../images/icon_huangguan_1da.png';
import huangguan2 from '../images/icon_huangguan_1xiao.png';
import huangguan3 from '../images/icon_huangguan_2.png';
import huangguan4 from '../images/icon_huangguan_3.png';
import Library from 'library';
import Services from 'services';
// import {mockFn, mockMyRankFn} from './mock';
const { JsBridge } = Library;

const GuessRankingList = () => {
  const [priceModalVisible, setPriceModalVisible] = useState(false);
  const [rankingList, setRankingList] = useState([]);
  const [myRankInfo, setMyRankInfo] = useState({});
  const [priceList, setPriceList] = useState([]);

  useEffect(() => {
    (async () => {
      await getRankList();
      await getMyRank();
    })();
  }, [])

  const getRankList = async () => {
    // const res = await mockFn();
    // console.log(res)
    // setRankingList(res.data);
    const result = await Services('MainPage', { code: '101290' }, {});
    if(result.status > 0) {
      setRankingList(result.data.list);
    }
  }

  const getMyRank = async () => {
    // const res = await mockMyRankFn();
    // console.log(res)
    // setMyRankInfo(res.data);
    const result = await Services('MainPage', { code: '101273' }, {});
    if(result.status > 0) {
      setMyRankInfo(result.data);
    }
  }

   // 奖品
   const getPriceData = async () => {
    const result = await Services('MainPage', { code: '101274' }, {});
    if(result.status > 0) {
      setPriceList(result.data);
      setPriceModalVisible(true);
    }
  }

  return <div className='guess-ranking-list'>
    <div className="guess-price-top">
      <div className="back-btn" onClick={() => JsBridge.back()}>
        <img src={back} alt="" />
      </div>
      <div className="see-price-detail" onClick={() => getPriceData()}>查看奖品详情</div>
      <PriceModal 
        priceModalVisible={priceModalVisible} 
        onClose={() => setPriceModalVisible(false)}
        priceList={priceList}
      />
    </div>
    <div className="user-activity-info">
      <div className="user-ranking">
        <span>{myRankInfo.rankNo}</span>
        <span>我的排名</span>
      </div>
      <div className="user-ranking">
        <span>{myRankInfo.hitGuess}</span>
        <span>猜中场数</span>
      </div>
      <div className="user-ranking">
        <span>{myRankInfo.inviteNum}</span>
        <span>助威团人数</span>
      </div>
    </div>
    <div className="user-ranking-table">
      <div className="pre-three">
        <div className="second rank-common-style">
          <img className='pre-header' src={rankingList[1]?.imagePath} alt="" />
          <span>{rankingList[1]?.loginName}</span>
          <span className='rank-tag'>2</span>
          <img className='huang-guan' src={huangguan3} alt="" />
        </div>
        <div className="first rank-common-style">
          <img className='pre-header' src={rankingList[0]?.imagePath} alt="" />
          <span>{rankingList[0]?.loginName}</span>
          <span className='rank-tag first-tag'>1</span>
          <img className='huang-guan' src={huangguanD} alt="" />
        </div>
        <div className="third rank-common-style">
          <img className='pre-header' src={rankingList[2]?.imagePath} alt="" />
          <span>{rankingList[2]?.loginName}</span>
          <span className='rank-tag'>3</span>
          <img className='huang-guan' src={huangguan3} alt="" />
        </div>
      </div>
      <div className="table-title">
        <span>排名</span>
        <span>用户</span>
        <span>猜中场数</span>
        <span>助威团人数</span>
      </div>
      {(rankingList && rankingList.length > 0) ? rankingList.map((item, index) => {
        return <div className="table-item" key={`ranking-${index}`}>
          <span className='ranking'>{item.rankNo}</span>
          <div className='user-info'>
            <div className='rankno-img'>
              {item.rankNo === 1 && <img className="price-rank" src={img1} alt="" />}
              {[2,3].includes(item.rankNo) && <img className="price-rank" src={img2} alt="" />}
              {[4,5,6].includes(item.rankNo) && <img className="price-rank" src={img3} alt="" />}
              {[7,8,9,10].includes(item.rankNo) && <img className="price-rank" src={img4} alt="" />}
              {[11,12,13,14,15].includes(item.rankNo) && <img className="price-rank" src={img5} alt="" />}
            </div>
            <img className='user-header first-circle' src={item.imagePath} alt="" />
            {item.rankNo === 1 && <img className='header-hg' src={huangguan2} alt="" />}
            {[2,3].includes(item.rankNo) && <img className='header-hg' src={huangguan3} alt="" />}
            {(item.rankNo > 3 && item.rankNo <16) && <img className='header-hg' src={huangguan4} alt="" />}
            <span className='guess-name'>{item.loginName}</span>
          </div>
          <span className='guess-counts'>{item.hitGuess}</span>
          <span className='invite-counts'>{item.inviteNum}</span>
        </div>
      }): <div className='empty-rank'>暂无排名</div>}
    </div>
  </div>
}

export default GuessRankingList;