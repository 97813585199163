import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import baoming from '../imgs/baoming.png'
import result from '../imgs/result.png'
import WinnerSwiper from '../winnerSwiper'
import { Swiper, Toast } from 'antd-mobile'
import slideLeft from '../imgs/slide-left.png'
import slideRight from '../imgs/slide-right.png'
import { useHistory } from "react-router-dom"
import close from '../imgs/close.png'
import Library from "library";
import { getDataInUnity } from "../../../../services/serviceUnity"
import ShareModal from './shareModal'
import moment from 'moment'
const { Util } = Library

const UnityPointsLottery = () => {
  const history = useHistory()
  const [prizeIndex, setPrizeIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [userInfo, setUserInfo] = useState({})
  const [data, setData] = useState({})
  const codeRef = useRef(null)
  const swiperRef = useRef(null);
  const BTN_NAME_MAP = {
    0: {text: '活动未开始', className: 'activity_end', progress: 0},
    1: {text: '报名已结束', className: 'activity_end', progress: 50},
    2: {text: `${data?.collectionActivityConfig?.costPoint} 积分报名`, className: 'activity_sign', progress: 20},
    3: {text: '已报名等待公布结果', className: 'activity_sign', progress: 40},
    4: {text: '已公布中奖结果', className: 'activity_result', progress: 80},
    5: {text: '活动已结束', className: 'activity_end', progress: 100},
  }

  useEffect(() => {
    getUserInfo()
    loadData();
  }, [])

  const getUserInfo = async () => {
    const res = await getDataInUnity('101056')
    console.log('userInfo', res)
    setUserInfo(res?.result || {})
  }

  const loadData = async () => {
    if (!codeRef.current) {
      const resCode = await getDataInUnity('600807')
      codeRef.current = resCode?.result
    }
    const res = await getDataInUnity('600808', { activityCode: codeRef.current }) || {}
    console.log(res.result)
    setData(res.result || {})
    if (res.result?.collectionActivityConfig) {
      const d1 = new Date(res.result?.collectionActivityConfig?.activityStartTime.replace(/-/g, '/')).getTime();
      const d2 = new Date(res.result?.collectionActivityConfig?.activityEndTime.replace(/-/g, '/')).getTime();
      const d3 = new Date(res.result?.systemTime.replace(/-/g, '/')).getTime();
      if (d3 <= d1) {
        setProgress(0)
      } else if (d3 >= d2) {
        setProgress(100)
      } else {
        // {/*0:活动未开始，1：报名结束，2：报名中，3：已报名，等待公布结果 4:已公布中奖结果，5：活动结束*/}
        setProgress(BTN_NAME_MAP[res.result?.status]?.progress)
      }
    }
  }

  const onSignUp = async () => {
    if (!userInfo.ID) {
      return Toast.show('请先实名认证')
    }
    if (data.status !== 2) return
    try {
      const res = await getDataInUnity('600806', {activityCode: codeRef.current});
      if (res) {
        loadData()
      }
    } catch (error) {
    }
  }

  const onSlideLeft = () => {
    if (data?.pointLotteries?.length === 1) return
    if (prizeIndex > 0) {
      setPrizeIndex(prizeIndex - 1)
      swiperRef.current.swipeTo(prizeIndex - 1);
    }
  }

  const onSlideRight = () => {
    if (data?.pointLotteries?.length === 1 || (prizeIndex === (data?.pointLotteries?.length - 1))) return
    setPrizeIndex(prizeIndex + 1)
    swiperRef.current.swipeTo(prizeIndex + 1);
  }

  const onItemClick = (index) => {
    setPrizeIndex(index);
    swiperRef.current.swipeTo(index);
  }

  const PrizeResult_0 = () => {
    return <div className={styles.prize_result_0}>
      <div className={styles.prize_result_title}>谢谢参与</div>
      <div className={styles.result_text}>很遗憾，未中奖！</div>
      <div className={styles.encourage_text}>请再接再厉！</div>
    </div>
  }

  const PrizeResult_1 = () => {
    const { jktActivityPrizeRecord } = data
    const { attendeeName, attendeeCardNo, attendeeContactNum, activityCode = '', id = '', clientId = '' } = jktActivityPrizeRecord || {}
    const isWrite = (attendeeName && attendeeCardNo && attendeeContactNum) ? 1 : 0
    return <div className={styles.prize_result_1}>
      <div className={styles.prize_result_title}>恭喜您中奖了</div>
      <div className={styles.result_text}>恭喜您获得{jktActivityPrizeRecord?.prizeName}，客服会尽快发放奖品。</div>
      <div 
        className={styles.prize_button}
        onClick={() => {
          history.push(`/front/activities/unityPointsLottery/performerInfo?isWrite=${isWrite}&clientId=${clientId}&id=${id}&activityCode=${activityCode}`)
        }}
      >
        {isWrite === 1 ? `查看观演人信息` : `填写观演人信息`}
      </div>
    </div>
  }

  const PrizeResult_2 = () => {
    const { address, prizeName } = data?.jktActivityPrizeRecord || {}
    const isWrite = address ? 1: 0
    return <div className={styles.prize_result_1}>
      <div className={styles.prize_result_title}>恭喜您中奖了</div>
      <div className={styles.result_text}>
        恭喜您获得{prizeName}，请尽快填写收货地址。
      </div>
      <div 
        className={styles.prize_button}
        onClick={() => {
          history.push(`/front/activities/unityPointsLOttery/address?isWrite=${isWrite}&${Util.url.objectToQueryParams(data?.jktActivityPrizeRecord)}`)
        }}
      >
        {address ? `查看收货地址` : `填写收货地址`}
      </div>
    </div>
  }

  return <div className={styles.unity_points_lottery}>
    <img src={close} alt="" className={styles.close_img} onClick={() => Util.notifyYYZToCloseWeb('')} />
    <div className={styles.top_utils}>
      <div 
        className={styles.activity_rules} 
        onClick={() => {
          const  { imgUrl, lotteryName} = data?.pointLotteries ? data?.pointLotteries[0]: {}
          ShareModal.open({prizeUrl: imgUrl, prizeName: lotteryName})
        }}
      >分享活动</div>
      <div 
        className={styles.activity_records} 
        onClick={() => history.push(`/front/activities/unityPointsLOttery/rules?activityCode=${codeRef.current}`)}
      >规则</div>
    </div>
    <div className={styles.activity_container}>
      <div className={styles.game_left}>
        <div className={styles.activity_title}></div>
        <div className={styles.game_result}>
          <div className={styles.result_tittle}>活动流程</div>
          <div className={styles.result_progress}>
            <div className={styles.progress_top}>
              <div className={styles.baoming}>
                <img src={baoming} alt="" />
                <span>报名参与</span>
              </div>
              <div className={styles.baoming}>
                <img src={result} alt="" />
                <span>公布结果</span>
              </div>
            </div>
            <div className={styles.progress_bottom}>
              <div className={styles.progress_strip}>
                <div className={styles.progress_strip_inner} style={{ width: `${progress}%` }}></div>
              </div>
              <div className={`${styles.baoming_time} ${styles.time_style}`}>
                {data?.collectionActivityConfig?.registraStartTime ? moment(data?.collectionActivityConfig?.registraStartTime).format('YYYY-MM-DD HH:mm'): ''}
              </div>
              <div className={`${styles.result_time} ${styles.time_style}`}>
                {data?.collectionActivityConfig?.settlementTime ? moment(data?.collectionActivityConfig?.settlementTime).format('YYYY-MM-DD HH:mm'): ''}
              </div>
            </div>
          </div>
          {([0, 1, 2, 3].includes(data?.status) || (!data?.jktActivityPrizeRecord &&  [4, 5].includes(data?.status))) ? <div className={styles.secret_script}>
            <p>参与活动</p>
            <p>参与活动：报名抽奖得精美礼品！</p>
          </div>: [4, 5].includes(data?.status) ? <div className={styles.prize_result_container}>
            {data?.jktActivityPrizeRecord?.prizeType == 9 && <PrizeResult_0 />}
            {data?.jktActivityPrizeRecord?.prizeType == 6 && <PrizeResult_1 />}
            {data?.jktActivityPrizeRecord?.prizeType == 3 && <PrizeResult_2 />}
          </div>: null}
          {data?.records && data?.records.length > 0 && <div className={styles.prize_records}><WinnerSwiper records={data.records}/></div>}
        </div>
      </div>
      <div className={styles.game_right}>
        <div className={styles.prize_name}>{data?.pointLotteries && data?.pointLotteries[prizeIndex]?.lotteryName}</div>
        <div className={styles.prize_swiper}>
          <img className={styles.slide_img} src={slideLeft} alt="" onClick={onSlideLeft} />
          <Swiper
            indicator={() => null}
            ref={(ref) => {
              swiperRef.current = ref
            }}
            defaultIndex={prizeIndex}
            onIndexChange={i => {
              setPrizeIndex(i)
            }}
          >
            {data?.pointLotteries && data?.pointLotteries?.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className={styles.item_box}>
                    <img
                      className={styles.item_img}
                      src={data?.pointLotteries[index]?.imgUrl}
                    />
                  </div>
                </Swiper.Item>
              )
            })}
          </Swiper>
          <img className={styles.slide_img} src={slideRight} alt="" onClick={onSlideRight} />
        </div>
        <div className={styles.user_poists}><span className={styles.cur_points}>当前积分：{data.pointNum}</span></div>
        <div className={`${styles.sign_up_btn} ${styles[BTN_NAME_MAP[data.status]?.className]}`} onClick={onSignUp}>
          <span>{BTN_NAME_MAP[data.status]?.text}</span>
        </div>
        <div className={styles.prize_list_box}>
          {data?.pointLotteries && data?.pointLotteries?.map((item, index) => {
            return (
              <div
                key={index}
                className={`${prizeIndex === index ? styles.big_item : styles.litter_item}`}
                onClick={() => onItemClick(index)}
              >
                <img
                  className={styles.prize_list_img}
                  src={item.imgUrl}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
}

export default UnityPointsLottery
