import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./eggJiFenDialog.module.scss"
import img_add from './../../../../images/egg_bt_jiahao.png'
import img_reduce from './../../../../images/egg_bt_jianhao.png'
import img_quxiao from './../../../../images/egg_bt_quxiao.png'
import img_sure from './../../../../images/egg_bt_yes.png'
import Services from "../../../../services";


const EggJiFenDialog = ({maskVisible, setMaskVisible, closeMask, userInfo, duihuanSuccess, dataSouece}) => {
    const [count, setCount] = useState(1);

    useEffect(() => {
        maskVisible && setCount(1);
    }, [maskVisible])


    const addReduceClick = (type) => {
        if (type === 1) { //加
            let currentNum = dataSouece.num || 0;  //当前已经兑换了多少了
            if (count >= (10 - currentNum)) {
                Toast.show({content: `最多只能兑换${count}张抽签券`, position: 'top'})
                return;
            }


            if (count > 9) {
                Toast.show({content: '最多只能兑换10张抽签券', position: 'top'})
                return
            }
            setCount((prevCount) => prevCount + 1);
        } else { //减去
            if (count < 2) {
                Toast.show({content: '至少兑换一张', position: 'top'})
                return
            }
            setCount((prevCount) => prevCount - 1);
        }
    }

    const btnClick = (index) => { //1确认 0取消
        if (index === 0) {
            setMaskVisible(!maskVisible);
        } else { //确定
            let productId = '000001';
            let num = count;
            let param = {
                productId: productId,
                num: num,
                clientId: userInfo.clientId,
                activityCode: 'APEW1001',
            }
            Services('MainPage', {code: 600687}, param).then((res) => {
                if (res.status > 0) {
                    Toast.show('积分兑换成功!');
                    duihuanSuccess && duihuanSuccess();
                }
            });
        }
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>积分兑换</div>
                    <div className={styles.little_title}>请选择兑换张数</div>
                    <div className={styles.btn_father}>
                        <img src={img_reduce} onClick={() => addReduceClick(0)}/>
                        <div className={styles.count}>{count}</div>
                        <img src={img_add} onClick={() => addReduceClick(1)}/>
                    </div>
                    <div className={styles.cancel_sure_father} onClick={() => btnClick(0)}>
                        <div className={styles.cancle_btn_father}>
                            <img src={img_quxiao} alt=""/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_cancel}>取消</span>
                            </div>
                        </div>

                        <div className={styles.cancle_btn_father} onClick={() => btnClick(1)}>
                            <img src={img_sure} alt=""/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_sure}>确认</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default EggJiFenDialog;
