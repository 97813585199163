import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GUComponents from 'components';
import styles from './index.module.scss';
import scanImg from './images/scan.png';
import moreImg from './images/arrow-more.svg';
import Services from 'services';
import { Actions } from 'reduxs';
export default () => {
    const [initResult, setInitResult] = useState(null);
    const [backUp, setBackup] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [scanCode, setScanCode] = useState('');

    const history = useHistory();

    const wxApiConfig = useSelector((state) => {
        return state.wxApi.toJS().config;
    });

    const verifyItem = useSelector((state) => {
        return state.coupon.toJS().verifyItem;
    });

    const requestCodeInfo = async (code) => {
        const result = await Services('Coupon', 'QueryWriteOffInfo', { couponCode: code });//1762103621100000
        if (result.status > 0) {
            setInitResult(result.data);
            Actions.coupon.setVerifyItem(null);
        } else {
            setInitResult(null);
        }
    };

    const scanCodeClick = async () => {
        if (wxApiConfig) {
            window.wx.ready(() => {
                window.wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function(res) {
                        setScanCode(res.resultStr)
                    },
                    fail: function(res) {
                        alert(JSON.stringify(res));
                    }
                });
            });
        }
    };

    useEffect(() => {
        if (scanCode.length === 16) {
            requestCodeInfo(scanCode);
        }
    }, [scanCode]);

    const confirmVertify = async () => {
        if (scanCode.length > 0 && smsCode.length > 0) {
            const params = {
                couponCode: scanCode,
                remarks: backUp,
                kaptchaCodeInput: smsCode
            };
            let couponInfo = initResult && initResult.writeOffInfo;
            if (couponInfo && couponInfo.biz_type === 1) {
                if (!verifyItem || !verifyItem.id) {
                    Actions.alert.show('请输入项目名称!!!');
                    return;
                }
                params.projectId = verifyItem.id;
            }
            const result = await Services('Copuon', 'WriteOff', params);
            if (result) {
                await Actions.alert.show(result.data.msg || '核销成功');
                history.replace('/front/admin/index/home');
            }
        } else {
            Actions.alert.show('检查券码或是验证码的输入!!!');
        }
    };

    let writeOffInfo = (initResult && initResult.writeOffInfo) || {};
    //设置input对应值
    const changeFieldValue = async (field, event) => {
        let value = event && event.target.value.trim();
        switch (field) {
            case 'backup': setBackup(value); break;
            case 'smsCode': setSmsCode(value); break;
            case 'scanCode':
            case 'scanCodeBlur':
                setScanCode(value);
                break;
            default: break;
        }
    };
    return <div className={styles.home}>
        <div className={styles.blockWhite}>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>券码</div>
                <div className={styles.itemRight}>
                    <input type='text'
                        className={styles.remarkInput}
                        placeholder="请输入券码号"
                        maxLength={16}
                        onBlur={(event) => changeFieldValue('scanCodeBlur', event)}
                        onChange={(event) => changeFieldValue('scanCode', event)}
                        value={scanCode}
                    />
                    <img src={scanImg} alt='扫一扫' className={styles.scanCode} onClick={scanCodeClick} />
                </div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>消费券类型</div>
                <div className={styles.itemTip}>{writeOffInfo['couponTypeName']}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>消费券价值</div>
                <div className={styles.itemTip}>{writeOffInfo.amount_type === 1 && writeOffInfo.amount <= 0 ? '礼品兑换' : writeOffInfo['amount'] && writeOffInfo['amount'] + '元'}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>使用人姓名</div>
                <div className={styles.itemTip}>{writeOffInfo['name']}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>使用人手机</div>
                <div className={styles.itemTip}>{writeOffInfo['mobile']}</div>
            </div>
            {writeOffInfo.biz_type === 1 ? <div className={styles.homeItem} onClick={() => {
                history.push('verify/item')
            }}>
                <div className={styles.itemTitle}>项目</div>
                <div className={styles.itemTip}>{verifyItem && verifyItem.title ? verifyItem.title : '请输入项目名称'}</div>
                <img className={styles.more} src={moreImg} alt='更多' />
            </div> : false}

            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>备注</div>
                <input type='text'
                    className={styles.remarkInput}
                    placeholder="请输入"
                    onChange={(event) => changeFieldValue('backup', event)}
                // onBlur={this.scrollTop.bind(this)}
                />
                <img className={styles.more} src={moreImg} alt='更多' />
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>验证码</div>
                <div className={styles.itemRight}>
                    <input type='text'
                        className={styles.remarkInput}
                        // maxLength={4}
                        placeholder='请输入'
                        onChange={(event) => changeFieldValue('smsCode', event)}
                    // onBlur={this.scrollTop.bind(this)}
                    />
                    <div className={styles.verifyCodeWrap}>
                        <GUComponents.Captcha />
                    </div>
                </div>
            </div>
            <div className={styles.verifyBtn} onClick={confirmVertify}>
                <span className={styles.verifyBtnText}>确认核销</span>
            </div>
        </div>
    </div>;

};
