import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import back from '../imgs/back.png'
import ruleTitle from '../imgs/rules.png'
import { useHistory } from 'react-router-dom'
import { getDataInUnity } from "../../../../services/serviceUnity"
import Library from "library"
const { Util } = Library

const ActivityRules = () => {
  const { activityCode='' } = Util.url.paramsToObj()
  const history = useHistory()
  const [activityRules, setActivityRules] = useState({})

  useEffect(() => {
    getActivityRules()
  }, [])

  const getActivityRules = async () => {
    try {
      const res = await getDataInUnity(101193, {codeType: 'activityRule', code: activityCode, activityCode});
      if (res.result && res.result.activityRule) {
          setActivityRules(res.result.activityRule);
      }
    } catch (error) {
    }
  }
  return <div className={styles.points_lottery_rules}>
    <div className={styles.rules_container}>
      <div className={styles.rules_top}>
        <img className={styles.back} src={back} alt="" onClick={() => history.goBack()}/>
        <img className={styles.title} src={ruleTitle} alt="" />
        <div className={styles.back}></div>
      </div>
      <div className={styles.rules_content}>{activityRules?.otherSign}</div>
    </div>
  </div>
}

export default ActivityRules