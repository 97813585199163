import React, { useEffect, useState, useRef } from 'react';
import Library from 'library';
import styles from './index.module.scss';
import Services from 'services';

const { JsBridge, Hooks } = Library;
let isLoading;
export default (props) => {
    const [pageNum, setPageNum] = useState(1);
    const [pageData, setPageData] = useState({ list: [] });
    const ref = useRef();

    // const handleScroll = (event) => {
    //     if (!isLoading && ref?.current && (document.documentElement.scrollTop + window.screen.height > ref.current.offsetHeight + ref.current.offsetTop)) {
    //         getInitData();
    //     }
    // }

    // Hooks.useEventListener('scroll', handleScroll, document);
    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            getInitData(true);
            onDidFocus();
        });
    }

    useEffect(() => {
        getInitData(true);
        onDidFocus();
    }, []);


    const getInitData = async (refresh) => {
        let currentPageNum = refresh ? 1 : pageNum + 1;
        const params = { pageNum: currentPageNum, pageSize: 10, activityCode: 'MEMBERDAY' };
        isLoading = true;
        const result = await Services('MainPage', { code: 101229 }, params);
        isLoading = false;
        if (result.status > 0) {
            setPageData(result.data);
            setPageNum(currentPageNum);
        }
    }
    const isEffect = pageData?.endDate && new Date(decodeURIComponent(pageData?.endDate).replace(/-/g, '/')).getTime() > new Date().getTime();
    return <div className={styles.container} ref={ref}>
        {pageData?.list?.map((item, key) => {
            const addressButtonText = () => {
                if (isEffect) {
                    if (item.deliveryAddressId) {
                        return '修改收货地址';
                    } else {
                        return '完善收货地址';
                    }
                } else {
                    return '查看收货地址';
                }
            };

            return <div key={key} className={styles.reward} onClick={() => {
                if (item.prizeType === 0) {
                    JsBridge.navigate('IntegralDetail');
                } else if (item.prizeType === 1) {
                    JsBridge.open(`/taro/subPages/user/mall/exchange/detail?id=${item.pointExchangeId}&merchant=${item.productMerchant}`);
                } else if (item.prizeType === 3) {
                    JsBridge.open(`/front/activities/memberDay/editAddress?endDate=${encodeURIComponent(pageData.endDate)}&id=${item.id}&${item.deliveryAddressId ? 'deliveryAddressId=' + item.deliveryAddressId : ''}`);
                } else {

                }
            }}>
                <div className={styles.content}>
                    <div className={styles.img}>
                        <img alt='产品图' src={item.prizeImgUrl} />
                    </div>
                    <div className={styles.title}>
                        {item.prizeName}
                    </div>
                    {item.prizeType === 3 ? <div className={styles.realTag}>{addressButtonText()}</div> : false}
                </div>
                <div className={styles.line}>

                </div>
                <div className={styles.footer}>
                    {item.prizeType === 0 ? '永久有效' : ''}
                    {item.prizeType === 1 ? '查看更多详情' : ''}
                    {item.prizeType === 3 ? '活动期间有效' : ''}
                    {item.prizeType === 4 ? '12月24日前由客服为您当前中奖账户升级' : ''}
                </div>
            </div>;
        })}
    </div>
}
