import React from 'react';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
export const VipDesc = () => {
    const tel = () => {
        Actions.modal.show('确定拨打电话：021-23296437 吗？', '拨打电话', [{
            name: '拨打电话', click: () => {
                window.location.href = 'tel://021-23296437';
                Actions.modal.hide();
            }
        }, {
            name: '取消', click: () => {
                Actions.modal.hide();
            }
        }])
    }
    return <div className={styles.container}>
        <h2>特权说明</h2>
        <p>①预订绿地自营品牌酒店，尊享门市价8.5折优惠</p>
        <p>②每月G-super超市会员日尊享9折优惠</p>

        <p>③在绿地珠宝购钻石尊享8.5折优惠</p >
        <p>④尊享上航旅游产品专属优惠价</p >
        <p>⑤尊享绿地金融优质理财产品优先认购权</p >
        <p>⑥尊享绿地金融私人财富一对一专业管家服务</p >
        <p>⑦尊享绿地金融小额贷款优惠贷款利率</p >
        <p>⑧海外优质医疗服务尊享8.5折</p >
        <p>⑨国内名医诊疗服务尊享8.5折</p >
        <p>⑩尊享绿地康养健康旅居产品市场最低价</p >
        <p>⑪尊享绿地汽车1年2次免费救援（15公里范围内）</p >
        <p>⑫尊享绿地汽车终身保养工时费享6折优惠</p >

        <h3>会员卡有效日期: 永久有效</h3>

        <h3>客户电话：<a onClick={tel}>021-23296437</a>(工作日9：00~17：00)</h3 >

        <h2>使用须知：</h2>
        <p>每人限领1张</p >
        <p>①本卡是绿地G优尊享会员的线下凭证，仅供会员本人使用，不得转让；</p >
        <p>②持卡者即有资格接受及享有绿地集团提供的各种优惠及服务；</p >
        <p>③本卡仅限在绿地集团指定门店消费使用，不可折现；</p >
        <p>④折扣权益细则有不确定因素影响，具体详情咨询各门店；</p >
        {/* <p>⑤最终解释权归绿地集团控股有限公司所有。</p > */}
    </div>
}