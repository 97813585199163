import React, {useEffect, useRef, useState} from 'react'
import styles from './styles.module.scss'
import {ListView} from "../../../../components";
import Library from "../../../../library";
import {CARBONPOINT} from "../../../../library/util/constant";
import Services from "../../../../services";

const {JsBridge} = Library;
//环保商品
const EnviroPro = () => {

    const currentPage = useRef(1)
    const [dataList, setDataList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        loadData().then();
    }, [])

    const loadData = async () => {
        currentPage.current = 1;
        // let res = await getData();
        let res = await Services('MainPage', {code: 101342}, {activityCode: CARBONPOINT});
        console.log('101342---', res);
        if (!!res && res?.status === 1 && !!res?.data) {
            let {content = []} = res.data[0];
            setIsLoading(false)
            setDataList(content)
            setHasMore(content.length >= 10)
        }
    }

    const getData = () => {
        return new Promise((resolve, reject) => {
            resolve(mock)
        });
    }

    const renderItem = (item) => {
        const {goodsName, marketPrice, pic, skuId,} = item;
        return <div className={styles.item} onClick={() => {
            JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}`})
        }}>
            <img src={pic} alt=""/>
            <p className={styles.name}>{goodsName}</p>
            <div className={styles.price_container}>
                <span>¥</span>
                <span>{marketPrice}</span>
                {/*<span>¥354.8</span>*/}
            </div>
        </div>
    }

    return (
        <div className={styles.enviro_pro_container}>
            <div className={styles.list}>
                <ListView
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={false}
                    isLoading={isLoading}
                    onRefresh={async () => {
                        currentPage.current = 1;
                        await loadData(true)
                    }}
                    loadMore={async () => await loadData(false)}
                />
            </div>
        </div>
    )
}


const mock = {
    code: '000000',
    msg: 'success',
    status: 1,
    data: [
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 0,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 1,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 2,
        }, {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 3,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 4,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 5,
        }, {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 6,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 7,
        },
        {
            nftNo: '123455',
            imageBg: require('../imgs/img_1.png'),
            id: 8,
        }
    ]

}
export default EnviroPro
