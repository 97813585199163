import React, { useState, useRef, useEffect } from 'react';
import './style.scss';
import poster from '../imgs/goddess-poster.png';
import ShareModal from '../inviteModal/inviteModal';
import { Popup, InfiniteScroll, PullToRefresh, DotLoading } from 'antd-mobile';
import closeImg from '../imgs/close.png';
import Services from 'services';
import Library from 'library';
import no_data from 'images/noCollection.png';
import ReceiveModal from '../components/receiveModal';
import {RulesModal} from 'components';
import backImg from '../imgs/goback.png';
import xiangshui from '../imgs/nvshen-xiangshui.png';
import meizhuang from '../imgs/nvshen-meizhuang.png';
import hufu from '../imgs/nvshen-hufu.png';
import riyongpin from '../imgs/nvshen-riyongpin.png';
import shipin from '../imgs/nvshen-shipin.png';
import logo from '../imgs/logo.png';
import prizeImg from '../imgs/pic_yaoxing.png';
import { Toast, Swiper } from 'antd-mobile';

const { JsBridge } = Library;

const ImgMap = {
  '女神香水': xiangshui,
  '女神美妆': meizhuang,
  '女神护肤': hufu,
  '女神日用品': riyongpin,
  '女神饰品': shipin,
}

const activityCode = 'goddessFes';

const GoddessHome = () => {
  const [goodsList, setGoodsList] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [activityInfo, setActivityInfo] = useState({});

  useEffect(() => {
    pageInit();
  }, [])

  const receiveRecord = () => {
    JsBridge.push('Web', {uri: `${window.location.origin}/front/activities/receiveRecord`});
  }

  const pageInit = async () => {
    await getActivityInfo();
    await getGoodsList();
  }

  const getActivityInfo = async () => {
    const result = await Services('MainPage', { code: '101327' }, {activityCode});
    JsBridge.print(result);
    if(result.status > 0) {
      setActivityInfo(result.data);
    }
  }

  const getGoodsList = async () => {
    const result = await Services('MainPage', { code: '101331' });
    JsBridge.print(result.data);
    if(result.status > 0) {
      setGoodsList(result.data);
    }
  }

  const receivePrize = async (id) => {
    const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
    if(isEnd >= 0) {
      Toast.show('活动已结束');
      return;
    }
    const result = await Services('MainPage', { code: '101326' }, {type: id});
    JsBridge.print(result);
    if(result.status > 0) {
      ReceiveModal.open({callBack: getActivityInfo, prizeInfo: result.data});
    }
  }

  const getActivityRules = () => {
    RulesModal.open({activityCode: 'goddessFes', codeType: 'activityRule', code: 'goddessFesRule'});
  }

  const {
    inviteCount,
    highLevelStatus,
    lowLevelCount,
    highLevelCount,
    lowLevelStatus,
    invitePoint	,
    inviteCode,
    records
  } = activityInfo;
 
  return <div className='goddess-home'>
    <div className="goddess-activity-rules" onClick={() => getActivityRules()}>活动规则</div>
    <div className="back-arrow" onClick={() => JsBridge.back()}>
      <img src={backImg} alt="" />
    </div>
    <div className="top-img">
      <img src={poster} alt="" />
    </div>
    <div className="goddess-content">
      <div className="invite-view">
        <div className="invite-task">
          <div className="title">邀新送好礼</div>
          <div className="desc">每邀1人，即送{invitePoint}积分，更多好礼领取</div>
          <div className="invite-btn" onClick={() => {
            const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
            if(isEnd >= 0) {
              Toast.show('活动已结束');
              return;
            }
            ShareModal.open({activityCode, inviteCode});
          }}>立即邀新</div>
          <div className="record-box">
            <span onClick={receiveRecord}>领取记录&gt;</span>
            <span onClick={() => setPopupVisible(true)}>邀新记录&gt;</span>
          </div>
        </div>
        <div className="task-prize">
          <div className="invite-prize">
            <img src={prizeImg} alt="" />
          </div>
          <div className="task-progress">
            <div className="progress-view">
              <div className="progress-inner" style={{width: (inviteCount/highLevelCount).toFixed(2)*100+'%'}}></div>
            </div>
            {inviteCount >= lowLevelCount ? <div 
              className={`flag-btn ${lowLevelStatus === 0 ? '': 'received'}`} 
              onClick={() => {
                if(activityInfo.lowLevelStatus === 1) {
                  return;
                }
                receivePrize('lowLevelProduct');
              }}
            >
              {lowLevelStatus === 0 ? '领取': '已领取'}
            </div>: <div className="receive-btn" style={{left: '31.5%'}}>
              {inviteCount+'/'+lowLevelCount}
            </div>}
            {inviteCount < highLevelCount ? <div className="receive-btn" style={{left: '79.5%'}}>
              {inviteCount+'/'+highLevelCount}
            </div>: <div 
              className={`flag-btn ${highLevelStatus === 0 ? '': 'received'}`} 
              style={{left: '79.5%'}} 
              onClick={() => {
                if(activityInfo.highLevelStatus === 1) {
                  return;
                }
                receivePrize('highLevelProduct');
              }}
            >
              {highLevelStatus === 0 ? '领取': '已领取'}
            </div>}
          </div>
        </div>
        {records?.length ? <div className="receive-name-list">
          <div className="list-title">领取名单</div>
          <Swiper
            className='swiper'
            direction={'vertical'}
            allowTouchMove={false}
            stuckAtBoundary={false}
            autoplay={true}
            loop={true}
            autoplayInterval={1500}
            slideSize={20}
            indicator={() => null}
          >
            {records.map(({loginName, prizeName}, index) => (
              <Swiper.Item key={`${index} - swiper}`}>
                <div className="list-item" key={`records-${index}`}>
                  <span>{loginName}:</span>
                  <span>{prizeName}</span>
                </div>
              </Swiper.Item>
            ))}
          </Swiper>
        </div>: null}
      </div>
      {goodsList?.length > 0 && goodsList.map((goodsItem, index) => {
        return <GoddessGoodsList goodsList={goodsItem} key={`goodsItem-${index}`}/>
      })}
      <div className="logo-view">
        <img src={logo} alt="" />
      </div>
    </div>
    <InviteRecord visible={popupVisible} close={() => setPopupVisible(false)} />
  </div>
}

const GoddessGoodsList = ({goodsList}) => {
  const { cateName, content=[] } = goodsList;
  return <div className='goddess-goods-list'>
    <div className="goods-title">
      <img src={ImgMap[cateName]} alt="" />
      <span>{cateName}</span>
    </div>
    <div className="goods-list">
      {content.length>0 && content.map((item, index) => {
        return <div className="goods-item" 
          key={`goodsList-${index}`} 
          onClick={() => JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${item.skuId}`})}
        >
          <img className='goods-img' src={item.pic} alt="" />
          <div className='goods-name'>{item.goodsName}</div>
        </div>
      })}
    </div>
  </div>
}

const InviteRecord = (props) => {
  const { visible, close } = props;
  const [isLoad, setIsLoad] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [inviteRecord, setInviteRecord] = useState([]);
  const [inviteCount, setInviteCount] = useState(0);
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    visible && getInviteRecord(true);
  }, [visible])

  const getInviteRecord = async (isRefresh) => {
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      activityCode
    }
    const result = await Services('MainPage', { code: '101328' }, params);  
    JsBridge.print(result);  
    if(result.status > 0) {
      if(pageNum.current === 1) {
        setInviteRecord(result.data?.inviteList || []);
      } else {
        setInviteRecord([...inviteRecord, ...(result.data?.inviteList || [])]);
      }
      setHasMore(result.data?.inviteList?.length >= pageSize.current);
      setInviteCount(result.data.inviteCount);
    }
    setIsLoad(true);
  }

  return <div>
    <Popup 
      visible={visible}
      onMaskClick={close}
      bodyStyle={{ borderRadius: "10px 10px 0 0" }}
    >
      <div className='invite-container'>
        <div className="invite-title">
          <span>邀新记录</span>
          <img onClick={close} src={closeImg} alt="" />
        </div>
        <div className="invite-counts"> ——已成功邀请{inviteCount}人——</div>
        <div className="invite-user-info">
          <PullToRefresh onRefresh={() => getInviteRecord(true)}>
            {inviteRecord.length > 0 ? inviteRecord.map((item, index) => {
              return <InviteItem item={item} key={`record-${index}`}/>
            }): isLoad ? <div className='empty-container'>
              <img src={no_data} alt="" />
              <span>暂无邀新记录</span>
            </div>: null}
            {inviteRecord.length > 0 ? <InfiniteScroll 
              loadMore={() => getInviteRecord(false)} 
              hasMore={hasMore} 
              threshold={50}
            >
              <InfiniteScrollContent hasMore={hasMore}/>
            </InfiniteScroll>: null}
          </PullToRefresh>
        </div>
      </div>
    </Popup>
  </div>
}

const InviteItem = ({item}) => {
  const {avatar, mobile, loginTime} = item || {};
  return <div className="invite-info-item">
    <div className="info-item-left">
      <img src={avatar} alt="" />
      <span>{mobile}</span>
    </div>
    <div className="invite-time">{loginTime?.slice(5)}</div>
  </div>
}

const InfiniteScrollContent = ({hasMore}) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  )
}

export default GoddessHome;