import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Config } from './config';
import styles from './rule.module.scss';


export const Rule = (props) => {
    const history = useHistory();
    const { activityCode } = useParams();
    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>活动规则</div>
                <div className={styles.modalContent}>
                    {Config?.[activityCode]?.rule}
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={() => {
                        history.goBack();
                    }}>我知道了</button>
                </div>
            </div>
        </div>
    </div>
}