import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./eggBaomingDialog.module.scss"
import img_quxiao from './../../../../images/egg_bt_quxiao.png'
import img_sure from './../../../../images/egg_bt_yes.png'
import egg_img_suiji from './../../../../images/egg_img_suiji.png'
import Library from "../../../../library";
import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";

const {JsBridge} = Library;

const EggBaomingDialog = ({maskVisible, setMaskVisible,}) => {
    const btnClick = (index) => { //0=查看详情,1确认
        setMaskVisible(!maskVisible);
        if (index === 0) {
            Services('MainPage', {code: 600066}, {source: 'app'}).then((res) => {
                const {status, data} = res;
                if (status > 0) {
                    let token = data.token;
                    if (!isEmptyString(token)) {
                        let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?t=${token}`;
                        JsBridge.push('CustomWeb', {uri: uri});
                    }
                }
            });
        }
    }
    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <img src={egg_img_suiji} className={styles.img_logo}/>
                    <div className={styles.little_title}>
                        <span className={styles.count}>恭喜你获得AWA/AWB随机纪念藏品
一款，请到【我的藏品】查看详情</span>
                    </div>
                    <div className={styles.cancel_sure_father} >
                        <div className={styles.cancle_btn_father} onClick={() => btnClick(0)}>
                            <img src={img_quxiao}/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_cancel}>查看详情</span>
                            </div>
                        </div>
                        <div className={styles.cancle_btn_father} onClick={() => btnClick(1)}>
                            <img src={img_sure} alt=""/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_sure}>确认</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default EggBaomingDialog;
