import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import qrcode from '../images/vip-btn-qr@2x.png';
import cardPic from '../images/card-pic@2x.png';
import experienceQrcode from '../images/experience-qr@2x.png';
import question from '../images/vip-icon-btn-question@2x.png';
import vipQuestion from '../images/general-icon-btn-question@2x.png';
import { vipConfig } from '../conf';
import { Actions } from 'reduxs';
import services from 'services';
import Library from 'library';
import { umengTrack_member } from 'library/jsbridge/config'
const { Platform, JsBridge } = Library;
export const MyCard = () => {
    const [vipLevel, setVipLevel] = useState(null);
    const [privilege, setPrivilege] = useState([]);
    const [cardNo, setCardNo] = useState('');
    const [expired, setExpired] = useState(new Date());
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        fetchMemberInfo();
    }, []);

    const fetchMemberInfo = async () => {
        const result = await services('MyPage', 'MemberInfo');
        if (result.status>0) {
            initMemberInfo(result.data);
        }
    };

    const initMemberInfo = (info) => {
        const {
            guCardNo,   //gu会员卡卡号，体验卡不显示卡号
            guCardType,  //gu卡类型：0-尊享卡，1-体验卡
            guCardStatus, //gu卡状态:0-有效,1-失效
            // guActivateTime, //gu会员卡激活时间
            guValidTime    //gu会员卡有效期
        } = info;
        if (guCardType !== null) {
            setCardNo(guCardNo);
            setVipLevel(guCardType === 1 ? 'temp' : 'vip');
            if (guValidTime) {
                setExpired(new Date(guValidTime.replace(/-/g, '/')));
            }
        } else {
            history.replace('/front/member/register');
        }
    };

    useEffect(() => {
        if (vipLevel && vipLevel !== 'base') {
            setPrivilege(vipConfig[vipLevel].privileges.concat(vipConfig.base.privileges));
        }
    }, [vipLevel]);

    const footerDisplay = () => {
        if (vipLevel === 'base') {
            return <footer className={styles.footer}>
                <div className={styles.footerWrap}>
                    <button onClick={getTempCard}>领取G优会员卡</button>
                </div>
            </footer>;
        } else if (vipLevel === 'temp') {
            return <footer className={styles.footer}>
                <div className={styles.footerWrap}>
                    <button onClick={() => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_member.memberbenefit, '二维码');
                        }
                        history.push(`/front/member/${vipLevel === 'vip' ? `qrcode/${cardNo}` : `qrcode/${cardNo}`}`)
                    }}>使用会员特权</button>
                </div>
            </footer>
        } else {
            return <footer className={styles.footer}>
                <div className={styles.footerWrap}>
                    <button onClick={() => {
                        history.push(`/front/member/${vipLevel === 'vip' ? `qrcode/${cardNo}` : `qrcode/${cardNo}`}`)
                    }}>使用特权</button>
                </div>
            </footer>;
        }
    }

    const howToBeVipModal = () => {
        Actions.modal.show(`您可前往绿地旗下各产业板块咨询升级标准，达标后您将自动升级为G优尊享会员。`,
            '怎样升级为尊享会员？', [{
                click: () => {
                    Actions.modal.hide();
                }, name: '确定'
            }]);
    }

    const getTempCard = async () => {

    }

    if (!vipLevel) {
        return false;
    }

    const Other = (props) => {
        const formattedPrivilege = [];
        privilege.sort((a, b) => {
            return a.sort - b.sort
        })
        privilege.forEach((item, key) => {
            const thisKey = parseInt(key / 2)
            if (!formattedPrivilege[thisKey]) {
                formattedPrivilege[thisKey] = [];
            }
            formattedPrivilege[thisKey].push(item);
        })

        const renderItem = (a) => {
            if (a) {
                return <Link className={`${styles.item} ${a.disabled ? styles.more : ''}`} to={!a.disabled ? `/front/member/${a.link}` : '/front/member/card'}>
                    <div className={styles.left} onClick={() => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_member.memberbenefit, a.title || a.tag);
                        }
                    }}>
                        <span className={styles.title}>{a.title || a.tag}</span>
                        <span className={styles.subTitle}>{a.sub}</span>
                        <span className={styles.desc}>{a.name}</span>
                    </div>
                    <div className={styles.right}>
                        <img mode='widthFix' src={a.icon} alt='pic' />
                    </div>

                </Link>
            } else {
                return false;
            }
        }
        return <div className={styles.other}>
            {formattedPrivilege.map((arr, key) => {
                return <div key={key} className={styles.row}>
                    {renderItem(arr[0])}
                    <div className={styles.split}></div>
                    {renderItem(arr[1])}
                </div>;
            })}
        </div>
    }
    return <div className={styles.container}>
        <div className={styles.scroll}>
            <div className={styles.header}>
                <div className={`${styles.cardWrap} ${vipLevel !== 'vip' ? styles.vipCardWrap : ''}`}>
                    <div className={styles.cardLeft}>
                        {/* <h1>G优尊享会员卡 {vipLevel === 'temp' ? <i>体验卡</i> : false}</h1> */}
                        <div className={styles.leftTitle}>
                            <img src={cardPic} alt='cardPic' className={styles.cardPic}></img>
                            <div className={styles.msg}>
                                <h1>{vipLevel === 'vip' ? 'G优尊享会员卡' : 'G优会员卡'}</h1>
                                <p className={`${vipLevel === 'vip' ? styles.companyTitleVip : styles.companyTitle}`}>绿地控股集团</p>
                            </div>
                        </div>
                        <h3 className={vipLevel === 'vip' ? styles.companyNum : styles.companyVipNum}>{vipLevel !== 'base' ? `NO.${cardNo}` : ' '}</h3>
                        {/*<p onClick={() => {*/}
                        {/*    history.push(`/front/member/${vipLevel === 'vip' ? 'vipDesc' : 'tempDesc'}`)*/}
                        {/*}}>会员说明 <img className={styles.question} src={vipLevel !== 'vip' ? `${vipQuestion}` : `${question}`} alt='question' /></p>*/}
                    </div>

                    {vipLevel !== 'base' ? <div className={styles.qrcode} onClick={() => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_member.memberinfo, '二维码');
                        }
                       history.push(`/front/member/${vipLevel === 'vip' ? `qrcode/${cardNo}` : `qrcode/${cardNo}`}`)
                    }}>
                        {vipLevel !== 'vip' ? <img src={experienceQrcode} alt='qrcode' /> : <img src={qrcode} alt='qrcode' />}
                    </div> : false}
                </div>
            </div>
            <div className={styles.content}>
                <h1>会员特权</h1>
                <h3>TOP 11 PLATINUM INTERESTS</h3>

                {vipLevel === 'temp' ? <div className={styles.warn}>
                    {/* <p>您当前为体验会员，有效期至: {Library.Util.format.time(expired, 'yyyy年MM月dd日')}, 升级成为绿地G优正式会员享受更多福利</p> */}
                    <p>升级成为绿地G优尊享会员享受更多福利</p>
                    <button onClick={() => {
                        howToBeVipModal();
                    }}>升级</button>
                </div> : false}
                <Other />
            </div>
        </div>
        {footerDisplay()}
    </div>;
}

