/**
 * 每日签到 通知设置弹窗
 */
import React, { useState, useEffect } from 'react';
import styles from './noticeSetting.module.scss';
import Services from 'services';
import Library from 'library';
import Bell from './imgs/icon_tixing.png';
import Check from './imgs/btn_sel.png';
import UnCheck from './imgs/btn_nor.png';
const { JsBridge } = Library;

export const NoticeSetting = (props) => {
    
    const {visible} = props;

    const [isEnable,setIsEnable] = useState(false);
    const [isCheck,setIsCheck] = useState(true);

    const getNotificationsEnabled = async ()=>{
        const enable = await JsBridge.nativeModules('SysModule','isNotificationsEnabled');
        setIsEnable(enable);
    }
    useEffect(()=>{
        getNotificationsEnabled();
    },[]);

    const openNotifications = async ()=>{
        JsBridge.nativeModules('SysModule','openNotificationsSetting');
    }

    const onClose = (v)=>{
        setTimeout(()=>{
            props.onClose(v);
        },100)
    }

    const agree = async ()=>{
        if (isCheck) {
            const result = await Services('MainPage', { code: '101213' },{notice:1}, true)
            if (result.status > 0) {
                props.callback(true)
                onClose(false)
            }

        }
    }
   
    if(!visible) return null;

    if (isEnable) {
        return <div className={styles.container}>
            <div className={styles.contentBg}>
                <div className={styles.contentOpen}>
                    <p className={styles.text}>向你推送以下消息</p>
                    <p className={styles.text2}>你可能接收到多次提醒，可在活动页面关闭提醒</p>
                    <div className={styles.checkBg} onClick={()=>{
                        setIsCheck(!isCheck);
                    }}>
                        <img src={isCheck?Check:UnCheck} className={styles.img} mode='widthFix' alt=''/>
                        <p className={styles.tip}>叮～绿地G优喊你来签到，快来签到领奖品吧</p>
                    </div>
                    <div className={styles.btns}>
                        <button className={styles.btn} onClick={()=>{
                            onClose(false) 
                        }}>取 消</button>
                        <button className={`${styles.btn} ${isCheck?styles.active:''}`} onClick={agree}>同 意</button>
                    </div>
                </div>
            </div>
        </div>
    } else {
        return <div className={styles.container}>
            <div className={styles.contentBg}>
                <div className={styles.contentClose}>
                    <img src={Bell} className={styles.img} mode='widthFix'/>
                    <p className={styles.text}>开启推送通知权限</p>
                    <p className={styles.text2}>不漏掉任何一天的签到更新提醒</p>
                    <button className={styles.btn} onClick={openNotifications}>立即开启</button>
                    <a className={styles.text3} onClick={()=>{
                        onClose(false)
                    }}>暂时不用</a>
                </div>
            </div>
        </div>
    }
}
