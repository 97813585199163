import React from "react"
import { Mask, } from "antd-mobile"
import styles from "./FriendReminder.module.scss"



const FriendReminder = ({ maskVisible, setMaskVisible }) => {
    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>友情提示</div>
                    <span className={styles.content}>活动期间未填写地址领取奖品，活动后奖品作废。</span>
                    <div className={styles.sure_btn_txt} onClick={() => btnClick()}>
                        <span>我知道了</span>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default FriendReminder;
