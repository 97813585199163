import React, { useEffect, useState } from 'react';
import styles from './invitee.module.scss';
import mainBg from '../registionActivity/images/main_picture_bg@2x.png';
import guRight from '../registionActivity/images/gu_right@2x.png';
import {Slogan} from 'components/slogan';
import { InviteCard } from './component';
import { useHistory, useParams } from 'react-router-dom';
import Services from 'services';
import Library from 'library';
const { Platform, Util } = Library;
export const Invitee = () => {
    const history = useHistory();
    const { code } = useParams();
    const getRules = () => {
        history.push('/front/activities/registion/rule');
    };

    const userIsRegistered = async () => {
        const result = Platform.check.isApp() ? await Services('MainPage', { code: '101148' }, { activity: 'INVITECLIE' }) : await Services('GuMember', 'IsRegisted', { activity: 'INVITECLIE' });
        const { status } = result;
        if (status > 0) {
            const { msg, data } = result.data;
            if (msg === 1) {
                history.replace('/front/activities/registion/inviter');
            }
        }
    };

    useEffect(() => {
        const { pathname } = history.location;
        console.log('pathName', pathname);
        if (pathname.indexOf('/front/activities/registion/invitee/login') === -1) {
            userIsRegistered();
        }
    }, [history.location]);

    return <div className={styles.container}>
        <div className={styles.tips}>
            <p onClick={() => {
                getRules()
            }}>活动规则</p>
        </div>
        <img src={mainBg} className={styles.img} />
        <div className={styles.textView}>
            <p className={styles.title}>邀新赚10倍积分，好礼兑不停</p>
            {/*<p className={styles.title_one}>活动时间：<ActivityTime /></p>*/}
        </div>
        <p>邀新赚10倍积分，好礼兑不停</p>
        <div className={styles.content}>
            <InviteCard />
            <div className={styles.column_one}>
                <img src={guRight} />
            </div>
            <Slogan className={styles.slogan} />
        </div>
        <div className={styles.bottom}>
            <div className={styles.button} onClick={() => {
                // history.push('/front/activities/registion/invitee/login/'+code);//没有登陆登陆
                window.location.href = Library.Util.ACTIVITY_REGISTION_INITEE_DOWNLOAD_URL;
            }}>
                <p>接受邀请并参与活动</p>
            </div>
        </div>
    </div>;
};
