/**
 * @description 充值卡购买记录
 * @returns /front/member/pointRecharge/buyRecord
*/
import React, { useEffect, useState } from "react";
import { Header, ListView } from 'components';
import Service from '../service';
import Styles from './index.module.scss';

export default (props) => {

    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = React.useRef(1)

    useEffect(()=>{
        onRefresh(true);
    },[])
    //加载列表数据
    const onRefresh = async (loading=false) => {
        currentPage.current = 1;
        if(loading) {
            setLoading(true)
        }
        const res = await Service.fetchBuyCardRecord({currentPage: currentPage.current}, loading);
        if(loading) {
            setLoading(false)
        }
        if (res?.data) {
          const { records = [] } = res.data;
          setDataList(records);
          setHasMore(records.length >= Service.PageSize);
        }
      }
  
      const loadMore = async () => {
        const res = await Service.fetchBuyCardRecord({currentPage: currentPage.current + 1}, loading);
        if (res?.data) {
          const { records = []} = res.data;
          setDataList([...dataList, ...records]);
          setHasMore(records.length >= Service.PageSize)
          currentPage.current = currentPage.current + 1;
        }
      }
  

    const renderItem = (item) => {
        return (
            <div className={Styles.render_item}>
                <div className={Styles.info}>
                    <p>{item.desc || 'G优充值卡购买'}</p>
                    <span>{item.payTime}</span>
                </div>
                <div className={Styles.price}>¥{item.totalPrice||0.00}</div>
            </div>
        )
    }

    return (
        <div className={Styles.buy_record_page}>
            <Header midText={'购买记录'} />
            <div className={Styles.page_body}>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    emtyDesc={loading ? '数据加载中...':'暂无数据'}
                    hasMore={hasMore}
                    onRefresh={onRefresh}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}