/**
 * @description 邀新专区-邀新记录页
 * @returns route: /front/activities/regularShare/invite-record
*/

import React, { useState, useEffect, useRef } from 'react';
import { Toast, Tabs, Input } from 'antd-mobile';
import { SearchOutline } from 'antd-mobile-icons'
import { Header, ListView } from 'components';
import Service from '../invite-zone/service';
import Styles from './index.module.scss';

const InviteRecord = () => {
    const [activeKey,setActiveKey] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [successCount, setSuccessCount] = useState(0);
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false);
    const search = useRef(undefined);
    const inputRef = useRef(null);
    const currentPage = useRef(1);

    useEffect(() => {
        if(activeKey == 0) {
            search.current = undefined;
        }
        loadData(true);
    }, [activeKey])

    const loadData = async (loading = false)=> {
        currentPage.current = 1;
        if (loading) {
            setLoading(true)
        }
        const res = await Service.getInviteList({
            currentPage: currentPage.current,
            type: Number(activeKey) + 1,
            productName: search.current
        }, loading);
        if (loading) {
            setLoading(false)
        }
        console.log('res', res)
        if (res?.data) {
            const {list = [], successCount} = res.data;
            setDataList(list);
            setSuccessCount(successCount)
            setHasMore(list.length >= Service.PageSize);
        }
    }
    const loadMore = async () => {
        const res = await Service.getInviteList({
            currentPage: currentPage.current + 1,
            type: Number(activeKey) + 1
        }, loading);
        if (res?.data) {
            const {list = []} = res.data;
            setDataList([...dataList, ...list]);
            setHasMore(list.length >= Service.PageSize)
            currentPage.current = currentPage.current + 1;
        }
    }

    const onSearch = ()=> {
        try {
            console.log('搜索:', inputRef.current.nativeElement.value)
            search.current = inputRef.current.nativeElement.value || undefined;
            inputRef.current.blur();
        } catch (error) {
            
        }
        
        loadData(true);
    }

    const renderItem = (item)=> {
        return (
            <div className={Styles.list_item}>
                <div className={Styles.item_left}>
                    <p>{item.mobile}</p>
                    <span>{item.createTime}</span>
                    {
                        activeKey == 1 && <div>{item.productName ?? ''}</div>
                    } 
                </div>
                <div className={Styles.item_right}>
                    <p>{item.inviteStatus}</p>
                    {
                        item.reason ? <span>{item.reason}</span> : null
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={Styles.invite_record}>
            <Header midText={'邀新记录'} shadow/>
            <div className={Styles.record_body}>
                <Tabs
                    className={Styles.tabs}
                    activeKey={activeKey+''}
                    onChange={(key) => setActiveKey(key)}
                >
                    {
                        ['积分','礼品'].map((name,index) =>{
                            return <Tabs.Tab key={index+''} 
                                title={name} 
                            >
                                <ListView 
                                    dataSource={dataList}
                                    renderHeader={()=> {
                                        return (
                                            <div className={Styles.list_header}>
                                                <img src={activeKey == 1 ? require('images/invite-zone/gift_icon.png'):require('images/invite-zone/ponit_icon.png')} alt="" />
                                                <span>{`您已成功邀新${successCount}人`}</span>
                                            </div>
                                        )
                                    }}
                                    emtyDesc={loading ? '数据加载中...' : '暂无数据'}
                                    renderItem={renderItem}
                                    hasMore={hasMore}
                                    onRefresh={loadData}
                                    loadMore={loadMore}
                                />
                            </Tabs.Tab>
                        })
                    }
                </Tabs>
                {
                    activeKey == 1 &&
                    <div className={Styles.input_box}>
                        <Input ref={inputRef} placeholder='请输入礼品名称' onEnterPress={onSearch}/>
                        <SearchOutline fontSize={13} onClick={onSearch}/>
                    </div>
                }
                
            </div>
        </div>
    )
}

export {
  InviteRecord
}