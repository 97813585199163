import React, { useEffect, useState,useRef } from 'react';
import styles from './modal.module.scss';
export const Modal = (props)=>{
    const [value,setValue] = useState('');
    //兑换弹框
    if (props.type === 2) {
        return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
            <div className={styles.exchangeWrap} onClick={()=>{
                props.onOK && props.onOK({value,type:props.type});
            }}/>
        </div>
    } else if(props.type === 3) {
        return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
            <div className={styles.giftWrap}>
                <div className={styles.giftBg}>
                    <p className={styles.title}>恭喜你 中奖啦</p>
                    <img src={props.imgUrl} className={styles.img}/>
                    <p className={styles.text2}>{props.content}</p>
                    <p className={styles.text3}>奖品在我的奖品记录</p>
                    <button className={styles.button} onClick={()=>{
                        props.onOK && props.onOK({value,type:props.type});
                    }}>立即查看</button>
                </div>

                <div className={styles.close} onClick={()=>{
                    props.onCancel && props.onCancel();
                }}/>
            </div>
        </div>
    }
    else {
        let displayButtons = [{
            name: '取消', click: () => {
                props.onCancel && props.onCancel();
            },
            className: styles.cancel,
        },{
            name: props.confirm || '确认', click: () => {
                props.onOK && props.onOK({value,type:props.type});
            },
            className: styles.confirm,
        }];
        if (props.type === 5) {
            displayButtons = [{
                name: props.confirm || '确认', click: () => {
                    props.onOK && props.onOK({value,type:props.type});
                },
                className: styles.confirm,
            }];
        }


        return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
            <div className={styles.modalWrap}>
                <div className={styles.modalTitle}>{props.title || ''}</div>
                {props.type !==1?<div className={styles.modalContent}>{props.content}</div>:false}
                {props.type ===1?<input type="text" value={value} className={styles.input} placeholder={props.content || ''}
                                        onChange={(event) => setValue(event.currentTarget.value)}/>:false}
                <div className={styles.modalButton}>
                    {displayButtons.map((item, key) => {
                        return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                    })}
                </div>
            </div>
        </div>
    }

}
