import React from 'react';
import { Fragment } from 'react';
import styles from '../score.module.scss';
import my_icon from '../images/obtaininSteps@2x.png'
const Invite = (props) => {
    return <div className={styles.inviteNewWrap}>
        <div className={styles.inviteTitleWrap}>
            <div className={styles.inviteTitle}> 邀新赚积分+抽奖</div>
        </div>
        <div className={styles.picWrap}>
            <img src={my_icon} alt='我' className={styles.my_icon} />
        </div>
        <div className={styles.inviteButton}>
            <div className={styles.inviteInfo}>邀请好友</div>
            <div className={styles.inviteInfo}>
                <p>下载绿地G优APP</p>
                <p>并注册登录</p>
            </div>
            <div className={styles.inviteInfo}>
                <p>获得{props.inviteNewClientPoint}积分</p>
                <p>和1次抽奖</p>
            </div>
        </div>
        <div className={styles.introRegular}>
            100%中奖*详见活动规则
        </div>
    </div>
}


const InviteNew = (props) => {
    return <Fragment>
        <Invite {...props}/>
    </Fragment>
}
export default InviteNew;