import React from 'react';
import Info from './info';
import { Success } from './success';

export const Routes = [
    {
        path: '/front/licaiInviter/yuangong',
        component: () => {
            return <Info channel={1} />
        },
        title: '新春福利'
    },
    {
        path: '/front/licaiInviter/success',
        component: Success,
        title: '领取成功',
    },
]