/**
 * @description 基础任务
*/

import React, { useState, useEffect } from 'react';
import Library from 'library';
import Service from '../../service';
import taskIcon1 from 'images/pointTask/icon_huiyuan.png';
import taskIcon2 from 'images/pointTask/icon_shiming.png';
import taskIcon3 from 'images/pointTask/icon_bangding.png';
import taskIcon4 from 'images/pointTask/icon_fengxian.png';
import taskIcon5 from 'images/pointTask/icon_xinxi.png';
import './index.scss';

const {JsBridge, Util} = Library;

const mockData = [
    {type: 'register', status: 'true', point: 200, name: '注册会员',complete:'完成注册' },
    {type: 'certificate', status: 'ready', point: 200, name: '实名认证',complete:'完成实名认证'},
    {type: 'bindCard', status: 'false', point: 200, name: '绑定银行卡',complete:'完成实名认证'},
    {type: 'riskReport', status: 'false', point: 200, name: '风险测评',complete:'完成实名认证'},
    {type: 'completeAll', status: 'true', point: 200, name: '完善个人信息',complete:'完善个人信息'},
];

export default (props) => {
    const { pointTaskList = mockData } = props;

    const onBtnClick = async (item) => {
        if (item.status === 'false') {
            const { path, params } = Util.url.getPramasFromUrl(item.jumpUrl);
            JsBridge.navigate(path, params);
        } else if (item.status === 'ready') {
            const result = await Service.receiveBaseTask(item);
           
            if (result) {
                props.callBack && props.callBack({
                    visible: true,
                    title: '领取成功',
                    content: `恭喜你完成任务！成功领取 ${result.data.oddsSum} 积分，再接再厉哦~`
                });

            }
        }
    }

    if(pointTaskList.length === 0) return null;
    const completeTasks = pointTaskList.filter(a => a.status === 'true');
    const completeRate = Math.floor(completeTasks.length / pointTaskList.length * 100);
    const totalPoints = pointTaskList.reduce((a,b)=>{ return  a + (b.point ||0)},0)
    
    return (
        <div className='base-task'>
            <h1>基础任务</h1>
            <p>进度达到100%<i>可得{totalPoints}积分</i></p>
            <div className={'progress'}>
                <div className={'current'} style={{ width: completeRate + '%' }}>
                    <span className={completeRate === 0 ? '' : 'styleLeft'}>{completeRate}%</span>
                </div>
            </div>
            {
                pointTaskList.sort((a, b) => {
                    return b.status === 'true' ? -1 : 1;
                }).map((item, key) => {
                    item.icon = {
                        register: taskIcon1,
                        certificate: taskIcon2,
                        bindCard: taskIcon3,
                        riskReport: taskIcon4,
                        completeAll: taskIcon5
                    }[item.type];
                    item.className = {
                        true: 'finish',
                        ready: 'earn',
                        false: 'todo'
                    }[item.status];
                    item.buttonText = {
                        true: '已完成',
                        ready: '领取积分',
                        false: '去完成'
                    }[item.status];
                    return (
                        <div key={key} className={'task'}>
                            <img className={'taskIcon'} src={item.icon} alt='任务' />
                            <div className={'taskInfo'}>
                                <h2>{item.name}</h2>
                                <p>{item.complete} <em>得{item.point}积分</em></p>
                            </div>
                            <button 
                                className={item.className}
                                onClick={()=>onBtnClick(item)}
                            >
                                {item.buttonText}
                            </button>
                        </div>
                    )
                })
            }
        </div>
    )
}