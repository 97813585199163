import React from 'react'
import styles from './styles.module.scss'
import back from '../imgs/back.png'
import title from '../imgs/prize-records.png'
import { useHistory } from 'react-router-dom'

const LotteryRecords = () => {
  const history = useHistory()
  return <div className={styles.lottery_records}>
    <div className={styles.lottery_records_container}>
      <div className={styles.records_top}>
        <img className={styles.back} src={back} alt="" onClick={() => history.goBack()}/>
        <img className={styles.title} src={title} alt="" />
        <div className={styles.back}></div>
      </div>
      <div className={styles.records_content}>
        <div className={styles.records_item}>
          <div className={styles.item_left}>
            <span>迪士尼五人（含儿童）家庭贵宾票</span>
            <span>2022-06-16 09:08:45</span>
          </div>
          <div className={styles.item_right} onClick={() => history.push('/front/activities/unityPointsLottery/performerInfo')}>填写观演人信息</div>
        </div>
        <div className={styles.records_item}>
          <div className={styles.item_left}>
            <span>迪士尼五人（含儿童）家庭贵宾票</span>
            <span>2022-06-16 09:08:45</span>
          </div>
          <div className={styles.item_right} onClick={() => history.push('/front/activities/unityPointsLOttery/address?isWrite=1')}>查看订单</div>
        </div>
        <div className={styles.records_item}>
          <div className={styles.item_left}>
            <span>迪士尼五人（含儿童）家庭贵宾票</span>
            <span>2022-06-16 09:08:45</span>
          </div>
          <div className={styles.item_right} onClick={() => history.push('/front/activities/unityPointsLOttery/address?isWrite=0')}>填写地址领奖品</div>
        </div>
      </div>
    </div>
  </div>
}

export default LotteryRecords