import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import {Calendar} from './components/calendar';
import { Actions } from 'reduxs';
import styles from './selectAppointmentDate.module.scss';
export const SelectAppointmentDate = (props) => {
    const history = useHistory();
    const [dictInfo,setDictInfo] = useState({});
    const [arrRow,setArrRow] = useState([]);
    const [arrCalendar,setArrCalendar] = useState([]);
    const [currentValue,setCurrentValue] = useState({});
    const [currentDate,setCurrentDate] = useState('');
    useEffect(() => {
        getData();
    }, []);

    const getData = async ()=>{
        let res = await Services('NuCalm','Calendar',{});
        if (res.status > 0) {
            let {list} = res.data;
            let dict = {};
            for (let item of list) {
                let {date,meal} = item;
                meal.sort((a,b)=>{
                    return a.name > b.name ? 1:-1;
                })
                let arr = [];
                for (let object of meal) {
                    let {type} = object;
                    let isContain = false;
                    let currentRow = null;
                    for (let row of arr) {
                        if (row.type === type) {
                            isContain = true;
                            currentRow = row;
                            break;
                        }
                    }
                    if (!isContain) {
                        arr.push({type,meal:[object]})
                    } else {
                        currentRow.meal.push(object);
                    }
                }
                dict[date] = arr;
            }
            setDictInfo(dict);
            setArrCalendar(list);
        }
    }

    return <div className={styles.container}>
        <p className={styles.title}>选择日期</p>
        <Calendar data={arrCalendar} onSelect={(date)=>{
            let arr = dictInfo[date];
            for (let item of arr) {
                item.unfold = true;
                if (item.meal.length > 4) {
                    item.unfold = false;
                }
            }
            setCurrentDate(date);
            setArrRow(arr);
            setCurrentValue({});
        }}/>
        <p className={styles.title2}>选择时间</p>
        {arrRow.map((item,index)=>{
            let {type,meal=[],unfold=true} = item;
            let arr = meal;
            if (!unfold && meal.length > 4) {
                arr = [meal[0],meal[1],meal[2]];
            }
            return <div className={styles.selectWrap} key={'wrap'+index}>
                <p className={styles.label}>{type}</p>
                <div className={styles.timeZone}>
                    {arr.map((o,i)=>{
                        let {name,value='',appoint,timeField} = o;
                        let currentStyle = '';
                        if (`${value}-${timeField}` === `${currentValue.value}-${currentValue.timeField}`) {
                            currentStyle = styles.active;
                        } else if(!appoint) {
                            currentStyle = styles.gray;
                        }
                        return <button
                            key={i}
                            className={`${styles.btnItem}  ${currentStyle}`}
                            onClick={()=>{
                                if (appoint) {
                                    setCurrentValue(o);
                                }
                            }}
                        >{name}</button>
                    })}
                    {!unfold?<button
                        className={`${styles.btnItem}`}
                        onClick={()=>{
                            item.unfold = true;
                            setArrRow([...arrRow]);
                        }}
                    >更多时间选择&gt;</button>:null}
                </div>
            </div>
        })}
        <button className={`${styles.commit} ${currentValue.value != null?styles.active:''}`} onClick={()=>{
            let {value} = currentValue;
            if (value != null) {
                Actions.calendar.save({...currentValue,date:currentDate});
                history.goBack();
            }
        }}>确定</button>
    </div>;
};
