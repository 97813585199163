import React, { useState, useEffect, useRef, useCallback } from 'react';
import Library from 'library';
import { useParams, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import  './index.scss';
import TopNavigation from '../topNavigation';
import Services from 'services';
import $rem from  '../../../../scss/variables.scss';
const { JsBridge, Hooks, Platform, Util } = Library;

export const MessageDetail = (props) => {
    const { path, params: { id ,type} } = Util.url.getPramasFromUrl(window.location.href);
    const location = useLocation();

    const [content,setContent]=useState("");
    const [title,setTitle]=useState("");
    const [updateTime,setUpdateTime]=useState("");

    useEffect(() => {
        getMessageDetail();
    }, []);

    const getMessageDetail = async () => {
        console.log('request params ',id);
        console.log('request params ',type);

        if(type==1||type==2||type==3)
        {
                    const result = await Services('MainPage', { code: '100010' },
                        {
                            id: id
                        }
                    );
                    if (result.status > 0) {
                        console.log("打印下详情", result.data);
                        setContent(result.data.content.replace(/(<img.*?)data-src=/g,'$1src='));
                        console.log("打印下具体的content------------",result.data.content);
                        setTitle(result.data.title);
                        setUpdateTime(result.data.updateTime);
                    }
             }else{
                    const result = await Services('MainPage', { code: '100011' },
                        {
                            id: id
                        }
                    );
                    if (result.status > 0) {
                        console.log("打印下详情", result.data);
                        setContent(result.data.description);
                        setTitle(result.data.title);
                        setUpdateTime(result.data.updateTime);
                    }
        }
       
       
    }

    return <div className={styles.container}>
        <div className={styles.colorArea}>

        </div>
        <div className={styles.titleCls}>
               {title}
        </div>
        <div className={styles.updateTime}>
               {updateTime}
        </div>
        <div className='descriptionText'
        dangerouslySetInnerHTML={{__html:content}}>
        </div>
        
    </div>

}