/**
 * @description 2023-01-11 界面废弃
*/
import React, { useEffect, useState, useRef } from 'react';
import Library from 'library';
import styles from './index.module.scss';
import selectMore from 'images/icon/select-more@2x.png';
import Services from 'services';
import {isEmptyString} from "../../../library/util";

const { JsBridge, Util } = Library;
export const EditAddress =(props) => {
    const { activityCode,orderId,orderSource } = Util.url.paramsToObj();
    // const { activityCode} = Util.url.paramsToObj();
    const [disabled,setDisabled] = useState(false);
    const [inputInfo, setInputInfo] = useState({ receiverName: '', receiverMobile: '', address: '', location: [] });
    const inputRef = useRef(null);
    useEffect(() => {
        initData();
        setTimeout(() => {
            inputRef.current.focus();
        }, 1000);
    }, []);
    const initData = async () => {
        let params = {};
        let code = '101263';
        if (activityCode) {
            params = {activityCode};
            code = '101267'
        }
        const res = await Services('MainPage', { code }, params);
        if (res.status > 0) {
            let data;
            if (activityCode) {
                data = res.data.jktActivityAddress;
                setDisabled(new Date(res.data.endTime.replace(/-/g, '/')).getTime() <= new Date().getTime());
            } else {
                data = res.data;
            }
            if (data) {
                setInputInfo({
                    receiverName: data.receiverName,
                    receiverMobile: data.receiverMobile,
                    address: data.address,
                    location: [data.province, data.city, data.area]
                });
            }
        }
    }

    const submit = async () => {
        const params = {
            ...inputInfo,
            province: inputInfo.location[0],
            city: inputInfo.location[1],
            area: inputInfo.location[2],
        };
        if (!inputInfo.receiverName) {
            JsBridge.runAction('alert', 'error', ['请填写收件人']);
            return;
        }

        if (!Library.Util.validate.mobile(inputInfo.receiverMobile)) {
            JsBridge.runAction('alert', 'error', ['请填写正确的手机号']);
            return;
        }
        if (inputInfo.location.length < 3) {
            JsBridge.runAction('alert', 'error', ['请填写所在区域']);
            return;
        }
        if (!inputInfo.address) {
            JsBridge.runAction('alert', 'error', ['请填写收件地址']);
            return;
        }
        let code = '101262';
        if (activityCode) {
            params.activityCode = activityCode;
            code = '101265'
        }
        if (!isEmptyString(orderId)){
            params.orderId = orderId;
        }
        if (!isEmptyString(orderSource)){
            params.orderSource = orderSource;
        }
        const res = await Services('MainPage', { code }, params);
        if (res.status > 0) {
            JsBridge.runAction('alert', 'error', ['保存成功！']);
            JsBridge.close();
        }
    }

    return <div className={styles.container}>
        <div className={styles.inputGroup}>
            <label htmlFor='name'>收货人</label>
            <div className={styles.inputLine}>
                <input ref={inputRef} onChange={(event) => {
                    inputInfo.receiverName = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.receiverName} id='name' type='text' placeholder='姓名' disabled={disabled}/>
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='mobile'>手机号</label>
            <div className={styles.inputLine}>
                <input onChange={(event) => {
                    inputInfo.receiverMobile = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.receiverMobile} id='mobile' type='tel' placeholder='输入联系方式' disabled={disabled}/>
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='location'>所在区域</label>
            <div onClick={() => {
                if (!disabled) {
                    JsBridge.runAction('locationPicker', 'show', []).then(e => {
                        inputInfo.location = e;
                        setInputInfo({ ...inputInfo })
                    })
                }

            }} className={styles.inputLine}>
                <input readOnly value={inputInfo.location?.join(' ')} id='location' type='text' placeholder='输入所在区域' disabled={disabled}/>
                <img className={styles.selectMore} src={selectMore} alt='下拉箭头' />
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='address'>详细地址</label>
            <div className={styles.inputLine}>
                <input onChange={(event) => {
                    inputInfo.address = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.address} id='address' type='text' placeholder='输入详细地址' disabled={disabled}/>
            </div>
        </div>
        {disabled ? false : <div className={styles.inputGroup}>
            <button onClick={submit} className={styles.button}>保存</button>
        </div>}

    </div>
}
