import React, { useState, useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Animated from 'animated/lib/targets/react-dom';
import styles from './index.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { vipConfig } from '../conf';
import JsBridge from 'library/jsbridge';


export const Privilege = (props) => {
    const [index, setIndex] = useState(0);
    const [position, setPosition] = useState(new Animated.Value(0));
    const thisIndex = useParams().index;
    const view = useRef();
    const handleChangeIndex = index => {
        setIndex(index);
    };

    const handleSwitch = (index, type) => {
        if (type === 'end') {
            Animated.spring(position, { toValue: index }).start();
            return;
        }
        position.setValue(index);
    };
    useEffect(() => {
        if (thisIndex) {
            setIndex(parseInt(thisIndex, 10));
            handleSwitch(parseInt(thisIndex, 10), 'end');
        }
    }, []);



    let privileges = vipConfig.vip.privileges.concat(vipConfig.base.privileges).filter(a => a.tag);
    const divObj = view && view.current;
    useEffect(() => {
        if (divObj) {
            let width = divObj.clientWidth;
            if (index <= 2) {
                console.log(1111);
                divObj.scrollTo(0, 0);
            } else if (index >= privileges.length - 3) {
                console.log(2222);
                let dis = (width / 375) * 50 * (privileges.length);
                divObj.scrollTo(Math.max(dis, 0), 0);
            } else {
                let x = (width / 375) * 50 * (index - 1);
                console.log(3333);
                divObj.scrollTo(Math.max(x, 0), 0);
            }
        }
    }, [index, divObj, privileges]);

    return <div className={styles.container} >
        <div className={styles.nav} ref={view}>
            <ul>
                {privileges.map((item, key) => {
                    return <li onClick={() => {
                        setIndex(key);
                        handleSwitch(key, 'end');
                    }} key={key} className={index.toString() === key.toString() ? styles.active : ''}>
                        <a onClick={() => {
                            console.log(1);
                        }}>{item.tag}</a>
                    </li>;
                })}
            </ul>
        </div>
        <SwipeableViews
            index={index}
            className={styles.swiper}
            onChangeIndex={handleChangeIndex}
            onSwitching={handleSwitch}
        >
            {privileges.map((privilege, currentIndex) => {
                const inputRange = privileges.map((_, i) => i);
                const scale = position.interpolate({
                    inputRange,
                    outputRange: inputRange.map(i => {
                        return currentIndex === i ? 1 : 565 / 620;
                    }),
                });
                const opacity = position.interpolate({
                    inputRange,
                    outputRange: inputRange.map(i => {
                        return currentIndex === i ? 1 : 0.3;
                    }),
                });
                const translateX = position.interpolate({
                    inputRange,
                    outputRange: inputRange.map(i => {
                        return 0 * (i - currentIndex);
                    }),
                });

                return (
                    <Animated.div
                        key={String(currentIndex)}
                        style={Object.assign(
                            {
                                opacity,
                                transform: [{ scale }, { translateX }],
                            }
                        )}
                        className={styles.wrap}
                    >
                        <h1>{privilege.tag}</h1>
                        <h2>{(privilege.sub || '').toUpperCase()}</h2>
                        <div className={`${styles.content} ${privilege.actionName ? '' : styles.contentBottom}`}>
                            {privilege.content}
                        </div>
                        {privilege.actionName ? <div className={styles.footer}>
                            <a className={styles.button} href={privilege.action}>{privilege.actionName}</a>
                        </div> : false}

                    </Animated.div>
                );
            })}
        </SwipeableViews>
    </div>;
}
