/**
 * @description 母亲节商品推荐模块
*/

import React, { useState, useEffect, Fragment, useMemo } from 'react';
import Library from 'library';
import Service from '../../service';
import './index.scss';

const { JsBridge } = Library;

const initData = process.env.NODE_ENV == 'development' ? 
[
    {
        cateName: "母亲节电器", 
        content: [
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
            {goodsName: "理肤泉新B5多效保湿修复面膜5*25g"},
        ]
    }
]:[]

const ShopRecommend = (props) => {

    const [dataSource, setDataSource] = useState(initData)

    useEffect(() => {
        loadShopList()
    }, [])
    const loadShopList = async () => {
        const result = await Service.getRecommendShopList()
        if (result) {
            setDataSource(result.data)
        }
    }

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${data.skuId}` })
    }

    //折扣价
    const discountPrice = (item={})=> {
        let _reslut = Number(item.marketPrice || 0) * (1 - (item.discount|| 1.0))

        return Number(_reslut.toFixed(2))
    }
    //最终价
    const finalPrice = (item={}) => {
        let _res = Number(item.marketPrice || 0) - discountPrice(item) - Number(item.maxDeductPoint||0)/100
        return _res < 0 ? 0 : Number(_res.toFixed(2));
    } 

    return (
        <div className='shop-recomend-wrapper'>
            {
                dataSource && dataSource.length > 0 &&
                dataSource.map((groupItem, indx) => {
                    return (
                        <div className='shop-recomend' key={indx}>
                            <div className="group-title">
                                <img src={require('images/mothersDay/shop-group.png')} alt="" />
                                <span>{groupItem.cateName}</span>
                            </div>
                            <div className='shop-list'>
                                {
                                    groupItem.content &&
                                    groupItem.content.map((item, i) => {
                                        // item.marketPrice = '200'
                                        // item.maxDeductPoint = '350'
                                        return (
                                            <div key={i} className='shop-list__item' onClick={() => onShopItemClick(item)}>
                                                <div className='item-box'>
                                                    {/* 商品图片 */}
                                                    <div className='img-box'>
                                                        <img src={item.pic } alt="" />
                                                    </div>
                                                    {/* 图框 */}
                                                    <img src={require('images/mothersDay/frame-img.png')} alt="" />
                                                    {/* 图框底部的价格信息 */}
                                                    <div className='abs-item'>
                                                        <div className='price-info'>
                                                            <div className='result-price'>
                                                                <div>
                                                                    <span>母亲节到手价</span>
                                                                    <div>
                                                                        <span>¥</span>
                                                                        {
                                                                            (finalPrice(item)+'').split('.').length > 1 ?
                                                                            <>
                                                                                <span>{(finalPrice(item)+'').split('.')[0]}</span>
                                                                                <span>{'.' + (finalPrice(item)+'').split('.')[1]}</span>
                                                                            </>
                                                                            :<span>{finalPrice(item)}</span> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='price-desc'>
                                                                <div>
                                                                    <span>{Number(item.marketPrice || 0)}</span>
                                                                    <span>原价</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span>{discountPrice(item)}</span>
                                                                    <span>{`${(item.discount || 1.0) * 10}折`}</span>
                                                                </div>
                                                                <div>
                                                                    <span>-</span>
                                                                </div>
                                                                <div>
                                                                    <span>{(item.maxDeductPoint || 0)/100}</span>
                                                                    <span>积分可抵</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className='item-info'>
                                                    {item.goodsName}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                dataSource.length - 1 === indx &&
                                <div className='footer'>
                                    <img src={require('images/mothersDay/footer-logo.png')} alt="" />
                                </div>
                            }
                            
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ShopRecommend;