import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './flexible.js';
import * as serviceWorker from './serviceWorker';


window.signLink = /(Android)/i.test(navigator.userAgent) ?
  undefined :
  window.location.href;

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
document.write(unescape(process.env.REACT_APP_UMENG_SCRIPT||''));
document.write(unescape(process.env.REACT_APP_ERUDA||''));
window.onerror = function(e) {

}
