import React, { Component } from 'react';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

const global = {
  getImageUrl: (url) => {
    return 'https://icon.gkewang.com/gu/weapp' + url;
  }
}
const arrSource = [
  { name: '绿地G优用户注册协议', url: process.env.REACT_APP_CRM_H5_LINK + '/crmh5/#/jkt/doc/company/register' },
  { name: '绿地G优隐私政策', url: process.env.REACT_APP_CRM_H5_LINK + '/crmh5/#/jkt/doc/company/privacy' },
  { name: 'G优尊享会员服务免责条款', url: '/front/member/protocol' },
  { name: '关于我们', url: process.env.REACT_APP_CRM_H5_LINK + '/crmh5/#/jkt/doc/company/aboutus' }
];
export default (props) => {
  const history = useHistory();
  return <div className={styles.aboutContainer}>
    {arrSource.map((item, index) => {
      let { name, url } = item;
      return <div className={styles.item} onClick={() => {
        window.location.href = url;
      }}>
        <span className={styles.text}>{name}</span>
        <img src={global.getImageUrl('/user/general-btn-arrow-more.svg')} className={styles.arrow} />
      </div>
    })}
  </div>
};


