import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import styles from './home.module.scss';
import TopImage from './images/home_info.png';
const { Util } = Library;
export const Home = (props) => {
    const history = useHistory();
    const [status, setStatus] = useState(-1);
    const [hasRecord, setHasRecord] = useState(false);
    useEffect(() => {
        if (new Date().getTime() > new Date('2021/06/28').getTime()) {
            getData();
        } else {
            setStatus(0);
        }
    }, []);

    const getData = async () => {
        let result = await Services('NuCalm', 'RecordList', { page: 1, pageSize: 10 });
        if (result.status > 0) {
            console.log(result);
            let { list } = result.data;
            if (list.length > 0) {
                setHasRecord(true);
                let item = list[0];
                if (parseInt(item.status, 10) === 1) {
                    setStatus(1);
                } else {
                    setStatus(2);
                }
            } else {
                setStatus(2);
                setHasRecord(false);
            }
        }
    }

    return <div className={styles.container}>
        <img src={TopImage} className={styles.topImage} />
        <div className={styles.div} />
        <div className={styles.bottom}>
            <div className={styles.tip}>欢迎有睡眠问题的用户优先体验</div>
            {status !== -1 ? <div className={styles.btnBg}>
                <button className={`${styles.button} ${status !== 2 ? styles.disable : ''}`} onClick={() => {
                    if (status === 2) {
                        history.push('/front/activities/nucalm/appointment');
                    }
                }}>{status === 0 ? '敬请期待' : '我要预约'}</button>
                {hasRecord ? <a className={styles.record} onClick={() => {
                    history.push('/front/activities/nucalm/recordList');
                }}>预约纪录&gt;</a> : null}
            </div> : null}
        </div>

    </div>;
};
