/**
 * 每日签到 参与活动页
 */
import React, { useState, useEffect } from 'react'
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import { useHistory } from 'react-router-dom';
import styles from './join.module.scss';
const { Util } = Library;
let SwiperNc = null;
export const Join = (props) => {
    const [mobile, setMobile] = useState('');
    const [image, setImage] = useState(null);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const history = useHistory();
    const { inviteCode='', activityCode='' } = Util.url.paramsToObj();

    useEffect(() => {
        initial();
    }, [])

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };

    const initial = async () => {
        let result = await Services('BindClient', 'DailySignInviteinit', {inviteCode, activityCode});
        if (result.status > 0) {
            setImage(result.data.bindClientImage);
            initNC();
        }
    }

    const bindClient = async () => {
        if (!Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res && mobile && mobile.replace(/\s/g, '').length >= 11) {
                let result = await Services('BindClient', 'DailySignInviteBind', {inviteCode, activityCode, mobile, ...res});
                if (result.status > 0) {
                    history.replace('/front/activities/dailySignin/joinSuccess')
                }
            }
        }
    }

    return <div className={styles.container}>
        <div className={styles.showBg}>
            <div className={styles.signTip}>连续签到30天下方礼物任选其一</div>
            <div className={styles.awardContent}>
                <div className={styles.tip}>连续签到30天，奖品任选其一</div>
                <img src={image} alt="产品图" className={styles.products} />
                <input type="text" maxLength={11} value={mobile} className={styles.tel} placeholder="输入手机号，签到领会员"
                       onChange={(event) => setMobile(event.currentTarget.value)}/>
                <div className={styles.inviteButton} onClick={()=>{
                    bindClient()
                }}>立即参与</div>
            </div>
        </div>
    </div>

}
