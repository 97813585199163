
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './noticeSetting.module.scss';
import Services from 'services';
import Library from 'library';
import Bell from '../images/bell@2x.png';
import Check from '../images/check.png';
import UnCheck from '../images/uncheck.png';
const { JsBridge } = Library;
export const NoticeSetting = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [isEnable,setIsEnable] = useState(false);
    const [isCheck,setIsCheck] = useState(true);
    const getNotificationsEnabled = async ()=>{
        let enable = await JsBridge.nativeModules('SysModule','isNotificationsEnabled');
        setIsEnable(enable);
    }
    useEffect(()=>{
        getNotificationsEnabled();
    },[]);

    const openNotifications = async ()=>{
        JsBridge.nativeModules('SysModule','openNotificationsSetting');
        history.goBack();
    }
    const agree = async ()=>{
        if (isCheck) {
            const result = await Services('MainPage', { code: '101276' },{isOpenRemind:1})
            if (result.status > 0) {
                location && location.callback(true);
                history.goBack();
            }

        }
    }
    if (isEnable) {
        return <div className={styles.container}>
            <div className={styles.contentBg}>
                <div className={styles.contentOpen}>
                    <p className={styles.text}>向你推送以下消息</p>
                    <p className={styles.text2}>你可能接收到多次提醒，可在活动页面关闭提醒</p>
                    <div className={styles.checkBg} onClick={()=>{
                        setIsCheck(!isCheck);
                    }}>
                        <img src={isCheck?Check:UnCheck} className={styles.img} mode='widthFix' alt=''/>
                        <p className={styles.tip}>叮～绿地G优喊你来世界杯竞猜，快来竞猜赢积分吧</p>
                    </div>
                    <div className={styles.btns}>
                        <button className={styles.btn} onClick={()=>{
                            location.callback(false);
                            history.goBack();
                        }}>取 消</button>
                        <button className={`${styles.btn} ${isCheck?styles.active:''}`} onClick={agree}>同 意</button>
                    </div>
                </div>
            </div>
        </div>
    } else {
        return <div className={styles.container}>
            <div className={styles.contentBg}>
                <div className={styles.contentClose}>
                    <img src={Bell} className={styles.img} mode='widthFix' alt=''/>
                    <p className={styles.text}>开启推送通知权限</p>
                    <p className={styles.text2}>不漏掉任何一场世界杯竞猜</p>
                    <button className={styles.btn} onClick={openNotifications}>立即开启</button>
                    <p className={styles.text2} onClick={()=>{
                        history.goBack();
                    }}>暂时不用</p>
                </div>
            </div>
        </div>
    }
}
