import { throttle } from '../util/throttle';
import { isEmptyString, isIOS } from "../util";
import * as Platform from '../platform';

const postParams = { method: 'setTitle', data: {} };
let actionPools = [];
window.callback = null;

class JsBridge {
    // static ReactNativeJsBridgeIsReady = false;
    static lastTime = 0;

    static ready() {
        if (!JsBridge.ReactNativeJsBridgeIsReady) {
            return new Promise((resolve) => {
                if (!window.JsbridgeReady) {
                    window.JsbridgeReady = [];
                }

                window.JsbridgeReady.push({
                    resolve: () => {
                        resolve();
                        JsBridge.ReactNativeJsBridgeIsReady = true;
                    }
                });
            });
        } else {
            return new Promise((resolve) => {
                resolve(true);
            });
        }
    }


    static hasWebViewBridge() {
        return window.ReactNativeWebView
    }

    // 是否小程序环境
    static isMiniprogram = Platform.check.isMiniprogram(); 

    static sendMessage(params) {
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(params);
            return true;
        } else {
            return false;
        }
    }

    /**
     * @description 发送消息给小程序
     * @param params 消息内容
     * @param { isRedirect } 是否重定向
     * @returns {目前该方法小程序有限制，需在特定时机(小程序后退，组件销毁，分享)触发}
    */
    static miniSendMessage(params, isRedirect) {
        if (window.wx && window.wx.miniProgram && window.wx.miniProgram.postMessage) {
            window.wx.miniProgram.postMessage({data:params});
            if(isRedirect) {
                // 由于程序限制，当前界面重定向才能获取消息
                window.wx.miniProgram.redirectTo({url: `/pages/webview/index?url=${encodeURIComponent(window.location.href)}`});
            }
        }else {
            console.log('小程序环境调用miniSendMessage')
        }
    }
    /**
     * @description 跳转小程序web界面
     * @param url h5界面地址
    */
    static miniWebPush(url) {
        if (window.wx && window.wx.miniProgram){
            window.wx.miniProgram.navigateTo({url: `/pages/webview/index?url=${encodeURIComponent(url)}`});
        }else {
            console.log('小程序环境调用miniWebPush')
        }
    }

    /**
     * @description 跳转小程序页面
     * @param path 小程序页面路径
    */
    static miniNavigateTo(path = '/pages/webview/index?url=') {
        if (window.wx && window.wx.miniProgram){
            window.wx.miniProgram.navigateTo({url: path});
        }else {
            console.log('小程序环境调用miniNavigateTo')
        }
    }
    /**
     * @description 重定向小程序页面
     * @param path 小程序页面路径
    */
    static miniRedirectTo(path = '/pages/index/index') {
        if (window.wx && window.wx.miniProgram){
            window.wx.miniProgram.redirectTo({url: path});
        }else {
            console.log('小程序环境调用miniRedirectTo')
        }
    }

    static miniBack(delta=1) {
        if (window.wx && window.wx.miniProgram){
            window.wx.miniProgram.navigateBack({delta: delta});
        }else {
            console.log('小程序环境调用miniBack')
        }
    }


    /**
     * 在app上打印网页数据
     * */
    static print(data) {
        if (this.hasWebViewBridge()) {
            postParams.method = 'print';
            postParams.data = data;
            this.sendMessage(JSON.stringify(postParams));
        } else {
            console.log(data)
        }

    }

    /**
     * 设置标题
     * title 标题名称
     */
    static setTitle(title) {
        postParams.method = 'setTitle';
        postParams.data = { title };
        this.sendMessage(JSON.stringify(postParams));
    }

    /**
     * 监听返回按钮事件
     */
    static setHeaderLeftClick() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'setHeaderLeftClick';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }


    static setStatusBar(action, params) {
        postParams.method = 'setStatusBar';
        postParams.data = { action, params };
        this.sendMessage(JSON.stringify(postParams));
    }

    static getStatusBarHeight() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'getStatusBarHeight';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 跳转原生页
     * path 原生路径 Web为Webview页面
     * params 路由参数
     *
     * 例：Jsbridge.navigate('Web', { uri:'http://localhost:8090/#/jkt/doc/company/safe'});
     * 跳转至Webview路径为http://localhost:8090/#/jkt/doc/company/safe的原生页面
     */
    static navigate(path = 'Web', params) {
        postParams.method = 'navigate';
        postParams.data = {
            path, params
        };
        this.sendMessage(JSON.stringify(postParams))

    }

    static push(path = 'Web', params = {}) {
        const { actionName, promise } = this.setActionName();
        const now = new Date().getTime();
        // eslint-disable-next-line no-undef
        if (now - this.lastTime >= 1000) {
            // eslint-disable-next-line no-undef
            const that = this;
            postParams.method = 'open';
            postParams.data = {
                actionName, path, params
            };
            that.sendMessage(JSON.stringify(postParams))
            this.lastTime = now;
        }
        return promise
    }

    /**
     * @description 设置原生跳转回调函数,目前仅支持push
     * @example const data = await JsBridge.push('CustomWeb', {uri: uri, callback: true});
     * @event window.JsBridge.callback(item)
    */
    static callback(params) {
        postParams.method = 'callback';
        postParams.data = { params };
        this.sendMessage(JSON.stringify(postParams))
    }

    static close() {
        postParams.method = 'close';
        postParams.data = {};
        this.sendMessage(JSON.stringify(postParams))
    }

    static backToTop() {
        postParams.method = 'navigatePopToTop';
        postParams.data = {};
        this.sendMessage(JSON.stringify(postParams))
    }

    /**
     * 跳转原生Webview
     * uri Webview路径
     * 例：Jsbridge.open('/#/jkt/doc/company/safe');
     */
    static open(uri) {
        const now = new Date().getTime();
        // eslint-disable-next-line no-undef
        if (now - this.lastTime >= 1000) {
            // eslint-disable-next-line no-undef
            uri = uri.startsWith('/') ? (window.location.origin + uri) : uri;
            postParams.method = 'open';
            postParams.data = {
                path: 'Web', params: { uri }
            };
            this.sendMessage(JSON.stringify(postParams));
            this.lastTime = now;
        }

    }

    /**
     * android下的webview返回
     */
    static back() {
        postParams.method = 'navigateGoback';
        postParams.data = {};
        this.sendMessage(JSON.stringify(postParams))
    }

    /**
     * Webview goback
     */
    static webviewGoback() {
        postParams.method = 'webviewGoback';
        postParams.data = {};
        this.sendMessage(JSON.stringify(postParams))
    }

    static replace(path = 'Web', params) {
        postParams.method = 'replace';
        postParams.data = {
            path, params
        };
        this.sendMessage(JSON.stringify(postParams))
    }

    static isFullScreen() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'isFullScreen';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 调用原生Services
     */
    static runServices(module = 'alert', serviceName = 'show', params = [], isLoading = true) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'services';
        postParams.data = {
            module, serviceName, params, actionName, isLoading
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 调用原生Actions
     */
    static runAction(module = 'alert', action = 'show', params = []) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'actions';
        postParams.data = {
            module, action, params, actionName
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }


    static getCurrentUserInfo() { //不会触发登录
        const { actionName, promise } = this.setActionName();
        postParams.method = 'getCurrentUserInfo';
        postParams.data = { actionName: actionName };
        this.sendMessage(JSON.stringify(postParams));
        return JsBridge.hasWebViewBridge() ? promise : {};;
    }

    static getGuUserClientId() { //获取用户clientId 供外部使用
        console.log('JsBridge.hasWebViewBridge()', JSON.stringify(JsBridge.hasWebViewBridge()))
        const { actionName, promise } = this.setActionName();
        postParams.method = 'getGuUserClientId';
        postParams.data = { actionName: actionName };
        this.sendMessage(JSON.stringify(postParams));
        return JsBridge.hasWebViewBridge() ? promise : {};;
    }

    static async getDetailCurrentUserInfo() { //不触发登录的前提下,拿到用户的登录状态
        const userInfo = await this.getCurrentUserInfo();
        if (userInfo && userInfo.clientId) { //登录了
            const { actionName, promise } = this.setActionName();
            postParams.method = 'getDetailCurrentUserInfo';
            postParams.data = { actionName: actionName, params: { clientId: userInfo.clientId } };
            this.sendMessage(JSON.stringify(postParams));
            return promise;
        } else { //没登录
            return { ...userInfo, 'isLogin': false }
        }
    }

    static async startUnity3d(params) { // 启动元宇宙
        const { actionName, promise } = this.setActionName();
        postParams.method = 'startUnity3d';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }


    static async goLogin() { // 去登陆
        const { actionName, promise } = this.setActionName();
        postParams.method = 'goLogin';
        postParams.data = { actionName: actionName };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }


    static async runRealName() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'realName';
        postParams.data = { actionName: actionName, params: {} };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 实名认证校验
     */
    static verifyRealName(message) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'realNameVerify';
        postParams.data = { actionName: actionName, params: { message } };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    static setActionName = () => {
        var actionName = new Date().getTime() + parseInt(Math.random() * 10000, 10);
        if (window[actionName]) {
            return this.setActionName();
        } else {
            const promise = new Promise((resolve) => {
                window[actionName] = resolve;
            });
            return {
                actionName, promise
            };
        }
    };

    static updateHistory = (history) => {
        postParams.method = 'updateHistory';
        postParams.data = {
            history
        };
        this.sendMessage(JSON.stringify(postParams));
    };

    /**
     * 原生打开Linking Url
     * Url
     * 例：Jsbridge.link('/#/jkt/doc/company/safe');
     */
    static link(url) {
        postParams.method = 'link';
        postParams.data = {
            link: url
        };
        this.sendMessage(JSON.stringify(postParams));
    }

    static canOpenLink(url) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'canOpenLink';
        postParams.data = {
            link: url, actionName
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    static share = (shareParams) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'share';
        postParams.data = { actionName: actionName, params: shareParams };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    static shareBoard = (shareParams) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'shareboard';
        postParams.data = { actionName: actionName, params: shareParams };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };
    /**
    *分享小程序
    */
    static shareMiniProgram = (params = []) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'shareMiniProgram';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    static saveImageToPhotosAlbum = (uri) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'saveImageToPhotosAlbum';
        postParams.data = { actionName: actionName, params: { uri } };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    /**
     * 保存base64图片
     */
    static saveImgBase64(imgData) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'saveImgBase64';
        postParams.data = {
            actionName,
            params: { imgData }
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 拨打电话
     * tel 电话号码
     */
    static tel(tel) {
        postParams.method = 'tel';
        postParams.data = { tel };
        this.sendMessage(JSON.stringify(postParams));
    }

    /**
     * 获取设备信息
     */
    static getDeviceInfo = (action) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'deviceInfo';
        postParams.data = { actionName: actionName, params: [], action };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    /**
     * 获取网络信息
     */
    static getNetInfo = () => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'netInfo';
        postParams.data = { actionName: actionName, params: {} };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    /**
     * 请求权限
     * @param {*} permission
     */
    static requestPermission = (permission) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'requestPermission';
        postParams.data = { actionName: actionName, params: { method: permission } };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    };

    /**
     * 选择图片
     */
    static imagePicker = (params) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'imagePicker';
        postParams.data = { actionName: actionName, params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 选择图片
     */
    static camera = (params) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'camera';
        postParams.data = { actionName: actionName, params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 下载图片
     */
    static downloadFile = (params) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'downloadFile';
        postParams.data = { actionName: actionName, params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 图片保存至相册
     */
    static saveImageToPhotosAlbum = (uri) => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'saveImageToPhotosAlbum';
        postParams.data = { actionName: actionName, params: { uri } };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 获取地理位置
     */
    static geolocation() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'geolocation';
        postParams.data = {
            actionName
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 银行卡扫描
     * platform:使用平台，百度:baidu,商汤:sense
     */
    static startBankCard(platform = 'sense') {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'startBankCard';
        let params = { platform };
        postParams.data = {
            actionName, params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 身份证扫描
     * type:正反面
     * platform:使用平台，百度:baidu,商汤:sense
     */
    static startIdCard(type, platform = 'sense') {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'startIdCard';
        let params = { type, platform };
        postParams.data = {
            actionName, params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 活体失败
     * platform:使用平台，百度:baidu,商汤:sense
     */
    static startLiveness(platform = 'sense') {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'startLiveness';
        let params = { platform };
        postParams.data = {
            actionName, params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**监听app的前后台的通知
     */
    static appStateListener() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'appStateListener';
        let params = {};
        postParams.data = {
            actionName, params
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }


    /**
     * U盟track
     */
    static UmengTrack = (umengPramas, content, extra) => {
        postParams.method = 'UmengTrack';
        postParams.data = { umengPramas, content, extra };
        this.sendMessage(JSON.stringify(postParams));
    }

    /**
     * 获取app版本号以及渠道信息
     */
    static getAppInfo = () => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'appInfo';
        postParams.data = { actionName: actionName };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 调用原生NativeModules
     */
    static nativeModules(module = 'SysModule', action = '', params = []) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'nativeModules';
        postParams.data = {
            module, action, params, actionName
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 添加监听事件桥接(监听当前页面navigation)
     */
    static addListener = (eventName = 'didFocus') => {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'addListener';
        postParams.data = { actionName: actionName, params: { eventName } };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 保存数据
     * */
    static setStorage(data) {
        postParams.method = 'setStorage';
        postParams.data = data;
        this.sendMessage(JSON.stringify(postParams));
    }

    /**
     * 读取数据
     * */
    static getStorage(key) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'getStorage';
        postParams.data = { actionName: actionName, key };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }
    /**
     * 删除数据
     * */
    static removeStorage(key) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'removeStorage';
        postParams.data = { actionName: actionName, key };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 版本号比较
     */
    static compareVersion = (v1, v2) => {
        if (v1 === v2) {
            return 0;
        } else {
            let arr1 = v1.split('.');
            let arr2 = v2.split('.');
            let ret = -1;
            for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
                let n1 = '0';
                let n2 = '0';
                if (arr1.length > i) {
                    n1 = arr1[i];
                }
                if (arr2.length > i) {
                    n2 = arr2[i];
                }
                if (n1 > n2) {
                    ret = 1;
                    break;
                } else if (n1 < n2) {
                    ret = -1;
                    break;
                }
            }

            return ret;
        }
    }

    /**
     * 返回按钮
     */
    static backHandler() {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'backHandler';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }
    /**
     * @description 拉起app收银台
     * @param {
     *       orderInfo: {
     *           orderSn: '20210210003800717', //订单号
     *           serviceTimestamp: '1617326893271', //服务器时间戳
     *           expired: '1617326893271',//订单过期时间戳
     *           amount: '100', //订单金额（元）
     *           resource: 'insurance' //订单来源 电商平台：mall    保险：insurance
     *       },
     *
     *   } params
    */
    static pay(params) {
        // JsBridge.replace('OrderPayment', params);
        JsBridge.navigate('OrderPayment', params);

    }

    /**
     * 监听键盘事件
     */
    static addKeyboardEvent() {
        if (isIOS()) return Promise.resolve();
        const { actionName, promise } = this.setActionName();
        postParams.method = 'addKeyboardEvent';
        postParams.data = {
            actionName, params: {}
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 设置右侧按钮
     * @program {
     *     title: '文字标题',
     *     style: {样式},
     *     uri: '图片',
     *     action: 事件类型
     * }
     * @program callback: 事件回调
     * 例子：{
     *          uri: require('@/assets/image/share/share-icon.png'),
     *          title: '分享',
     *          style: {color: 'red',height: 16},
     *          action:'sendHomeShareMessage'
     *      }
     * 使用:
     * JsBridge.setHeaderRightContent((data)=> {
            JsBridge.print('点击了分享')
        })
     * 清理按钮: JsBridge.clearRightContent();
     *
     */
    static setHeaderRightContent(params = {}, callback) {
        document.rightContent = params || {}
        if (callback) {
            JsBridge.onMessage((data) => {
                if (data.action == params.action) {
                    callback(data)
                }
            })
        }
    }

    static onMessage(callback) {
        if (document.rightContent.action) {
            //首页首次触发监听时，需主动发送一次消息。具体可查看rn交互注入代码部分
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ method: 'historyState', title: document.title, rightContent: document.rightContent || {} }));
            }

        }

        window.addEventListener('message', (event) => {
            JsBridge.print(`window--------onMessage:${event.data}`)

            const data = (typeof event.data == 'string' && !isEmptyString(event.data)) ? JSON.parse(event.data) : event.data
            if (data.action) {
                if (window.ReactNativeWebView) {
                    callback && callback(data)
                } else {
                    console.log('not found window.ReactNativeWebView')
                }
            }

        }, false);
        document.addEventListener('message', (event) => {
            JsBridge.print(`document--------onMessage:${event.data}`)

            const data = (typeof event.data == 'string' && !isEmptyString(event.data)) ? JSON.parse(event.data) : event.data
            if (data.action) {
                if (window.ReactNativeWebView) {
                    callback && callback(data)
                } else {
                    console.log('not found window.ReactNativeWebView')
                }
            }
        }, false);

    }
    /**
     * 清理右侧按钮
     *
     */
    static clearRightContent() {
        document.rightContent = null
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ method: 'historyState', title: '', rightContent: {} }));
        }
    }

    static async launchMiniApp(params) { // 打开小程序
        const { actionName, promise } = this.setActionName();
        postParams.method = 'launchMiniApp';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    static async isAuthHealthPermission(params) { // 是否授权打开了健康功能
        const { actionName, promise } = this.setActionName();
        postParams.method = 'isAuthHealthPermission';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    static async getUserStepCount(params) { // 此方法只对iOS 获取健康的步数
        const { actionName, promise } = this.setActionName();
        postParams.method = 'getUserStepCount';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    static async openAppSettings(params) { // 此方法只对iOS 打开app的设置
        const { actionName, promise } = this.setActionName();
        postParams.method = 'openAppSettings';
        postParams.data = { actionName: actionName, params: params };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }
    /**
    * 竖屏设置
    */
    static screenRotate(lock = false) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'screenRotate';
        postParams.data = {
            actionName, params: { lock }
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }

    /**
     * 隐藏状态栏
     */
    static hiddenStatusBar(hiddenStatusBar) {
        const { actionName, promise } = this.setActionName();
        postParams.method = 'hiddenStatusBar';
        postParams.data = {
            actionName, params: { hiddenStatusBar }
        };
        this.sendMessage(JSON.stringify(postParams));
        return promise;
    }
}

window.JsBridge = JsBridge;
export default JsBridge;

//  import JsBridge from 'gu-jsbridge';
//  export default JsBridge;
