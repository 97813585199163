import React, { useState, useEffect, useRef, useCallback } from 'react';
import Library from 'library';
import TopNavigation from '../topNavigation';
import styles from './index.module.scss';
import wrapFirstBg from './images/wrapFirstBg.png';
import wrapSecondBg from './images/wrapSecondBg.png';
import secondPartImg1 from './images/secondPartImg1.png';
// import secondPartImg2 from './images/secondPartImg2.png';
// import secondPartImg3 from './images/secondPartImg3.png';
// import secondPartImg4 from './images/secondPartImg4.png';
import smallCloseIcon from './images/smallCloseIcon.png';

const { JsBridge } = Library;
export const RespectRights = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    return <div className={styles.container}>
        <TopNavigation title='尊享权益' />
        <div className={styles.header}>

            <div className={styles.wrapBigImg}>
                <img src={wrapFirstBg} className={styles.wrapFirstBg} />
                <img src={wrapSecondBg} className={styles.wrapSecondBg} />
            </div>
        </div>
        <div className={styles.respectSecondPart}>
            <img src={secondPartImg1} className={styles.secondPartImg1} />
            <img src={require('./images/vip4.png')} className={styles.secondPartCartImg} />
            <img src={require('./images/vip3.png')} className={styles.secondPartCartImg} />
            <img src={require('./images/vip2.png')} className={styles.secondPartCartImg} />
            <img src={require('./images/vip1.png')} className={styles.secondPartCartImg} />
        </div>
        <div className={styles.botBtn} onClick={() => {
            setIsVisible(true);
        }}>
            咨询详情  了解更多
        </div>

        <div onClick={() => {
            setIsVisible(false);
        }} className={styles.botModal} style={isVisible ? { display: 'block' } : { display: 'none' }}>
            <div className={styles.botModalInner} onClick={(event) => {
                event.stopPropagation();
            }} >
                <img src={smallCloseIcon}
                    className={styles.smallCloseIcon}
                    onClick={() => {
                        setIsVisible(false);
                    }} />
                <span className={styles.smallElement1}>
                    联系我们
                </span>
                <span className={styles.smallElement2}>
                    总机号码：021-53011225
                </span>
                <span className={styles.smallElement3}>
                    根据语音提示请拨分机号816
                </span>
                <span className={styles.smallElement4} onClick={() => {
                    JsBridge.tel('021-53011225');
                }}>
                    拨打电话
                </span>
            </div>
        </div>
    </div>
}
