import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import styles from "./share.module.scss";
import Library from 'library';
import close from "./images/xx@2x.png";
import { Actions } from 'reduxs';
import MomentImage from "../signActivity/images/Moments-icon@2x.png";
import WechartImage from "../signActivity/images/WeChat-icon@2x.png";
import QQImage from "../signActivity/images/QQ-icon@2x.png";
import qqkongjianImage from "../signActivity/images/qqkongjian-icon@2x.png";
import weiboImage from "../registionActivity/images/weibo@2x.png";
import downloadImage from "../registionActivity/images/download@2x.png";
import QRCode from "qrcode.react";
import logoImage from "../../../images/icon/logo@2x.png";
import share from './images/share.png';
const { Platform, JsBridge, Util } = Library;


export const ShareModal = () => {
    const [shareModalStatus, setShareModalStatus] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { activityCode ='' } = useParams();
    const { inviteCode } = Util.url.paramsToObj();

    const shareImageRef = useRef();

    useEffect(() => {

    }, []);

    const goBack = () => {
        if (Platform.check.isApp() && Platform.check.isAndroid()) {
            JsBridge.webviewGoback();
        } else {
            history.goBack();
        }
    }


    const createShareImage = () => {
        return new Promise(async (resolve) => {
            html2canvas(document.getElementById('shareImageRef'), {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch(() => {
                resolve(false);
            });
        })
    }
    // 生成分享图片
    const getShareImagePath = () => {
        return new Promise(async (resolve) => {
            if (!shareImageRef.current) {
                resolve(false);
            }
            let base64 = await createShareImage();
            if (base64) {
                let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
                if (!res) {
                    JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                    resolve(false);
                } else {
                    resolve(res.tempFilePath);
                }
            } else {
                resolve(false);
            }

        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.modal} onClick={() => {
            }}>
                <div className={styles.childBack}>

                    <div className={styles.verticalMiddle}>

                        <div className={styles.shareContainer} id='shareImageRef' ref={shareImageRef}>
                            <div className={styles.shareImage}>
                                <img src={share} alt="背景" />
                            </div>
                            <div className={styles.erCode}>
                                <QRCode
                                    value={`${window.location.origin}/front/inviter/register/${inviteCode}?activityCode=${activityCode}`}
                                    size={70}
                                    fgColor="#000000"
                                    imageSettings={{
                                        src: logoImage,
                                        x: null,
                                        y: null,
                                        height: 10,
                                        width: 10,
                                        excavate: false,
                                    }} />
                                <div className={styles.div}>
                                    <p className={styles.title}>绿地30周年限量数字藏品</p>
                                    <p className={styles.span}>长按识别二维码快来抢购</p>
                                </div>
                            </div>
                        </div>
                        <img className={styles.close} src={close} onClick={() => { goBack && goBack() }} />
                        <div className={styles.middleWord}>好友通过此二维码购买数字藏品即分享成功</div>
                    </div>
                </div>
                <div className={styles.bottomShare} >
                    <div className={styles.share} onClick={() => { setShareModalStatus(true) }}>分享</div>
                    {shareModalStatus ? <div className={styles.shareMask}>
                        <div onClick={() => {
                            setShareModalStatus(false);
                        }} className={styles.mask} />
                        <div className={styles.shareBox}>
                            <div className={styles.shareTitle}>请选择分享平台</div>
                            <div className={styles.shareInfo}>
                                <button onClick={async () => {
                                    let filePath = await getShareImagePath();
                                    if (filePath) {
                                        const result = await JsBridge.share({ text: '', icon: filePath, link: '', title: '', platform: 2 });
                                        Actions.alert.show(result.message);
                                    }
                                }}><img src={MomentImage} />
                                    <p>朋友圈</p>
                                </button>

                                <button onClick={async () => {
                                    let filePath = await getShareImagePath();
                                    if (filePath) {
                                        const result = await JsBridge.share({ text: '', icon: filePath, link: '', title: '', platform: 1 });
                                        Actions.alert.show(result.message);
                                    }
                                }}><img src={WechartImage} />
                                    <p>微信好友</p>
                                </button>
                                <button onClick={async () => {
                                    const permissionResult = await JsBridge.requestPermission('requestAlbum');
                                    if (permissionResult.result) {
                                        let filePath = await getShareImagePath();
                                        const result = await JsBridge.share({ text: '', icon: filePath, link: '', title: '', platform: 4 });
                                        Actions.alert.show(result.message);
                                    }
                                }}><img src={QQImage} />
                                    <p>QQ好友</p>
                                </button>
                                <button onClick={async () => {
                                    let filePath = await getShareImagePath();
                                    if (filePath) {
                                        const result = await JsBridge.share({ text: '', icon: filePath, link: '', title: '', platform: 5 });
                                        Actions.alert.show(result.message);
                                    }
                                }}><img src={qqkongjianImage} />
                                    <p>QQ空间</p>
                                </button>
                                <button onClick={async () => {
                                    const res = await JsBridge.canOpenLink('sinaweibo://splash');
                                    if (res) {

                                        let filePath = await getShareImagePath();
                                        const result = await JsBridge.share({ text: '', icon: filePath, link: '', title: '', platform: 0 });
                                        Actions.alert.show(result.message);
                                    } else {
                                        Actions.alert.show('请先安装新浪微博客户端');
                                    }
                                }}><img src={weiboImage} />
                                    <p>新浪微博</p>
                                </button>
                                <button onClick={async () => {
                                    const permissionResult = await JsBridge.requestPermission('requestAlbum');
                                    if (permissionResult.result) {
                                        let res = Platform.check.isAndroid() ? await getShareImagePath() : await createShareImage();
                                        const result = await JsBridge.saveImageToPhotosAlbum(res);
                                        Actions.alert.show(result.message);
                                    } else {
                                        // Actions.alert.show(permissionResult.message);
                                    }
                                }}><img src={downloadImage} />
                                    <p>下载相册</p>
                                </button>
                            </div>
                            <div className={styles.buttonBg}>
                                <button className={styles.shareCancel} onClick={() => {
                                    setShareModalStatus(false);
                                }}>取 消</button>
                            </div>
                        </div></div> : false}
                </div>
            </div>
        </div>
    )

}
