/**防重复点击*/
import {Toast} from "antd-mobile";
import {SHA256} from 'crypto-js';

export function throttle(func, wait) {
    var previous = 0;
    return function () {
        let now = Date.now();
        let context = this;
        let args = arguments;
        if (now - previous > wait) {
            func.apply(context, args);
            previous = now;
        }
    }
}
/* 防止重复点击 */
let clickTimer = 0
export function clickThrottle(interval = 3000) {
    let now = +new Date(); // 获取当前时间的时间戳
    let timer = clickTimer; // 记录触发事件的事件戳

    if (now - timer < interval) {
        // 如果当前时间 - 触发事件时的事件 < interVal，那么不符合条件，直接return false，
        // 不让当前事件继续执行下去
        return false;
    } else {
        // 反之，记录符合条件触发了事件的时间戳，并 return true，使事件继续往下执行
        clickTimer = now;
        return true;
    }
}



/**
 *
 * @param {string} text 需要复制的文字
 * @param {node} parentNode 插入的父节点，如果插入body会使页面focus后偏移
 */
export function copyText(text, parentNode) {
    let input = document.createElement('input')
    input.id = 'copy_input'
    input.readOnly = "readOnly"  //防止ios聚焦触发键盘事件
    input.style.position = "absolute";
    input.style.left = '-1000px'
    input.style.zIndex = "-1000";
    parentNode.appendChild(input)

    input.value = text.toString();
    // ios必须先选中文字且不支持 input.select();
    selectText(input, 0, text.length);
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        Toast.show("复制成功！")
    } else {
        Toast.show("复制失败，请手动复制！")
    }
    input.blur();
    parentNode.removeChild(input);

    // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
    // 选择文本。createTextRange(setSelectionRange)是input方法
    function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {//ie
            const range = textbox.createTextRange();
            range.collapse(true);
            range.moveStart('character', startIndex);//起始光标
            range.moveEnd('character', stopIndex - startIndex);//结束光标
            range.select();//不兼容苹果
        } else {//firefox/chrome
            textbox.focus();
            textbox.setSelectionRange(startIndex, stopIndex);
        }
    }
}

/****
 *获取随机的名字
 *
 * */
export function randWord(count = 1, type, len = 10) {

    let rdmString = '';
    return rdmString + getTimeString().substr(getTimeString.length - 5, 5);
}

/****
 * 获取时间的字符串
 *
 * */

export function getTimeString() {
    let date = new Date(),
        Y = date.getFullYear(),
        M = date.getMonth(),
        D = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds(),
        time = Y.toString() + M.toString() + D.toString() + h.toString() + m.toString() + s.toString();
    let math = Math.random() * 1000000000000000000;
    return time + math.toString();
}

/****
 * 获取年月日的字符串
 *
 * */

export function getYMRTimeString() {
    let date = new Date(),
        Y = date.getFullYear(),
        M = date.getMonth() + 1,
        D = date.getDate(),
        time = Y.toString() + M.toString() + D.toString();
    return time;
}

/****
 * 获取当前时间戳
 *
 * */
export function getTimestamp() {
    const timestamp = Math.round(new Date().getTime() / 1000);
    return timestamp;
}

/**
 * @description 生成唯一标识
*/
export function getUniqueId(){
    return  Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

/**
 * @description 字符串中字母大小写互换
*/
export function swapCase(str) {
    return str.split('').map(char => {
      if (char === char.toUpperCase()) {
        return char.toLowerCase();
      } else {
        return char.toUpperCase();
      }
    }).join('');
}

/**
 * @description 外网访问接口参数处理
 * @param {clientId: , *} 需要的参数
*/

export function encryptFuc (objc = {}) {

    function randomString(e) {
        e = e || 36;
        var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n
      }

      function hashcode(str) {
        var hash = 0, i, chr, len;
        if (str.length === 0) return hash;
        for (i = 0, len = str.length; i < len; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    const timestamp = new Date().getTime()
    const nonce = randomString()

    const count = Math.abs(hashcode(nonce) % 36) || 8;
    const sign = SHA256((objc.clientId || '') + timestamp.toString() + nonce.slice(-count)).toString().toUpperCase()

    const encrypt = Buffer.from(JSON.stringify(objc)).toString("base64");

    return {
        timestamp,
        nonce,
        sign,
        data: swapCase(encrypt)
    }
}

/**
 *获取文件后缀
 * path:param
 *
 */
export function getfileSuffix(path) {
    if (!path || path.indexOf('.') === -1) {
        return '';
    }
    let ext = path.substring(path.lastIndexOf('.')).toLowerCase();
    return ext;
}

export const checkType = (e, typeStr) => {
    return Object.prototype.toString.call(e) === '[object ' + typeStr + ']';
};

export const isNumber = (e) => checkType(e, 'Number');
export const isString = (e) => checkType(e, 'String');
export const isBoolean = (e) => checkType(e, 'Boolean');
export const isObject = (e) => checkType(e, 'Object');
export const isArray = (e) => checkType(e, 'Array');
export const isFunction = (e) => checkType(e, 'Function');

export function isEmptyString(string) {
    return (string === undefined || !string || !(string.constructor === String) || string.length === 0);
}

export function isEmptyArray(array) {
    return (array === undefined || !array || !Array.isArray(array) || array.length === 0);
}

export function isEmptyObject(obj) {
    if (!obj || !isObject(obj)) return true;
    return Object.keys(obj).length === 0
}

export function isIOS(){
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return isiOS;
}

/**
 *  通知元宇宙关闭商城的webview
 *
 */
export const notifyYYZToCloseWeb = (params) => {
    console.log('关闭元宇宙');
    window.location.href = `ulitewebview://JSSendMessageToUnityCloesView?${'type=close'}`;
};



