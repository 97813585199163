import React from 'react';
import Jsbridge from 'library/jsbridge';
import styles from './list.module.scss';
import conf from './conf';

const HelpList = (props) => {
    const listArray = props.listArray;
    return (
        <ul className={styles.helpCenterWrap}>
            {listArray.map((item, index) => {
                return <li key={index} className={styles.helpItem} onClick={() => {
                    Jsbridge.open(`${window.location.origin}/front/doc/company/help/detail/${index}`);
                }}>
                    <i className={styles.itemIcon}></i>
                    {item.title}
                </li>;
            })}
        </ul>
    );
}

export class List extends React.Component {

    /**
     * async fetch demo
     * @returns {XML}
     */
    constructor(props) {
        super(props);
        this.state = {
            init: false,
        };
        this.unlisten = null;
    }

    componentWillMount() {
        Jsbridge.setTitle('帮助中心');
    }

    render() {
        return <div className={styles.helpCenter}>
            <HelpList listArray={conf.helpCenter} />
        </div>
    }
}
