import React from 'react';
import Jsbridge  from 'library/jsbridge';
import styles from './detail.module.scss';
import conf from './conf';

class ListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        };
    }

    render() {
        const { item } = this.props;
        const { status } = this.state;
        return (
            <div>
                <div className={styles.helpItem} onClick={() => {
                    this.setState({ status: !status });
                }}>
                    <i className={styles.itemIcon}></i>
                    {item.title}
                </div>
                <div className={status
                    ?  `${styles.helpItemText} ${styles.show}`
                    : styles.helpItemText } dangerouslySetInnerHTML={{
                        __html: (typeof item.content === 'string' ? item.content : item.content.map((text) => {
                            return `<p>${text}</p>`;
                        }).join(''))
                    }}>

                </div>
            </div>
        );
    }
}

export class Detail extends React.Component {

    /**
     * async fetch demo
     * @returns {XML}
     */
    constructor(props) {
        super(props);
        const { index } = props.match.params;
        this.state = {
            article: conf.helpCenter[index]
        };
    }

    componentWillMount() {
        Jsbridge.setTitle('帮助中心');
    }

    render() {
        const { article } = this.state;
        return <div className={styles.helpDetail}>
            <div className={styles.helpDetailWrap}>
                {article.contents.map((item, index) => {
                    return <ListItem key={index} item={item} />
                })}
            </div>
        </div>
    }
}
