import React, { useEffect, useState } from "react";
import Services from 'services';
import { useHistory } from 'react-router-dom'
import { Actions } from 'reduxs';
import Library from 'library';
const { Platform, JsBridge } = Library;
export const ActivityTime = (props) => {
    const history = useHistory();
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const { isProtocal, isJump } = props;
    useEffect(() => {
        requestTime();
    }, []);

    const requestTime = async () => {
        const result = Platform.check.isApp() ? await Services('MainPage', { code: '101147' }, { activity: 'INVITECLIE' }) : await Services('InviteActivity', 'ActivityRequest', { activity: 'INVITECLIE' });
        const { status, data } = result;
        if (status > 0) {
            const { startTime, endTime, active } = data.data;
            setStartTime(startTime);
            setEndTime(endTime);
            if (!active && isJump) {
                Actions.alert.show('活动已经结束啦');
                setTimeout(() => {
                    if (Platform.check.isApp()) {
                        JsBridge.close()
                    } else {
                        history.replace('/front/isRegisted/home')
                    }
                }, 2000);
            }
        }

    };
    if (isProtocal) {
        if (endTime && endTime.length > 0) {
            let time = endTime.split('/');
            return time[0] + '年' + time[1] + '月' + time[2] + '日';
        }
    }
    return ''
};
