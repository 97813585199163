import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./eggDialog.module.scss"
import img_quxiao from './../../../../images/egg_bt_quxiao.png'
import img_sure from './../../../../images/egg_bt_yes.png'

/**
 *
 * type=0 暂无隐藏框 (按钮:取消:邀请赠送)  content:还没持有隐藏款，请好友赠送
 * type=1 title:暂无史诗款  (按钮:取消:邀请赠送)  content:还没持有史诗款，请好友赠送
 * type=2 title:暂无传说款 (按钮:取消:邀请赠送)  content:还没持有传说款，请好友赠送
 * type=3 title:惊喜空投 (按钮:查看详情:确认)  content:尊贵的隐藏款用户，恭喜您获得绿地Ape-World （领取数量：Nx1）请到【我的藏品】查看详情
 **/

const EggDialog = ({maskVisible, setMaskVisible, type = 2}) => {
    let title = '';
    let content = '';
    let leftBtnText = '';
    let rightBtnTitle = '';

    if (type === 0) {
        title = '暂无隐藏款';
        content = '还没持有隐藏款，请好友赠送';
        leftBtnText = '取消';
        rightBtnTitle = '邀请赠送';
    } else if (type === 1) {
        title = '暂无史诗款';
        content = '还没持有史诗款，请好友赠送';
        leftBtnText = '取消';
        rightBtnTitle = '邀请赠送';
    } else if (type === 2) {
        title = '暂无传说款';
        content = '还没持有传说款，请好友赠送';
        leftBtnText = '取消';
        rightBtnTitle = '邀请赠送';
    } else if (type === 3) {
        title = '惊喜空投';
        content = '尊贵的隐藏款用户，恭喜您获得绿地Ape-World （领取数量：Nx1）请到【我的藏品】查看详情';
        leftBtnText = '查看详情';
        rightBtnTitle = '确认';
    }

    const btnClick = (index) => { //0取消,1确认
        setMaskVisible(!maskVisible);
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.little_title}>
                        <span className={styles.count}>{content}</span>
                    </div>
                    <div className={styles.cancel_sure_father} onClick={() => btnClick(0)}>
                        <div className={styles.cancle_btn_father}>
                            <img src={img_quxiao} alt=""/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_cancel}>{leftBtnText}</span>
                            </div>
                        </div>
                        <div className={styles.cancle_btn_father} onClick={() => btnClick(1)}>
                            <img src={img_sure} alt=""/>
                            <div className={styles.text_btn_father}>
                                <span className={styles.text_btn_sure}>{rightBtnTitle}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default EggDialog;
