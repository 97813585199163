import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Actions } from 'reduxs';
import styles from './appointment.module.scss';
import TopImage from './images/GPremium-pic-brand.png';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import arrow from './images/arrow.png';
import sloganImage from './images/slogan_white.png';
const AppointmentHook = (props) => {
    const history = useHistory();
    const { nucalmInfo, calendarInfo } = props;
    const [name, setName] = useState(nucalmInfo.name || '');
    const [phone, setPhone] = useState(nucalmInfo.phone || '');
    const [department, setDepartment] = useState(nucalmInfo.department || '');
    const [hasRecord, setHasRecord] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let result = await Services('NuCalm', 'RecordList', { page: 1, pageSize: 10 });
        if (result.status > 0) {
            let { list } = result.data;
            setHasRecord(list.length > 0);
        }
    }

    const add = async () => {
        let reg = new RegExp(/[^\u4e00-\u9fa5A-Za-z]+$/);
        let reg2 = new RegExp(/[^\u4e00-\u9fa5A-Za-z0-9]+$/);
        if (reg.test(name)) {
            Actions.alert.show('姓名仅支持中文和字母,请重新输入');
            return;
        } else if (reg2.test(department)) {
            Actions.alert.show('部门仅支持中文,字母和数字,请重新输入');
            return;
        }

        Actions.user.nucalmInfo({ name, phone, department })
        let time = `${calendarInfo.date} ${calendarInfo.name.substr(0, 5)}:00`;
        let result = await Services('NuCalm', 'Add', {
            promotionTime: time,
            deviceCategoryNumber: calendarInfo.value,
            userName: Library.Util.format.utf16toEntities(name),
            mobile: phone,
            department: Library.Util.format.utf16toEntities(department),
            type: calendarInfo.type,
            timeField:calendarInfo.timeField
        });
        if (result.status > 0) {
            history.replace('/front/activities/nucalm/appointmentSuccess');
        }
    }
    const canCommit = () => {
        if (name.length > 0 && phone.length === 11 && department.length > 0 && calendarInfo != null) {
            return true;
        }
        return false;
    }

    const onChangeText = (type, e) => {
        if (type === 0) {
            setName(e.currentTarget.value.replace(' ', ''));
        } else if (type === 1) {
            setPhone(e.currentTarget.value.replace(/[^0-9]/g, ''));
        } else if (type === 2) {
            setDepartment(e.currentTarget.value.replace(' ', ''));
        }
    };

    return <div className={styles.container}>
        <img src={TopImage} className={styles.topImage} />
        <div className={styles.content}>
            <a className={styles.title}>NuCalm 体验预约</a>
            <div className={styles.inputWrap}>
                <img className={styles.icon} src={icon1} />
                <input type='text' maxLength={10} placeholder={'请输入姓名'} onChange={(e) => {
                    onChangeText && onChangeText(0, e)
                }} value={name} />
            </div>
            <div className={styles.inputWrap}>
                <img className={styles.icon2} src={icon2} />

                <input type="tel" maxLength={11} placeholder={'请输入联系方式'} onChange={(e) => {
                    onChangeText && onChangeText(1, e)
                }} value={phone} />
            </div>

            <div className={styles.inputWrap}>
                <img className={styles.icon3} src={icon3} />
                <input type='text' maxLength={10} placeholder={'请输入所在部门'} onChange={(e) => {
                    onChangeText && onChangeText(2, e)
                }} value={department} />
            </div>

            <div className={styles.btnDate} onClick={() => {
                Actions.user.nucalmInfo({ name, phone, department })
                history.push('/front/activities/nucalm/selectAppointmentDate');
            }}>
                <p className={`${styles.des} ${calendarInfo != null ? styles.active : ''}`}>{calendarInfo != null ? `${calendarInfo.type} | ${calendarInfo.date} ${calendarInfo.name}` : '选择套餐和预约时间'}</p>
                <img className={styles.arrow} src={arrow} />
            </div>
            <button className={`${styles.commit} ${canCommit() ? styles.active : ''}`} onClick={() => {
                if (canCommit()) {
                    add();
                }
            }}>提交预约</button>
            {hasRecord ? <a className={styles.record}
                onClick={() => {
                    history.push('/front/activities/nucalm/recordList');
                }}>预约纪录&gt;
            </a> : null}
        </div>
        <img className={styles.slogan} src={sloganImage} />
    </div>;
};

export const Appointment = connect((state) => {
    return { calendarInfo: state.calendar.toJS().info, nucalmInfo: state.user.toJS().nucalmInfo || {} };
})(AppointmentHook);