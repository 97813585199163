//在元宇宙里面的h5页面调用gkt的接口
import Library from 'library';
import Service from 'gktapp-service/dist/src/h5/service';
import Common from '../h5/index';
import { showLoading, hideLoading } from '../../components/loading1'
import { Toast } from 'antd-mobile'

const { Fetch, Platform } = Library;
const SMSdk = window.SMSdk;

function dealSmDeviceId () {
  return new Promise((resolve, reject) => {
    const smTimeoutTime = 1000;
    let smDeviceId = '';
    let smDeviceIdReady = false;

    function getSmDeviceCallBack () {
      smDeviceId = SMSdk.getDeviceId ? SMSdk.getDeviceId() : smDeviceId;
      clearTimeout(smTimer);
      if (!smDeviceIdReady) {
        smDeviceIdReady = true;
        //执行业务逻辑
        resolve((smDeviceId.length <= 100 && smDeviceId) || '');
      }
      return { smDeviceId, smDeviceIdReady };
    }

    const smTimer = setTimeout(getSmDeviceCallBack, smTimeoutTime);
    SMSdk.ready(getSmDeviceCallBack);
  });
}

const h5Service = async (moduleName, service, params, header, customLoading = false) => {
  const _Service = Service;
  const _Common = Common;
  const Services = { ..._Service, ..._Common };
  const module = Services[moduleName];
  console.log(module);
  if (!module) {
    throw `module【${moduleName}】 not exist`;
  }
  let ServiceClass;
  if (typeof service === 'string') {
    ServiceClass = module[service];
    console.log(ServiceClass);
  } else {
    ServiceClass = module['BaseService'];
  }
  if (!ServiceClass) {
    throw `request【${moduleName}/${service}】 not exist`;
  }
  const serviceClass = new ServiceClass()
  if (typeof service == 'object') {
    serviceClass.code = service.code
    serviceClass.method = service.method || 'post';
  }
  const smDeviceId = await dealSmDeviceId();
  const fetch = serviceClass.createFetch({
    url: '/h5/api/', source: Platform.check.isWechat() ? 'wh5' : 'h5', version: 'wh5', smDeviceId: smDeviceId,
    token: header?.token || '', userCode: header?.userCode || ''
  }, Fetch);

  fetch.requestParams = params || {};
  if (!customLoading) {
    showLoading();
  }
  const res = await fetch.fetch('h5');
  if (!customLoading) {
    hideLoading();
  }
  if (res.status > 0) {
    if (res.data.success !== false && (!res.data.code || res.data.code === '000000')) {
      return res;
    } else {
      Toast.show(res.data.msg);
      throw (res.data.msg);
    }
  } else if (res.status === -1000) {
    return false;
  } else {
    Toast.show(res.msg || '网络请求异常!');
    return false;
  }
}

export const getDataInUnity = async (serviceCode, params, hideLoading=false) => {
  let user = localStorage.getItem('user_info');
  if (!user) {
    return { status: -1, msg: '无用户数据' }
  }
  try {
    user = JSON.parse(user);
    let userCode = user?.userCode || '';
    console.log('user---', user)
    if (!userCode) {
      const res = await h5Service('Common', { code: '600029' }, {
        "clientId": user.clientId,
      }, undefined, true);
      userCode = res.data.result;
      localStorage.setItem('user_info', JSON.stringify({ ...user, userCode: res.data.result }))
      console.log('result---', res.data.result)
    }
    const header = {
      token: user.token,
      userCode: userCode,
    }
    const result = await h5Service('Common', { code: serviceCode, }, {...params, userCode, token: user.token, clientId: user.clientId}, header, hideLoading);

    return result.data;
  } catch (e) {
    console.log('e---', e)
  }
};
