import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './rewardModal.module.scss';
import rewardTitleImage from './images/game-reward-head@2x.png';
import gamePatchImage from './images/game-patch@2x.png';

export default (props) => {
    const history = useHistory();
    const location = useLocation();
    const { nextDays, fragment, nextFragment, signInDay } = location.state;
    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>
                    <img src={rewardTitleImage} alt='蝴蝶结' />
                </div>
                <div className={styles.modalContent}>
                    <h1>—— 您已连续签到{signInDay}天 ——</h1>
                    <p>获得</p>
                    <div className={styles.rewardInfo}>
                        <img src={gamePatchImage} alt='碎片' />
                        <span>{fragment}个碎片</span>
                    </div>
                    <div className={styles.rewardFuture}>
                        再签到{nextDays}天可获得：<img src={gamePatchImage} alt='碎片' /> x {nextFragment}
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={() => {
                        history.goBack();
                    }}>我知道啦</button>
                </div>
            </div>
        </div>
    </div>
}