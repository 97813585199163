import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import vsIcon from '../images/icon_vs.png';
import smile from '../images/smile.png';
import cry from '../images/cry.png';
import share from '../images/share.png';
import Progress from '../components/progress';
import notLimit from '../images/pic_biaoqian2.png';
import Header from 'components/header';
import ShareModal from '../components/activityShareModal';
import guanbi from "../images/close.png";
import vs from '../images/icon_vs.png';
import shareImg from '../../../../images/icon/icon_share.png';
import { Popup, Toast } from 'antd-mobile';
import Services from 'services';
import ListView from 'components/ListView1';
import Library from 'library';

const { Util, JsBridge } = Library;

const GuessDetail = () => {
  const { number='' } = Util.url.paramsToObj() || {};
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [invitePopupVisible, setInvitePopupVisible] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [popoverIndex, setPopoverIndex] = useState('');
  const [inviteRecord, setInviteRecord] = useState([]);
  const [guessInfo, setGuessInfo] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [inviteCount, setInviteCount] = useState(0);
  const timeId = useRef(null);
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    timeId.current = setTimeout(() => {
      setPopoverIndex('')
    }, 1500);
    return () => {
      timeId.current && clearTimeout(timeId.current);
    }
  }, [popoverIndex])

  useEffect(() => {
    (async () => {
      await getGuessInfo();
    })();
  }, [])

  const participateInGuess = async (i, guessName) => {
    if(guessInfo?.gameResult) return;
    if(popoverIndex === i) {
      setPopoverIndex('');
      return;
    } else {
      timeId.current && clearTimeout(timeId.current);
      setPopoverIndex(i);
    }
    await attendWorldCupGuess(guessName);
  }

  // 参与竞猜
  const attendWorldCupGuess = async (guessName) => {
    const result = await Services('MainPage', { code: '101271' }, {number, gameResult: guessName});
    if(result.status > 0) {
      await getGuessInfo();
      Toast.show('已参与本场竞猜');
    }
  }

  // 获取竞猜详情
  const getGuessInfo = async () => {
    const result = await Services('MainPage', { code: '101292' }, {number});
    JsBridge.print(result);
    if(result.status > 0) {
      setGuessInfo(result.data);
    }
  }

  const getInviteRecord = async (isRefresh) => {
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      number: guessInfo?.number
    }
    const result = await Services('MainPage', { code: '101270' }, params);
    JsBridge.print(result);
    if(result.status > 0) {
      if(pageNum.current === 1) {
        setInviteRecord(result.data?.InviteRecord || []);
      } else {
        setInviteRecord([...inviteRecord, ...(result.data?.InviteRecord || [])]);
      }
      setHasMore(result.data?.InviteRecord?.length >= pageSize.current);
      setInviteCount(result.data?.count);
    }
    setIsLoad(true);
    setInvitePopupVisible(true);
  }

  const {
    isOpenGuess,
    isOpenInvite,
    isOpenShow,
    group,
    round,
    gameResult,
    homeTeamName,
    visitingTeamName,
    homeTeamFlag,
    visitingTeamFlag,
    winRatio,
    evenRatio,
    loseRatio,
    inviteNum,
    gameStartTime,
    guessPoints
  } = guessInfo || {};

  if(!guessInfo) return null;

  return <div className='guess-detial'>
    <Header
      midText={`${homeTeamName}-${visitingTeamName}`}
      rightImg={shareImg}
      rightClick={() => {
          if(!guessInfo?.gameResult) return Toast.show('请先参与竞猜');
          setShareModalVisible(true)
        }
      }
    />
    <ShareModal
      maskVisible={shareModalVisible}
      closeMask={() => setShareModalVisible(false)}
      type={1}
      matchInfo={guessInfo}
      isDonation={true}
    />
    <ShareModal
      maskVisible={inviteModalVisible}
      closeMask={() => setInviteModalVisible(false)}
      type={1}
      matchInfo={guessInfo}
      isDonation={false}
    />
    {isOpenGuess === 1 && <div className='guess-info'>
      <div className="guess-info-title">
        {guessInfo?.number > 48 ? <span>第{guessInfo?.number}场 {group}</span>: <span>第{guessInfo?.number}场 {group}组第{round}轮</span>}
        <span>{gameStartTime?.slice(5,16)}开赛</span>
      </div>
      <div className="battle-info">
        <div className="battle-info-top">
          <span>{homeTeamName}</span>
          <img className='contury-flag' src={homeTeamFlag} alt="" />
        </div>
        <div className="battle-info-middle">
          <div className="battle-info-middle-score">
            <img src={vsIcon} alt="" />
          </div>
        </div>
        <div className="battle-info-top">
          <span>{visitingTeamName}</span>
          <img className='contury-flag' src={visitingTeamFlag} alt="" />
        </div>
      </div>
      <div className="pop-progress">
        <Progress successPercentage={winRatio} failPercentage={loseRatio} flatPercentage={evenRatio} />
      </div>
      <div className="guess-btn-box">
        <div className={`btn-left ${gameResult === '3' || !gameResult ? 'light-btn': 'gray-btn'}`} onClick={() => participateInGuess(0, '3')}>
          <span>{homeTeamName}</span>
          {popoverIndex === 0 && <div className="popover-points">积分+{guessPoints}</div>}
        </div>
        <div className={`btn-middle ${gameResult === '1' || !gameResult ? 'light-btn': 'gray-btn'}`} onClick={() => participateInGuess(1, '1')}>
          <span>平局</span>
          {popoverIndex === 1 && <div className="popover-points">积分+{guessPoints}</div>}
        </div>
        <div className={`btn-right ${gameResult === '0' || !gameResult ? 'light-btn': 'gray-btn'}`} onClick={() => participateInGuess(2, '0')}>
          <span>{visitingTeamName}</span>
          {popoverIndex === 2 && <div className="popover-points">积分+{guessPoints}</div>}
        </div>
      </div>
    </div>}
    <div className="task-box">
      <div className="task-title">做任务，得更多基础积分！</div>
      <div className="task-type">
        {isOpenInvite === 1 && <div className="task-left task-common-style">
          <img className='not-limit' src={notLimit} alt="" />
          <div className="task-left-top">
            <p>邀新助威得积分</p>
            <p>竞猜连胜最高返5倍积分！</p>
          </div>
          <div className="task-left-middle">
            <div className="guess-points">
              <img src={smile} alt="" />
              <span>猜对+300</span>
            </div>
            <div className="guess-points">
              <img src={cry} alt="" />
              <span>猜错+100</span>
            </div>
          </div>
          <div className="invite-piples" onClick={() => getInviteRecord(true)}>已邀新{inviteNum || 0}人&gt;</div>
          <Popup
            visible={invitePopupVisible}
            onMaskClick={() => setInvitePopupVisible(false)}
            bodyStyle={{ borderRadius: "10px 10px 0 0" }}
          >
            <div className='invite-container'>
              <div className="invite-title">
                <span>助威记录</span>
                <img onClick={() => setInvitePopupVisible(false)} src={guanbi} alt="" />
              </div>
              <div className="current-match-info">
                <span className='left-span'>{homeTeamName}</span>
                <div className="vs-img"><img src={vs} alt="" /></div>
                <span className='right-span'>{visitingTeamName}</span>
              </div>
              <div className="invite-counts"> ——已有{inviteCount || 0}人帮你助威——</div>
              <div className="invite-user-info">
                <ListView
                  onRefresh={() => getInviteRecord(true)}
                  list={inviteRecord}
                  hasMore={hasMore}
                  isLoad={isLoad}
                  loadMore={() => getInviteRecord(false)}
                  ListItem={({item}) => <InviteItem item={item}/>}
                />
              </div>
            </div>
          </Popup>
          <div
            className="invite-btn"
            onClick={() => {
              if(!guessInfo?.gameResult) return Toast.show('请先参与竞猜');
              setInviteModalVisible(true);
            }}
          >立即邀新</div>
        </div>}
        {isOpenShow === 1 && <div className="task-right task-common-style">
          <div className='task-left-top'>分享当前比赛，得积分</div>
          <img className='share-img' src={share} alt="" />
          <div className='invite-piples'>每日首次分享得积分</div>
          <div
            className="share-btn invite-btn"
            onClick={() => {
                if(!guessInfo?.gameResult) return Toast.show('请先参与竞猜');
                setShareModalVisible(true)
            }}
          >立即分享</div>
        </div>}
      </div>
    </div>
  </div>
}

const InviteItem = ({item}) => {
  const {imagePath, createTime, loginName} = item || {};
  return <div className="invite-info-item">
    <div className="info-item-left">
      <img src={imagePath} alt="" />
      <span>用户{loginName}帮你助威</span>
    </div>
    <div className="invite-time">{createTime?.slice(5)}</div>
  </div>
}

export default GuessDetail;
