import React, { useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import BScroll from "@better-scroll/core";
import ObserveDOM from "@better-scroll/observe-dom";
import MouseWheel from "@better-scroll/mouse-wheel";
import ScrollBar from "@better-scroll/scroll-bar";
import PullDown from "@better-scroll/pull-down";
import Pullup from "@better-scroll/pull-up";
import styles from "../modal.module.scss";
import Library from 'library';

import close from "../images/rectangle@2x.png"
const { Platform, JsBridge } = Library;

const Scroll = ({
  prop,
  onPullup,
  loading,
  myPopularity,
  children
}) => {
  const history = useHistory();
  //  Better-scroll 实例
  const wrapRef = useRef(null);

  //  记录Better-scroll是否实例化
  const initRef = useRef(false);

  const [scrollObj, setscrollObj] = useState();

  BScroll.use(ObserveDOM);
  BScroll.use(MouseWheel);
  BScroll.use(ScrollBar);
  BScroll.use(PullDown);
  BScroll.use(Pullup);

  const initBScroll = () => {

    let bScroll = new BScroll(wrapRef.current, {
      probetype: 3,
      //  可以使用原生的点击
      click: true,
      //  检测dom变化
      observeDOM: true,
      //  鼠标滚轮设置
      mouseWheel: {
        speed: 20,
        invert: false,
        easeTime: 300,
      },
      //  显示滚动条
      //  过度动画
      useTransition: true,
      //  下拉刷新
      pullDownRefresh: {
        threshold: 70,
        stop: 0,
      },
      //  上拉加载更多
      pullUpLoad: {
        threshold: 90,
        stop: 10,
      },
    });
    setscrollObj(
        bScroll
    );

  };

  //  对象初始化
  useEffect(() => {
    initBScroll();
    return () => {
      scrollObj&&scrollObj.destroy();
    };
  }, []);


  const pullup = async () => {
    onPullup && (await onPullup());
    setTimeout(() => {
      scrollObj.finishPullUp();
      scrollObj.refresh();
    }, 500);
  };
  const goBack = () => {
    if (Platform.check.isApp() && Platform.check.isAndroid()) {
      JsBridge.webviewGoback();
    } else {
      history.goBack();
    }
  }
  //  对象事件挂载
  useEffect(() => {

    if (initRef.current === true) {

      //  上拉加载
      //  每次更新都需要先把之前的pullingDown事件清除，不然会累加
      scrollObj.off("pullingUp");
      scrollObj.on("pullingUp", pullup);
    } else {
      initRef.current = true;
    }
  }, [prop]);

  return (
    <div className={styles.bottomScroll}>

    <div className={styles.head}>
      <div className={styles.bigword}>好友助力记录<span><img onClick={()=>{goBack&&goBack()}} src={close} alt="close"/></span></div>
      <div className={styles.smallword}>人气值: <span>{myPopularity}</span></div>
    </div>
    <div
      ref={wrapRef}
      className={`${styles.wrapHeight} scroll-warpper`}
    >
      <div className="scroll-content">
        {children}
        {loading===0?<div className={styles.loading}>正在拼命加载...</div>:loading===2?
            <div className={styles.loading}></div>:""
        }

      </div>

    </div>
  </div>
  );
};


export default Scroll;
