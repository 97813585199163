import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Services from 'services';
import Scroll from "./components/scroll";
import styles from "./editTeam.module.scss";
import {Modal} from "./components/modal";
import Library from 'library';
import backIcon from 'images/icon/back-svg-black.svg';
import shareIcon from "../activity71/images/share.png";


const { Util, JsBridge } = Library;
export const EditTeam = () => {
    const [pageNum, setPageNum] = useState(1);
    const [newstate, setnewstate] = useState([]);
    const [visible, setVisible] = useState(false);
    const [loadingState, setloadingState] = useState(0);
    const [deleteElement,setDeleteElement] = useState({id:-1,index:-1});
    const location = useLocation();
    const [statusBarHeight, setStatusBarHeight] = useState(0);

    const [isLoad, setIsLoad] = useState(false);
    const teamId = useQuery().get('teamId');
    const activityCode = useQuery().get('activityCode');

    const modalInfo = useRef({head:"删除成员",title:"确认删除该成员",confirm:"确认",type:1});
    useEffect(() => {
        getHeight();
        getList(pageNum)
    }, []);

    const getHeight = async () => {
        let { height } = await JsBridge.getStatusBarHeight();
        setStatusBarHeight(height);
    }

    const dissolve = async () => {
        if (isLoad) {
            return;
        }
        let res = await Services('MainPage', { code: 300430}, { teamId:teamId});

        if (res.status > 0) {
            JsBridge.back();
        }
    };

    const getList = async (page) => {
        if (isLoad) {
            return;
        }
        let res = await Services('MainPage', { code: 300421 }, { teamId:teamId,activityCode, pageNum: page, pageSize: 10 });

        if (res.status > 0) {
            let arr = res.data;
            setnewstate([...newstate, ...arr]);
            setloadingState(2);
        }
    };
    const deleteMember = async (id,index)=>{
        setDeleteElement({id,index});
        setVisible(true);
    };
    const deleteRequest = async (id,index)=>{
        let res = await Services('MainPage', { code: 300422 }, { teamId,teamMemberId:id,activityCode });
        if(res.data.isSuccess === true){
            newstate.splice(index,1);
            setnewstate(newstate.concat([]));
        }
    }
    if (statusBarHeight === 0) {
        return false;
    } else {
        return (
            <div className={styles.container}>
                <div style={{ paddingTop: (statusBarHeight) + 'px' }}>
                    <div className={styles.header}/>
                </div>
                <div className={styles.navigator} style={{ paddingTop: statusBarHeight + 'px' }}>
                    <div className={styles.header} >
                        <div className={styles.left} onClick={() => {
                            JsBridge.back();
                        }}>
                            <img src={backIcon} alt='箭头' />
                        </div>
                        <div className={styles.title} >编辑战队成员</div>
                        <div onClick={() => {
                            modalInfo.current = {head:"解散战队",title:"解散后将清空战队战力值，确认解散战队？",confirm:"确认",type:2};
                            setVisible(true);
                        }} className={styles.right}>
                            解散战队
                        </div>
                    </div>
                </div>
                <div className={styles.feedListScoped}>
                    {newstate.map((item, idx) =>
                        <a>
                            <div className={styles.feedItemContainer} key={idx}>
                                <section className={styles.imgContainer}>
                                    <img src={item.imagePath} className={styles.lazyWrapper} />
                                    {item.isRealName === '0'?<div className={styles.inviteValidate}><span>未实名</span></div>:<span></span>}
                                    <span className={styles.inviteLogin}>
                                      <div>{item.realName || ""}</div>
                                      <div>{item.createTime || ""}</div>
                                </span>
                                </section>
                                <article onClick={deleteMember.bind(this,item.id,idx)}>删除成员</article>
                            </div>
                        </a>
                    )}
                </div>
                <Modal
                    visible={visible}
                    content={modalInfo.current}
                    onOK={async ()=>{
                        if (modalInfo.current.type === 1) {
                            await deleteRequest(deleteElement.id,deleteElement.index);
                            setVisible(false);
                        } else if (modalInfo.current.type === 2) {
                            setVisible(false);
                            dissolve();
                        }

                    }}
                    onCancel={()=>{
                        setVisible(false);
                    }}
                />
            </div>
        )
    }


}
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
