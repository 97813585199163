/**
 * @description 商品推荐模块
*/

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import { Toast } from 'antd-mobile';
import { ModalAlert } from 'components';
import GoodsShareModal from '../goodsShareModal';
import ShopItem from './shopItem';
import PointShopItem from './pointShopItem';
import Service from '../../invite-zone/service';
import Styles from './index.module.scss';
const { JsBridge, Util } = Library;

const ShopRecommend = (props)=> {
    const {dataSource={}, onRefesh} = props
    const {productList = [], exchList=[] } = dataSource;
    const history = useHistory();
    const PageRef = useRef();

    const onDidFocus = () => {
        JsBridge.addListener().then(async () => {
           onRefesh && onRefesh(true);
        });
    }

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        const skuId = data.skuId
        if(skuId) {
            JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` });
        }
       
    }
    // 兑换事件
    const onItemReceive = (data) => {
        console.log(data);
        const {skuId, productId} = data;
        if (JsBridge.hasWebViewBridge()) {
            const param = Util.url.objectToQueryParams({
                skuId: skuId,
                activityCode: Service.activityCode,
                productId: productId,
            });
            //添加activityCode用于在商城端验证用户资格
            JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?${param}` })
            onDidFocus();
        }
    }
    // 领取记录
    const onReceiveRecord = ()=> {
        const param = Util.url.objectToQueryParams({
            apiCode: '101329',
            idf: 'commonSub',
            activityCode: Service.activityCode,
        });
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/activities/awardRecord?${param}` });
        } else {
            history.push(`/front/activities/awardRecord?${param}`)
        }
        
    }

    // 商品邀新
    const onShopInvite = (item={})=> {
        const cuttentTime = dataSource?.systemTime ? new Date(dataSource?.systemTime?.replace(/-/g, '/')) : new Date();
        const isEnd = cuttentTime.getTime() - new Date(dataSource?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        GoodsShareModal.open({
            activityCode: Service.activityCode, 
            inviteCode: dataSource?.inviteCode,
            goods: item
        })
        
    }

    //领取商品奖励
    const onReceive = async (st)=> {
        const cuttentTime = dataSource?.systemTime ? new Date(dataSource?.systemTime?.replace(/-/g, '/')) : new Date();
        const isEnd = cuttentTime.getTime() - new Date(dataSource?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        // 领取
        const result = await Service.receivePrize({productId: st.productId});
        if(result) {
            const content = (
                <div className={Styles.modal_receive}>
                    <img src={require('images/invite-zone/modal_close.png')} alt="" onClick={()=>{
                        ModalAlert.hide();
                    }}/>
                    <div className={Styles.content}>
                        <div className={Styles.title}>{'领取成功!'}</div>
                        <span>{'请尽快填写您的收货地址'}</span>
                        <span>{'也可以在领取记录中查看并填写地址'}</span>
                        <span>{'活动结束后未填写地址奖品将作废'}</span>
                        <div className={Styles.address_btn} onClick={()=>{
                            onInputAddress(result.data ?? {})
                            setTimeout(()=>{
                                ModalAlert.hide();
                            },1000)
                        }}>填写地址</div>
                    </div>
                </div>
            );
            ModalAlert.show({
                getContainer: PageRef.current,
                maskStyle: { background: 'rgba(0, 0, 0, 0.7)' },
                content: content,
            })
            // 领取成功刷新界面
            if(onRefesh) {
                setTimeout(()=>{
                    onRefesh()
                },500)
            }
            
        }
        
    }

    // 填写地址
    const onInputAddress = (prizeInfo={})=> {
        const param = Util.url.objectToQueryParams({
            idf: 'commonSub',
            activityCode: Service.activityCode,
            name: prizeInfo.prizeName,
            imgUrl: prizeInfo.imageUrl,
            req: prizeInfo.req,
        });
        JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/guapp/activityOrder?${param}` });
    }

    return (
        <div className={Styles.shop_recommend} ref={PageRef}>
            <div className={Styles.shop_bg}/>
            <div className={Styles.content}>
                <div className={Styles.shop_header}>
                    <div>
                        <img src={require('images/invite-zone/shop_header.png')} alt="" />
                        <span onClick={onReceiveRecord}>{'领取记录>'}</span>
                    </div>
                    <span>邀新新用户成功领好礼</span>
                </div>
                {
                    exchList &&
                    exchList.map((item,k)=>{
                        return (
                            <PointShopItem key={k} item={item} onClick={()=>onShopItemClick(item)} onReceive={onItemReceive} onShopInvite={onShopInvite}/>
                        )
                    })
                }
                {
                    productList &&
                    productList.map((item,k)=>{
                        return (
                            <ShopItem key={k} item={item} onClick={()=>onShopItemClick(item)} onReceive={onReceive} onShopInvite={onShopInvite}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ShopRecommend;