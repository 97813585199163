import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import GUComponents from './../../components';
import Library from '../../library';
import { Actions } from '../../reduxs';
import Services from './../../services';

export default (props) => {
    const [mobile, setMobile] = useState('');
    const [init] = useState(false);
    const [kaptchaCodeInput, setKaptchaCodeInput] = useState('');
    const [dynamicCode, setDynamicCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const history = useHistory();

    const countDown = useSelector((state) => {
        return state.sms.toJS().adminLoginCounter;
    });

    //设置input对应值
    const changeFieldValue = async (field, event) => {
        let value = event && event.target.value;
        if (field === 'mobile') {
            setMobile(value);
        } else if (field === 'kaptchaCodeInput') {
            setKaptchaCodeInput(value);
        } else if (field === 'dynamicCode') {
            setDynamicCode(value);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)

        return () => {
            clearInterval(timer);
        }
    }, [countDown]);

    useEffect(() => {
        isLogin();
    }, [init]);

    const isLogin = async () => {
        const result = await Services('Coupon', 'IsLogin');
        console.log(result);
        if (result) {
            // 分享会有异常
            // history.replace('/front/admin/index/home');
            const { data: { list, staffFlag } } = result;
            Actions.user.staffMenu(list);
            Actions.user.isStaff(staffFlag);
            window.location.href = '/front/admin/index/home';
        }
    };

    const sendSms = async () => {
        // 短信验证码
        const result = await Services('Coupon', 'GetDynamicCode', {
            mobile,
            kaptchaCodeInput
        });
        if (result) {
            Actions.sms.countDownLogin(new Date().getTime() + 60 * 1000);
        }
    }

    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.smsBtn}> {lastSecond} 秒 </button>;
        } else {
            if (Library.Util.validate.mobile(mobile) && kaptchaCodeInput.length >= 4) {
                return <button onClick={sendSms} className={styles.smsBtn}>获取</button>;
            } else {
                return <button disabled className={styles.smsBtn}>获取</button>;
            }
        }
    };

    const submitRender = () => {
        if (Library.Util.validate.mobile(mobile) && kaptchaCodeInput.length >= 4 && dynamicCode.length >= 6) {
            return <div className={styles.loginBtn} onClick={submit}>
                <span className={styles.verifyBtnText} >登录</span>
            </div>;
        } else {
            return <div className={styles.disabledBtn}>
                <span>登录</span>
            </div>;
        }
    }

    const submit = async () => {
        const result = await Services('Coupon', 'Login', {
            mobile,
            dynamicCode
        });
        if (result) {
            const { data: { list, staffFlag } } = result;
            Actions.user.staffMenu(list);
            Actions.user.isStaff(staffFlag);
            window.location.href = '/front/admin/index/home';
        }
    };

    return <div className={styles.home}>
        <div className={styles.blockGray}></div>
        <div className={styles.blockWhite}>
            <div className={styles.loginContent}>
                <input type='tel'
                    className={styles.loginItem}
                    value={mobile}
                    placeholder="请输入手机号码"
                    onChange={(event) => changeFieldValue('mobile', event)}
                // onBlur={this.scrollTop.bind(this)}
                />
                <div className={styles.loginCodeWrap}>
                    <input type='text'
                        className={styles.loginCodeItem}
                        value={kaptchaCodeInput}
                        placeholder="请输入图形验证码"
                        maxLength='6'
                        onChange={(event) => changeFieldValue('kaptchaCodeInput', event)}
                    // onBlur={this.scrollTop.bind(this)}
                    />
                    <div className={styles.codeItemWrap}>
                        <GUComponents.Captcha />
                    </div>
                </div>
                <div className={styles.loginCodeWrap}>
                    <input type='text'
                        className={styles.loginCodeItem}
                        value={dynamicCode}
                        placeholder="请输入验证码"
                        maxLength='6'
                        onChange={(event) => changeFieldValue('dynamicCode', event)}
                    // onBlur={this.scrollTop.bind(this)}
                    />
                    {sendSmsRender()}
                </div>
                {submitRender()}
            </div>
        </div>
    </div>;

}
