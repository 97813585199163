/**
 * @description 活动/抽奖 实物订单提交界面
 * @interface route:/front/guapp/activityOrder
 * @param { idf: 唯一标识
 *  (lottery: 积分抽奖订单,
 *   goddessFes: 女神节,
 *   message: 消息发放实物,
 *   mothersDay: 母亲节,
 *   child2023: 儿童节
 *  )
 * }
 * @param {otherParam: 其他参数，根据需求做不同处理}
 *
 */

import React, {useEffect, useState, useRef, Fragment} from "react";
import {Toast} from "antd-mobile";
import {Header} from 'components';
import Library from "library";
import Service from "./service";
import AddressService from '../address/service'
import './index.scss';
import {CARBONPOINT} from "../../../library/util/constant";

const {JsBridge, Util} = Library;

const ActivityOrder = (props) => {

    const {idf, ...otherParam} = Util.url.paramsToObj();

    const [visible, setVisible] = useState(false)
    const [addressItem, setAddressItem] = useState()
    const [stateParam, setStateParam] = useState(otherParam)

    useEffect(() => {
        console.log(idf)
        loadData()
    }, [])

    const loadData = async () => {
        if (idf === 'message' || idf === 'goddessFes') {
            // 获取商品信息及回显地址数据
            const res = await Service.getMessageThingsInfo(otherParam)
            if (res) {
                Object.assign(stateParam, res.data || {})
                setStateParam({...stateParam})
                if (res.data?.jktActivityOrder) {
                    const orderInfo = res.data.jktActivityOrder
                    const addressItem = {
                        ...orderInfo,
                        provinceName: orderInfo.province,
                        cityName: orderInfo.city,
                        areaName: orderInfo.area,
                        streetName: orderInfo.street,
                        deliveryAddress: orderInfo.address,
                        consigneeName: orderInfo.receiverName,
                        consigneeNumber: orderInfo.receiverMobile
                    }
                    stateParam.orderId = orderInfo.orderId;
                    setStateParam({...stateParam})
                    setAddressItem(addressItem)
                } else {
                    queryDefalutAddress()
                }
            }

        } else {
            queryDefalutAddress()
        }

    }

    const queryDefalutAddress = async () => {
        const item = await AddressService.queryCommonAddress()
        if (item) {
            setAddressItem(item)
        }
    }

    const onSubmit = async () => {
        console.log('onSubmit')
        if (addressItem.needComplete) {
            return Toast.show('请先完善地址信息');
        }
        if (idf === 'lottery') {
            //积分抽奖
            const reslut = await Service.lotteryOrderSubmit({...addressItem, ...otherParam})
            if (reslut) {
                Toast.show({
                    content: '提交成功', afterClose: () => {
                        window.JsBridge.back()
                    }
                })
            }
        } else if (['goddessFes', 'mothersDay', 'child2023', 'commonSub'].includes(idf)) {
            // 女神节/母亲节/儿童节活动/父亲节/七夕节
            const reslut = await Service.festivalOrderSubmit({...addressItem, ...otherParam})
            if (reslut) {
                Toast.show({
                    content: '提交成功', afterClose: () => {
                        window.JsBridge.back()
                    }
                })
            }
        } else if (idf === 'message') {
            //站内信发放实物
            const reslut = await Service.saveMessageThingsInfo({...addressItem, ...stateParam})
            if (reslut) {
                Toast.show({
                    content: '提交成功', afterClose: () => {
                        window.JsBridge.back()
                    }
                })
            }
        } else if (idf === 'mail') {
            //站内信发放实物
            const reslut = await Service.submitAddress({...addressItem, ...stateParam})
            if (reslut) {
                Toast.show({
                    content: '提交成功', afterClose: () => {
                        window.JsBridge.back()
                    }
                })
            }
        }else if (idf === CARBONPOINT) {
            //站内信发放实物
            const reslut = await Service.exchangeProduct({...addressItem, ...stateParam})
            if (reslut) {
                Toast.show({
                    content: '提交成功', afterClose: () => {
                        window.JsBridge.back()
                    }
                })
            }
        }


        onClose()
    }

    const onChangeAddress = async () => {
        if (window.JsBridge.hasWebViewBridge()) {
            const address = await JsBridge.push('CustomWeb', {
                uri: window.location.origin + `/front/guapp/address/addressList?mode=1`,
                callback: true
            });
            if (address) {
                setAddressItem(address)
            }
        } else {
            props.history.push('/front/guapp/address/addressList?mode=1')
        }

        onClose()
    }

    const onConfirmOrder = () => {
        if (addressItem.needComplete) {
            //完善地址信息
            Toast.show('请先完善地址信息')
        } else {
            setVisible(true)
        }

    }

    const onClose = () => {
        setVisible(false)
    }

    return (
        <div className="activity_order_page">
            <Header midText={`${stateParam.orderId ? '订单信息' : '确认订单'}`}/>
            <div className="body">
                {/* 地址信息 */}
                <div className="address-item" onClick={stateParam.orderId ? () => {
                } : onChangeAddress}>
                    <img src={require('images/address_icon.png')} alt=""/>
                    {
                        addressItem ?
                            <div className="address-center">
                                <div className="user_info">
                                    <span>{addressItem.consigneeName || ''}</span>
                                    <span>{addressItem.consigneeNumber || ''}</span>
                                </div>
                                <div className="address-info">
                                    {addressItem.provinceName + addressItem.cityName + addressItem.areaName + (addressItem.streetName || '') + (addressItem.deliveryAddress || '')}
                                </div>
                            </div>
                            : <div className="none-address">{'您的收货地址为空，点击添加收货地址'}</div>

                    }

                    <div className="right-arrow">
                        {stateParam.orderId ? <></> : <img src={require('images/more_icon.png')} alt=""/>}
                    </div>
                </div>
                {/* 商品信息 */}
                <div className="shop-item" style={{display: otherParam.hide==1 ? 'none': 'block'}}>
                    <div className="item_top">
                        <div className="left">
                            <img src={stateParam.imgUrl} alt=""/>
                        </div>
                        <div className="right">
                            <span>{(stateParam.isChangeName && stateParam.isChangeName == '1') ? '罴九X上海申花精酿啤酒330ml*6瓶装' : decodeURI(stateParam.name || '')}</span>
                            {
                                stateParam?.carbonPoints ? <div className={'right-bottom'}>
                                    <img src={require('../../../images/icon_tjf.png')} alt=""/>
                                    <span>{stateParam?.carbonPoints / 1000}kg</span>
                                </div> : null
                            }
                        </div>
                    </div>
                    <div className={'item_bottom'}>
                        <div>配送服务</div>
                        <span>快递免邮</span>
                    </div>
                </div>
            </div>
            {
                stateParam.orderId ? null :
                    <div className={`bottom`}>
                        {
                            addressItem ?
                                <div className={`button`} onClick={onConfirmOrder}>{'提交订单'}</div>
                                : <div className={`button disable`}>{'提交订单'}</div>
                        }

                    </div>
            }
            <ConfirmModal visible={visible}
                          onClose={onClose}
                          addressItem={addressItem}
                          onEditAddress={onChangeAddress}
                          onSubmit={onSubmit}
            />
        </div>
    )
}

// 此处弹框和积分商城兑换弹框保持一致
const ConfirmModal = (props) => {
    const {visible, addressItem, onClose} = props
    return (
        <Fragment>
            {
                visible ?
                    <div className="confirm_modal_mask">
                        <div className="modal_body">
                            <div className="modal_header">
                                <div className="modal_header_title">核对地址</div>
                                <img src={require('images/icon_close.png')} alt="" onClick={onClose}/>
                            </div>
                            <div className="modal_content">
                                <div className="notice_info">提交后收件人信息不可更改，请您仔细核对收件人信息哦</div>
                                <div className="confirm_info">
                                    <div>
                                        <span>姓名：</span>
                                        <span>{addressItem.consigneeName || ''}</span>
                                    </div>
                                    <div>
                                        <span>电话：</span>
                                        <span>{addressItem.consigneeNumber || ''}</span>
                                    </div>
                                    <div>
                                        <span>地址：</span>
                                        <span>{addressItem.provinceName + addressItem.cityName + addressItem.areaName + (addressItem.streetName || '') + (addressItem.deliveryAddress || '')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_footer">
                                <div onClick={props.onEditAddress}>{'修改信息'}</div>
                                <div onClick={props.onSubmit}>{'确认'}</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </Fragment>
    )
}

export default ActivityOrder;
