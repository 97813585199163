import React, { useEffect, useState,useRef } from 'react';
import styles from './modal.module.scss';
export const Modal = (props)=>{
    return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
        <div className={styles.giftWrap}>
            <p className={styles.title}>{props.type === '4'?'谢谢参与!':'恭喜中奖'}</p>
            <p className={styles.des}>{props.title}</p>
            <img src={props.imgUrl} className={styles.img}/>
            <p className={styles.text2}>{props.content}</p>
            <div className={styles.btnBg}>
                <div className={`${styles.button} ${styles.icon1}`} onClick={()=>{
                    props.onOK && props.onOK({value:props.id,type:props.type});
                }}>{props.type === '0' ?'我知道了':'查看详情'}</div>
                <div className={`${styles.button} ${styles.icon2}`} onClick={()=>{
                    props.onCancel && props.onCancel();
                }}>继续抽奖</div>
            </div>
        </div>
    </div>

}
