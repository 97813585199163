/**
 * @description 儿童节点赞弹框
 * 
*/
import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Mask, Toast } from "antd-mobile";
import Service from "../../service";
import Styles from './index.module.scss';

const LikesModal = (props)=> {
    const { closeMask, userCode, activityCode, call } = props;
    const [visible, setVisible] = useState(true);

    const cancel = () => {
        setVisible(false);
        closeMask();
    }

    const onZanNow = async () => {
        const result = await Service.postThumbsUp({thumbedPhone:userCode,activityCode})
        if(result?.data) {
            cancel()
            Toast.show({
                content: '点赞成功',
                afterClose:()=>{
                    call && call()
                }
            })
        }
        
    }

    return (
        <Mask visible={visible} onMaskClick={cancel} opacity={0.7} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className={Styles.likes_content}>
                <div className={Styles.content_body}>
                    <img className={Styles.close} onClick={cancel} src={require('images/childrensDay/mask-close.png')} alt="" />
                    <img className={Styles.poster} src={require('images/childrensDay/likes-img.png')} alt="" />
                    <div onClick={onZanNow}>
                        <img src={require('images/childrensDay/zan-now.png')} alt="" />
                    </div>
                </div>
            </div>
        </Mask>
    )
}

LikesModal.open = ({ activityCode, userCode, call }) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<LikesModal activityCode={activityCode} userCode={userCode} call={call} closeMask={close} />, div);
}

export default LikesModal;