import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../reduxs';
import './index.scss';

let thisInterval;
const AlertComponent = (props) => {
    const [thisStatus, setThisStatus] = useState('HIDE');
    useEffect(() => {
        const { status } = props.alert;
        if (status === 'SHOW') {
            setThisStatus('SHOW');
            clearTimeout(thisInterval);
            thisInterval = setTimeout(() => {
                Actions.alert.hide();
                clearTimeout(thisInterval);
            }, props.alert.countdown || 3000);
        } else {
            setThisStatus('HIDE');
            props.alert.success && props.alert.success('HIDE');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.alert.status, props.alert.title]);

    return <div onClick={() => {
        Actions.alert.hide()
    }} className={thisStatus === 'SHOW'
        ? 'alert-container show'
        : 'alert-container '}>
        <p>
            {props.alert.title}
        </p>
    </div>
}

/**
 *
 * @param state
 * @returns {{loading}}
 */
export const Alert = connect((state) => {
    return { alert: state.alert.toJS() };
})(AlertComponent);
