import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./cartoonRuleDialog.module.scss"
import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";

const CartoonRuleDialog = ({maskVisible, setMaskVisible}) => {
    const [title, setRuleTitle] = useState();
    const [content, setRuleContent] = useState();
    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    useEffect(() => {
        maskVisible && getProtocol();
    }, [maskVisible]);
    const getProtocol = async () => { //获取协议
        const res = await Services('MainPage', {code: '101193'}, {
            codeType: 'activityRule',
            activityCode: 'CR2023',
            code: 'apeWorldInviteRule',
        })
        if (res.status > 0) {
            const {data} = res
            setRuleTitle(data.activityRule.codeAlias)
            let otherSign = data.activityRule.otherSign;
            if (!isEmptyString(otherSign)) {
                setRuleContent(otherSign)
            }
        }
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.content}>{content}</div>
                    <div className={styles.sure_btn} onClick={() => btnClick()}>我知道了</div>
                </div>
            </div>
        </Mask>
    )
}

export default CartoonRuleDialog;
