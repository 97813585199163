/*
    省市区街道 受控组件
*/
import React, {useEffect, useState, useRef} from "react";
import {Form, Input} from "antd-mobile";
import AddressSelect from '../addressSelect'
import './index.scss';

const name1 = 'provinceName'
const namekey1 = 'provinceId'
const name2 = 'cityName'
const namekey2 = 'cityId'
const name3 = 'areaName'
const namekey3 = 'areaId'
const name4 = 'streetName'
const namekey4 = 'streetId'

const errorMessage = '请完善地址信息'

const AddressForm = (props)=> {

    const {data={},form} = props

    const [areaInfo, setAreaInfo] = useState()
    const [selectList, setSelectList] = useState([])
    const [visible,setVisible]=useState(false)

    useEffect(()=>{
       const initialValue = ((data.provinceName||'') + (data.cityName||'') + (data.areaName||'') + (data.streetName||'')) || ''
       if(form) {
            setAreaInfo(initialValue)
            form.current.setFieldValue('areaInfo',initialValue)
       }
    },[])

    const onSelectChange = (list=[])=> {
        setSelectList(list)
        window.JsBridge.print(list);
        //兼容其他区没有街道的场景
        if(list.length >= 3) {
            let extent = {}
            let streetName = ''
            if(list.length >=4) {
                extent = {
                    [name4]: list[3].label,
                    [namekey4]: list[3].value,
                }
                streetName = list[3].label
            }else {
                extent = {
                    [name4]: undefined,
                    [namekey4]: undefined,
                }
            }

            form.current.setFieldsValue({
                [name1]: list[0].label,
                [namekey1]: list[0].value,
                [name2]: list[1].label,
                [namekey2]: list[1].value,
                [name3]: list[2].label,
                [namekey3]: list[2].value,
                ...extent,
                areaInfo: list[0].label + list[1].label + list[2].label + streetName
            })
            //触发显示渲染
            setAreaInfo(form.current.getFieldValue('areaInfo'))
        }
    }

    return (
        <div className="address_form">
            <Form.Item
                name='areaInfo'
                label='所在地区'
                arrow={true}
                rules={[{ required: true }]}
                onClick={()=>{
                    console.log('选择地区')
                    setVisible(true)
                    /*
                    // 不再调用rn区域选择组件
                    if(window.JsBridge.hasWebViewBridge()){
                        window.JsBridge.runAction('locationPicker', 'show', []).then(e => {
                            onSelectChange(e)
                        })
                    }
                    */
                    
                }}
            >
                {/* <Input placeholder='请选择' readOnly/> */}
                {
                    areaInfo ?
                    <span>{areaInfo}</span>
                    : <span className="placeholder">{'请选择'}</span>
                }
                
            </Form.Item>
            <Form.Item
                hidden
                name={name1}
                label='省'
                initialValue={data.provinceName}
            >
                <Input readOnly/>
            </Form.Item>
            <Form.Item
                noStyle
                name={namekey1}
                label='省id'
                rules={[{ required: true, message: errorMessage}]}
                initialValue={data.provinceId}
            >
                <Input readOnly style={{display: 'none'}}/>
            </Form.Item>
            <Form.Item
                hidden
                name={name2}
                label='市'
                initialValue={data.cityName}
            >
                <Input readOnly />
            </Form.Item>
            <Form.Item
                noStyle
                name={namekey2}
                label='市id'
                rules={[{ required: true, message: errorMessage}]}
                initialValue={data.cityId}
            >
                <Input readOnly style={{display: 'none'}}/>
            </Form.Item>
            <Form.Item
                hidden
                name={name3}
                label='区'
                rules={[{ required: true, message: errorMessage}]}
                initialValue={data.areaName}
            >
                <Input readOnly/>
            </Form.Item>
            <Form.Item
                noStyle
                name={namekey3}
                label='区id'
                initialValue={data.areaId}
            >
                <Input readOnly style={{display: 'none'}}/>
            </Form.Item>
            <Form.Item
                hidden
                name={name4}
                label='街道'
                initialValue={data.streetName}
            >
                <Input readOnly />
            </Form.Item>
            <Form.Item
                noStyle
                name={namekey4}
                label='街道id'
                rules={[{ required: selectList?.length == 3 ? false: true, message: errorMessage}]}
                initialValue={data.streetId}
            >
                <Input readOnly style={{display: 'none'}}/>
            </Form.Item>
            {/* 省市区选择器 */}
            <AddressSelect visible={visible} 
                title={'请选择'}
                onClose={()=>{
                    setVisible(false)
                }}
                onFinish={onSelectChange}
            />
        </div>
    )
}

export default AddressForm;