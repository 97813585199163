/**
 * @description 我的充值卡
 * @returns /front/member/pointRecharge/myPointCards
*/
import React, { useEffect, useMemo, useState } from "react";
import {Input, Toast} from 'antd-mobile';
import { ExclamationCircleOutline } from 'antd-mobile-icons';
import Library from 'library';
import { Header, ListView, ModalAlert } from 'components';
import Service from '../service';
import Styles from './index.module.scss';

const { JsBridge } = Library;

export default (props) => {

    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = React.useRef(1);

    useEffect(() => {
        onRefresh(true);
    }, [])

    // 充值卡记录
    const onMyPointRecord = () => {
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/member/pointRecharge/myPointCardRecord` });
        } else {
            props.history.push('/front/member/pointRecharge/myPointCardRecord')
        }
    }

    //加载列表数据
    const onRefresh = async (loading = false) => {
        currentPage.current = 1;
        if (loading) {
            setLoading(true)
        }
        const res = await Service.getMyPointCards({ currentPage: currentPage.current }, loading);
        if (loading) {
            setLoading(false)
        }
        if (res?.data) {
            const { records = [] } = res.data;
            setDataList(records);
            setHasMore(records.length >= Service.PageSize);
        }
    }

    const loadMore = async () => {
        const res = await Service.getMyPointCards({ currentPage: currentPage.current + 1 }, loading);
        if (res?.data) {
            const { records = [] } = res.data;
            setDataList([...dataList, ...records]);
            setHasMore(records.length >= Service.PageSize)
            currentPage.current = currentPage.current + 1;
        }
    }

    return (
        <div className={Styles.myPointCards_page}>
            <Header midText={'我的充值卡'} rightText={'充值卡记录'} rightClick={onMyPointRecord} />
            <div className={Styles.page_body}>
                <ListView
                    dataSource={dataList}
                    renderItem={(item) => <MyCardsItem item={item} reload={onRefresh} />}
                    emtyDesc={loading ? '数据加载中...' : '暂无数据'}
                    hasMore={hasMore}
                    onRefresh={onRefresh}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}

/**
 * @class Item
*/
const MyCardsItem = (props) => {
    const { item = {}, reload } = props

    const inputRef = React.useRef();

    //退卡
    const onRefundCard = async () => {
        const res = await Service.pointCardRefund({ pointCardNo: item.pointCardNo }, true);
        if (res) {
            reload && reload()
        }
    }
    // 充值
    const onJumpCashier = async () => {
        const res = await Service.pointCardRecharge({ pointCardNo: item.pointCardNo }, true);
        if (res) {
            reload && reload()
        }

    }

    // 转赠
    const onPointCardTransfer = async (mobile, callback) => {
        let errMsg = '';
        if (mobile) {
            if (Library.Util.validate.mobile(mobile)) {
                const res = await Service.pointCardTran({
                    rUserCode: mobile,
                    pointCardNo: item.pointCardNo
                });
                if (res?.status > 0) {
                    callback && callback();
                    reload && reload()
                } else {
                    //手机号后段验证是否注册
                    // errMsg = '该账号不存在'
                }

            } else {
                errMsg = '请输入正确的手机号'
            }
        } else {
            errMsg = '请输入手机号';
        }
        if (errMsg) {
            callback && callback(errMsg);
        }
    }

    const onItemClick = (type) => {
        const bottomBtns = (actions = [], onAction) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 12px', marginTop: 25, width: '100%' }}>
                    {
                        actions.map((action, k) => {
                            const syle = { width: 100, height: 40, borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, color: '#926A03' };
                            const leftStyle = { ...syle, border: '1px solid #DFCEAB' };
                            const rightStyle = { ...syle, background: 'linear-gradient(270deg, #FEEAB4 0%, #D4BA76 100%)' };
                            return (
                                <div key={k}
                                    style={k == 1 ? rightStyle : leftStyle}
                                    onClick={() => {
                                        onAction && onAction(action);
                                    }}
                                >
                                    {action.text}
                                </div>
                            )
                        })
                    }
                </div>
            )
        };
        if (type == 1) {
            ModalAlert.show({
                title: <span style={{ color: '#3D3D3D' }}>是否确定退款？</span>,
                content: bottomBtns([{ key: 'ok', text: '确定' }, { key: 'cancel', text: '取消' }], (action) => {
                    if (action.key == 'ok') {
                        console.log('退卡')
                        onRefundCard();
                    }
                    ModalAlert.hide();
                })
            })
        } else if (type == 2) {

            ModalAlert.show({
                title: '',
                content: <div>
                    <p style={{ fontSize: 14, color: '#3D3D3D', fontWeight: 500, marginBottom: 8 }}> 转赠账号： </p>
                    <div style={{ border: `1px solid #FBE7B0`, borderRadius: 5, height: 32, display: 'flex', justifyContent: 'center', padding: '0 8px' }}>
                        <Input placeholder='请填写好友注册时的手机号' maxLength={11} style={{ '--font-size': '12px' }}
                            onChange={value => {
                                inputRef.current = value;
                            }}
                        />
                    </div>
                    <span className="validator" style={{ color: '#FF2A2A', fontSize: 10, paddingTop: 3 }}></span>
                    {
                        bottomBtns([{ key: 'cancel', text: '取消' }, { key: 'ok', text: '转赠' }], async (action) => {
                            if (action.key == 'ok') {
                                console.log('转赠');
                                onPointCardTransfer(inputRef.current,(errMsg)=>{
                                    if(errMsg) {
                                        document.querySelector('.validator').innerHTML = errMsg;
                                    }else {
                                        Toast.show('转赠成功')
                                        setTimeout(() => {
                                            ModalAlert.hide();
                                        }, 1000)
                                    }

                                });
                            } else {
                                ModalAlert.hide();
                            }

                        })
                    }
                </div>

            })
        } else if (type == 3) {

            ModalAlert.show({
                content: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span style={{ color: '#FA6300', fontSize: 24, fontWeight: 'bold', marginBottom: 5 }}>
                        <span style={{ fontSize: 32 }}>{(item.price||0) * 100}</span>积分
                    </span>
                    <div style={{ marginBottom: 15, fontSize: 12 }}>充值积分</div>
                    <div style={{ color: '#979797', fontSize: 12 }}>
                        <ExclamationCircleOutline color='#FEE496' />
                        <span style={{ paddingLeft: 3 }}>充值后该卡将不能转赠</span>
                    </div>
                    {
                        bottomBtns([{ key: 'cancel', text: '取消' }, { key: 'ok', text: '确定充值' }], (action) => {
                            if (action.key == 'ok') {
                                console.log('充值');
                                onJumpCashier();
                            }
                            ModalAlert.hide();
                        })
                    }
                </div>
            })
        }

    }

    const btnsConfig = (itm = {}) => {
        let btns = [];
        const config1 = { title: '退卡', type: 1, theme: 1, enable: true };
        const config2 = { title: '转赠', type: 2, theme: 2, enable: true };
        const config3 = { title: '充值', type: 3, theme: 2, enable: true };
        const config4 = { title: '已充值', enable: false };
        const config5 = { title: '退款中', enable: false };

        // tradable: 0未发生转赠
        if(itm.tradable == 0 && itm.pointCardStatus == 0) {
            btns.push(config1)
        }
        //pointCardStatus: 0持有,1已充值,3退款中,4已退款,5/6退款审核中
        if (itm.pointCardStatus == 0) {
            btns.push(config2)
        }
        if (itm.pointCardStatus == 0) {
            btns.push(config3)
        }

        return btns;
    }

    const statusText = useMemo(()=> {
        const status = item.pointCardStatus;
        let txt = '';
        if(status == 1) {
            txt = '已充值';
        }else if(status == 3) {
            txt = '退款中';
        }else if(status == 4) {
            txt = '已退款';
        }else if([5,6].includes(status)) {
            txt = '退款中';
        }
        return txt;
    })

    return (
        <div className={Styles.my_cards_item}>
            <div className={Styles.item_header}>
                <div className={Styles.top_idf}>
                    <span>充值卡号:{item.pointCardNo}</span>
                </div>
                <span>{statusText}</span>
            </div>

            <div className={Styles.content}>
                <div>
                    <img src={item.imageUrl} alt="" />
                    <span>{item.expiredTime && `有效期至：${item.expiredTime.split(' ')[0]}`}</span>
                    <p>{(item.price || 0) * 100}积分</p>
                </div>
            </div>
            <div className={Styles.btns_box}>
                {
                    btnsConfig(item).map((config, k) => {
                        return (
                            <div className={`${Styles.btn} ${config.enable ? Styles.enable : ''} ${config.theme ? Styles['theme' + config.theme] : ''}`}
                                key={k}
                                onClick={() => {
                                    config.enable && onItemClick(config.type)
                                }}
                            >
                                <span>{config.title}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
