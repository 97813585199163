/**
 * @description 全额积分商品item
*/

import React, { useMemo } from 'react';
import Styles from './index.module.scss';

const PointShopItem = (props) => {
    const { item, onReceive, onShopInvite, onClick } = props;
    const progress = useMemo(() => {
        const progress = Math.floor(item.inviteesNum / item.InvitationsNumTotal * 100);
        if (progress >= 100) {
            return 100;
        }
        return progress;
    },[item.inviteesNum])
    return (
        <div className={Styles.pointShopItem} onClick={()=>{
            const _item = {...item};
            onClick && onClick(_item);
        }}>
            <div className={Styles.tagBox}>
                <img src={require('images/invite-zone/point_tag_img.png')} alt="" />
                <span >{`邀新${item.InvitationsNumTotal ?? 100}人可全额积分兑换`}</span>
            </div>
            <div className={Styles.imgWrap}>
                <img src={item.imgUrl} alt="" />
            </div>
            <div className={Styles.info}>
                <span className={Styles.name}>{item.productName}</span>
                <div className={Styles.progressBox}>
                    <div className={Styles.progressOut}>
                        <div className={Styles.progressIn} style={{'--progress': `${progress}%`, display: progress ? 'block': 'none'}}></div>
                    </div>
                    <span>{`已邀请：${+item.inviteesNum}/${+item.InvitationsNumTotal}`}</span>
                </div>
                <span className={Styles.timeEnd}>截止时间：{item.collectTimeEnd}</span>
                {
                    item.collectionProgress == 2 ?
                    <div className={Styles.disable} onClick={(e)=>{e.stopPropagation()}}>
                        <span>{'已兑换'}</span>
                    </div>:
                    item.collectionProgress == 1 ?
                    <div onClick={(e)=>{
                        e.stopPropagation();
                        const _item = {...item};
                        if(item.exchSkuId) {
                            _item.skuId = item.exchSkuId;
                        }
                        onReceive && onReceive(_item)
                    }}>
                        <span>{'立即兑换'}</span>
                    </div>
                    :<div onClick={(e)=>{
                        e.stopPropagation();
                        onShopInvite && onShopInvite(item)
                    }}>
                        <span>{'立即邀请'}</span>
                    </div>
                }
            </div>

        </div>
    )
}

export default PointShopItem;