import React, {useEffect, useRef, useState} from 'react';
import Library from "../../../library";
import styles from './activitySelection.module.scss'
import {Swiper} from "antd-mobile";
import {PublicActyList} from "./publicActyList";
import {YuYueActyList} from "./yuYueActyList";
import NoticeUtil from "../../../library/util/NoticeUtil";


const {JsBridge} = Library;
const kRefreshActyNotification = 'kRefreshActyNotification';
export const ActivitySelection = () => { //活动精选
    const {userAccount = ''} = Library.Util.url.paramsToObj();
    const [selIndex, setSelIndex] = useState(0);
    const swiperRef = useRef(null);
    useEffect(() => {
        JsBridge.setHeaderRightContent({
            title: '预约记录',
            style: {height: 16, marginRight: 10, color: '#9E9E9E', fontsize: 12},
            action: 'yuyueclick'
        }, (data) => {
            JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/appointmentRecord?userAccount=${userAccount}`});

            JsBridge.addListener().then((result) => {
                NoticeUtil.emit(kRefreshActyNotification);
            });

        });
        return () => {
            JsBridge.clearRightContent();
        }
    }, [])

    const titleClick = (index) => {
        if (index === selIndex) {
            return;
        }
        setSelIndex(index);
        swiperRef.current.swipeTo(index);
    }

    const titles = () => {
        let arr = ['预约活动', '公开活动'];
        return arr.map((item, index) => {
            return <div className={styles.acty_header_item}
                        onClick={() => titleClick(index)} key={index.toString()}>
                <p>{item}</p>
                <div className={styles.acty_header_item_line}
                     style={{visibility: selIndex === index ? 'visible' : 'hidden'}}/>
            </div>
        })
    }


    return (
        <div className={styles.aptmt_acty_selection_container}>
            <div className={styles.acty_header}>
                {titles()}
            </div>
            <Swiper
                direction='horizontal'
                indicator={() => null}
                ref={swiperRef}
                allowTouchMove={false}
                defaultIndex={0}>
                <Swiper.Item>
                    <YuYueActyList focusStatus={selIndex === 0} userAccount={userAccount}/>
                </Swiper.Item>
                <Swiper.Item>
                    <PublicActyList focusStatus={selIndex === 1} userAccount={userAccount}/>
                </Swiper.Item>
            </Swiper>
        </div>
    )
}


