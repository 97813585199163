import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { DatePicker, } from 'antd-mobile';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions } from 'reduxs';
import Library from 'library';
import Services from 'services';
import { jobMap } from '../conf';

import styles from './index.module.scss';
import closeImg from '../images/close@2x.png';
import arrImg from '../images/arr@2x.png'

let SwiperNc = null;
const { Util, Fetch, Hooks } = Library;
const minDate = new Date('1960/01/01');
const appRoot = document.getElementById('root');

export default (props) => {
    const [collegeMap, setCollegeMap] = useState([]);
    const [formValue, setFormValue] = useState({});
    const [collegesSugget, setCollegesSugget] = useState([]);
    const [showJob, setShowJob] = useState(false);
    const [visible, setVisible] = useState(false);


    const history = useHistory();

    useEffect(() => {
        initNC();
    }, [])



    const inputCollege = async (event) => {
        const value = event?.target?.value;
        changeFormValue('education', value);
        if (!value) {
            setCollegesSugget([]);
            return;
        }
        let _collegeMap = collegeMap;
        if (_collegeMap.length <= 0) {
            const fetch = new Fetch({ url: '/ChinaUniversityList.json' });
            const result = await fetch.get({}, {});
            if (result.status > 0) {
                _collegeMap = result.data;
                setCollegeMap(result.data);
            }
        }
        setCollegesSugget(_collegeMap.filter(e => e.includes(value)).slice(0, 4))
    }

    const changeFormValue = (fieldName, value) => {
            if(fieldName === 'graduationTime'){
                setVisible(false)
        }
        setFormValue({ ...formValue, [fieldName]: value })
    }

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
        })
    };



    const submit = async () => {
        if (!formValue?.name) {
            Actions.alert.show('填写姓名方可提交');
            return;
        }
        if (!formValue?.mobile) {
            Actions.alert.show('填写手机号方可提交');
            return;
        }
        if (!formValue?.dynamicCode) {
            Actions.alert.show('填写验证码方可提交');
            return;
        }
        if (!formValue?.positionIntention || formValue?.positionIntention.length <= 0) {
            Actions.alert.show('填写职位方可提交');
            return;
        }
        if (!formValue?.education) {
            Actions.alert.show('填写学校方可提交');
            return;
        }
        if (!formValue?.major) {
            Actions.alert.show('填写专业方可提交');
            return;
        }
        if (!formValue?.graduationTime) {
            Actions.alert.show('填写毕业时间方可提交');
            return;
        }
        const result = await Services('Employment', 'SubmitApply', {
            ...formValue,
            positionIntention: formValue?.positionIntention?.map(e => e.name)?.join('、'),
            graduationTime: Util.format.time(formValue?.graduationTime, 'yyyy年MM月')
        });
        if (result.status > 0) {
            Actions.alert.show('提交成功');
            history.goBack();
        }
    };

    const datepicker = useMemo(() => {
        return <DatePicker
            visible={visible}
            className={styles.datepicker}
            mode="month"
            title="毕业时间"
            min={minDate}
            value={formValue?.graduationTime}
            onConfirm={date => changeFormValue('graduationTime', date) }
            onCancel={()=>setVisible(false)}
        >
            {/*<DatePickerRender />*/}
        </DatePicker>
    }, [formValue, changeFormValue]);

    return <div className={styles.modal}>

        <div className={styles.modalWrap}>
            <div className={styles.close} onClick={() => {
                history.goBack();
            }}>
                <img src={closeImg} alt='close' />
            </div>
            <h2>加入我们</h2>
            <div className={styles.warnning}>
                应聘者请完成以下信息填写并同步将个人简历邮件发送至<br />hr@lianqicloud.com，标题备注 姓名+学校+求职方向
            </div>
            <div className={styles.modalContent}>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        姓名
                    </label>
                    <input onChange={(event) => {
                        changeFormValue('name', event.target.value);
                    }} value={formValue?.name || ''} type='text' placeholder='请输入姓名' />
                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        手机号
                    </label>
                    <input onChange={(event) => {
                        changeFormValue('mobile', event.target.value);
                    }} value={formValue?.mobile || ''} type='tel' placeholder='请输入手机号' />
                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        验证码
                    </label>
                    <input maxLength={6} onChange={(event) => {
                        changeFormValue('dynamicCode', event.target.value);
                    }} value={formValue?.dynamicCode || ''} type='tel' placeholder='请输入验证码' />
                    <SendSmsRender mobile={formValue?.mobile} />
                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        职位
                    </label>
                    <input readOnly onClick={() => {
                        setShowJob(true);
                    }} value={formValue?.positionIntention?.map(e => e.name)?.join('、') || ''} type='text' placeholder='请输入职位' />
                    <img
                        onClick={() => {
                            setShowJob(true);
                        }}
                        src={arrImg}
                        alt='下拉箭头'
                        className={`${styles.arr} ${showJob ? styles.active : ''}`} />
                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        学校
                    </label>
                    <input onBlur={() => {
                        setTimeout(() => setCollegesSugget([]), 50);
                    }} onChange={inputCollege} value={formValue?.education || ''} type='text' placeholder='请输入学校' />
                    {collegesSugget.length > 0 && <div className={styles.collegesSugget}>
                        {collegesSugget.map((item, key) => {
                            return <p onClick={() => {
                                changeFormValue('education', item);
                                setCollegesSugget([]);
                            }} key={key}>{item}</p>
                        })}
                    </div>}

                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        专业
                    </label>
                    <input onChange={(event) => {
                        changeFormValue('major', event.target.value);
                    }} value={formValue?.major || ''} type='text' placeholder='请输入专业' />
                </div>
                <div className={styles.line}>
                    <label>
                        <span className={styles.red}>*</span>
                        毕业时间
                    </label>
                    <span style={{color:(formValue?.graduationTime?'black':'#A3A3A3'),fontSize:'13px',flex:'1',height:'100%',paddingTop:'18px'}} onClick={()=>setVisible(!visible)}>{formValue?.graduationTime ?(Util.format.time(formValue?.graduationTime, 'yyyy年MM月')) : '请选择毕业时间'}</span>
                    {datepicker}
                </div>
                <button onClick={submit} className={styles.submit}>提交信息</button>
            </div>
        </div>
        <MajorModal visible={showJob} onClose={() => {
            setShowJob(false);
        }} onChange={(jobs) => {
            changeFormValue('positionIntention', jobs);
            setShowJob(false);
        }} />
    </div>;
}

const SendSmsRender = (props) => {
    const [lastSecond, setLastSecond] = useState(0);
    const countDown = useSelector((state) => {
        return state.sms.toJS().joinUsCounter;
    });

    useEffect(() => {
        const timer = setInterval(() => {
            if (countDown?.expire) {
                let count = parseInt((countDown?.expire - new Date().getTime()) / 1000);
                setLastSecond(count);
            }
        }, 500);
        return () => {
            clearInterval(timer);
        };

    }, [countDown]);

    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button className={`${styles.send} ${styles.disable}`}>{lastSecond}秒后重发</button>
        } else {
            if (Util.validate.mobile(props?.mobile)) {
                return <button className={styles.send} onClick={async () => {
                    if (SwiperNc) {
                        const capchaResult = await SwiperNc();
                        const result = await Services('Employment', 'GetDynamicCode', { ...capchaResult, mobile: props?.mobile });
                        if (result.status > 0) {
                            Actions.sms.countDownJoinUs(new Date().getTime() + 60 * 1000);
                        }
                    }
                }} >获取验证码</button>
            } else {
                return <button className={`${styles.send} ${styles.disable}`}>获取验证码</button>
            }

        }
    };
    return sendSmsRender();
}

const MajorModal = (props) => {
    const { visible } = props;
    const [jobIndexs, setJobIndexs] = useState([]);
    const [showState, setShowState] = useState(false);
    const majorModalRef = useRef();

    useEffect(() => {
        majorModalRef.current = document.createElement('div');
        appRoot.appendChild(majorModalRef.current);
        return () => {
            appRoot.removeChild(majorModalRef.current);
        }
    }, []);

    useEffect(() => {
        if (visible) {
            setShowState(true);
        } else {
            setTimeout(() => {
                setShowState(false);
            }, 500);
        }
    }, [visible]);

    return majorModalRef.current ? createPortal(
        <div className={`${styles.modal} ${showState || visible ? styles.show : styles.hide}`} onClick={() => {
            props.onClose();
        }}>
            <div className={`${styles.majorModal} ${visible ? styles.show : styles.hide}`} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div>
                    <div className={styles.close} onClick={() => {
                        props.onClose();
                    }}>
                        <img src={closeImg} alt='close' />
                    </div>
                    <h2>请选择职位（多选）</h2>
                    <div className={styles.nav}>
                        {jobMap.map((item, key) => {
                            return <div
                                key={key}
                                onClick={() => { setJobIndexs(jobIndexs.includes(key) ? jobIndexs.filter(e => e !== key) : [key].concat(jobIndexs)) }}
                                className={`${styles.title} ${jobIndexs.includes(key) ? styles.active : ''}`}>
                                {item.name}
                            </div>
                        })}
                    </div>
                </div>
                <div className={styles.footer}>
                    <button onClick={() => {
                        setJobIndexs([]);
                    }}>重置</button>
                    <button onClick={() => {
                        props.onChange(jobMap.filter((item, key) => {
                            return jobIndexs.includes(key);
                        }));
                    }}>确定({jobIndexs.length})</button>
                </div>
            </div>
        </div>,
        majorModalRef.current
    ) : false;
}

const DatePickerRender = (props) => {
    const { extra, onClick } = props;
    if (extra !== '请选择') {
        return <>
            <input type='text' readOnly value={extra || ''} onClick={onClick} />
            <img onClick={onClick} src={arrImg} alt='下拉箭头' className={styles.arr} />
        </>
    } else {
        return <>
            <input type='text' readOnly placeholder='请选择毕业时间' value={''} onClick={onClick} />
            <img onClick={onClick} src={arrImg} alt='下拉箭头' className={styles.arr} />
        </>
    }
}
