/**
 * @description gu签到模块
*/
import React, { useState, useEffect } from 'react';
import { SignTimeLine } from '../../../dailySignin/components/signTimeLine';
import { SignReports } from '../../../dailySignin/components/signReports';
import InviteBox from '../../../dailySignin/components/InviteBox';
import FirstDayModal from 'routes/activities/dailySignin/firstDayModal';
import './index.scss';

let isFist = true;
const GuSign = (props)=> {

    const { dataSource = {} } = props;

    const [visible, setVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        if (dataSource.popAd && dataSource.popAd.length > 0 && !isFist) {
            const url = dataSource.popAd[0].tinyPicUrl;
            setImageUrl(url);
            setVisible(true);
            isFist = true;
        }
    },[dataSource])

    return (
        <div className='gu_sign'>
            <div className='time_line_card'>
                <SignTimeLine state={dataSource} 
                    signInDay={dataSource.signInDay}
                    onRefesh={props.onRefesh}
                    onUpdateState={props.onUpdateState}
                />
            </div>
            {dataSource.signInArray && <SignReports state={dataSource} signInDay={dataSource.signInDay} signInArray={dataSource.signInArray}/>}
            { Object.keys(dataSource).length > 0 && <InviteBox state={dataSource} onCallback={props.onRefesh}/>}
            <FirstDayModal visible={visible} imageUrl={imageUrl} onClose={()=>setVisible(false)}/>
        </div>
    )
}

export default GuSign;