/**
 * @description 商品推荐模块
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Tabs } from 'antd-mobile';
import Library from 'library';
import Service from '../../service';
import Styles from './index.module.scss';
const { JsBridge } = Library;

const ShopRecommend = () => {

    const [activeKey,setActiveKey] = useState(0);
    const [listArray, setListArray] = useState([]);

    useEffect(() => {
      Service.getRecommendShopList().then(res => {
        console.log('getRecommendShopList', res)
        if(res) {
          setListArray(res?.data);
          setActiveKey(0);
        }
        
      })
    }, []);

    return (
        <div className={Styles.shop_recomend} >
            <Tabs
                className={Styles.tabs}
                activeKey={activeKey+''}
                onChange={(key) => setActiveKey(key)}
            >
            {
                listArray.map((tab,k)=> {
                    return (
                        <Tabs.Tab
                            key={k}
                            title={
                                <div className={`${Styles.tab} ${activeKey == k ? Styles.tabSelect: Styles.tabUnSelect}`}>
                                    <span>{tab.cateName}</span>
                                </div>
                            }
                        >
                            {
                                tab.goodsSimplifyList &&
                                tab.goodsSimplifyList.map((item,idx)=> {
                                    return (<ShopItem key={idx} item={item}/>)
                                })
                            }
                        </Tabs.Tab>
                        
                    )
                })
            }
            </Tabs>
        </div>
    )
}

const ShopItem = (props) => {
    const {item={}} = props

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        const skuId = data.goodsInfoId
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
    }

    const originalPrice = Number(item.marketPrice);
    const pointPrice = Number(item.maxDeductPoint||0) / 100;
    //最终价
    const actualPrice = Math.max(Number(((originalPrice * (item.discount || 1)) - pointPrice).toFixed(2)), 0); 

    return (
        <div className={Styles.goods_item} onClick={() => onShopItemClick(item)}>
            <div className={Styles.item_box}>
              <div className={Styles.left}>
                  <div className={Styles.conent}>
                      <div className={Styles.tag}> {'购买返3倍积分'}</div>
                      <img src={item.goodsImg} alt="" />
                  </div>
              </div>
              <div className={Styles.right}>
                  <p>{item.goodsInfoName}</p>
                  <div className={Styles.priceLine}>
                      <div>
                          <div>¥<span>{originalPrice}</span></div>
                          <div>原价</div>
                      </div>
                      <div>
                          <div>¥<span>{pointPrice}</span></div>
                          <div>积分可抵</div>
                      </div>
                  </div>
                  <div className={Styles.finalPrice}>
                      <div>
                          <span>到手价 ¥</span>
                          <span>{actualPrice}</span>
                      </div>
                      <div>去抢购</div>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default ShopRecommend;