import React, { useState, useRef } from 'react'
import styles from './style.module.scss'
import title from './img/title.png'
import { Form, Input, DatePicker, Toast } from 'antd-mobile'
import moment from 'moment'
import back from '../../../images/icon/back@2x.png'
import share from './img/share-fill.png'
import Library from "library"
import { useHistory } from 'react-router-dom'
import Services from 'services'
import ActivityShareModal from 'components/activityShareModal'
import bgImage from "./img/share-bg.png"
import { showLoading, hideLoading } from '../../../components/loading1'
const { Util, Platform } = Library

const BookBoardroom = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [date, setDate] = useState('')
  const [visible, setVisible] = useState(false)
  const [codeStatus, setCodeStatus] = useState('init')
  const [count, setCount] = useState(60)
  const [subStatus, setSubStatus] = useState(false)
  const [formData, setFormData] = useState({})
  const timeId = useRef(null)
  const isApp = Platform.check.isApp()
  const serviceModal = isApp ? 'MainPage' : 'Common'

  const countDown = () => {
    setCodeStatus('finish')
    timeId.current = setInterval(() => {
      setCount((pre) => {
        if (pre > 0) {
          return pre - 1
        } else {
          timeId.current && clearInterval(timeId.current)
          timeId.current = null
          setCodeStatus('restart')
          return 60
        }
      })
    }, 1000)
  }

  const getVerifyCode = Library.Util.debounce(async () => {
    const phone = await form.getFieldValue('phone')
    if (!phone) {
      return Toast.show('请输入手机号')
    }
    if (!Util.validate.mobile(phone)) {
      return Toast.show('请输入正确的手机号')
    }
    try {
      !isApp && showLoading()
      const res = await Services(serviceModal, { code: '600831' }, { cellphoneNum: phone })
      !isApp && hideLoading()
      if (res.status > 0) {
        countDown()
      }
    } catch (error) {
      !isApp && hideLoading()
    }
  }, 500)

  const validateFields = async ()=> {
    let reslut = {};
    try {
      reslut = await form.validateFields()
    } catch (error) {
      reslut = error
    }
    return reslut;
  }

  const onSubmit = Library.Util.debounce(async () => {
    const values = await validateFields()
    console.log(values)
    if(values.errorFields) {
      return Toast.show(values.errorFields[0].errors[0])
    }
    try {
      !isApp && showLoading()
      const res = await Services(serviceModal, { code: '600830' }, values)
      !isApp && hideLoading()
      if(res.status > 0) {
        setFormData(values || {})
        setSubStatus(true)
      }
    } catch (error) {
      !isApp && hideLoading()
    }
  }, 500)

  const onShare = () => {
    ActivityShareModal.open({
      shareUrl: `${window.location.origin}/front/activities/boardroom`,
      children: <img className={styles.shareInvite} src={bgImage} alt="bg" />,
      qrcodeConfig: {size: 60}
    })
  }

  return <div className={styles.book_boardroom}>
    {isApp && <img 
      className={styles.back} 
      src={back} alt="" 
      onClick={() => {
        if (window.JsBridge.hasWebViewBridge()) {
          window.JsBridge.back()
        } else {
          history.goBack();
        }
      }}
    />}
    {isApp && <img className={styles.share} src={share} alt="" onClick={onShare}/>}
    <img className={styles.title} src={title} alt="" />
    {!subStatus ? <div className={styles.before_sub}>
      <div className={styles.form_title}>免费预约</div>
      <Form className={styles.form_style} form={form} autoComplete="off">
        <div className={styles.form_item}>
          <div className={styles.label}>姓名：</div>
          <div className={styles.input}>
            <Form.Item 
              name="userName" 
              noStyle
              validateTrigger="blur" 
              rules={[
                { required: true, message: '请填写姓名' },
              ]}
            >
              <Input placeholder='请填写姓名' className={styles.input_color}/>
            </Form.Item>
          </div>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>联系电话：</div>
          <div className={styles.input}>
            <Form.Item 
              name="phone" 
              noStyle
              validateTrigger="blur"
              rules={[
                { required: true, message: '请填写联系电话' },
                { pattern: /^1[3-9]\d{9}$/, message: '请填写正确的手机号' },
              ]}
            >
              <Input type='tel'  maxLength={11} placeholder='请填写联系电话' className={styles.input_color}/>
            </Form.Item>
          </div>
          <div className={styles.code_box}>
            <div className={styles.code_input}>
              <Form.Item 
                name="dynamicCode" 
                noStyle
                validateTrigger="blur"
                rules={[
                  { required: true, message: '请填写验证码' },
                ]}
              >
                <Input type='tel' maxLength={6} placeholder='请填写验证码' className={styles.input_color}/>
              </Form.Item>
            </div>
            {codeStatus === 'init'
              ? <div className={styles.init} onClick={getVerifyCode}>获取验证码</div>
              : <div>
              {codeStatus === 'restart'
                ? <span className={styles.init} onClick={getVerifyCode}>重新获取</span>
                : <span className={styles.disable}>{`${count}后可重新获取`}</span>}
            </div>}
          </div>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>参加人数：</div>
            <div className={styles.input}>
              <Form.Item 
                name="number" 
                noStyle 
                validateTrigger="blur"
                rules={[
                  { required: true, message: '请填写参加人数' },
                ]}
              >
                <Input type='number' placeholder='请填写参加人数' className={styles.input_color}/>
              </Form.Item>
            </div>
        </div>
        <div className={styles.form_item} onClick={() => setVisible(true)}>
          <div className={styles.label}>开会日期：</div>
            <div className={styles.input}>
              <Form.Item 
                name="time" 
                noStyle
                initialValue={date}
                rules={[
                  { required: true, message: '请选择开会日期' },
                ]}
              >
                <Input placeholder='请选择开会日期' className={styles.input_color}/>
              </Form.Item>
              <div className={styles.date}></div>
            </div>
        </div>
      </Form>
      <div className={styles.sub_btn} onClick={onSubmit}>提交</div>
    </div>:<div className={styles.after_sub}>
      <div className={styles.form_title}>预约成功</div>
      <div className={styles.tip}>工作人员会及时与您取得联系</div>
      <div className={styles.success_item}>
        <span>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</span>
        <span>{formData.userName}</span>
      </div>
      <div className={styles.success_item}>
        <span>联系电话：</span>
        <span>{formData.phone}</span>
      </div>
      <div className={styles.success_item}>
        <span>参加人数：</span>
        <span>{formData.number}</span>
      </div>
      <div className={styles.success_item}>
        <span>开会日期：</span>
        <span>{formData.time}</span>
      </div>
    </div>}
    <DatePicker
      title='时间选择'
      visible={visible}
      onClose={() => setVisible(false)}
      min={new Date()}
      precision='minute'
      onConfirm={val => {
        form.setFieldsValue({time: val ? moment(val).format('YYYY-MM-DD HH:mm'): ''})
        setDate(val)
      }}
    />
  </div>
}

export default BookBoardroom