import React, {useState} from "react"
import styles from './index.module.scss'
import gotoBrowserImg from 'images/goto-browser.png'
import share from '../imgs/share-page.png'
import Library from 'library'

const SharePage = () => {
  const [maskShow, setMaskShow] = useState(false)

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true)
    } else {
      window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL
    }
  }

  return <div className={styles.points_lottery_share}>
    <img src={share} alt="" />
    <div className={styles.load_btn} onClick={() => openApp()}>下载/打开绿地G优APP</div>
      {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default SharePage
