import React, { useEffect, useState, useMemo } from 'react'
import {useHistory} from 'react-router-dom';
import Library from 'library';
import Services from 'services';
import styles from './assemble.module.scss';
import as_top from './images/as_top.png'
import as_rule from './images/as_rule.png'
import as_bt1 from './images/as_bt1.png'
import as_bt2 from './images/as_bt2.png'
import as_text from './images/as_text.png'
import as_item1_bg from './images/as_item1_bg.png'
import as_item1_title from './images/as_item1_title.png'
import as_item1_price1 from './images/as_item1_price1.png'
import as_item1_price2 from './images/as_item1_price2.png'
import as_item1_content from './images/as_item1_content.png'
import as_item2_bg from './images/as_item2_bg.png'
import as_item2_title from './images/as_item2_title.png'
import as_item2_price from './images/as_item2_price.png'
import as_item2_content from './images/as_item2_content.png'
import as_item3_bg from './images/as_item3_bg.png'
import as_item3_title from './images/as_item3_title.png'
import as_item3_price from './images/as_item3_price.png'
import as_item3_content from './images/as_item3_content.png'
import as_item4_bg from './images/as_item4_bg.png'
import as_item4_title from './images/as_item4_title.png'
import as_item4_price from './images/as_item4_price.png'
import as_item4_content from './images/as_item4_content.png'
import as_light from './images/as_light.png'
import as_price_ing from './images/as_price_ing.png'
import as_price_out from './images/as_price_out.png'
import as_logo from './images/as_logo.png'
import as_mask1 from './images/as_mask1.png'
import as_mask2 from './images/as_mask2.png'
import as_close from './images/as_close.png'
import as_confirm from './images/as_confirm.png'
import as_share from './images/as_share.png'
import as_airdrop_icon from './images/as_airdrop_icon.png'
import airdrop_mask from './images/as_airdrop_mask.png'
import { useRef } from 'react';
const { JsBridge, Util } = Library;
let timer
export const Assemble = () => {
  let leave = false
  let inviteCode = useRef()
  const history = useHistory();
  const [show1, setShow1] = useState(false)
  const toggle1 = () => {
    // setShow1(!show1)
    JsBridge.open(`/front/activities/nft/anniversary`);
  }
  const [show2, setShow2] = useState(false)
  const toggle2 = () => {
    // setShow2(!show2)
    JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/nft/nftActive/disneyRaffle`});
  }
  const [show3, setShow3] = useState(false)
  const toggle3 = () => {
    setShow3(!show3)
  }
  const [show4, setShow4] = useState(false)
  const toggle4 = () => {
    setShow4(!show4)
  }
  const [ruleText, setRuleText] = useState('')
  const [ruleTitle, setRuleTitle] = useState('')
  // 数字产品列表
  const [list, setList] = useState([])
  async function fetchData () {
    const res = await Services('MainPage', { code: '101193' }, { activityCode: 'nftActive' , codeType: 'activityRule', code: 'nftAggregateRule'})
    if(res.status > 0) {
      const {data} = res
      setRuleText(data.activityRule.otherSign)
      setRuleTitle(data.activityRule.codeAlias)
    }
  }
  async function fetchList() {
    const res = await Services('MainPage', { code: '600069' }, {})
    console.log(res)
    if(res.status > 0) {
      const list = res.data.ktNftAggregateList
      inviteCode.current = res.data.inviteCodeSelf
      dealList(list)
      setList(list)
     }
    //  const list = await getList()
    //  dealList(list)
    //  setList(list)
  }
  /* function getList() {
    return new Promise((resolve,reject) => {
      setTimeout(() => {
        const res = [
          {
            startTime: '2022-06-08 09:00:00',
            endTime: '2022-06-08 23:59:00',
            period: '09:00:00-23:59:00',
            availableStatus: 0,
            productType: '0',
            productPrice: null,
            progressBar: '95',
            salesTime: '2022-06-08 09:00:00',
            systemTime: '2022-06-08 08:00:00',
            detailsPageUrl: 'https://www.baidu.com'
          },
          {
            startTime: '2022-06-08 09:00:00',
            endTime: '2022-06-08 15:00:00',
            period: '09:00:00-15:00:00',
            availableStatus: 0,
            productType: '1',
            productPrice: '30',
            progressBar: '0',
            salesTime: '2022-06-08 00:00:00',
            systemTime: '2022-06-08 08:59:50',
            detailsPageUrl: 'https://www.baidu.com'
          },
          {
            startTime: '2022-06-08 09:00:00',
            endTime: '2022-06-08 15:00:00',
            period: '09:00:00-15:00:00',
            availableStatus: 2,
            productType: '2',
            productPrice: '300',
            progressBar: '20',
            salesTime: '2022-06-08 00:00:00',
            systemTime: '2022-06-08 08:59:40',
            detailsPageUrl: 'https://www.baidu.com'
          },
          {
            startTime: '2022-06-08 09:00:00',
            endTime: '2022-06-08 15:00:00',
            period: '09:00:00-15:00:00',
            availableStatus: 3,
            productType: '3',
            productPrice: '30000',
            progressBar: '100',
            salesTime: '2022-06-08 09:00:00',
            systemTime: '2022-06-08 08:00:00',
            detailsPageUrl: 'https://www.baidu.com'
          }
        ]
        resolve(res)
      }, 1000);
    })
  } */
  function dealList(arr) {
    arr.forEach(item => {
      switch(item.productType) {
        case '0': // 盲盒
          item.bg = as_item1_bg
          item.title = as_item1_title
          item.price = as_item1_price1
          item.price2 = as_item1_price2
          // item.content= '开盲盒可获得隐藏款或典藏款隐藏款999份、典藏款1份'
          item.content = as_item1_content
          item.count = ''
          item.second=1
        break;
        case '1': // 史诗
          item.bg = as_item2_bg
          item.title = as_item2_title
          item.price = as_item2_price
          // item.content= '绿地G优尊享会员、返888积分迪士尼抽奖1次、盲盒抽签券1张'
          item.content = as_item2_content
          item.count = ''
          item.second=1
        break;
        case '2': // 传说
          item.bg = as_item3_bg
          item.title = as_item3_title
          item.price = as_item3_price
          // item.content= '绿地G优荣耀会员、返8888积分迪士尼抽奖3次、盲盒抽签券10张'
          item.content = as_item3_content
          item.count = ''
          item.second=1
        break;
        case '3': // 典藏
          item.bg = as_item4_bg
          item.title = as_item4_title
          item.price = as_item4_price
          // item.content= '绿地G优王者会员、返88888积分神秘大礼一份、迪士尼5人(含儿童)家庭贵宾票、获购买款式无聊猿在衍生品上的使用权(仅限公益用途)'
          item.content = as_item4_content
          item.count = ''
          item.second=1
        break;
      }
    });
  }
  function count() {
    const res = list.findIndex((item)=> item.availableStatus===0 && item.productType!=='0')
    if(res < 0) return
    timer = setTimeout(() => {
      list.forEach((item) => {
        if(item.availableStatus === 0 && item.productType!=='0') {
          const obj = Util.getDistanceTime(item.startTime, item.systemTime, item.second)
          item.count = obj.str
          item.second++
          if(obj.done) {
            item.availableStatus = 1
            clearTimeout(timer)
          }
        }
      })
      const arr = [...list]
      setList(arr)
    },1000)

  }
  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
        fetchList()
        console.log('leave'+ leave)
        onDidFocus()
    });
  }
  useEffect(() => {
    JsBridge.verifyRealName('购买绿地30周年庆数字藏品，请提前完成实名认证。');
    fetchData()
    fetchList()
    onDidFocus()
    checkToday()
    return () => {
      leave = true
      clearTimeout(timer)
    }
  },[])
  useEffect(() => {
    if (leave) return
    count()
    return () => {
      clearTimeout(timer)
    }
  }, [list])
  const handleClick = (item) => {
    return () => {
      if(item.productType==='0') {
        // JsBridge.open(item.detailsPageUrl);
        JsBridge.push('CustomWeb',{uri:item.detailsPageUrl});
      } else if(item.productType==='3') {
        JsBridge.push('CustomWeb',{uri:item.detailsPageUrl});
      }else {
        if(item.availableStatus!==1) return
        if(item.progressBar >= 100) return
        JsBridge.push('CustomWeb',{uri:item.detailsPageUrl});
      }
    }
  }
  const handleShare = () => {
    console.log(inviteCode.current)
    history.push(`/front/activities/nft/assemble/nftActive/share?inviteCode=${inviteCode.current}`); // 需要携带邀请码
  }
  const goAirdrop = () => {
    history.push('/front/activities/nft/airdrop');
  }
  const checkToday = () => {
    const key = localStorage.getItem('as_time')
    if(key) {
      const obj = Util.getDistanceTime(new Date().toDateString(), key, 0)
      if(obj.done) return
      setShow4(true)
      localStorage.setItem('as_time', new Date().toDateString())
    } else {
      setShow4(true)
      localStorage.setItem('as_time', new Date().toDateString())
    }
  }
  return(
    <div className={styles.container}>
      <div className={styles.top}>
        <img className={styles.bg} src={as_top} />
        <img className={styles.rule_bt} src={as_rule} onClick={toggle3} />
        <img className={styles.share} src={as_share} onClick={handleShare} />
        <img className={styles.airdrop_icon} src={as_airdrop_icon} onClick={goAirdrop} />
      </div>
      <div className={styles.bg_wrap}>
        <div className={styles.bts_wrap}>
          <img src={as_bt1} onClick={toggle1} />
          <img src={as_bt2} onClick={toggle2}/>
        </div>
        <div className={styles.as_text}>
          <img src={as_text} />
          <div className={styles.date}>6月20日-7月15日 9点-15点开启秒杀</div>
        </div>
        <div className={styles.lists}>
          {
            list.map((item, index) => {
              return (
                <div className={styles.item} key={index} onClick={handleClick(item)} style={{display:(item.productType==='3'&&item.availableStatus===3)?'none': 'block'}}>
                  <div className={styles.item_bg}>
                    <img src={item.bg} />
                    <div className={styles.item_count} style={{display:(item.productType!=='0'&&item.availableStatus===0)?'block': 'none'}} >
                      开售倒计时 {item.count}
                    </div>
                    <div className={styles.item_count} style={{display:(item.productType!=='0'&&item.availableStatus===2)?'block': 'none'}} >
                      明日09:00开售
                    </div>
                  </div>
                  <div className={styles.item_bottom}>
                    <div className={styles.item_left}>
                      <div className={styles.item_title}>
                        <img src={item.title} />
                      </div>
                      <ProgressLine
                        // style={{display:(item.productType!=='0'&&(item.availableStatus===1 || item.availableStatus===2 || item.availableStatus===3))?'flex': 'none'}}
                        style={{display:item.productType!=='0'?'flex': 'none'}}
                        percent={item.progressBar}
                        status={item.availableStatus}
                        />
                      <div className={styles.item_content}>
                        <img src={item.content} />
                      </div>
                    </div>
                    <div className={styles.item_right}>
                      <img src={item.productType==='0'?
                      (item.availableStatus===1? item.price2:item.price) :
                      (item.availableStatus === 1?(item.progressBar>=100 ?as_price_out:item.price): (item.availableStatus === 0?as_price_ing : as_price_out))
                      } />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={styles.logo}>
          <img src={as_logo} />
        </div>
      </div>
      <Mask1 toggle={toggle1} style={{display: show1?'flex': 'none'}} />
      <Mask2 toggle={toggle2} style={{display: show2?'flex': 'none'}} />
      <Rule text={ruleText} title={ruleTitle} toggle={toggle3} style={{display: show3?'flex': 'none'}} />
      <AirModel toggle={toggle4} style={{display: show4?'flex': 'none'}} goAir={goAirdrop} />
    </div>
  )
}

// 30周年活动遮罩
const Mask1 = (props) => {
  const handleClose = () =>{
    props.toggle()
  }
  return (
    <div style={props.style} className={styles.mask} >
      <div className={styles.content}>
        <img className={styles.ad} src={as_mask1} />
        <img className={styles.close} src={as_close} onClick={handleClose} />
      </div>
    </div>
  )
}
// 迪士尼活动
const Mask2 = (props) => {
  const handleClose = () =>{
    props.toggle()
  }
  return (
    <div style={props.style} className={styles.mask} >
      <div className={styles.content}>
        <img className={styles.ad} src={as_mask2} />
        <img className={styles.close} src={as_close} onClick={handleClose} />
      </div>
    </div>
  )
}
// 活动活动
const Rule = (props) => {
  const handleClose = () =>{
    props.toggle()
  }
  return (
    <div style={props.style} className={styles.mask_rule} >
      <div className={styles.content}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.text}>{props.text}</div>
        <div className={styles.close} onClick={handleClose}>
          <img src={as_confirm} />
        </div>
      </div>
    </div>
  )
}
// 进度条
const ProgressLine = (props) => {
  const { percent, status } = props
  return (
    <div className={styles.pro_wrap} style={props.style}>
      <div className={styles.pro_left}>
        <div className={styles.pro_pa}>
          <NormalShow percent={percent} status={status}/>
        </div>
      </div>
    </div>
  )
}
const NormalShow = (props) => {
  const { percent, status } = props
  const render = useMemo(() => {
    if(status === 0 || percent <= 0) {
      return (
        <div className={styles.pro_chil} style={{width: '100%',background: 'transparent', textAlign: 'left', paddingLeft: '5px'}}>
          <span style={{color: '#CF77F7'}}>0%</span>
        </div>
      )
    } else {
      return(
        <div className={styles.pro_chil} style={{width: (status === 2 || status === 3)? '100%':(percent+'%')}}>
            <span>{(status === 2 || status === 3)? 100: percent}%</span>
            <div className={styles.img_wrap}>
              <img src={as_light} />
            </div>
        </div>
      )
    }
  },[percent,status])
  return render
}

// 空投遮罩
const AirModel = (props) => {
  const {toggle, goAir} = props;
  const handleClose = () =>{
    toggle()
  }
  const goToAirdrop = () => {
    goAir()
  }
  return (
    <div style={props.style} className={styles.mask} >
      <div className={styles.content}>
        <img className={styles.ad} src={airdrop_mask} onClick={goToAirdrop} />
        <img className={styles.close} src={as_close} onClick={handleClose} />
      </div>
    </div>
  )
}
