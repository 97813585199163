import React, { useState, useEffect, useRef, useCallback } from 'react';
import TopNavigation from '../topNavigation';
import styles from './index.module.scss';
import modalImg1 from './images/modalImg1.png'
import modalImg2 from './images/modalImg2.png'
import modalImg3 from './images/modalImg3.png'
import modalImg4 from './images/modalImg4.png'
import modalImg5 from './images/modalImg5.png'
import modalImg6 from './images/modalImg6.png'
import modalImg7 from './images/modalImg7.png'
import modalImg8 from './images/modalImg8.png'
import secondBg from './images/secondBg.png';
import botSloganImg from './images/botSloganImg.png';
import modalExampleBgImg1 from "./images/modalExampleBgImg1.png";
import modalExampleBgImg2 from "./images/modalExampleBgImg2.png";

import { url } from 'library/util';

import leftArrowImg from './images/leftArrowImg.png';
import Library from 'library';
const { JsBridge } = Library;

export const WealthManage = (props) => {
    const [color1, setColor1] = useState("#593013");
    const [color2, setColor2] = useState("#333333");

    const [height, setHeight] = useState(null);
    //border1
    const [borderColor1, setBorderColor1] = useState("#EED7C0");
    const [borderColor2, setBorderColor2] = useState("#C3C9D4");

    useEffect(() => {
        console.log("打印下");
        getStatusBarHeight();
    }, []);

    const getStatusBarHeight = async () => {
        console.log("进入函数1");
        const { height } = await JsBridge.getStatusBarHeight();
        console.log("执行了上述代码");
        console.log("打印下状态栏的高度", height);
        setHeight(height);
    }

    return <div className={styles.container}>
        <TopNavigation title='财富管理' theme='dark'/>
        <div className={styles.header}>

            <div className={styles.headerInner}>
                <div className={styles.headerInnerCtn}>
                    <span className={styles.modalBox}>
                        <img src={modalImg1} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>私募股权基金</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg2} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>证券投资基金</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg3} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>信托</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg4} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>金融资产交易所</span>
                    </span>

                    <span className={styles.modalBox}>
                        <img src={modalImg5} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>融资租赁</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg6} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>区域性小贷</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg7} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>互联网小贷</span>
                    </span>
                    <span className={styles.modalBox}>
                        <img src={modalImg8} className={styles.modalImg} />
                        <span className={styles.botTextDesc}>互联网保险</span>
                    </span>
                </div>
            </div>
        </div>
        <div className={styles.secondPart}>
            <div className={styles.secondPartInner}>

                <div className={styles.secondTitleWrap}>
                    <img src={secondBg} className={styles.secondBg} />
                    <span className={styles.textTitle2}>境外投资工具</span>
                </div>
                <div className={styles.secondContentMain}>
                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg1})`, color: color1 }}>
                        <span className={styles.modalText1}>绿地（亚洲）证券</span>
                        <span className={styles.modalText2}>Greenland(Asia) Securities</span>
                        <span className={styles.modalText3} style={{borderColor:borderColor1}}></span>
                        <span className={styles.modalText4}>持有香港金融管理局4号牌与9号牌</span>
                        <span className={styles.modalText5}>
                        可以合法开展证券投资咨询，资产管理和设立全权委托类的基金。
                        </span>
                    </span>

                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg2})`, color: color2 }}>
                        <span className={styles.modalText1}>绿联国际银行</span>
                        <span className={styles.modalText2}>Green Link Digital Bank</span>
                        <span className={styles.modalText3}  style={{borderColor:borderColor2}}></span>
                        <span className={styles.modalText4}>持有新加坡数字银行牌照</span>
                        <span className={styles.modalText5}>
                            可以从中小型企业和其他非零售客户部门收取存款并提供银行服务。
                        </span>
                    </span>

                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg1})`, color: color1 }}>
                        <span className={styles.modalText1}>绿地金融财务公司</span>
                        <span className={styles.modalText2}>Greenland Financial Finance Company</span>
                        <span className={styles.modalText3} style={{borderColor:borderColor1}}></span>
                        <span className={styles.modalText4}>持有香港放债人牌照</span>
                        <span className={styles.modalText5}>
                            可以开展供应链金融以及放贷业务。
                        </span>
                    </span>

                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg2})`, color: color2 }}>
                        <span className={styles.modalText1}>绿地（新加坡）信托管理公司</span>
                        <span className={styles.modalText2}>Greenland（Singapore）Trust Management</span>
                        <span className={styles.modalText3} style={{borderColor:borderColor2}}></span>
                        <span className={styles.modalText4}>新加坡注册控股公司</span>
                        <span className={styles.modalText5}>
                            计划收购海外房地产标的，并进行资产证券化。
                        </span>
                    </span>

                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg1})`, color: color1 }}>
                        <span className={styles.modalText1}>绿地金融海外投资集团</span>
                        <span className={styles.modalText2}>Greenland Global Investment</span>
                        <span className={styles.modalText3} style={{borderColor:borderColor1}}></span>
                        <span className={styles.modalText4}>绿地全资子公司</span>
                        <span className={styles.modalText5}>
                            主要业务为海外投资，涉及股票、债券等权益，并已注册BVI。
                        </span>
                    </span>

                    <span className={styles.modalExampleDiv} style={{ backgroundImage: `url(${modalExampleBgImg2})`, color: color2 }}>
                        <span className={styles.modalText1}>绿地财富（澳门）投资一人公司</span>
                        <span className={styles.modalText2}>Greenland Wealth（Macau）Investment</span>
                        <span className={styles.modalText3} style={{borderColor:borderColor2}}></span>
                        <span className={styles.modalText4}>绿地全资子公司</span>
                        <span className={styles.modalText5}>
                            立足澳门参与粤港澳大湾区金融建设，协助境内外资金连通。
                        </span>
                    </span>


                </div>

            </div>
        </div>

        <div className={styles.thirdSloganPart}>
            <img src={botSloganImg} className={styles.botSloganImg} />
        </div>
    </div>
}