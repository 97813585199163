import {SHARE_HIDE, SHARE_SHOW} from '../../../constants';

export const share = {
    show: (showMask = false, title) => {
        return {
            type: SHARE_SHOW,
            data: {
                showMask: showMask
                    ? 'SHOW'
                    : 'HIDE',
                title: title
            }
        };
    },
    hide: () => {
        return {type: SHARE_HIDE};
    }
};
