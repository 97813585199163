/**
 * 每日签到首页
 */
import React, {useEffect, useState, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import Library from 'library';
import Services from 'services';
import styles from './home.module.scss';
import back from 'images/icon/back-black@2x.png';
import iconPrice from './imgs/icon_liwu.png';
import signTipDis from './imgs/sign-tip-btn.png';
import inviteNewImg from './imgs/icon_yaoxing.png';
import exchangeImg from './imgs/icon_jilu.png';
import signTipGold from './imgs/sign-tip-gold-btn.png';
import taskIcon1 from './imgs/icon_huiyuan.png';
import taskIcon2 from './imgs/icon_shiming.png';
import taskIcon3 from './imgs/icon_bangding.png';
import taskIcon4 from './imgs/icon_fengxiang.png';
import taskIcon5 from './imgs/icon_xinxi.png';
import signBtnDis from './imgs/btn_dis_qiandao.png';
import signBtn from './imgs/btn_qiandao.png';
import bgTop from './imgs/pic_hei.png';
import bgBottom from './imgs/pic_huang.png';
import lastBgBottom from './imgs/pic_huang2.png';
import {SignModal} from './components/signModal';
import SignSuccessModal from './components/signSuccessModal';
import {isEmptyString, isIOS} from '../../../library/util/publicFun';

import InviteBox from './components/InviteBox';
import {Exchange} from './components/exchange';
import { SignTimeLine } from './components/signTimeLine';
import { NotifySwitch } from './components/notifySwitch';
import {CARBONPOINT} from "../../../library/util/constant";

let toastTimer;
let rewardTimer;
let isLoading;
const {JsBridge, Util} = Library;

const DailySignHeader = (props) => {
    const {state, onSignIn, initCallback, statusHeight} = props;
    const {amount, maxAmount, signInArray, signInDay, avatar, isSigned} = state || {};
    const history = useHistory();
    
    const isIos = isIOS();

    const {signArray, num} = useMemo(() => {
        let newSignArray = [];
        let num = 0;
        
        if (signInDay <= 7) {
            newSignArray = signInArray.slice(1, 8);
        } else if (signInDay > 7 && signInDay <= 14) {
            newSignArray = signInArray.slice(8, 15);
            num = 7;
        } else if (signInDay > 14 && signInDay <= 21) {
            newSignArray = signInArray.slice(15, 22);
            num = 14;
        } else if (signInDay > 21) {
            newSignArray = signInArray.slice(22);
            num = 21;
        }
        return {signArray: newSignArray, num: num};
    }, [signInArray])

    if (!signInArray) return null;

    return <div className={`${styles.dailySignHeader} ${isIos ? '' : styles.androidBottom}`}>
        <div
            className={styles.dailyHeaderTop}
            style={{paddingTop: statusHeight + 5 + 'px'}}
        >
            <img
                className={styles.backImg}
                src={back} alt=''
                onClick={() => {
                    if (!isSigned && amount < maxAmount) {
                        history.push('/front/activities/dailySignin/index/exitModal');
                        return;
                    }
                    JsBridge.close();
                }}
            />
            <div className={styles.signRules} onClick={() => {
                history.push('/front/activities/dailySignin/index/rule', {rule: state.signRule});
            }}>签到规则
            </div>
        </div>
        <div className={styles.dailyHeaderMiddle}>
            <div className={styles.userAvatar}>
                <img src={avatar} alt=''/>
                <span>可用兑换碎片：<i>{amount}</i>个</span>
            </div>
            <div
                className={styles.exchangeFragment}
                onClick={() => {
                    history.push({
                        pathname: '/front/activities/dailySignin/index/exchange', callback: () => {
                            initCallback && initCallback();
                        },
                        maxAmount: maxAmount
                    });
                }}
            >
                碎片兑换
            </div>
        </div>
        <div className={styles.signProgressWrap}>
            <div className={styles.signDaysFlag}>
                <div
                    className={styles.signProgress}
                    style={{width: (signInDay - num) / signArray.length * 100 + '%'}}
                ></div>
            </div>
            <div className={`${styles.continuitySignDays} ${signInDay > 21 ? styles.dayLeft : ''}`}>
                {signArray.map((item, index) => {
                    return <div className={styles.continuitySignDaysItem} key={`item-${index}`}>
                        {item.signPrize > 0 && item.prizeType > 1 && item.signOrder > signInDay ?
                            <img src={iconPrice} alt=''/> :
                            <div className={styles.daysFlag}><span className={styles.signProgressInnerItem}></span>
                            </div>}
                        <span>{item.signOrder}天</span>
                    </div>
                })}
            </div>
        </div>
        <SignOperation state={state} onSignIn={onSignIn}/>
        <div className={styles.fragmentDesc}>
            <div className={styles.fragmentTop}>
                <span className={styles.signText}>已连续签到 <i>{signInDay}</i> 天</span>
                <div className={styles.signTip}>
                    {state ? <NotifySwitch isNotice={state.noticeSwtich}/> : false}
                </div>
            </div>
            <div className={styles.fragmentCount}>
                {signInArray && signInArray.map((item, index) => {
                    if (item.signPrize > 0 && item.prizeType > 1) {
                        if (index === signInArray.length - 1) {
                            if (item.signInStatus === 1) {
                                return <div
                                    className={styles.lastFragItem}
                                    key={`item-${index}`}
                                >
                                    <img className={styles.bgTop} src={bgTop} alt=''/>
                                    <img className={styles.lastBgBottom} src={lastBgBottom} alt=''/>
                                    <span className={styles.lianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.prizeCount}>+{item.signPrize}</span>
                                    <span className={styles.prizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            } else {
                                return <div
                                    className={`${styles.lastItem} ${styles.weilingqu}`}
                                    key={`item-${index}`}
                                >
                                    <span className={styles.noLianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.noPrizeCount}>+{item.signPrize}</span>
                                    <span className={styles.noPrizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            }
                        } else {
                            if (item.signInStatus === 1) {
                                return <div
                                    className={styles.fragReceived}
                                    key={`item-${index}`}
                                >
                                    <img className={styles.bgTop} src={bgTop} alt=''/>
                                    <img className={styles.bgBottom} src={bgBottom} alt=''/>
                                    <span className={styles.lianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.prizeCount}>+{item.signPrize}</span>
                                    <span className={styles.prizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            } else {
                                return <div
                                    className={`${styles.fragmentCountItem} ${styles.weilingqu}`}
                                    key={`item-${index}`}
                                >
                                    <span className={styles.noLianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.noPrizeCount}>+{item.signPrize}</span>
                                    <span className={styles.noPrizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            }
                        }
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    </div>
}

const TaskRender = (props) => {
    const {pointTaskList} = props;
    const completeTasks = pointTaskList.filter(a => a.status === 'true');
    const completeRate = completeTasks.length / pointTaskList.length * 100;
    return <div className={styles.taskBox}>
        <h1>积分任务</h1>
        <p>完成任务，进度达到100%可<i>得500积分</i></p>
        <div className={styles.progress}>
            <div className={styles.current} style={{width: completeRate + '%'}}>
                <span className={completeRate === 0 ? '' : styles.styleLeft}>{completeRate}%</span>
            </div>
        </div>

        {pointTaskList.sort((a, b) => {
            return b.status === 'true' ? -1 : 1;
        }).map((item, key) => {
            item.icon = {
                register: taskIcon1,
                certificate: taskIcon2,
                bindCard: taskIcon3,
                riskReport: taskIcon4,
                completeAll: taskIcon5
            }[item.type];
            item.className = {
                true: styles.finish,
                ready: styles.earn,
                false: styles.todo
            }[item.status];
            item.buttonText = {
                true: '已完成',
                ready: '领取积分',
                false: '去完成'
            }[item.status];
            return <div key={key} className={styles.task}>
                <img className={styles.taskIcon} src={item.icon} alt='任务'/>
                <div className={styles.taskInfo}>
                    <h2>{item.name}</h2>
                    <p>{item.complete} <em>得{item.point}积分</em></p>
                </div>
                <button onClick={async () => {
                    if (item.status === 'false') {
                        const {path, params} = Util.url.getPramasFromUrl(item.jumpUrl);
                        JsBridge.navigate(path, params);
                    } else if (item.status === 'ready') {
                        const result = await JsBridge.runAction('user', 'submitPointTask', [{
                            taskType: item.type
                        }]);
                        if (result.status > 0) {
                            props.callBack && props.callBack({
                                visible: true,
                                title: '领取成功',
                                content: `恭喜你完成任务！成功领取 ${result.data.oddsSum} 积分，再接再厉哦~`
                            });

                        }
                    }
                }} className={item.className}>{item.buttonText}</button>
            </div>
        })}

    </div>;
}

const SignOperation = (props) => {
    const {state, onSignIn} = props;
    return <div className={styles.signOperation}>
        <button className={styles.commonBtnStyle} onClick={() => {
            JsBridge.open('/front/activities/dailySignin/inviteRecord?activityCode=CLIENTSIGN');
        }}>
            <img src={inviteNewImg} alt=''/>
            <span>邀新记录</span>
        </button>
        {state?.isSigned ? <button className={`${styles.signBtn}`} onClick={onSignIn}>
            <img src={signBtnDis} alt=''/>
        </button> : <button className={styles.signBtn} onClick={onSignIn}>
            <img className={styles.signBtnText} src={signBtn} alt=''/>
        </button>}
        <button className={styles.commonBtnStyle} onClick={() => {
            JsBridge.open('/front/activities/dailySignin/patches');
        }}>
            <img src={exchangeImg} alt=''/>
            <span>碎片记录</span>
        </button>
    </div>;
}

export default (props) => {
    const history = useHistory();
    const [state, setState] = useState(null);
    const [alertData, setAlertData] = useState({visible: false, content: '', title: '', buttons: []});
    const [statusHeight, setStatusHeight] = useState('');
    const [signModalVisible, setSignModalVisible] = useState(false);
    const [pageStatus, setPageStatus] = useState('init');
    const {whichFrom = ''} = Library.Util.url.paramsToObj();
    const getStatusHeight = async () => {
        let {height} = await JsBridge.getStatusBarHeight();
        setStatusHeight(height);
    }

    useEffect(() => {
        getStatusHeight();
        initData(true);
        onDidFocus();
        return () => {
            clearTimeout(toastTimer);
            clearTimeout(rewardTimer);
        };
    }, []);


    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            JsBridge.getStorage({key: 'whichFrom'}).then(res => {
                if (res && res.result && res.result.whichFrom == 1) { //订单提交回来的
                    history.push('/front/activities/dailySignin/index/exchangeSuccess');
                    JsBridge.removeStorage({key: 'whichFrom'});
                }
            })
            initData();
            onDidFocus();
        });
    }

    useEffect(() => {
        backHandler();
    }, [state]);

    const backHandler = async () => {
        await JsBridge.backHandler();
        if (state && !state.isSigned && state.amount < state.maxAmount) {
            history.push('/front/activities/dailySignin/index/exitModal');
        } else {
            JsBridge.close();
        }
        backHandler();
    }

    //初始化数据
    const initData = async (isFirst = false) => {

        const [avatarResult, initDataResult] = await Promise.all(
            [
                JsBridge.runAction('user', 'getAvatar', []),
                Services('MainPage', {code: '101211 '}, {})
            ]
        );
        setPageStatus('finish');
        if (initDataResult.status > 0 && avatarResult.status > 0) {
            const data = initDataResult.data;
            JsBridge.print(data)
            data.signInArray = [{}].concat(data.signInArray);
            data.avatar = avatarResult.data.imagePath || '';
            setState(data);
            if (data.popAd && data.popAd.length > 0 && isFirst) {
                let url = data.popAd[0].tinyPicUrl;
                history.push('/front/activities/dailySignin/index/firstDay', {imageUrl: url});
            }
        }
    };

    //签到
    const signIn = async () => {
        const data = Object.assign({}, state);

        if (data.isSigned) {
            //已签到 抛出提示
            JsBridge.runAction('alert', 'error', ['明日可继续签到'])
        } else {
            //未签到 走签到流程
            if (data.amount >= data.maxAmount) {
                //已获取碎片数>=最多可获取碎片数 需要先去兑换产品
                history.push({
                    pathname: '/front/activities/dailySignin/index/exchange', callback: () => {
                        initData();
                    },
                    maxAmount: data.maxAmount
                });
                return;
            }
            if (isLoading) {
                return;
            }
            isLoading = true;
            const signResult = await Services('MainPage', {code: '101212 '}, {});
            isLoading = false;
            if (signResult.status > 0) {
                const _signResult = signResult.data;
                data.signInDay = _signResult.signInDay;
                data.isSigned = true;
                data.points = _signResult.points;
                setState(data);
                rewardTimer = setTimeout(() => {
                    clearTimeout(rewardTimer);
                    if (_signResult.fragment) {
                        data.amount = data.amount + _signResult.fragment;
                        setState(data);
                    }
                    setSignModalVisible(true);
                }, 1000);
            }
        }
    };

    if (pageStatus !== 'finish') return null;

    return <div className={styles.fullScreen}>
        <div className={styles.container}>
            <DailySignHeader state={state} onSignIn={signIn} statusHeight={statusHeight} initCallback={initData}/>
            <InviteBox state={state} onCallback={initData}/>
            {/* <TaskRender
                pointTaskList={state?.pointTaskList || []}
                callBack={(data) => {
                    setAlertData({
                        ...data, buttons: [{
                            name: '确定', click: () => {
                                setAlertData({visible: false})
                            },
                            className: 'confirm',
                        }]
                    })
                    initData();
                }}
            /> */}
            <Exchange state={state}/>
        </div>
        <SignModal
            {...alertData}
        />
        <SignSuccessModal
            modalVisible={signModalVisible}
            signData={state}
            onClose={() => {
                setSignModalVisible(false);
                initData();
            }}
        />
    </div>
}
