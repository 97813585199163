import React, {useState, useEffect, useRef} from 'react';
import './style.scss';

const CountDown = ({sysTime, endTime, refreshData, isOpacity = false}) => {
    const initalTime = {day: '00', hour: '00', minute: '00', second: '00'};
    const [countTime, setCountTime] = useState(initalTime);
    const timeStampCurrent = useRef(null);
    const timerCurrent = useRef(null);
    // console.log('sysTime---', sysTime)
    // console.log('endTime---', endTime)
    const clearAllTimer = () => { //清除所有的定时器
        let end = setInterval(function () {
        }, 3000);
        for (let i = 1; i <= end; i++) {
            i && clearInterval(i);
        }
    }

    const countDown = (currentTime, endTime, callBack) => {
        if (!currentTime || !endTime) {
            return initalTime;
        }
        const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
        if (timeStampCurrent.current == null) {
            timeStampCurrent.current = currentTimeStamp
        }
        const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
        const distanceStamp = endTimeStamp - timeStampCurrent.current;

        if (distanceStamp <= 0) {
            callBack && callBack(distanceStamp);
            return initalTime;
        }

        let hour = Math.floor((distanceStamp / 1000 / 60 / 60));
        let minute = Math.floor((distanceStamp / 1000 / 60) % 60);
        let second = Math.floor((distanceStamp / 1000) % 60);

        // day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;
        return {day: day, hour: hour, minute: minute, second: second};
    }

    const timerStart = () => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        if (!sysTime || !endTime) {
            return;
        }
        timerCurrent.current = setInterval(() => {
            // console.log('setInterval---')
            if (timeStampCurrent.current) {
                timeStampCurrent.current = timeStampCurrent.current + 1000;
            }
            let obj = countDown(sysTime, endTime, (distanceStamp) => {
                timerCurrent.current && clearInterval(timerCurrent.current);
                if (distanceStamp === 0) {
                    refreshData && refreshData();
                }
            });
            setCountTime(obj);
        }, 1000)
    }

    const visibilitychange = () => {
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                clearAllTimer();
            } else {
                refreshData && refreshData();
            }
        });
    }


    useEffect(() => {
        timeStampCurrent.current = null;
        timerStart();
        return () => {
            timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
        }
    }, [sysTime, endTime])
    const {day, hour, minute, second} = countTime;
    if (!sysTime || !endTime) {
        return null;
    }
    return (
        <div className='guess_count-down-component'>
            <span className={`count ${isOpacity ? 'disable' : ''}`}>{hour}:</span>
            <span className={`count ${isOpacity ? 'disable' : ''}`}>{minute}:</span>
            <span className={`count ${isOpacity ? 'disable' : ''}`}>{second}</span>
        </div>
    )
}

export default CountDown;
