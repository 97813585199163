/**
 * 每日签到 兑换商品弹窗
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import styles from './exchangeSuccess.module.scss';

import {isEmptyString} from "../../../library/util";
const {Platform, JsBridge, Util} = Library;
export const ExchangeSuccess = (props) => {
    const history = useHistory();
    const { nftJumpUrl } = Util.url.paramsToObj();
    const goBack = () => {
        if (Platform.check.isApp() && Platform.check.isAndroid()) {
            JsBridge.webviewGoback();
        } else {
            history.goBack();
        }
    }

    useEffect(()=>{

    },[]);

    return <div className={styles.container}>
        <div className={styles.contentBg}>
            <div className={styles.btnClose} onClick={()=>{
                goBack();
            }}/>
            <div className={styles.wrap}>
                <div className={styles.gift}/>
                <div className={styles.text}>兑换成功</div>
                <div className={styles.bottom}>
                    <button className={styles.btn1} onClick={()=>{
                        goBack();
                    }}>我知道啦</button>
                    <button className={styles.btn2} onClick={()=>{
                        goBack();
                        if (!isEmptyString(nftJumpUrl)){
                            let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?t=${nftJumpUrl}`;
                            JsBridge.push('CustomWeb', {uri: uri});
                        }else {
                            JsBridge.open(`${process.env.REACT_APP_GKT_LINK}/taro/subPages/user/coupon/myCoupon/index`);
                        }
                    }}>立即查看</button>
                </div>
            </div>
        </div>
    </div>
}
