/**
 * @description 邀新专区-邀新记录页
 * @returns route: /front/activities/regularShare/invite-record
 */

import React, { useState, useEffect, useRef, Fragment } from "react";
import { Toast, Tabs, Input, Divider } from "antd-mobile";
import { Header, ListView, ModalAlert } from "components";
import Service from "../invite-zone/service";
import Library from "library";
import moment from "moment";

import Styles from "./index.module.scss";
import status1 from "images/activityShare/status1.png";
import status2 from "images/activityShare/status2.png";
import status3 from "images/activityShare/status3.png";
import status4 from "images/activityShare/status4.png";
import status5 from "images/activityShare/status5.png";
const { JsBridge } = Library;

const TicketRecord = () => {
  const [activeKey, setActiveKey] = useState("0");
  const [dataList, setDataList] = useState([]);
  const [dataList1, setDataList1] = useState([]);

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const inputRef = useRef(null);
  const currentPage = useRef(1);
  const pageSize = 20;
  let dividerFlag = false;
  const visibilitychange = () => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
      } else {
        if (activeKey === "0") {
          loadData1(true);
        } else {
          loadData(true);
        }
      }
    });
  };

  useEffect(() => {
    visibilitychange();
  }, []);

  useEffect(() => {
    if (activeKey === "0") {
      loadData1(true);
    } else {
      loadData(true);
    }
  }, [activeKey]);
  const hideMiddleDigits = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
  };
  const loadData1 = async (loading = false) => {
    if (loading) {
      setLoading(true);
    }
    const res = await Service.getRecordList(
      {
        activityCode: Service.activityCode,
        pageSize: undefined,
      },
      loading
    );
    if (loading) {
      setLoading(false);
    }
    console.log("res", res);
    if (res?.data) {
      const {
        winningList = [],
        unlicensedList = [],
        expireList = [],
      } = res.data;
      setDataList1([...winningList, ...unlicensedList, ...expireList]);
    }
  };
  const loadData = async (loading = false) => {
    currentPage.current = 1;
    if (loading) {
      setLoading(true);
    }
    const res = await Service.getTranRecordList(
      {
        pageSize,
        pageNum: currentPage.current,
      },
      loading
    );
    if (loading) {
      setLoading(false);
    }
    console.log("res", res);
    if (res?.data) {
      const { records = [] } = res.data;
      setDataList(records);
      setHasMore(records.length >= pageSize);
    }
  };
  const loadMore = async () => {
    const res = await Service.getTranRecordList(
      {
        pageSize,
        pageNum: currentPage.current + 1,
      },
      loading
    );
    if (res?.data) {
      const { records = [] } = res.data;
      setDataList([...dataList, ...records]);
      setHasMore(records.length >= pageSize);
      currentPage.current = currentPage.current + 1;
    }
  };

  // 转赠
  const onPointCardTransfer = async (item, mobile, callback) => {
    let errMsg = "";
    if (mobile) {
      if (Library.Util.validate.mobile(mobile)) {
        const res = await Service.tranTicket({
          couponCode: item?.couponCode,
          cycle: item.cycle,
          ruserCode: mobile,
        });

        if (res?.status > 0) {
          callback && callback();
          loadData1(true);
        } else {
          //手机号后段验证是否注册
          // errMsg = '该账号不存在'
        }
      } else {
        errMsg = "请输入正确的手机号";
      }
    } else {
      errMsg = "请输入手机号";
    }
    if (errMsg) {
      callback && callback(errMsg);
    }
  };
  const onItemClick = (type, item) => {
    const bottomBtns = (actions = [], onAction) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 12px",
            marginTop: 25,
            width: "100%",
          }}
        >
          {actions.map((action, k) => {
            const syle = {
              width: 100,
              height: 40,
              borderRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 16,
              color: "#926A03",
            };
            const leftStyle = { ...syle, border: "1px solid #DFCEAB" };
            const rightStyle = {
              ...syle,
              background: "linear-gradient(270deg, #FEEAB4 0%, #D4BA76 100%)",
            };
            return (
              <div
                key={k}
                style={k == 1 ? rightStyle : leftStyle}
                onClick={() => {
                  onAction && onAction(action);
                }}
              >
                {action.text}
              </div>
            );
          })}
        </div>
      );
    };
    if (type == 1) {
      ModalAlert.show({
        title: "",
        content: (
          <div>
            <p
              style={{
                fontSize: 14,
                color: "#3D3D3D",
                fontWeight: 500,
                marginBottom: 8,
              }}
            >
              {" "}
              转赠账号：{" "}
            </p>
            <div
              style={{
                border: `1px solid #FBE7B0`,
                borderRadius: 5,
                height: 32,
                display: "flex",
                justifyContent: "center",
                padding: "0 8px",
              }}
            >
              <Input
                placeholder="请填写好友注册时的手机号"
                maxLength={11}
                style={{ "--font-size": "12px" }}
                onChange={(value) => {
                  inputRef.current = value;
                }}
              />
            </div>
            <span
              className="validator"
              style={{ color: "#FF2A2A", fontSize: 10, paddingTop: 3 }}
            ></span>
            {bottomBtns(
              [
                { key: "cancel", text: "取消" },
                { key: "ok", text: "转赠" },
              ],
              async (action) => {
                if (action.key == "ok") {
                  console.log("转赠");
                  onPointCardTransfer(item, inputRef.current, (errMsg) => {
                    if (errMsg) {
                      document.querySelector(".validator").innerHTML = errMsg;
                    } else {
                      Toast.show("转赠成功");
                      setTimeout(() => {
                        ModalAlert.hide();
                      }, 1000);
                    }
                  });
                } else {
                  ModalAlert.hide();
                }
              }
            )}
          </div>
        ),
      });
    }
  };
  let renderDivider = () => {
    dividerFlag = true;
    return (
      <Divider
        style={{
          margin: "0.5rem 0",
          borderColor: "#793802",
          borderStyle: "dashed",
        }}
      ></Divider>
    );
  };
  const renderItem = (item, index) => {
    let className = "";
    const now = moment().valueOf();
    // true 过期 false 未过期
    const isExprie = item?.expireTime
      ? moment(item?.expireTime) < now
      : undefined;
    if (item?.status !== 0) {
      if (item?.status === 1) {
        className = Styles.defaultItem;
      } else if (item?.status === 2) {
        if (isExprie === false) {
          className = Styles.activeItem;
        } else {
          className = Styles.defaultItem;
        }
      } else {
        className = Styles.defaultItem;
      }
    } else {
      className = Styles.activeItem;
    }
    // 已开奖-未过期
    const flag = item?.status === 2 && isExprie === false;
    return (
      <Fragment>
        {className === Styles.defaultItem &&
          dividerFlag === false &&
          renderDivider()}
        <div
          key={item?.couponCode}
          className={`${className} ${Styles.list_item}`}
        >
          <div className={Styles.item_fixed}>
            {item?.status === 1 && (
              <img className={Styles.item_img1} src={status5} alt="" />
            )}
            {item?.status === 2 && isExprie === true && (
              <img src={status2} className={Styles.item_img} alt="" />
            )}
            {flag && <img src={status1} className={Styles.item_img} alt="" />}
            {item?.status === 3 && (
              <img src={status3} className={Styles.item_img1} alt="" />
            )}
          </div>
          <div className={Styles.item_left}>第{item?.cycle || "-"}期</div>
          <div className={Styles.item_middle}>
            <div className={Styles.title}>{`抽奖券${
              item?.couponCode || "-"
            }`}</div>
            <div className={flag ? undefined : Styles.item_middle_info}>
              <div className={Styles.info}>{`来源：${
                { 0: "邀新", 1: "guland抽奖", 2: "接收" }[item?.source] || "-"
              }`}</div>
              <div className={Styles.info}>{`获取时间：${
                item?.createTime || "-"
              }`}</div>
              <div className={Styles.info}>{`有效时间：${
                item?.startTime.split(" ")[0] || "-"
              } 至 ${item?.endTime.split(" ")[0] || "-"}`}</div>
              {item?.expireTime && (
                <div className={Styles.info}>{`兑换截止时间：${
                  item?.expireTime || "-"
                }`}</div>
              )}
            </div>
          </div>
          {flag && (
            <div
              className={Styles.item_right}
              onClick={() => {
                // 访问元宇宙
                JsBridge.startUnity3d({
                  sceneName: "shop",
                });
              }}
            >
              去兑换
            </div>
          )}
          {item?.status === 0 && (
            <div
              className={Styles.item_right}
              onClick={() => {
                onItemClick(1, item);
              }}
            >
              转赠
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  const renderGiveItem = (item, index) => {
    let className = Styles.activeItem;
    return (
      <Fragment>
        <div
          key={item?.couponCode}
          className={`${className} ${Styles.list_item}`}
        >
          <div className={Styles.item_left}>第{item?.cycle || "-"}期</div>
          <div className={Styles.item_middle}>
            <div className={Styles.title}>{`抽奖券${
              item?.couponCode || "-"
            }`}</div>
            <div className={false ? undefined : Styles.item_middle_info}>
              <div className={Styles.info}>{`操作：${
                { 0: "接收", 1: "转赠" }[item?.action] || "-"
              }`}</div>
              <div className={Styles.info}>{`${
                { 0: "转赠人", 1: "接收人" }[item?.action]
              }：${
                {
                  0: hideMiddleDigits(item?.userCode),
                  1: hideMiddleDigits(item?.rUserCode),
                }[item?.action]
              }`}</div>
              <div className={Styles.info}>{`有效时间：${
                item?.startTime.split(" ")[0] || "-"
              } 至 ${item?.endTime.split(" ")[0] || "-"}`}</div>

              <div className={Styles.info}>{`${
                { 0: "接收时间", 1: "转赠时间" }[item?.action]
              }：${item?.createdTime || "-"}`}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <div className={Styles.invite_record}>
      <Header midText={"我的抽奖券"} shadow />
      <div className={Styles.record_body}>
        <Tabs
          className={Styles.tabs}
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
        >
          {["获取记录", "转赠记录"].map((name, index) => {
            return (
              <Tabs.Tab key={index + ""} title={name}>
                <ListView
                  dataSource={activeKey === "0" ? dataList1 : dataList}
                  emtyDesc={loading ? "数据加载中..." : "暂无数据"}
                  renderItem={activeKey === "0" ? renderItem : renderGiveItem}
                  hasMore={hasMore}
                  onRefresh={activeKey === "0" ? loadData1 : loadData}
                  loadMore={loadMore}
                />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export { TicketRecord };
