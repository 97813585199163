import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./eggAirDropDialog.module.scss"
import egg_tan_img from './../../../../images/egg_tan_img.jpg'
import img_sure from './../../../../images/egg_bt_yes.png'


const EggAirDropDialog = ({maskVisible, setMaskVisible, type}) => {
    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>惊喜空投</div>
                    <img src={egg_tan_img} className={styles.logo}/>
                    <span className={styles.content}>限持有史诗款、传说款、典藏款、隐藏款、世界人工智能纪念款、世界人工智能珍藏款的用户将获得X Ape World纪念藏品一份
平台将在2022年10月16日20:00直接发放至用户账户(单个账户限投一份)。</span>
                    <div className={styles.sure_btn_father} onClick={() => btnClick()}>
                        <img src={img_sure}/>
                        <div className={styles.sure_btn_txt}>
                            <span>我知道了</span>
                        </div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default EggAirDropDialog;
