import React, {useEffect, useRef, useState, useContext, createContext} from 'react';

import Library from "../../../library";
import styles from './allSaintsDayHome.module.scss';
import wanshengjie_headkv from '../../../images/wanshengjie_headkv.png';//上面的大背景
import egg_bt_chakanxiangqing from '../../../images/egg_bt_chakanxiangqing.png';
import egg_bt_huodong from '../../../images/egg_bt_huodong.png';
import egg_bt_shiming from '../../../images/egg_bt_shiming.png';
import egg_bt_lijibaoming_liang from '../../../images/egg_bt_lijibaoming.png'; //立即报名 亮色 大按钮


import icon_saintDay_button_nor from '../../../images/icon_saintDay_button_nor.png';// 灰色 小按钮// 亮色 小按钮
import icon_saintDay_button_dis from '../../../images/icon_saintDay_button_dis.png';// 灰色 小按钮
import icon_saintDay_button_biaoqian from '../../../images/icon_saintDay_button_biaoqian.png';// 标签
import egg_logo from '../../../images/egg_logo.png';

import mu_nei_yi from '../../../images/mu_nei_yi.png'; //木乃伊
import ye_guang_gu_jia from '../../../images/ye_guang_gu_jia.png'; //夜光骨夹
import xu_xue_lie_ren from '../../../images/xu_xue_lie_ren.png';//吸血猎人
import xu_xue_gong_jue from '../../../images/xu_xue_gong_jue.png';//吸血公爵


import AllSaintsDayRuleDialog from "./views/allSaintsDayRuleDialog";
import Services from "../../../services";
import {getYMRTimeString, isArray, isEmptyArray, isEmptyObject, isEmptyString} from "../../../library/util";
import {Toast} from "antd-mobile";
import ListView from "../../../components/ListView1";


const {JsBridge} = Library;
const Context = createContext(null);

export const AllSaintsDayHomeStatic = () => {
    const [userInfo, setUserInfo] = useState({}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名

    useEffect(() => {
        getUserInfo();
    }, [])

    const refreshData = () => { //刷新数据 主要是登录之后要刷新
        getUserInfo();
    }
    const getUserInfo = () => { //获取用户信息
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            setUserInfo(res);

        })
    }

    return (
        <Context.Provider value={{userInfo}}>
            <div className={styles.container}>
                <HeaderComponent refreshData={refreshData}/>
                <ContentComponent/>
                <BottomLogoComponent/>
            </div>
        </Context.Provider>
    )
}

//头部视图
const HeaderComponent = (props) => {
    const [showRuleShow, setShowRuleShow] = useState(false);
    const {userInfo,} = useContext(Context);
    const refreshData = () => {
        if (props.refreshData) {
            props.refreshData();
        }
    }

    const goBack = () => {
        JsBridge.back()
    }

    const huodongRule = () => {
        setShowRuleShow(!showRuleShow)
    }

    const shiMingRenZhen = () => {
        if (!userInfo.isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => { //登录回来
                refreshData();
            });
            return;
        }
        JsBridge.addListener().then(() => { //实名认证回来
            if (props.refreshData) {
                props.refreshData();
            }
        });
        JsBridge.navigate('Verify');
    }

    return (
        <>
            <div className={styles.header_component}>
                <img src={wanshengjie_headkv} className={styles.img_bg}/>
                <div className={styles.back_share_father}>
                    <div className={styles.back_btn} onClick={() => goBack()}></div>
                    <div className={styles.share}/>
                </div>

                <div className={styles.rule_shiming_father}>
                    <div className={styles.huodong_father} onClick={() => huodongRule()}>
                        <img src={egg_bt_huodong} alt=""/>
                        <div className={styles.title}>活动规则</div>
                    </div>

                    {isEmptyString(userInfo.ID) ?
                        <div className={styles.huodong_father} onClick={() => shiMingRenZhen()}>
                            <img src={egg_bt_shiming} alt=""/>
                            <div className={styles.title_shiming}> 实名认证</div>
                        </div> : null}
                </div>

                <span className={styles.fashou_time}>发售时间：10月28日10点-10月30日20点</span>
            </div>
            <AllSaintsDayRuleDialog maskVisible={showRuleShow} setMaskVisible={setShowRuleShow} type={0}/>
        </>
    )
}

const ContentComponent = (props) => {

    const {userInfo} = useContext(Context);
    const [data, setData] = useState([]);
    useEffect(() => {
        loadData();
    }, [userInfo])

    const loadData = async () => { //必须要登录
        if (!userInfo.isLogin) {
            let arr = [];
            for (let i = 0; i < 4; i++) {
                let item = {};
                if (i === 0) {
                    item.img = mu_nei_yi;
                    item.price = '50.00';
                    item.countDes = '限量50件';
                    item.title = '木乃伊绷带';
                } else if (i === 1) {
                    item.img = ye_guang_gu_jia;
                    item.price = '50.00';
                    item.countDes = '限量50件';
                    item.title = '夜光骨架';
                } else if (i === 2) {
                    item.img = xu_xue_lie_ren;
                    item.price = '98.00';
                    item.countDes = '限量20件';
                    item.title = '吸血鬼猎人';
                } else {
                    item.img = xu_xue_gong_jue;
                    item.price = '98.00';
                    item.countDes = '限量20件';
                    item.title = '吸血鬼公爵';
                }
                arr.push(item)
            }
            setData(arr);
        } else {
            const res = await Services('MainPage', {code: '600069'}, {})
            if (res.status > 0) {
                const {ktNftAggregateList} = res.data;
                if (ktNftAggregateList && isArray(ktNftAggregateList) && ktNftAggregateList.length > 0) {
                    for (let i = 0; i < ktNftAggregateList.length; i++) {
                        let item = ktNftAggregateList[i]
                        item.availableStatus=0;
                        if (i === 0) {
                            item.img = mu_nei_yi;
                            item.price = '50.00';
                            item.countDes = '限量50件';
                            item.title = '木乃伊绷带';
                        } else if (i === 1) {
                            item.img = ye_guang_gu_jia;
                            item.price = '50.00';
                            item.countDes = '限量50件';
                            item.title = '夜光骨架';
                        } else if (i === 2) {
                            item.img = xu_xue_lie_ren;
                            item.price = '98.00';
                            item.countDes = '限量20件';
                            item.title = '吸血鬼猎人';
                        } else {
                            item.img = xu_xue_gong_jue;
                            item.price = '98.00';
                            item.countDes = '限量20件';
                            item.title = '吸血鬼公爵';
                        }
                    }
                    setData(ktNftAggregateList);
                }
            }
        }
    }

    const goBuy = (item) => {
        if (item.availableStatus === 0) { //立即购买
            JsBridge.verifyRealName('购买数字藏品，请提前完成实名认证。').then((res) => {
                if (res.result) {
                    if (item.detailsPageUrl) {
                        // JsBridge.push('CustomWeb',{uri:'http://192.168.110.14:3001/allSaintsDaySaleDetail?collectionId=900101006&sale_batch_id=100&t=eJiEipgtC4h0E2gfBwTI+iHD5wONgaeoycmZysBZbdnEShMz9FRG9PTxkQqaFWTqCwwtKaU/RbPyIgZRtgjyKfLc7ihXixt0HZ02Ai7UhwC6GPRyLWsy0WKHTfXwlFze0S3e131k3QUxFeyyGuP3qUXEGnecmahT8E9jt1KZSAKmEfbfHYolLKVRqh0vCzjfFfyjXsT78o8/BuhF8H8l6axU5wkvCSa7SIZreQudTEWDPjgP9noUnHDkCQSaiCOP9SAr7kdqz3WNaozdwn5K0a191T3s8W6FT8hUeST+Uwd6US6RZUzhNl54rtgQ4N6khjNRrse2zMGSsGAha3rm4IlXRRoI0VSEYt/nX6xnfTpfBuLIUI4TbChMBMVRN5hGUuQ3CJ9sV8Eg7ZI6XTJZxyEnSgreJdemvZqa6c4ZPro='})
                        JsBridge.push('CustomWeb', {uri: item.detailsPageUrl});

                    }
                }
            });
        }
    }

    const listItem = (item) => {
        //   availableStatus: -1, //0=立即购买 1=即将开售 2=活动结束 3=今日已售罄 4=倒计时
        let img = icon_saintDay_button_dis;
        let title = '敬请期待';
        if (item.availableStatus === 0) {
            img = icon_saintDay_button_nor;
            title = '购买';
        } else if (item.availableStatus === 1 || item.availableStatus === 4) {
            img = icon_saintDay_button_dis;
            title = '敬请期待';
        } else if (item.availableStatus === 2 || item.availableStatus === 3) {
            img = icon_saintDay_button_dis;
            title = '已售罄';
        }

        return (
            <div className={styles.item_container}>
                <img src={item.img} className={styles.item_img}/>
                <div className={styles.item_name}>{item.title}</div>
                <div className={styles.item_money}>¥{item.price}</div>
                <div className={styles.btn_father}>
                    <div className={styles.btn_father0} onClick={() => goBuy(item)}>
                        <img src={img} alt=""/>
                        <div className={styles.goBuy_title_father}>
                            <span className={styles.goBuy_title_liang}>{title}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.tag_father}>
                    <img src={icon_saintDay_button_biaoqian}/>
                    <span>{item.countDes}</span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.content_father}>
            <ListView
                onRefresh={() => loadData()}
                list={data}
                hasMore={false}
                isLoad={false}
                isNeedRefresh={false}
                ListItem={({item}) => listItem(item)}
            />
        </div>
    )
}

const BottomLogoComponent = (props) => {
    return (
        <div className={styles.logo_father}>
            <img src={egg_logo} className={styles.egg_logo}/>
        </div>
    )
}





