import React, { useEffect, useState, Fragment, useRef } from 'react';

import fuwuneirong from 'images/kangyang/fuwuneirong@2x.png';
import styles from './index.module.scss';
import { BottomButton } from "./components/bottomButton";
import { IntentionCommit } from './components/intentionCommit';
import { Slogon } from '../../slogon';
import { Actions } from 'reduxs';
import services from 'services';
import Library from 'library';
import { umengTrack_healthcare } from 'library/jsbridge/config';
import { Header } from 'components';
import { YSJDetail } from '../ysjdetail';
import { GridView } from './components/GridView';
import { VideoGrid } from './components/videoGrid';
import { Introduce }  from './components/Introduce';

const {JsBridge, Platform} = Library;

const Tabs = [
    [{ name: '介绍', type: 0 }, { name: '视频', type: 1 }],
    [{ name: '康养居', type: 2 }, { name: '服务内容', type: 3 }],
]

export const KangYangJu = (props) => {
    const {segment, index} = Library.Util.url.paramsToObj();
    console.log(`segment:${segment}`, `index:${index}`);

    const [segmentIndex, setSegmentIndex] = useState(segment&&+segment < Tabs.length ? +segment: 0);
    const [tabIndex, setTabIndex] = useState(index && +index < Tabs[0].length  ? +index :0);

    const [visible, setVisible] = useState(false);
    const [arrSource, setArrSource] = useState([]);
    const [arrVideo, setArrVideo] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const currentPage = useRef(1);

    const [videoDot, setVideoDot] = useState(false);
    
    useEffect(()=>{
        getVideoDot();
    },[])

    useEffect(() => {
        if((segment || index) && arrVideo.length > 0) {
            setVideoDot(false);
        }
        loadData() 
    }, [segmentIndex, tabIndex])

    const loadData = async () => {
        if(segmentIndex === 1) {
            if (tabIndex === 0 && arrSource.length === 0) {
                window.scrollTo({ top: 0.1 });//如果滚动后进入其他页面再返回会导致tab栏显示不了，需要滚动让tab栏显示出来
                getData()
            }
        }else {
            if(tabIndex === 1 && arrVideo.length === 0){
                setVideoDot(false);
                await onRefreshVideo();
            }
        }  
    }

    const getVideoDot = async () => {
        const module = Platform.check.isApp() ? 'MainPage': 'Common'
        const res = await services(module, { code: 101196 }, { }, true);
        if (res.status > 0) {
            const flag = res.data.result || res.data;
            setVideoDot(flag !== true);
        }
    }

    const getData = async () => {
        const result = Platform.check.isApp() ? await services('MainPage', { code: 101149 }, {}) : await services('KangYang', 'List', {});
        if (result.status > 0) {
            let { recuperation, base } = result.data;
            setArrSource(recuperation);
        }
    }

    const getVideoList = async (more)=> {
        const module = Platform.check.isApp() ? 'MainPage': 'Common';
        const param = {
            type:1,
            pageNum:more ? currentPage.current +1: currentPage.current, 
            pageSize:10 
        }
        const res =  await services(module, { code: 101195 }, param, !loading);
        setLoading(false);
        if (res.status > 0) {
            const list = res.data.result || res.data;
            if(more) {
                setArrVideo([...arrVideo, ...list])
                currentPage.current = currentPage.current + 1
            }else {
                setArrVideo(list);
            }
            setHasMore(list.length >= 10)
        }
    }
    const onRefreshVideo = async ()  => {
        currentPage.current = 1;
       await getVideoList();
    }
    const loadMoreVideo = async ()=> {
       await getVideoList(true);
    }

    const renderServerContent = () => {
        return <div className={styles.service_box}>
            <img className={styles.fullImg} src={fuwuneirong} draggable={false}/>
            <Slogon />
        </div>
    }

    const renderHeader = ()=> {
        const segments = ['绿地颐尚居', '康养居酒店'];
        return (
            <div className={styles.header_segment}>
                {
                    segments.map((item, i) => {
                        return (
                            <div className={`${styles.segment} ${segmentIndex ===i ? styles.active:''}`} key={i} 
                                onClick={()=>{
                                    setSegmentIndex(i);
                                    setTabIndex(0);
                                }}
                            >{item}</div>
                        )
                    })
                }
            </div>
        )
    }

    return <div className={styles.container}>
         <Header midText={renderHeader()} shadow />
        <div className={styles.tabs}>
            {Tabs[segmentIndex].map((item, i) => {
                return <div className={`${styles.item} ${i !== 0 ? styles.line : ''}`} key={i} onClick={() => {
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_healthcare.topic1, item.name);
                    }
                    setTabIndex(i);
                    window.scrollTo({ top: 0.1 });
                }}>
                    <div className={`${styles.textBg}`}>
                        {item.type === 1 && videoDot && <div className={styles.dot}/>} 
                        <span className={`${i === tabIndex ? styles.active : ''}`}>{item.name}</span>
                    </div>
                </div>
            })}
        </div>
        <div className={styles.content}>
        {
            segmentIndex === 0 ?
            <Fragment>
            {tabIndex === 0 ? <Introduce/> :  <VideoGrid list={arrVideo} hasMore={hasMore} onRefresh={onRefreshVideo} loadMore={loadMoreVideo}/>}
            </Fragment>:
            <Fragment>
            {tabIndex === 0 ? <GridView arrSource={arrSource} /> : null}
            {tabIndex === 1 ? renderServerContent() : null}
            </Fragment>
        }
        </div>
        {
            tabIndex === segmentIndex &&
            <Fragment>
            <BottomButton onClick={() => {
                setVisible(true);
            }} />
            <IntentionCommit visible={visible} 
                onHide={(isSuccess) => {
                    setVisible(false);
                    if (isSuccess) {
                        Actions.alert.show('提交意向成功');
                    }
                }}
            />
            </Fragment>
        }

    </div>
};

