import React, {useRef} from "react";
import {Mask, Toast} from "antd-mobile";
import html2canvas from "html2canvas";
import {throttle} from '../../../library/util/publicFun';
import styles from "./activityShareModal.module.scss";
import QRCode from "qrcode.react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_share_copy from "../../../images/icon_share_copy.png";
import icon_share_down from "../../../images/icon_share_down.png";
import icon_share_pyp from "../../../images/icon_share_pyp.png";
import icon_share_wechat from "../../../images/icon_share_wechat_1.jpg";
import bgImage1 from "./imgs/pic_1.png";
import bgImage2 from "./imgs/pic_2.png";
import bgImage3 from "./imgs/pic_3.png";
import Library from "library";
import { Actions } from 'reduxs';

const {JsBridge} = Library;

const imgMap = {
  'yflhfcz': bgImage1,
  'yflshjf': bgImage2,
  'yflwm': bgImage3,
}

const ShareModal = ({maskVisible, closeMask, activityCode, inviteCode}) => {
    const domRef = useRef(null);
    const shareUrl = `${window.location.origin}/front/activities/yifuli/invite/${activityCode}/${inviteCode}`;

    const createShareImage = () => {
      return new Promise(async (resolve) => {
        html2canvas(domRef.current, {
          allowTaint: true,
          useCORS: true,
          scrollX: 0,
          scrollY: 0
        }).then(async (canvas) => {
          const result = canvas.toDataURL('image/jpeg', 0.8);
          resolve(result);
        }).catch(() => {
          resolve(false);
        });
      })
    }

    // 生成分享图片
    const getShareImagePath = () => {
      return new Promise(async (resolve) => {
        if (!domRef.current) {
          resolve(false);
        }
        let base64 = await createShareImage();
        if (base64) {
          let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
          if (!res) {
            JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
            resolve(false);
          } else {
            resolve(res.tempFilePath);
          }
        } else {
          resolve(false);
        }
      })
    }

    // 微信分享
    const wxShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
          let filePath = await getShareImagePath();
          window.JsBridge.share({
            text: '',
            icon: filePath,
            link: '',
            title: '',
            platform: 1,
          }).then(function (result) {
            shareToast && shareToast.close();
            closeMask && closeMask();
          })
      }
    }, 3000)

    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
        let filePath = await getShareImagePath();
        window.JsBridge.share({
          text: '',
          icon: filePath,
          link: '',
          title: '',
          platform: 2,
        }).then(function (result) {
          shareToast && shareToast.close();
          closeMask && closeMask();
        })
      }
    }, 3000);

    // 下载图片
    const downloadImg = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      let filePath = await getShareImagePath();
      if (JsBridge.hasWebViewBridge()) {
        JsBridge.requestPermission("requestAlbum")
        JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
          shareToast && shareToast.close()
          if (res) {
              Toast.show("图片保存成功");
              closeMask && closeMask();
          } else {
              Toast.show("图片保存失败")
          }
        })
      } else {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.download = `image-${new Date().getTime()}`
        a.href = filePath
        a.click()
        document.body.removeChild(a)
        shareToast && shareToast.close()
      }
    }, 3000)

    const cancel = () => {
      closeMask();
    }

    return (
    <Mask visible={maskVisible} onMaskClick={closeMask} opacity={0.7} style={{
        overflow: 'auto',
        paddingBottom: '10px'
    }}>
      <div className={styles.share_container}>
        <div className={styles.share_body}>
          <div className={styles.share_content} ref={domRef}>
            <div className={styles.share_img_box}>
              <img className={styles.share_avatar} src={imgMap[activityCode]} alt="avatar"/>
            </div>
            <div className={styles.share_detail}>
              <QRCode 
                value={shareUrl} 
                size={120}
                fgColor="#000000"
              />
            </div>
          </div>
        </div>
        <div className={styles.opt}>
          <div className={styles.wechat} onClick={wxShare}>
            <img src={icon_share_wechat} alt=""/>
            <span>微信好友</span>
          </div>
          <div className={styles.share} onClick={momentsShare}>
            <img src={icon_share_pyp} alt=""/>
            <span>朋友圈</span>
          </div>
          <CopyToClipboard text={shareUrl}
              onCopy={async () => {
                await Actions.alert.show('复制成功', 1000);
                closeMask && closeMask();
            }}>
              <div className={styles.copy}>
                <img src={icon_share_copy} alt=""/>
                <span>复制链接</span>
              </div>
            </CopyToClipboard>
          <div className={styles.download} onClick={downloadImg}>
            <img src={icon_share_down} alt=""/>
            <span>下载图片</span>
          </div>
        </div>
        <div className={styles.cancel_btn} onClick={cancel}>
          <span>取消</span>
        </div>
      </div>
    </Mask>
    )
}

export default ShareModal;
