
// G尊享会员卡
export const umengTrack_member = {
    memberinfo: {
        position: 'um_key_memberinfo', 
        positionDesc: '顶部会员卡信息', 
    },
    memberbenefit: {
        position: 'um_key_memberbenefit', 
        positionDesc: '会员特权', 
    },
    use_memberbenefit: {
        position: 'um_key_memberbenefit', 
        positionDesc: '使用特权', 
    }
};
// 酒店
export const umengTrack_hotel = {
    ad: {
        position: 'um_key_hotel_ad', 
        positionDesc: '广告图片', 
    },
    topic: {
        position: 'um_key_hotel_topic', 
        positionDesc: '标题', 
    }
};
// 旅游模块
export const umengTrack_trip = {
    ad: {
        position: 'um_key_trip_ad', 
        positionDesc: '广告图片', 
    },
    topic: {
        position: 'um_key_trip_topic', 
        positionDesc: '标题', 
    }
};
// 康养模块
export const umengTrack_healthcare = {
    topic1: {
        position: 'um_key_healthcare_topic1', 
        positionDesc: '第一行标题', 
    },
    pic: {
        position: 'um_key_healthcare_pic', 
        positionDesc: '广告图片', 
    },
    topic2: {
        position: 'um_key_healthcare_topic2', 
        positionDesc: '第二行标题', 
    },
    list: {
        position: 'um_key_healthcare_list', 
        positionDesc: '酒店和基地列表', 
    },
    phone: {
        position: 'um_key_healthcare_phone',
        positionDesc: '底部电话',
    },
    submit: {
        position: 'um_key_healthcare_submit',
        positionDesc: '提交意向',
    }
};
// 活动模块详情
export const umengTrack_exchange_info = {
    info: {
        position: 'umengTrack_exchange', 
        positionDesc: '热门兑换', 
    }
};
// 立即邀请
export const umengTrack_invite = {
    share: {
        position: 'um_key_activity_invite_share', 
        positionDesc: '分享', 
    },
    pyq: {
        position: 'um_key_activity_invite_share_pyq', 
        positionDesc: '朋友圈', 
    },
    wechatfriend: {
        position: 'um_key_activity_invite_share_wechatfriend', 
        positionDesc: '微信好友', 
    },
    qqfriend: {
        position: 'um_key_activity_invite_share_qqfriend', 
        positionDesc: 'qq好友', 
    },
    qzone: {
        position: 'um_key_activity_invite_share_qzone', 
        positionDesc: 'qq空间', 
    },
    sinaweibo: {
        position: 'um_key_activity_invite_share_sinaweibo', 
        positionDesc: '新浪微博', 
    },
    download: {
        position: 'um_key_activity_invite_share_download', 
        positionDesc: '下载相册', 
    },
    cancel: {
        position: 'um_key_activity_invite_share_cancel', 
        positionDesc: '取消', 
    },
};
