/**
 * @description 介绍
*/
import React, {useState} from "react";
import { ImageViewer } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import JsBridge from 'library/jsbridge';
import styles from './index.module.scss';
import tipsImg from 'images/kangyang/biaoti-icon@2x.png';
import picture1 from 'images/kangyang/kangyang-1@2x.png';
import picture2 from 'images/kangyang/kangyang-2@2x.png';
import picture3 from 'images/kangyang/kangyang-3@2x.png';
import picture4 from 'images/kangyang/kangyang-4@2x.png';
import picture5 from 'images/kangyang/kangyang-5@2x.png';
import picture6 from 'images/kangyang/kangyang-6@2x.png';
import picture7 from 'images/kangyang/kangyang-7@2x.png';
import icon1 from 'images/kangyang/houniao-1@2x.png';
import icon2 from 'images/kangyang/houniao-2@2x.png';
import icon3 from 'images/kangyang/liaoyang-1@2x.png';
import icon4 from 'images/kangyang/liaoyang-2@2x.png';
import icon5 from 'images/kangyang/wenhua-1@2x.png';
import icon6 from 'images/kangyang/wenhua-2@2x.png';
import icon7 from 'images/kangyang/tianyuan-1@2x.png';
import icon8 from 'images/kangyang/tianyuan-2@2x.png';

export const Introduce = ()=> {
    const history = useHistory();

    const renderIntroduce = () => {
        return <div className={styles.old_introduce}>
            <img className={styles.topImg} src={picture1} />
            <p>2018年，康养产业集团正式推出绿地康养居，面向健康活力老人的旅居养老度假产品，以康养酒店为载体，以会员俱乐部为平台，以健康养生、主题文化活动为特色。绿地康养居，致力于成为百姓首选的健康快乐养老生活设计专家！</p>
            <h2>全新生活方式</h2>
            <img className={styles.img} src={picture2} />
            <p>在健康、养生、轻松、安全的环境下，休闲娱乐、旅居交友，心情愉悦，享受康养居带来的健康和快乐。</p>
            <h2>会员俱乐部平台</h2>
            <img className={styles.img} src={picture3} />
            <p>汇集一群乐于享受高品味生活的会员，帮助他们利用闲暇时光边游边养，扩大社交圈，提高养老生活的质量。</p>
            <h2>创新服务模式</h2>
            <img className={styles.img} src={picture4} />
            <p>创新康养服务模式，六大服务体系，健康助理、快乐助理全程陪伴，“更健康·更快乐”全新服务体验。</p>
            <h2>战略布局发展</h2>
            <img className={styles.img} src={picture5} />
            <p>绿地康养居战略布局全国省会、旅游城市、风景区、特色小镇等区域，打造专业化旅居酒店。 现已有四川成都、湖北武汉、江西南昌、苏州昆山、浙江宁波、江苏盐城、广东阳江、贵州贵阳、甘肃兰州、宁夏银川等10余家 “绿地康养居”落成，有超过30家的“绿地康养居”已经纳入规划，正在加紧建设中。</p>
            <div className={styles.title}>
                <img src={tipsImg} className={styles.icon} />
                <h3 className={styles.titleText}>四种生活随心选择</h3>
                <img src={tipsImg} className={`${styles.icon} ${styles.transfer}`} />
            </div>
            <h3>候鸟式养老生活</h3>
            <div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon1} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>享三亚的暖冬美景</h4>
                        </div>

                    </div>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon2} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>享盘山的清凉夏日</h4>
                        </div>

                    </div>
                </div>
            </div>
            <h3>疗养式养老生活</h3>
            <div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon3} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>于峨眉山享清新的氧吧生活</h4>
                        </div>

                    </div>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon4} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>于黄山享太平湖清静的世外桃源</h4>
                        </div>
                    </div>
                </div>
            </div>

            <h3>文化式养老生活</h3>
            <div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon5} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>于灵山九华参禅修佛、明心见性</h4>
                        </div>

                    </div>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon6} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>游古戏今，红动南昌</h4>
                        </div>
                    </div>
                </div>
            </div>

            <h3>田园式养老生活</h3>
            <div>
                <div className={styles.row}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon7} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>绿地郫县康养居，走进中国第一家农家乐——农科村徐家大院</h4>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <img className={styles.icon} src={icon8} />
                        <div className={styles.textBg}>
                            <h4 className={styles.text}>武汉绿地康养居体验木兰农耕年华农业度假生活</h4>
                        </div>
                    </div>
                </div>
            </div>

            <img className={styles.img2} src={picture6} />
            <img className={styles.img} src={picture7} />
        </div>
    }

    //了解更多
    const onMoreClick = (item)=> {
        if(JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/guApp/kangyang/introduce-more?type=${item.index}`});
        }else {
            history.push(`/front/guApp/kangyang/introduce-more?type=${item.index}`)
        }
       
    }

    const Bservices = [
        {title: '健康保健服务', img: require('images/kangyang/introduce_base1.png')},
        {title: '医疗保障服务', img: require('images/kangyang/introduce_base2.png')},
        {title: '智能安康服务', img: require('images/kangyang/introduce_base3.png')},
        {title: '生活照料服务', img: require('images/kangyang/introduce_base4.png')},
        {title: '娱乐社交服务', img: require('images/kangyang/introduce_base5.png')},
        {title: '文化教育服务', img: require('images/kangyang/introduce_base6.png')},
    ]

    return (
        <div className={styles.Introduce}>
            <div className={styles.container}>
                <div className={styles.topBg} draggable={false}/>
                <div className={styles.body_bg}>
                    <div className={styles.header}>
                        <h2>绿地国际康养城<br></br>颐居生活新纪元</h2>
                        <span>/From China the world</span>
                    </div>
                    <p>绿地国际康养城是由绿地康养产业集团依托母公司世界500强雄厚资源，投资29.35亿筑造的集“医、康、养、旅、产、融”六大产业模式于一体的国际化综合性全龄康养社区。作为集团的标杆示范项目，绿地国际康养城结合国内市场需求、借鉴国外先进模式，为长者带来颐动颐静的全新退休生活体验。</p>
                    <img src={require('images/kangyang/introduce_p1.png')} draggable={false}/>
                    <div className={styles.card}>
                        <p>绿地国际康养城坐落于上海市青浦区朱家角这一交通便捷的休闲度假养生地，依托周边丰富文旅资源以及中山医院青浦分院、朱家角人民医院、德颐护理院等优质医疗资源，打造出一处自然与人文兼具的生态天然养心居。</p>
                    </div>
                    <div className={styles.header}>
                        <h2>颐尚居项目展示</h2>
                        <span>/Project Display</span>
                    </div>
                    <div className={styles.row}>
                        <p>项目共分三期开发，其中一期颐尚居定位为会籍制康养社区，涵盖约70~108㎡的颐养公寓和配套商业。二期规划打造康养居酒店、颐养公寓和配套商业，是一期的延伸和拓展。三期规划建设长租式公寓，计划引进幼儿园等教育配套。</p>
                        <div>
                            <img className={styles.img} src={require('images/kangyang/introduce_p2.png')} draggable={false}/>
                            <img className={styles.img} src={require('images/kangyang/introduce_p3.png')} draggable={false}/>
                        </div>
                    </div>
                    <div className={styles.header}>
                        <h2>六颐服务 双重会籍</h2>
                        <span>/Service Membership</span>
                    </div>
                    <p>项目一期颐尚居定位为会籍制康养社区，涵盖颐养公寓、服务中心及配套商业从颐身、颐年、颐家、颐贤、颐神、颐志六个方面构建“六颐”服务体系。颐尚居通过健康助理、生活助理、快乐助理三大助理，提供108项专业化服务更全面采用了56项适老化配置，贴心细致，安全舒适，让会员乐养于此，幸福所居。</p>
                    <img className={styles.img} src={require('images/kangyang/introduce_p4.png')} draggable={false}/>
                    <div className={styles.header_center}>
                        <div>
                            <img src={require('images/kangyang/icon_star.png')} alt="" draggable={false}/>
                            <h2>颐尚居-基础服务</h2>
                            <img src={require('images/kangyang/icon_star.png')} alt="" draggable={false}/>
                        </div>
                        <span>Basic services</span>
                    </div>
                    <div className={styles.service_box}>
                    {
                        Bservices.map((item,index)=>{
                            item.index = index;
                            return <div key={index} className={styles.service_item} onClick={()=>onMoreClick(item)}>
                                <div>
                                    <img src={item.img} alt="" draggable={false}/>
                                    <span>{item.title}</span>
                                </div>
                                <div>
                                    <span>了解详情</span>
                                </div>
                            </div>
                        })
                    }
                    </div>
                    <div className={styles.header_center}>
                        <div>
                            <img src={require('images/kangyang/icon_star.png')} alt="" draggable={false}/>
                            <h2>康养城地址</h2>
                            <img src={require('images/kangyang/icon_star.png')} alt="" draggable={false}/>
                        </div>
                        <span>Health City Address</span>
                    </div>
                    <img className={styles.img} src={require('images/kangyang/introduce_p6.png')} 
                        draggable={false}
                        onClick={()=>{
                            ImageViewer.show({image: require('images/kangyang/introduce_p6.png')})
                        }}
                    />
                </div>
            </div>
            
        </div>
    );

}