import React from 'react'
import styles from './styles.module.scss'
import back from '../imgs/back.png'
import title from '../imgs/protocol.png'
import { useHistory } from 'react-router-dom'

const RealNamePro = () => {
  const history = useHistory()
  return <div className={styles.real_name_pro}>
    <div className={styles.rules_container}>
      <div className={styles.rules_top}>
        <img className={styles.back} src={back} alt="" onClick={() => history.goBack()}/>
        <img className={styles.title} src={title} alt="" />
        <div className={styles.back}></div>
      </div>
      <div className={styles.rules_content}>
        根据现行法律以及公安、防疫部门对大型活动实名制的要求，实名制的项目在购票时需要提供你的实名信息，并在接收方采取严格信息保护措施的前提下，向主办机构/活动场馆/出票方提供你的实名信息，用于出票、入场核验身份或防疫调查，实名信息包括姓名、身份证 。
      </div>
    </div>
  </div>
}

export default RealNamePro