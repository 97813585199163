const sequenceKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J','K','L','M','N']
const QuestionType = {
    'choice1': 0, //单选
    'choice0': 1, //多选
    'input': 2,
    'input0': 2, //文字
    'input1': 2, //文字
    'textarea': 2,
    'textarea0': 2, //文字
    'textarea1': 2, //文字
}

const libData = {
    title: '母亲节礼物调查问卷',
    activityCode: 'motherDay',
    currentTime: '2023-03-17 14:10:00',//系统时间
    beginTime: undefined,
    endTime: '2023-03-17 15:10:00',
    rule: `1、问卷调查活动时间内，用户完成后赠送300积分
    2、每人限填一次，不可重复填写
    3、积分发放完成后，积分明细页面会新增一条积分增加记录，并且发放方式列显示为问卷调查`,
    votedList:{}, //后端返回的答案对象
    list: [
       {
            title: '您的年龄是？', //标题
            required: true, //是否必选
            type: 'choice', //类型: choice: 选择,input:单行输入,textarea: 多行输入
            multiple: 1, //能选几个: 1单选，0: 多选，x: 可选x个
            answer: undefined, //用于回显的数据，例如：0则显示'25以下'
            options: [
                { title: '25以下', idf: 0},
                { title: '25-35岁',  idf: 1},
                { title: '35-45岁', idf: 2},
                { title: '45–55岁', idf: 3},
                { title: '55岁以上', idf: 4}
            ]
        },
        {
            title: '您的性别？', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: undefined,
            options: [
                { title: '男士', idf: 0},
                { title: '女士', idf: 1},
            ]
        },
        {
            title: '您今年是否准备送母亲节礼物？', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: undefined,
            options: [
                { title: '是', idf: 0},
                { title: '否', idf: 1},
            ]
        },
        {
            title: '您选购母亲节礼物优先考虑哪个方面', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: {value: undefined, content: undefined },
            options: [
                { title: '性价比', idf: 0},
                { title: '品牌', idf: 1},
                { title: 'DIY定制专属', idf: 2},
                { title: '其它（填空项）', idf: 3, mark: 'other', required: true },
            ]
        },
        {
            title: '如果您准备送母亲节礼物，礼物价位会选择？', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: undefined,
            options: [
                { title: '100元以下', idf: 0},
                { title: '100–300元', idf: 1},
                { title: '300–500元', idf: 2},
                { title: '500–800元', idf: 3},
                { title: '800元以上', idf: 4}
            ]
        },
        {
            title: '您偏好的母亲节礼物类型是？', 
            required: true, 
            type: 'choice', 
            multiple: 0, 
            answer: undefined,
            options: [
                { title: '饰品（包包、戒指、吊坠等）', idf: 0},
                { title: '美妆护肤品（口红、精华、面膜等）', idf: 1},
                { title: '生活用品（乳胶枕、梳子、泡脚桶等）', idf: 2},
                { title: '养生保健品（蜂蜜、口服液等）', idf: 3},
                { title: '鲜花（康乃馨、百合等）', idf: 4},
                { title: '食品（零食、水果、牛奶等）', idf: 5},
                { title: '电子产品（手机、平板、投影仪等）', idf: 6},
                { title: '服装（丝巾、衣服、鞋子等）', idf: 7},
                { title: '体检服务', idf: 8},
                { title: '红包', idf: 9},
                { title: '其它（填空项）', idf: 10, mark: 'other', required: true, content: undefined},
            ]
        },
        {
            title: '按照您上题选择的品类，您希望选择具体何种商品作为母亲节礼物？', 
            required: true, 
            type: 'input', 
            multiple: 0, 
            answer: [],
            options: [
                {idf: 0, required: true, content: undefined },
                {idf: 1,  content: undefined},
                {idf: 2,  content: undefined},
            ]
        },
        {
            title: '您偏好在什么渠道购买母亲节礼物', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: undefined,
            options: [
                { title: '线下门店', idf: 0},
                { title: '线上官方平台', idf: 1},
                { title: '淘宝、京东、天猫等线上平台', idf: 2},
                { title: '抖音、快手等直播平台', idf: 3},
            ]
        },
        {
            title: '相比上述几个购买渠道，母亲节商品G优平台提供多少折扣力度，您会优先考虑在G优平台进行购买母亲节礼物？', 
            required: true, 
            type: 'choice', 
            multiple: 1, 
            answer: undefined,
            options: [
                { title: '1折', idf: 0},
                { title: '2折', idf: 1},
                { title: '3折', idf: 2},
                { title: '4折', idf: 3},
                { title: '5折', idf: 4},
                { title: '6折', idf: 5},
                { title: '7折', idf: 6},
                { title: '8折', idf: 7},
                { title: '9折', idf: 8},
            ]
        },
        {
            title: '您对G优母亲节活动还有哪些建议吗？（填空）', 
            required: true, 
            type: 'textarea', 
            multiple: 1, 
            answer: undefined,
            options: [
                {idf: 0},
            ]
        },
    ]
    /*
    //单个输入
    {
        title: '您对G优母亲节活动还有哪些建议吗？（填空）', 
        required: true, 
        type: 'input', 
        multiple: 1,
        answer: undefined,
        options:[]
    },
    */
}

export {
    sequenceKeys,
    QuestionType,
    libData
}