import React, { Fragment, memo } from "react"
import { InfiniteScroll, PullToRefresh, DotLoading, Empty } from "antd-mobile"
import "./index.scss"

/*
    // 基本用例
    <ListView 
        dataSource={list}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
        renderItem={renderItem}
        hasMore={hasMore}
        onRefresh={()=>{
            currentPage.current = 1
            loadData()
        }}
        loadMore={loadMore}
    />

    //或
    <ListView 
        hasMore={hasMore}
        onRefresh={()=>{
            currentPage.current = 1
            loadData()
        }}
        loadMore={loadMore}
    >
        {
            list.map((item,index)=> {
                return renderItem(item,index)
            })
        }
    </ListView>

*/

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  )
}

const ListView = (props) => {
    const {
            dataSource=[], 
            renderHeader,
            renderItem, 
            renderFooter,
            onRefresh, 
            loadMore, 
            threshold,
            infiniteContent,
            hasMore=false, 
            isLoading,
            emtyImg=<img style={{width: 185}} src={require("images/queshengye/pic_wushuju.png")}/>,
            emtyDesc='没有更多内容',
            emtyTop='30%'
        } = props

    return (
        <div className="ant-list-view">
            <PullToRefresh 
              onRefresh={onRefresh} disabled={onRefresh ? false : true}
              headHeight={70}
              threshold={100}
              pullingText={
                <img 
                  src={require('../../images/queshengye/new-loading.gif')}
                  style={{height: 50}} alt=''
                />
              }
              refreshingText={
                <img
                  src={require('../../images/queshengye/new-loading.gif')}
                  style={{height: 50}} alt=''
                />
              }
              canReleaseText={
                <img
                  src={require('../../images/queshengye/new-loading.gif')}
                  style={{height: 50}} alt=''
                  />
                }
              renderText={null}
            >
                <div className="list-view-content">
                    { renderHeader && renderHeader() }
                    {
                        props.children ? props.children:
                        <div className="list-view-body">
                        {
                            dataSource.length > 0 ? 
                            dataSource.map((item, index) => <Fragment key={index}>{renderItem(item,index,dataSource)}</Fragment>) : 
                            isLoading ? null:
                            <Empty style={{ width: "100%" ,paddingTop: emtyTop}} 
                                image={emtyImg} 
                                description={emtyDesc}
                            />
                        }
                        </div>
                        }
                    { renderFooter && renderFooter() }
                </div>
                {
                    loadMore &&
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={threshold}>
                        {
                            infiniteContent ? <>{hasMore ? infiniteContent() : null}</>:
                            <InfiniteScrollContent hasMore={hasMore} />
                        }
                    </InfiniteScroll>
                }
            </PullToRefresh>
        </div>
    )
}

export default memo(ListView);
