/**
 * @description 积分充值记录
 * @returns /front/member/pointRecharge/rechargeRecord
*/
import React, {useEffect, useState, Fragment} from "react";
import { Header, ListView, ModalAlert } from 'components';
import Service from '../service';
import Styles from './index.module.scss';

export default (props) => {

    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = React.useRef(1)

    useEffect(()=>{
        onRefresh(true);
    },[])
    //加载列表数据
    const onRefresh = async (loading=false) => {
        currentPage.current = 1;
        if(loading) {
            setLoading(true)
        }
        const res = await Service.fetchBuyPointRecord({currentPage: currentPage.current}, loading);
        if(loading) {
            setLoading(false)
        }
        if (res?.data) {
          const { records = [] } = res.data;
          setDataList(records);
          setHasMore(records.length >= Service.PageSize);
        }
      }
  
      const loadMore = async () => {
        const res = await Service.fetchBuyPointRecord({currentPage: currentPage.current + 1}, loading);
        if (res?.data) {
          const { records = []} = res.data;
          setDataList([...dataList, ...records]);
          setHasMore(records.length >= Service.PageSize)
          currentPage.current = currentPage.current + 1;
        }
      }
  
    //退款
    const onRefundMoney = async (item)=> {
        ModalAlert.show({
            title: <span style={{ color: '#3D3D3D' }}>是否确定退款？</span>,
            content: '',
            onAction: async (action, index) => {
                if(action.key == 'confirm') {
                    const res = await Service.rechargeRefund({orderNo: item.tradeNo},true);
                    if(res) {
                        onRefresh();
                    }
                }
                ModalAlert.hide();
            },
            actions: [{key: 'cancel', text: '取消'},{key: 'confirm', text: '确认'}]
        })
        
    } 

    const renderItem = (item) => {
        return (
            <div className={Styles.render_item}>
                <div className={Styles.info}>
                    <p>{item.desc || '积分充值'}</p>
                    <span>{item.rechargeTime}</span>
                </div>
                <div className={Styles.right_info}>
                    <div className={Styles.price}>+{item.rechargePoint}</div>
                    {
                        //orderStatus: 1已充值，4退款中，5已退款
                        item.orderStatus == 1 ?
                        <div className={Styles.tuikuan_btn} onClick={()=>onRefundMoney(item)}>退款</div>:
                        <span>{item.orderStatus == 5 ? '已退款': item.orderStatus == 4 ? '退款中': ''}</span>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={Styles.recharge_pecord_page}>
            <Header midText={'充值记录'} />
            <div className={Styles.page_body}>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    emtyDesc={loading ? '数据加载中...':'暂无数据'}
                    hasMore={hasMore}
                    onRefresh={onRefresh}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}