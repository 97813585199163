/**
 * @description 母亲节相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';

const DataList = {
    status: 1,
    data: [
        {blessingWords: '分享点赞', blessingId: 0},
        {blessingWords: '母亲节快乐快乐快乐快乐快乐快', blessingId: 10},
        {blessingWords: '母亲节到了，愿天下母亲健康长寿。', blessingId: 5},
        {blessingWords: '标签来设置缩放比例', blessingId: 30},
        {blessingWords: '快乐', blessingId: 56},
        {blessingWords: '母亲节快乐母亲节快乐母亲节快乐，快乐快乐', blessingId: 78},
        {blessingWords: '母亲节快乐', blessingId: 108},
        {blessingWords: '快来帮我点赞吧！', blessingId: 9},
        {blessingWords: '爱在G优,感恩母亲节拥抱最美丽的妈妈', blessingId: 1},
        {blessingWords: '希望您每一天都快快乐乐！希望您每一天！', blessingId: 18},
        {blessingWords: '全场活动商品', blessingId: 0},
        {blessingWords: '查看所有祝福', blessingId: 69},
        {blessingWords: '查看所有祝福', blessingId: 1088},
        {blessingWords: '母亲节快乐', blessingId: 998},
        {blessingWords: '妈妈', blessingId: 0},
        {blessingWords: '写下您对妈妈的祝福', blessingId: 10},
    ]
}

const activityCode = 'mothersDay'
class Service {

    /**
     * @description 母亲节活动初始化接口
     * @interface 101327
     * @param {activityCode: 活动编码} params
     * @param { 是否显示加载loading } showLoading
     * @returns {
     *  middleLevelStatus: 中等级商品领取状态,
     *  middleLevelCount: 中等级商品领取数量,
     *  highLevelStatus: 高等级商品领取状态,
     *  highLevelCount: 高等级商品领取数量,
     *  lowLevelStatus: 低等级商品领取状态,
     *  lowLevelCount: 低等级商品领取数量,
     *  multiple: 母亲节商品返积分倍数,
     *  discount: 母亲节商品折扣,
     *  percentage: 商品积分抵扣百分比,
     *  sendBlessingPoints: 留言赠送积分数,
     *  sendInvitePoints: 邀请用户赠送积分数,
     *  currentTime: 当前时间,
     *  startTime: 活动开始时间,
     *  endTime: 活动结束时间,
     *  inviteCount: 邀请成功人数,
     *  inviteCode: 邀请编码,
     *  thumbsUpAmount: 点赞数量,
     *  blessingId: 祝福语id,
     *  blessingWords: 祝福语,
     *  blessingMobile: 祝福发送人手机号,
     *  blessingCreateTime: 祝福语创建时间,
     *  blessingStatus: 祝福语状态0: 未审核 1: 审核通过 2: 审核拒绝,
     *  records: 领取记录列表
     * }
    */
    static getMothersDayData = async (showLoading=true)=> {
        const code = '101327'
        try {
            const params = {
                activityCode: activityCode
            }
            const res = await Services('MainPage', { code }, params, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 获取推荐商品接口
     * @interface 101342
     * @param {*} params
     * @returns
    */
    static getRecommendShopList = async (params={})=> {
        const code = '101342'
        try {
            const body = {
                activityCode: activityCode,
                ...params,
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    
    /**
     * @description 根据祝福语id，获取某条祝福语信息
     * @interface 101343
     * @param {blessingId: 祝福语id, activityCode} 
     * @returns
    */
    static getBlessingsById = async (blessingId)=> {
        const code = '101343'
        try {
            const params = {
                activityCode: activityCode,
                blessingId: blessingId
            }
            const res = await Services('MainPage', { code }, params, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 发送祝福语接口
     * @interface 101336
     * @param { activityCode:活动编码, blessingWords: 祝福语 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static sendBlessings = async (words, showLoading=true)=>{
        const code = '101336'
        try {
            const params = {
                activityCode: activityCode,
                blessingWords: words
            }
            const res = await Services('MainPage', { code }, params, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '发送失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 母亲节活动点赞记录接口
     * @interface 101337
     * @param { activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getThumbsUpList = async (params={})=>{
        const code = '101337'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 邀新列表接口
     * @interface 101340
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params={})=>{
        const code = '101340'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
     /**
     * @description 领取奖励接口
     * @interface 101341
     * @param {activityCode: 活动编码,type: highLevelProduct,middleLevelProduct,lowLevelProduct} params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
     static receiveGift = async (params={}, showLoading=true)=>{
        const code = '101341'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取记录接口
     * @interface 101329
     * @param {activityCode: 活动编码,} params
     * @returns
    */
    static getReceiveGiftList = async (params={})=>{
        const code = '101329'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }


    /**
     * @description 母亲节活动点赞接口
     * @interface 101338
     * @param { activityCode: 活动编码 ,blessingId: 点赞祝福语id } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static postThumbsUp = async (params={}, showLoading=true)=>{
        const code = '101338'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 获取祝福语列表接口
     * @interface 101339
     * @param {activityCode: 活动编码,pageNum,pageSize } params 
     * @param { 是否显示加载loading } showLoading
     * @returns { [{blessingId: 祝福语id, blessingWords: 祝福语, status: 0：未点赞  1：点赞}] }
     */
    static getBlessingsList = async (params={}, showLoading)=> {
        const code = '101339'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            // const res = DataList;
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
}
Service.activityCode = activityCode;
export default Service;