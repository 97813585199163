import React, {useState, useMemo} from 'react';
import styles from '../redTheme.module.scss';
import Services from 'services';
import {Picker} from 'antd-mobile';
import {Marquee} from './marguee';
import Library from 'library';

const {JsBridge, Util, Hooks} = Library;

let Departments = [[
    {label: '绿地大基建集团', value: '绿地大基建集团'}, {label: '绿地城建', value: '绿地城建'}, {label: '绿地城投', value: '绿地城投'},
    {label: '贵州建工', value: '贵州建工'}, {label: '江苏省建', value: '江苏省建'}, {label: '西安建工', value: '西安建工'},
    {label: '天津建工', value: '天津建工'}, {label: '广西建工', value: '广西建工'}, {label: '上海水利', value: '上海水利'},
    {label: '河南公路局', value: '河南公路局'}, {label: '绿地金控', value: '绿地金控'}, {label: '其他', value: '其他'}
]];


export default (props) => {
    const {blessingPojo} = props;
    const [count, setCount] = useState(props.count);
    const [allList, setAllList] = useState(props.allList);
    const [blessingFlag, setBlessingFlag] = useState(props.blessingFlag);
    const [codeName, setCodeName] = useState(props.codeName);
    const [deptName, setDeptName] = useState(blessingPojo.deptName || '');
    const [content, setContent] = useState(blessingPojo.content || '');
    const [isFlower, setIsFlower] = useState(false);

    const MarqueeComponent = useMemo(() => {
        return Marquee;
    }, []);

    const sendFlower = async (defaultStatus) => {
        if (!blessingFlag) {
            if (!deptName) {
                JsBridge.runAction('alert', 'error', ['请选择所属部门']);
                return false;
            }
            if (!codeName) {
                JsBridge.runAction('alert', 'error', ['请填写口令']);
                return false;
            }
            if (!defaultStatus && !content) {
                JsBridge.runAction('alert', 'error', ['请编辑寄语']);
                return false;
            }
            if (!defaultStatus && content.length < 5) {
                JsBridge.runAction('alert', 'error', ['寄语长度不能少于5个字']);
                return false;
            }
            const result = await Services('MainPage', {code: '101190'}, {
                deptName,
                codeName: Util.format.utf16toEntities(codeName),
                content: Util.format.utf16toEntities(content),
                defaultStatus,
                activityCode: 'SEVENONE'
            });
            if (result.status > 0) {
                if (defaultStatus) {
                    setIsFlower(true);
                    setTimeout(() => {
                        setBlessingFlag(true);
                    }, 1000)
                } else {
                    setBlessingFlag(true);
                }
                setAllList([].concat(allList, [result.data.blessingPojo]));
                setCount(count + 1);
                setContent(result.data.blessingPojo && result.data.blessingPojo.content);
                JsBridge.runAction('alert', 'error', ['您的祝福已经送出，可以去抽奖了！']);
            } else {
                setBlessingFlag(false);
            }
        } else {
            JsBridge.runAction('alert', 'error', ['您已经为党送过祝福了，不能重复提交。']);
        }
    }
    const readOnlyProps = {};
    if (blessingFlag) {
        readOnlyProps.readOnly = 'readOnly';
    }
    return (
        <div className={styles.blessWrapper}>
            <div className={styles.blessArea}>
                <div className={styles.blessHead}></div>
                <div className={styles.blessCount}>已经有{count}位用户为党送上了祝福</div>
                <div className={styles.blessList}>
                    <MarqueeComponent list={allList || []}/>
                </div>
            </div>

            <Picker extra="请选择所属部门"
                    columns={Departments}
                    title="请选择所属部门"
                    cols={1}
                    value={[deptName]}
                    onConfirm={(e) => {
                        console.log(e);
                        setDeptName(e && e[0]);
                    }}
                    onDismiss={e => console.log('dismiss', e)}
                    disabled={blessingFlag ? true : false}
            >
                {/*<div className={styles.select} style={!deptName ? {color: '#999999'} : {}}>*/}
                {/*    {deptName || '请选择所属部门'}*/}
                {/*</div>*/}
            </Picker>
            <input type='text' placeholder='请输入口令' value={codeName} {...readOnlyProps}
                   onChange={(event) => setCodeName(event.currentTarget.value)}/>
            <div className={styles.toSend}>
                <textarea maxLength={100} rows={5} placeholder='您原创的寄语才有可能中大奖哦！' {...readOnlyProps}
                          value={Util.format.entitiesToUtf16(content)}
                          onChange={(event) => setContent(event.currentTarget.value)}/>
                <div className={styles.flowerWrap}>
                    <div className={`${styles.sendFlower} ${isFlower ? styles.isActive : ''}`} onClick={(event) => {
                        sendFlower(1);
                    }}></div>
                    <div className={styles.flowerText}>为党献花</div>
                </div>
            </div>
            {/* <button style={{ background: blessingFlag ? '#CDCDCD' : 'linear-gradient(180deg, #E9D1A2 0%, #C49350 100%)' }} onClick={() => {
                sendFlower(1);
            }}>随机生成祝福献给党</button> */}
            <button className={styles.inputWrap} onClick={() => {
                sendFlower(0);
            }}>为党献祝福
            </button>
        </div>

    )
}
