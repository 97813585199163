import GuessDetail from "./guessDetail";
import MyGuess from "./myGuess";
import MatchDetail from "./matchDetail";
import GuessRankingList from "./guessRankingList";
import WorldCupIndex from "./worldCupIndex";
import { NoticeSetting } from "./worldCupIndex/noticeSetting.jsx";
import WorldCupGuessShare from "./worldCupGuessShare";
import WorldCupGuessInvite from "./worldCupGuessInvite";
import RecordDetail from "./recordDetail";

export default {
  GuessDetail,
  MyGuess,
  MatchDetail,
  GuessRankingList,
  WorldCupIndex,
  NoticeSetting,
  WorldCupGuessShare,
  WorldCupGuessInvite,
  RecordDetail
}