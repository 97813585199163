import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import moreImg from './images/arrow-more.svg';

export default () => {
    const { isStaff, staffMenu } = useSelector((state) => {
        return state.user.toJS();
    });
    // const history = useHistory();
    // useEffect(() => {
    //     if (!isStaff) {
    //         history.replace('/front/admin/index/login');
    //     }
    // });
    return <div className={styles.home}>
        <div className={styles.blockGray}></div>
        <div className={styles.blockWhite}>
            {(staffMenu || []).map((item, key) => {
                return <Link to={item.url} className={styles.homeItem}>
                    <div className={styles.flexTitle}>{item.name}</div>
                    <img className={styles.more} src={moreImg} alt='更多' />
                </Link>
            })}
        </div>
    </div>;
};