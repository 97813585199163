import React, {useEffect, useState} from 'react'
import styles from './styles.module.scss'

const SelectAddress = () => {
  const [info, setInfo] = useState({})

  useEffect(() => {
    getInfo()
  }, [])

  const getInfo = () => {
    try {
      const userInfo = localStorage.getItem('user_info')
      userInfo && setInfo(JSON.parse(userInfo))
    } catch (e) {
        console.log(e)
    }
  }

  if (!(info.clientId && info.token)) {
    return null
  }

  return <div className={styles.iframe_box}>
    <iframe
      src={`${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-A/customer/receive-address-unity/index?clientId=${info.clientId}&token=${info.token}&isUnity=1`}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '16px'
      }}
      frameBorder='none'
    >
    </iframe>
  </div>
}

export default SelectAddress