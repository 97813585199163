import React, { Component } from 'react'
import Library from 'library';
import styles from './join.module.scss';
import successImg from './images/successBg.png';
export default (props) => {
    return <div className={`${styles.container} ${styles.background1}`}>
        <div className={styles.successBg}>
            <img src={successImg} alt='背景' />
        </div>
        <div className={styles.safe}>
            <div className={styles.btnWrapper}>
                <div className={styles.inviteButton} onClick={() => {
                    window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
                }}>立即打开G优App</div>
            </div>
        </div>
    </div>
}
