import React, {useEffect, useState, useRef} from "react";
import {Mask, Toast} from "antd-mobile";
import html2canvas from "html2canvas";
import {throttle} from '../../../../library/util/publicFun';
import styles from "./activityShareModal.module.scss";
import QRCode from "qrcode.react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_share_copy from "../../../../images/icon_share_copy.png";
import icon_share_down from "../../../../images/icon_share_down.png";
import icon_share_pyp from "../../../../images/icon_share_pyp.png";
import icon_share_wechat from "../../../../images/icon_share_wechat_1.jpg";
import bgImage from "../images/pic_fenxing2.png";
import bgImage0 from "../images/pic_fenxi.png";
import shareImg1 from '../images/new-share1.png';
import shareImg2 from '../images/new-share2.png';
import Library from "../../../../library";
import Services from "../../../../services";
import { Actions } from 'reduxs';
import vs from '../images/icon_vs.png';

const {JsBridge} = Library;
const ImgMap0 = {
  1: bgImage0,
  2: shareImg1,
  3: shareImg2,
}
const ImgMap1 = {
  1: bgImage,
  2: shareImg1,
  3: shareImg2,
}
const getRandomFn = () => {
  return Math.ceil(Math.random()*3);
}

const ShareModal = ({maskVisible, closeMask, type, matchInfo, inviteCode, isDonation=false}) => {
    const domRef = useRef(null);
    const [shareUrl, setShareUrl] = useState('');
    const [random, setRandom] = useState(1);

    const { 
      homeTeamName,
      visitingTeamName,
      homeTeamFlag,
      visitingTeamFlag,
      number,
      showPoints,
      inviteCodeSelf,
      activityCode
    } = matchInfo || {};

    useEffect(() => {
      if(maskVisible) {
        setRandom(() => getRandomFn());
      }
    }, [maskVisible])

    useEffect(() => {
      const inviteUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/worldCupGuess/worldCupGuessInvite/?activityCode=${activityCode}&shareType=${type}&homeName=${encodeURIComponent(homeTeamName)}&homeFlag=${encodeURIComponent(homeTeamFlag)}&num=${number}&visitName=${encodeURIComponent(visitingTeamName)}&visitFlag=${encodeURIComponent(visitingTeamFlag)}&invitationCode=${inviteCodeSelf}`;

      const shareUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/worldCupGuess/worldCupGuessInvite/?activityCode=worldcup&shareType=${type}&invitationCode=${inviteCode}`;

      setShareUrl(type === 0 ? shareUrl: inviteUrl);
    }, [matchInfo, inviteCode]);

    const recordShareCount = async () => {
      const params = type === 0 ? {}: {number: matchInfo?.number};
      const res = await Services('MainPage', { code: '101280' }, params);
      if(res.status > 0) {
        (res.data.shareCount === 1 && res.data.number) && Toast.show(`分享成功，积分+${showPoints}`);
      }
    }

    const createShareImage = () => {
      return new Promise(async (resolve) => {
        html2canvas(domRef.current, {
          allowTaint: true,
          useCORS: true,
          scrollX: 0,
          scrollY: 0
        }).then(async (canvas) => {
          const result = canvas.toDataURL('image/jpeg', 0.8);
          resolve(result);
        }).catch(() => {
          resolve(false);
        });
      })
    }

    // 生成分享图片
    const getShareImagePath = () => {
      return new Promise(async (resolve) => {
        if (!domRef.current) {
          resolve(false);
        }
        let base64 = await createShareImage();
        if (base64) {
          let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
          if (!res) {
            JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
            resolve(false);
          } else {
            resolve(res.tempFilePath);
          }
        } else {
          resolve(false);
        }
      })
    }

    // 微信分享
    const wxShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
          let filePath = await getShareImagePath();
          window.JsBridge.share({
            text: '',
            icon: filePath,
            link: '',
            title: '',
            platform: 1,
          }).then(function (result) {
            shareToast && shareToast.close();
            isDonation && recordShareCount();
            closeMask && closeMask();
          })
      }
    }, 3000)

    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
        let filePath = await getShareImagePath();
        window.JsBridge.share({
          text: '',
          icon: filePath,
          link: '',
          title: '',
          platform: 2,
        }).then(function (result) {
          shareToast && shareToast.close();
          isDonation && recordShareCount();
          closeMask && closeMask();
        })
      }
    }, 3000);

    // 下载图片
    const downloadImg = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      let filePath = await getShareImagePath();
      if (JsBridge.hasWebViewBridge()) {
        JsBridge.requestPermission("requestAlbum")
        JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
          shareToast && shareToast.close()
          if (res) {
              Toast.show("图片保存成功");
              closeMask && closeMask();
              isDonation && recordShareCount();
          } else {
              Toast.show("图片保存失败")
          }
        })
      } else {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.download = `image-${new Date().getTime()}`
        a.href = filePath
        a.click()
        document.body.removeChild(a)
        shareToast && shareToast.close()
      }
    }, 3000)

    const cancel = () => {
      closeMask();
      isDonation && recordShareCount();
    }

    return (
    <Mask visible={maskVisible} onMaskClick={closeMask} opacity={0.7} style={{
        overflow: 'auto',
        paddingBottom: '10px'
    }}>
      <div className={styles.share_container}>
        <div className={styles.share_body}>
          <div className={styles.share_content} ref={domRef}>
            <div className={styles.share_img_box}>
              {type === 1 && <div className={styles.share_match_info}>
                <div className={styles.share_match_title}>第{number}场</div>
                <div className={styles.share_match_content}>
                  <div className={styles.share_match_name_left}>
                    <span>{homeTeamName}</span>
                    <img className={styles.country_flag} src={homeTeamFlag} alt="" />
                  </div>
                  <div className={styles.share_match_flag}>
                    <img className={styles.vs} src={vs} alt="" />
                  </div>
                  <div className={styles.share_match_name_right}>
                    <span>{visitingTeamName}</span>
                    <img className={styles.country_flag} src={visitingTeamFlag} alt="" />
                  </div>
                </div>
              </div>}
              <img className={styles.share_avatar} src={type === 0 ? ImgMap0[random]: ImgMap1[random]} alt="avatar"/>
            </div>
            <div className={type === 0 ? styles.share_detail: styles.share_detail_match}>
              <QRCode 
                value={shareUrl} 
                size={120}
                fgColor="#000000"
              />
            </div>
          </div>
        </div>
        <div className={styles.opt}>
          <div className={styles.wechat} onClick={wxShare}>
            <img src={icon_share_wechat} alt=""/>
            <span>微信好友</span>
          </div>
          <div className={styles.share} onClick={momentsShare}>
            <img src={icon_share_pyp} alt=""/>
            <span>朋友圈</span>
          </div>
          {/* <CopyToClipboard text={shareUrl}
              onCopy={async () => {
                await Actions.alert.show('复制成功', 1000);
                closeMask && closeMask();
                isDonation && recordShareCount();
            }}>
              <div className={styles.copy}>
                <img src={icon_share_copy} alt=""/>
                <span>复制链接</span>
              </div>
            </CopyToClipboard>
          <div className={styles.download} onClick={downloadImg}>
            <img src={icon_share_down} alt=""/>
            <span>下载图片</span>
          </div> */}
        </div>
        <div className={styles.cancel_btn} onClick={cancel}>
          <span>取消</span>
        </div>
      </div>
    </Mask>
    )
}

export default ShareModal;
