/**
 * @description 获奖名单的跑马灯展示模块
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Toast, Swiper } from 'antd-mobile';
import './index.scss';

const defaultData = process.env.NODE_ENV == 'development' ? [
    {loginName: '177****6789', prizeName:'已领取购物大礼包', prizeType: ''},
    {loginName: '137****6780', prizeName:'已领取购物大礼包', prizeType: ''},
    {loginName: '135****6781', prizeName:'已领取购物大礼包', prizeType: ''},
    {loginName: '137****6782', prizeName:'已领取购物大礼包', prizeType: ''},
    {loginName: '187****6781', prizeName:'已领取购物大礼包', prizeType: ''},
    {loginName: '156****6782', prizeName:'已领取购物大礼包', prizeType: ''},
]:[]

const WinnerSwiper = (props)=> {
    const {
        title='领取名单',
        detail='领取详情 >',
        records= defaultData,
        showCount=6,
        size=22
    } = props

    const swiperRecords = useMemo(()=>{
        if(records.length <= showCount * 2 - 1) {
            return [...records,...records]
        }
        return records
    },[records,showCount])

    const rempx = (size) => {
        return size * (window.innerWidth || 375) / 375
    }

    return (
        <div className='winner-swiper'>
            <div className='title-wrapper' style={{display: 'none'}}>
                <span>{title}</span>
               {
                detail && <div>{detail}</div>
               } 
            </div>
            {
                records && records.length > 0 &&
                <div className='winner-list'>
                    <Swiper
                        style={{ '--height': (size * showCount) + 'px', paddingBottom: size * 0.5 }}
                        direction={'vertical'}
                        allowTouchMove={false}
                        stuckAtBoundary={false}
                        autoplay={ records.length > showCount - 1}
                        // autoplay={false}
                        loop={true}
                        autoplayInterval={1500}
                        slideSize={ Math.floor((100 + 100/showCount * 0.66) /showCount )}
                        indicator={() => null}
                    >
                        {
                            swiperRecords.map(({loginName, prizeName}, index) => (
                                <Swiper.Item key={index}>
                                    <div className="winner-swiper-item">
                                        {`${loginName}：${prizeName}`}
                                    </div>
                                </Swiper.Item>
                            ))
                        }
                    </Swiper>
                    <div className='mask-opacity' style={{height: 0}}></div>
                </div>
            }
            
        </div>
    )
}

export default WinnerSwiper;