import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './index.module.scss';
import seconImg from './images/seconImg.png';
import thirdInnerImg from './images/thirdInnerImg.png';
import fourInnerImg1 from './images/fourInnerImg1.png';
import fourInnerImg2 from './images/fourInnerImg2.png';
import fourInnerImg3 from './images/fourInnerImg3.png';
import rightImg1 from './images/rightImg1.png';
import rightImg2 from './images/rightImg2.png';
import rightImg3 from './images/rightImg3.png';
import smaLeft1 from './images/smaLeft1.png';
import smaLeft2 from './images/smaLeft2.png';
import sixPartBackground from './images/sixPartBackground.png';
import TopNavigation from '../topNavigation';
import Library from 'library';
const { JsBridge } = Library;

export const KnowUs = (props) => {
   const [height, setHeight] = useState(null);
   useEffect(() => {
      console.log("打印下");
      getStatusBarHeight();
   }, []);
   const getStatusBarHeight = async () => {
      console.log("进入函数1");
      const { height } = await JsBridge.getStatusBarHeight();
      console.log("执行了上述代码");
      console.log("打印下状态栏的高度", height);
      setHeight(height);
   }

   return <div className={styles.container}>
      <TopNavigation title='了解我们' />
      <div className={styles.header}>

      </div>
      <div className={styles.secondKnowPart}>
         <div className={styles.secondKnowPartInner}>
            <span className={styles.smallEleText1}>公司简介</span>
            <span className={styles.smallEleText2}>Company profile</span>
            <img src={seconImg} className={styles.seconImg} />
            <div className={styles.longText}>
               <span className={styles.specialTextWeight}>绿地金创科技集团有限公司</span>（简称为“绿地金创”或“绿地金创科技集团”）是一家全球经营的新兴产业集团，总部设立于中国上海，是世界500强企业—绿地集团旗下聚焦数字科技、金融、教育、康养、科创、物业商管等多元产业推进数字化升级的核心载体。自绿地金融控股集团2011年成立起，经过10余年努力，绿地金创将传承绿地金融互联网化、数字驱动的金融全牌照全产业链布局，通过牌照的有机整合，强化“产业+金融”的协同效应，以数字科技手段重塑集团产业内核，将不断完善“投资+孵化+风控”平台，打造集数据、科技、金融及场景于一体的年利润百亿级的国际化、多元化综合产业集团。
               <div className={styles.smallLineBot}></div>
            </div>
         </div>


      </div>
      <div className={styles.thirdBigPart}>
         <div className={styles.thirdBigPartInner}>
            <span className={styles.innerElement1}>G优家族</span>
            <span className={styles.innerElement2}>G family office</span>
            <img src={thirdInnerImg} className={styles.thirdInnerImg} />
            <span className={styles.botSmallText}>
               <span className={styles.firstTitleText}>G优家族办公室</span>是绿地金创财富管理板块的重要一环，是依托绿地集团强大的产业背景以及绿地金创资产管理和财富管理板块深厚的资源优势而构建的全方位一站式家族财富管理和服务平台。
            </span>
         </div>
      </div>
      <div className={styles.fourBigPart}>
         <span className={styles.fourInnerElement1}>
            G优家族办公室家族财富管理目标
         </span>
         <span className={styles.fourInnerElement2}>
            Office family wealth management goals
         </span>
         <span className={styles.fourInnerElement3}>
            <span className={styles.fourModalBox}>
               <img src={fourInnerImg1} className={styles.fourInnerImg1} />
               <span className={styles.fourBotDesc}>
                  家族财富的保护
               </span>
            </span>
            <span className={styles.fourModalBox}>
               <img src={fourInnerImg2} className={styles.fourInnerImg1} />
               <span className={styles.fourBotDesc}>
                  家族财富的管理
               </span>
            </span>
            <span className={styles.fourModalBox}>
               <img src={fourInnerImg3} className={styles.fourInnerImg1} />
               <span className={styles.fourBotDesc}>
                  家族财富的传承
               </span>
            </span>
         </span>
         <span className={styles.fourInnerElement4}>
            <span className={styles.botColorText1}>
               <span className={styles.smallCirlcleText}>· </span>
               <span className={styles.ffdescribeText}>短期目标：家族财富的系统性安全</span>
            </span>
            <span className={styles.botColorText2}>
               <span className={styles.smallCirlcleText}>· </span>
               <span className={styles.ffdescribeText}>长期目标：家族基业长青</span>
            </span>
         </span>
      </div>
      <div className={styles.fiveBigPart}>
         <div className={styles.fiveBigPartInner}>
            <span className={styles.fiveInnerElement1}>G优家族办公室家族财富管理方案</span>
            <span className={styles.fiveInnerElement2}>Office family wealth management program</span>
            <div className={styles.fiveThreeModal}>
               <span className={styles.fiveInnerModal1}>
                  <span className={styles.fModalHead}>
                     一、家族财富管理的架构设计
                  </span>
                  <span className={styles.fModalContent}>
                     <span className={styles.fModalContentLeft}>
                        <span className={styles.leftInnerText}>
                           如何持有各类财富
                        </span>
                     </span>
                     <span className={styles.fModalContentRight}>
                        <img src={rightImg1} className={styles.rightImg1} />
                     </span>

                  </span>
               </span>

               <span className={styles.fiveInnerModal1}>
                  <span className={styles.fModalHead}>
                     二、解决方案围绕核心价值
                  </span>
                  <span className={styles.fModalContent}>
                     <span className={styles.fModalContentLeft}>
                        <span className={styles.leftInnerText}>
                           “守”、“管”、“传”目标的实现
                        </span>
                     </span>
                     <span className={styles.fModalContentRight}>
                        <img src={rightImg2} className={styles.rightImg1} />
                     </span>

                  </span>
               </span>

               <span className={styles.fiveInnerModal1}>
                  <span className={styles.fModalHead}>
                     三、实现方式依赖各种平台和工具的有效使用
                  </span>
                  <span className={styles.fModalContent}>
                     <span className={styles.fModalContentLeft}>
                        <span className={styles.leftInnerText}>
                           家族办公室平台和金融工具、结构化工具、家族意愿安排、家族协议等法律工具
                        </span>
                     </span>
                     <span className={styles.fModalContentRight}>
                        <img src={rightImg3} className={styles.rightImg1} />
                     </span>

                  </span>
               </span>


            </div>
         </div>
      </div>
      <div className={styles.sixBigPart}>
         {/* <img src={sixPartBackground} className={styles.sixPartBackground} /> */}
         <span className={styles.sixPartElement1}>联系我们</span>
         <span className={styles.sixPartElement2}>Contact us</span>
         <div className={styles.sixPartElement3}>
            <img src={smaLeft1} className={styles.smaLeft1} />
            <span className={styles.smaText}>电话：021-53011225-816</span>
         </div>
         <div className={`${styles.sixPartElement3} ${styles.extraMargin}`} >
            <img src={smaLeft2} className={styles.smaLeft1} />
            <span className={styles.smaText}>地址：上海市黄浦区龙华东路868号1508室</span>
         </div>
         {/* <div>地址：上海市黄浦区龙华东路868号1508室</div> */}
      </div>
   </div>
}
