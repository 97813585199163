/**
 * @description 母亲节分享链接页面
 * @returns /front/activities/mothersDay/landingPage
*/
import React, { useState } from "react";
import './index.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import Library from 'library';

const appSchema = 'gktapp://com.gkewang';

const LandingPage = () => {
    // 获取链接携带参数
    const params = Library.Util.url.paramsToObj();

    const [maskShow, setMaskShow] = useState(false);
    const targetUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/mothersDay?${Library.Util.url.objectToQueryParams(params)}`;

    const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setMaskShow(true);
        } else {
            window.location.href = launchUrl;
            setTimeout(() => {
                window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
            }, 3000);

        }
    }

    return (
        <div className="share-download-app-page">
            <img src={require('images/mothersDay/download-app-page.png')} alt="" />
            <div className='download-btn' onClick={() => openApp()}></div>
            {
                maskShow && 
                <div className='mask' onClick={() => setMaskShow(false)}>
                    <img src={gotoBrowserImg} alt="" />
                </div>
            }
        </div>
    )
}

export default LandingPage;