import React, {useEffect, useRef, useState} from 'react';
import Library from "../../../library";
import styles from './yuYueActyList.module.scss'
import ListView1 from "../../../components/ListView1";
import Services from "../../../services";
import {isEmptyString} from "../../../library/util";
import EmptyView from "../../../components/emptyView";
import NoticeUtil from "../../../library/util/NoticeUtil";
const kRefreshActyNotification = 'kRefreshActyNotification';
const {JsBridge} = Library;
export const YuYueActyList = (props) => { //预约活动列表
    const {userAccount = ''} = Library.Util.url.paramsToObj();
    const [data, setData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [initStatus, setInitStatus] = useState(false);
    const {focusStatus} = props;


    useEffect(() => {
        NoticeUtil.addListener(kRefreshActyNotification,addListener);
        if (!initStatus && focusStatus) {
            setInitStatus(true);
            !isEmptyString(props.userAccount) && getActysData();
        }
        return () => {
            NoticeUtil.removeListener(kRefreshActyNotification, addListener);
        }
    }, [initStatus, focusStatus, props.userAccount])

    const addListener = () => {
        getActysData();
    }

    const getActysData = async () => {
        if (!isEmptyString(userAccount)) {
            const result = await Services('MainPage', {code: 500011}, {
                pageNum: 0,
                pageSize: 1000,
                customerAccount: userAccount
            }, false);
            if (result.status > 0) {
                setData(result?.data?.guHomeOfficeActivityVOPage?.content || [])
                setIsLoad(true)
            }
        }
    }

    const goYuYue = (item) => { //去预约
        // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${item.id}&activityName=${item.activityName}&userAccount=${userAccount}&isNowActy=1`});
        let data = {
            activityId: item.id,
            activityName: item.activityName,
            userAccount: userAccount,
            isNowActy: '1',
        }
        JsBridge.push('GoAppointment', data)
        JsBridge.addListener().then((result) => {
            getActysData();
        });
    }

    const btnStatus = (item) => {
        let registrationState = item.registrationState || '';

        let txt = '去预约';
        let opacity = 1;
        if (registrationState === 'UNREGISTERED') { //未报名
            txt = '去预约';
            opacity = 1;
        } else if (registrationState === 'REGISTERED') { //已报名/预约成功
            txt = '已预约';
            opacity = 0.6;
        }
        let betweenRegistrationTime = item.betweenRegistrationTime || false;

        if (!betweenRegistrationTime) {
            txt = '去预约';
            opacity = 0.6;
        }

        return <div className={styles.aptmt_acty_selection_list_item_btn}
                    style={{opacity: opacity}}
                    onClick={() => goYuYue(item)}>{txt}</div>
    }

    const itemView = () => {
        return data.map((item, index) => {
            return (
                <div className={styles.aptmt_acty_selection_list_item} key={index.toString()}>
                    <img src={item.activityPic || require('./images/pic_moren.png')}/>
                    <div className={styles.aptmt_acty_selection_list_item_content}>
                        <p>{item.activityName || ''}</p>
                        <p>{`${item.activityRegistrationStartTime}--${item.activityRegistrationEndTime}`}</p>
                    </div>
                    {btnStatus(item)}
                </div>
            )
        })
    }

    return (
        <div
            className={styles.apty_yuyue_container}>
            {data.length > 0 ? itemView() : (isLoad ? <EmptyView height={'80vh'}/> : null)}
            <OldActyComponent userAccount={userAccount}/>
        </div>
    )
}

const OldActyComponent = (props) => { //以往的活动
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        !isEmptyString(props.userAccount) && loadData();
    }, [props.userAccount])
    const loadData = async (isRefresh) => {
        if (isRefresh) {
            pageNum.current = 0;
        }
        let params = {
            'pageSize': pageSize,
            'pageNum': pageNum.current,
            customerAccount: props.userAccount || '',
        };
        const res = await Services('MainPage', {code: 500012}, params);
        if (res.status > 0) {
            const {guHomeOfficeActivityVOPage = {}} = res.data;
            let newArr = [];
            let list = guHomeOfficeActivityVOPage?.content || [];
            if (pageNum.current === 0) {
                newArr = [...list];
            } else {
                newArr = [...data, ...list];
            }
            setData(newArr);
            setIsLoad(true);
            setHasMore(list.length >= pageSize);
        }
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData(false);
    }

    const itemClick = (item) => { //以往的活动
        // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${item.id}&activityName=${item.activityName}&userAccount=${props.userAccount}&isNowActy=0`});
        let data = {
            activityId: item.id,
            activityName: item.activityName,
            userAccount: props.userAccount,
        }
        JsBridge.push('GoAppointment', data)
    }

    const listItem = (item) => {
        return (
            <div className={styles.aptmt_acty_selection_list_item} style={{marginLeft: '3%'}}>
                <img src={item.activityPic || require('./images/pic_moren.png')}/>
                <div className={styles.aptmt_acty_selection_list_item_content}>
                    <p>{item.activityName || ''}</p>
                    <p>{item?.activityRegistrationStartTime + '--' + item?.activityRegistrationEndTime}</p>
                </div>
                <div className={styles.aptmt_acty_selection_list_item_btn}
                     onClick={() => itemClick(item)}>查看详情
                </div>
            </div>
        )
    }

    return (
        <div className={styles.aptmt_old_acty}>
            {data.length > 0 ? <p className={styles.aptmt_old_acty_des}>以往活动</p> : null}
            {data.length > 0 ? <ListView1
                onRefresh={() => loadData(true)}
                data={data}
                hasMore={hasMore}
                isLoad={isLoad}
                loadMore={loadMore}
                ListItem={({item}) => listItem(item)}
            /> : null
            }
        </div>
    )
}
