import React, { useEffect, useState, useRef } from 'react';
import Library from 'library';
import styles from './index.module.scss';
import selectMore from 'images/icon/select-more@2x.png';
import Services from 'services';

const { JsBridge, Util } = Library;
export const EditAddress =(props) => {
    const { id, deliveryAddressId, endDate, activityCode } = Util.url.paramsToObj();
    const [inputInfo, setInputInfo] = useState({ name: '', consigneeNumber: '', deliveryAddress: '', location: [] });
    const inputRef = useRef(null);
    useEffect(() => {
        initData();
        setTimeout(() => {
            inputRef.current.focus();
        }, 1000);
    }, []);
    const initData = async () => {
        if (deliveryAddressId) {
            const res = await Services('MainPage', { code: '101314' }, { id });
            if (res.status > 0) {
                const data = res.data;
                setInputInfo({
                    name: data.name,
                    consigneeNumber: data.consigneeNumber,
                    deliveryAddress: data.deliveryAddress,
                    location: [data.proviceId, data.cityId, data.areaId]
                });
            }
        }
    }

    const submit = async () => {
        const params = {
            ...inputInfo, id, activityCode,
            provice: inputInfo.location[0],
            city: inputInfo.location[1],
            area: inputInfo.location[2],
        };
        if (!inputInfo.name) {
            JsBridge.runAction('alert', 'error', ['请填写收件人']);
            return;
        }

        if (!Library.Util.validate.mobile(inputInfo.consigneeNumber)) {
            JsBridge.runAction('alert', 'error', ['请填写正确的手机号']);
            return;
        }
        if (inputInfo.location.length < 3) {
            JsBridge.runAction('alert', 'error', ['请填写所在区域']);
            return;
        }
        if (!inputInfo.deliveryAddress) {
            JsBridge.runAction('alert', 'error', ['请填写收件地址']);
            return;
        }
        const res = await Services('MainPage', { code: '101312' }, params);
        if (res.status > 0) {
            JsBridge.runAction('alert', 'error', ['保存成功！']);
            JsBridge.close();
        }
    }

    const isEffect = endDate && new Date(decodeURIComponent(endDate).replace(/-/g, '/')).getTime() > new Date().getTime();
    return <div className={styles.container}>
        <div className={styles.inputGroup}>
            <label htmlFor='name'>收货人</label>
            <div className={styles.inputLine}>
                <input ref={inputRef} onChange={(event) => {
                    inputInfo.name = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.name} id='name' type='text' placeholder='姓名' disabled={!isEffect} />
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='mobile'>手机号</label>
            <div className={styles.inputLine}>
                <input onChange={(event) => {
                    inputInfo.consigneeNumber = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.consigneeNumber} id='mobile' type='tel' placeholder='输入联系方式' disabled={!isEffect} />
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='location'>所在区域</label>
            <div onClick={() => {
                if (isEffect) {
                    JsBridge.runAction('locationPicker', 'show', []).then(e => {
                        inputInfo.location = e;
                        setInputInfo({ ...inputInfo })
                    })
                }
            }} className={styles.inputLine}>
                <input readOnly value={inputInfo.location?.join(' ')} id='location' type='text' placeholder='输入所在区域' />
                <img className={styles.selectMore} src={selectMore} alt='下拉箭头' />
            </div>
        </div>
        <div className={styles.inputGroup}>
            <label htmlFor='address'>详细地址</label>
            <div className={styles.inputLine}>
                <input onChange={(event) => {
                    inputInfo.deliveryAddress = event.target.value;
                    setInputInfo({ ...inputInfo })
                }} value={inputInfo.deliveryAddress} id='address' type='text' placeholder='输入详细地址' disabled={!isEffect} />
            </div>
        </div>
        {isEffect ? <div className={styles.inputGroup}>
            <button onClick={submit} className={styles.button}>保存</button>
        </div> : false}

    </div>
}
