import React from 'react';
import goufang from './images/privilege/icon-Real Estate@2x.png';
import jiudian from './images/privilege/icon-Hotel@2x.png';
import chaoshi from './images/privilege/icon-Shopping@2x.png';
import zuanshi from './images/privilege/icon-Diamond@2x.png';
import lvyou from './images/privilege/icon-Trip@2x.png';
import licai from './images/privilege/icon-Financial Management@2x.png';
import caifu from './images/privilege/icon-Private Wealth@2x.png';
import kangyang from './images/privilege/icon-Wellness@2x.png';
import qichebaoyang from './images/privilege/icon-Car@2x.png';
import jiaoyu from './images/privilege/icon-Education@2x.png';
import daikuan from './images/privilege/icon-Petty Loan@2x.png';
import more from './images/privilege/icon-More@2x.png';

import goufangInvalid from './images/privilege/icon-Real Estate@2x.png';
import jiudianInvalid from './images/privilege/icon-Hotel@2x.png';
import chaoshiInvalid from './images/privilege/icon-Shopping@2x.png';
import zuanshiInvalid from './images/privilege/icon-Diamond@2x.png';

import goufangImg1 from './images/goufang/01.png';
import jiudianImg1 from './images/jiudian/01.png';

import gouwuImg1 from './images/gouwu/01.jpg';
import gouwuImg2 from './images/gouwu/02.jpg';

import lvyouImg1 from './images/lvyou/01.jpg';
import kangyangImg1 from './images/kangyang/640.png';

import licaiImg1 from './images/licai/640.jpg';

import jiaoyuImg1 from './images/jioayu/00.jpg';
import jiaoyuImg2 from './images/jioayu/01.jpg';
import jiaoyuImg3 from './images/jioayu/03.jpg';

const goucheImg1 = 'https://icon.gkewang.com/gu/weapp/user/gouche/01.jpg?v=20200916131120';


export const vipConfig = {
    vip: {
        privileges: [
            {
                icon: goufang, name: '最高优惠20万', link: 'privilege/0', tag: '购房', sub: 'Real Estate', sort: 1,
                content: <img src={goufangImg1} alt='goufangImg1' />,
            },
            {
                icon: jiudian, name: '8.5折市场最低价', link: 'privilege/1', tag: '酒店', sub: 'Hotel',
                actionName: '去度假', sort: 2,
                action: 'https://mp.weixin.qq.com/s/ADZ6doSk9_59Gp3ioNkIcQ',
                content: <img src={jiudianImg1} alt='jiudianImg1' />
            },
            {
                icon: chaoshi, name: '会员日9折优惠', link: 'privilege/2', title: 'Gsuper超市', tag: '购物', sub: 'shopping', sort: 7,
                actionName: '去购物',
                action: 'https://mp.weixin.qq.com/s/aXwk-FLGSfdGoU0kPJWr8Q',
                content: <><img src={gouwuImg1} alt='gouwuImg1' /><img src={gouwuImg2} alt='gouwuImg2' /></>
            },
            {
                icon: zuanshi, name: '钻石8.5折优惠', link: 'privilege/2', title: '钻石', sub: 'Diamond', sort: 8
            },
        ]
    },
    temp: {
        privileges: [
            { icon: goufangInvalid, name: '最高优惠20万', link: 'privilege/0', tag: '购房', sub: 'Real Estate', sort: 1 },
            { icon: jiudianInvalid, name: '8.5折市场最低价', link: 'privilege/1', tag: '酒店', sub: 'Hotel', sort: 2 },
            { icon: chaoshiInvalid, name: '会员日9折优惠', link: 'privilege/2', tag: '购物', sub: 'shopping', title: 'Gsuper超市', sort: 7 },
            { icon: zuanshiInvalid, name: '钻石8.5折优惠', link: 'privilege/2', tag: '钻石', sub: 'Diamond', sort: 8 },
        ]
    },
    base: {
        privileges: [
            {
                icon: lvyou, name: '专属优惠价', link: 'privilege/3', tag: '旅游', sub: 'Trip',
                actionName: '去度假', sort: 3,
                action: 'https://mp.weixin.qq.com/s/ADZ6doSk9_59Gp3ioNkIcQ',
                content: <img src={lvyouImg1} alt='lvyouImg1' />
            },
            {
                icon: licai, name: '产品优先认购权', link: 'privilege/4', tag: '理财', sub: 'Financial management', sort: 5, title: '金融理财',
                actionName: '去理财',
                action: 'https://mp.weixin.qq.com/s/QRXPzTrbSGq1_g-SN7evzA',
                content: <img src={licaiImg1} alt='licaiImg1' />

            },
            {
                icon: caifu, name: '专业管家服务', link: 'privilege/4', title: '私人财富', sub: 'Private Wealth', sort: 6
            },
            {
                icon: daikuan, name: '优惠贷款利率', link: 'privilege/4', title: '小额贷款', sub: 'Petty Loan', sort: 10
            },
            {
                icon: kangyang, name: '名医诊疗8.5折', link: 'privilege/5', tag: '康养', sub: 'Wellness', sort: 11,
                actionName: '去康养',
                action: 'https://mp.weixin.qq.com/s/ZUeuzP83w0_-4zQAoOve-Q',
                content: <img src={kangyangImg1} alt='kangyangImg1' />,
            },
            {
                icon: qichebaoyang, name: '终身享6折优惠', link: 'privilege/6', tag: '汽车', sub: 'Car',
                actionName: '去购车', sort: 9,
                action: 'https://mp.weixin.qq.com/s/DC_KdDsXgBsLdInPUQk_8w',
                content: <img src={goucheImg1} alt='goucheImg1' />,
                title: '汽车保养'
            },
            {
                icon: jiaoyu, name: '课程9折优惠', link: 'privilege/7', tag: '教育', sub: 'Education', sort: 4,
                content: <><img src={jiaoyuImg1} alt='jiaoyuImg1' /><img src={jiaoyuImg2} alt='jiaoyuImg2' /><img src={jiaoyuImg3} alt='jiaoyuImg3' /></>
            },
            {
                icon: more, name: '敬请期待', sub: 'More', title: '更多服务', disabled: true, sort: 10000
            }
        ]
    }
};