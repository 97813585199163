/**
 * @description 活动规则弹框
 * @param {btnStyle: 按钮样式,onClose: function }
*/
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Mask } from 'antd-mobile';
import './rulesModal.scss';

const RulesModal = ({ onClose = () => {}, btnStyle = {}, visible, children, content }) => {
  const [_visible, setVisible] = useState(true);

  useEffect(() => {
    if (visible) {
      setVisible(true);
    } else {
      onCancel();
    }
  }, [visible]);

  const onCancel = () => {
    setVisible(false);
    setTimeout(onClose, 1000);
  }

  return (
    <Mask visible={_visible} onMaskClick={onCancel}>
      <div className='goddess-rules-modal'>
        <div className="goddess-modal-container">
          <div className="goddess-modal-title">活动规则</div>
          <div className="goddess-modal-content">
            {children || content}
          </div>
          <div className="goddess-rules-btn" style={{...btnStyle}} onClick={onCancel}>我知道了</div>
        </div>
      </div>
    </Mask>
  )
}

RulesModal.open = ({btnStyle={}, children, content }) => {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const close = () => div.remove();
  ReactDOM.render(<RulesModal 
    btnStyle={btnStyle} 
    onClose={close}
    children={children}
    content={content}
    visible
  />, div);
}

export default RulesModal;