/**
 * @description 2023双十一活动
 * @returns route: /front/activities/double11
*/

import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { Header,RulesModal } from 'components';
import Library from 'library';
import ActivityShareModal from 'components/activityShareModal';
import Service from '../service';
import Interceptor from '../components/interceptor';
import PopupModal from "../components/popupModal";
import WinnerSwiper from '../components/winnerSwiper';
import GiftList from '../components/giftList';
import ShopRecommend from '../components/shopRemmend';
import PopupShare from '../components/popupShare';
import ShareModal from "../components/shareModal";
import ReceiveGiftModal from "../components/receiveGiftModal";
import InviteSuccess from '../inviteSuccess';
import Styles from './styles.module.scss';

const { JsBridge } = Library;
export { InviteSuccess }

export default (props)=> {

    const {clientId} = Library.Util.url.paramsToObj();

    const [statusHeight, setStatusHeight] = useState(JsBridge.hasWebViewBridge() ? -120: 20);
    const [activityInfo, setActivityInfo] = useState({})
    const [shareConfig, setShareConfig] = useState({})

    const getStatusHeight = async () => {
        let {height} = await JsBridge.getStatusBarHeight();
        setStatusHeight(height);
    }

    useEffect(()=>{
        getStatusHeight();
        if(clientId) {
            Service.shareActivityTask({clientId});
        }
        loadData()
    },[])

    const loadData = async ()=>{
        await refeshData(true)
        const result = await Service.getShareConfig()
        if(result?.data) {
            setShareConfig(result.data)
        }else {
            setShareConfig({status: 0})
        }
    }

    const refeshData = async (showLoading = false)=> {
        const result = await Service.getInitData(showLoading)
        
        if(result?.data) {
            setActivityInfo(result.data)
        }
        return result?.data

    }

    // 活动规则
    const onRuleClick =()=>{
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)'},
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }
    // 活动分享
    const onActivityShareClick = ()=>{
        PopupShare.open({
            activityCode: Service.activityCode, 
            inviteCode: activityInfo.inviteCode,
            shareConfig
        });
    }

    // 邀新
    const onInvite = ()=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        ShareModal.open({activityCode: Service.activityCode, inviteCode: activityInfo.inviteCode })
        /*
        const param = {
            ic: activityInfo.inviteCode,
            ac: Service.activityCode
        }
        const shareUrl = `${window.location.origin}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
        ActivityShareModal.open({
            shareUrl,
            children: <img className={Styles.shareInvite} src={require('images/magpieFestival/qiXishareBg.png')} alt="bg" />,
        })
        */
    }

    //领取奖励
    const onReceive = async (st)=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        // 查询库存
        const res = await Service.queryGiftStock({skuId: st.goodsId})
        if(!res) return;
        if(res.data) {
            // 有库存
            ReceiveGiftModal.open({
                prizeInfo: {},
                callBack: async ()=> {
                    const result = await Service.receiveGift({skuId: st.goodsId});
                    if(result) {
                        setTimeout(()=>{
                            refeshData()
                        },500)
                        return result;
                    }
                    return false;
                }
            })
        }else {
            ReceiveGiftModal.open({})
            setTimeout(()=>{
                refeshData()
            },500)
        }
        
    }
    // 邀新记录
    const onInviterRecord = ()=> {
        PopupModal.open(Service.activityCode);
    }
    // 领取记录
    const onReceiveRecord = ()=> {
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&idf=commonSub` });
        } else {
            props.history.push(`/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&&idf=commonSub`)
        }
    }

    return (
        <div className={Styles.double11Page}>
            <div className={Styles.double11Page__wraper}>
                {
                    JsBridge.hasWebViewBridge() &&
                    <div
                        className={Styles.navHeader}
                        style={{marginTop: statusHeight + 5 + 'px'}}
                    >
                        <img src={require('images/double11/back.png')} alt=''
                            onClick={() => {
                                JsBridge.close();
                            }}
                        />
                    </div>
                }
                <div className={Styles.bgTop}>
                    <div className={Styles.activityBtns}>
                        <div onClick={onRuleClick}>活动规则</div>
                        {
                            shareConfig.status === 1 &&
                            <div onClick={onActivityShareClick}>分享活动</div>
                        }
                    </div>
                </div>
                {/* 邀新送好礼 */}
                <div className={Styles.center}>
                    <div className={Styles.gift_decs}>
                        <p>奖品四选一，每人仅可领取一次！</p>
                        <p>每成功邀新一人再送200积分</p>
                    </div>
                    <GiftList activityInfo={activityInfo} onReceive={onReceive} onInvite={onInvite}/>
                    <div className={Styles.receive_record}>
                        <WinnerSwiper 
                            records={activityInfo.prizeRecord} 
                            onInviterRecord={onInviterRecord}
                            onReceiveRecord={onReceiveRecord}
                        />
                    </div>
                </div>
                {/* 商品推荐 */}
                <ShopRecommend />
                {/* 底部logo */}
                <div className={Styles.footer_logo}>
                    <img src={require('images/double11/bottom-logo.png')} alt="" />
                </div>
                <Interceptor />
            </div>
        </div>
    )

}