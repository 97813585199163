/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */

export const getDistanceTime = (startTime, currentTime, second) => {
    if(!currentTime||!startTime) return
    var current = Date.parse(currentTime.replace(/-/g, '/')) /*replace将时间字符串中所有的'-'替换成'/',parse将时间格式的字符串转换成毫秒*/
    var start = Date.parse(startTime.replace(/-/g, '/'))
    var distance = start - current - second*1000
    var day = 0
    var hour = 0
    var minute = 0
    var second = 0
    day = Math.floor(distance / 1000 / 60 / 60 / 24)
    hour = Math.floor((distance / 1000 / 60 / 60) % 24)
    minute = Math.floor((distance / 1000 / 60) % 60)
    second = Math.floor((distance / 1000) % 60)
    const str = `00${hour}`.slice(-2) + ':' + `00${minute}`.slice(-2)+ ':' + `00${second}`.slice(-2)
    const done = distance<=0
    console.log('distance:'+distance)
    return {
        done,
        str
    }
  }
