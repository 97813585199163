/**
 * @description 积分充值模块
*/
import React, { useState, useEffect, Fragment } from 'react';
import { Toast } from 'antd-mobile';
import { ModalAlert } from 'components';
import Service from "../../service";
import Styles from './index.module.scss';

const Recharge = (props) => {
    
    const { dataSource={} } = props;

    const [selectItem, setSelectItem] = useState();
    const pageRef = React.useRef();

    useEffect(()=>{
        if(!selectItem && dataSource.list?.length) {
            const item = dataSource.list[0];
            item.select = 0;
            setSelectItem(item);
        }
    },[dataSource.list])

    const onSelectClick = (item)=> {
        console.log(item);
        setSelectItem(item);
    }

    const goRecharge = ()=> {
        if(selectItem) {
            props.goRecharge && props.goRecharge(selectItem);
        }
    }

    //协议
    const onProtcol = async (item) => {
        console.log(item);
        const rules = await Service.getRules({activityCode: item.type == 1 ? 'gkxy': 'gkzc'});
        if(rules) {
            const {otherSign} = rules.activityRule||{}
            ModalAlert.show({
                getContainer: pageRef.current,
                title: item.title,
                content: <div style={{lineHeight: '18px'}}>{otherSign}</div>,
                actions: [{ key: 'cancel', text: '确定'}],
                onAction: ()=> {
                    ModalAlert.hide()
                }
            })
        }else {
            Toast.show(`${item.title}内容获取失败`)
        }
       
        
    }

    return (
        <div className={Styles.recharge}>
            <div className={Styles.recharge_content}>
                <h2>充值金额</h2>
                <div className={Styles.grid}>
                {
                    dataSource?.list?.map((item,k)=>{
                        item.select = k;
                        return (
                            <div key={k} className={`${Styles.item} ${selectItem && selectItem.select == k ? Styles.active: ''}`}
                                onClick={()=>onSelectClick(item)}
                            >
                                <span>{item.price}<span>元</span></span>
                                {/* marketingType: 0无优惠，1金额优惠，2折扣优惠 */}
                                {
                                    item.discounts && item.marketingType == 2 ?
                                    <div className={Styles.tag}>
                                        <div>
                                            <span>{item.discounts * 10}折</span>
                                        </div>
                                        <img src={require('images/pointRecharge/tag.png')} alt="" />
                                    </div>
                                    :null
                                }
                                {
                                    item.discounts && item.marketingType == 1 ?
                                    <div className={Styles.tag}>
                                        <div>
                                            <span>立减{item.discounts}元</span>
                                        </div>
                                        <img src={require('images/pointRecharge/tag.png')} alt="" />
                                    </div>
                                    :null
                                }
                                {
                                    selectItem && selectItem.select == k ?
                                    <img className={Styles.select_badge} src={require('images/pointRecharge/select_badge.png')} alt="" />
                                    :null
                                }
                            </div>
                        )
                    })
                }
                </div>
                {
                    selectItem && dataSource.list?.length ?
                    <div className={Styles.go_recharge} onClick={goRecharge}>
                        <span>去充值</span>
                    </div>
                    : <div className={`${Styles.go_recharge} ${Styles.disable}`}>
                        <span>去充值</span>
                    </div>
                }
                
            </div>
            <div className={Styles.recharge_card_desc} ref={pageRef}>
                <h2>充值须知：</h2>
                <p>{`①充值金额与G优积分的换算比例为1：100，支付成功后对应积分直接充值到购买人积分账户。`}</p>
                <p>②购买即视为阅读并同意{[{title: 'G优单用途预付卡购卡章程', type: 2}].map((item,i)=>{
                    return <Fragment key={i} >
                        <span onClick={()=>onProtcol(item)}>{`《${item.title}》`}</span>
                    </Fragment> 
                })}，请点击仔细阅读后再购买。</p>
            </div>
            
        </div>
    )
}

export default Recharge;