import React, {useState} from "react";
import './style.scss';
import activeImg from '../images/pic_fenxing.png';
import activeImg1 from '../images/pic_fenxing1.png';
import gotoBrowserImg from 'images/goto-browser.png';
import vs from '../images/icon_vs.png';

import Library from 'library';
const { Util } = Library;

const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

const WorldCupGuessShare = () => {
  const { shareType='0', homeName, homeFlag, visitName, visitFlag, num } = Util.url.paramsToObj() || {};
  const [maskShow, setMaskShow] = useState(false);

  const url = '/front/activities/worldCupGuess/worldCupIndex';
  const targetUrl = `${process.env.REACT_APP_GKT_LINK}${url}`;
  const luanchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(`${targetUrl}`)}`;

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = luanchUrl;
      setTimeout(() => {
          window.location.href = downloadUrl;
      }, 3000);
    }
  }

  return <div className="world-cup-guess-share">
    <img src={shareType === '0' ? activeImg: activeImg1} alt="" />
    <div className={`load-btn ${shareType === '0' ? '': 'load-btn-1'}`} onClick={() => openApp()}>下载/打开APP</div>
    {shareType === '1' && <div className='share_match_info'>
      <div className='share_match_title'>第{num}场</div>
      <div className='share_match_content'>
        <div className='share_match_name_left'>
          <span>{decodeURIComponent(homeName)}</span>
          <img className='country_flag' src={decodeURIComponent(homeFlag)} alt="" />
        </div>
        <div className='share_match_flag'>
          <img className='vs' src={vs} alt="" />
        </div>
        <div className='share_match_name_right'>
          <span>{decodeURIComponent(visitName)}</span>
          <img className='country_flag' src={decodeURIComponent(visitFlag)} alt="" />
        </div>
      </div>
    </div>}
    {maskShow && <div className='mask' onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default WorldCupGuessShare;