import React, {useEffect, useState, useRef} from 'react';
import './inviteRecord.scss';
import Services from 'services';
import Library from 'library';
import { InfiniteScroll, PullToRefresh, DotLoading  } from 'antd-mobile';
import no_data from 'images/queshengye/pic_wushuju.png'

const { Util, JsBridge } = Library;

const failMap = {
  '0': '被邀请人未登录',
  '1': '已送积分',
  '2': '被邀请人使用重复的设备登录',
  '3': '被邀请人邀请码不存在',
  '4': '被邀请人已注销过',
  '5': '已经超过邀请限制',
  '13': '被邀请人账号异常',
}

const InviteRecord = () => {
  const {type=''} = Util.url.paramsToObj();
  const [inviteRecord, setInviteRecord] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageStatus, setPageStatus] = useState('init');
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    getInviteRecord(true);
  }, []);

  const getInviteRecord = async (isRefresh) => {
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      activityCode: type || ''
    }
    const result = await Services('MainPage', { code: '100300' }, params);  
    JsBridge.print(result);
    setPageStatus('finish');  
    if(result.status > 0) {
      if(pageNum.current === 1) {
        setInviteRecord(result.data?.invitedList || []);
      } else {
        setInviteRecord([...inviteRecord, ...(result.data?.invitedList || [])]);
      }
      setHasMore(result.data?.invitedList?.length >= pageSize.current);
    }
  }

  const InviteItem = ({item}) => {
    const {createTime, invitedMobile, sourceFromDep} = item;
    return <div className="record-list-item">
      <div className="item-left">
        <span>{invitedMobile}</span>
        <span>{createTime}</span>
      </div>
      <div className="item-right">
        {sourceFromDep === '1' ? <span>邀新成功</span>: null}
        {sourceFromDep === '0' ? <span>邀新进行中</span>: null}
        {['2', '3', '4', '5', '13'].includes(sourceFromDep) ? <span>邀新失败</span>: null}
        {['0','2', '3', '4', '5', '13'].includes(sourceFromDep) ? <span>{failMap[sourceFromDep]}</span>:null}
      </div>
    </div>
  }

  if(pageStatus !== 'finish') return null;

  return <div className='invite-record-page'>
    <PullToRefresh 
      onRefresh={() => getInviteRecord(true)}
      headHeight={70}
      threshold={100}
      pullingText={
        <img 
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
        />
      }
      refreshingText={
        <img
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
        />
      }
      canReleaseText={
        <img
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
          />
        }
      renderText={null}
    >
      {inviteRecord.length > 0 ? inviteRecord.map((item) => {
        return <InviteItem item={item}/>
      }): <div className='empty-container'>
        <img src={no_data} alt="" />
        <span>暂无邀新记录</span>
      </div>}
      {inviteRecord.length > 0 ? <InfiniteScroll 
        loadMore={() => getInviteRecord(false)} 
        hasMore={hasMore} 
        threshold={50}
      >
        <InfiniteScrollContent hasMore={hasMore}/>
      </InfiniteScroll>: null}
    </PullToRefresh>
  </div>
}

const InfiniteScrollContent = ({hasMore}) => {
  return (
    <>
      {hasMore ? (
        <>
          <span>Loading</span>
          <DotLoading />
        </>
      ) : null}
    </>
  )
}

export default InviteRecord;