import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import {Mask, Toast} from 'antd-mobile';
import Library from "../../../../../library";
const {JsBridge, } = Library;

const ExchangeSuccModal = ({onClose, data, callback}) => {
    const [visible, setVisible] = useState(true);

    const handleClick = async (type) => {
        setVisible(false);
        onClose && onClose();
        if (type === 0) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?initalTab=1`;
            JsBridge.push('CustomWeb', {uri: uri});
            // JsBridge.push('CustomWeb', {uri: `http://192.168.110.82:3001/myCollection?initalTab=1`});
        }
    }


    return <Mask className={styles.compound_dialog} visible={visible}>
        <div className={styles.put_modal_container}>
            <div className={styles.btns}>
                <img src={require('../../imgs/btn_ck.png')} alt="" onClick={() => handleClick(0)}/>
            </div>
        </div>
    </Mask>
}

ExchangeSuccModal.open = (data, callback) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<ExchangeSuccModal onClose={close} data={data} callback={callback}/>, div);
}

export default ExchangeSuccModal;
