/**
 * @description 积分任务
 * @returns route: /front/activities/pointTask
*/
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, Popup, Tabs } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import classNames  from 'classnames';
import { Header } from 'components';
import Library from 'library';
import Service from '../service';
import GuSign from '../components/guSign';
import BaseTask from '../components/baseTask';
import DailyTask from '../components/dailyTask';
import { SignModal } from '../components/signModal';
import './index.scss';
import UnitySign from '../components/unitySign';
import UnityTask from '../components/unityTask';
const { JsBridge } = Library;

export default (props) => {
    const history = useHistory();

    const [avatar, setAvatar] = useState('');
    const [activeKey, setActiveKey] = useState('0');
    // gu签到数据
    const [guSignData, setGuSignData] = useState({});
    const [normalTasks, setNormalTasks] = useState([])
    const [baseTasks, setBaseTasks] = useState()
    // 积分/能量值
    const [valueX, setValueX] = useState(0)
    const [clientId, setClientId] = useState();
    const [alertData, setAlertData] = useState({ visible: false, content: '', title: '', buttons: [] });
    // 元宇宙签到数据
    const [signData, setSignData] = useState([])
    const [everyDayTasks, setEveryDayTasks] = useState([])

    useEffect(() => {
        Service.getAvatar().then(av => {
            setAvatar(av.avatar)
        })
        onDidFocus()
    }, [])

    useEffect(()=>{
        activeKey == 1 ? unity_initData(): gu_initData()
    },[activeKey])

    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            JsBridge.getStorage({ key: 'whichFrom' }).then(res => {
                if (res && res.result && res.result.whichFrom == 1) { //订单提交回来的
                    history.push('/front/activities/dailySignin/index/exchangeSuccess');
                    JsBridge.removeStorage({ key: 'whichFrom' });
                }
            })
            if(activeKey == 1) {
                unity_initData()
            }else {
                gu_initData();
            }
            onDidFocus();
        });
    }

    const gu_initData = async () => {

        const [res1, res2] = await Promise.all([Service.getNormalTasksData(), Service.getBaseTask()]);
        if (res1) {
            setNormalTasks(res1.data?.clientPointTaskCycleVOList || [])
            setValueX(res1.data?.points)
            setClientId(res1.data?.clientId)
        }
        if (res2) {
            const {baseTasks, ...guSign} = res2;
            guSign.signInArray = [{}, ...guSign.signInArray]
            setBaseTasks(baseTasks);
            setGuSignData(guSign)
        }else {
            setBaseTasks([])
        }
    }

    const unity_initData = async ()=> {
        const [res1, res2] = await Promise.all([ Service.querySignData({clientId}), Service.queryEveryDayTask({clientId})]);
        if(res1?.data?.signReport) {
            setSignData(res1.data.signReport||[])
        }
        if(res2?.data) {
            // setValueX(res2.data?.num || 0)
            setEveryDayTasks(res2.data?.tasks || [])
        }
    }

    // 碎片兑换
    const onExchangeDebriis = ()=> {
        if(JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/activities/pointTask/exchangeDebris'});
        }else {
            history.push('/front/activities/pointTask/exchangeDebris');
        }
    }

    //奖品领取
    const onReceiveReward = ()=> {
        if(JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/activities/pointTask/exchangeReward'});
        }else {
            history.push('/front/activities/pointTask/exchangeReward');
        }
        
    }

    const onGuCallBack = (data) => {
        setAlertData({
            ...data, 
            buttons: [{
                name: '确定', click: () => {
                    setAlertData({ visible: false })
                },
                className: 'confirm',
            }]
        })
        gu_initData();
    }

    return (
        <div className='point-task'>
            <Header bgColor={'transparent'} />
            <div className='point-task__wrapper'>
                <div className='bgTop'>
                    <div className='header-box'>
                        <div className='user-info'>
                            <div className='avatar'>
                                <img src={avatar} alt='' />
                            </div>
                            <div>
                                 {/* <span>{valueX}<span>{activeKey==1 ? '能量': '积分'}</span></span> */}
                                <div className='points'>{valueX}<span>{'积分'}</span></div>
                                <div className='debris'>可用兑换碎片：<span>{guSignData.amount ?? 0}</span> 个</div>
                            </div>
                           
                        </div>
                        {
                            activeKey == 1 ?
                            <div className='task-rule' onClick={onReceiveReward }>
                                <span>奖品领取填写</span>
                                <RightOutline fontSize={12} color='#FFECBB' />
                            </div>:
                            <Fragment>
                            {
                                (normalTasks.length > 0 || baseTasks?.length > 0) &&
                                <div className='task-rule' onClick={onExchangeDebriis}>
                                    <span>碎片兑换</span>
                                    <RightOutline fontSize={12} color='#FFECBB' />
                                </div>
                            }
                            </Fragment>
                        }
                    </div>
                </div>
                <Tabs
                    className={classNames('task_tabs',{'task_tabs-1': activeKey == 1})}
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                >
                    {
                        ['G优积分任务', '元宇宙任务'].map((item, i) => {
                            return (
                                <Tabs.Tab
                                    key={i}
                                    title={<TabHeaderItem title={item} index={i} active={activeKey == i} />}
                                >
                                    {
                                        i == 0 ? 
                                        <div className='gu_tasks'>
                                            <GuSign dataSource={guSignData} onUpdateState={data=>setGuSignData(data)} onRefesh={gu_initData}/>
                                            {
                                                normalTasks.length > 0 &&
                                                <DailyTask dataSource={normalTasks} clientId={clientId} callBack={onGuCallBack} />
                                            }
                                            {
                                                // baseTasks.length > 0 &&
                                                <BaseTask pointTaskList={baseTasks} callBack={onGuCallBack} />
                                            }
                                        </div>:
                                        <div className='unity_tasks'>
                                            {
                                                // signData.length > 0 &&
                                                <UnitySign dataSource={signData}/>
                                            }
                                            {
                                                everyDayTasks.length > 0 &&
                                                <UnityTask dataSource={everyDayTasks}/>
                                            }
                                        </div>
                                    }
                                </Tabs.Tab>
                            )
                        })
                    }
                </Tabs>

            </div>
            <SignModal
                {...alertData}
            />
        </div>
    )
}

const TabHeaderItem = (props) => {
    const { title, active, index } = props
    return (
        <div className={'headerItem' + ` ${active ? 'active' : ''}`}>
            {active && <img src={index == 0 ? require('images/pointTask/left_active.png'):require('images/pointTask/right_active.png')}/>}
            <div>{title}</div>
        </div>
    )
}