import React from 'react';
import { Toast } from 'antd-mobile';
import Library from "../../library";
let toast = null;
const {JsBridge} = Library;
export const showLoading = () => {
  if (JsBridge.hasWebViewBridge()) {
    JsBridge.runAction('loading', 'start', [false]);
  } else {
    toast = Toast.show({ icon: 'loading', content: '加载中...', duration: 0, maskClickable: false })
  }
}

export const hideLoading = () => {
  if (JsBridge.hasWebViewBridge()) {
    JsBridge.runAction('loading', 'end', [false]);
  } else {
    toast && toast.close();
  }
}
