import React from 'react';
import Library from 'library';
import './index.scss';
const {JsBridge} = Library;

export class IntegralRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        };
    }

    componentWillMount() {
        JsBridge.setTitle('G优积分规则');
    }

    onJumpClick = ()=> {
        //
         if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/pointTask?navHide=true`});
        }else {
            this.props.history.push('/front/activities/pointTask?navHide=true')
        }
    }

    onPonitClick = ()=> {
        JsBridge.verifyRealName('积分充值需先进行实名认证, 请点击前往认证').then((res) => {
            if (res.result) {
                JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/member/pointRecharge/home`});
            }
        });
    }

    render() {
        return (
            <div className='role-wrap'>
                <h2>积分获取途径</h2>
                <p>1.积分任务：完成积分任务获取对应的积分，具体规则请在<span style={{color: '#1677ff'}} onClick={this.onJumpClick}>积分任务</span>页面查看</p>
                <p>2.线上签到： 每日签到获得10积分</p>
                <p>3.优质点评：用户确认收货后每个订单给到4-5星符合产品信息的有效评论且字数大于30可得100积分</p>
                <p>4.购物返积分：会员在商城内消费可得不同数量积分具体可获得数量受消费的商品品类，商城积分活动，订单金额等因素影响</p>
                {/* <p>1.注册登录：注册成为绿地G优会员完成APP端登录即可获得50积分</p>
                <p>2.实名认证：会员完成身份认证通过后可得100积分，仅发放1次，更改证件再次验证不发放</p>
                <p>3.绑定银行卡：会员首次绑定银行卡成功后可得200积分，仅发放1次，绑多张卡不再发放</p>
                <p>4.完善个人信息：按要求100%完成个人信息的全部填写可得100积分</p>
                 // <p>5.绑定微信 +100 积分；</p>
                <p>5.风险评测：按要求完成风险评测的全部答题内容可得50积分</p>
                <p>6.	线上签到：每日签到获得10积分</p>
                <p>7.	优质点评：用户确认收货后每个订单给到4-5星符合产品信息的有效评论且字数大于30可得100积分</p>
                <p>8.	购物返积分：会员在商城内消费可得不同数量积分，具体可获得数量受消费的商品品类，商城积分活动，订单金额等因素影响</p> */}
                <p>5.积分充值：通过充值方式获取积分，<span style={{color: '#1677ff'}} onClick={this.onPonitClick}>{'去充值>>'}</span></p>

                <h2 className='section'>积分使用途径</h2>
                <p>1.	积分抽奖：每个用户每日可抽5次，每次消耗30积分</p>
                <p>2.	积分商城兑换：每个商品对应指定积分数，详细兑换规则遵循积分商城说明</p>
                <p>3.	积分抵扣实物消费：单用户满1000积分可抵扣，按照100：1进行抵扣，最高可抵扣订单金额的20%</p>

                <h2 className='section'>积分规则申明</h2>
                <p>1.	绿地G优积分为永久积分，凡未使用积分无有效期限制，但不得兑现，不可转让</p>
                <p>2.	会员获得积分后有权根据绿地G优平台提供的使用途径和规则进行使用，需通过正常途径获得积分，不得通过软件，程序等方式获得，对于利用不正当手段（如作弊领取，恶意套现，虚假交易等）谋取利益的作弊违法违规行为，绿地G优有权进行如下处理：</p>
                <p>①绿地G优有权冻结积分及撤销兑换的物品使用资格，并有权收回用户已获得的权益<br />
                    ②绿地G优会同网信管理部门、公安机关依法进行严厉打击<br />
                    ③对用户账户进行封禁处理<br />
                </p>
                <p>3.	购物返积分规则说明：绿地G优平台会员在商城购买商品订单完成支付并确认收货后才可获得，大促活动、拼团、砍价等商品不在返积分范畴</p>
                <p>4.	积分商城兑换：会员在绿地G优APP的积分专区，依据自己的积分数量挑选可兑换礼物，完成兑换，系统会生成二维码或者卡号及密码存放在兑换记录中，产品使用方法参考对应的产品页面，积分兑换仅针对实名认证用户，且一个会员身份仅可认证一个账户</p>
                <p>5.	积分换礼属于会员回馈活动，不提供发票，礼物一经兑换不可撤销、退货、换货、退积分或兑换现金</p>
                <p>6.	积分抵扣商品订单，出现改价时，订单中抵扣所使用的积分不会退还</p>
            </div >
        );
    }
}
