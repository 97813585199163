import {LOADING_START, LOADING_COMPLETE} from '../../../constants';

export const loading = {
    start: (data) => {
        return {type: LOADING_START};
    },
    end: (data) => {
        return {type: LOADING_COMPLETE};
    }
};
