import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import back from '../imgs/back-gray.png'
import weizhi from '../imgs/location.png'
import { useHistory } from 'react-router-dom'
import SelectAddress from '../selectAddress'
import { Popup, Toast } from 'antd-mobile'
import { getDataInUnity } from "../../../../services/serviceUnity"
import moment from 'moment'
import Library from "library";
const { Util } = Library

const FillInAddress = () => {
  const history = useHistory()
  const { isWrite, activityCode='', ...rest } = Util.url.paramsToObj()
  const [userAddress, setUserAddress] = useState(null)
  const [visible, setVisible] = useState(false)
  
  useEffect(() => {
    if (isWrite === '1') {
      setUserAddress(rest)
    } else {
      getDefaultAddress()
    }
    window.addEventListener('message', getAddress)
  }, [])

  const getAddress = (e) => {
    console.log('e========>', e.data && JSON.parse(e.data))
    const data = e.data ? JSON.parse(e.data): null
    data && setUserAddress({...rest, ...data})
    setVisible(false)
  }

  const getDefaultAddress = async () => {
    const res = await getDataInUnity(101294)
    console.log('defaultaddress', res)
    const list = res?.result || []
    //查找默认地址
    const defaltAddress = (list.length > 0 && (list.find(item => item.isDefaltAddress == 1) || list[0])) || {}
    setUserAddress({...rest, ...defaltAddress})
  }

  const onSubmit = async () => {
    const params = {
      type: 1,
      req: rest.id,
      activityCode,
      province: userAddress.provinceName,
      city: userAddress.cityName,
      area: userAddress.areaName,
      street: userAddress.streetName,
      receiverName: userAddress.consigneeName,
      receiverMobile: userAddress.consigneeNumber,
      address: userAddress.deliveryAddress
    }
    const res = await getDataInUnity(101330, params)
    if(res?.code === '000000') {
      Toast.show({
        content: '提交成功',
        afterClose: () => history.goBack()
      })
    }
  }

  return <div className={styles.fill_in_address}>
    <div className={styles.main_container}>
      <div className={styles.top_nav}>
        <img className={styles.back} src={back} alt="" onClick={() => history.goBack()}/>
        <span className={styles.nav_title}>确认订单</span>
        <span className={styles.back}></span>
      </div>
      <div className={styles.mid_content}>
        <div className={styles.content_left}>
          <div className={styles.goods_info}>
            <div className={styles.goods_img}><img src={decodeURIComponent(userAddress?.prizeImgUrl)} alt="" /></div>
            <div className={styles.goods_name}>
              <span>{userAddress?.prizeName ? decodeURIComponent(userAddress?.prizeName) : ''}</span>
              <span>1件</span>
            </div>
          </div>
          {isWrite === '0' ? null: <div className={styles.order_info}>
            <div className={styles.order_info_item}>
              <span>订单编号</span>
              <span>{userAddress?.orderId || ''}</span>
            </div>
            <div className={styles.order_info_item}>
              <span>下单时间</span>
              <span>{userAddress?.createTime ? moment(decodeURIComponent(userAddress.createTime)).format('YYYY-MM-DD HH:mm:ss'): ''}</span>
            </div>
          </div>}
        </div>
        <div className={styles.content_right}>
          <div className={styles.pay_type}>
            <span>配送服务</span>
            <span>快递配送</span>
          </div>
          <div className={styles.address}>
            {isWrite === '0' ? <div className={styles.add_address} onClick={() => {
              setVisible(true)
            }}>
              <img src={weizhi} alt="" />
              <span className={styles.tip_text}>
                {(userAddress?.provinceName && userAddress?.cityName && userAddress?.areaName && userAddress?.streetName) ? userAddress.provinceName + userAddress.cityName + userAddress.areaName + (userAddress.streetName || '') + (userAddress.deliveryAddress || ''): '请添加收货地址'}
              </span>
              <span className={styles.line}></span>
              <span className={styles.add_btn}>添加</span>
            </div>: <div className={styles.address_detail}>
              <img src={weizhi} alt="" />
              <div className={styles.address_text}>
                <span>{userAddress ? decodeURIComponent(userAddress.province) + decodeURIComponent(userAddress.city) + decodeURIComponent(userAddress.area) + (decodeURIComponent(userAddress.street) || '') + (decodeURIComponent(userAddress.address) || ''): ''}</span>
                <div className={styles.user_info}>
                  <span>{decodeURIComponent(userAddress?.consigneeName) || ''}</span>
                  <span>{userAddress?.consigneeNumber || ''}</span>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {isWrite === '0' && <div className={styles.bottom_btn}>
        <div className={styles.sub_btn} onClick={onSubmit}>提交订单</div>
      </div>}
    </div>
    <Popup 
      visible={visible}
      onMaskClick={() => setVisible(false)}
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        background: 'none',
      }}
    >
      <SelectAddress />
    </Popup>
  </div>
}

export default FillInAddress