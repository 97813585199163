import React from 'react';
import './style.scss';
import no_data from '../../images/queshengye/pic_wushuju.png'

const Empty = ({image, description,height}) => {
  return <div className="empty-new" style={{height:height}}>
    <img className='empty-img' src={image || no_data} alt="" />
    <span className='empty-desc'>{description || '没有更多内容'}</span>
  </div>
}

export default Empty;
