import React, { useState, useEffect } from 'react';
import styles from './vote.module.scss';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import Services from 'services';
import headImg from './images/headMap.png';
import { End as ActivityEnd } from '../activities51/end';
const { Platform, Util, JsBridge } = Library;

// 主组件
export const Vote = () => {
    const history = useHistory();
    const [pageData, setPageData] = useState(null);
    const [currentTime, setCurrentTime] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);

    const getInit = async () => {
        if (Platform.check.isApp()) {
            const result = await Services('MainPage', { code: '101187' }, {})
            const res = await JsBridge.isFullScreen();
            setIsFullScreen(res.isFullScreen);
            if (result.status > 0) {
                const { data } = result;
                data.startTime = new Date((data.startTime || '').replace(/-/g, '/'));
                data.endTime = new Date((data.endTime || '').replace(/-/g, '/'));
                data.currentTime = new Date((data.currentTime || '').replace(/-/g, '/'));
                
                data.advertisementList = {
                    imageUrl: data.registerImgUrl,
                    url: data.registerShareUrl,
                    title: data.registerTitle,
                    text: data.registerText,
                    InvitationCode: data.inviteCode
                }
                setPageData(data);
            }
        }
    };

    const inviteDownload = () => {
        JsBridge.runAction('invitation', 'show', [pageData.advertisementList]);
    }

    const vote = async (item) => {
        if (pageData.voteMax <= pageData.voteCount) {
            JsBridge.runAction('alert', 'error', [`每日最多投${pageData.voteMax}次，明日再来哦～`]);
            return;
        }
        const result = await Services('MainPage', { code: '101188' }, item)
        if (result.status > 0) {
            let { data } = result;
            setPageData(Object.assign({}, pageData, data));
            JsBridge.runAction('alert', 'error', ['投票成功']);
        }
    };

    useEffect(() => {
        getInit();
        const timer = setInterval(() => {
            setCurrentTime(new Date())
        }, 5000);
        return () => {
            JsBridge.print('clear');
            clearInterval(timer);
        }
    }, []);

    if (!pageData) {
        return false;
    }
    if (pageData.endTime < pageData.currentTime) {
        return <ActivityEnd />;
    }
    return <div className={`${styles.container} ${isFullScreen ? styles.fullScreen : ''}`}>
        <div className={styles.headImg}>
            <img className={styles.headImg} src={headImg} alt="投票banner" />
            <div className={styles.ruleButton} onClick={() =>{
                history.push('/front/activities/sloganVote/rules');
            }}>活动规则</div>
            <div className={styles.voteTime}>投票时间：{Util.format.time(pageData.startTime, 'yyyy.MM.dd')}-{Util.format.time(pageData.endTime, 'yyyy.MM.dd')}</div>
        </div>
        <div className={styles.voteWrapper}>
            <div className={styles.voteTit}>
                <p>投票</p>
            </div>
            <div className={styles.voteContent}>
                <ul className={styles.voteList}>
                    {pageData.voteList.map((item, key) => {
                        const crownClass = [styles.crownOne, styles.crownTwo, styles.crownThree][key] || '';
                        return <li className={styles.sloganItem} key={key} onClick={vote.bind(this, item)}>
                            <div className={styles.itemContent}>
                                <div className={styles.sloganContent}>
                                    <div className={styles.sloganText}>{item.sloganContent}</div>
                                    <div className={styles.voteNum}>投票数：{item.count}</div>
                                </div>
                                <div className={`${styles.voteButton} ${pageData.voteMax <= pageData.voteCount && styles.voteButtonGray}`}>投票</div>
                            </div>
                            <div className={`${styles.flag} ${crownClass}`}></div>
                        </li>;
                    })}
                </ul>
            </div>
        </div>
        <div className={`${styles.inviterWrapper} `} onClick={() => {
            inviteDownload();
        }}>
            <div className={styles.inviteButton}>邀请好友下载App投票，享更多优惠</div>
        </div>
    </div >
}