/**
 * @description 扫码领取限量版土地相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const {JsBridge} = Library;

const activityCode = 'SCANCLAIMLAND';

class Service {

    /**
    * @description 扫码领地始化接口
    * @interface 600866
    * @param {activityCode: 活动编码} params
    * @param { 是否显示加载loading } showLoading
    * @returns {
    * 
   */
    static getInitData = async (showLoading = true) => {
        const code = '600866'
        try {
            const params = {
                activityCode: activityCode
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.result||res.data };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取土地接口
     * @interface 600867
     * @param {activityCode: 活动编码,skuId: 商品skuId } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static receiveLand = async (params={}, showLoading=true)=>{
        const code = '600867'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

}

Service.activityCode = activityCode;
export default Service;