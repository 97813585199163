import React, { useEffect, useState, useRef } from 'react';
import Library from 'library';
import styles from './myRewards.module.scss';
import Services from 'services';

const { JsBridge, Hooks,Util } = Library;
let isLoading;
export const MyRewards = (props) => {
    const { activityCode } = Util.url.paramsToObj();
    const [pageNum, setPageNum] = useState(1);
    const [pageData, setPageData] = useState({ list: [] });
    const ref = useRef();

    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            getInitData(true);
            onDidFocus();
        });
    }


    useEffect(() => {
        getInitData(true);
        onDidFocus();
    }, []);


    const getInitData = async (refresh) => {
        let currentPageNum = refresh ? 1 : pageNum + 1;
        const params = { pageNum: currentPageNum, pageSize: 10, activityCode };
        isLoading = true;
        const result = await Services('MainPage', { code: 101318 }, params);
        JsBridge.print(result);
        isLoading = false;
        if (result.status > 0) {
            setPageData(result.data);
            setPageNum(currentPageNum);
        }
    }
    const isEffect = pageData?.endDate && new Date(decodeURIComponent(pageData?.endDate).replace(/-/g, '/')).getTime() > new Date().getTime();
    return <div className={styles.container} ref={ref}>
        {pageData?.list?.map((item, key) => {
            const addressButtonText = () => {
                if (isEffect) {
                    if (item.deliveryAddressId) {
                        return '修改收货地址';
                    } else {
                        return '完善收货地址';
                    }
                } else {
                    return '查看收货地址';
                }
            };

            return <div key={key} className={styles.reward} onClick={() => {
                if (item.prizeType === 0) {
                    JsBridge.navigate('IntegralDetail');
                } else if (item.prizeType === 1) {
                    JsBridge.open(`/taro/subPages/user/mall/exchange/detail?id=${item.pointExchangeId}&merchant=${item.productMerchant}`);
                } else if (item.prizeType === 3) {

                } else {

                }
            }}>
                <div className={styles.content}>
                    <div className={styles.title}>{item.prizeName}</div>
                    <div className={styles.date}>{Util.format.time(new Date(item.createTime), 'yyyy-MM-dd hh:mm:ss')}</div>
                    {item.prizeType === 3?<div className={styles.editAddressBg}>
                        <div className={styles.line}/>
                        <div className={styles.text} onClick={()=>{
                            JsBridge.open(`/front/activities/nft/editAddress?endDate=${encodeURIComponent(pageData.endDate)}&id=${item.id}&${item.deliveryAddressId ? 'deliveryAddressId=' + item.deliveryAddressId : ''}&activityCode=${activityCode}`);
                        }}>{item.deliveryAddressId?'修改收货地址':'填写地址领取奖品'}</div>
                    </div>:false}
                    {item.prizeType === 6?<div className={styles.editAddressBg}>
                        <div className={styles.line}/>
                        <div className={styles.text}>门票奖品请等待客服电话</div>
                    </div>:false}
                </div>
            </div>;
        })}
    </div>
}
