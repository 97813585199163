import React from 'react';
import styles from '../score.module.scss';
// import Robbed from '../images/Robbed@2x.png';
import InviteNew from './inviteNew';
import Footer from './footer';
import { Fragment } from 'react';
import Library from 'library';
const { Platform, JsBridge } = Library;
const ExchangeScore = (props) => {
    // alert(JSON.stringify(props))
    const ExchangeItem = (props.gift || []).map((item, index) => {
        return <Fragment>
            <div
                key={index}
                className={styles.productWrap}
                onClick={() => {
                    if (Platform.check.isApp()) {
                        JsBridge.open(`/taro/subPages/user/mall/product/index?goodsId=${item.productId}&goodsType=${item.merchant === '1' ? 'limit' : 'hot'}`);
                    }
                }}>
                <div className={styles.thumbnail}>
                    <img src={item.url} className={styles.img} />
                    {/* {item.remainedNum <= 0 ? <img src={Robbed} className={styles.soldOut} /> : null} */}
                </div>
                <div className={styles.ItemContent}>
                    <span className={styles.title}>{item.productName}</span>
                    <div className={styles.bottomView}>
                        <span className={styles.text1}>{item.pointCost}</span>
                        <span className={styles.unit}>积分</span>
                        {item.moneyCost ? <span className={styles.text2}>￥{item.moneyCost}</span> : null}
                    </div>
                </div>
            </div>
        </Fragment>
    })
// 顶部图片广告
    return <div className={styles.scoreWrap}>
        <InviteNew {...props}/>
        <div className={styles.listHeader}>积分兑换</div>
        <div className={styles.exchangeItemWrap}>{ExchangeItem}</div>
        <Footer />
    </div>
}
export default ExchangeScore;