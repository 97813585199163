import React, {useState} from "react";
import './style.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import bg1 from '../imgs/bg_1.png';
import bg2 from '../imgs/bg_2.png';
import bg3 from '../imgs/bg_3.png';

import Library from 'library';
const { Util } = Library;

const imgMap = {
  'yflshjf': bg2,
  'yflhfcz': bg1,
  'yflwm': bg3,
}

const downloadUrl = 'https://app.gkewang.com/app';

const YFLInviteSuccess = () => {
  const { type='' } = Util.url.paramsToObj() || {};
  const [maskShow, setMaskShow] = useState(false);

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = downloadUrl;
    }
  }

  return <div className="world-cup-guess-share">
    <img src={imgMap[type]} alt="" />
    <div className='load-btn' onClick={() => openApp()}>下载/打开APP</div>
    {maskShow && <div className='mask' onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default YFLInviteSuccess;