/**
 * @description 邀新赠送奖品列表
*/

import React, { useMemo } from 'react';
import Library from 'library';
import Styles from './index.module.scss';
const { JsBridge } = Library;

const GiftList = (props) => {
    const { activityInfo={}, onReceive, onInvite } = props;
    const { inviteCount=0, receiveSkuId, inviteProductInfo=[] } = activityInfo;

    // 领取奖励
    const onReceiveGive = (st,e)=> {
        e.stopPropagation();
        if(st.stepCount > inviteCount) {
            console.log('邀请')
            return;
            // 让按钮事件不可点击
            onInvite && onInvite()
        }else {
            console.log('领取奖励')
            onReceive && onReceive(st)
        }
        
    }  

    const Steps = useMemo(()=>{
        const item1 = {
            stepCount: 30,
            goodsId: activityInfo.lowLevelProductId,
            productName: '',
            img: '',
            status: 0,
            stock: 10,
        }
        const item2 = {
            stepCount: 30,
            goodsId: activityInfo.middleLevelProductId,
            status: activityInfo.middleLevelStatus,
            productName: '',
            img: '',
            stock: 10,
        }
        const item3 = {
            stepCount: 60,
            goodsId: activityInfo.highLevelProductId,
            status: 0,
            productName: '',
            img: '',
            stock: 10,
        }
        const item4 = {
            stepCount: 80,
            goodsId: activityInfo.highLevelProductId,
            status: 0,
            productName: '',
            img: '',
            stock: 10,
        }
        const items = [item1,item2,item3, item4].map((item,index) => {
                if(inviteProductInfo.length > index) {
                    const raw = inviteProductInfo[index];
                    item.stepCount = raw.inviteCount
                    item.goodsId = raw.goodsId
                    item.productName = raw.lotteryName
                    item.img = raw.img || item.img
                    item.stock = raw.stock
                    item.status = raw.goodsId == receiveSkuId ? 1:0
                    item.skuId = raw.skuId
                }

                return item;
        })
        return items;
    },[activityInfo.highLevelProductId, activityInfo.lowLevelProductId, activityInfo.middleLevelProductId, activityInfo.middleLevelStatus, inviteProductInfo, receiveSkuId])

    const onDetailClick =(item={})=> {
        const skuId = item.skuId
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
    }

    return (
        <div className={Styles.gift_list}>
        {
            Steps.map((item,key)=> {
                let enable = true
                let btnTitle = '立即领取'
                const isRecive = Steps.find(v=>v.status)
                // 先判断有没有领取过
                if(isRecive) {
                    enable = false;
                    if(item.status) {
                        btnTitle = '已领取'
                    }else {
                        btnTitle = `已完成`
                    }
                } else {
                    if(item.stock > 0) {
                        btnTitle = `邀${item.stepCount}人可领`
                        if(item.stepCount <= inviteCount) {
                            btnTitle = '立即领取'
                        }
                    }else {
                        enable = false;
                        btnTitle = '已领完'
                    }
                    
                }
                return (
                    <div key={key} className={Styles.item_container}>
                      <div className={Styles.gift_item} onClick={()=>onDetailClick(item)}>
                          <img className={Styles.img} src={item.img} alt="" />
                          <span className={Styles.desc}>{item.productName}</span>
                      </div>
                      {
                          enable ?
                          <div className={Styles.btn_enable} onClick={(e)=>onReceiveGive(item,e)}>
                              <span>{btnTitle}</span>
                          </div>
                          :<div className={Styles.btn_disable} onClick={e=>e.stopPropagation()}>
                              <span>{btnTitle}</span>
                          </div>
                      }
                    </div>
                )
            })
        }
        </div>
        
    )
}

export default GiftList;