/**
 * @description 领取奖励弹框
 * @implements  代码来源于gaoyu的女神节活动
*/

import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import Styles from './index.module.scss';
import { Mask } from 'antd-mobile';
import Library from 'library';
const { JsBridge, Util } = Library;

const ReceiveModal = ({onClose, callBack, prizeInfo}) => {
  const [visible, setVisible] = useState(true);

  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
      callBack && callBack();
    });
  }
  
  const handleClick = () => {
    const param = Util.url.objectToQueryParams({
      idf: 'commonSub',
      activityCode: 'fathersDay',
      name: prizeInfo.prizeName,
      imgUrl: prizeInfo.imageUrl,
      req: prizeInfo.req,
    });
    JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/guapp/activityOrder?${param}`});
    onDidFocus();
    onClose && onClose();
    setVisible(false);
  }

  const onMask = () => {
    callBack && callBack();
    onClose && onClose();
    setVisible(false);
  }

  return <Mask visible={visible} onMaskClick={onMask}>
    <div className={Styles.goddess_receive_modal}>
      <div className={Styles.goddess_modal_container}>
        <div className={Styles.goddess_modal_title}>领取奖励</div>
        <div className={Styles.goddess_modal_content}>
          <div className={Styles.receive_status}>恭喜您！领取成功！</div>
          <div className={Styles.wenxin_tip}> 请尽快填写您的收获地址，也可以在领取记录中查看并填写地址，活动结束后未填写地址奖品将作废</div>
        </div>
        <div className={Styles.goddess_receive_btn} onClick={handleClick}>填写地址</div>
      </div>
    </div>
  </Mask>
}

ReceiveModal.open = ({callBack, prizeInfo}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ReceiveModal callBack={callBack} prizeInfo={prizeInfo} onClose={close}/>, div);
}

export default ReceiveModal;