import React from 'react';
import icon_zongtaofang from './images/icon-zongtaofang@2x.png';
import icon_sanzhisongshu from './images/icon-sanzhisongshu@2x.png';
import JF1000 from './images/icon-1000jifen@2x.png';
import icon_promax from './images/icon-12pro max@2x.png';
import icon_watsons from './images/icon-watsons@2x.png';
import JF100 from './images/icon-100jifen@2x.png';
import icon_haagendazs from './images/icon-haagendazs@2x.png';
import icon_Gsuper from './images/icon-Gsuper@2x.png';
import back from './images/back@2x.png';
import blackBack from './images/back-black@2x.png';
import styles from './score.module.scss'
import ExchangeScore from './shareComponents/exchangeScore';
import Marquee from './shareComponents/Marquee';
// import { useHistory } from 'react-router-dom'
import Library from 'library';
import Services from 'services';
import modalBackground from './images/draw-window-winning@2x.png';
import modal from './images/modal@2x.png';
import colsePic from './images/close.png';
import { Actions } from "../../../reduxs";
const { Platform, JsBridge } = Library;
// 积分抽奖
const buttons = [
    { type: 'gift', img: icon_zongtaofang, name: '总统套房1晚', value: '1', step: 0 },
    { type: 'gift', img: icon_sanzhisongshu, name: '满300减205', value: '35', step: 1 },
    { type: 'gift', img: icon_watsons, name: '50元代金券', value: '10', step: 2 },
    { type: 'gift', img: icon_haagendazs, name: '100元代金券', value: '48', step: 7 },
    { type: 'start' },
    { type: 'integral', img: JF1000, name: '1000积分', step: 3 },
    { type: 'integral', img: JF100, name: '100积分', value: '18', step: 6 },
    { type: 'gift', img: icon_Gsuper, name: '300元购物卡', value: '888', step: 5 },
    { type: 'gift', img: icon_promax, name: '华为Mate X2 5G', value: '2', step: 4 },
];
let timer;
// let timer2;
let runner;
export class ScoreDraw extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            stars: [],
            showModal: false,
            isBegin: false,
            endSteps: 0,
            thisStep: 0,
            reward: null,
            inviteNewClientPoint: null,
            statusBarHeight: 0,
            isFullScreen: true,
            scrollY: 0,
            gift: [],
            over_flow: true,
            remainTimes: 0,
            // 跑马灯数据
            lotteryRecordList: [],
            point: 0
        };
        this.Actions = context.Actions;
        this.Services = context.Services;
        this.lightChange = this.lightChange.bind(this);
        this.scroll = this.scroll.bind(this);

    }
    async componentDidMount() {
        // JsBridge.setStatusBar('setBarStyle', ['light-content']);
        if (Platform.check.isApp()) {
            let { height } = await JsBridge.getStatusBarHeight();
            this.setState({
                statusBarHeight: height
            })
        }
        const stars = [];
        for (var i = 0; i < 34; i++) {
            stars.push(i);
        }
        this.setState({
            stars
        });
        this.eventListener = window.addEventListener('scroll', this.scroll);
        this.scroll();
        timer = setInterval(this.lightChange, 1000);
        this.initInfo();
        // timer2 = setInterval(this.initInfo.bind(this, true), 10000);
        // 积分兑换
        this.exchange();
    }
    async initInfo(initFlag = "0") {
        //获取用户信息
        const fetchResult = await Services('User', { code: 101184 }, { activityCode: 'INVITENEW', initFlag });
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            if (initFlag === "0") {
                let { startTime, endTime } = result.jktActivity;
                const start = new Date(startTime.replace(/-/g, '/'));
                const end = new Date(endTime.replace(/-/g, '/'));
                if (start > new Date() || end < new Date()) {
                    this.props.history.replace(
                        '/front/activities/activities11/End', { start, end }
                    );
                }
                this.setState({
                    point: result.availablePoint,
                    inviteNewClientCount: result.inviteNewClientCount,
                    remainTimes: result.remainTimes,
                    inviteNewClientPoint: result.inviteNewClientPoint,
                    lotteryRecordList: result.lotteryRecordList
                })
            } else {
                this.setState({
                    lotteryRecordList: []
                }, () => {
                    this.setState({
                        lotteryRecordList: result.lotteryRecordList
                    });
                });
            }
        }
    }
    componentWillUnmout() {
        clearInterval(timer);
        // clearInterval(timer2);
        clearTimeout(runner);
    }
    scroll() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 10) {
            JsBridge.setStatusBar('setBarStyle', ['dark-content']);
        } else {
            JsBridge.setStatusBar('setBarStyle', ['light-content']);
        }
        this.setState({ scrollY: scrollTop });
    }
    lightChange() {
        let { showLight } = this.state;
        this.setState({ showLight: !showLight });
    }
    // 积分兑换
    async exchange() {
        const fetchResult = await Services('User', { code: 101152 }, { pageNum: 1, pageSize: 100 });
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            this.setState({
                gift: result.productList
            });
        }
    }
    getMoreTimes = async () => {
        const result = Platform.check.isApp() ? await Services('MainPage', { code: '101186' }, { activityCode: 'INVITENEW' }) : await Services('GuMember', 'IsRegisted', { activity: 'INVITECLIE' });
        const { status } = result;
        if (status > 0) {
            const { data } = result;
            if (Platform.check.isApp()) {
                this.props.history.push('/front/activities/shareOfficer/scoreDraw/shareImage/' + data.inviteCode, {
                    myShareImage: data.myShareImage,
                    rewardPoint: data.rewardPoint
                });
            }
        }
    };
    // 新增内容
    async getAward() {
        //抽奖
        let { thisStep, hasClick } = this.state;
        if (hasClick) {
            return;
        }
        this.setState({ hasClick: true });
        const fetchResult = await Services('User', { code: 101185 }, { activityCode: 'INVITENEW' });
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            if (result.status === "0") {
                Actions.alert.show('今日抽奖次数已用完，请明天再来吧！');
                // await JsBridge.runAction('immersivemodal', 'show', [`抽奖次数不足, 今日抽奖次数已用完，请明天再来吧！`, '抽奖次数不足',
                //     [
                //         {
                //             name: '确定',
                //             style: { color: '#999' }
                //         }
                //     ]
                // ]);
                // JsBridge.runAction('immersivemodal', 'hide', []);
                this.setState({ hasClick: false });
            } else if (result.status === "1") {
                this.setState({
                    showModal: true
                });
                // this.setState({
                //     showModal: true
                // })
                
                // const runAction = await JsBridge.runAction('immersivemodal', 'show', [`抽奖次数不足,快去邀请好友吧！`, '抽奖次数不足',
                //     [
                //         {
                //             name: '确定',
                //             style: { color: '#999' }
                //         },
                //         {
                //             name: '分享'
                //         }
                //     ]
                // ]);
                // if (runAction === '分享') {
                //     this.getMoreTimes();
                // }
                // JsBridge.runAction('immersivemodal', 'hide', []);
                this.setState({ hasClick: false });
            } else {
                const diff = Math.abs(8 - thisStep % 8 + (result.index));
                const endSteps = 24 + diff;
                this.setState({ isBegin: true, endSteps: endSteps, reward: result }, () => {
                    runner = setTimeout(this.startAnimation.bind(this), 200);
                });
                this.setState({ hasClick: false });
            }
            this.setState({ point: result.availablePoint, inviteNewClientCount: result.inviteNewClientCount, remainTimes: result.remainTimes });
        }
    }
    getScoreRecord = () => {
        JsBridge.open(`/taro/subPages/user/coupon/myCoupon/index`);
    }
    getRewardRecord = () => {
        JsBridge.navigate('IntegralDetail');
    }
    async startAnimation(interval = 500) {
        let { thisStep, endSteps } = this.state;
        if (endSteps <= 0) {
            clearTimeout(runner);
            this.reward(thisStep);
            return;
        } else {
            endSteps = endSteps - 1;
            thisStep = thisStep + 1;
            this.setState({ endSteps, thisStep });
        }
        if (endSteps < 5) {
            interval = Math.min(1000, interval + 200);
        } else {
            interval = Math.max(50, interval - 200);
        }
        runner = setTimeout(this.startAnimation.bind(this, interval), interval);
    }
    async reward() {
        let { reward } = this.state;
        //结束抽奖，给出提示
        const thisItem = buttons.filter((item) => { console.log(item); return parseInt(reward.index) % 8 === item.step })[0];
        if (thisItem.type === 'none') {
            JsBridge.runAction('alert', 'error', ['很遗憾，这次没有中奖，请再试试吧！']);
            this.setState({ isBegin: false });
            return;
        }
        let drawName = ''
        if (thisItem.type === 'gift') {
            drawName = `恭喜您中得${thisItem.name}\r\n可以在兑换记录中查看`
        } else {
            drawName = `恭喜您中得${thisItem.name}\r\n可以在积分明细中查看`
        }
        const result = await JsBridge.runAction('immersivemodal', 'show', [`${drawName}`, { background: window.location.origin + modalBackground, backIcon: reward.imgUrl },
        [
            {
                name: '查看详情',
                style: { color: '#999' }
            },
            {
                name: '继续抽奖'
            }
        ]
        ]);
        if (result === '查看详情') {
            JsBridge.runAction('immersivemodal', 'hide', []);

            if (reward.type === 'integral') {
                JsBridge.navigate('IntegralDetail');
            } else {
                this.getScoreRecord();
            }
        } else {
            JsBridge.runAction('immersivemodal', 'hide', []);
        }
        this.initInfo("1");
        // 刷新跑马灯
        // if (thisItem.type === 'integral') {
        //     alert(thisItem.availablePoint);
        //     alert(point + parseInt(thisItem.availablePoint, 10));
        //     point = point + parseInt(thisItem.availablePoint, 10);
        // }
        this.setState({ isBegin: false });
    }
    render() {
        const { stars, showLight, isBegin, thisStep, point, inviteNewClientPoint, inviteNewClientCount, remainTimes, scrollY, statusBarHeight } = this.state;
        const rate = scrollY / 100;
        const remainTimesMap = [{
            title: '可用积分',
            info: point
        }, {
            title: '邀请好友',
            info: inviteNewClientCount || inviteNewClientCount + '人'
        }, {
            title: '剩余抽奖次数',
            info: remainTimes || remainTimes + '次'
        }];
        const remainItem = remainTimesMap.map((item, index) => {
            return <div className={styles.remainItem}>
                {item.title}
            </div>;
        })
        const remainNums = remainTimesMap.map((item, index) => {
            return <div className={`${styles.remainItem} ${styles.itemInfo}`}>
                {item.info}
            </div>;
        })
        return <div className={styles.activityContainer}>
            {this.state.showModal ?
                <div className={styles.modal}  onClick={() => {
                    this.setState({
                        showModal: false
                    });
                }}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalMain}>
                            <img src={modal} alt="图片分享" onClick={() => {
                                this.getMoreTimes();
                            }} />
                        </div>
                        <div className={styles.colsePic} >
                            <img src={colsePic} alt="关闭" onClick={() => {}} />
                        </div>
                    </div>
                </div> : <></>}
            <div className={styles.banner} style={{ height: statusBarHeight + 'px' }}></div>
            <div className={styles.drawWrap}>
                <div className={styles.back_rule} style={{ paddingTop: statusBarHeight + 'px', background: `rgba(255,255,255,${rate})` }}>
                    <div className={styles.backWrap}>
                        <div className={styles.back_area} onClick={() => { JsBridge.back() }}>
                            <img src={rate > 0.5 ? blackBack : back} alt='退回' className={styles.back_icon} />
                        </div>
                        <div onClick={() => { JsBridge.open("/front/activities/shareOfficer/rules") }}><span className={styles.activietyRules} style={{ color: rate > 0.5 ? 'black' : 'white' }}>活动规则</span></div>
                    </div>
                </div>
                <div className={styles.integralDrawWrap}>
                    <Marquee lotteryRecordList={this.state.lotteryRecordList} />
                    <div className={styles.drawWraps}>
                        <div className={showLight ? `${styles.drawBorder}` : `${styles.drawBorder} ${styles.light}`}>
                            {stars.map((item, key) => {
                                return <i key={key} className={styles.circle}></i>;
                            })}
                            {isBegin ? <div className={styles.drawMask}></div> : false}
                            {buttons.map((button, key) => {
                                if (button.type === 'start') {
                                    return <div className={styles.drawStart} key={key} onClick={this.getAward.bind(this, 0)}>
                                        <h2>开始抽奖</h2>
                                    </div>
                                }
                                return <div onClick={() => {
                                    if (button.click && typeof (button.click === 'function')) {
                                        button.click();
                                    }
                                }} className={thisStep % 8 === button.step ? `${styles.drawButton} ${styles.active}` : `${styles.drawButton}`} key={key}>
                                    <img src={button.img} alt='奖品' />
                                    <p>{button.name}</p>
                                </div>;
                            })}

                        </div>
                    </div>
                </div>
                {/* 查看抽奖记录按钮 */}
                <div className={styles.button_area}>
                    <div className={styles.button_item} onClick={this.getRewardRecord.bind(this)}>
                    </div>
                    <div className={styles.button_item} onClick={this.getScoreRecord.bind(this)}>
                    </div>
                </div>
                <div className={styles.drawLine}>
                    <div className={styles.remainBox}>
                        {remainItem}
                    </div>
                    <div className={styles.remainBox}>
                        {remainNums}
                    </div>
                </div>
                {/* 获取抽奖次数按钮 */}
                <div className={styles.getMoreTimes} onClick={this.getMoreTimes}>
                    获取抽奖次数
                </div>
                {/* <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalMain}>
                            <img src={modal} alt="图片分享" />
                        </div>
                    </div>
                </div> */}
            </div>
            <ExchangeScore inviteNewClientPoint={inviteNewClientPoint} gift={this.state.gift} />
        </div>
    }
}