import React, {useEffect, useState} from 'react';
import styles from "./guide.module.scss";
import btnImg from './img/btn-bg.png';
import gotoBrowserImg from 'images/goto-browser.png';
import img88 from './img/poster-88.png';
import img488 from './img/poster-488.png';
import Library from 'library';

const appSchema = 'gktapp://com.gkewang';
// const downloadUrl = 'https://app.gkewang.com/index';
const downloadUrl = 'https://app.gkewang.com/app';

export const Guide = () => {
    const {
        classify = "88_product",
        code = '',
        collectionId = '',
        type = 'digitalCollection'
    } = Library.Util.url.paramsToObj();
    const [maskShow, setMaskShow] = useState(false);
    const params = {
        classify,
        code,
        collectionId,
        type,
    }

    const URL_88 = `${process.env.REACT_APP_GKT_LINK}/front/activities/waic/gather`;
    const URL_488 = `${process.env.REACT_APP_NFT_LINK}/saleDetail`;
    const targetUrl = classify === '88_product' ? URL_88 : URL_488;
    // const webTem = classify === '88_product' ? 'Web' : 'CustomWeb';
    const webTem = 'CustomWeb';
    const luanchUrl = `${appSchema}/screen/${webTem}?uri=${encodeURIComponent(`${targetUrl}?${Library.Util.url.objectToQueryParams(params)}`)}`;


    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setMaskShow(true);
        } else {
            window.location.href = luanchUrl;
            setTimeout(() => {
                window.location.href = downloadUrl;
            }, 3000);
        }
    }

    return <div className={styles.activity_page}>
        <img src={classify === '88_product' ? img88 : img488} alt=""/>
        <div className={styles.btn_box} onClick={() => openApp()}>
            <img src={btnImg} alt=""></img>
        </div>
        {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
            <img src={gotoBrowserImg} alt=""/>
        </div>}
    </div>
}

