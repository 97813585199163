/**
 * @description 积分抽奖-抽奖记录-实物订单详情界面
 * route: /front/activities/lottery/orderDetail
 * @param { * }
 *
 */

import React, {useEffect, useState, useRef} from 'react';
import {Toast} from "antd-mobile";
import Services from 'services';
import Library from 'library';
import Header from "components/header";
import './index.scss';
import moment from 'moment';

const {JsBridge, Util} = Library;

export const OrderDetail = () => {

    const orderInfo = Util.url.paramsToObj();

    const [addressItem, setAddressItem] = useState({})

    useEffect(() => {

    }, [])

    const initData = async () => {
        const result = await Services('MainPage', {code: '101261'}, {orderId: orderInfo.id})
        console.log(result)
        if (result.status > 0) {
            setAddressItem(result.data)
        }
    }

    const onRemind = () => {
        Toast.show({
            icon: 'loading',
            duration: 500,
            afterClose: () => {
                Toast.show('已提醒发货')
            }
        })
    }

    return (
        <div className='order_detail_page'>
            <Header midText={`订单详情`}/>
            <div className='body_content'>
                {orderInfo.idf === 'mail' && <div
                    className='order_status'>{orderInfo.deliverStatus ? orderInfo.deliverStatus === '0' ? '未发货' : '已发货' : ''}</div>}
                {/* 地址信息 */}
                <div className="address-item">
                    <img src={require('images/address_icon.png')} alt=""/>
                    <div className="address-center">
                        <div className="user_info">
                            <span>{decodeURI(orderInfo.consigneeName || '')}</span>
                            <span>{orderInfo.consigneeNumber || ''}</span>
                        </div>
                        {orderInfo.idf === 'mail' ?
                            <div className="address-info">{decodeURIComponent(orderInfo.deliveryAddress)}</div> :
                            <div className="address-info">
                                {decodeURI(orderInfo.province) + decodeURI(orderInfo.city) + decodeURI(orderInfo.area) + decodeURI(orderInfo.street || '') + decodeURI(orderInfo.address || '')}
                            </div>}
                    </div>
                </div>
                {/* 商品信息 */}
                <div className="shop-item" style={{display: orderInfo.hide == 1 ? 'none': 'block'}}>
                    <div className="item_top">
                        <div className="left">
                            <img src={orderInfo.prizeImgUrl} alt=""/>
                        </div>
                        <div className="right">
                            <span>{decodeURI(orderInfo.prizeName || '')}</span>
                            {
                                orderInfo.points ? <div className={'right-point'}>
                                    <img src={require('../../../../images/icon_tjf.png')} alt=""/>
                                    <span>{orderInfo.points / 1000}kg</span>
                                </div> : null
                            }
                        </div>
                    </div>
                    <div className={'item_bottom'}>
                        <div>配送服务</div>
                        <span>快递免邮</span>
                    </div>
                </div>
                {/* 订单信息 */}
                <div className='order-info' style={{display: orderInfo.hide == 1 ? 'none': 'block'}}>
                    {orderInfo.orderId && <div>
                        <span>订单编号：</span>
                        <span>{orderInfo.orderId || ''}</span>
                    </div>}
                    <div>
                        <span>下单时间：</span>
                        <span>{orderInfo.createTime ? moment(decodeURIComponent(orderInfo.createTime)).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
                    </div>
                </div>
                {/* 提醒发货 */}
                {orderInfo.idf === 'mail' && orderInfo.deliverStatus === '0' && <div className='remind'>
                    <span onClick={onRemind}>{'提醒发货'}</span>
                </div>}
            </div>
        </div>
    )
}


