import React, {useState} from 'react';
import styles from "./guide_test.module.scss";

export const GuideTest = () => {


    return <div className={styles.container}>
        <div className={styles.div_1}/>
    </div>

}

