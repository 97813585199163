export const throttle = (fn, duration = 1000) => {
    let lastTime = 0;
    const callback = (...args) => {
        const now = new Date().getTime();
        // eslint-disable-next-line no-undef
        console.log(now, lastTime, now - lastTime >= duration);
        if (now - lastTime >= duration) {
            // eslint-disable-next-line no-undef
            fn.apply(this, args);
            lastTime = now;
        }
    };

    callback.cancel = () => {
        clearTimeout(lastTime);
    };

    return callback;
};
