import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import Tab from '../components/tab';
import vsIcon from '../images/icon_vs.png';
import noData from '../../../../images/noCollection.png';
import successTag from '../images/icon_caizhon.png';
import failTag from '../images/icon_weicaizhon.png';
import shengli from '../images/shengli.png';
import Progress from '../components/progress';
import Services from 'services';
import {isIOS} from '../../../../library/util/publicFun';
import Library from 'library';

const { Util, JsBridge } = Library;

const tabs = [{title: '历史竞猜', key: 1}, {title: '未来赛事', key: 2}];

const MatchDetail = () => {
  const { currentIndex } = Util.url.paramsToObj() || {};
  const [tabIndex, setTabIndex] = useState(Number(currentIndex));
  const [matchList, setMatchList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    getMatchList(tabs[tabIndex].key, true);
  }, [tabIndex]);

  const clickTab = async (i) => {
    setTabIndex(i);
    setIsLoad(false);
    setMatchList([]);
    await getMatchList(tabs[i].key, true);
    document.getElementsByClassName('world-cup-match-detail')[0].scrollTop = 0 + 'px';
  }

  const getMatchList = async (key, isRefresh) => {
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      type: key
    }
    const result = await Services('MainPage', { code: '101269' }, params);
    JsBridge.print(result);
    setIsLoad(true);
    if(result.status > 0) {
      setMatchList(result.data?.list || []);
    }
  }

  return <div className='world-cup-match-detail'>
    <Tab tabs={tabs} onClick={clickTab} currentTab={currentIndex}/>
    {matchList?.length > 0 ? matchList.map((item, index) => {
      return <MatchDetailItem tabIndex={tabIndex} item={item} key={`item-${index}`}/>
    }): isLoad ? <div className='no-data'>
      <img src={noData} alt="" />
      <span>暂无数据</span>
    </div>: null}
  </div>
}

const MatchDetailItem = ({tabIndex, item}) => {
  const {
    number,
    round,
    group,
    homeTeamName,
    visitingTeamName,
    homeTeamFlag,
    visitingTeamFlag,
    gameStartTime,
    homeTeamRegularScore,
    visitingTeamRegularScore,
    homeTeamFinalScore,
    visitingTeamFinalScore,
    scoreStatus,
    winRatio,
    evenRatio,
    loseRatio,
    gameResult,
    guessResult,
    myGuessResult
  } = item || {};
  const isIos = isIOS();
  const joinInGuess = () => {
    if(!myGuessResult) return;
    JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/activities/worldCupGuess/recordDetail?number=${number}`});
  }
  return <div className='match-detail-item'>
    {(guessResult && tabIndex === 0 && scoreStatus=== '2') && <div className="guess-result-tag"><img src={guessResult === '2' ? successTag: failTag} alt="" /></div>}
    <div className="match-detail-item-title">
      {number > 48 ? <span>第{number}场 {group}</span>: <span>第{number}场 {group}组第{round}轮</span>}
      {tabIndex === 0 ? <span>{scoreStatus=== '2'? '已结束': '已结束 待揭晓'}</span>: <span>{gameStartTime?.slice(5,16)+'开赛'}</span>}
    </div>
    {gameResult === "3" && <img className={isIos ? 'shengli-left-tag': 'android-shengli-left-tag'} src={shengli} alt="" />}
    {gameResult === "0" && <img className={isIos ? 'shengli-right-tag': 'android-shengli-right-tag'} src={shengli} alt="" />}
    <div className={`battle-info ${tabIndex === 1 ? 'battle-info-bottom': ''}`}>
      <div className="battle-info-top">
        <span>{homeTeamName}</span>
        <img className='contury-flag' src={homeTeamFlag} alt="" />
      </div>
      <div className="battle-info-middle">
        <div className="battle-info-middle-score">
          {tabIndex === 0 && scoreStatus=== '2' &&  <span>{homeTeamFinalScore}</span>}
          <img src={vsIcon} alt="" />
          {tabIndex === 0 && scoreStatus=== '2' &&  <span>{visitingTeamFinalScore}</span>}
        </div>
      </div>
      <div className="battle-info-top">
        <span>{visitingTeamName}</span>
        <img className='contury-flag' src={visitingTeamFlag} alt="" />
      </div>
      {tabIndex === 0 && number > 48 && scoreStatus=== '2' && <div className="routine-time-score">
        常规比赛时间比分：{homeTeamRegularScore}:{visitingTeamRegularScore}
      </div>}
    </div>
    {tabIndex === 0 && <div className="pop-progress">
      <Progress successPercentage={winRatio} failPercentage={loseRatio} flatPercentage={evenRatio} />
    </div>}
    <div
      className={`guess-btn ${tabIndex === 0 ? myGuessResult ? 'participating': 'not-participating': 'not-participating btn-top'}`}
      onClick={() => joinInGuess()}
    >
      {tabIndex === 1 ? '竞猜未开始': myGuessResult ? '查看竞猜详情': '未参与竞猜'}
    </div>
  </div>
}

export default MatchDetail;
