/**
 * @description 2023中秋国庆活动
 * @returns route: /front/activities/midAutumn
*/

import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { Header,RulesModal } from 'components';
import Library from 'library';
import Service from '../service';
import ShopRecommend from '../components/shopRemmend';
import Styles from './styles.module.scss';
import GiftList from '../components/giftList';
import WinnerSwiper from '../components/winnerSwiper';
import PopupShare from '../components/popupShare';
import ShareModal from "../components/shareModal";
import GetDrawModal from "../components/getDrawModal";

import Interceptor from '../components/interceptor';
import PopupModal from "../components/inviteRecord";
const { JsBridge } = Library;


export default (props)=> {
    const {clientId} = Library.Util.url.paramsToObj();

    const [activityInfo, setActivityInfo] = useState({})
    const [shareConfig, setShareConfig] = useState({})

    useEffect(()=>{
      if(clientId) {
          Service.shareActivityTask({clientId});
      }
        loadData()
    },[])

    const loadData = async ()=>{
        await refeshData(true)
        const result = await Service.getShareConfig()
        if(result?.data) {
            setShareConfig(result.data)
        }else {
            setShareConfig({status: 0})
        }
    }

    const refeshData = async (showLoading = false)=> {
        const result = await Service.getInitData(showLoading)
        JsBridge.print('result====>', result)
        if(result?.data) {
            setActivityInfo(result.data)
        }
        return result?.data

    }

    // 活动规则
    const ruleClick =()=>{
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)'},
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }
    // 活动分享
    const shareActyClick = ()=>{
        PopupShare.open({
            activityCode: Service.activityCode, 
            inviteCode: activityInfo.inviteCode,
            shareConfig
        });
    }

    // 邀新
    const onInvite = ()=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        ShareModal.open({activityCode: Service.activityCode, inviteCode: activityInfo.inviteCode })
    }
    // 邀新记录
    const onInviterRecord = ()=> {
        PopupModal.open(Service.activityCode);
    }

    //领取奖励
    const onReceive = async (st)=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        // 查询库存
        const res = await Service.queryGiftStock({skuId: st.goodsId})
        if(!res) return;
        if(res.data) {
            // 有库存
            GetDrawModal.open({
                prizeInfo: {},
                callBack: async ()=> {
                    const result = await Service.receiveGift({skuId: st.goodsId});
                    if(result) {
                        setTimeout(()=>{
                            refeshData()
                        },500)
                        return result;
                    }
                    return false;
                }
            })
        }else {
            GetDrawModal.open({})
            setTimeout(()=>{
                refeshData()
            },500)
        }
        
    }
    // 领取记录
    const onReceiveRecord = ()=> {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&idf=commonSub` });
        } else {
            props.history.push(`/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&&idf=commonSub`)
        }
    }

    return (
      <div className={Styles.magpiePage}>
        {
          JsBridge.hasWebViewBridge() &&
          <Header bgColor={'transparent'} />
        }
        <div className={Styles.page_container}>
          <div className={Styles.bgTop}>
            {/* 顶部按钮布局 */}
            <div className={Styles.activityBtns}>
              <div className={Styles.activityRule}>
                <button onClick={()=>ruleClick()}></button>
              </div>
              {
                shareConfig.status === 0 ? null:
                <div className={Styles.activityShare}>
                  <button onClick={()=>shareActyClick()}></button>
                </div>
              }
            </div>
          </div>
          <div className={Styles.magpiePage_wraper}>
            <div className={Styles.b}>
              <div className={Styles.tip_title}>
                <img className={Styles.titleImg} src={require('images/midAutumn/inviter-title.png')} alt="" />
                <span>{`奖品四选一，每人仅可领取一次！每成功邀新一人再送200积分`}</span>
              </div>
              {/* 邀新送福利 */}
              <div className={Styles.center}>
                <GiftList activityInfo={activityInfo} onReceive={onReceive} onInvite={onInvite}/>
                <img className={Styles.inviter_now} src={require('images/midAutumn/inviter_now_btn.png')} alt="" onClick={onInvite}/>
                <div className={Styles.records}>
                  <div className={Styles.inviter_record} onClick={onInviterRecord}>{'邀新记录>'}</div>
                  <span className={Styles.check_record} onClick={onReceiveRecord}>{'领取记录>'}</span>
                </div>
              </div>
              <div className={Styles.swiper_container}>
                <WinnerSwiper records={activityInfo.prizeRecord}/>
              </div>
            </div>
            {/* <div className={Styles.give_title}>
              <img className={Styles.gift_title} src={require('images/midAutumn/gift_title.png')} alt="" />
              <span>购买中秋国庆活动商品订单金额满<span>1314元</span>，赠蜜丝婷防晒霜<span>两份</span>！</span>
            </div> */}
            {/* 购物赠好礼 */}
            {/* <div className={Styles.give_gift}>
              <div className={Styles.gift_shop}>
                <div className={Styles.gift_shop_content} onClick={()=>{
                  console.log('查看商品详情')
                  const skuId = activityInfo.prizeInfo?.skuId
                  JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
                }}>
                  <img className={Styles.shopImg} src={activityInfo.prizeInfo?.img} alt="" />
                  <div className={Styles.shopInfo}>
                    <span className={Styles.shopDesc}>{activityInfo.prizeInfo?.prizeName}</span>
                    <span className={Styles.free}>{'免费送'}</span>
                  </div>
                </div>
              </div>
            </div> */}
            {/* 商品推荐 */}
            <ShopRecommend />
            {/* 底部logo */}
            <div className={Styles.footer_logo}>
              <img src={require('images/midAutumn/bottom-logo.png')} alt="" />
            </div>
          </div>
          <Interceptor />
        </div>
      </div>
    )
}
