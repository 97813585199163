/**
 * @description 2023母亲节活动
 * @returns route: /front/activities/mothersDay
*/

import React, { useState, useEffect } from 'react';
import { Toast, Popup } from 'antd-mobile';
import { Header, RulesModal } from 'components';
import Library from 'library';
import ChatCard from '../components/chatCard';
import LikesProgress from '../components/likesProgress';
import WinnerSwiper from '../components/winnerSwiper';
import ShopRecommend from '../components/shopRecommend';
import RecordPopup from '../components/recordPopup';
import { ReceivePrizeRecord } from '../receive-prize-record';
import LandingPage from '../landingPage';
import ShareModal from '../components/shareModal';
import GiveLoveModal from '../components/giveLoveModal';
import BarrageWall from '../barrage-wall';
import Service from '../service';
import './index.scss';

export default (props) => {
    // 分享链接携带祝福语id
    const {blessingId} = Library.Util.url.paramsToObj();
   
    const [recordVisible, setRecordVisible] = useState(false)
    const [type, setType] = useState()
    const [activityInfo, setActivityInfo] = useState({})
    const [audio, setAudio] = useState(false)

    useEffect(()=>{
        loadData()
        
    },[])

    const loadData = async ()=>{
        await refeshData(true)
        if(blessingId) {
            const reslut = await Service.getBlessingsById(blessingId)
            if(reslut) {
                const blessingInfo = reslut.data
                GiveLoveModal.open({activityCode: Service.activityCode, activityInfo:blessingInfo});
            }
            
        }
    }

    const refeshData = async (showLoading = false)=> {
        const result = await Service.getMothersDayData(showLoading)
        if(result?.data) {
            setActivityInfo(result.data)
        }
        return result?.data
    }

    // 活动规则
    const onRuleClick = ()=> {
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)'},
            activityCode: Service.activityCode, 
            codeType: 'activityRule', 
            code: 'activityMothersDayRule'
        });
    }
    // 分享邀新
    const onNewShareClick = ()=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        ShareModal.open({activityCode: Service.activityCode, inviteCode:activityInfo.inviteCode});
        
    }
    // 查看所有祝福
    const checkAllBless = ()=> {
        setAudio(true)
        // if (window.JsBridge.hasWebViewBridge()) {
        //     window.JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/barrage-wall?blessingId=${activityInfo.blessingId}`});
        // }else {
        //     props.history.push(`/front/activities/barrage-wall?blessingId=${activityInfo.blessingId}`)
        // }
    }
    // 发送祝福语
    const onSendBless = async (text)=> {
        const result = await Service.sendBlessings(text)
        if(result) {
            Toast.show('发送成功！');
            //刷新界面
            refeshData()
        }
    }

    // 点赞/邀新详情
    const onGiveOrInviteClick = (type) => {
        setType(type)
        setRecordVisible(true)
       
    }

    // 领奖记录
    const onReceivePrizeRecord = ()=> {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/mothersDay/receive-prize-record`});
        }else {
            props.history.push('/front/activities/mothersDay/receive-prize-record')
        }
    }

    return (
        <div className='mothers_day_page'>
            <Header bgColor={'transparent'} />
            <div className='mothers_day_page__body'>
                <div className='top-box'>
                    <div className='top-fixed-btns'>
                        <div onClick={onRuleClick}>活动规则</div>
                        <div onClick={onNewShareClick}>分享邀新</div>
                    </div>
                </div>
                <div className='center-box'>
                    <div className='box-title'>
                        <div className='d-zan'>
                            <div>母情节祝福语点赞送好礼</div>
                            <span>每人仅有一次点赞机会</span>
                        </div>
                        <div className='check-btn' onClick={checkAllBless}><span>查看所有祝福</span></div>
                    </div>
                    
                    <ChatCard activityInfo={activityInfo} onSendBless={onSendBless}/>
                    <div className='detail-models'>
                        <div onClick={()=>onGiveOrInviteClick('give')}>{'点赞详情 >'}</div>
                        <div onClick={()=>onGiveOrInviteClick('invite')}>{'邀新详情 >'}</div>
                    </div>
                    <LikesProgress activityInfo={activityInfo} refreshActivityInfo={refeshData}/>
                    <WinnerSwiper 
                        records={activityInfo.records}
                        detail={<span onClick={onReceivePrizeRecord}>{'领取详情 >'}</span>}
                    />
                </div>
                <div className='bottom-box'>
                    <ShopRecommend />
                </div>
            </div>
            <RecordPopup visible={recordVisible} type={type} close={()=> setRecordVisible(false)}/>
           {
                <Popup visible={audio} position='right' destroyOnClose>
                   <BarrageWall onBack={()=>{
                        setAudio(false)
                    }}/>
                </Popup>
           }
            
        </div>
    )
}

export {
    ReceivePrizeRecord,
    LandingPage
}