/**
 * 发送祝福语和发送祝福语通过后的祝福语卡片
*/
import React, { createRef, useEffect, useState } from 'react';
import { Toast, TextArea } from 'antd-mobile';
import ShareHelpModal from '../shareHelpModal';
import './index.scss';

const ChatCard = (props) => {

    const { activityInfo={}, onSendBless } = props

    const textAreaRef = createRef()
    const [blessingWords, setBlessingWords] = useState()

    useEffect(()=>{
        setBlessingWords(activityInfo.blessingWords)
    },[activityInfo])

    // 发送祝福
    const sendBless = ()=> {
        const text = textAreaRef?.current.nativeElement.value;
        if(text?.length > 0){
            onSendBless && onSendBless(text)
        }else{
            Toast.show('请输入祝福语')
        }
        
    }
    // 分享点赞
    const shareAction = ()=> {
        ShareHelpModal.open({activityCode: 'mothersDay', activityInfo:activityInfo});
    }

    return (
        <div className='chat-card'>
            {/* blessingStatus  0: 未审核 1: 审核通过 2: 审核拒绝 */}
            {
                activityInfo.blessingStatus == 1 ?
                <div className='card-container'>
                    <img className='mark-img' src={require('images/mothersDay/mother-me.png')} alt="" />
                    <div className='mail-content'>
                        <span>{'@妈妈，我有话对您说:'}</span>
                        <div className='my-blessing'>{activityInfo.blessingWords}</div>
                        <div className='mail-end'>
                            <span>{activityInfo.blessingMobile}</span>
                            <span>{activityInfo.blessingCreateTime}</span>
                        </div>
                    </div>
                    <div className='bottom-share'>
                        <div className='share-btn' onClick={shareAction}>
                            <span>分享点赞</span>
                            <span>{`邀请新用户即送${activityInfo.sendInvitePoints || 200}积分`}</span>
                        </div>
                        <img src={require('images/mothersDay/mothers-home-zan.png')} alt="" />
                        <span>{activityInfo.thumbsUpAmount || '0'}</span>
                    </div>
                    
                </div>
                :
                <div className='chat-input'>
                    <span>{'@妈妈，我有话对您说:'}</span>
                    <div className='input-content'>
                        <TextArea 
                            ref={textAreaRef}
                            className='row-textarea'
                            placeholder='写下您对妈妈的祝福' 
                            readOnly={activityInfo.blessingStatus == 0}
                            showCount 
                            maxLength={30}
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            rows={1}
                            value={blessingWords}
                            onChange={e=> {
                                setBlessingWords(e)
                            }}
                        />
                    </div>
                    <div className='btn-status'>
                        {
                           activityInfo.blessingStatus == 2 && <span>审核未通过，请重新提交</span>
                        }
                        {
                            activityInfo.blessingStatus != 0 ?
                            <div className='enable-btn' onClick={sendBless}>
                                <span>发送祝福</span>
                                <span>{`祝福成功得${activityInfo.sendBlessingPoints || 100}积分`}</span>
                            </div>
                            :<div className='disable-btn'>
                                <span>审核中...</span>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ChatCard;