
/**
 * @description 儿童节活动
 * @returns route: /front/activities/childrensDay
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Swiper, Toast } from 'antd-mobile';
import { Header, RulesModal } from 'components';
import Library from 'library';
import Service from '../service';
import ShareInviteModal from '../components/shareInviteModal';
import ShareHelpModal from '../components/shareHelpModal';
import RecordPopup from '../components/recordPopup';
import ReceiveModal from '../components/receiveModal';
import LikesModal from '../components/likesModal';
import ExihbitBox from '../components/ExhibitBox';
import ProgressBar from '../components/ProgressBar';
import GoodsTabs from '../components/GoodsTab'
import styles from './styles.module.scss';

const { JsBridge } = Library;

export default (props) => {
    // 分享链接携带的用户邀请码
    const {userCode} = Library.Util.url.paramsToObj();

    const [recordVisible, setRecordVisible] = useState(false)
    const [type, setType] = useState()
    const [activityInfo, setActivityInfo] = useState({})

    const records = useMemo(()=>{
        let list = [{loginName: '154****6789',prizeName: '一花一朵伴手礼'},{loginName: '136****8754',prizeName: '一花一朵伴手礼'}];
        list = [...list,...list,...list]
        if(activityInfo.records?.length) {
            list = activityInfo.records
            if(list.length < 5){
                list = [...list,...list]
            }
        }
        return list
    },[activityInfo])

    useEffect(() => {
        loadData()
        if(userCode) {
            LikesModal.open({activityCode: Service.activityCode, userCode, call: ()=>{
                loadData()
            }});
        }
    }, [])

    const loadData = async () => {
        const result = await Service.getInitData()
        if (result?.data) {
            setActivityInfo(result.data)
        }
    }

    // 活动规则
    const onRuleClick = () => {
        RulesModal.open({
            btnStyle: { background: 'linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)' },
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: 'child2023Rule'
        });
    }

    // 分享邀新
    const onNewShareClick = () => {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if (isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        ShareInviteModal.open({ activityCode: Service.activityCode, inviteCode: activityInfo.inviteCode });

    }
    // 分享点赞
    const shareAction = () => {
        ShareHelpModal.open({ ...activityInfo, userCode: activityInfo.userCode, activityCode: Service.activityCode });
    }
    // 点赞/邀新详情
    const onPopupClick = (type) => {
        setType(type)
        setRecordVisible(true)

    }

    // 领奖记录
    const onReceiveRecord = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/activities/childrensDay/receive-record-page` });
        } else {
            props.history.push('/front/activities/childrensDay/receive-record-page')
        }
    }

    // 领取奖励
    const onReceiveGive = async (st) => {
        console.log('领取奖励')
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if (isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        const result = await Service.receiveGift({ type: st.type });
        JsBridge.print(result);
        if (result) {
            ReceiveModal.open({ callBack: null, prizeInfo: result.data });
            setTimeout(() => {
                loadData()
            }, 500)

        }

    }

    return (
        <div className={styles.container}>
            <Header bgColor={'transparent'} />
            <div className={styles.rule_style} onClick={onRuleClick}>活动规则</div>
            <div className={styles.wraper}>
                <div className={styles.wraper_content}>
                    <div className={styles.activity1}>
                        <div className={styles.content}>
                            邀请新用户注册成功送 <span>{activityInfo.sendInvitePoints || 0}</span> 积分
                            <br />
                            积分可抵现金
                        </div>
                        <div className={styles.detailBtn} onClick={() => onPopupClick('invite')}>邀新详情&gt;&gt; </div>
                        <button onClick={onNewShareClick}>立即邀请</button>
                    </div>
                    <div className={styles.activity2}>
                        {
                            [{text:'奖品一：小黄人(Minions)折叠购物袋', count: 20, img:require('images/childrensDay/shop1.png')},
                            {text:'奖品二：uooha双饮杯350ml(李佳琦推荐爆款）',count: 50, img:require('images/childrensDay/shop2.png')}].map((item,k)=>{
                                return  <ExihbitBox key={k} img={item.img} text={item.text} count={item.count}/>
                            })
                        }
                    </div>
                    <ProgressBar activityInfo={{...activityInfo}} onReceive={onReceiveGive} />
                    <div className={styles.zanDetailBtn} onClick={() => onPopupClick('give')}>点赞详情&gt;&gt; </div>
                    <div className={styles.shareBtn} onClick={shareAction}>立即分享</div>
                    <div className={styles.row}>
                        <span>领取名单</span>
                        <span onClick={onReceiveRecord}>领取详情&gt;&gt;</span>
                    </div>
                    <Swiper
                        className={styles.swiper}
                        style={{
                            '--height': '2.7rem',
                        }}
                        indicator={() => null}
                        loop={true}
                        autoplay={true}
                        direction='vertical'
                        slideSize={33.3333}
                        allowTouchMove={false}
                    >
                        {
                            records.map((item, index) => (
                                    <Swiper.Item key={index}>
                                       {`${item.loginName}: ${item.prizeName}`}
                                    </Swiper.Item>
                                ))
                        }
                    </Swiper>
                </div>
                <GoodsTabs />
                <div className={styles.footer} />
            </div>
            <RecordPopup visible={recordVisible}
                type={type}
                close={() => setRecordVisible(false)}
            />
        </div>
    )
}
