const HelpConfig = {
    helpCenter: [
        {
            title: '一、注册相关问题',
            contents: [
                {
                    title: '1.自然人如何进行注册成为G优投资人？',
                    content: '自然人通过线上注册的方式成为会员。自然人登录APP点击【我的】，根据如下步骤完成投资流程：实名认证→银行卡绑定→风险评估→设置交易密码→投资交易。'
                },
                {
                    title: '2.非大陆居民是否可以通过本系统购买产品？',
                    content: '目前G优暂不支持非大陆居民购买产品。'
                },
                {
                    title: '3.注册的用户名有什么要求？',
                    content: '通过手机号可直接进行注册，用户名默认为注册的手机号。'
                },
                {
                    title: '4.注册对年龄有什么要求?',
                    content: '年龄需满足18周岁且具有完全行为能力。'
                },
                {
                    title: '5.系统登录支持哪些方式？',
                    content: '系统登录可支持注册手机动态码、用户名密码及指纹/人脸登录。'
                }

            ]
        }, {
            title: '二、密码相关问题',
            contents: [
                {
                    title: '1.设置密码有什么要求吗？',
                    content: '登录密码要求：长度为6-20个字符，至少包含数字、字母(区分大小写)、符号中的两种； 支付密码要求：长度为6位数字。'
                }, {
                    title: '2.忘记登录密码和支付密码怎么办？',
                    content: ['忘记登录密码，可以在登录页面通过【快捷登录】按钮使用手机验证码或指纹/人脸识别方式进行登录。', '用户登录后可以在【我的】→【安全中心】中对登录密码或交易密码进行重置。']
                }, {
                    title: '3.登录密码和支付密码如何变更？',
                    content: '用户登录后可以在【我的】→【安全中心】中对登录及支付密码进行修改/重置。'
                }, {
                    title: '4.如何开通或关闭指纹/人脸或手势密码？',
                    content: '用户登录后，可以在【我的】→【安全中心】进行开通或关闭操作。'
                }

            ]
        }, {
            title: '三、实名认证相关问题',
            contents: [
                {
                    title: '1.如何进行实名认证？',
                    content: '用户登录后，通过【我的】→【实名认证】根据提示进行实名认证。'
                }, {
                    title: '2.身份证最后一位如果是X要怎么输入？',
                    content: '直接输入字母“X”即可。'
                }, {
                    title: '3.15位的身份证可以进行实名认证吗？',
                    content: '目前暂时不支持。'
                }, {
                    title: '4.一个身份证可以绑定多个账号吗？',
                    content: '可以。'
                }, {
                    title: '5.实名认证失败被锁定怎么办？',
                    content: '用户当天累计实名认证失败5次将被锁定，无法继续进行实名认证，第二天零点自动解锁。'
                }

            ]
        }, {
            title: '四、绑卡相关问题',
            contents: [
                {
                    title: '1.如何绑定银行卡？',
                    content: '用户登录并完成实名认证后，通过【我的】→【银行卡】→【添加卡】根据提示进行绑卡。'
                }, {
                    title: '2.绑定的银行卡有什么要求？',
                    content: ['1)银行卡的持卡人必须和实名认证成功的是同一人。', '2)必须是借记卡（准贷记卡、贷记卡、借贷合一卡均不可用）。', '3)卡为正常流通卡（不能是注销/挂失/冻结等状态，避免充值异常）。']
                }, {
                    title: '3.目前支持哪些银行卡？',
                    content: '请在【我的】→【银行卡】→【添加卡】页面点击“查看支持的银行卡”获取并查看最新支持绑定的银行卡列表。'
                }, {
                    title: '4.是否可以绑定多张银行卡？',
                    content: '目前G优支持绑定多张银行卡。不同产品模块对应交易平台仅支持授权绑定一张交易卡，请谨慎操作。如遇到问题请联系客服协助解决。'
                }, {
                    title: '5.银行预留手机号与注册手机号不一致怎么办？',
                    content: '在绑定银行卡页面可以手动修改银行预留手机号后再进行提交。'
                }, {
                    title: '6.我不知道银行预留手机号怎么办？',
                    content: '可以与您借记卡的开户行进行确认。'
                }, {
                    title: '7.已经成功绑定的银行卡是否可以解绑？',
                    content: '暂不支持解绑操作。'
                }, {
                    title: '8.如何确认交易卡授权绑定成功？',
                    content: '可以前往【我的】点击对应产品模块查看交易卡授权绑卡状态。如遇到问题请联系客服协助解决。'
                }

            ]
        },
        // {
        //     title: '五、高端理财业务相关问题',
        //     contents: [
        //         {
        //             title: '1.如何完成高端理财所需的交易中心开户？',
        //             content: '首次点击产品信息并同意交易中心开户协议；在输入开户手机收到的验证码之后完成开户。'
        //         }, {
        //             title: '2.投资人充值是否有金额限制？',
        //             content: '具体限额可详见各银行的入金限额详情表。'
        //         }, {
        //             title: '3.充值后到账时间为多久？',
        //             content: '充值后，由银行进行实时扣款，客户可通过【我的】中对应理财账户的“可用余额”查看账户可用资金。'
        //         }, {
        //             title: '4.提现后到账时间为多久？',
        //             content: '提现后一般1-2小时内可以到账，具体时间以各银行的实际到账时间为准。'
        //         }, {
        //             title: '5. 交易中心提现是否存在金额限制？',
        //             content: '目前交易中心单笔提现限额为5000万元，每日提现限额为1亿元。'
        //         },
        //         {
        //             title: '6.充值、提现不成功如何处理？',
        //             content: '请联系客服咨询。'
        //         },
        //         {
        //             title: '7.客服的联系方式及工作时间？',
        //             content: ['客服热线：400-920-8888', '客服邮箱：service@greenlandfinancial.com', '客服工作时间为工作日9:00-17:00，若有国定假期则放假当天休息。']
        //         }

        //     ]
        // },
        {
            title: '五、基金业务相关问题',
            contents: [
                {
                    title: '1.新基金购买后能撤单吗？',
                    content: '认购的基金处于募集期，不支持撤单。'
                }, {
                    title: '2.新基金购买后什么时候确认？',
                    content: '新发基金份额确认一般是两次确认，T+2日可登录账户查看初步确认情况，待该基金成立后可在持仓中查看确认份额。'
                }, {
                    title: '3.刚买的基金什么时候可以赎回？',
                    content: '在基金份额确认（普通基金T+1个工作日，QDII基金T+2个工作日）后即可赎回。'
                }, {
                    title: '4.基金赎回多长时间到账？',
                    content: '不同类型的基金到账时间不同，货币型基金赎回到账时间一般为T+1个工作日；债券型基金、混合型基金、股票型基金赎回到账后时间一般为T+3个工作日；QDII赎回到账时间一般为T+7~9个工作日，个别基金除外，具体以基金公司确认数据为准。'
                }, {
                    title: '5. 什么叫惩罚性赎回费？',
                    content: '证监会发布了《公开募集开放式证券投资基金流动性风险管理规定》，规定除货币基金与交易型开放式指数基金以外（ETF），其余基金要对持有少于7日（自然日）的投资者收取不低于1.5%的赎回费，并将上述赎回费全额计入基金财产。'
                }
            ]
        }, {
            title: '六、其他问题',
            contents: [
                {
                    title: '1.怎样才能看到我的账户余额情况？',
                    content: '投资人登录后，通过【我的】点击“总资产”即可查询到各交易账户的余额情况。'
                }, {
                    title: '2.如何参加平台的各类活动？',
                    content: '活动的参与方式及规则请以活动页面中的“条款及细则”为准。也可以拨打客户电话进行咨询。'
                }

            ]
        }
    ]
};

export default HelpConfig;