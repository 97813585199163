import React from 'react';
import styles from './invite.module.scss';
import people from '../../registionActivity/images/people@2x.png'
import marks from '../../registionActivity/images/jifen@2x.png'
import arrow from '../../registionActivity/images/arrow@2x.png'
import iceCream from '../../registionActivity/images/ice_cream@2x.png'
import topRank from '../../registionActivity/images/top50@2x.png'
export const InviteCard = (props) => {
    const { buttonText, onClick, rewardPoint = 0 } = props;
    return <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.column_one}>
                <div className={[styles.icon_view]}>
                    <img className={styles.icon} src={people} />
                    <p className={styles.text}>每邀1人</p>
                </div>
                <img className={styles.arrow_image} src={arrow} />
                <div className={[styles.icon_view]}>
                    <img className={styles.icon_one} src={marks} />
                    <p className={styles.text}>{rewardPoint}积分</p>
                </div>
            </div>
            <div className={styles.column_two}>
                <p>{`每成功邀请1位好友下载绿地G优APP并注册登录，邀请人即得${rewardPoint}积分。`}</p>
            </div>
        </div>
    </div>
};
