/**
 * @description 邀新专区-邀新记录页
 * @returns route: /front/activities/regularShare/invite-record
 */

import React, { useState, useRef, useEffect } from "react";
import { Header, ListView } from "components";
import Service from "../invite-zone/service";
import Styles from "./index.module.scss";
import Library from "library";
const { Util } = Library;

const InviteRecord = () => {
  const params = Util.url.paramsToObj();
  const [dataList, setDataList] = useState([]);
  const [successCount, setSuccessCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const currentPage = useRef(1);
  const paramsObj = {
    activityCode:params.activityCode,
    activityCycleDetailId: +params.activityCycleDetailId,
    cycle: +params.cycle,
    pageSize: 20,
  };
  useEffect(() => {
    loadData(true);
  }, []);
  const hideMiddleDigits = (phoneNumber)=> {
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  }
  const loadData = async (loading = false) => {
    currentPage.current = 1;
    if (loading) {
      setLoading(true);
    }
    const res = await Service.getInviteList(
      {
        ...paramsObj,
        pageNum: currentPage.current,
      },
      loading
    );
    if (loading) {
      setLoading(false);
    }
    console.log("res", res);
    if (res?.data) {
      const { invitedBaseVOS = [], inviteCount } = res.data;
      setDataList(invitedBaseVOS);
      setSuccessCount(inviteCount);
      setHasMore(invitedBaseVOS.length >= paramsObj?.pageSize);
    }
  };
  const loadMore = async () => {
    const res = await Service.getInviteList(
      {
        ...paramsObj,
        pageNum: currentPage.current + 1,
      },
      loading
    );
    if (res?.data) {
      const { invitedBaseVOS = [] } = res.data;
      setDataList([...dataList, ...invitedBaseVOS]);
      setHasMore(invitedBaseVOS.length >= paramsObj?.pageSize);
      currentPage.current = currentPage.current + 1;
    }
  };

  const renderItem = (item) => {
    return (
      <div className={Styles.list_item}>
        <div className={Styles.item_left}>
          <p>{hideMiddleDigits(item.mobile)}</p>
          <span>{item.createTime}</span>
        </div>
        <div className={Styles.item_right}>
          <p className={`${item.inviteStatus === "FAIL" ? Styles.defeat : ""}`}>
            {
              {
                SUCCESS: "邀请成功",
                FAIL: "邀请失败",
                UNDERWAY: "邀请进行中",
              }[item.inviteStatus]
            }
          </p>
          {item.comment ? <span>{item.comment}</span> : null}
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.invite_record}>
      <Header midText={"邀新记录"} shadow />
      <div className={Styles.record_body}>
        <ListView
          dataSource={dataList}
          renderHeader={() => {
            return (
              <div className={Styles.list_header}>
                <img
                  src={require("images/activityShare/ponit_icon.png")}
                  alt=""
                />
                <span>{`您已成功邀新${successCount || '0'}人`}</span>
              </div>
            );
          }}
          emtyDesc={loading ? "数据加载中..." : "暂无数据"}
          renderItem={renderItem}
          hasMore={hasMore}
          onRefresh={loadData}
          loadMore={loadMore}
        />
      </div>
    </div>
  );
};

export { InviteRecord };
