/**
 * @description 领取奖励的弹框提示
*/
import React, { useEffect, useState,useRef } from 'react';
import styles from './index.module.scss';
export const SignModal = (props)=>{
    const [state,setState] = useState(props);
    useEffect(()=>{
        setState({...props})
    },[props.visible,props.content,props.title,props.buttons])
    return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
        <div className={styles.modalWrap}>
            <div className={styles.title}>{state.title}</div>
            <div className={styles.modalContent}>{state.content}</div>
            <div className={styles.modalButton}>
                {state.buttons && state.buttons.map((item, key) => {
                    let {className = '', click = ()=>{
                        setState({...state, visible:false})
                    }, name=''} = item;
                    let btnStyle = styles.cancel;
                    if (className === 'confirm') {
                        btnStyle = styles.confirm;
                    }
                    return <button className={`${btnStyle} ${state.buttons.length - 1 > key?styles.btnMargin:''}`} key={key} onClick={click}>{name}</button>;
                })}
            </div>
        </div>
    </div>
}
