/**
 * @description 公共分享弹窗：图片及二维码
 * @example { 
 *  ActivityShareModal.open({activityCode:'activityCode', inviteCode:'inviteCode'})
 * }
*/

import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import { Mask, Toast } from "antd-mobile";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { throttle } from 'library/util/publicFun';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_share_copy from "images/icon_share_copy.png";
import icon_share_down from "images/icon_share_down.png";
import icon_share_pyp from "images/icon_share_pyp.png";
import icon_share_wechat from "images/icon_share_wechat_1.jpg";
import Library from "library";
import Styles from './styles.module.scss';

const { JsBridge } = Library;

const ActivityShareModal = ({
  visible = false,
  onClose,
  shareUrl = '',
  className='',
  children = null,
  qrcodeConfig = {},
}) => {
    const [_visible, setVisible] = useState(false);
    const domRef = useRef(null);
    useEffect(() => {
      if (visible) {
        setVisible(true);
      } else {
        onCancel();
      }
    }, [visible]);
    
    const createShareImage = () => {
      return new Promise(async (resolve) => {
        html2canvas(domRef.current, {
          allowTaint: true,
          useCORS: true,
          scrollX: 0,
          scrollY: 0
        }).then(async (canvas) => {
          const result = canvas.toDataURL('image/jpeg', 0.8);
          resolve(result);
        }).catch(() => {
          resolve(false);
        });
      })
    }

    // 生成分享图片
    const getShareImagePath = () => {
      return new Promise(async (resolve) => {
        if (!domRef.current) {
          resolve(false);
        }
        let base64 = await createShareImage();
        if (base64) {
          let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
          if (!res) {
            JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
            resolve(false);
          } else {
            resolve(res.tempFilePath);
          }
        } else {
          resolve(false);
        }
      })
    }

    // 微信分享
    const wxShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
          let filePath = await getShareImagePath();
          window.JsBridge.share({
            text: '',
            icon: filePath,
            link: '',
            title: '',
            platform: 1,
          }).then(function (result) {
            shareToast && shareToast.close();
          })
      }
    }, 3000)

    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      if (window.JsBridge.hasWebViewBridge()) {
        let filePath = await getShareImagePath();
        window.JsBridge.share({
          text: '',
          icon: filePath,
          link: '',
          title: '',
          platform: 2,
        }).then(function (result) {
          shareToast && shareToast.close();
        })
      }
    }, 3000);

    // 下载图片
    const downloadImg = throttle(async () => {
      let shareToast = Toast.show({
        icon: 'loading',
        content: '加载中…',
      })
      let filePath = await getShareImagePath();
      if (JsBridge.hasWebViewBridge()) {
        JsBridge.requestPermission("requestAlbum")
        JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
          shareToast && shareToast.close()
          if (res) {
              Toast.show("图片保存成功");
          } else {
              Toast.show("图片保存失败")
          }
        })
      } else {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.download = `image-${new Date().getTime()}`
        a.href = filePath
        a.click()
        document.body.removeChild(a)
        shareToast && shareToast.close()
      }
    }, 3000)

    const onCancel = () => {
      setVisible(false);
      setTimeout(onClose, 1000);
    };

    const qrcodeProps = {
      value: shareUrl,
      size: 60,
      // imageSettings: {
      //   src: require('images/dark-logo.png'),
      //   x: null,
      //   y: null,
      //   height: 6,
      //   width: 6,
      //   excavate: false,
      // },
      ...qrcodeConfig,
    }

    return (
    <Mask visible={_visible} onMaskClick={onCancel} opacity={0.7} style={{
        overflow: 'auto',
        paddingBottom: '10px'
    }}>
      <div className={Styles.share_container + ' ' + className}>
        <div className="share-body" ref={domRef}>
          {children}
          <div className="qrcode">
            <QRCode {...qrcodeProps} />
          </div>
        </div>
        <div className='footer'>
          <div className={Styles.wechat} onClick={wxShare}>
            <img src={icon_share_wechat} alt=""/>
            <span>微信好友</span>
          </div>
          <div className={Styles.share} onClick={momentsShare}>
            <img src={icon_share_pyp} alt=""/>
            <span>朋友圈</span>
          </div>
          <CopyToClipboard text={shareUrl} onCopy={() => Toast.show('复制成功')}>
            <div className={Styles.copy}>
              <img src={icon_share_copy} alt=""/>
              <span>复制链接</span>
            </div>
          </CopyToClipboard>
          <div className={Styles.download} onClick={downloadImg}>
            <img src={icon_share_down} alt=""/>
            <span>下载图片</span>
          </div>
        </div>
        <div className="btn-cancel" onClick={onCancel}>取消</div>
      </div>
    </Mask>
    )
}

ActivityShareModal.open = ({ shareUrl = '', className = '', children = null, qrcodeConfig = {} }) => {
  const container = document.createElement('div');
  document.body.appendChild(container);
  const close = () => container.remove();
  ReactDOM.render(<ActivityShareModal
    shareUrl={shareUrl}
    onClose={close}
    className={className}
    qrcodeConfig={qrcodeConfig}
    visible
  >
    { children }
  </ActivityShareModal>, container);
}

export default ActivityShareModal;
