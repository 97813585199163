import Home from './home';
import MyRewards from './myRewards';
import EditAddress from './editAddress';
import Join from './join';
import Success from './success';
import {Rule} from './rule';
import {ShareModal} from './share';
export default [
    {
        path: '/activities/memberDay/home/:activityCode',
        component: Home,
        title: 'G优会员日'
    },
    {
        path: '/activities/memberDay/myrewards',
        component: MyRewards,
        title: '我的奖品'
    },
    {
        path: '/activities/memberDay/editAddress',
        component: EditAddress,
        title: '编辑收货地址'
    }, {
        path: '/activities/memberDay/join',
        component: Join,
        title: '会员日抽盲盒'
    }, {
        path: '/activities/memberDay/success',
        component: Success,
        title: '会员日抽盲盒'
    },
    {
        path: '/activities/memberDay/home/:activityCode/rule',
        component: Rule,
        title: 'G优会员日'
    },
    {
        path: '/activities/memberDay/home/:activityCode/share',
        component: ShareModal,
        title: 'G优会员日'
    },
]
