import { ALERT_SHOW, ALERT_HIDE } from '../../../constants';

export const alert = {
    show: (title, countdown) => {
        return async (dispatch) => {
            return await new Promise((success, error) => {
                return dispatch({
                    type: ALERT_SHOW,
                    data: {
                        title,
                        countdown,
                        success
                    }
                });
            });
        }
    },
    hide: () => {
        return { type: ALERT_HIDE };
    }
};
