import * as React from "react";
import { Tabs } from 'antd-mobile';
import Library from 'library';
import service from '../../service/index';
import styles from './styles.module.scss';

const { JsBridge } = Library;

const defaultData = [
  {
    cateId: '0',
    cateName: '童趣乐园',
    content: [
      {
        marketPrice: '12.12',
        maxDeductPoint: '',
        pic: 'http://img',
        goodsName: 'Moschino梦仙奴泰迪两瓶装',
        skuId: '',
        discount: '7'
      }
    ]
  },
  {
    cateId: '1',
    cateName: '学习园地',
    content: [
      {
        marketPrice: '12.12',
        maxDeductPoint: '',
        pic: 'http://img',
        goodsName: 'Moschino梦仙奴泰迪两瓶装',
        skuId: '',
        discount: '7'
      }
    ]
  },
  {
    cateId: '2',
    cateName: '快乐成长',
    content: [
      {
        marketPrice: '12.12',
        maxDeductPoint: '',
        pic: 'http://img',
        goodsName: 'Moschino梦仙奴泰迪两瓶装',
        skuId: '',
        discount: '7'
      }
    ]
  }
]

const TabHeaderItem = ({ title = '', active = false }) => (
  <div className={styles.headerItem + ` ${active ? styles.active : ''}`}>
    {title}
  </div>
);

const onShopItemClick = (skuId) => {
  JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
}

const renderItem = (item = {}, index) => {
  const originalPrice = Number(item.marketPrice);
  const pointPrice = item.maxDeductPoint / 100;
  const actualPrice = Math.max((originalPrice * (item.discount || 1)) - pointPrice, 0);
  return (
    <div className={styles.itemWrap} key={index} onClick={() => onShopItemClick(item.skuId)}>
      <div className={styles.thumb}>
        {
          item.pic && <img src={item.pic} alt="商品图片" />
        }
        <div className={styles.label}><span>购买返3倍积分</span></div>
      </div>
      <div className={styles.info}>
        <p>{item.goodsName}</p>
        <div className={styles.left}><span>¥{originalPrice}</span><span>原价</span></div>
        <div className={styles.right}><span>¥{pointPrice}</span><span>积分可抵</span></div>
        <div className={styles.center}>限时 <br /> 特价</div>
        <div className={styles.row}>
          <div>
            <span className={styles.text}>到手价</span>
            <span className={styles.textSmall}>¥</span>
            <span className={styles.textStrong}>{actualPrice.toFixed(2)}</span>
          </div>
          <div className={styles.textBtn}>去抢购</div>
        </div>
      </div>
    </div>
  )
};



export default React.memo(() => {
  const [activeKey, setActiveKey] = React.useState('0');
  const [listArray, setListArray] = React.useState([]);
  const [statusBarHeight, setStatusBarHeight] = React.useState(0);

  const TabObjc = {
    '童趣乐园': 'tab0.png',
    '学习园地': 'tab1.png',
    '快乐成长': 'tab2.png'
  }

  React.useEffect(() => {
    JsBridge.getStatusBarHeight().then(({height}) => {
      setStatusBarHeight(height);
    });
    if(process.env.NODE_ENV == 'development') {
      setTimeout(()=>{
        setListArray(defaultData);
        setActiveKey(defaultData[0]?.cateId);
      },1500)
    }
   
    service.getRecommendShopList().then(res => {
      console.log('getRecommendShopList', res)
      if(res) {
        setListArray(res?.data);
        setActiveKey(String(res?.data?.[0]?.cateId));
      }
      
    })
  }, []);

  return (
      <Tabs
        className={styles.tabs}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        style={{ '--status-bar-height' : statusBarHeight + 'px' }}
      >
      {
        listArray?.map?.((cate,i) => (
          <Tabs.Tab
            key={String(cate.cateId)}
            title={<TabHeaderItem title={cate.cateName} active={activeKey === String(cate.cateId)}
          />}>
            <div className={styles.listWrap}>
              <div className={styles.groupHeader}>
                {cate.cateName}
              </div>
              {cate.content?.map?.(renderItem)}
            </div>
          </Tabs.Tab>
        ))
      }
    </Tabs>
  )
})