/**
 * @description 双旦活动
 * @returns route: /front/activities/newYear
*/
import React, { useState, useEffect } from 'react';
import { Toast, Swiper } from 'antd-mobile';
import { Header,RulesModal, ModalAlert } from 'components';
import Library from 'library';
import ActivityShareModal from 'components/activityShareModal';
import Service from '../service';
import Interceptor from '../components/interceptor';
import ShopRecommend from '../components/shopRemmend';
import WinnerSwiper from '../components/winnerSwiper';
import PopupModal from "../components/popupModal";
import PopupShare from '../components/popupShare';
import Styles from './styles.module.scss';

const { JsBridge } = Library;

export const NewYear = (props)=> {

    const {clientId} = Library.Util.url.paramsToObj();

    const [activityInfo, setActivityInfo] = useState({});
    const [shareConfig, setShareConfig] = useState({});
    const [action, setAction] = useState(false);
    const PageRef = React.useRef(null);

    useEffect(()=>{
        if(clientId) {
            Service.shareActivityTask({clientId});
        }
        loadData()
    },[])

    const loadData = async ()=>{
        await refeshData(true)
        const result = await Service.getShareConfig()
        if(result?.data) {
            setShareConfig(result.data)
        }else {
            setShareConfig({status: 0})
        }
    }

    const refeshData = async (showLoading = false)=> {
        const result = await Service.getInitData(showLoading)
        
        if(result?.data) {
            let lotteryProductInfo = result.data?.lotteryProductInfo || []
            if(lotteryProductInfo.length > 0) {
                while(lotteryProductInfo.length  < 9) {
                    lotteryProductInfo = [...lotteryProductInfo, ...lotteryProductInfo];
                }
                
            }
            result.data.lotteryProductInfo = lotteryProductInfo;
            setActivityInfo(result.data)
        }
        return result?.data

    }

    // 活动规则
    const onRuleClick =()=>{
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(90deg, #FFC7AB 0%, #FF58A1 28%, #FF3F38 75%, #FF8634 100%)'},
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }
    // 活动分享
    const onActivityShareClick = ()=>{
        PopupShare.open({
            activityCode: Service.activityCode, 
            inviteCode: activityInfo.inviteCode,
            shareConfig
        });
    }

    // 邀新记录
    const onInviterRecord = ()=> {
        PopupModal.open(Service.activityCode);
    }

    // 立即邀新
    const onInvite = ()=> {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        const param = {
            ic: activityInfo.inviteCode,
            ac: activityInfo.activityCode
          }
        const shareUrl = `${window.location.origin}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
        ActivityShareModal.open({
            shareUrl: shareUrl,
            children: <img className={Styles.shareBg} src={require('images/newYear/shareBg.png')} alt="bg" />,
            qrcodeConfig: {size: 67}
        })
    }

    // 领取记录
    const onReceiveRecord = ()=> {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/activities/newYear/giftRecord`});
        }else {
            props.history.push('/front/activities/newYear/giftRecord')
        }
        
    }

    // 准备获取中奖结果
    const onPreviewReadyPrize = async (tag) => {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }

        if(!activityInfo.remainingLotteryTimes) {
            Toast.show('您的抽奖次数不足');
            return;
        }
        if(tag == 10 && activityInfo.remainingLotteryTimes < 10) {
            Toast.show('您的抽奖次数不足');
            return;
        }

        const content = (
            <div className={Styles.modal_preview}>
                <img className={Styles.modal_bg} src={require('images/newYear/star_guang.png')} />
                <img className={Styles.ready_gift_img} src={require('images/newYear/gift.png')} />
            </div>
        )
        ModalAlert.show({
            getContainer: PageRef.current,
            maskStyle: {background: 'rgba(0, 0, 0, 0.8)'},
            content: content,
        });

        const res = await Service.lotteryGift({reduceTimes: tag});
        ModalAlert.hide();
        if(res?.status > 0) {
            if(res.data?.list?.length > 0) {
                const groupList = [];
                res.data.list.forEach(element => {
                    const index = groupList.findIndex(item => item.value == element.value);
                    if(index < 0) {
                        element.count = 1;
                        groupList.push(element);
                    }else {
                       const item = groupList[index];
                       item.count++;
                       groupList[index] = item;
                    }
                });
                const list = groupList.filter(item => item.type !== 9);
                if(list.length > 0) {
                    onWinningResult(list);
                }else {
                    onThanksComing();
                }
                
            }else if(res.data){
                if(res.data.type == 9) {
                    onThanksComing();
                }else {
                    const list = [res.data];
                    onWinningResult(list);
                }
                
            }else {
                onThanksComing();
            }
            
            refeshData(false);
        }

    }

    // 谢谢参与
    const onThanksComing = ()=> {
        const content = (
            <div className={Styles.modal_thanks}>
                <img className={Styles.modal_bg} src={require('images/newYear/guang.png')} />
                <img className={Styles.modal_img} src={require('images/newYear/thanks.png')} />
                <div className={Styles.close} onClick={()=>{
                    ModalAlert.hide();
                }}/>
            </div>
        )
        ModalAlert.show({
            getContainer: PageRef.current,
            maskStyle: {background: 'rgba(0, 0, 0, 0.8)'},
            content: content,
        })
    }

    // 中奖结果
    const onWinningResult = (items=[])=> {
        const list = items;
        const showList = list.length > 1 || list.some(item=>item.count > 1);
        const content = (
            <div className={`${Styles.modal_result} ${showList ? Styles.list : ''}`}>
                <img className={Styles.modal_bg} src={require('images/newYear/guang.png')} />
                <div className={Styles.content}>
                    <img className={Styles.modal_img} 
                        src={showList ? require('images/newYear/result2.png'): require('images/newYear/result1.png')} 
                    />
                    {
                        showList ? 
                        <div className={Styles.resultBox}>
                            <div className={Styles.items}>
                                {
                                    list.map((item,k)=> {
                                        return (
                                            <div className={Styles.item} key={k}>
                                                <div>
                                                    <div>
                                                        <img src={item.imgUrl} alt="" />
                                                    </div>
                                                    <span>
                                                        {item.value || ''}
                                                    </span>
                                                </div>
                                                <span>x{item.count || 1}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={Styles.btn_again} onClick={()=> {
                                ModalAlert.hide();
                                onPreviewReadyPrize(10);
                            }}>
                                <span>再抽10次</span>
                            </div>
                        </div>:
                        <>
                        {
                            list.map((item,k)=> {
                                return <div className={Styles.result} key={k}>
                                    <img src={item.imgUrl} alt="" />
                                    <span>{item.value || ''}</span>
                                </div>
                            })
                        }
                        </>
                    }
                    
                </div>
                <div className={Styles.close} onClick={()=>{
                    ModalAlert.hide();
                }}/>
            </div>
        )
        ModalAlert.show({
            getContainer: PageRef.current,
            maskStyle: {background: 'rgba(0, 0, 0, 0.8)'},
            content: content,
        })
    }

    // 奖品详情
    const onProductInfo = (item) => {
        if(item.skuId) {
            const skuId = item.skuId
            JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
        }
    }

    return (
        <div className={Styles.newYearPage} ref={PageRef}>
           { JsBridge.hasWebViewBridge() && <Header bgColor={'transparent'}/> } 
            <div className={Styles.newYearPage__wraper}>
                <div className={Styles.bgTop}>
                    <div className={Styles.activityBtns}>
                        <div onClick={onRuleClick}>规则</div>
                        {
                            shareConfig.status === 1 &&
                            <div onClick={onActivityShareClick}>分享</div>
                        }
                    </div>
                </div>
                {/* 立即邀新/抽奖 */}
                <div className={Styles.center}>
                    <img className={Styles.title_img} src={require('images/newYear/title_img.png')} alt="" />
                    <div className={Styles.invite_new}>
                        <div className={Styles.invite_repord} onClick={onInviterRecord}>{'邀新记录>'}</div>
                        <div className={Styles.invite_now_btn} onClick={onInvite}>
                            <span>立即邀新</span>
                        </div>
                    </div>
                    {/* 抽奖 */}
                    <div className={Styles.jiqi_box}>
                        <span>{`再抽${activityInfo.remainingHitTimes || 0}次必中 当前有${activityInfo.remainingLotteryTimes || 0}次抽奖机会`}</span>
                        <div className={Styles.award_repord} onClick={onReceiveRecord}>{'中奖记录>'}</div>
                        <div className={Styles.magic_box}>
                            <div>
                                <img src={require('images/newYear/gift.png')} alt="" 
                                className={action ? Styles.active: ''}
                                onClick={()=>{
                                    setAction(true);
                                    setTimeout(()=>{
                                        setAction(false);
                                    },500)
                                }}/>
                            </div>
                            <div>
                                <img src={require('images/newYear/btn_1.png')} onClick={()=>onPreviewReadyPrize(1)} alt="" />
                                <img src={require('images/newYear/btn_10.png')} onClick={()=>onPreviewReadyPrize(10)} alt="" />
                            </div>
                        </div>
                        <div className={Styles.award_box}>
                            <div className={Styles.desc}>{'每次开盲盒可随机获得以下奖励'}</div>
                            <div className={Styles.flowBox}>
                                <Swiper
                                    style={{ }}
                                    allowTouchMove={false}
                                    stuckAtBoundary={false}
                                    autoplay={true}
                                    loop={true}
                                    autoplayInterval={1500}
                                    slideSize={ 22 }
                                    indicator={() => null}
                                >
                                    {
                                        activityInfo?.lotteryProductInfo?.map((item, index) => (
                                            <Swiper.Item key={index}>
                                                <div className={Styles.box_item} onClick={()=>onProductInfo(item)}>
                                                    <div>
                                                        <div>
                                                            <img src={item.img} alt="" />
                                                        </div>
                                                        <span>{item.lotteryName}</span>
                                                    </div>
                                                </div>
                                            </Swiper.Item>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <WinnerSwiper records={activityInfo?.prizeRecord}/>
                </div>
                {/* 商品推荐 */}
                <ShopRecommend />
                {/* 底部logo */}
                <div className={Styles.footer_logo}>
                    <img src={require('images/newYear/bottom-logo.png')} alt="" />
                </div>
                <Interceptor />
            </div>
        </div>
    )
}

export * from '../giftRecord';