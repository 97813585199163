import React, {useEffect, useRef, useState} from 'react';
import styles from './inviteFriendList.module.scss';
import Library from "../../../../library";
import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";
import ListView1 from "../../../../components/ListView1";


const {JsBridge} = Library;
const activityCode = 'CR2023';
export const InviteFriendList = (props) => { //邀新好友
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(1);
    const initalRef = useRef(props.initalIndex);
    const [data, setData] = useState([]);
    const [initStatus, setInitStatus] = useState(false);
    const {focusStatus} = props;

    useEffect(() => {
        if (!initStatus && focusStatus) {
            setInitStatus(true);
            loadData(true).then();
            return;
        }
    }, [initStatus, focusStatus])

    const loadData = async (isRefresh) => {
        if (isRefresh) {
            pageNum.current = 1;
        }
        let params = {
            'activityCode': activityCode,
            'pageSize': pageSize,
            'pageNum': pageNum.current,
        };
        const res = await Services('MainPage', {code: 300031}, params);
        if (res.status > 0) {
            const {inviteRecordList = [], count = 0} = res.data;
            let newArr = [];
            if (pageNum.current === 1) {
                let i = 0;
                for (const item of inviteRecordList) {
                    item.index = i;
                    i++;
                }
                newArr = [...inviteRecordList];
            } else {
                newArr = [...data, ...inviteRecordList];
            }
            setData(newArr);
            setIsLoad(true);
            setHasMore(count > newArr.length);
        }
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData(false);
    }

    const listItem = (item) => {
        return (
            <div className={styles.list_item} style={{marginTop: item.index === 0 ? '0px' : '10px'}}>
                <div className={styles.list_item_left}>
                    <div className={styles.list_item_name}>{item.mobile}</div>
                    <div className={styles.list_item_time}>{item.createTime}</div>
                </div>
                <div className={styles.list_item_right}>
                    <div className={styles.list_item_state_title}
                         style={{marginBottom:!isEmptyString(item.reason) ? '15px' : '0'}}>{item.inviteStatus}</div>
                    {
                        !isEmptyString(item.reason) ?
                            <div className={styles.list_item_state_des}>{item.reason}</div> : null
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={styles.invite_friend_container}>
            <div
                style={{height: '90vh', overflowY: 'scroll', position: 'relative'}}>
                <ListView1
                    onRefresh={() => loadData(true)}
                    data={data}
                    hasMore={hasMore}
                    isLoad={isLoad}
                    loadMore={loadMore}
                    ListItem={({item}) => listItem(item)}
                />
            </div>
        </div>
    )
}
