/**
 * @description 点赞弹框
 * 
*/
import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Mask, Toast } from "antd-mobile";
import Service from "../../service";
import Styles from './index.module.scss';

const LikesModal = (props)=> {
    const { closeMask, blessingId, call } = props;
    const [visible, setVisible] = useState(true);
    const [blessingObj, setBlessingObj] = useState({});

    React.useEffect(() => {
        Service.getBlessingWordsById(blessingId).then(res => {
            setBlessingObj(res);
        });
    }, []);

    const cancel = () => {
        setVisible(false);
        closeMask();
    }

    const onZanNow = async () => {
        const result = await Service.postThumbsUp({ blessingId })
        if(result?.data) {
            cancel()
            Toast.show({
                content: '点赞成功',
                afterClose:()=>{
                    call && call()
                }
            })
        }
        
    }

    return (
        <Mask visible={visible} onMaskClick={cancel} opacity={0.7} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className={Styles.likes_content}>
                <div className={Styles.content_body}>
                    <div className={Styles.text}>
                        <div>@爸爸，我有话对您说:</div>
                        <p>{blessingObj?.blessingWords}</p>
                    </div>
                    <img className={Styles.close} onClick={cancel} src={require('images/childrensDay/mask-close.png')} alt="" />
                    <div className={Styles.btn} onClick={onZanNow}>立即点赞</div>
                </div>
            </div>
        </Mask>
    )
}

LikesModal.open = ({ blessingId, call }) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<LikesModal blessingId={blessingId} call={call} closeMask={close} />, div);
}

export default LikesModal;