import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import { loadImage } from 'library/util';
import Library from 'library';
import { Actions } from 'reduxs';
import Services from 'services';
import ReactLoading from 'react-loading';
import styles from './shareImage.module.scss';
import mainBg from '../registionActivity/images/main_picture_bg@2x.png';
import logoImage from 'images/icon/logo@2x.png';
import closeImage from '../registionActivity/images/shutdown@2x.png';
import shareImage from '../signActivity/images/share.png';
import QQImage from '../signActivity/images/QQ-icon@2x.png';
import qqkongjianImage from '../signActivity/images/qqkongjian-icon@2x.png';
import weiboImage from '../registionActivity/images/weibo@2x.png';
import downloadImage from '../registionActivity/images/download@2x.png';
import MomentImage from '../signActivity/images/Moments-icon@2x.png';
import WechartImage from '../signActivity/images/WeChat-icon@2x.png';
import { umengTrack_invite } from 'library/jsbridge/config'
const { Platform, JsBridge } = Library;
let timer;
export const ShareImage = (props) => {
    const [logoStatus, setLogoStatus] = useState(false);
    const [mainStatus, setMainBgStatus] = useState(false);
    const [shareModalStatus, setShareModalStatus] = useState(false);
    const { code } = useParams();
    const shareImageRef = useRef();
    const history = useHistory();
    const [shareImageUrl, setShareImageUrl] = useState(null);
    const [shareFilePath, setShareFilePath] = useState(null);
    const shareUrl = `${window.location.origin}/front/inviter/register/${code}?activityCode=INVITECLIE`;
    const [thisRewardPoint, setRewardPoint] = useState(null);
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        const { myShareImage, rewardPoint } = location.state || {};
        setRewardPoint(rewardPoint);
        if (myShareImage) {
            const mainBgImage = new Image();
            mainBgImage.src = myShareImage;
            loadImage(mainBgImage).then(res => {
                setShareImageUrl(myShareImage);
            }).catch(err => {
                JsBridge.runAction('alert', 'error', ['分享图片加载失败,请重试']);
                goBack();
            });
        } else {
            getImages();
        }
    }, []);

    useEffect(() => {
        if (shareImageRef && logoStatus && mainStatus) {
            timer = setTimeout(async () => {
                createShareImage(shareImageRef.current);
            }, 1000);
        }
        return () => {
            clearTimeout(timer);
        }
    }, [shareImageRef, mainStatus, logoStatus]);

    const getImages = async () => {
        const mainBgImage = new Image();
        mainBgImage.src = mainBg;
        const logo = new Image();
        logo.src = logoImage;
        await loadImage(mainBgImage);
        await loadImage(logo);
        setLogoStatus(true);
        setMainBgStatus(true);
    }
    const goBack = () => {
        if (Platform.check.isApp() && Platform.check.isAndroid()) {
            JsBridge.webviewGoback();
        } else {
            history.goBack();
        }
    }
    // 生成分享图片
    const createShareImage = async (curent) => {
        html2canvas(curent, {
            allowTaint: true,
            useCORS: true
        }).then(async (canvas) => {
            const res = canvas.toDataURL('image/jpeg', 0.8);
            const uploadResult = Platform.check.isApp() ? await Services('MainPage', { code: 101145 }, {
                activityCode: 'INVITECLIE',
                imageType: 'JPEG',
                file: encodeURIComponent(res) // app调用需urlencode该参数
            }, true) : await Services('InviteActivity', 'UpdateActivityImage', {
                activityCode: 'INVITECLIE',
                imageType: 'JPEG',
                file: res
            }, true);
            if (uploadResult.status > 0) {
                const mainBgImage = new Image();
                mainBgImage.src = uploadResult.data.obj;
                const res = await loadImage(mainBgImage);
                if (res) {
                    setShareImageUrl(uploadResult.data.obj);
                }
            } else {
                await JsBridge.runAction('alert', 'error', ['分享图片加载失败,请重试']);
                goBack();
            }
        }).catch(ex => {
            alert(JSON.stringify(ex));
        });
    }
    const shareTitle = `邀新赚${thisRewardPoint}积分，好礼兑不停，`;
    return <div className={styles.container}>
        <div className={styles.modal} onClick={() => {
            goBack();
        }}>
            <div onClick={(e) => {
                e.stopPropagation();
            }} >
                <div className={`${styles.modalContent} ${styles.hide}`} ref={shareImageRef} >
                    <div className={styles.modalMain}>
                        <img src={mainBg} />
                        <div className={styles.modalTitle} >
                            <p>{shareTitle}</p>
                            <p>快来和我一起参与吧！</p>
                        </div>

                    </div>
                    <div className={styles.modalFooter}>
                        <div className={styles.left}>
                            <QRCode
                                value={shareUrl}
                                size={120}
                                fgColor="#000000"
                                imageSettings={{
                                    src: logoImage,
                                    x: null,
                                    y: null,
                                    height: 20,
                                    width: 20,
                                    excavate: false,
                                }} />
                            <img className={styles.logo} src={logoImage} />
                        </div>
                        <div className={styles.right}>
                            <h3>G优超级分享官</h3>
                            <p>长按识别二维码一起参与活动</p>
                        </div>
                    </div>

                </div>


                {shareImageUrl ? <><div className={`${styles.modalContent} ${styles.realShareImage}`}>
                    <img src={shareImageUrl} />
                    <div onClick={() => {
                        goBack();
                    }} className={styles.close}><img src={closeImage} /></div>
                </div>
                    <p className={styles.shareText}>好友通过此图中二维码注册即邀请成功</p>
                </> :
                    <div className={styles.loading}>
                        <ReactLoading type='bars' color='black' />
                    </div>}
            </div>
        </div>
        {shareImageUrl ? (!Platform.check.isApp() ? <div className={styles.footerWrap}>
            <div className={styles.footer}>
                长按图片保存到相册
            </div>
        </div> : <div className={styles.footerWrap}>
            <div className={styles.footer}>
                {/* <button onClick={async () => {
                    const result = await JsBridge.saveImageToPhotosAlbum(shareImageUrl);
                    JsBridge.runAction('alert', 'error', [result.message]);
                }}>保存图片</button> */}
                <button onClick={() => {     
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_invite.share);
                    } 
                    setShareModalStatus(true);
                }}>分享</button>
            </div>
        </div>) : false}
        {shareModalStatus ? <div className={styles.shareMask}>
            <div onClick={() => {
                setShareModalStatus(false);
            }} className={styles.mask}></div>
            <div className={styles.shareBox}>
                <div className={styles.shareTitle}>请选择分享平台</div>
                <div className={styles.shareInfo}>
                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.pyq);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const result = await JsBridge.share({ text: '', icon: shareImageUrl, link: '', title: '', platform: 2 });
                        Actions.alert.show(result.message);
                    }}><img src={MomentImage} />
                        <p>朋友圈</p>
                    </button>

                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.wechatfriend);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const result = await JsBridge.share({ text: '', icon: shareImageUrl, link: '', title: '', platform: 1 });
                        Actions.alert.show(result.message);
                    }}><img src={WechartImage} />
                        <p>微信好友</p>
                    </button>
                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.qqfriend);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const permissionResult = await JsBridge.requestPermission('requestAlbum');
                        if (permissionResult.result) {
                            const result = await JsBridge.share({ text: '', icon: shareImageUrl, link: '', title: '', platform: 4 });
                            Actions.alert.show(result.message);
                        }
                    }}><img src={QQImage} />
                        <p>QQ好友</p>
                    </button>
                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.qzone);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const result = await JsBridge.share({ text: '', icon: shareImageUrl, link: '', title: '', platform: 5 });
                        Actions.alert.show(result.message);
                    }}><img src={qqkongjianImage} />
                        <p>QQ空间</p>
                    </button>
                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.sinaweibo);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const res = await JsBridge.canOpenLink('sinaweibo://splash');
                        if (res) {
                            const result = await JsBridge.share({ text: '', icon: shareImageUrl, link: '', title: '', platform: 0 });
                            Actions.alert.show(result.message);
                        } else {
                            Actions.alert.show('请先安装新浪微博客户端');
                        }
                    }}><img src={weiboImage} />
                        <p>新浪微博</p>
                    </button>
                    <button onClick={async () => {
                        if (Platform.check.isApp()) {
                            JsBridge.UmengTrack(umengTrack_invite.download);
                        } 
                        if (!shareImageUrl) {
                            return;
                        }
                        const permissionResult = await JsBridge.requestPermission('requestAlbum');
                        if (permissionResult.result) {
                            let filePath = shareFilePath;
                            if (!filePath) {
                                const downloadFileResult = await JsBridge.downloadFile({ url: shareImageUrl, fileType: 'jpg' });
                                setShareFilePath(downloadFileResult.tempFilePath);
                                filePath = downloadFileResult.tempFilePath;
                            }
                            const result = await JsBridge.saveImageToPhotosAlbum(filePath);
                            Actions.alert.show(result.message);
                        } else {
                            // Actions.alert.show(permissionResult.message);
                        }
                    }}><img src={downloadImage} />
                        <p>下载相册</p>
                    </button>

                </div>
                <button className={styles.shareCancel} onClick={() => {
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_invite.cancel);
                    }
                    setShareModalStatus(false);
                }}>取 消</button>
            </div></div> : false}


    </div >
}
