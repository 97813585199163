/**
 * @description 活动规则弹框
 * @param {activityCode: 活动编码, codeType: 'activityRule'固定值, code: 活动编码规则, btnStyle: 按钮样式,onClose: function }
 */
import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import Library from "library";
import { CloseOutline } from "antd-mobile-icons";
import "./record.scss";
import { Mask } from "antd-mobile";
import Services from "../../routes/activities/activityShare/invite-zone/service";
const { Util } = Library;

const RecordModal = ({ onClose, btnStyle = {}, params, title }) => {
  const [visible, setVisible] = useState(true);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    visible && lotteryRecord();
  }, [visible]);

  const handleClick = () => {
    setVisible(false);
    onClose && onClose();
  };

  const lotteryRecord = async () => {
    const result = await Services.lotteryRecord();
    if (result?.data) {
      setRecord(result?.data || []);
    }
  };
  const trans = (num) => {};
  return (
    <Mask visible={visible} onMaskClick={() => handleClick()}>
      <div className="goddess-rules-modal">
        <div className="goddess-modal-container">
          <div className="goddess-modal-title">
            {title || "标题"}
            <div className="goddess-modal-icon">
              <CloseOutline onClick={() => handleClick()} />
            </div>
          </div>
          <div className="goddess-modal-content">
            {record && record?.length
              ? record?.map((item, index) => {
                  return (
                    <div key={index} className="goddess-modal-outer">
                      <div className="goddess-modal-record">
                        {`第${Util.format.convertToChinese(
                         item?.cycle
                        )}期`}
                      </div>
                      <div className="goddess-modal-record-number">
                        {item?.list.map((_item, _index) => {
                          return (
                            <div key={`${index}-${_item}`}>
                              {_item}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div
            className="goddess-rules-btn"
            style={{ ...btnStyle }}
            onClick={handleClick}
          >
            我知道了
          </div>
        </div>
      </div>
    </Mask>
  );
};

RecordModal.open = ({ btnStyle = {}, params, title }) => {
  let div = null;
  if (!div) {
    div = document.createElement("div");
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  };
  ReactDOM.render(
    <RecordModal
      btnStyle={btnStyle}
      onClose={close}
      title={title}
      params={params}
    />,
    div
  );
};

export default RecordModal;
