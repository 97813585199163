/**
 * @description G优家办首页
 * @returns /front/member/family/addExtraHome
*/
import React, {useState, useEffect, useRef, useCallback, createContext, useContext} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay, virtualize} from 'react-swipeable-views-utils';
import {mod} from 'react-swipeable-views-core';
import styles from './index.module.scss';
import rightSlogan from './images/rightSlogan.png';
import TopNavigation from '../topNavigation';
import smallImg6 from "./images/6.png";
import smallImg7 from "./images/7.png";
import smallImg8 from "./images/8.png";
import smallImg9 from "./images/9.png";
import smallImg10 from "./images/10.png";
import smallRightArrow from "./images/smallRightArrow.png";
import sevenBigImg from "./images/sevenBigImg.png";
import Library from 'library';
import Services from 'services';
import {Swiper} from "antd-mobile";
import {isEmptyArray, isEmptyObject, isEmptyString} from "../../../../library/util";
import img from "./images/pic_ka_qy.png";
import {vipLevel} from "../../utils";

const {JsBridge, Hooks, Platform, Util} = Library;
const AutoPlaySwipeableViews = autoPlay(virtualize(SwipeableViews));

const Context = createContext(null);
export const AddExtraHome = (props) => {
    const [activityCalendarList, setActivityCalendarList] = useState([]);
    const [scrollNewsList, setScrollNewsList] = useState([]);
    const [stockNewsAdvertisement, setStockNewsAdvertisement] = useState({});
    const [hotNewsAdvertisementList, setHotNewsAdvertisementList] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const userRef = useRef({});
    useEffect(() => {
        getInitHomeList();
        getUserInfo();
    }, []);

    const getInitHomeList = async () => {
        if (Platform.check.isApp()) {
            const result = await Services('MainPage', {code: '100008'}, {});
            if (result.status > 0) {
                // setActivityCalendarList(result.data.activityCalendarList);
                setScrollNewsList(result.data.scrollNewsList);
                setStockNewsAdvertisement(result.data.stockNewsAdvertisement);                 // setScrollViewList();
                setHotNewsAdvertisementList(result.data.hotNewsAdvertisementList);
                // setInitHomeList(result.data.activityCalendarList);
            }
        }
    }

    const loadData = (arr) => {
        setActivityCalendarList(arr)
    }

    const getUserInfo = async () => {
        let user = await JsBridge.getDetailCurrentUserInfo();
        if (user.isLogin && !isEmptyString(user.account)) {
            userRef.current = user;
            const result = await Services('MainPage', {code: '500009'}, {customerAccount: user.account}, false);
            if (result.status > 0) {
                setUserInfo(result.data || {});
            }
        }
    }

    const renderHotNewsAdvertisementList = () => {
        return <div className={styles.bannerMask}>
            {hotNewsAdvertisementList.map((item, index) => {
                return <div key={index} className={styles.scrollImg1}>
                    <div className={styles.img} onClick={() => {
                        const {path, params} = Util.url.getPramasFromUrl(item.detailUrl);
                        console.log('跳转链接：', path, params);
                        JsBridge.push(path, params);
                    }} style={{backgroundImage: `url(${getImage(item.tinyPicUrl)})`}}>
                        {index > 0 ? <div className={styles.botTitleTel}>{item.title}</div> : false}
                    </div>
                </div>
            })}
        </div>
    }
    const getImage = (url) => {
        return url.replace(/^(http)[s]*(\:\/\/)/, 'https://images.weserv.nl/?url=')
    }

    const timeFormat = (startTime) => {
        if (!isEmptyString(startTime)) {
            let startTimeDate = new Date(startTime.replace(/\./g, '/'));
            let M0 = (startTimeDate.getMonth() + 1) < 10 ? `0${(startTimeDate.getMonth() + 1)}` : `${(startTimeDate.getMonth() + 1)}`,
                D0 = startTimeDate.getDate() < 10 ? `0${(startTimeDate.getDate())}` : `${(startTimeDate.getDate())}`;
            let time0 = `${M0}-${D0}`
            return `${time0}`
        }
        return startTime;
    }

    const renderCalenderList = () => { //日历下面的节日的文字
        return activityCalendarList.map((item, index) => {
            return <div className={styles.fiveCtnBotTop} key={index} onClick={() => {

                let data = {
                    activityId: item.id,
                    activityName: item.activityName,
                    userAccount: userRef.current.account,
                    isNowActy: '1',
                    isHome: '1',
                }
                JsBridge.push('GoAppointment', data)
                // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${item.id}&activityName=${item.activityName}&userAccount=${userRef.current.account}&isNowActy=1&isHome=1`});
            }}>
                <div className={styles.fiveBotTopText1}>
                    <div
                        className={styles.smallFontWeight}>{isEmptyString(item.participateTime) ? '公开' : timeFormat(item.participateTime)}</div>
                </div>

                <div className={styles.smallTextdE}>{item.activityName}</div>
            </div>
        })
    }

    return <Context.Provider value={{userInfo, userRef}}>
        <div className={styles.container}>
            <TopNavigation title='G优家办'/>
            <div className={styles.header}>
                <img src={rightSlogan} className={styles.rightSlogan}/>
                <UserCardLevelComponent/>
                <div className={styles.botFour}>
                    <div className={styles.firstBox} onClick={() => {
                        JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/wealthManage'});
                    }}>
                        <img src={smallImg6}/>
                        <div className={styles.text}>财富管理</div>
                    </div>
                    <div className={styles.firstBox} onClick={() => {
                        JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/familyInherit'});
                    }}>
                        <img src={smallImg7}/>
                        <div className={styles.text}>家族传承</div>
                    </div>
                    <div className={styles.firstBox} onClick={() => {
                        JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/hotInformation/1'});
                    }}>
                        <img src={smallImg9}/>
                        <div className={styles.text}>热门资讯</div>
                    </div>
                    <div className={styles.firstBox} onClick={() => {
                        JsBridge.push('WealthLectureHall');
                    }}>
                        <img src={smallImg10}/>
                        <div className={styles.text}>财富讲堂</div>
                    </div>
                </div>
            </div>
            <Marquee scrollNewsList={scrollNewsList}/>


            <div className={styles.thirdPart}>
                <div className={styles.myTitle}>
                    <div className={styles.myTitleLeft}>
                        <h3>热门资讯</h3>
                    </div>
                    <div className={styles.myTitleRight} onClick={() => {
                        JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/hotInformation/1'});
                    }}>
                        <span className={styles.moreMsg}>更多资讯</span>
                        <span>&gt;</span>
                    </div>
                </div>

                <div className={styles.thirdMyContent}>

                    {renderHotNewsAdvertisementList()}
                </div>
            </div>
            <div className={styles.fourPart}>
                {/* <img src={fourBigImg} className={styles.fourBigImg} /> */}
                <img src={stockNewsAdvertisement.tinyPicUrl} className={styles.fourBigImg} onClick={() => {
                    JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/hotInformation/4'});
                    //JsBridge.navigate('ExchangeDetail', {tabIndex:1});
                }}/>
            </div>
            <div className={styles.fivePart}>
                <div className={styles.jb_home_hdjx}>
                    <span className={styles.sixPartTitle}>活动精选</span>
                    <span className={styles.acty_jinxun_more}
                          onClick={() => {
                              JsBridge.push('Web', {uri: window.location.origin + `/front/member/appointment/activitySelection?userAccount=${userRef.current.account}`});
                          }}>{'更多>'}</span>
                </div>

                <div className={styles.fiveContent}>
                    <div className={styles.fiveCtnTop}>
                        <CalenderImgList user={userRef.current} loadData={(arr) => loadData(arr)}/>
                    </div>
                    <div className={styles.fiveCtnBot}>
                        {renderCalenderList()}
                    </div>
                </div>
            </div>

            <div className={styles.sevenPart}>
            <span className={styles.sevenPartTitle}>
                了解我们
            </span>
                <div className={styles.sevenPartContent} onClick={() => {
                    JsBridge.push('CustomWeb', {uri: window.location.origin + '/front/member/family/knowUs'});
                }}>
                    <img src={sevenBigImg} className={styles.sevenBigImg}/>
                </div>
            </div>
            <div className={styles.eightPartContent}>
                <img src={require('./images/logo.png')}/>
            </div>
        </div>
    </Context.Provider>
}

const UserCardLevelComponent = (props) => {
    const {userInfo, userRef} = useContext(Context);
    const join = () => { //加入我们
        let obj = {...userInfo, ...userRef.current};
        JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/vipCardHome?${JSON.stringify(obj)}`});
    }

    const goActyDetail = () => { //红色的背景
        if (userInfo.activityId >= 0) {
            let actyName = '活动详情';
            if (!isEmptyString(userInfo.remindAttendActivity)) {
                let strings = userInfo.remindAttendActivity.split('\"');
                if (!isEmptyArray(strings) && strings[1]) {
                    actyName = strings[1];
                }
            }
            let data = {
                activityId: userInfo.activityId,
                activityName: actyName,
                userAccount: userRef.current.account,
                isNowActy: '1',
                isHome: '1',
            }
            JsBridge.push('GoAppointment', data)
            // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${userInfo.activityId}&activityName=${actyName}&userAccount=${userRef.current.account}&isNowActy=1&isHome=1`});
        }
    }

    const vipLevelView = (index) => { //vip用户登录 0=青玉 1=黑砖 2=白金 3=水晶
        let img = require('./images/pic_ka_qy.png');
        let nameColor = '#348065';
        if (index === 0) {
            img = require('./images/pic_ka_qy.png');
            nameColor = '#348065';
        } else if (index === 1) {
            img = require('./images/pic_ka_hj.png');
            nameColor = '#FACDA2';
        } else if (index === 2) {
            img = require('./images/pic_ka_bj.png');
            nameColor = '#717171';
        } else if (index === 3) {
            img = require('./images/pic_ka_sj.png');
            nameColor = '#16517D';
        }

        return (
            <div className={styles.jb_home_user_level_container}
                 onClick={() => join()}
            >
                <img src={img}/>
                <p className={styles.jb_vip_name} style={{color: nameColor}}>{userInfo.customerName}</p>
                <p className={styles.jb_vip_more_qy}
                   style={{color: nameColor}}
                   onClick={() => join()}
                >{'查看更多权益>'}</p>
                <p className={styles.jb_vip_more_time}
                   style={{color: nameColor}}>{`有效期${userInfo.levelValidityStart || ''}-${userInfo.levelValidityEnd || ''}`}</p>
                {
                    !isEmptyString(userInfo.remindAttendActivity) ?
                        <div className={styles.jb_vip_bottom} onClick={() => goActyDetail()}>
                            <p className={styles.jb_vip_bottom_des}>{userInfo.remindAttendActivity}</p>
                            <img src={require('./images/icon_xiangqing.png')}/>
                        </div> : null
                }
            </div>
        )
    }

    const normalLevelView = () => { //普通用户等级
        return (
            <div className={styles.jb_home_user_level_container}
                 onClick={() => join()}>
                <img src={require('./images/pic_sy.png')} className={styles.jb_nomal_join_bg}/>
                <p onClick={() => join()} className={styles.jb_nomal_join}>立即加入</p>
            </div>
        )
    }

    if (userInfo.customerLevelId > -1) { //0=青玉 1=黑砖 2=白金 3=水晶
        if (!isEmptyArray(userInfo.customerLevelInfoVoList)) {
            let itemTem = null;
            for (let i = 0; i < userInfo.customerLevelInfoVoList.length; i++) {
                let item = userInfo.customerLevelInfoVoList[i];
                if (item.customerLevelId === userInfo.customerLevelId) {
                    itemTem = item;
                    break;
                }
            }
            if (itemTem) {
                let index = -1;
                if (itemTem.levelName === vipLevel.qy.name) {
                    index = 0;
                } else if (itemTem.levelName === vipLevel.hz.name) {
                    index = 1;
                } else if (itemTem.levelName === vipLevel.bj.name) {
                    index = 2;
                } else if (itemTem.levelName === vipLevel.sj.name) {
                    index = 3;
                }
                if (index > -1) {
                    return vipLevelView(index);
                } else {
                    return normalLevelView();
                }
            } else {
                return normalLevelView();
            }
        } else {
            return normalLevelView();
        }
    }
    return normalLevelView();
}

const CalenderImgList = (props) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        !isEmptyObject(props.user) && loadData();
    }, [props.user])

    const loadData = async () => {
        const result = await Services('MainPage', {code: 500014}, {
            pageNum: 0,
            pageSize: 1000,
            customerAccount: props.user.account
        }, false);
        if (result.status > 0) {
            setData(result?.data?.guHomeOfficeActivityVOPage?.content || []);
            if (props.loadData) {
                props.loadData(result?.data?.guHomeOfficeActivityVOPage?.content || [])
            }
        }
    }

    const itemClick = (item) => {//banner
        let data = {
            activityId: item.id,
            activityName: item.activityName,
            userAccount: props.user.account,
            isNowActy: '1',
            isHome: '1',
        }
        JsBridge.push('GoAppointment', data)

        // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${item.id}&activityName=${item.activityName}&userAccount=${props.user.account}&isNowActy=1&isHome=1`});
    }

    const items = data.map((item, index) => (
        <Swiper.Item key={index}>
            <div className={styles.calender_img_list}
                 onClick={() => itemClick(item)}
            >
                <img src={item.activityPic || ''}/>
            </div>
        </Swiper.Item>
    ))

    return (
        <>
            <div className={styles.calender_img_list}
                 onClick={() => JsBridge.push('Web', {uri: window.location.origin + `/front/member/appointment/activitySelection?userAccount=${props.user.account}`})
            }
            >
                <img src={require('./images/pic_huodon3.png')}/>
            </div>

            {/*{*/}
            {/*    data.length > 1 ? <Swiper*/}
            {/*        style={{'--border-radius': '10px',}}*/}
            {/*        indicatorProps={{color: 'white'}}*/}
            {/*        autoplay={true}*/}
            {/*        autoplayInterval={5000}*/}
            {/*        loop={true}*/}
            {/*        defaultIndex={0}>*/}
            {/*        {items}*/}
            {/*    </Swiper> : <Swiper*/}
            {/*        style={{'--border-radius': '10px',}}*/}
            {/*        indicator={() => null}*/}
            {/*        defaultIndex={0}>*/}
            {/*        {items}*/}
            {/*    </Swiper>*/}
            {/*}*/}

        </>
    )
}

const Marquee = (props) => {
    const {scrollNewsList} = props;
    const slideRenderer = ({index, key}) => {
        if (mod(index, scrollNewsList.length) < scrollNewsList.length) {
            const _index = mod(index, scrollNewsList.length);
            const item = scrollNewsList[_index];
            return <div key={key} className={styles.secondInnerPa} onClick={() => {
                JsBridge.push('Web', {uri: item.url});
            }}>
                <div className={styles.secInnerLeft}>
                    {/* <span className={styles.secText1}>{item.source}</span> */}
                    <div className={styles.secText2}>
                        {/* <span className={styles.smTextNN1}>{item.source}</span> */}
                        <span className={styles.smTextNN2}>
                            <span className={styles.innerTxt} style={{color: '#CAA846'}}>{item.source}</span>
                            <span>{item.title.substring(0, 19)}</span>
                        </span>
                    </div>
                </div>
                <div className={styles.secInnerRight}>
                    <img src={smallRightArrow} className={styles.smallRightArrow}/>
                </div>
            </div>;
        } else {
            return null;
        }
    }
    return <div className={styles.secondPart}>
        <AutoPlaySwipeableViews className={styles.slideContainer} axis="y" slideRenderer={slideRenderer}/>
    </div>
}
