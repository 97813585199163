import React, {useState, useEffect} from 'react';
import foldImg from '../images/coupon-more-fold@2x.png';
import unfoldImg from '../images/coupon-more-unfold@2x.png';
import styles from './index.module.scss';
import Library from 'library';
const { Fetch, Platform, JsBridge } = Library;
export const CouponCell = (props) => {
    const [item,setItem] = useState(props.item);
    const [btnText] = useState(props.btnText || '使用');
    const [unfold,setUnfold] = useState(false);
    const [disabled,setDisabled] = useState(props.disabled);
    const [dateValid,setDateValid] = useState({date:'',isHighlied:false});
    useEffect(()=>{
        if (disabled) {
            setDateValid({date:`有效期至${item.validEnd}`,isHighlied: false});
        } else {
            let today = new Date();
            let year  = parseInt(item.validEnd.substr(0,4));
            let month = parseInt(item.validEnd.substr(5,2));
            let day = parseInt(item.validEnd.substr(8,2));
            if (today.getFullYear() === year && today.getMonth() === month-1 && today.getDate() === day) {
                setDateValid({date:'今天即将过期',isHighlied: true});
            } else if (today.getFullYear() === year && today.getMonth() === month-1 && day - today.getDate() === 1){
                setDateValid({date:'明天即将过期',isHighlied: true});
            } else {
                setDateValid({date:`有效期至${item.validEnd}`,isHighlied: false});
            }
        }
    },[item]);
    return <li className={styles.container}>
        <div className={styles.item}>
            <div className={`${styles.chunk} ${disabled?styles.disable:''}`}/>
            <div className={styles.imgBg}>
                <img className={`${styles.img} ${disabled?styles.disable:''}`} src={item.picUrl}/>
            </div>
            <div className={styles.dashedBG}>
                <div className={styles.dashed}/>
            </div>
            <div className={styles.content}>
                <h2 className={styles.title}>{item.name}</h2>
                {item.couponAmount ?<h2 className={`${styles.text} ${disabled?styles.disable:''}`}>{item.couponAmount}元</h2>:null}
                <div className={styles.dateContent}>
                    <h2 className={`${styles.date} ${dateValid.isHighlied?styles.highlied:''}`}>{dateValid.date}</h2>
                    <a className={styles.rule} onClick={()=>{
                        setUnfold(!unfold);
                    }}>规则</a>
                    <img onClick={()=>{
                        setUnfold(!unfold);
                    }} className={styles.imgFold} src={unfold?foldImg:unfoldImg}/>
                </div>

            </div>
            <div className={`${styles.bgIcon} ${disabled?styles.disable:''}`}>
                <p className={`${disabled?styles.disable:''}`}>惠</p>
            </div>
            <a className={`${styles.button} ${disabled?styles.disable:''}`} onClick={props.onClick}>{btnText}</a>
        </div>
        {unfold?<div className={styles.ruleDetail}>
            <p>{item.rules}</p>
        </div>:null}
    </li>
}

