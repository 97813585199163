import {LOADING_START, LOADING_COMPLETE} from '../../../constants';
const initialState = {
    tasks: 0,
    status: 'COMPLETE'
};

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function loading(state = initialState, action) {
    switch (action.type) {
        case LOADING_START:
            return {
                tasks: state.tasks + 1,
                status: 'LOADING',
                text: action.text
            };
        case LOADING_COMPLETE:
            {
                let count = Math.max(0, state.tasks - 1);
                let status = count > 0
                    ? 'LOADING'
                    : 'COMPLETE';
                return {tasks: count, status: status};
            }
        default:
            return state;
    }
}
