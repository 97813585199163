import * as actions from './actions/index';
import { bindActionCreators } from 'redux';

export const configureActions = (dispatch) => {
    const bindActions = {};
    Object.keys(actions).forEach((key) => {
        bindActions[key] = bindActionCreators(actions[key], dispatch);
    });
    return bindActions;
}
/**
 *
 * @param dispatch
 * @returns {{}}
 */
export default configureActions;
