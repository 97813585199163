/**
 * @description 购买充值卡模块模块
*/
import React, { useState, useEffect } from 'react';
import Styles from './index.module.scss';

const BuyCard = (props) => {
    const { dataSource={} } = props;

    const onPointDetail = (item)=> {
        props.onPointDetail &&  props.onPointDetail(item);
    }

    return (
        <div className={Styles.buy_card}>
            <div className={Styles.buy_card_content}>
                <h2>请选择电子礼品卡券图案</h2>
                <div className={Styles.grid}>
                {
                    dataSource?.themeList?.map((item,k)=> {
                        return (
                            <div key={k} className={Styles.item}
                                onClick={()=>onPointDetail(item)}
                            >
                                <div>
                                    <img src={item.url} alt="" />
                                </div>
                                <span>{item.theme}</span>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            {
                dataSource.activityRule?.otherSign ?
                <div className={Styles.buy_card_desc} style={{display: 'none'}}>
                    <h2>购买须知</h2>
                    <p>{dataSource.activityRule?.otherSign}</p>
                </div>
                :null
            }
        </div>
    )
}

export default BuyCard;
