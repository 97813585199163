import React from 'react';
import { Guide } from './guide';
import { Register,} from './register';
import Registers from './registers';
import { LandPage } from './landPage';
import { Success } from './success';
import { Protocol } from '../member/card';
import { NftSuccess } from './nftSuccess';
import  GuessingActy  from './guessingActy';

const shareInfo = {
    imgUrl: 'https://h5.gkewang.com/crmh5/src/images/logo-share.jpg',
    title: '邀请您加入世界500强绿地旗下G优生活平台',
    desc: '享受安全、便捷、贴心的城市生活优质增值服务',
    link: window.location.origin + '/jkt/coupon/wxentrance?state=' + encodeURIComponent('/front/inviter/register')
}
const xShareInfo = {
    imgUrl: 'https://h5.gkewang.com/crmh5/src/images/logo-share.jpg',
    title: '邀请您加入世界500强绿地旗下G优生活平台',
    desc: '享受安全、便捷、贴心的城市生活优质增值服务',
    link: window.location.origin + '/jkt/coupon/wxentrance?state=' + encodeURIComponent('/front/inviter/registers')
}
export const Routes = [
    {
        path: '/front/inviter/guide/:invitationCode?',
        component: Guide,
        title: 'G优账户注册',
        share: shareInfo
    },
    {
        path: '/front/inviter/register/:invitationCode?',
        component: Register,
        title: 'G优账户注册',
        share: shareInfo
    },
    {
        path: '/front/inviter/registers/:base64?',
        component: Registers,
        title: 'G优账户注册',
        share: xShareInfo
    },
    {
        path: '/front/inviter/landPage/:activityCode',
        component: LandPage,
        title: 'G优下载引导',
    },
    {
        path: '/front/inviter/success',
        component: Success,
        title: 'G优账户注册',
        share: shareInfo
    }, {
        path: '/front/inviter/:page?/protocol',
        component: Protocol,
        title: 'G优会员服务条款',
        share: shareInfo
    }, {
        path: '/front/inviter/:page?/protocol',
        component: () => {
            return <Protocol style={{ position: 'fixed', overflowY: 'scroll', height: '100%', top: 0, width: '100%' }} />;
        },
        title: 'G优会员服务条款',
        share: shareInfo
    },
    {
        path: '/front/inviter/nftSuccess',
        component: NftSuccess,
        title: '',
        share: shareInfo
    },
    {
        path: '/front/inviter/guessingActy',
        component: GuessingActy,
        title: '',
        share: shareInfo
    }
]
