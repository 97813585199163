/**
 * @description 积分充值相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const { JsBridge } = Library;

const isMock = false

const PageSize = 20
class Service {
    /**
     * @description 获取用户信息
     * @interface MainPage/ 108002
     * @returns {clientId, nftLevel, avatar}
    */

    static getAvatar = async () => {
        // 获取用户信息方式1
        //    const result = await JsBridge.getCurrentUserInfo();
        //    console.log(JSON.stringify(result))
        // 获取用户信息方式2
        // const result = await Services('MainPage', {code: '108002 '}, {});
        // console.log(JSON.stringify(result))
        const result = await JsBridge.runAction('user', 'getAvatar', []);
        let avatar = ''
        if (result.status > 0) {
            avatar = result.data.imagePath || ''
        }
        return { avatar }
    }

    /**
     * @description 获取用户总积分
     * @code 600850
     * @param {*} params
    */
    static getTotalPoint = async (params = {}) => {
        const code = '600850';
        try {
            const res = await Services('MainPage', { code }, params, true);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 积分充值金额列表
     * @code 600833
     * @param {*} params
     * @param { 是否显示加载loading } customLoading
    */
    static rechargeDataList = async (params = {}, customLoading) => {
        const code = '600833'
        if (isMock) {
            const mockData = {
                status: 1,
                data: [
                    { price: 100, discounts: 0.8, marketingType: 2 },
                    { price: 200, discounts: 10, marketingType: 1 },
                    { price: 500, discounts: 0, marketingType: 0 },
                    { price: 1000, discounts: 0, marketingType: 0 },
                    // { price: 2000, discounts: 0, marketingType: 0 },
                    // { price: 5000, discounts: 0, marketingType: 0 },
                ]
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 获取充值卡主题列表接口
     * @code 600838
     * @param {*} params
     * @param { 是否显示加载loading } customLoading
    */
    static getCardTheme = async (params = {}, customLoading) => {
        const code = '600838'
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    records: [
                        { id: '1', theme: '主题', title: '卡主题名称' },
                        { id: '2', theme: '主题', title: '卡主题名称' },
                        { id: '3', theme: '主题', title: '卡主题名称' },
                        { id: '4', theme: '主题', title: '卡主题名称' }
                    ]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }


    /**
     * @description 获取规则说明
     * @code 101193
     * @param {
     *  czxz    充值须知
     * 	gkxy    购卡协议
     * 	gkzc    购卡章程
     * }
     * @param {activityCode: 活动code, codeType: 固定为activityRule} params
    */
    static getRules = async (params = {}) => {
        const code = '101193'
        const ServiceModule = window.JsBridge.hasWebViewBridge() ? 'MainPage' : 'Common';
        try {
            const res = await Services(ServiceModule, { code }, {
                activityCode: params.activityCode,
                codeType: 'activityRule',
                code: params.activityCode,
            });
            if (res.status > 0) {
                return res?.data?.result || res.data
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }

    }

    /**
     * @description 获取充值卡详情列表数据
     * @code 600839
     * @param {pointCardConfigId: 充值卡id } params
     * @param { 是否显示加载loading } customLoading
    */
    static getPointCardDetailData = async (params = {}, customLoading) => {
        const code = '600839'
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    theme: '主题',
                    title: '积分卡',
                    priceList: [100, 200, 500, 1000],
                    list: [
                        { cardId: '1', imageUrl: '' },
                        { cardId: '2', imageUrl: '' },
                        { cardId: '3', imageUrl: '' },
                        { cardId: '4', imageUrl: '' }
                    ]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
    * @description 充值(购买积分)记录接口
    * @code 600834
    * @param {pageSize: Number, currentPage} params
    * @param { 是否显示加载loading } customLoading
    * @returns 
    */
    static fetchBuyPointRecord = async (params = { currentPage: 1 }, customLoading) => {
        const code = '600834'
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    totalCount: 10,
                    record: [
                        { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 1000, desc: '积分充值', orderStatus: 1 },
                        { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 1000, desc: '积分充值', orderStatus: 4 },
                        { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 1000, desc: '积分充值', orderStatus: 5 }
                    ]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const body = {
                pageSize: PageSize,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }

    }

    /**
     * @description 充值后退款接口
     * @code 600836
     * @param {orderNo: 订单ID} params
    */
    static rechargeRefund = async (params = {}, customLoading) => {
        const code = '600836'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
    * @description 充值卡购买记录接口
    * @code 600842
    * @param {pageSize: Number, currentPage} params
    * @param { 是否显示加载loading } customLoading
    * @returns 
    */
    static fetchBuyCardRecord = async (params = { currentPage: 1 }, customLoading) => {
        const code = '600842'
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    totalCount: 10,
                    record: [
                        { payTime: '2023.09.18 17:00:00', totalPrice: 100, desc: 'G优充值卡购买', orderNo: 'xxx' },
                        { payTime: '2023.09.18 17:00:00', totalPrice: 200, desc: 'G优充值卡购买', orderNo: 'xxx' },
                        { payTime: '2023.09.18 17:00:00', totalPrice: 500, desc: 'G优充值卡购买', orderNo: 'xxx' }
                    ]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const body = {
                pageSize: PageSize,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }

    }

    /**
     * @description 积分充值提交订单获取支付订单接口
     * @code 600835
     * @param { price: 提交价格} params
     * @returns {orderNo: 订单编号}
    */
    static payCommit = async (params = {}, customLoading) => {
        const code = '600835'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 积分卡提交订单获取支付订单接口
     * @code 600840
     * @param { totalPrice: 提交价格, pointCardItemRequestList: [{price, num, pointCardId}] } params
     * @returns {orderNo: 订单编号}
    */
    static payCardsCommit = async (params = {}, customLoading) => {
        const code = '600840'
        try {
            let totalPrice = 0;
            const list = (params.cards ||[]).map(item=> {
                totalPrice += item.price * item.count;
                return {
                    price: item.price, 
                    num: item.count, 
                    pointCardId: item.cardId
                }
            })
            const body = {
                totalPrice,
                pointCardItemRequestList: list
            }
            const res = await Services('MainPage', { code }, body, !customLoading);
            if (res.status > 0) {
                res.data.price = res.data.price || totalPrice;
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 我的充值卡列表接口
     * @code 600845
     * @param {currentPage, pageSize} params
     * @returns {}
    */
    static getMyPointCards = async (params = { currentPage: 1 }, customLoading) => {
        const code = '600845'
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    totalCount: 10,
                    records: [
                        { orderTime: '2023.09.18 17:00:00', price: 100, desc: 'G优充值卡', pointCardNo: '2139190900', imageUrl: "https://icon-test.gkewang.com/jkt/business/66c7007f-3d57-404f-a2ed-913dddcbc27f.png", pointCardStatus: 0, tradable: 0, expiredTime: '2024-03-19' },
                        { orderTime: '2023.09.18 17:00:00', price: 200, desc: 'G优充值卡', pointCardNo: '2139190901', imageUrl: "https://icon-test.gkewang.com/jkt/business/66c7007f-3d57-404f-a2ed-913dddcbc27f.png", pointCardStatus: 0, tradable: 1, expiredTime: '2024-03-29' },
                        { orderTime: '2023.09.18 17:00:00', price: 500, desc: 'G优充值卡', pointCardNo: '2139190902', imageUrl: "https://icon-test.gkewang.com/jkt/business/66c7007f-3d57-404f-a2ed-913dddcbc27f.png", pointCardStatus: 1, tradable: 0, expiredTime: '2024-05-02' }
                    ]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const body = {
                pageSize: PageSize,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 我的积分卡-转赠接口
     * @code  600846
     * @param {rUserCode: 接收人手机号, pointCardNo: 充值卡编号} params
     * @returns {}
    */
    static pointCardTran = async (params = {}, customLoading) => {
        const code = '600846'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            return res;
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 我的积分卡-退卡接口
     * @code 600849
     * @param {pointCardNo: 充值卡编号} params
    */
    static pointCardRefund = async (params = {}, customLoading) => {
        const code = '600849'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 我的积分卡-充值接口
     * @code 600858
     * @param {pointCardNo: 充值卡编号} params
    */
    static pointCardRecharge = async (params = {}, customLoading) => {
        const code = '600858'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 我的充值卡记录
     * @code {充值记录1: 600844, 转赠记录2: 600847, 退卡记录3: 600848}
     * @param {currentPage, pageSize, type: 1-充值；2-转赠；3-退卡 } params
     * @returns {}
    */
    static myPointCardsRecord = async (params = { currentPage: 1, type: 1 }, customLoading) => {
        const code = { 1: '600844', 2: '600847', 3: '600848' }[params.type];
        
        if (isMock) {
            const mockData = {
                status: 1,
                data: {
                    totalCount: 10,
                    record: {
                        1: [
                            { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 1000, desc: 'G优充值卡充值', pointCardNo: '2139190900', imageUrl: "" },
                            { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 2000, desc: 'G优充值卡充值', pointCardNo: '2139190901', imageUrl: "" },
                            { rechargeTime: '2023.09.18 17:00:00', rechargePoint: 5000, desc: 'G优充值卡充值', pointCardNo: '2139190902', imageUrl: "" }
                        ],
                        2: [
                            { transferTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190900', rUserCode: "178****6788" },
                            { transferTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190901', rUserCode: "178****6788" },
                            { transferTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190902', rUserCode: "178****6788" }
                        ],
                        3: [
                            { refundTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190900', pointCardStatus: "", refund: '' },
                            { refundTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190901', pointCardStatus: "", refund: '' },
                            { refundTime: '2023.09.18 17:00:00', desc: 'G优充值卡充值', pointCardNo: '2139190902', pointCardStatus: "", refund: '' }
                        ],
                    }[params.type]
                }
            };
            const res = await Promise.resolve(mockData)
            if (res.status > 0) {
                return res
            }
            return undefined;
        }
        try {
            const body = {
                pageSize: PageSize,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

}
Service.PageSize = PageSize;
export default Service;