import React, { useEffect, useMemo, useState } from 'react';
import Library from 'library';
import { useHistory } from 'react-router-dom';
import Services from 'services';
import styles from './redTheme.module.scss';
import { End as ActivityEnd } from '../activities51/end';

import ShareModal from './components/ShareModal'
import DrawComponent from './components/Draw'
import BlessComponent from './components/Bless'
import { Slogan } from 'components/slogan';

import backIcon from 'images/icon/back-svg-black.svg';
import shareIcon from './images/share.png';
import rulesImg from './images/icon-activity-rule@2x.png';
import headTitle from './images/title@2x.png'
import backToTopImg from './images/backtop@2x.png';
import bigBaseImage from './images/bigBaseImage.png';
import GUImage from './images/GUImage.png';
import bottomImage from './images/bottom@2x.png';
import finalPrizeImage from './images/finalPrize@2x.png';
import videoPreloadImage from './images/Video.png';

const { JsBridge, Platform, Hooks, Util } = Library;
export const RedTheme = (props) => {
    const history = useHistory();
    const [statusBarHeight, setStatusBarHeight] = useState(0);
    const [shareModalStatus, setShareModalStatus] = useState(false);
    const [pageData, setPageData] = useState(null);

    const pageInit = async () => {
        if (Platform.check.isApp()) {
            const { height } = await JsBridge.getStatusBarHeight();
            setStatusBarHeight(height);
            JsBridge.setStatusBar('setBarStyle', ['dark-content']);
            const result = await Services('MainPage', { code: '101189' }, { activityCode: "SEVENONE" })
            if (result.status > 0) {
                const { data } = result;
                data.startTime = new Date((data.activityStartTime || '').replace(/-/g, '/'));
                data.endTime = new Date((data.activityEndTime || '').replace(/-/g, '/'));
                data.nowTime = new Date((data.nowTime || '').replace(/-/g, '/'));
                setPageData(data);
                return;
            }
        } else {
            setPageData({
                startTime: new Date(),
                nowTime: new Date(),
                endTime: new Date(),
            });
        }
    }


    useEffect(() => {
        pageInit();
    }, []);


    const BackToTop = useMemo(() => {
        return BackToTopComponent;
    }, []);
    const Draw = useMemo(() => {
        return DrawComponent;
    }, []);
    const Bless = useMemo(() => {
        return BlessComponent;
    }, [pageData]);

    if (!pageData) {
        return <div className={styles.container} >
            <Navigator statusBarHeight={statusBarHeight} setShareModalStatus={setShareModalStatus} />
        </div>;
    }
    if (pageData.endTime < pageData.nowTime) {
        return <ActivityEnd />;
    }

    return <div className={styles.container} >
        <Navigator statusBarHeight={statusBarHeight} setShareModalStatus={setShareModalStatus} />
        <Content startTime={pageData.startTime} endTime={pageData.endTime} />
        <Bless blessingPojo={pageData.blessingPojo || {}} codeName={pageData.codeName || ''} blessingFlag={pageData.blessingFlag || false} allList={(pageData.allList && pageData.allList.slice(0, 15)) || []} count={pageData.count || 0} />
        <Draw lotteryFlag={pageData.lotteryFlag} />
        <Bottom />
        <BackToTop />
        <ShareModal setShareModalStatus={setShareModalStatus} shareModalStatus={shareModalStatus} />
    </div>
}

const Navigator = (props) => {
    const { statusBarHeight } = props;
    return <>
        <div style={{ paddingTop: (statusBarHeight) + 'px' }}>
            <div className={styles.header} ></div>
        </div>
        <div className={styles.navigator} style={{ paddingTop: statusBarHeight + 'px' }}>
            <div className={styles.header} >
                <div className={styles.left} onClick={() => {
                    JsBridge.back();
                }}>
                    <img src={backIcon} alt='箭头' />
                </div>
                <div className={styles.title} >
                    活动详情
                </div>
                <div onClick={() => {
                    props.setShareModalStatus(true);
                }} className={styles.right}>
                    <img src={shareIcon} alt='分享' />
                </div>
            </div>
        </div>
    </>
}

const Content = (props) => {
    const history = useHistory();
    const { startTime, endTime } = props;
    return <div className={styles.content}>
        <div className={styles.topBackground}></div>
        <div className={styles.topicShow}>
            <div className={styles.rulesButton}>
                <img src={rulesImg} alt="活动规则" className={styles.rulesImg} onClick={() => {
                    JsBridge.open('/front/activities/activity71/rules');
                }} />
            </div>
            <div className={styles.contentHeader}>
                <img src={headTitle} alt="title" className={styles.contentHeadTitle} />
                <span className={styles.subTitle}>绿地G优与绿地大基建集团携手奉献</span>
                <span className={styles.tipText}>为党和国家送上祝福寄语，即可抽奖，100%中奖</span>
                <span className={styles.tipText}>活动结束后我们将从祝福寄语中选出五条颁发大奖</span>
            </div>
            <div className={styles.awardContent}>
            </div>
            <div className={styles.activityTimeBox}>
                <div className={styles.activityTime}>
                    <div>活动时间:{Util.format.time(startTime, 'yyyy.MM.dd')}-{Util.format.time(endTime, 'yyyy.MM.dd')}</div>
                    <div>大奖揭晓时间:2021.8.8</div>
                </div>
                <div>阅读下文“百年历程”图文及视频，留下祝福和寄语，即刻参与抽奖</div>
            </div>
        </div>
        <div className={styles.bigBase}>
            <div className={styles.bigBaseHead}></div>
            <div className={styles.bigBaseFoot}>
                <img src={bigBaseImage} alt="大基建" className={styles.bigBaseImage} />
                <div className={styles.baseIntro}>绿地大基建集团（简称“绿地大基建”） 是绿地控股集团把握中国新型城镇化及基础设施互联互通的历史机遇，聚焦基础设施建设，全力打造的千亿级产业发展平台之一，组建于2020年4月，总部设立于中国上海。</div>
                <div className={styles.baseIntro}>集团旗下现拥有绿地城建集团、贵州建工集团、绿地城投集团、江苏省建集团、西安建工集团、天津建工集团、广西建工集团、上海市水利工程集团、河南省公路工程局集团等9家成员企业；拥有建筑工程、市政工程、公路工程施工总承包特级资质近20个，积极承接轨道交通、高速公路、市政桥梁等重大基础设施项目，业务布局覆盖海内外50多个国家和地区，经营范围涵盖基建行业各个领域，整体规模位居行业前列。</div>
            </div>
        </div>
        <div className={styles.GUIntro}>
            <div className={styles.GUHead}></div>
            <div className={styles.bigBaseFoot}>
                <img src={GUImage} alt="G优" className={styles.GUImage} />
                <div className={styles.baseIntro}>“绿地G优尊享会”以“G优永久G分，让生活更缤纷”的理念和定位，发挥绿地多元产业优势，以优质产业群为依托，以多样化产品为载体，以“开放、共享、极致”的互联网思维，打造一个以绿地业主及各产业链客户为中心，以全生活需求为场景，以共享绿地发展成果为目的，惠及绿地业主及全产业链客户的权益专享专属线上服务平台。</div>
                <div className={styles.baseIntro}>绿地G优APP为你带来11大铂金权益、52项VIP服务和涵盖安居、汽车、金服、购物、旅游、康养、医疗、教育、酒店9大板块的社区服务，将逐步拓展绿地集团合作伙伴等资源，将打造成业内生活需求场景覆盖全面的客户线上服务APP。  平台将是传统房企业主平台的全新升级换代，打通了绿地集团旗下9大类近百项全产业资源，打造业内资源最全的线上服务平台。</div>
            </div>
        </div>
        <div className={styles.videoWrap}>
            <video playsInline loop preload='preload' controls="controls" poster={videoPreloadImage} src="https://icon.gkewang.com/gu/h5/media/20210622.mp4"></video>
        </div>
        <div className={styles.partyHistory}>
            <div className={styles.firstStageWrap}>
                <div>1921年-1949年</div>
                <div>新民主主义革命时期</div>
            </div>
            <div className={styles.firstStageOne}>
                <div>开天辟地</div>
                <div>1921年7月，中国共产党诞生，中国革命和建设事业从此有了坚强的领导核心。</div>
            </div>
            <div className={`${styles.firstStageOne} ${styles.firstStageBlock}`}>
                <div>星火燎原</div>
                <div>1927年8月，中国共产党人发动南昌起义，打响了武装反抗国民党反动统治的第一枪。</div>
            </div>
            <div className={`${styles.firstStageOne} ${styles.firstStageBlock}`}>
                <div>中流砥柱</div>
                <div>1937年7月，卢沟桥事变，中日战争全面爆发。中国共产党成为中国抗日战争当之无愧的中流砥柱。</div>
            </div>
            <div className={styles.twoStageWrap}>
                <div>1949年-1978年</div>
                <div>社会主义革命和建设时期</div>
            </div>
            <div className={styles.twoStageOne}>
                <div>开国大业</div>
                <div>1949年10月，中华人民共和国成立，中国人民从此当家作主。</div>
            </div>
            <div className={`${styles.twoStageOne} ${styles.twoStageBlock}`}>
                <div>探索前进</div>
                <div>1976年10月，粉碎“四人帮”，工作中心开始转向经济建设。</div>
            </div>
            <div className={styles.threeStageWrap}>
                <div>1978年-现在</div>
                <div>中国现代化建设新时期</div>
            </div>
            <div className={styles.threeStageOne}>
                <div>改革开放</div>
                <div>1978年12月，十一届三中全会后，中国开始实施“改革开放”的一系列经济改革和措施。</div>
            </div>
            <div className={`${styles.threeStageOne} ${styles.threeStageBlock}`}>
                <div>辉煌成就</div>
                <div>20世纪90年代以来，经过党和全国人民的共同努力，社会主义建设事业取得了举世瞩目的伟大成就。</div>
            </div>
        </div>
    </div>
}

const BackToTopComponent = () => {
    const [opacity, setOpacity] = useState(0);
    const handleScroll = (event) => {
        if (document.documentElement.scrollTop || document.body.scrollTop > window.screen.height) {
            setOpacity(1);
        } else {
            setOpacity(0);
        }
    }
    Hooks.useEventListener('scroll', handleScroll, document);

    return <div className={styles.backToTop} style={{ opacity }} onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }}>
        <img src={backToTopImg} alt='回到顶部' />
    </div>
}

const Bottom = (props) => {
    return <div className={styles.bottom}>
        <img className={styles.finalPrizeImage} src={finalPrizeImage} alt='最终大奖' />
        <h2>2021.8.8开启终极大奖<br />
            敬请期待</h2>
        <Slogan />
        <img src={bottomImage} alt='底图' />
    </div>
}