// eggDialog.js
import React, { useRef, useState, useEffect } from 'react';
import styles from '../redTheme.module.scss';
import Library from 'library';
const { Util } = Library;
let scrollInterval;
let timer;

export class Marquee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animate: false,
            listMarginTop: "0",
            animate: false,
            data: props.list || []
        }
    }
    componentDidMount() {
        this.startScrollUp();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.list });
    }
    scrollUp = e => {
        this.state.data.push(this.state.data[0]);

        if (this.marqueeRef && this.marqueeRef.getElementsByClassName(styles["marquee-content-itm"])[0]) {
            let height = this.marqueeRef.getElementsByClassName(styles["marquee-content-itm"])[0].scrollHeight;
            this.setState({
                animate: true,
            }, () => {
                this.setState({ listMarginTop: "-" + height + "px", });
            });
            timer = setTimeout(() => {
                this.state.data.shift();
                this.setState({
                    animate: false,
                    listMarginTop: "0",
                });
                this.forceUpdate();
            }, 2000)
        }
    }

    startScrollUp = e => {
        this.scrollUp();
        scrollInterval = setInterval(this.scrollUp, 3000);
    }

    componentWillUnmout() {
        clearInterval(scrollInterval);
    }

    render() {
        const { data, listMarginTop, animate } = this.state;
        return <div className={styles['marquee-container']} >
            <div className={styles["marquee-wrapper"]}>
                <div className={`${styles["marquee-content"]} ${animate ? styles.animate : ''}`} style={{ marginTop: listMarginTop }} ref={(e) => { this.marqueeRef = e; }}>
                    {
                        data.map((itm, i) => {
                            if (itm) {
                                return <div key={`${itm}-${i}`} className={styles["marquee-content-itm"]}>
                                    <p>
                                        {`${Util.format.mobileSecurity(itm.mobile)}对党说： ${Util.format.entitiesToUtf16(itm.content)}`}
                                    </p>
                                </div>
                            } else {
                                return false;
                            }
                        })
                    }
                </div>
            </div>
        </div>
    }
}
