import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import Immutable from 'immutable';
import * as thunkModule from 'redux-thunk';
import { typedToPlain } from 'redux-typed';
import * as _allReducers from './reducers/index';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reduxToStorage } from './middleware';

export const history = createBrowserHistory();

const configureStore = (initialState) => {

    const immutableArrays = [
        'routing',
        'navigator'
    ];
    Object.keys(initialState).forEach((key) => {
        if (immutableArrays.indexOf(key) < 0) {
            initialState[key] = Immutable.fromJS(initialState[key]);
        }
    });

    const thunk = thunkModule.default;
    const windowIfDefined = typeof window === 'undefined'
        ? null
        : window;
    const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension; // If devTools is installed, connect to it
    const createStoreWithMiddleware = compose(
        // applyMiddleware(thunk, typedToPlain, middleware),
        applyMiddleware(thunk, typedToPlain, reduxToStorage, routerMiddleware(history)), devToolsExtension
        ? devToolsExtension()
        : (f) => {
            return f;
        })(createStore);
    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(history);
    const store = createStoreWithMiddleware(allReducers, initialState);
    // Enable Webpack hot module replacement for reducers
    return store;
}
/**
 *
 * @param initialState
 * @returns {*}
 */
export default configureStore;

/**
 *
 * @param history
 * @returns {*}
 */
function buildRootReducer(history) {
    //console.log(allReducers);
    return combineReducers({
        router: connectRouter(history),
        ..._allReducers // rest of your reducers
    });
}
