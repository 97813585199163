/**
 * @description 攻略弹框
*/

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Toast, Mask } from 'antd-mobile';
import { CARBONPOINT } from "library/util/constant";
import Services from "services";
import Styles from './index.module.scss';

const GuidePopup = ({ visible, onClose, onConfirm }) => {

    const [_visible, setVisible] = useState(false);
    const [otherSign, setOtherSign] = useState('');

    useEffect(() => {
        if (visible) {
            loadData();
            setVisible(true)
        }
    }, [visible]);

    const loadData = async () => {
        
        const res = await Services('MainPage', { code: 101193 }, {
            activityCode: CARBONPOINT,
            codeType: 'activityRule',
            code: 'activityCarbonPoint',
        }, false);
        
        if (res.status > 0) {
            const { otherSign = '' } = res.data?.activityRule;
            setOtherSign(otherSign);
        }
    }

    const onCancel = () => {
        setVisible(false);
        setTimeout(onClose, 1000);
    }

    return <Mask
        visible={_visible}
        onMaskClick={onCancel}
    >
        <div className={Styles.guidePopup}> 
            <div className={Styles.guide_title}>
                <span>攻略</span>
            </div>
            <div className={Styles.carbon_integral_gl}>
                <p>{otherSign}</p>
            </div>
            <div className={Styles.guide_btn} onClick={onCancel}>我知道了</div>
        </div>
    </Mask>
}
GuidePopup.show = () => {

    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<GuidePopup
        onClose={close}
        visible
    />, div);
}
export default GuidePopup;