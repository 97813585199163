import React, { Component } from 'react'
import QQImage from '../../signActivity/images/QQ-icon@2x.png';
import qqkongjianImage from '../../signActivity/images/qqkongjian-icon@2x.png';
import weiboImage from '../../registionActivity/images/weibo@2x.png';
import MomentImage from '../../signActivity/images/Moments-icon@2x.png';
import WechartImage from '../../signActivity/images/WeChat-icon@2x.png';
import styles from '../shareModal.module.scss';
import Library from 'library';
const { JsBridge } = Library;
export default (props) => {
    const { shareModalStatus, setShareModalStatus } = props;

    if (shareModalStatus === false) return false;

    const shareToPlatform = async (platform) => {
        const share ={
            text: '绿地G优与绿地大基建集团携手奉献，邀请您为党和国家送上祝福寄语！上绿地G优，读“百年历程”，重温红色经典，传承伟大精神。', 
            link: 'https://app.gkewang.com/app',
            icon: 'https://h5.gkewang.com/crmh5/src/images/logo-share.jpg',
            title: '百年颂党恩，绿地送祝福!',
            platform,
        };
        const result = await JsBridge.share({...share});
        JsBridge.runAction('alert', 'error', [result.message]);
    }

    return (
        <div className={styles.shareMask}>
            <div onClick={() => {
                setShareModalStatus(false);
            }} className={styles.mask}></div>
            <div className={styles.shareFullBox}>
                <div className={styles.shareTitle}>请选择分享平台</div>
                <div className={styles.shareInfo}>
                    <button onClick={() => {
                        shareToPlatform(2)
                    }}><img src={MomentImage} />
                        <p>朋友圈</p>
                    </button>

                    <button onClick={() => {
                        shareToPlatform(1)
                    }}><img src={WechartImage} />
                        <p>微信好友</p>
                    </button>
                    <button onClick={() => {
                        shareToPlatform(4)
                    }}><img src={QQImage} />
                        <p>QQ好友</p>
                    </button>
                    <button onClick={() => {
                        shareToPlatform(5)
                    }}><img src={qqkongjianImage} />
                        <p>QQ空间</p>
                    </button>
                    <button onClick={async () => {
                        const res = await JsBridge.canOpenLink('sinaweibo://splash');
                        if (res) {
                            shareToPlatform(0)
                        } else {
                            JsBridge.runAction('alert', 'error', ['请先安装新浪微博客户端']);
                        }
                    }}><img src={weiboImage} />
                        <p>新浪微博</p>
                    </button>
                </div>
                <div className={styles.shareButton}>
                    <button className={styles.shareCancel} onClick={() => {
                        setShareModalStatus(false);
                    }}>取 消</button>
                </div>
            </div>
        </div>
    )

}
