import React, {useState} from "react";
import './style.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import bg from '../imgs/dowlode-page.png';
import Library from 'library';

const downloadUrl = 'https://app.gkewang.com/app';

const GoddessInviteSuccess = () => {
  const [maskShow, setMaskShow] = useState(false);

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = downloadUrl;
    }
  }

  return <div className="world-cup-guess-share">
    <img src={bg} alt="" />
    <div className='load-btn' onClick={() => openApp()}>下载/打开APP</div>
    {maskShow && <div className='mask' onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default GoddessInviteSuccess;