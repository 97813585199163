/**
 * @description 问卷调查接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';

const mockData = {
    "status": 1,
    "message": "处理成功",
    "data": {
        "filledFlag": 1,
        "currentTime": "2023-05-04 09:48:06",
        "stemVOList": [
            {
                "id": 114,
                "questionId": 22,
                "stemNo": 0,
                "stemText": "这是题目名称",
                "questionType": 0,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [
                    {
                        "id": 1140,
                        "stemId": 114,
                        "optionNo": "A",
                        "optionType": 2,
                        "optionText": "选项一这里我讲设置更多的字看下是增显示的是否换行",
                        "optionImg": 'https://icon-test.gkewang.com/jkt/activity/d1f2fde7-6baa-4cb9-a4a1-4d3f842f07bd.png',
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:39:58"
                    },
                    {
                        "id": 1141,
                        "stemId": 114,
                        "optionNo": "B",
                        "optionType": 0,
                        "optionText": "选项一",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:40:02"
                    },
                    {
                        "id": 1143,
                        "stemId": 114,
                        "optionNo": "C",
                        "optionType": 0,
                        "optionText": "选项三",
                        "optionImg": null,
                        "isAnswer": 0,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 14:08:07"
                    }
                ],
                "resultList": [
                    {
                        "id": 3,
                        "clientId": "b9a83c01dc4b49729093d2aa5e73c369",
                        "questionId": 22,
                        "stemNo": 2,
                        "optionNo": "C",
                        "content": null,
                        "questionType": 0,
                        "createTime": "2023-05-02 17:57:28",
                        "updateTime": "2023-05-02 17:57:28"
                    }
                ]
            },
            {
                "id": 115,
                "questionId": 22,
                "stemNo": 2,
                "stemText": "题目1",
                "questionType": 0,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [
                    {
                        "id": 125,
                        "stemId": 115,
                        "optionNo": "A",
                        "optionType": 0,
                        "optionText": "2",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:39:58"
                    },
                    {
                        "id": 124,
                        "stemId": 115,
                        "optionNo": "B",
                        "optionType": 0,
                        "optionText": "1",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:40:02"
                    },
                    {
                        "id": 126,
                        "stemId": 115,
                        "optionNo": "C",
                        "optionType": 1,
                        "optionText": "文本答案",
                        "optionImg": null,
                        "isAnswer": 0,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 14:08:07"
                    }
                ],
                "resultList": [
                    {
                        "id": 3,
                        "clientId": "b9a83c01dc4b49729093d2aa5e73c369",
                        "questionId": 22,
                        "stemNo": 2,
                        "optionNo": "C",
                        "content": "其他选项",
                        "questionType": 0,
                        "createTime": "2023-05-02 17:57:28",
                        "updateTime": "2023-05-02 17:57:28"
                    }
                ]
            },
            {
                "id": 116,
                "questionId": 22,
                "stemNo": 1,
                "stemText": "题目2",
                "questionType": 1,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:10",
                "updateTime": "2023-05-02 14:25:10",
                "optionList": [
                    {
                        "id": 127,
                        "stemId": 116,
                        "optionNo": "A",
                        "optionType": 0,
                        "optionText": "A",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:09:33",
                        "updateTime": "2023-05-02 14:09:33"
                    },
                    {
                        "id": 128,
                        "stemId": 116,
                        "optionNo": "B",
                        "optionType": 0,
                        "optionText": "B",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:09:33",
                        "updateTime": "2023-05-02 14:09:33"
                    },
                    {
                        "id": 129,
                        "stemId": 116,
                        "optionNo": "C",
                        "optionType": 0,
                        "optionText": "C",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:09:33",
                        "updateTime": "2023-05-02 14:09:33"
                    },
                    {
                        "id": 130,
                        "stemId": 116,
                        "optionNo": "D",
                        "optionType": 0,
                        "optionText": "D",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:09:33",
                        "updateTime": "2023-05-02 14:09:33"
                    }
                ],
                "resultList": [
                    {
                        "id": 1,
                        "clientId": "b9a83c01dc4b49729093d2aa5e73c369",
                        "questionId": 22,
                        "stemNo": 1,
                        "optionNo": "A",
                        "content": null,
                        "questionType": 1,
                        "createTime": "2023-05-02 17:57:28",
                        "updateTime": "2023-05-02 17:57:28"
                    },
                    {
                        "id": 2,
                        "clientId": "b9a83c01dc4b49729093d2aa5e73c369",
                        "questionId": 22,
                        "stemNo": 1,
                        "optionNo": "B",
                        "content": null,
                        "questionType": 1,
                        "createTime": "2023-05-02 17:57:28",
                        "updateTime": "2023-05-02 17:57:28"
                    }
                ]
            },
            {
                "id": 119,
                "questionId": 22,
                "stemNo": 6,
                "stemText": "选择日期",
                "questionType": 4,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [],
                "resultList": [
                    {
                        "stemId": 119,
                        "content": '2023-05-06',
                    }
                ]
            },
            {
                "id": 117,
                "questionId": 22,
                "stemNo": 3,
                "stemText": "填空题",
                "questionType": 2,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [
                    {
                        "id": 1170,
                        "stemId": 117,
                        "optionNo": "A",
                        "optionType": 0,
                        "optionText": "2",
                        "optionImg": null,
                        "isAnswer": 1,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:39:58"
                    },
                    {
                        "id": 1171,
                        "stemId": 117,
                        "optionNo": "B",
                        "optionType": 0,
                        "optionText": "1",
                        "optionImg": null,
                        "isAnswer": null,
                        "isValid": 1,
                        "createTime": "2023-05-02 14:08:07",
                        "updateTime": "2023-05-02 17:40:02"
                    },
                ],
                "resultList": [
                    {
                        "id": 1170,
                        "content": '这个必填',
                    }
                ]
            },
            {
                "id": 118,
                "questionId": 22,
                "stemNo": 4,
                "stemText": "单个填空题",
                "questionType": 2,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [],
                "resultList": [{
                    "content": '填空的内容',
                }]
            },
            {
                "id": 120,
                "questionId": 22,
                "stemNo": 5,
                "stemText": "简答题",
                "questionType": 3,
                "isAnswer": 1,
                "isValid": 1,
                "createTime": "2023-05-02 14:25:08",
                "updateTime": "2023-05-02 14:25:08",
                "optionList": [],
                "resultList": [
                    {
                        "content": '简单表述的内容',
                    }
                ]
            }
        ],
        "questionnaireInfo": {
            "id": 22,
            "questionName": "问卷调查test",
            "reward": "100",
            "rule": "问卷调查测试test",
            "questionBeginTime": "2023-05-02 00:00:00",
            "questionEndTime": "2023-05-13 00:00:00",
            "isValid": 1,
            "createTime": "2023-05-02 14:07:16",
            "updateTime": "2023-05-02 14:07:16"
        }
    }
}

class Service {

    /**
     * @description 获取问卷调查信息接口
     * @code 101344
     * @param {questionId: 问卷id} params 
     * @param { 是否显示加载loading } customLoading
     * @returns 
     */
    static getQuestionInfo = async (params = {}, customLoading) => {
        const code = '101344'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            // const res = mockData;
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 提交保存问卷调查接口
     * @code 101345
     * @param {questionId: '问卷id', resultList: [{stemNo: 题号, questionType: 0 单选 1 多选 2 填空 3 简答, content: 内容, optionNo: 答案序号}]} params 
     * @param { 是否显示加载loading } customLoading
     * @returns 
     */
    static saveCommitInfo = async (params = {}, customLoading) => {
        const code = '101345'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
}

export default Service;