/**
 * @description 母亲节领奖记录页
 * @returns route: /front/activities/mothersDay/receive-prize-record
*/

import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'antd-mobile';
import { Header, ListView } from 'components';
import Library from "library";
import Service from '../service';
import './index.scss';

const { JsBridge, Util } = Library;

const ReceivePrizeRecord = ()=> {

    const currentPage = useRef(1)
    const [dataList, setDataList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    useEffect(() => {
        loadData();
    }, []);

    const onDidFocus = () => {
      JsBridge.addListener().then(() => {
        loadData();
      });
    }

    const loadData = async () => {
        currentPage.current = 1;
        setIsLoading(true)
        const res = await Service.getReceiveGiftList();
        setIsLoading(false)
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = [] } = res.data
            setDataList(list)
            setHasMore(list.length >= 10)
        }
    }
    const loadMore = async () => {
        const res = await Service.getReceiveGiftList({pageNum: `${currentPage.current + 1}`});
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = []} = res.data
            setDataList([...dataList, ...list])
            setHasMore(list.length >= 10)
            currentPage.current = currentPage.current + 1
        }
    }

    const addOrChangeAddress = (item) => {
        if(item.address) {
            const param = Util.url.objectToQueryParams(item)
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/activities/lottery/orderDetail?${param}`});
        }else {
            const param = Util.url.objectToQueryParams({
                idf: Service.activityCode,
                activityCode: Service.activityCode,
                req: item.id,
                name: item.prizeName,
                imgUrl: item.prizeImgUrl
            })
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/guapp/activityOrder?${param}`});
            onDidFocus();
        }
    }

    const renderItem = (item)=> {

        return (
            <div className='record-itemContainer'>
                <div className={'itemLeft'}>
                    <div className={'itemTop'}>
                        <div className={'itemTopTitle'}>
                            {`${item?.prizeName}`}
                        </div>
                    </div>
                    <div className={'itemTime'}>
                        {`${item?.createTime}`}
                    </div>
                </div >
                {
                    item?.prizeType === 3 && 
                    <div className={'itemAddressBtn'}>
                        <div className={'itemAddressBtnTitle'} 
                            onClick={() => {addOrChangeAddress(item)}}>
                            {item.address ? `查看领取奖品详细信息` : `填写地址领取奖品`}
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='receive-prize-record'>
            <Header midText={'领取记录'} shadow/>
            <div className='container'>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    onRefresh={()=>{
                        loadData(false)
                    }}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}

export {
    ReceivePrizeRecord
};