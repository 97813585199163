export const vipLevel = {// 0=青玉 1=黑砖 2=白金 3=水晶
    qy: {
        name: '青玉会员',
        id: 0,
    },
    hz: {
        name: '黑钻会员',
        id: 1,
    },
    bj: {
        name: '白金会员',
        id: 2,
    },
    sj: {
        name: '水晶会员',
        id: 3,
    },
}
