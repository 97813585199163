import React, { useMemo, lazy } from 'react';
import { Admin, Main, Member, Doc, Activity, IsRegisted } from './layouts';
import { Routes as adminRoutes } from './admin';
import { Routes as memberRoutes } from './member';
import { Routes as inviterRoutes } from './inviter';
import { Routes as docRoutes } from './doc';
import { Routes as activityRoutes } from './activities';
import { Routes as guRoutes } from './guapp';
import { Routes as licaiInviterRoutes } from './licaiInviter';
import { Routes as recruit } from './recruit';
import GUComponents from '../components';
import { Route } from 'react-router-dom';

export const Routes = [{
    path: '/',
    component: Main,
    routes: [{
        path: '/admin',
        component: Admin,
        routes: adminRoutes
    }, {
        path: '/member',
        component: Member,
        routes: memberRoutes
    }, {
        path: '/inviter',
        component: Member,
        routes: inviterRoutes
    }, {
        path: '/doc',
        component: Doc,
        routes: docRoutes
    }, {

        path: '/isRegisted',
        component: IsRegisted,
        routes: memberRoutes,
    }, {
        path: '/front/activities',
        component: Activity,
        routes: activityRoutes
    },
    {
        path: '/front/guApp',
        component: Activity,
        routes: guRoutes
    },
    {
        path: '/front/licaiInviter',
        component: Activity,
        routes: licaiInviterRoutes
    },
    {
        path: '/front/recruit',
        component: Activity,
        routes: recruit
    }
    ]
}];

const highOrderedRoute = (route) => {
    if (route.routes) {
        route.routes = route.routes.map((_route, i) => {
            return highOrderedRoute(_route);
        });
        return route;
    } else {
        const thisComponent = route.component;
        const TitleComponent = GUComponents.ChangeTitle(thisComponent);
        const ShareComponent = GUComponents.Share(TitleComponent);
        route.component = ShareComponent;
        return route;
    }
};

const RouteWithSubRoutes = (route) => {

    const renderChildren = useMemo(() => {
        return (
            <Route path={route.path.indexOf('/front') > -1 ? route.path : `/front${route.path}`} 
                exact={route.exact || false} 
                render={(props) => {
                    if (route.routes) {
                        return (<route.component {...props}>
                            {
                                route.routes.map((_route, i) => {
                                    return (<RouteWithSubRoutes key={i} {..._route} />);
                                })
                            }
                        </route.component>);
                    } else {
                        return (<route.component {...props} title={route.title || '绿地G优尊享会'} share={route.share} />);
                    }
                }} 
            />
        );
    }, [route]);

    return renderChildren;

};

export const RouteRender = () => {
    return <React.Suspense fallback={<></>}>
        {Routes.map((route, i) => {
            const thisRoute = highOrderedRoute(route);
            return (
                <RouteWithSubRoutes key={i} {...thisRoute} />
            );
        })}

    </React.Suspense>
};
