import React, {useEffect, useState} from 'react';
import styles from "./eggGuide.module.scss";
// import btnImg from './img/btn-bg.png';
import gotoBrowserImg from 'images/goto-browser.png';

import img_zhuli_bg from 'images/egg_xiazai_zhuli.png';
import img_canjia_bg from 'images/egg_xiazai_canjia.png';
import btn_down from 'images/egg_bt_xiazai.png';

import Library from 'library';

const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/app';

export const EggGuide = () => {
    const {
        type = '0', //0=参加 1=助力
        inviteCode,
        activityCode,
    } = Library.Util.url.paramsToObj();
    const [maskShow, setMaskShow] = useState(false);
    const targetUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/petEggs/petEggHome`;
    const luanchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(`${targetUrl}`)}`;

    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setMaskShow(true);
        } else {
            window.location.href = luanchUrl;
            setTimeout(() => {
                window.location.href = downloadUrl;
            }, 3000);
        }
    }

    return <div className={styles.activity_page}>
        <img src={type === '0' ? img_canjia_bg : img_zhuli_bg} alt=""/>
        <div className={styles.btn_box} onClick={() => openApp()}>
            <img src={btn_down} alt=""></img>
        </div>
        {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
            <img src={gotoBrowserImg} alt=""/>
        </div>}
    </div>
}

