import React, { useEffect, useState,useRef } from 'react';
import styles from './modal.module.scss';
export const Modal = (props)=>{
    const displayButtons = [{
        name: '取消', click: () => {
            props.onCancel && props.onCancel();
        },
        className: styles.cancel,
    },{
        name: props.content.confirm, click: () => {
            props.onOK && props.onOK();
        },
        className: styles.confirm,
    }];
    return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
        <div className={styles.modalWrap}>
            <div className={styles.modalContent}>{props.content.head || ''}</div>
            <div className={styles.modalElement}>{props.content.title}</div>
            <div className={styles.modalButton}>
                {displayButtons.map((item, key) => {
                    return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                })}
            </div>
        </div>
    </div>
}
