import { WEIXIN_API_CONFIG_START, WEIXIN_API_CONFIG_END } from '../../../constants';

export const wxApi = {
    start: () => {
        return {
            type: WEIXIN_API_CONFIG_START,
        };
    },
    end: () => {
        return { type: WEIXIN_API_CONFIG_END };
    },

};
