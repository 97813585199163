import React, {lazy} from 'react';
import {MyCard, TempSuccess, Privilege, VipDesc, TempDesc, Protocol, Qrcode} from './card';
import * as GetCard from './getCard';
import {Mall} from './credit';
import {Home} from './home';
import {Register, RegisterSuccess} from './register';
import {MyCoupon, HistoryCoupon} from './coupon';
import * as regularShare from '../activities/regularShare';
import Aboutus from './aboutus';
import * as Appointment from './appointment';
import * as VipCard from './vipCard';
import * as PointRecharge from './pointRecharge';
import {
    AddExtraHome,
    RespectRights,
    HotInformation,
    FamilyInherit,
    WealthManage,
    KnowUs,
    MessageDetail,
    MemberLevel,
    UseRecord,
} from "./family";


/**
 * 路由规则：
 * member开头：无需校验是否绑定手机号
 * isRegisted开头：微信打开页面后，layout中会校验手机号绑定状态，未绑定则跳转注册/登录页
 */
export const Routes = [{
    path: '/front/member/tempSuccess',
    component: TempSuccess,
    title: '绿地G优尊享会',
},
    {
        path: '/front/member/privilege/:index?',
        component: Privilege,
        title: '绿地G优尊享会',
    },
    {
        path: '/member/register',
        component: Register,
        title: '绿地G优尊享会',
    },
    {
        path: '/front/member/regSuccess',
        component: RegisterSuccess,
        title: '绿地G优尊享会',
    },
    {
        path: '/front/member/family/addExtraHome',
        component: AddExtraHome,
        title: '家办首页',
    },
    {
        path: '/front/member/family/knowUs',
        component: KnowUs,
        title: '了解我们',
    },
    {
        path: '/front/member/family/respectRights',
        component: RespectRights,
        title: '尊享权益',
    },
    {
        // path: '/front/member/family/hotInformation',
        path: '/front/member/family/hotInformation/:tabIndex?',
        component: HotInformation,
        title: '热门资讯',
    },
//familyInherit
    {
        path: '/front/member/family/familyInherit',
        component: FamilyInherit,
        title: '家族传承',
    },
    {
        path: '/front/member/family/wealthManage',
        component: WealthManage,
        title: '财富管理',
    },

    {
        path: '/front/member/family/messageDetail',
        component: MessageDetail,
        title: '资讯详情',
    },

    {
        path: '/:path/vipDesc',
        component: VipDesc,
        title: '会员说明',
    }, {
        path: '/:path/tempDesc',
        component: TempDesc,
        title: '体验会员说明',
    },

    {
        path: '/front/member/register/protocol',
        component: () => {
            return <Protocol style={{position: 'fixed', overflowY: 'scroll', height: '100%', top: 0, zIndex: 5}}/>
        },
        title: 'G优会员服务条款',
    }, {
        path: '/front/member/protocol',
        component: Protocol,
        title: 'G优会员服务条款',
    }, {
        path: '/isRegisted/qrcode/:codeNo',
        component: Qrcode,
        title: '会员二维码'
    }, {
        path: '/:path/qrcode/:codeNo',
        component: Qrcode,
        title: '会员二维码'
    }, {
        path: '/:path/credit/mall',
        component: Mall,
        title: '积分商城',
    }, {
        path: '/isRegisted/home',
        component: Home,
        title: '我的',
    }, {
        path: '/member/home',
        component: Home,
        title: '我的',
    }, {
        path: '/:path/coupon',
        component: MyCoupon,
        title: '我的优惠券',
    }, {
        path: '/:path/historyCoupon',
        component: HistoryCoupon,
        title: '历史优惠券',
    }, {
        path: '/member/aboutus',
        component: Aboutus,
        title: '关于我们',
    }, {
        path: '/isRegisted/card',
        component: MyCard,
        title: '绿地G优尊享会',
    }, {
        // 兼容app中旧的页面
        path: '/member/card',
        component: MyCard,
        title: '绿地G优尊享会',
    }, {
        path: '/member/getCard/:code?',
        component: GetCard.GetCard,
        title: '领取会员卡',
    }, {
        path: '/member/getCard/login',
        component: GetCard.Login,
        title: '活动'
    }, {
        path: '/member/getCardSuccess',
        component: GetCard.RegisterSuccess,
        title: '绿地G优会员卡'
    }, {
        path: '/front/member/appointment/onLineAppointment',
        component: Appointment.OnLineAppointment,
        title: '在线预约'
    }, {
        path: '/front/member/appointment/appointmentSucceed',
        component: Appointment.AppointmentSucceed,
        title: '预约成功'
    }, {
        path: '/front/member/appointment/appointmentRecord',
        component: Appointment.AppointmentRecord,
        title: '预约记录'
    }, {
        path: '/front/member/appointment/activitySelection',
        component: Appointment.ActivitySelection,
        title: '活动精选'
    }, {
        path: '/front/member/appointment/vipCardHome',
        component: VipCard.VipCardHome,
        title: '会员等级'
    },{
        path: '/front/member/appointment/goAppointment',
        component: Appointment.GoAppointment,
        title: '去预约'
    },
    {
        path: '/front/member/pointRecharge/home',
        component: PointRecharge.Home,
        title: '积分充值'
    },
    {
        path: '/front/member/pointRecharge/rechargeRecord',
        component: PointRecharge.RechargeRecord,
        title: '充值记录'
    },
    {
        path: '/front/member/pointRecharge/buyRecord',
        component: PointRecharge.BuyCardRecord,
        title: '购买记录'
    },
    {
        path: '/front/member/pointRecharge/pointCardDetail',
        component: PointRecharge.PointCardDetail,
        title: '充值卡'
    },
    {
        path: '/front/member/pointRecharge/myPointCards',
        component: PointRecharge.MyPointCards,
        title: '我的充值卡'
    },
    {
        path: '/front/member/pointRecharge/myPointCardRecord',
        component: PointRecharge.MyPointCardRecord,
        title: '充值卡记录'
    },
    {
        path: '/front/member/family/hotelTravel/memberLevel',
        component: MemberLevel,
        title: '会员权益',
    },
    {
        path: '/front/member/family/hotelTravel/useRecord',
        component: UseRecord,
        title: '使用明细',
    },

];
