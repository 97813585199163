/**
 * @description 酒旅权益-使用明细
 * @returns /front/member/family/hotelTravel/useRecord
*/
import React, {useState, useEffect, useRef, useCallback} from 'react';
import styles from './index.module.scss';
import clickThrottle from "../../../../../library/util/clickThrottle";
import {Swiper} from "antd-mobile";
import {ListView} from "../../../../../components";
import Services from "../../../../../services";
import Library from 'library';

const {JsBridge} = Library;

//使用明细
export const UseRecord = (props) => {
    const [selIndex, setSelIndex] = useState(0);
    const swiperRef = useRef(null);
    useEffect(() => {

    }, []);

    const clickIndex = (index) => {
        if (!clickThrottle(1000)) {
            return
        }
        if (selIndex === index) {
            return;
        }
        setSelIndex(index);
        swiperRef.current && swiperRef.current.swipeTo(index);
    }

    return <div className={styles.container}>
        <div className={styles.record_header}>
            <div className={styles.heder_title_com}
                 onClick={() => clickIndex(0)}>
                <div className={selIndex === 0 ? styles.header_title_sel : styles.header_title_dis}>无门槛额度</div>
                <div className={styles.heder_title_line}
                     style={{visibility: selIndex === 0 ? 'visible' : 'hidden'}}/>
            </div>
            <div className={styles.heder_title_com}
                 onClick={() => clickIndex(1)}>
                <div className={selIndex === 1 ? styles.header_title_sel : styles.header_title_dis}>金旅积分</div>
                <div className={styles.heder_title_line}
                     style={{visibility: selIndex === 1 ? 'visible' : 'hidden'}}/>
            </div>
        </div>


        <div className={styles.swiper}>
            <Swiper
                style={{height: '100%'}}
                direction='horizontal'
                indicator={() => null}
                ref={swiperRef}
                allowTouchMove={false}
                defaultIndex={0}>
                <Swiper.Item>
                    <UseList focusStatus={selIndex === 0} type={1}/>
                </Swiper.Item>
                <Swiper.Item>
                    <UseList focusStatus={selIndex === 1} type={2}/>
                    {/*<UseList1/>*/}
                </Swiper.Item>
            </Swiper>
        </div>
    </div>
}
const UseList1 = React.memo(() => {
    console.log('UseList1从新渲染啦')
    return <div>aaaa</div>
});
const UseList = React.memo((props) => {
    const [dataSource, setDataSource] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const currentPage = useRef(0);
    const initalRef = useRef(props.initalIndex);
    const [initStatus, setInitStatus] = useState(false);
    const {focusStatus, type} = props;
    console.log('uselist从新渲染啦,,')
    useEffect(() => {
        if (!initStatus && focusStatus) {
            setInitStatus(true);
            loadData(true).then();
            return;
        }
    }, [initStatus, focusStatus])

    const mockFn = () => {
        return new Promise((resolve, reject) => {
            resolve(mock);
        })
    }

    const loadData = async (isRefresh) => {
        if (isRefresh) {
            currentPage.current = 0;
        }
        // const res = await mockFn();
        setIsLoad(true);
        const { clientId } = await JsBridge.getCurrentUserInfo();
        const res = await Services('MainPage', {code: 600205}, {
            userId: clientId,
            type: type,
            pageSize: pageSize,
            pageNum: currentPage.current
        });
        console.log('res--', res)
        if (res.status > 0) {
            const {content = []} = res.data.guHomeWineshopOrderVOPage;
            if (currentPage.current > 1) {
                setDataSource([...dataSource, ...content]);
            } else {
                setDataSource(content);
            }
            setHasMore(content.length >= 10);
            setIsLoad(false);
        }
    }

    const renderItem = (item) => {
        if (item.createTime && item.createTime.indexOf('.') > -1) {
            item.createTime = item.createTime.split('.')[0];
        }
        // type-1: 无门槛；type-2: 金旅积分
        // orderType 1: 支付； 2: 退款
        if (item.type === 1) {
            item.name = item.orderType == 2 ? '酒店预定-取消预定退回': '酒店预定';
            item.price = `${item.orderType == 2 ? '+': '-'}${item.useAsCashLimit || 0}`;
        } else {
            item.name = item.orderType == 2 ? '酒店预定-取消预定退回': '酒店预定';
            item.price = `${item.orderType == 2 ? '+': '-'}${item.hotelPoints || 0}`;
        }

        return <div className={styles.item}>
            <p className={styles.title}>{item.name}</p>
            <p className={styles.time}>{item.createTime}</p>
            <p className={styles.order}>订单号:{item.orderId}</p>
            <p className={styles.price}>{item.price}</p>
        </div>
    }

    return (
        <div className={styles.use_list}>
            <ListView
                dataSource={dataSource}
                renderItem={renderItem}
                hasMore={hasMore}
                onRefresh={() => loadData(true)}
                onEndReached={() => loadData(false)}
                style={{paddingTop: 60}}
                isLoading={isLoad}
            />
        </div>
    )
})
const mock = {
    code: '000000',
    msg: 'success',
    status: 1,
    data: {
        list: [
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回酒店预订-取消预订退回酒店预订-取消预订退回酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '20221212090909',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            }, {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
            {
                nftNo: '123455',
                title: '酒店预订-取消预订退回',
                time: '2022-12-12 09:09:09',
                order: '2022-12-12 09:09:09',
                price: 20000,
                id: 0,
            },
        ]
    }

}
