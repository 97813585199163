import React, { useEffect, useState } from 'react'
import styles from './draw.module.scss';
import Library from 'library';
import Services from 'services';
import draw_now from './images/draw-now.png'
import DrawResult from './DrawResult';
import { Toast } from 'antd-mobile';
import { isNumber } from 'library/util';

const { Hooks, JsBridge } = Library;
let runner;
const stars = [];
for (var i = 0; i < 38; i++) {
  stars.push(i);
}
let hasClick = false;
let drawResultData = null

export const Draw = ({
  drawLotteryListData,
  drawTimesData,
  drawActionCB,
  userInfo
}) => {
  const [showLight, setShowLight] = useState(false);
  const [drawResultMaskVisible, setDrawResultMaskVisible] = useState(false);
  const [status, setStatus] = useState({
    isBegin: false,
    thisStep: 0,
    endSteps: 0,
    interval: 200,
  });


  Hooks.useInterval(() => {
    setShowLight(!showLight);
  }, 1500);
  const savedCallback = React.useRef();
  useEffect(() => {
    savedCallback.current = startAnimation;
  });
  useEffect(() => {
    if (status.isBegin) {
      runner = setTimeout(savedCallback.current, status.interval);
    }
  }, [status])

  const startAnimation = async () => {
    let { thisStep, endSteps, interval } = status;
    if (endSteps <= 0) {
      clearTimeout(runner);
      drawEnd();
      return;
    } else {
      endSteps = endSteps - 1;
      thisStep = thisStep + 1;
    }
    if (endSteps < 5) {
      interval = Math.min(1000, interval + 200);
    } else {
      interval = Math.max(50, interval - 200);
    }
    setStatus({ ...status, endSteps, thisStep, interval });
  }

  const drawStart = async () => {
    drawResultData = null
    if (!!hasClick) return
    if (drawTimesData?.remainTimes === 0) {
      Toast.show(`抽奖次数已用完`)
      return
    }
    const { isBegin, thisStep, } = status;
    hasClick = true;
    const res = await Services('MainPage', { code: 300028 }, {
      activityCode: "NY2023",
      clientId: userInfo?.clientId || ''
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      const diff = Math.abs(8 - thisStep % 8 + mappingIndexTostep(res?.data.index));
      const endSteps = 24 + diff;
      drawResultData = { ...res?.data }
      setStatus({ ...status, isBegin: true, endSteps: endSteps, });
    }
    hasClick = false;
  }

  const drawEnd = async () => {
    !!drawActionCB && drawActionCB()
    setStatus({ ...status, isBegin: false, interval: 200, });
    let tempDrawResultData = {
      headTitle: '恭喜中奖啦!',
      iconUrl: drawResultData.imgUrl,
      title: `恭喜您抽中${drawResultData.value}`,
      leftBtnTitle: `我知道了`,
      rightBtnTitle: `继续抽奖`,
      leftBtnClick: () => { restDrawResult() },
      rightBtnClick: () => { restDrawResult() },
      closeBtnClick: () => { restDrawResult() },
    }
    //  0积分， 3实物 ，8数字藏品，9未中奖
    if (drawResultData.type == '0') {
      tempDrawResultData.subTitle = `请在【积分-我的积分】查看`
    }
    if (drawResultData.type == '3') {
      tempDrawResultData.subTitle = `请填写收货地址\n我们会第一时间发放奖品`
      tempDrawResultData.leftBtnTitle = `填写地址`
      tempDrawResultData.leftBtnClick = () => {
        JsBridge.push('CustomWeb', { uri: window.location.origin + '/front/activities/carnival/fillAddress' + `?id=${drawResultData?.id}` });
        restDrawResult()
      }
    }
    if (drawResultData.type == '8') {
      tempDrawResultData.subTitle = `请在【我的-我的藏品】查看`
    }
    if (drawResultData.type == '9') {
      tempDrawResultData.headTitle = '谢谢参与!'
      tempDrawResultData.title = `很遗憾 未中奖`
      tempDrawResultData.subTitle = `继续抽奖，获奖概率更高哦！`
      tempDrawResultData.leftBtnTitle = `取消`
    }
    drawResultData = { ...drawResultData, ...tempDrawResultData }
    setDrawResultMaskVisible(true)
  }
  const restDrawResult = () => {
    setDrawResultMaskVisible(false)
    drawResultData = null
  }

  return (
    <>
      <div className={!!showLight ? `${styles.drawWraps}` : `${styles.drawWraps} ${styles.light}`}>
        {stars.map((item, key) => {
          return <div key={key} className={styles.circle} />
        })}
        <div className={styles.drawHeader}>
          <div className={styles.drawTitle}>
            {`您还剩`}
            <span>{` ${isNumber(drawTimesData?.remainTimes) ? drawTimesData?.remainTimes : '-'} `}</span>
            {`次抽奖机会`}
          </div>
          <div className={styles.drawSubTitle}>
            {`抽15次必中新年限定数字藏品,您还剩`}
            <span>{` ${isNumber(drawTimesData?.dValueTimes) ? drawTimesData?.dValueTimes : '-'} `}</span>
            {`次`}
          </div>
        </div>
        <div className={styles.drawBorder}>
          {!!status.isBegin &&
            <div className={styles.drawMask} />
          }
          {!!drawLotteryListData && drawLotteryListData.map((item, index) => {
            if (item.type === 'start') {
              return (
                <div className={styles.drawStart} key={index} onClick={drawStart}>
                  <img className={styles.drawStartImg} src={draw_now} />
                </div>
              )
            }
            return (
              <div className={status.thisStep % 8 === item.step ? `${styles.drawButton} ${styles.active}` : `${styles.drawButton}`} key={index}>
                <div className={styles.drawButtonC}>
                  <img className={styles.drawButtonImg} src={item.imgUrl} />
                  <div className={styles.drawButtonTitle}>
                    {`${item.value}`}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {!!drawResultMaskVisible &&
        <DrawResult
          {...drawResultData}
        />
      }
    </>
  )
}
const mappingIndexTostep = (index) => {
  let step = index
  index == 1 && (step = 0)
  index == 2 && (step = 1)
  index == 3 && (step = 2)
  index == 4 && (step = 7)
  index == 5 && (step = 3)
  index == 6 && (step = 6)
  index == 7 && (step = 5)
  index == 8 && (step = 4)
  return step
}