/**
 * @description 2024 G优超级分享官-邀新专区相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const { JsBridge } = Library;

const activityCode = 'inviteZone2024';

const mockData = {
    status: 1,
    data: {
        startTime: '',
        endTime: '',
        inviteCode: '',
        givePoint: 180,
        productList: [
            {
                productId: '商品id',
                skuId: 'skuId',
                productName: '男士深层清洁洗面奶',
                imgUrl: require('images/invite-zone/bg_top.png'),
                collectTimeStart: '',
                collectTimeEnd: '2024/09/08 12:34',
                InvitationsNumTotal: 10,
                inviteesNum: 5,
                collectionProgress: 1, //0待邀请，1待领取，2已领取
            }
        ],
        exchList: [
            {
                productId: '商品id',
                skuId: 'skuId',
                exchSkuId: '兑换商品skuId',
                productName: '男士深层清洁洗面奶',
                imgUrl: require('images/invite-zone/bg_top.png'),
                collectTimeStart: '',
                collectTimeEnd: '2024/09/08 12:34',
                InvitationsNumTotal: 100,
                inviteesNum: 10,
                collectionProgress: 1, //0待邀请，1待领取，2已领取
            }
        ]
    }
}
const mock = false;

class Service {

    /**
    * @description 初始化接口
    * @interface 600871
    * @param {activityCode: 活动编码} params
    * @param { 是否显示加载loading } showLoading
    * @returns {
    * 
   */
    static getInitData = async (showLoading = true) => {
        const code = '600871'
        try {
            const params = {
                activityCode: activityCode,
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common';
            if(mock) {
                return { data: mockData.data };
            }
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.data||res.data };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 邀新列表/商品邀新列表接口
     * @interface 101340/600873
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params={})=>{
        const code = params.type == 1 ? '101340':'600873';
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取商品接口
     * @interface 600872
     * @param {activity: 活动编码 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static receivePrize = async (params={}, showLoading=true)=>{
        const code = '600872'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    

}

Service.activityCode = activityCode;
export default Service;