import React, {useState} from "react";
import styles from './index.module.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import qiXi_success_download from 'images/magpieFestival/qiXi_success_download.png';
import Library from 'library';
const { Util } = Library;


const appSchema = 'gktapp://com.gkewang';

const QiXiInviteSuccess = () => {
  const { type='' } = Util.url.paramsToObj() || {};
  const [maskShow, setMaskShow] = useState(false);

  const targetUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/magpieFestival`;

  const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = launchUrl;
      setTimeout(() => {
          window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
      }, 3000);
    }
  }

  return <div className={styles.qixi_share}>
    <img src={qiXi_success_download} alt="" />
    <div className={styles.load_btn} onClick={() => openApp()}>下载/打开绿地G优APP</div>
    {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default QiXiInviteSuccess;
