import { Mask } from 'antd-mobile';
import React from 'react';
import styles from './drawResult.module.scss';
import drawResBg from '../images/drawResBg.png'

export default ({
    iconUrl,
    headTitle,
    title,
    subTitle,
    leftBtnTitle,
    rightBtnTitle,
    leftBtnClick,
    rightBtnClick,
    closeBtnClick,
}) => (
    <Mask className={styles.mask} visible={true} onMaskClick={closeBtnClick}>
        <div className={styles.container}>
            <img className={styles.imgBg} src={drawResBg} />
            <div className={styles.headTitle} >
                {headTitle || `恭喜中奖啦!`}
            </div>
            <img className={styles.icon} src={iconUrl} />
            <div className={styles.title} >
                {title || ``}
            </div>
            <div className={styles.subTitle} >
                {subTitle || ``}
            </div>
            <div className={styles.leftBtn} onClick={leftBtnClick}>
                {leftBtnTitle || `我知道了`}
            </div>
            <div className={styles.rightBtn} onClick={rightBtnClick}>
                {rightBtnTitle || `继续抽奖`}
            </div>
        </div>
    </Mask>
)
