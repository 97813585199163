import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';

import lea_img_01 from 'images/lea_img_01.jpg'
import lea_img_02 from 'images/lea_img_02.jpg'
import lea_img_03 from 'images/lea_img_03.jpg'
import lea_img_04 from 'images/lea_img_04.jpg'
import lea_img_05 from 'images/lea_img_05.jpg'

import Library from 'library';

const {JsBridge} = Library;


const LeagueOfLegends = () => {
    const [userInfo, setUserInfo] = useState({}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名

    useEffect(async () => {
        loadData();
    }, []);

    const loadData = async () => {
        let userInfoRes = await JsBridge.getDetailCurrentUserInfo()
        if (userInfoRes.isLogin) {
            setUserInfo(userInfoRes);
        }
    }

    const arr = [lea_img_01, lea_img_02, lea_img_03, lea_img_04, lea_img_05];
    const imageClick = (index) => { //进入无聊猿
        if (index === 1) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/variationApe/home`;
            JsBridge.push('CustomWeb', {uri: uri});
        } else if (index === 3) {//进入元宇宙
            if (userInfo.isLogin) {
                JsBridge.startUnity3d();
            } else { //没有登录
                JsBridge.goLogin();
                JsBridge.addListener().then(() => {
                    loadData();
                });
            }
        }
    }

    const goBack = () => {
        JsBridge.back();
    }

    const images = () => {
        return arr.map((item, index) => {
            return <img src={item} alt="" key={index.toString()}
                        onClick={() => imageClick(index)}
                        style={{marginTop: index >= 1 ? '-1px' : '0'}}/>
        })
    }

    return <div className={styles.leagueOfLegends}>
        <div className={styles.back_share_father}>
            <div className={styles.back_btn} onClick={() => goBack()}></div>
            <div></div>
        </div>
        {images()}
    </div>
}

export default LeagueOfLegends;
