
/**
 * @description 父亲节活动
 * @returns route: /front/activities/fathersDay
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Swiper, Toast } from 'antd-mobile';
import { Header } from 'components';
import ActivityRuleModal from 'components/activityRuleModal';
import Library from 'library';
import Service from '../service';
import ActivityShareModal from 'components/activityShareModal';
import RecordPopup from '../components/recordPopup';
import ReceiveModal from '../components/receiveModal';
import LikesModal from '../components/likesModal';
import ExihbitBox from '../components/ExhibitBox';
import ProgressBar from '../components/ProgressBar';
import GoodsTabs from '../components/GoodsTab'
import styles from './styles.module.scss';
import toggleImg from '../images/toggle_img.png';
import bigHand from '../images/big_hand.gif';
import back from '../../../../images/icon/back-black@2x.png';
import giftImg from '../images/gift-goods.png';
import disImg from '../images/dis-goods.png';

const { JsBridge } = Library;

export default (props) => {
    // 分享链接携带祝福语id
    const { blessingId } = Library.Util.url.paramsToObj();

    const [recordVisible, setRecordVisible] = useState(false)
    const [type, setType] = useState()
    const [activityInfo, setActivityInfo] = useState({})
    const [blessingInfo, setBlessingInfo] = useState('');
    const [activityRuleContent, setActivityRuleContent] = useState('')

    const records = useMemo(()=>{
        let list = [{loginName: '154****6789',prizeName: '倍轻松u型枕（肖战代言款）'},{loginName: '136****8754',prizeName: '倍轻松u型枕（肖战代言款）'}];
        list = [...list,...list,...list]
        if(activityInfo.records?.length) {
            list = activityInfo.records
            if(list.length < 5){
                list = [...list,...list]
            }
        }
        return list
    },[activityInfo])

    useEffect(() => {
        loadData()
        Service.getActivityRules().then(res => setActivityRuleContent(res?.otherSign));
        if(blessingId) {
            // 如果分享链接 携带祝福语id，弹窗点赞
            LikesModal.open({blessingId, call: () => {
                loadData()
            }});
        }
    }, [])

    const loadData = async () => {
        const result = await Service.getInitData()
        console.log('result=====', result)
        if (result?.data) {
            setActivityInfo(result.data)
        }
    }

    // 活动规则
    const onRuleClick = () => {
        ActivityRuleModal.open({
            btnStyle: { background: 'linear-gradient(102deg, #FF8354 0%, #FB3D28 108%)' },
            content: activityRuleContent,
        });
    }

    // 分享邀新
    const onNewShareClick = () => {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if (isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        const param = {
            ic: activityInfo.inviteCode,
            ac: Service.activityCode
        }
        const shareUrl = `${window.location.origin}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
        ActivityShareModal.open({
            shareUrl,
            children: <img className={styles.shareInvite} src={require('../images/bg-share-invite.png')} alt="bg" />,
        })

    }

    // 邀请点赞
    const shareAction = async () => {
        let blessingWords = activityInfo.blessingWords;
        let blessingId = activityInfo.blessingId;
        if (!blessingWords) {
            blessingWords = blessingInfo.blessingWords;
            if (!blessingWords) {
                return;
            }
            const { data } = await Service.bindBlessing({ blessingWords });
            blessingId = data.blessingId;
            loadData();
        };
        const param = {
            ic: activityInfo.inviteCode,
            ac: 'fathersDay',
            blessingId,
        }
        const shareUrl = `${window.location.origin}/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString("base64")}`;
        ActivityShareModal.open({
            shareUrl,
            className: styles.shareZanModal,
            qrcodeConfig: { size: 72 },
            children: (
                <div className={styles.shareZan}>
                    <div>@爸爸，我有话对您说:</div>
                    <p>{blessingWords}</p>
                </div>
            ),
        });
    }
    // 点赞/邀新详情
    const onPopupClick = (type) => {
        setType(type)
        setRecordVisible(true)
    }

    // 领奖记录
    const onReceiveRecord = () => {
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&idf=commonSub` });
        } else {
            props.history.push(`/front/activities/awardRecord?activityCode=${Service.activityCode}&apiCode=101329&&idf=commonSub`)
        }
    }

    // 领取奖励
    const onReceiveGive = async (st) => {
        const isEnd = new Date(activityInfo?.currentTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if (isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        const result = await Service.receiveGift({ type: st.type });
        JsBridge.print(result);
        if (result) {
            ReceiveModal.open({ callBack: null, prizeInfo: result.data });
            setTimeout(() => {
                loadData()
            }, 500)
        } else {
          loadData();
        }
    }

    // 获取祝福语
    const getBlessingText = async () => {
      const result = await Service.getRandomBlessingWords();
      if(result) {
        setBlessingInfo(result.data);
      }
    }

    return (
        <div className={styles.container}>
            <img className={styles.back_img} src={back} alt="" onClick={() => JsBridge.back()}/>
            <div className={styles.rule_style} onClick={onRuleClick}>活动规则</div>
            <div className={styles.wraper}>
                <div className={styles.wraper_content}>
                    <div className={styles.activity_title}>父亲节祝福语点赞送好礼</div>
                    <div className={styles.invite_blessing}>
                      {!activityInfo.blessingWords && !blessingInfo.blessingWords && <img className={styles.hand_img} onClick={getBlessingText} src={bigHand} alt="" />}
                      <div className={styles.letter_title}>每人仅有一次点赞机会</div>
                      <div className={styles.letter_start}>@爸爸，我有话对您说:</div>
                      <div 
                        className={`${styles.letter_content} ${blessingInfo.blessingWords || activityInfo.blessingWords ? '': styles.center}`} 
                        onClick={blessingInfo.blessingWords || activityInfo.blessingWords ? null: getBlessingText}
                      >
                        {activityInfo.blessingWords ? activityInfo.blessingWords: blessingInfo.blessingWords || '点击生成我的父亲节祝福语'}
                      </div>
                      {!activityInfo.blessingWords && blessingInfo.blessingWords ? <div className={styles.toggle_blessing} onClick={getBlessingText}>
                        <span>换一个</span>
                        <img src={toggleImg} alt="" />
                      </div>: <div className={styles.toggle_blessing}></div>}
                      <div 
                        className={`${styles.invite_btn} ${activityInfo.blessingWords || blessingInfo.blessingWords ? styles.invite_available: ''}`} 
                        onClick={shareAction}
                      >
                        立即分享
                      </div>
                    </div>
                    <div className={styles.detail_box}>
                      <div className={styles.zanDetailBtn} onClick={() => onPopupClick('give')}>点赞详情 &gt;</div>
                      <div className={styles.zanDetailBtn} onClick={onReceiveRecord}>领取详情 &gt;</div>
                    </div>
                    <div className={styles.activity2}>
                        <img src={activityInfo.lowLevelProductStock > 0 ? giftImg: disImg} alt="" />
                    </div>
                    <ProgressBar activityInfo={{...activityInfo}} onReceive={onReceiveGive} />
                    <div className={styles.row}>
                        <span>领取名单&gt;&gt;</span>
                    </div>
                    <Swiper
                        className={styles.swiper}
                        style={{
                            '--height': '2.7rem',
                        }}
                        indicator={() => null}
                        loop={true}
                        autoplay={true}
                        direction='vertical'
                        slideSize={33.3333}
                        allowTouchMove={false}
                    >
                        {
                            records.map((item, index) => (
                                    <Swiper.Item key={index}>
                                       <div className={styles.swiper_item}>{`${item.loginName}: ${item.prizeName}`}</div>
                                    </Swiper.Item>
                                ))
                        }
                    </Swiper>
                </div>
                <div className={styles.connect_module}><span></span><span></span></div>
                <div className={styles.invite_new_box}>
                  <div className={styles.invite_new_title}>邀新注册送积分</div>
                  <div className={styles.invite_points_box}>
                    <div className={styles.box_left}>
                      <p>邀请新用户注册成功</p>
                      <p>送{activityInfo.sendInvitePoints}G优积分</p>
                      <p onClick={() => onPopupClick('invite')}>邀新详情&gt;&gt;</p>
                    </div>
                    <div className={styles.box_right} onClick={onNewShareClick}></div>
                  </div>
                </div>
                <GoodsTabs />
                <div className={styles.footer} />
            </div>
            <RecordPopup visible={recordVisible}
                type={type}
                close={() => setRecordVisible(false)}
            />
        </div>
    )
}
