import React, {useEffect, useState, useRef} from "react"
import {Mask, Toast} from "antd-mobile"
import styles from "./eggRuleDialog.module.scss"
// import egg_tan_img from './../../../../images/egg_tan_img.png'
import img_sure from './../../../../images/egg_bt_lijibaoming.png'
import egg_biaoge from './../../../../images/egg_biaoge.png'
import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";

const FenGe = 'fenge'; //分割的字符串
const EggRuleDialog = ({maskVisible, setMaskVisible, type}) => {
    const [title, setRuleTitle] = useState();
    const [content0, setContent0] = useState();
    const [content1, setContent1] = useState();
    const [hasTab, setHasTab] = useState(false); //是否有表格

    const [content, setRuleContent] = useState();
    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    useEffect(() => {
        maskVisible && getProtocol();
    }, [maskVisible]);
    const getProtocol = async () => { //获取协议
        const res = await Services('MainPage', {code: '101193'}, {
            activityCode: 'DIGITCOLLE',
            codeType: 'activityRule',
            code: type === 0 ? 'apeWorldRule' : 'supportRule',
        })
        if (res.status > 0) {
            const {data} = res
            setRuleTitle(data.activityRule.codeAlias)
            let otherSign = data.activityRule.otherSign;
            if (!isEmptyString(otherSign)) {
                if (otherSign.includes(FenGe)) {
                    let stringArray = otherSign.split(FenGe);
                    if (stringArray.length > 0) {
                        setContent0(stringArray[0])
                    }
                    if (stringArray.length > 1) {
                        setContent1(stringArray[1])
                    }
                    setHasTab(true)
                } else {
                    setRuleContent(otherSign)
                }
            }
        }
    }

    return (
        <Mask visible={maskVisible} opacity={0.8}>
            <div className={styles.share_container}>
                <div className={styles.share_container_content}>
                    <div className={styles.title}>{title}</div>
                    {
                        hasTab ? <div className={styles.styles_content_father}>
                            <div className={styles.content}>{content0}</div>
                            <img src={egg_biaoge} alt="" className={styles.img}/>
                            <div className={styles.content}>{content1}</div>
                        </div> : <div className={styles.content1}>{content}</div>
                    }

                    <div className={styles.sure_btn_father} onClick={() => btnClick()}>
                        <img src={img_sure}/>
                        <div className={styles.sure_btn_txt}>
                            <span>我知道了</span>
                        </div>
                    </div>
                </div>
            </div>
        </Mask>
    )
}

export default EggRuleDialog;
