import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Mask } from 'antd-mobile'
import styles from './confirmModal.module.scss'

const ConfirmModal = ({title, content, onCancel, onConfirm, onClose, cancelText="取消", confirmText="确认"}) => {
  const [visible, setVisible] = useState(true)

  const cancel = () => {
    setVisible(false)
    onClose && onClose()
    onCancel && onCancel()
  }

  const confirm = () => {
    setVisible(false)
    onClose && onClose()
    onConfirm && onConfirm()
  }

  return <Mask visible={visible} className={styles.mask_container}>
    <div className={styles.modal_container}>
      {title ? <div className={styles.title}>{title}</div>: null}
      {content ? <div className={styles.content}>{content}</div>: null}
      <div className={styles.btn_box}>
        <div className={styles.cancel_btn} onClick={cancel}>{cancelText}</div>
        <div className={styles.confirm_btn} onClick={confirm}>{confirmText}</div>
      </div>
    </div>
  </Mask>
}

ConfirmModal.open = ({onCancel, onConfirm, content, title, cancelText, confirmText}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ConfirmModal 
    onCancel={onCancel} 
    onConfirm={onConfirm} 
    onClose={close} 
    content={content} 
    title={title}
    confirmText={confirmText}
    cancelText={cancelText}
  />, div);
}

export default ConfirmModal