/**
 * @description 商品推荐模块
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Tabs } from 'antd-mobile';
import Library from 'library';
import Service from '../../service';
import Styles from './index.module.scss';
const { JsBridge } = Library;

const defaultData = [
    {
      cateId: '0',
      cateName: '爱她',
      goodsSimplifyList: [
        {
          marketPrice: '213',
          maxDeductPoint: '122',
          goodsImg: 'https://img2.baidu.com/it/u=1133371837,3614664115&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
          goodsInfoName: 'Moschino梦仙奴泰迪两瓶装',
          goodsInfoId: '',
          discount: '0.7'
        }
      ]
    },
    {
      cateId: '1',
      cateName: '爱他',
      goodsSimplifyList: [
        {
          marketPrice: '1998',
          maxDeductPoint: '21389',
          goodsImg: 'https://img2.baidu.com/it/u=1133371837,3614664115&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
          goodsInfoName: '梦仙奴泰迪',
          goodsInfoId: '',
          discount: '0.7'
        }
      ]
    },
    {
      cateId: '2',
      cateName: '我们',
      goodsSimplifyList: [
        {
          marketPrice: '9',
          maxDeductPoint: '120',
          goodsImg: 'https://img2.baidu.com/it/u=1133371837,3614664115&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
          goodsInfoName: 'Moschino梦仙奴泰迪两瓶装更过瘾哦',
          goodsInfoId: '',
          discount: '0.7'
        }
      ]
    }
  ]

const ShopRecommend = (props) => {

    const [activeKey,setActiveKey] = useState(0);
    const [listArray, setListArray] = useState([]);

    useEffect(() => {
        if(process.env.NODE_ENV == 'development') {
          setTimeout(()=>{
            const datas =  defaultData.map(group=> {
                const content = Array(6).fill().reduce((pre,_)=>{
                    return [...pre, ...group.goodsSimplifyList]
                },[])
                group.goodsSimplifyList = content
                return group
            })
            
            setListArray(datas);
            setActiveKey(0);
          },1500)
        }
       
        Service.getRecommendShopList().then(res => {
          console.log('getRecommendShopList', res)
          if(res) {
            setListArray(res?.data);
            setActiveKey(0);
          }
          
        })
      }, []);


    const matchImg = (name='')=>{
        let imgPath = require('images/magpieFestival/love-icon.png')
        if(name.includes('她')) {
            imgPath = require('images/magpieFestival/woman-icon.png')
        }else if(name.includes('他')) {
            imgPath = require('images/magpieFestival/man-icon.png')
        }
        return imgPath;
    }

    return (
        <div className={Styles.shop_recomend} >
            <Tabs
                className={Styles.tabs}
                activeKey={activeKey+''}
                onChange={(key) => setActiveKey(key)}
            >
            {
                listArray.map((tab,k)=> {
                    return (
                        <Tabs.Tab
                            key={k}
                            title={
                                <div className={`${Styles.tab} ${activeKey == k ? Styles.tabSelect: Styles.tabUnSelect}`}>
                                    <img src={matchImg(tab.cateName)} alt="" />
                                    <span>{tab.cateName}</span>
                                </div>
                            }
                        >
                            {
                                tab.goodsSimplifyList &&
                                tab.goodsSimplifyList.map((item,idx)=> {
                                    return (<ShopItem key={idx} item={item}/>)
                                })
                            }
                        </Tabs.Tab>
                        
                    )
                })
            }
            </Tabs>
        </div>
    )
}

const ShopItem = (props) => {
    const {item={}} = props

    //商品点击事件
    const onShopItemClick = (data) => {
        console.log(data)
        const skuId = data.goodsInfoId
        JsBridge.push('Web', { uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${skuId}` })
    }

    const originalPrice = Number(item.marketPrice);
    const pointPrice = Number(item.maxDeductPoint||0) / 100;
    //最终价
    const actualPrice = Math.max(Number(((originalPrice * (item.discount || 1)) - pointPrice).toFixed(2)), 0); 

    return (
        <div className={Styles.shop_item} onClick={() => onShopItemClick(item)}>
            <div className={Styles.left}>
                <div className={Styles.conent}>
                    <div className={Styles.tag}> {'购买返3倍积分'}</div>
                    <img src={item.goodsImg} alt="" />
                </div>
            </div>
            <div className={Styles.right}>
                <p>{item.goodsInfoName}</p>
                <div className={Styles.priceLine}>
                    <div>
                        <div>¥<span>{originalPrice}</span></div>
                        <div>原价</div>
                    </div>
                    <div>
                        <div>¥<span>{pointPrice}</span></div>
                        <div>积分可抵</div>
                    </div>
                </div>
                <div className={Styles.finalPrice}>
                    <div>
                        <span>到手价 ¥</span>
                        <span>{actualPrice}</span>
                    </div>
                    <div>去抢购</div>
                </div>
            </div>
        </div>
    )
}

export default ShopRecommend;