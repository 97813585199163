import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import JsBridge from 'library/jsbridge';
import * as Platform from 'library/platform';
import { umengTrack_healthcare } from 'library/jsbridge/config'
import { Slogon } from '../../../../slogon';
import styles from './index.module.scss';

export const GridView = (props) => {
    const history = useHistory();
    const [arrLeft, setArrLeft] = useState([]);
    const [arrRight, setArrRight] = useState([]);
    useEffect(() => {
        let arr1 = [];
        let arr2 = [];
        for (let i = 0; i < props.arrSource.length; i++) {
            let item = props.arrSource[i];
            if (i % 2 === 0) {
                arr1.push(item);
            } else {
                arr2.push(item);
            }
        }
        setArrLeft(arr1);
        setArrRight(arr2);
    }, [props.arrSource]);
    const cell = (item) => {
        return <div className={styles.cell} onClick={() => {
            if (Platform.check.isApp()) {
                JsBridge.UmengTrack(umengTrack_healthcare.list, item.name);
            }
            if(JsBridge.hasWebViewBridge()) {
                if (item.detailUrls) {
                    JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/guApp/kangyang/basedetail?arr=${JSON.stringify(item.detailUrls)}`});
                } else {
                    let url = encodeURIComponent(item.infoPicInfo);
                    console.log(url);
                    JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/guApp/kangyang/basedetail?url=${url}`});
                }
            }else {
                if (item.detailUrls) {
                    history.push(`/front/guApp/kangyang/basedetail?arr=${JSON.stringify(item.detailUrls)}`);
                } else {
                    let url = encodeURIComponent(item.infoPicInfo);
                    console.log(url);
                    history.push(`/front/guApp/kangyang/basedetail?url=${url}`);
                }
            }
            
        }}>
            <img className={styles.img} src={item.picUrl} />
            <div className={styles.cellContent}>
                <h4 className={styles.titleCell}>{item.name}</h4>
                <h4 className={styles.desc}>{item.hotelInfo}</h4>
            </div>
        </div>
    }
    return <div className={styles.gridViewContainer}>
        <div className={styles.gridView}>
            <div className={styles.gridLeftRight}>
                {arrLeft.map((item, index) => {
                    return <Fragment key={`left${index}`}>{cell(item)}</Fragment>;
                })}
            </div>

            <div className={styles.gridLeftRight}>
                {arrRight.map((item, index) => {
                    return <Fragment key={`right${index}`}>{cell(item)}</Fragment>;
                })}
            </div>
        </div>
        <Slogon />
    </div>
}