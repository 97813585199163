const productList = {
  code: '000000',
  msg: 'success',
  data: [
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/47bb3eb1d368ad11510501ea5dfe479a.jpg', 
      productName: '奥兰小红帽干红葡萄酒750ml', 
      marketPrice: 58.00,
      goodsId: '8a8083ff7e760145017f245ab38d0195',
      goodsInfoId: '8a8083857f2a38f1017f3006d3800088'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/0717655ab504e132ce516c72dd0d648d.jpg', 
      productName: '汉斯小木屋菠萝啤330ml*24罐', 
      marketPrice: 68.0,
      goodsId: '8a8082877e760002017f24e448f901b2',
      goodsInfoId: '8a8082877e760002017f24e4490501b6'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/992b428b1ab8df952b60893ad9bf625a.jpg', 
      productName: '拉萨啤酒高原泉水10度355ml/箱', 
      marketPrice: 58.00,
      goodsId: '8a8082877e760002017f24d8833401aa',
      goodsInfoId: '8a80829d7f2a379b017f2ff61a420081'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/ced97cf09a92e884b78d80c9e446165d.jpg', 
      productName: '猪肉条', 
      marketPrice: 90.00,
      goodsId: '8a8083fb7e522ae2017e57e751f20007',
      goodsInfoId: '8a8083fa8364291101842ca2f8c6005b'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/7cfd1d815456257b4e13de9044a505b6.jpg', 
      productName: '5369藏式卤制牦牛肉70g', 
      marketPrice: 65.0,
      goodsId: '8a8084997b874dbc017bc9649f190084',
      goodsInfoId: '8a8084997b874dbc017bc9649f310086'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/8f51da4b5be118ac6e19e382cf590f4c.jpg', 
      productName: '新疆吐鲁番 葡萄干', 
      marketPrice: 29.0,
      goodsId: '8a8083ff7e760145017f245ab38d0195',
      goodsInfoId: '8a80816a835db7900184177fc0e10036'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/403ad1de6feea4ed9b8d12de69b59c75.jpg', 
      productName: '沾化冬枣 一代冬枣 家庭装', 
      marketPrice: 49.0,
      goodsId: '8a8083fa83642911018417d9e4c70029',
      goodsInfoId: '8a8083fa83642911018417d9e4e2002c'
    },
    {
      url: 'https://mall-images-prod.obs.cn-east-2.myhuaweicloud.com/a61eb173d518f87b5de4086699a6713c.jpg', 
      productName: '攀枝花纽荷尔脐橙', 
      marketPrice: 95.00,
      goodsId: '8a80816a835db790018417455fa10021',
      goodsInfoId: '8a80816a835db790018417455fb70024'
    },
  ]
}

const testProduct = {
  data: [
    {
      url: '', 
      productName: '记事本', 
      marketPrice: 8.00,
      goodsId: '8aaa85157b2a9d97017b2f658cf60024',
      goodsInfoId: '8aaa85157b2a9d97017b2f658cf70025'
    }
  ]
}

const uatProduct = {
  data: [
    {
      url: 'https://mall-images-uat.obs.cn-north-4.myhuaweicloud.com/d69b251a5cba3f8e1187191b40eade71.jpg', 
      productName: '代码自有化0524', 
      marketPrice: 0.01,
      goodsId: '8aaa85fe80f4fc6d0180f55ae5400000',
      goodsInfoId: '8aaa85fe80f4fc6d0180f55ae56f0002'
    }
  ]
}

const getListFn = () => {
  return new Promise((resolve, reject) => {
    resolve(productList);
  })
}

export default getListFn;