import React, {useEffect, useState} from 'react';
import styles from './goAppointment.module.scss';
import {YuYueDialog} from "./views/yuYueDialog";
import Header from "../../../components/header";
import Library from "../../../library";
import Services from "../../../services";
import {useHistory} from 'react-router-dom';
import {TelDialog} from "../../../components/telDialog";
import {isEmptyString} from "../../../library/util";

export const GoAppointment = () => { //去预约
    const history = useHistory();
    //isHome ="1"是否是首页直接跳转到过的,需要特殊处理
    const {
        activityId = 0,
        activityName = '活动精选',
        userAccount = '',
        isNowActy = '0',
        isHome
    } = Library.Util.url.paramsToObj();
    const [maskVisible, setMaskVisible] = useState(false)
    const [telVisible, setTelVisible] = useState(false)
    const [data, setData] = useState({});
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        const res = await Services('MainPage', {code: 500013}, {id: activityId, customerAccount: userAccount});
        if (res.status > 0) {
            res.data.userAccount = userAccount;
            setData(res.data || {})
        }
    }

    const submit = (type) => { //0=在线预约 1=咨询 2=已预约 3=预约已满
        if (type === 0) {
            data.content = '';
            let param = data;
            if (isHome && !isEmptyString(isHome)) {
                param.isHome = isHome;
            }
            history.push(`/front/member/appointment/onLineAppointment?`, {param: param});
        } else if (type === 1) {//打电话
            setTelVisible(!telVisible);
        } else if (type === 2) {//已经预约 弹窗 预约提醒
            setMaskVisible(!maskVisible);
        }
    }

    const bootomView = () => {
        let registrationState = data.registrationState;//报名状态
        let remainEnrollment = data.remainEnrollment; //剩余报名人数
        let btnTitle = '在线预约';
        let opacity = 1;
        //0=在线预约 1=咨询 2=已预约 3=预约已满
        let type = 0;
        if (registrationState === 'UNREGISTERED') { //未报名
            if (data.homeOfficeMember) { //会员
                btnTitle = '在线预约';
                type = 0;
                opacity = 1;
            } else {
                btnTitle = '咨询详情 了解更多';
                type = 1;
                opacity = 0.6;
            }
            if (remainEnrollment === 0) { //预约已满
                btnTitle = '预约已满';
                type = 3;
                opacity = 0.6;
            }
        } else if (registrationState === 'REGISTERED') {//已报名/预约成功
            btnTitle = '已预约';
            type = 2;
            opacity = 0.6;
        }

        return (
            <div className={styles.go_aptmt_bottom_container}>
                {remainEnrollment === 0 ? <p className={styles.go_aptmt_bottom_des}/> :
                    <p className={styles.go_aptmt_bottom_des}>剩余预约名额：{data.remainEnrollment || 0}</p>}
                <div className={styles.go_aptmt_bottom_btn} onClick={() => submit(type)}
                     style={{opacity: opacity}}>
                    {btnTitle}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.go_aptmt_container}
             style={{paddingBottom: (isNowActy === '0' && data.contentType === 1) ? '0px' : '21.5 *$rem'}}>
            <Header midText={decodeURIComponent(activityName)}/>
            {
                data.contentType === 1 ?
                    <iframe scrolling="yes" frameBorder="0"
                            className={isNowActy === '1' ? styles.pddBottom : null}
                            style={{width: '100%', height: '100%', overflow: 'visible', paddingBottom: '10px'}}
                            src={data.linkUrl}
                        // src={'https://gu-test.gkewang.com/front/activities/goddessHome'}
                    /> : <div
                        className={isNowActy === '1' ? styles.pddBottom : null}
                        style={{paddingTop: '10px'}}
                        dangerouslySetInnerHTML={{__html: data.content}}>
                    </div>
            }

            {isNowActy === '1' ? bootomView() : null}
            <YuYueDialog maskVisible={maskVisible}
                         setMaskVisible={setMaskVisible}
                         appointActivityOrderId={data.appointActivityOrderId}
                         participateTime={data.participateTime}
                         isHome={isHome}
                         userAccount={userAccount}/>
            <TelDialog isVisible={telVisible} setIsVisible={setTelVisible}/>
        </div>
    )
}
