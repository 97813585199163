// eggDialog.js
import React, { useEffect, useState, useRef } from 'react'
import styles from './marguee.module.scss';
import Library from 'library';
const { Util } = Library;
export const Marquee = (props) => {
    let timer
    const [animate, setAnimate] = useState(false)
    const [listMarginTop, setTop] = useState('0')
    const [data, setData] = useState([])
    /* const [data, setData] = useState([
        {
            mobile: '12222223333',
            content: '你好哈哈哈哈haha说暗号搜房东欧hahaha哈哈哈'
        }
])
    document.onclick=() => {
        setData([...data,{mobile: '12222224444',content: '你好哈哈哈哈haha说暗号搜房东欧hahaha哈哈哈'}])
    } */
    const ref = useRef(null)
    useEffect(() => {
        setData(props.list)
    }, [props.list])
    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])
    useEffect(() => {
        startScroll()
        return () => {
            clearTimeout(timer)
        }
    },[data])
    const startScroll = () => {
        if(data.length<4) return
        setAnimate(true)
        let height= ref.current.getElementsByClassName(styles["marquee-content-itm"])[0].offsetHeight
        setTop('-'+height+'px')
        timer = setTimeout(() => {
            let arr = [...data]
            arr.push(data[0])
            setAnimate(false)
            setTop('0')
            arr.splice(0,1)
            setData(arr)
        }, 2000)
    }
    return (
        <div className={styles['marquee-container']} >
                <div className={styles["marquee-wrapper"]}>
                    <div className={`${styles["marquee-content"]} ${animate ? styles.animate : ''}`} style={{ marginTop: listMarginTop }} ref={ref}>
                        {
                            data.map((itm, i) => {
                                if (itm) {
                                    return <div key={`${itm}-${i}`} className={styles["marquee-content-itm"]}>
                                        <p>
                                            <span style={{color: '#092ab4'}}>
                                                {`${Util.format.mobileSecurity(itm.mobile)}对绿地集团说：`}
                                            </span>
                                            <span>{Util.format.entitiesToUtf16(itm.content)}</span>
                                        </p>
                                    </div>
                                } else {
                                    return false;
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        )
}
