import React from 'react';
import Library from 'library';
import styles from './nftSuccess.module.scss';

export class NftSuccess extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            downUrl: ''
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ downUrl: Library.Util.APP_INVITER_DOWNLOAD_URL });
    }

    render() {
        const { downUrl } = this.state;
        return <div className={styles.container}>
            <div className={styles.pic1}/>
            <div className={styles.btn} onClick={()=>{
                window.location.href = downUrl;
            }}/>
            <div className={styles.pic2}/>
        </div>;
    }
}
