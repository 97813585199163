import React, {useState, useEffect, useRef} from 'react';
import {Toast,} from 'antd-mobile';
import styles from './styles.module.scss';
import {ListView} from "components";
import Services from "../../../../../services";
import {CARBONPOINT} from "../../../../../library/util/constant";
import Library from "../../../../../library";

const {JsBridge, Util} = Library;
const ExchangeRecord = ({onClose, visible}) => {
    const [list, setList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [_visible, setVisible] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        currentPage.current = 1;
        loadData().then();
    }, []);

    const loadData = async () => {
        try {
            const params = {
                pageNum: currentPage.current,
                pageSize: pageSize.current,
                activityCode: CARBONPOINT,
            }
            setIsLoad(true);
            // const res = await mockFetch(params)
            let res = await Services('MainPage', {code: 600857}, params);
            console.log('600857---', res);
            if (res.status > 0 && !!res.data) {
                setIsLoad(false);
                if (currentPage.current > 1) {
                    setList([...list, ...res.data]);
                } else {
                    setList(res.data);
                }
                setHasMore(res.data.length >= pageSize.current)
            }
        } catch (error) {
            Toast.show(error);
        }
    }

    const itemClick = async (item) => {//3=实物 8=数字藏品 10=碳证书
        const {exchangeType = 0, goodsId = 0, orderId = '', points = 0} = item;
        if (exchangeType === 3) {
            const params = {
                orderId: orderId,
                activityCode: CARBONPOINT,
            }
            let res = await Services('MainPage', {code: 600862}, params);
            console.log('600862---', res);
            if (res.status > 0) {
                let data = res.data;
                const {
                    province = '',
                    city = '',
                    area = '',
                    street = '',
                    address = '',
                    deliverStatus = '',
                    consigneeName = '',
                    consigneeNumber = '',
                    productImgUrl = '',
                    productName = '',
                    orderId = '',
                    createTime = '',
                } = data;
                let deliveryAddress = province + city + area + street + address;
                let params = {
                    idf: 'mail',
                    deliverStatus: deliverStatus ?? '',
                    consigneeName: consigneeName || '',
                    consigneeNumber: consigneeNumber || '',
                    prizeImgUrl: productImgUrl || '',
                    prizeName: productName || '',
                    orderId: orderId || '',
                    createTime: createTime || '',
                    deliveryAddress: deliveryAddress || '',
                    points:points,
                }
                let urlString = Util.url.objectToQueryParams(params);
                JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/activities/lottery/orderDetail?${urlString}`});
            }
        } else if (exchangeType === 8) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection`;
            JsBridge.push('CustomWeb', {uri: uri});
            // JsBridge.push('CustomWeb', {uri: `http://192.168.110.82:3001/myCollection`});
        } else if (exchangeType === 10) {
            let uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?initalTab=1`;
            JsBridge.push('CustomWeb', {uri: uri});
            // JsBridge.push('CustomWeb', {uri: `http://192.168.110.82:3001/myCollection?initalTab=1`});
        }
    }

    const RenderItem = (item) => {
        const {exchangeName, imgUrl, exchangeNum = 0, points = 0, exchangeTime = ''} = item;
        return <div className={styles.ex_record_item}>
            <div className={styles.top}>
                <img src={imgUrl} alt=""/>
                <div className={styles.top_right}>
                    <p className={styles.title}>{exchangeName}</p>
                    <div className={styles.top_bottom}>
                        <div className={styles.val_container}>
                            <img src={require('../../imgs/icon_tjf.png')} alt=""/>
                            <p>{points / 1000}kg</p>
                        </div>
                        <p className={styles.count}>x{exchangeNum}</p>
                    </div>
                </div>
                <div className={styles.line}/>
            </div>
            <div className={styles.bottom}>
                <p className={styles.time}>兑换于{exchangeTime}</p>
                <p className={styles.btn} onClick={() => itemClick(item)}>查看详情</p>
            </div>
        </div>
    }

    return <div className={styles.ex_list}>
        <ListView
            dataSource={list}
            renderItem={RenderItem}
            hasMore={hasMore}
            onRefresh={async () => {
                currentPage.current = 1;
                await loadData()
            }}
            style={{paddingTop: 60}}
            isLoading={isLoad}
            loadMore={async () => {
                currentPage.current += 1;
                await loadData()
            }}
        />
    </div>
}


export default ExchangeRecord;
