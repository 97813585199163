import React, { useEffect, useState } from 'react'
import Services from 'services';
import library from 'library';
import { Marquee } from './components/marguee';
import styles from './anniversary.module.scss';
import anni_top from './images/anni_top.png'
import anni_rule from './images/anni_rule.png'
import anni_bottom from './images/anni_bottom.png'
import anni_title from './images/anni_title.png'
import anni_logo from './images/anni_logo.png'
import anni_border from './images/anni_border.png'
const {JsBridge} = library
export const Anniversary = () => {
  const [list, setList] = useState([])
  const [disabled1, setDisabled1] = useState(false)
  const [disabled2, setDisabled2] = useState(false)
  const [show, setShow] = useState(false)
  const [ruleText, setRuleText] = useState('')
  const [ruleTitle, setRuleTitle] = useState('')
  useEffect(() => {
    fetchData()
  },[])
  
  async function fetchData () {
    const result = await Services('MainPage', { code: '101189' }, { activityCode: "nftActive" })
    if(result.status > 0) {
      const {data} = result
      setDisabled1(data.LikeFlag)
      setDisabled2(data.blessingFlag)
      setList(data.allList)
    }
    const res = await Services('MainPage', { code: '101192' }, { activityCode: "nftActive" })
    if(res.status > 0) {
      const {data} = res
      setRuleText(data.nftBlessingRule.otherSign)
      setRuleTitle(data.nftBlessingRule.codeAlias)
    }
    
  }
  const handleBless = (type) => {
    if(type === 'type1') {
      return async() => {
        if (disabled1) return false
        const result = await Services('MainPage', { code: '101190' }, { activityCode: "nftActive", blessingType: 3, defaultStatus: 1,  deptName: '不涉及' })
        console.log(result)
        if(result.status > 0) {
          const {data} = result
          setList(list.concat(data.blessingPojo))
          if(data.success) {
            setDisabled1(true)
            JsBridge.runAction('alert', 'error', ['点赞成功，积分+100']);
          }
        }
      }
    } else {
      return async() => {
        if (disabled2) return false
        const result = await Services('MainPage', { code: '101190' }, { activityCode: "nftActive", blessingType: 1, defaultStatus: 1, deptName: '不涉及' })
        console.log(result)
        if(result.status > 0) {
          const {data} = result
          setList(list.concat(data.blessingPojo))
          if(data.success) {
            setDisabled2(true)
            JsBridge.runAction('alert', 'error', ['祝福成功，积分+200']);
          }
        }
      }
    }
  }
  const toggle = () => {
    setShow(!show)
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <img src={anni_top} />
        <img className={styles.rule_button} src={anni_rule} onClick={toggle} />
      </div>
      <div className={styles.bottom}>
        <img className={styles.bg} src={anni_bottom} />
        <img className={styles.title} src={anni_title} />
        <div className={styles.content} onClick={handleBless}>
          <div className={styles.show_wrap}>
            <img className={styles.border_bg} src={anni_border} />
            <div className={styles.text_wrap}>
              <Marquee list={list} />
            </div>
          </div>
          <div className={styles.bts_wrap}>
            <div className={disabled1?styles.disabled:styles.bt} onClick={(handleBless('type1'))}>为绿地点赞</div>
            <div className={disabled2?styles.disabled:styles.bt} onClick={(handleBless('type2'))}>为绿地祝福</div>
          </div>
        </div>
        <img className={styles.logo} src={anni_logo} />
      </div>
      <Myrule toggle={toggle} text={ruleText} title={ruleTitle} style={{display: show?'flex': 'none'}} />
    </div>
  )
}
const Myrule = (props) => {
  const handleClose = () =>{
    props.toggle()
  }
  return (
    <div style={props.style} className={styles.mask} >
      <div className={styles.content}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.text}>
          {props.text}
        </div>
        <div className={styles.close} onClick={handleClose}>我知道了</div>
      </div>
    </div>
  )
}
