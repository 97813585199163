/**
 * @description 地址新增及编辑, 编辑时路由将携带地址参数
 * @returns {/front/guapp/address/addressEdit} 
 * 
*/
import React, {useEffect, useRef} from "react";
import {Form, Input, Switch, Toast} from "antd-mobile";
import { Header, ModalAlert } from 'components';
import Library from "library";
import Service from "../service";
import AddressForm from "./component/addressForm";
import './index.scss';
const { JsBridge } = Library;

const kLocalSelectAddressKey = Service.kLocalSelectAddressKey;

const AddressEdit = (props) => {
    // 获取参数
    const { addressInfo='{}' } = Library.Util.url.paramsToObj();
    const _addressInfo = JSON.parse(decodeURIComponent(addressInfo))
    
    const formRef = useRef()

    useEffect(()=>{
        
    },[])

    const validateFields = async ()=> {
        let reslut = {};
        try {
            reslut = await formRef.current.validateFields()
        } catch (error) {
            reslut = error
        }
        return reslut;
    }

    const onSaveAddress = async ()=> {
        const reslut = await validateFields()
        console.log(reslut)
        if(reslut.errorFields) {
            Toast.show(reslut.errorFields[0].errors[0])
            return;
        }
        
        const values = formRef.current.getFieldsValue()
        if(_addressInfo.deliveryAddressId){
            values.deliveryAddressId = _addressInfo.deliveryAddressId
        }
        
        window.JsBridge.print(values)
        const newAddress = {
            ..._addressInfo,
            ...values
        }
        const res = await Service.editSaveAddress(newAddress)
        if(res) {
            selectChangeAddress(newAddress)
        }
       
    }

    const onDelete =()=> {
        ModalAlert.show({
            title: <span style={{fontFamily:'initial'}}>{'确定要删除该地址吗？'}</span>,
            content: '',
            onAction: (item, index) => {
                if(item.key === 'confirm'){
                    Service.deleteAddress(_addressInfo).then((res) => {
                        if(res) {
                            selectChangeAddress()
                        }
                    })
                }
                ModalAlert.hide()
            },
            actions: [
                {
                    key: 'cancel',
                    text: '取消'
                },
                {
                    key: 'confirm',
                    text: '确认删除',
                }
            ]
        })
    }

    const selectChangeAddress = async (newAddress)=> {
        if (window.JsBridge.hasWebViewBridge()) {

            window.JsBridge.back()
        }else {
            
            if(newAddress) {
                const localAddress = JSON.parse(sessionStorage.getItem(kLocalSelectAddressKey) || '{}')
                if(localAddress && localAddress.deliveryAddressId == _addressInfo.deliveryAddressId) {
                    sessionStorage.setItem(kLocalSelectAddressKey, JSON.stringify(newAddress));
                }
            }
            
            props.history.go(-1)
        }
    }

    const checkMobile = (rule, value) => {
        if (value) {
            if(!Library.Util.validate.mobile(value)) {
                return Promise.reject(new Error('请填写正确的手机号!'))
            }else {
                return Promise.resolve(value)
            }
        }
        return Promise.reject(new Error('手机号不能为空!'))
      }

    return (
        <div className="address_edit_page">
            <Header midText={`编辑收货地址`} bgColor={'#fff'} 
                rightText={
                    _addressInfo.customerId ?
                    <span style={{color:'#624C16', padding: 10}} onClick={onDelete}>
                        {'删除'}
                    </span> 
                    : null
                }
            />
            <div className="edit_page_body">
                <Form layout='horizontal'
                    requiredMarkStyle='none'
                    ref={formRef}
                >
                    <Form.Item
                        name='consigneeName'
                        label='收货人'
                        initialValue={_addressInfo.consigneeName}
                        rules={[{ required: true, message: '请输入收货人姓名' }, {min: 2, message: '收货人长度必须为2-15个字符之间'}]}
                    >
                        <Input onChange={console.log} placeholder='请输入收货人姓名' maxLength={15}/>
                    </Form.Item>
                    <Form.Item
                        name='consigneeNumber'
                        label='联系电话'
                        initialValue={_addressInfo.consigneeNumber}
                        rules={[
                            {required: true },
                            {validator: checkMobile}
                        ]}
                    >
                        <Input maxLength={11} onChange={console.log} placeholder='请输入联系方式' />
                    </Form.Item>
                    <AddressForm data={_addressInfo} form={formRef}/>
                    <Form.Item
                        name='deliveryAddress'
                        label='详细地址'
                        initialValue={_addressInfo.deliveryAddress}
                        rules={[{ required: true, message: '请输入详细地址' }]}
                    >
                        <Input onChange={console.log} placeholder='请输入' />
                    </Form.Item>
                    <Form.Item
                        name='isDefaltAddress'
                        label='设为默认'
                        valuePropName={'checked'}
                        initialValue={_addressInfo.isDefaltAddress || 0}
                        childElementPosition='right'
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </div>
            <div className="edit_page_bottom">
                <div className="bottom-btn" onClick={onSaveAddress}>
                    {'保存'}
                </div>
                {
                    JsBridge.isMiniprogram && _addressInfo.customerId ?
                    <div className="bottom-btn" onClick={onDelete}>
                        {'删除'}
                    </div>
                    :null
                }
            </div>
        </div>
    )
}

export default AddressEdit;