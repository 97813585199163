import backgroundImage from './images/spilceOne@2x.png';
import backgroundImageTwo from './images/spliceTwo.png';
import background from './images/background@2x.png';

import backgroundSlImage from './images/spilceOne_sl@2x.png';
import backgroundSlImageTwo from './images/spliceTwo_sl.png';
import backgroundSl from './images/background_sl@2x.png';

export const Config = {
    'DISNEYTEAM': {
        disney: background,
        backgroundImage: backgroundImage,
        backgroundImageTwo: backgroundImageTwo,
        rule: `1. 拼人气赢迪士尼贵宾礼遇门票，G优会员可通过组队参加拼人气得门票活动，在活动时间内最终冠军战队可获得该次活动奖品。
2. 拼人气活动每天每个战队可邀请助力好友数量不限，新会员助力可得500人气值，老会员助力可得100人气值，每个会员每天只能助力一个战队且一次。（同一台设备、同一个手机、相同账号等情况均视为同一个会员）。
3. 活动结束后，我们将会对本次获奖战队的实际助力情况进行审核，审核通过后我们会与团长联系确认相关信息告知注意事项，若发现异常，包括并不仅限于恶性机刷，或者通过插件、外挂等其他不正当手段完成助力，G优平台有权取消获得战队的资格，福利名额取参与活动排名次之战队。
4. 此门票为实名制门票，仅限战队成员本人使用，获得此门票战队成员严禁私下交易，如因违规操作门票（不仅限于私下交易）等造成无法使用甚至违规违法，后果自行承担
5. 此门票包含五大礼遇：两个免费专属停车位，5张免费贵宾礼遇门票，快速入园特殊通道，花车游行专属观赏位，八大热门项目快速通道（翱翔飞跃地平线、雷鸣山漂流、创极速光轮、七个小矮人矿山车、加勒比海盗之沉落宝藏之战、小熊维尼历险记、小飞侠天空奇遇、巴斯光年星际营救），除以上五大礼遇外，其它费用需用户自理。
6. 活动时间：2021年8月20日00:00至2021年8月27日12:00
7. 票出游日期：2021年9月4日`
    },
    'DISNEY0903': {
        disney: background,
        backgroundImage: backgroundImage,
        backgroundImageTwo: backgroundImageTwo,
        rule: ``
    },
    'DISNEY0910': {
        disney: background,
        backgroundImage: backgroundImage,
        backgroundImageTwo: backgroundImageTwo,
        rule: ``
    },
    'DISNEY1028':{
        disney: backgroundSl,
        backgroundImage: backgroundSlImage,
        backgroundImageTwo: backgroundSlImageTwo,
        rule: ``
    },
    'DISNEY1117': {
        disney: backgroundSl,
        backgroundImage: backgroundSlImage,
        backgroundImageTwo: backgroundSlImageTwo,
        rule: ``
    }
};