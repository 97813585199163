import React from 'react';
import Jsbridge from 'library/jsbridge';
import styles from './index.module.scss';

export class Privacy extends React.Component {

    /**
     * async fetch demo
     * @returns {XML}
     */
    constructor(props) {
        super(props);
        this.state = {
            init: false,
        };
        this.unlisten = null;
    }

    componentWillMount() {
        Jsbridge.setTitle('详情');
    }

    render() {
        return <div className={styles.privacyProtocol}>
            <h1 className={styles.protocolTit}>绿地G优隐私政策</h1>
            <div className={styles.protocolTextWrap}>
                <p className={styles.protocolText}>【特别提示】尊敬的绿地G优用户：我们(或绿地G优指绿地金融投资控股集团有限公司，注册地址为上海市崇明县潘园公路1800号2号楼888室，联系电话为4000525055，常用办公地址为上海市黄浦区龙华东路868号办公A楼1508室)对《绿地G优隐私政策》进行了更新。本次更新我们做出了诸多有利于保护您个人信息的调整； 请您仔细阅读《绿地G优隐私政策》（尤其是加粗划线的内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可联系我们的客服咨询，如您不同意协议中的任何条款，您应立即停止访问绿地G优。</p>
                <p className={styles.protocolText}>《绿地G优隐私政策》正文</p>
                <p className={styles.protocolText}>版本更新日期：2020年09月08日</p>
                <p className={styles.protocolText}>版本生效日期：2020年09月08日</p>
                <h3 className={styles.protocolTip}>引言</h3>
                <p className={styles.protocolText}>绿地G优（在本隐私政策中，指绿地金融投资控股集团有限公司及其关联方，以下或称“我们”）非常重视用户的隐私和个人信息保护。 您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《绿地G优隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、 使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</p>
                <h3 className={styles.protocolTip}>本政策将帮助您了解以下内容：</h3>
                <p className={styles.protocolText}>1、我们如何收集和使用您的个人信息</p>
                <p className={styles.protocolText}>2、我们如何使用 Cookie 和同类技术</p>
                <p className={styles.protocolText}>3、我们如何共享、转让、公开披露您的个人信息</p>
                <p className={styles.protocolText}>4、我们如何保护和保存您的个人信息</p>
                <p className={styles.protocolText}>5、您如何管理个人信息</p>
                <p className={styles.protocolText}>6、通知和修订</p>
                <p className={styles.protocolText}>7、如何联系我们</p>
                <p className={styles.protocolText}>8、未成年人信息保护</p>
                <p className={styles.protocolText}>9、第三方责任的管理及责任声明</p>
                <p className={styles.protocolText}>本隐私政策与您所使用的绿地G优服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。</p>
                <p className={styles.protocolText}>如对本隐私政策或相关事宜有任何问题，您可随时拨打我们的客服电话（4000525055）或绿地G优微信公众号留言的方式与我们联系 。</p>
                <h3 className={styles.protocolTip}>一、我们如何收集和使用您的个人信息</h3>
                <p className={styles.protocolText}>（一）我们如何收集您的信息</p>
                <p className={styles.protocolText}>1、您直接提供的信息：我们可能收集并存储您在使用绿地G优服务时向我们提供的任何信息，包括您在我们页面中在线填写信息时、参与网络社区讨论时、解决争议或就绿地G优服务与我们联系时所提供的信息，以及您在使用我们服务过程中产生的关于您的交易和活动的信息。</p>
                <p className={styles.protocolText}>2、我们主动收集的信息：在您使用绿地G优网站、APP及相关服务过程中，我们会收集您的计算机、手机及其他访问设备发送给我们的信息。</p>
                <p className={styles.protocolText}>3、我们从其他来源取得的信息：在取得您同意的前提下，我们还可能从绿地G优关联公司及合作伙伴、信用机构及依法成立并合法留存您的相关信息的第三方机构获取您的相关信息。</p>
                <p className={styles.protocolText}>（二）我们如何使用您的信息</p>
                <p className={styles.protocolText}>我们收集您信息的目的主要是为了向您提供安全、高效以及个性化的服务体验。我们会出于以下目的使用您的个人信息：</p>
                <p className={styles.protocolText}>1、进行实名制管理</p>
                <p className={styles.protocolText}>您在使用绿地G优服务时我们需要首先认证您身份的真实性，为此您需要提供您的身份信息、银行卡信息及手机号。我们会将上述信息提交给合法持有您上述信息的第三方身份验证机构，以便对您所提供信息的准确性进行核对。如果您不提供上述信息则无法使用根据中国相关法律法规必须进行实名制管理的相关金融服务，例如：支付、理财、信贷等。</p>
                <p className={styles.protocolText}>2、 进行资格、信用及偿付能力审核</p>
                <p className={styles.protocolText}>您在使用绿地G优服务时，我们会使用您的个人基本信息、个人身份信息、个人财产信息及其他在具体业务开展过程中基于您的同意而采集的信息进行风险管理及控制，检测、预防及/或修复欺诈或其他潜在的非法活动。同时，在您享受绿地G优服务的过程中，我们会持续性的利用上述信息检测、防止或纠正违反相关法律法规或适用的用户协议的行为。</p>
                <p className={styles.protocolText}>3、为您提供您选择的绿地G优相关服务</p>
                <p className={styles.protocolText}>在您使用绿地G优相关服务，例如信贷、理财、支付服务时，我们会使用您的个人身份信息、个人财产信息及其他在业务具体开展过程中基于您的同意而采集的信息对我们产品或服务进行适用性评估、验证交易真实性、处理产品或服务请求以及完成交易指令并向您发送交易通知等。同时，为了遵守相关法律法规及监管规定，也为了便于您查询交易记录或历史状态，我们会将您使用绿地G优时的身份信息、交易信息及行为信息进行存档，并严格按照法律法规的规定对这些信息进行妥善保管。</p>
                <p className={styles.protocolText}>4、 提供客户服务及进行投诉处理</p>
                <p className={styles.protocolText}>我们的客服系统会使用您的账号信息和交易信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您交易信息相关的客服与售后服务时，我们将会查询您的交易信息。</p>
                <p className={styles.protocolText}>为了保证您及他人的合法权益，如您被他人投诉或投诉他人，我们会将您的姓名及身份证号码、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。</p>
                <p className={styles.protocolText}>5、改进我们的产品以为您提供更为个性化的服务</p>
                <p className={styles.protocolText}>我们可能会收集您的交易信息、浏览信息、您的关注信息进行数据分析以形成用户画像，用来将您感兴趣的产品或服务信息展示给您。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。</p>
                <p className={styles.protocolText}>6、保障交易安全所必须的功能</p>
                <p className={styles.protocolText}>为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、交易信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于绿地G优系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</p>
                <p className={styles.protocolText}>7、开展营销活动</p>
                <p className={styles.protocolText}>我们可能会利用您提供的以及我们自行采集的用户信息为您推荐个性化的产品/服务。同时，未经您明确同意，我们不会出于满足第三方的营销目的而将您的个人信息出售或出租给任何第三方</p>
                <p className={styles.protocolText}>（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
                <p className={styles.protocolText}>1、与国家安全、国防安全有关的；</p>
                <p className={styles.protocolText}>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                <p className={styles.protocolText}>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                <p className={styles.protocolText}>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</p>
                <p className={styles.protocolText}>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
                <p className={styles.protocolText}>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
                <p className={styles.protocolText}>7、根据您的要求签订合同所必需的；</p>
                <p className={styles.protocolText}>8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置服务的故障；</p>
                <p className={styles.protocolText}>9、为合法的新闻报道所必需的；</p>
                <p className={styles.protocolText}>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
                <p className={styles.protocolText}>11、法律法规规定的其他情形。</p>
                <p className={styles.protocolText}>（四）我们从第三方获得您个人信息的情形</p>
                <p className={styles.protocolText}>1、我们可能从第三方获取您授权共享的账户信息，并在您同意本隐私政策后通过第三方账户直接登录并使用我们的产品与/或服务。我们将会依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。</p>
                <p className={styles.protocolText}>2、在取得您同意的前提下，我们可能从合法持有您个人信息的第三方机构获得您的个人信息。</p>
                <p className={styles.protocolText}>（五）您个人信息使用的规则</p>
                <p className={styles.protocolText}>1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</p>
                <p className={styles.protocolText}>2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</p>
                <p className={styles.protocolText}>3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并按照相关法律法规的要求删除您的个人信息或进行匿名化处理。</p>
                <p className={styles.protocolText}>4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
                <p className={styles.protocolText}>5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</p>
                <p className={styles.protocolText}>6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
                <h3 className={styles.protocolTip}>二、我们如何使用 Cookie 和同类技术</h3>
                <p className={styles.protocolText}>（一）Cookie的使用</p>
                <p className={styles.protocolText}>1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies 是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的浏览习惯和偏好等数据，进而帮助您简化个人信息的填写、提供您的服务偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全、提供给您更好的用户体验。</p>
                <p className={styles.protocolText}>2、我们不会将 Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问绿地G优网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</p>
                <p className={styles.protocolText}>（二）网络Beacon和同类技术的使用</p>
                <p className={styles.protocolText}>除 Cookie外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络 Beacon"）。我们使用网络Beacon的方式有：</p>
                <p className={styles.protocolText}>1、通过在绿地G优网站上使用网络Beacon，计算用户访问数量、停留时间，并通过访问 Cookies 辨认注册的绿地G优用户。</p>
                <p className={styles.protocolText}>2、通过得到的Cookies信息，为您提供个性化服务。</p>
                <h3 className={styles.protocolTip}>三、我们如何共享、转让、公开披露您的个人信息</h3>
                <p className={styles.protocolText}>（一）共享</p>
                <p className={styles.protocolText}>1、我们不会与绿地G优以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
                <p className={styles.protocolText}>（1）事先获得您明确的同意或授权；</p>
                <p className={styles.protocolText}>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
                <p className={styles.protocolText}>（3）在法律法规允许的范围内，为维护绿地G优、绿地G优的关联方或合作伙伴、您或其他绿地G优用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
                <p className={styles.protocolText}>（4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</p>
                <p className={styles.protocolText}>（5）应您需求为您处理您与他人的纠纷或争议；</p>
                <p className={styles.protocolText}>（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
                <p className={styles.protocolText}>（7）基于学术研究而使用；</p>
                <p className={styles.protocolText}>（8）基于符合法律法规的社会公共利益而使用。</p>
                <p className={styles.protocolText}>2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
                <p className={styles.protocolText}>3、我们可能会向合作伙伴等第三方共享您的交易信息、账户信息及设备信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：</p>
                <p className={styles.protocolText}>（1）绿地集团的成员，如绿地商业集团等。这些成员有权提供与绿地G优服务相关的产品和服务（如注册、交易和客户支持），帮助检测和防止潜在的违法犯罪行为和违反我们政策的行为。</p>
                <p className={styles.protocolText}>（2）提供技术、咨询服务的供应商。我们可能会将您的个人信息共享给支持我们提供服务的第三方。这些机构包括为我们提供基础设施技术服务、数据处理、信贷审批和客户服务等的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。</p>
                <p className={styles.protocolText}>（3）合作金融机构，这些机构可以与我们联合研发并提供金融产品。除非您同意将这些信息用于其他用途，否则这些金融机构不得将此类信息用于绿地G优相关产品之外的其他目的。</p>
                <p className={styles.protocolText}>4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</p>
                <p className={styles.protocolText}>5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护绿地G优、您或其他绿地G优客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，我们可能会与其他公司和组织交换您的个人信息、交易信息及活动信息。不过,这并代表我们会违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</p>
                <p className={styles.protocolText}>6、为了向您提供一键分享服务，我们的产品集成友盟+ U-Share SDK，将收集您的设备标识信息（如IMEI/android ID/IDFA等）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方SDK服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟+】采集的数据类型及用途，以及何保护您的个人信息，您可以登陆https://www.umeng.com/policy了解【友盟+】隐私权政策。我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力</p>
                <p className={styles.protocolText}>（二）转让</p>
                <p className={styles.protocolText}>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                <p className={styles.protocolText}>1、事先获得您明确的同意或授权；</p>
                <p className={styles.protocolText}>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
                <p className={styles.protocolText}>3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
                <p className={styles.protocolText}>4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
                <p className={styles.protocolText}>（三）公开披露</p>
                <p className={styles.protocolText}>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
                <p className={styles.protocolText}>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
                <p className={styles.protocolText}>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时， 我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</p>
                <h3 className={styles.protocolTip}>四、我们如何保护和保存您的个人信息</h3>
                <p className={styles.protocolText}>（一）我们保护您个人信息的技术与措施</p>
                <p className={styles.protocolText}>我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
                <p className={styles.protocolText}>1、数据安全技术措施</p>
                <p className={styles.protocolText}>我们会采用符合业界标准的安全防护措施以及行业内通行的安全技术来防止您的个人信息遭到未经授权的访问、修改,避免您的个人信息泄露、损坏或丢失：</p>
                <p className={styles.protocolText}>（1）采取加密技术对您的个人信息进行加密存储。</p>
                <p className={styles.protocolText}>（2）我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保您的个人信息在传输过程中的安全。</p>
                <p className={styles.protocolText}>（3）在使用您的个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内的多种数据脱敏技术增强个人信息在使用中的安全性。</p>
                <p className={styles.protocolText}>（4）我们存储您个人数据的系统已从安全管理、策略、过程、网络体系结构等诸多方面保障交易及个人信息安全。</p>
                <p className={styles.protocolText}>2、为保护个人信息采取的其他安全措施</p>
                <p className={styles.protocolText}>我们通过建立数据分类分级、数据安全管理规范、安全开发规范来管理规范个人信息的存储和使用：</p>
                <p className={styles.protocolText}>（1）我们采用严格的数据访问权限和多重身份认证技术控制和保护个人信息，通过与信息接触者签署严格的保密协议、监控和审计机制来对数据进行全面安全控制，避免数据被违规使用。</p>
                <p className={styles.protocolText}>（2）我们采用代码安全检查、数据访问日志分析技术进行个人信息安全审计。</p>
                <p className={styles.protocolText}>（3）我们建立了信息安全委员会并下设信息安全小组，由各小组信息安全专员负责个人信息安全事务。</p>
                <p className={styles.protocolText}>（4）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
                <p className={styles.protocolText}>3、安全事件处置</p>
                <p className={styles.protocolText}>（1）为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
                <p className={styles.protocolText}>（2）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                <p className={styles.protocolText}>（3）如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。</p>
                <p className={styles.protocolText}>（二）您个人信息的保存</p>
                <p className={styles.protocolText}>1、我们将在中华人民共和国境内收集的个人信息存储在中华人民共和国境内。如为处理跨境业务，且需要向境外传输您的个人信息完成交易的，我们会按照法律法规和相关监管部门的规定执行，并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。</p>
                <p className={styles.protocolText}>2、如果我们终止服务或运营，我们会至少提前三十日通知您，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</p>
                <h3 className={styles.protocolTip}>五、您如何管理您的个人信息</h3>
                <p className={styles.protocolText}>绿地G优非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</p>
                <p className={styles.protocolText}>1、访问和更正您的个人信息</p>
                <p className={styles.protocolText}>除法律法规规定外，您有权随时访问下述您的个人信息并随时更正其中的部分信息，具体包括：</p>
                <p className={styles.protocolText}>（1）您的账户信息</p>
                <p className={styles.protocolText}>登录密码、交易密码：首页--“我的”进入我的绿地G优—点击头像进入账号设置—访问/更正登录密码、交易密码；</p>
                <p className={styles.protocolText}>银行卡：首页—“我的”进入我的绿地G优—“我的银行卡”进入银行卡信息—长按“具体银行卡”进入设置—访问/更正银行卡信息</p>
                <p className={styles.protocolText}>（2）您的交易信息</p>
                <p className={styles.protocolText}>账单：首页—“我的”进入我的绿地G优—交易记录—访问历史交易信息。</p>
                <p className={styles.protocolText}>（3）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</p>
                <p className={styles.protocolText}>（4）您无法访问和/或更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和/或更正的服务，这些信息主要是为了保证交易安全满足相关强制性法律法规要求所收集的您的设备信息、您使用金融产品时产生的个人信息。上述信息我们会在您的授权范围内按照相关法律法规的规定进行使用，您无法变更或自主删除。</p>
                <p className={styles.protocolText}>2、删除您的个人信息</p>
                <p className={styles.protocolText}>您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括绑定银行卡、浏览记录、收藏记录、关注记录等。</p>
                <p className={styles.protocolText}>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
                <p className={styles.protocolText}>（1）如果我们处理个人信息的行为违反法律法规；</p>
                <p className={styles.protocolText}>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
                <p className={styles.protocolText}>（3）如果我们处理个人信息的行为违反了与您的约定；</p>
                <p className={styles.protocolText}>（4）如果您注销了绿地G优账户；</p>
                <p className={styles.protocolText}>（5）如果我们终止服务及运营。</p>
                <p className={styles.protocolText}>若我们决定响应您的删除请求，我们会根据您的要求及相关法律法规的要求进行后续删除处理并向您进行结果反馈。</p>
                <p className={styles.protocolText}>3、改变您授权同意的范围或撤回您的授权</p>
                <p className={styles.protocolText}>您可以通过删除信息、关闭设备功能、在绿地G优软件中进行隐私设置等方式改变部分您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</p>
                <p className={styles.protocolText}>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
                <p className={styles.protocolText}>4、注销</p>
                <p className={styles.protocolText}>您可以在我们的产品与/或服务中直接申请注销账户或注销您申请的具体绿地G优服务功能。关于您注销账户的方式以及您应满足的条件您可以通过本协议下方的方式与我们联系来获取。您注销账户后，我们将停止为您提供产品与/或服务，并依据您及相关法律法规的要求处理您的个人信息。</p>
                <p className={styles.protocolText}>5、响应您的请求</p>
                <p className={styles.protocolText}>如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为绿地G优存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以拨打绿地G优客服电话（4000525055）或绿地G优微信公众号留言的方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
                <p className={styles.protocolText}>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
                <p className={styles.protocolText}>（1）与国家安全、国防安全有关的；</p>
                <p className={styles.protocolText}>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
                <p className={styles.protocolText}>（3）与犯罪侦查、起诉和审判等有关的；</p>
                <p className={styles.protocolText}>（4）有充分证据表明您存在主观恶意或滥用权利的；</p>
                <p className={styles.protocolText}>（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
                <h3 className={styles.protocolTip}>六、通知和修订</h3>
                <p className={styles.protocolText}>1、为给您提供更好的服务以及随着绿地G优业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在绿地G优网站、绿地G优移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问绿地G优以便及时了解最新的隐私政策。</p>
                <p className={styles.protocolText}>2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</p>
                <p className={styles.protocolText}>本政策所指的重大变更包括但不限于：</p>
                <p className={styles.protocolText}>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
                <p className={styles.protocolText}>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
                <p className={styles.protocolText}>（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
                <p className={styles.protocolText}>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
                <p className={styles.protocolText}>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
                <p className={styles.protocolText}>（6）个人信息安全影响评估报告表明存在高风险时。</p>
                <p className={styles.protocolText}>3、我们还会将本策略的旧版本存档，供您查阅。</p>
                <h3 className={styles.protocolTip}>七、如何联系我们</h3>
                <p className={styles.protocolText}>1、我们设立了专职个人信息保护负责人和个人信息保护工作机构，如您在使用绿地G优服务时就您的个人信息的收集、使用、共享、保存等事宜有任何困惑，或您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请拨打我们的客服电话（4000525055）或绿地G优微信公众号留言的方式与我们联系，客服部门将会同个人信息保护工作机构及时答复您。</p>
                <p className={styles.protocolText}>2、一般情况下，我们将在三十天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
                <h3 className={styles.protocolTip}>八、未成年人信息保护</h3>
                <p className={styles.protocolText}>我们非常重视对未成年人信息的保护。您理解并同意，我们仅向实名注册且具有法律规定的完全民事权利能力和民事行为能力、能够独立承担民事责任的成年人提供绿地G优服务。如果您是18周岁以下的未成年人，我们将不会对您提供服务(但在未注册情况下进行的有限浏览情形除外)。</p>
                <p className={styles.protocolText}>如果我们发现已收集未成年人的个人信息，我们会采取必要措施主动及时地删除相关信息。</p>

                <h3 className={styles.protocolTip}>九、第三方责任的管理及责任的声明</h3>
                <p className={styles.protocolText}>对于第三方产品和服务的接入，我们会建立严格的管理机制和工作流程，并与第三方产品或服务提供方通过合同等形式明确双方的安全责任及实施的个人信息安全措施。在您使用的产品或服务由第三方提供时，我们会向您明确标识和说明。如第三方因服务所必需要采集您的信息，将会单独获得您的授权同意。</p>
                <p className={styles.protocolText}>请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务和由绿地G优APP处接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，监督第三方服务商加强个人信息安全管理，一旦我们发现第三方服务商没有落实安全管理要求和责任的，我们将及时督促其整改，必要时会停止与第三方服务商的接入和合作。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。</p>

                <h3 className={styles.protocolTip}>附：个人信息示例</h3>
                <p className={styles.protocolText}>个人信息举例</p>
                <p className={styles.protocolText}>个人基本信息:个人姓名、生日、性别、联系地址、个人电话号码、电子邮箱</p>
                <p className={styles.protocolText}>个人身份信息:身份证、护照、驾驶证、台胞证、回乡证</p>
                <p className={styles.protocolText}>网络身份标识信息:系统账号、IP地址、邮箱地址及与前述有关的密码</p>
                <p className={styles.protocolText}>个人教育工作信息:个人职业、工作单位、学历、学位</p>
                <p className={styles.protocolText}>个人财产信息:银行账户及其他支付工具的账户信息、收入来源、收入水平、交易记录、信贷信息、征信信息</p>
                <p className={styles.protocolText}>联系人信息:通讯录</p>
                <p className={styles.protocolText}>个人上网记录:网站浏览记录、软件使用记录、点击记录</p>
                <p className={styles.protocolText}>个人常用设备信息:硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息</p>
                <p className={styles.protocolText}>个人位置信息:定位信息、经纬度</p>
                <p className={styles.protocolText}>个人敏感信息是指，一旦泄露、非法提供或滥用可能危害人身或财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</p>
                <p className={styles.protocolText}>个人敏感信息举例</p>
                <p className={styles.protocolText}>个人身份信息:身份证、护照、驾驶证、台胞证、回乡证</p>
                <p className={styles.protocolText}>网络身份标识信息:系统账号、IP地址、邮箱地址及与前述有关的密码</p>
                <p className={styles.protocolText}>个人财产信息:银行账户及其他支付工具的账户信息、收入来源、收入水平、交易记录、信贷信息、征信信息</p>
                <p className={styles.protocolText}>其他信息:通讯录、个人电话号码、手机号码、行程信息、网页浏览记录、精准定位信息</p>
            </div>
        </div>
    }
}
