import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import rightArrow from './images/arrow-more.svg';
import Services from '../../services';
import { useEventListener } from '../../library/hooks';

export default (props) => {
    const [RecordMap, setRecordMap] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 10;

    // 使用 useCallback 的事件处理程序，这样引用就不会更改

    useEffect(() => {
        getList(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onScroll = (event, ) => {

        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop + document.body.clientHeight >= document.documentElement.scrollHeight - 30) {
            getList();
        }
    }

    const handler = useCallback(onScroll);

    useEventListener('scroll', handler, document);

    const getList = async (refresh) => {
        const thisPage = refresh ? 1 : pageNo;
        const thisHasMore = refresh ? true : hasMore;
        if (thisHasMore && !isLoading) {
            setIsLoading(e => true);
            let result = await Services('Coupon','WriteOffRecordList',{
                pageNum: thisPage,
                pageSize: pageSize
            });
            if (result.status > 0) {
                console.log(RecordMap, result && result.data && result.data.list.length >= pageSize);
                const thisRecords = RecordMap.concat((result && result.data && result.data.list) || []);
                setHasMore(e => result && result.data && result.data.list.length >= pageSize);
                setPageNo(e => e + 1);
                setRecordMap(thisRecords);
            }
            setIsLoading(false);
        }

    };

    return <div className={styles.home}>
        <div className={styles.blockGray}></div>
        <div className={styles.blockWhite}>
            {RecordMap.length > 0 && RecordMap.map((item, key) => {
                return <RecordList data={item} index={key} key={key} />
            })}
        </div>
        <p className={styles.hasMore}>{hasMore ? '加载更多' : '已经到底！'}</p>
    </div>;
};

const RecordList = (props) => {
    const history = useHistory();
    return <div className={styles.recordItem} onClick={() => {
        history.push('/front/admin/index/detail?couponCode=' + props.data.coupon_code);
    }}>
        <div className={styles.recordItemLeft}>
            <div className={styles.recordTitWrap + ' ' + styles.recordTopWrap}>
                <div className={styles.itemTitle}>{props.data.name}</div>
                <div className={styles.itemTitle}>{props.data.amount}{props.data.amount_type == '1' ? '元' : '%'}</div>
            </div>
            <div className={styles.recordTitWrap + ' ' + styles.recordBottomWrap}>
                <div className={styles.itemTitle}>{props.data.mobile}</div>
                <div className={styles.itemTitle}>{props.data.update_time}</div>
            </div>
        </div>
        <img src={rightArrow} alt='箭头' className={styles.rightArrow} />
    </div>;
}
