import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import Services from '../../services';
import { Actions } from '../../reduxs';
import Library from "../../library";

const throttleCallback = Library.Util.debounce(async (itemName, callback) => {
    const result = await Services('Coupon', 'WriteOffProject', { projectName: itemName });
    if (result) {
        callback(result.data.list);
    } else {
        callback(false);
    }
}, 500);


export default () => {
    const input = useRef();
    const history = useHistory();

    useEffect(() => {
        input && input.current && input.current.focus();
    }, [input]);


    const [itemName, setItemName] = useState('');
    const [itemList, setItemList] = useState([]);
    const listCallback = async () => {
        if (itemName) {
            throttleCallback(itemName, (result) => {
                setItemList(result || [])
            })
        } else {
            setItemList([])
        }

    }

    useEffect(() => {
        listCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemName]);



    return <div className={styles.itemModal}>

        <div className={styles.homeItem}>
            <div className={styles.itemTitle}>项目推广名</div>
            <input ref={input} type='text'
                className={styles.remarkInput}
                placeholder="请输入"
                onChange={(event) => setItemName(event.target.value)}
                value={itemName}
            // onBlur={this.scrollTop.bind(this)}
            />
        </div>
        <div className={styles.itemList}>
            {itemList.map((item, key) => {
                return <div key={key} className={styles.itemBox} onClick={() => {
                    Actions.coupon.setVerifyItem({ title: item.project_name, id: item.id });
                    history.goBack();
                }}>{item.project_name}</div>;
            })}
        </div>
    </div>;

};
