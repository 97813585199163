import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';
import loginBg from 'images/login-bg@2x.png';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux'
import {Actions} from 'reduxs';
import Services from 'services';
import Library from 'library';

let SwiperNc = null;
const {Platform, Util} = Library;
export const Register = (props) => {
    const [isAgree, setIsAgree] = useState(false);
    const history = useHistory();
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    // const [isNcShow, setIsNcShow] = useState(false);
    // const NCRef = useRef();
    const {invitationCode} = useParams();
    const activityCode = Library.Util.url.param('activityCode');
    const share_type = Library.Util.url.param('share_type');

    const countDown = useSelector((state) => {
        return state.sms.toJS().couponCounter;
    });

    useEffect(() => {
        isRegisted();
    }, []);

    const isRegisted = async () => {
        const result = await Services('GuMember', 'IsRegisted', {});
        if (result && result.data && result.data.msg) {
            if (activityCode === 'nftActive') {
                const res = await Services('GuMember', 'BindNftInvite', {inviteCode: invitationCode, activityCode});
                history.replace('/front/inviter/nftSuccess');
            } else if (activityCode === 'goddessFes') {
                history.replace(`/front/activities/goddessInviteSuccess`);
            }else if (activityCode === 'mothersDay') {
                const blessingId = Library.Util.url.param('blessingId');
                const param = {
                        blessingId: blessingId
                    }

                history.replace(`/front/activities/mothersDay/landingPage?${Library.Util.url.objectToQueryParams(param)}`);
            } else {
                history.replace('/front/inviter/success');
            }
        }
    }

    const sendSms = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('DynamicCode', 'GetDynamicCode', {
                    mobile,
                    ...res
                });
                if (result.status > 0) {
                    Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
                }
            }
        }
    }


    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.sms}> {lastSecond} 秒 </button>;
        } else {
            return <button onClick={sendSms} className={styles.sms}>获取</button>;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [countDown]);

    const submit = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (!code) {
            Actions.alert.show('请输入短信验证码');
            return;
        }
        if (!isAgree) {
            Actions.alert.show('请先同意协议');
            return;
        }
        const params = {mobile: mobile, dynamicCode: code, invitationCode, activityCode};
        const result = await Services('GuMember', (Platform.check.isH5() ? 'RegistOrLoginForH5' : 'RegistOrLogin'), params);
        if (result.status > 0) {
            if (activityCode === 'nftActive') {
                history.replace('/front/inviter/nftSuccess');
            } else if (activityCode === 'goddessFes') {
              history.replace(`/front/activities/goddessInviteSuccess`);
            }else if (activityCode === 'mothersDay') {
                const blessingId = Library.Util.url.param('blessingId');
                const param = {
                        blessingId: blessingId
                    }

                history.replace(`/front/activities/mothersDay/landingPage?${Library.Util.url.objectToQueryParams(param)}`);
            } else {
                history.replace('/front/inviter/success');
            }
        }
    };

    useEffect(() => {
        initNC();
    }, []);

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            // setSwiperCaptcha(e.SwiperCaptcha);
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    }

    // useEffect(() => {
    //     NCRef.current = async (data) => {
    //         // 短信验证码
    //         const { code } = data;
    //         if (code === 0) {
    //             const result = await Services('DynamicCode', 'GetDynamicCode', {
    //                 mobile,
    //                 ...data
    //             });
    //             if (result) {
    //                 Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
    //             }
    //         }

    //     }
    // }, [mobile]);

    return <div className={styles.container}>

        <div className={styles.headers}>
            <img src={loginBg} alt='头图'/>
        </div>
        <div className={styles.form}>

            <div className={styles.inputWrap}>
                <p><label>手机</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setMobile(event.target.value);
                        }} type='tel' value={mobile} placeholder='请输入' maxLength='11'/>
                    </div>
                </div>
            </div>

            <div className={styles.inputWrap}>
                <p><label>验证码</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setCode(event.target.value);
                        }} type='text' val={code} placeholder='请输入' maxLength='6'/>
                    </div>
                    {sendSmsRender()}

                </div>
            </div>

            <div className={styles.agreeWrap} onClick={() => {
                setIsAgree(!isAgree);
            }}>
                <i className={`${styles.agreeIcon} ${isAgree ? '' : styles.notAgreeIcon}`}></i>
                <p>我已阅读并同意<Link to='/front/inviter/register/protocol'>《G优尊享会员服务免责条款》</Link></p>
            </div>
            <div onClick={submit} className={styles.button}>注册/登录并激活会员</div>
        </div>
        {/* {isNcLoaded ? <SwiperNc visible={isNcShow} success={(e) => {
            NCRef.current(e);
        }} hide={() => {
            setIsNcShow(false)
        }} /> : false} */}
    </div>;
}
