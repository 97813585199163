import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import styles from './index.module.scss';
import loginBg from './images/login-bg@2x.png';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux'
import {Actions} from 'reduxs';
import gotoBrowserImg from './images/goto-browser.png';
import Services from 'services';
import Library from 'library';

let SwiperNc = null;
const {Platform} = Library;

const getUrlParams = () => {
  let params = {};
  const paramsArr = window.location.search.toString().slice(1).split('&');
  for (let i=0; i < paramsArr.length; i++) {
    const newArr = paramsArr[i].split('=');
    params[newArr[0]] = newArr[1];
  }
  return params;
}
const { activityCode, homeName, homeFlag, visitName, visitFlag, num, invitationCode, shareType } = getUrlParams();

const WorldCupGuessInvite = () => {
  const [isAgree, setIsAgree] = useState(false);
  const history = useHistory();
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState('');
  const [lastSecond, setLastSecond] = useState(0);
  const [isNcLoaded, setIsNcLoaded] = useState(false);
  const [maskShow, setMaskShow] = useState(false);
  
  const countDown = useSelector((state) => {
      return state.sms.toJS().couponCounter;
  });

  useEffect(() => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    }
    // isRegisted();
  }, []);
  
  const isRegisted = async () => {
      const result = await Services('GuMember', 'IsRegisted', {});
      if (result && result.data && result.data.msg) {
        history.replace(`/front/activities/worldCupGuess/worldCupGuessShare?shareType=1&homeName=${homeName}&homeFlag=${homeFlag}&num=${num}&visitName=${visitName}&visitFlag=${visitFlag}`);
      }
  }

  const sendSms = async () => {
    if (!Library.Util.validate.mobile(mobile)) {
      Actions.alert.show('请输入正确的手机号');
      return;
    }
    if (isNcLoaded) {
      const res = await SwiperNc();
      if (res) {
        const result = await Services('DynamicCode', 'GetDynamicCode', {
          mobile,
          ...res
        });
        if (result.status > 0) {
          Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
        }
      }
    }
  }


  const sendSmsRender = () => {
    if (lastSecond > 0) {
      return <button disabled className={styles.sms}> {lastSecond} 秒 </button>;
    } else {
      return <button onClick={sendSms} className={styles.sms}>获取</button>;
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
    }, 500)
    return () => {
      clearInterval(timer);
    }
  }, [countDown]);

  const submit = async () => {
    if (!Library.Util.validate.mobile(mobile)) {
      Actions.alert.show('请输入正确的手机号');
      return;
    }
    if (!code) {
      Actions.alert.show('请输入短信验证码');
      return;
    }
    if (!isAgree) {
      Actions.alert.show('请先同意协议');
      return;
    }
    const params = {mobile: mobile, dynamicCode: code, invitationCode, activityCode};
    const result = await Services('GuMember', (Platform.check.isH5() ? 'RegistOrLoginForH5' : 'RegistOrLogin'), params);
    if (result.status > 0) {
      history.replace(`/front/activities/worldCupGuess/worldCupGuessShare?shareType=${shareType}&homeName=${homeName}&homeFlag=${homeFlag}&num=${num}&visitName=${visitName}&visitFlag=${visitFlag}`);
    }
  };

  useEffect(() => {
    initNC();
  }, []);

  const initNC = async () => {
    import('components/txCaptcha').then(e => {
      SwiperNc = e.TxCaptcha;
      setIsNcLoaded(true);
    })
  }

  return <div className={styles.container}>
    {maskShow && <div className={styles.mask}>
      <img src={gotoBrowserImg} alt=""/>  
    </div>}
    {/* <div className={styles.headers}>
      <img src={loginBg} alt='头图'/>
    </div> */}
    <div className={styles.form}>
      <div className={styles.inputWrap}>
        <p><label>手机</label></p>
        <div className={styles.inputFlex}>
          <div className={styles.input}>
            <input 
              onChange={(event) => {
                setMobile(event.target.value);
              }} 
              type='tel' 
              value={mobile} 
              placeholder='请输入' 
              maxLength='11'
            />
          </div>
        </div>
      </div>
      <div className={styles.inputWrap}>
        <p><label>验证码</label></p>
        <div className={styles.inputFlex}>
          <div className={styles.input}>
            <input 
              onChange={(event) => {
                setCode(event.target.value);
              }} 
              type='text' 
              val={code} 
              placeholder='请输入' 
              maxLength='6'
            />
          </div>
          {sendSmsRender()}
        </div>
      </div>
      <div 
        className={styles.agreeWrap} 
        onClick={() => {
          setIsAgree(!isAgree);
        }}
      >
        <i className={`${styles.agreeIcon} ${isAgree ? '' : styles.notAgreeIcon}`}></i>
        <p>我已阅读并同意<Link to='/front/inviter/register/protocol'>《G优尊享会员服务免责条款》</Link></p>
      </div>
      <div onClick={submit} className={styles.button}>注册/登录并激活会员</div>
    </div>
  </div>;
}

export default WorldCupGuessInvite;
