import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, autoPlay } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';
import styles from '../score.module.scss'
const VirtualizeSwipeableViews = autoPlay(virtualize(SwipeableViews));
function Marquee(props) {
  if (props.lotteryRecordList && props.lotteryRecordList.length > 0) {
    function slideRenderer(params) {
      const { index, key } = params;
      const item = props.lotteryRecordList[mod(index, props.lotteryRecordList.length)];
      let message = ''
      if (item.prizeType==='1' || item.prizeType==='3') {
          message = `恭喜 ${item.loginName}抽中了${item.prizeName}`
        } else {
          message = `恭喜 ${item.loginName}抽中了${item.prizeName}积分`
      }
      if (item) {
        return <div key={key} style={Object.assign({}, styles.slide, styles.slide1)} className={styles.messageColor}>
          {message}
        </div>
      } else {
        return <></>;
      }
    }
    return  <div className={styles.swiperWrap}><VirtualizeSwipeableViews axis="y"  containerStyle={{ height: '0.65rem'}} slideRenderer={slideRenderer} /></div>
  } else {
    return false;
  }
}
export default Marquee;