import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Toast, Popup} from 'antd-mobile';
import styles from './styles.module.scss';
import {ListView} from "components";
import Library from "../../../../../library";
import Services from "../../../../../services";
import {TaskType} from "../../home";
import {isIOS} from "../../../../../library/util";

const {JsBridge} = Library;


const TaskRecord = ({onClose, visible}) => {
    const [list, setList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [_visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible) {
            loadData();
            setVisible(true)
        }
    }, [visible]);

    const loadData = async () => {
        setIsLoad(true);
        let res = await Services('MainPage', {code: 600852}, {});
        setIsLoad(false)
        console.log('600852---', res);
        const {data = [], status} = res;
        if (status > 0) {
            for (const item of data) {
                if (item.taskType === TaskType.SIGN_IN) {//签到
                    item.id = 0;
                    item.imagePath = require('../../imgs/icon_qd.png');
                    item.btnTitle = item.status ? '已签到' : '签到';
                } else if (item.taskType === TaskType.BUY_GOODS) {//购买商品
                    item.id = 1;
                    item.imagePath = require('../../imgs/icon_sp.png');
                    item.status = false;
                    item.btnTitle = item.status ? '已购买' : '去购买';
                } else if (item.taskType === TaskType.STEP_NUMBER) {//步数
                    item.id = 2;
                    item.imagePath = require('../../imgs/icon_bs.png');
                    if (isIOS()) {
                        item.btnTitle = item.status ? '已开启' : '去设置';
                    } else {
                        item.btnTitle = item.status ? '已领取' : '去获取';
                    }

                    // if (isIOS()) {
                    //     let res = await JsBridge.isAuthHealthPermission('');
                    //     if (res.isAuth) {
                    //         item.status = true;
                    //         item.btnTitle = '已开启';
                    //     } else {
                    //         item.status = false;
                    //         item.btnTitle = '去设置';
                    //     }
                    // } else {
                    //     // item.status = false;
                    //     item.btnTitle = item.status ? '已开启' : '去领取';
                    // }
                } else if (item.taskType === TaskType.META_VISIT) {//元宇宙访问时长
                    item.id = 3;
                    item.imagePath = require('../../imgs/icon_meta.png');
                    item.btnTitle = item.status ? '已完成' : '去浏览';
                } else if (item.taskType === TaskType.TRADE_IN) {//以旧换新
                    item.id = 4;
                    item.imagePath = require('../../imgs/icon_nft.png');
                    item.btnTitle = item.status ? '已完成' : '去换新';
                }
            }
            setList([...data])
        }
    }

    const onCancel = () => {
        setVisible(false);
        setTimeout(onClose, 1000);
    }

    const openWXMini = () => { //打开微信小程序
        let params = {
            appid: '',
            userName: 'gh_c937730c5540',
            path: 'pages/activitys/stepCount/index',
            isRelease: (process.env.REACT_APP_MALL_ENV === 'pro') ? 'true' : 'false',
        };
        JsBridge.launchMiniApp(params);
    }

    const itemClick = async (item) => {
        const {taskType, status} = item;
        if (status) {
            return;
        }
        onCancel();
        if (taskType === TaskType.SIGN_IN) {//签到
            // JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/activities/dailySignin/index`});
        } else if (taskType === TaskType.BUY_GOODS) {//购买商品
            JsBridge.push('Web', {uri: `${window.location.origin}/front/activities/enviroPro`});
        } else if (taskType === TaskType.STEP_NUMBER) {//步数
            if (isIOS()) {
                let user = await JsBridge.getDetailCurrentUserInfo();
                console.log('user---', user.account); //特殊处理账户
                if (user && user.account === '13164690896') {
                    openWXMini();
                    return;
                }
                let res = await JsBridge.isAuthHealthPermission('');
                if (res.isAuth) {
                    getUserStep().then();
                } else {
                    JsBridge.openAppSettings();
                }
            } else {
                openWXMini();
            }
        } else if (taskType === TaskType.META_VISIT) {//元宇宙访问时长
            JsBridge.startUnity3d({
                sceneName: 'shop'
            });
        } else if (taskType === TaskType.TRADE_IN) {//以旧换新

        }
    }

    const getUserStep = async () => {
        const date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        let time = `${year}-${month}-${day} 00:00:00`;
        console.log('date---', date)
        let res = await JsBridge.getUserStepCount({startTime: time});
        if (res.status > 0) {
            Toast.show('获取成功!')
            console.log('step---', res.step)
        }
    }

    const RenderItem = (item) => {
        const {taskName, imagePath, taskDetails, status, btnTitle} = item;
        return <div className={styles.variation_item} onClick={() => itemClick(item)}>
            <img src={imagePath} alt=""/>
            <div className={styles.des_container}>
                <p>{taskName}</p>
                <p>{taskDetails}</p>
            </div>
            <p className={`${styles.setting} ${status ? styles.disable : ''}`}>{btnTitle}</p>
        </div>
    }

    return <Popup
        visible={_visible}
        onMaskClick={onCancel}
        bodyStyle={{
            borderRadius: "9px 9px 0 0",
            background: 'linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%)',
            backdropFilter: 'blur(11px)',
            boxShadow: 'inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42)',
            overflow: 'hidden',
        }}
    >
        <div className={styles.container}>
            <div className={styles.task_title}>
                <span>做任务 得更多碳积分</span>
            </div>
            <img src={require('../../imgs/icon_del.png')} alt="" className={styles.close_img}
                 onClick={onCancel}/>
            <div className={styles.task_list}>
                <ListView
                    dataSource={list}
                    renderItem={RenderItem}
                    hasMore={false}
                    onRefresh={() => loadData()}
                    style={{paddingTop: 60}}
                    isLoading={isLoad}
                />
            </div>
        </div>
    </Popup>
}

TaskRecord.open = () => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<TaskRecord
        onClose={close}
        visible
    />, div);
}

export default TaskRecord;
