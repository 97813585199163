import React, {useEffect, useState, useRef} from 'react';
import './style.scss';
import Library from 'library';
import Services from 'services';
import Header from 'components/header';
import lifeImg from './imgs/life.png';
import telImg from './imgs/tel-bill.png';
import takeoutFoodImg from './imgs/take-out-food.png';
import iconTip from './imgs/icon-tip.png';
import lifeUse from './imgs/icon_shenghuojiaofei.png';
import telUse from './imgs/icon_shoujichonzhi.png';
import foodUse from './imgs/icn_waimai.png';
import ShareModal from './activityShareModal';
import offlinePay from './imgs/icon_maidan.png';
import offlineUse from './imgs/icon_buzhou.png';
import { PullToRefresh } from 'antd-mobile';
const {JsBridge, Util} = Library;

const pageMap = {
  'yflshjf': {
    'topIcon': lifeImg,
    'title': '生活缴费',
    'btnText': '立即缴纳',
    'useMethodImg': lifeUse,
  },
  'yflhfcz': {
    'topIcon': telImg,
    'title': '话费充值',
    'btnText': '立即充值',
    'useMethodImg': telUse,
  },
  'yflwm': {
    'topIcon': takeoutFoodImg,
    'title': '外卖点餐',
    'btnText': '立即点餐',
    'useMethodImg': foodUse,
  },
  'yflshjfwl': {
    'topIcon': lifeImg,
    'title': '生活缴费',
    'btnText': '立即缴纳',
    'useMethodImg': lifeUse,
  },
  'yflhfczwl': {
    'topIcon': telImg,
    'title': '话费充值',
    'btnText': '立即充值',
    'useMethodImg': telUse,
  },
  'yflwmwl': {
    'topIcon': takeoutFoodImg,
    'title': '外卖点餐',
    'btnText': '立即点餐',
    'useMethodImg': foodUse,
  },
  'yflxxmdwl': {
    'topIcon': offlinePay,
    'title': '线下买单',
    'btnText': '立即使用',
    'useMethodImg': offlineUse,
  }
}

const Yifuli = () => {
  const {type=''} = Util.url.paramsToObj();
  const [tipShow, setTipShow] = useState(false);
  const [shareModalVisible, setShareMoalVisible] = useState(false);
  const [inviteInfo, setInviteInfo] = useState({});
  const [pageStatus, setPageStatus] = useState('init');

  useEffect(() => {
    getInviteInfo();
    onDidFocus();
  }, [])

  const getInviteInfo = async () => {
    const result = await Services('MainPage', { code: '600045' }, {activityCode: type});
    setPageStatus('finish');
    JsBridge.print(result);
    if(result.status > 0) {
      setInviteInfo(result.data);
    }
  }

  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
      getInviteInfo();
      onDidFocus();
    });
  }

  const myNavigate = (web, url) => {
    JsBridge.push(web, {uri: `${window.location.origin}${url}`});
  }

  const getPageContent = () => {
    return pageMap[type];
  }
  
  const { topIcon, title, btnText, useMethodImg } = getPageContent() || {};
  const isActivityEnd = Date.parse(inviteInfo?.currentTime?.replace(/-/g, '/')) >= Date.parse(inviteInfo?.endTime?.replace(/-/g, '/'));
  const isActivityStart = Date.parse(inviteInfo?.currentTime?.replace(/-/g, '/')) >= Date.parse(inviteInfo?.startTime?.replace(/-/g, '/'));
  
  const btnClick = () => {
    if(inviteInfo?.isWhiteList) {
      if(inviteInfo?.beyondLimit) return;
      JsBridge.push('CustomWeb', {uri: inviteInfo?.activityRule?.codeName});
      return;
    }
    if(!isActivityStart) return;
    if(isActivityEnd) {
      return;
    } else {
      if(inviteInfo.invitedCount < inviteInfo.taskCount) {
        setShareMoalVisible(true);
      } else {
        if(inviteInfo?.beyondLimit) return;
        JsBridge.push('CustomWeb', {uri: inviteInfo.jumpUrl});
      }
    }
  }

  if(pageStatus !== 'finish') return null;

  return <div className='yifuli-page'>
    <Header 
      midText={title} 
      rightText={inviteInfo?.isWhiteList ? '': '邀新记录'} 
      rightClick={() => myNavigate('Web', `/front/activities/yifuli/inviteRecord?type=${type}`)}
    />
    <PullToRefresh 
      onRefresh={getInviteInfo}
      headHeight={70}
      threshold={100}
      pullingText={
        <img 
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
        />
      }
      refreshingText={
        <img
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
        />
      }
      canReleaseText={
        <img
          src={require('../../../images/queshengye/new-loading.gif')}
          style={{height: 50}} alt=''
          />
        }
      renderText={null}
    >
      <div className='yifuli-content'>
        <div className="yifuli-top">
          <img className='top-img' src={topIcon} alt="" />
          <div className='yifuli-desc' onClick={() => setTipShow((pre) => !pre)}>
            <span>{title}，积分当钱花</span>
            <img src={iconTip} alt=""/>
            <div className={`desc-content ${tipShow ? 'show': 'hide'}`}>{inviteInfo?.activityRule?.codeAlias}</div>
          </div>
        </div>
        {!inviteInfo?.isWhiteList ? <div>
          <div 
            className={`yifuli-btn ${isActivityStart ? isActivityEnd ? 'gray-btn': inviteInfo.invitedCount < inviteInfo.taskCount ? '': inviteInfo.beyondLimit ? 'gray-btn': '': 'gray-btn'}`} 
            onClick={btnClick}
          >
            <p>{isActivityStart ? isActivityEnd ? '活动结束': inviteInfo.invitedCount < inviteInfo.taskCount ? `立即邀新 开通/续用服务`: inviteInfo.beyondLimit ? '当日余额已不足，请明日再来': btnText: '活动未开始'}</p>
            {isActivityStart && !isActivityEnd && inviteInfo.invitedCount < inviteInfo.taskCount ? <p>您还需邀新{inviteInfo.taskCount-inviteInfo.invitedCount}人 即可使用服务</p>: null}
            {!isActivityEnd && (inviteInfo.invitedCount >= inviteInfo.taskCount) && !inviteInfo.beyondLimit && <CountDownComponent 
              sysTime={inviteInfo.currentTime} 
              endTime={inviteInfo.endTime} 
              des='剩余使用时间'
              refreshData={getInviteInfo}
            />}
          </div>
          <ShareModal 
            maskVisible={shareModalVisible} 
            closeMask={() => setShareMoalVisible(false)} 
            activityCode={type}
            inviteCode={inviteInfo.inviteCode}
          />
          {/* {inviteInfo.invitedCount < inviteInfo.taskCount && !isInviteEnd ? <CountDownComponent 
            sysTime={inviteInfo.currentTime} 
            endTime={inviteInfo.inviteEndTime} 
            des='剩余开通时间'
            refreshData={getInviteInfo}
          />: null} */}
        </div>: <div 
            className={`yifuli-btn bai-ming-dan ${inviteInfo.beyondLimit ? 'gray-btn': ''}`}
            onClick={btnClick}
          >
            <p>{inviteInfo.beyondLimit ? '当日余额已不足，请明日再来': btnText}</p>
          </div>}
        <div className="use-methods">
          <span>怎么操作{title?.slice(2)}</span>
          <img src={useMethodImg} alt="" />
        </div>
      </div>
      <div className="yifuli-foot">
        <span>其他注意事项</span>
        <div className="rules-content">
          {inviteInfo?.activityRule?.otherSign}
        </div>
      </div>
    </PullToRefresh>
  </div>
}

//倒计时
const CountDownComponent = ({sysTime, endTime, des, refreshData}) => {
  const initalTime = {day: '00', hour: '00', minute: '00', second: '00'};
  const [countTime, setCountTime] = useState(initalTime);
  const timeStampCurrent = useRef(null);
  const timerCurrent = useRef(null);

  const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
  }

  const countDown = (currentTime, endTime, callBack) => {
    if (!currentTime || !endTime) {
      callBack && callBack()
      return initalTime;
    }
    const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      callBack && callBack()
      return initalTime;
    }
    let remianAllSeconds = Math.floor(distanceStamp / 1000);
    let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
    let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);
    let minute = Math.floor(remianAllSeconds / 60 % 60);
    let second = Math.floor(remianAllSeconds % 60);

    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;
    return {day: day, hour: hour, minute: minute, second: second};
  }

  const timerStart = () => {
    timerCurrent.current && clearInterval(timerCurrent.current);
    timerCurrent.current = setInterval(() => {
      if (timeStampCurrent.current) {
        timeStampCurrent.current = timeStampCurrent.current + 1000;
      }
      let obj = countDown(sysTime, endTime, () => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        refreshData && refreshData();
      });
      setCountTime(obj);
    }, 1000)
  }

  const visibilitychange = () => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        clearAllTimer();
      } else {
        refreshData && refreshData();
      }
    });
  }

  useEffect(() => {
    visibilitychange();
  }, [])

  useEffect(() => {
    timerStart();
    return () => {
      timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
    }
  }, [sysTime, endTime])
  const {day, hour, minute, second} = countTime;
  return (
    <div className={des === '剩余使用时间'? 'color-style': 'count-down '}>
      <span>{des}</span>
      <span>{day}</span>
      <span>天</span>
      <span>{hour}</span>
      <span>时</span>
      <span>{minute}</span>
      <span>分</span>
      <span>{second}</span>
      <span>秒</span>
    </div>
  )
}

export default Yifuli;