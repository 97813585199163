/**
 * @description 模态弹框组件，从底部弹出列表
*/
import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Toast, Popup} from 'antd-mobile';
import Service from '../../nft-official/service';
import styles from './styles.module.scss';
import {ListView} from "components";

const mockData = {
    status:1,
    data: {
        inviteCount: 20,
        successCount: 20,
        list: [
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请购买成功',
                mobile: '13236510099',
                imagePath: '',
                reason: '',
            },
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请失败',
                mobile: '13236510099',
                imagePath: '',
                reason: '被邀请人设别重复登录',
            },{
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请购买成功',
                mobile: '13236510099',
                imagePath: '',
                reason: '',
            },
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请失败',
                mobile: '13236510099',
                imagePath: '',
                reason: '被邀请人设别重复登录',
            },{
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请购买成功',
                mobile: '13236510099',
                imagePath: '',
                reason: '',
            },
            {
                createTime: '2023-03-20 09:00:00',
                inviteStatus: '邀请失败',
                mobile: '13236510099',
                imagePath: '',
                reason: '被邀请人设别重复登录',
            }
        ]
    }
}

const mockFetch = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(mockData);
        }, 2000);
    })
}

const PopupModal = ({onClose, visible}) => {
    const [hasMore, setHasMore] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [successCount, setSuccessCount] = useState(0);
    const [isLoad, setIsLoad] = useState(false);
    const [_visible, setVisible] = useState(false);
    const currentPage = useRef(1);
    const pageSize = useRef(10);

    useEffect(() => {
        if (visible) {
            getRecordList();
            setVisible(true)
        } else {
            onCancel();
        }
    }, [visible]);

    const getRecordList = async (isRefresh = true, isLoading = true) => {
        try {
            currentPage.current = isRefresh ? 1 : currentPage.current + 1;
            const params = {
                pageNum: currentPage.current,
            }
            setIsLoad(true);
            // const res = await mockFetch(params)
            const res = await Service.getInviteList(params);
            setIsLoad(false);
            if(res.data) {
                if (currentPage.current > 1) {
                    setRecordList([...recordList, ...res.data.list]);
                } else {
                    setRecordList(res.data.list);
                }
                setSuccessCount(res.data.successCount)
                setHasMore(res.data.list.length >= pageSize.current);
            }
            
        } catch (error) {
            Toast.show(error);
        }
    }

    const onCancel = () => {
        setVisible(false);
        setTimeout(onClose, 1000);
    }

    const RenderItem = ({mobile, createTime, inviteStatus, reason, point}) => {
        return <div className={styles.variation_item} onClick={()=>{}}>
            <div className={styles.item_left}>
                <span>{mobile}</span>
                <span>{createTime}</span>
            </div>
            <div className={styles.item_right}>
                <span>{inviteStatus}</span>
                {(point && point > 0) ? <span>积分+{point}</span> : (reason ? <span>{reason}</span> : null)}
            </div>
        </div>
    }

    return <Popup
        visible={_visible}
        onMaskClick={onCancel}
        bodyStyle={{
            borderRadius: "9px 9px 0 0",
            background: 'linear-gradient(270deg, rgba(52,51,65,0.76) 0%, rgba(52,51,65,0.69) 100%)',
            backdropFilter: 'blur(11px)',
            boxShadow: 'inset 0px 0px 10px 0px rgba(107, 33, 66, 0.42)',
            overflow:'hidden',
        }}
    >
        <div className={styles.container}>
            <div className={styles.invite_title}>
                <span>邀新记录</span>
                <span>{`——已成功邀新${successCount}个——`}</span>
            </div>
            <div className={styles.variation_invite_list}>
                <ListView
                    dataSource={recordList}
                    renderItem={RenderItem}
                    hasMore={hasMore}
                    onRefresh={() => getRecordList(true, false)}
                    onEndReached={() => getRecordList(false, false)}
                    style={{paddingTop: 60}}
                    isLoading={isLoad}
                />
            </div>
        </div>
    </Popup>
}

PopupModal.open = () => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => div.remove();
    ReactDOM.render(<PopupModal
        onClose={close}
        visible
    />, div);
}

export default PopupModal;
