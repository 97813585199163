/**
 * @description 扫码领取限量版土地，活动结束后也会显示该界面
 * @returns route: /front/activities/claimLand?type=ac
*/

import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { Header, RulesModal, ModalAlert } from 'components';
import Library from 'library';
import Service from '../service';
import Styles from './index.module.scss';

const { JsBridge } = Library;

export const ClaimLand = () => {

    const [data, setData] = useState({});
    const [activityEnd, setActivityEnd] = useState(null);
    const PageRef = React.useRef(null);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async (loading=true)=> {
        const res = await Service.getInitData(loading);
        if(res) {
            const activityInfo = res.data;
            setData(activityInfo);
            const isEnd = new Date(activityInfo?.systemTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.activityEndTime?.replace(/-/g, '/')).getTime();
            setActivityEnd(isEnd >= 0);
        }
    }

    // 活动规则
    const onRuleClick = () => {
        RulesModal.open({
            btnStyle: { background: 'linear-gradient(180deg, #FF9292 0%, #B83838 50%, #D45757 100%)', 'borderRadius': '15px' },
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }

    // 立即领取
    const onReceiveLand = async () => {
        const isAuth = await authRealNameCheck();
        if(isAuth) {
            const res = await Service.receiveLand();
            if(res?.data?.nftNo) {
                setData({...data, claimStatus: true})
            }
        }
    }

    // 实名认证
    const authRealNameCheck = async () => {
        const userInfo0 = await JsBridge.getCurrentUserInfo();
        if(!(userInfo0 && userInfo0.clientId) && process.env.NODE_ENV !== 'development') {
            JsBridge.goLogin();
            return false;
        }
        const userInfo = await JsBridge.getDetailCurrentUserInfo();
        if(userInfo?.ID) {
            return true
        }
        const content = (
            <div className={Styles.realNameCheck}>
                <div className={Styles.contentBg}>
                    <div className={Styles.contentBox}>
                        <p>实名认证</p>
                        <span>{`领取土地需实名认证 \n 请前往实名`}</span>
                        <div className={Styles.btns}>
                            <div className={Styles.btnBox1} onClick={()=>{
                                ModalAlert.hide();
                            }}>
                                <div className={Styles.btn}>
                                    <span>{'取消'}</span>
                                </div>
                            </div>
                            <div className={Styles.btnBox2} onClick={()=>{
                                JsBridge.navigate('Verify');
                                ModalAlert.hide();
                            }}>
                                <div className={Styles.btn}>
                                    <span>{'去实名'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        
        ModalAlert.show({
            getContainer: PageRef.current,
            maskStyle: { background: 'rgba(0, 0, 0, 0.8)' },
            content: content,
        })
        return false;
    }

    // 去查看
    const onGoCheck = () => {
        const uri = `${process.env.REACT_APP_NFT_LINK}/myCollection?initalTab=1`;
        JsBridge.push('CustomWeb', { uri: uri });
    }

    return (
        <div className={Styles.claimLandPage} ref={PageRef}>
            {JsBridge.hasWebViewBridge() && <Header bgColor={'transparent'} theme={'light'} />}
            <div className={Styles.claimLandPage__wraper}>
                <div className={Styles.bgTop}>
                    <div onClick={onRuleClick}><span>活动规则</span></div>
                </div>
                <div className={Styles.content}>
                    <img className={Styles.content__title} src={require('images/claimLand/title.png')} draggable={false}/>
                    {
                        data?.claimStatus == true ?
                            <div className={Styles.landFinish}>
                                <div className={Styles.circle_tick}>
                                    <img src={require('images/claimLand/tick.png')} draggable={false} alt="" />
                                </div>
                                <p>恭喜您！领取成功！</p>
                                <span>您的土地请在“我的数字资产”里查看</span>
                            </div> :
                            <div className={Styles.landBox}>
                                <img src={require('images/claimLand/land.png')} draggable={false} alt="" />
                            </div>
                    }
                    {
                        activityEnd ?
                        <div className={Styles.activity_end}>
                            <span>{'当前活动已结束'}</span>
                        </div>:
                        activityEnd == false ?
                            <>
                                {
                                    data.claimStatus == true ?
                                        <img className={Styles.receive_btn} src={require('images/claimLand/btn_check.png')} draggable={false} onClick={onGoCheck} /> :
                                        <img className={Styles.receive_btn} src={require('images/claimLand/btn_now.png')} draggable={false} onClick={onReceiveLand} />
                                }
                            </>
                            :null
                            
                    }
                </div>
            </div>
        </div>
    )
}