const themes = [
    {
      title: '橘黄',
      color: 'rgb(22,22,2)',
      bgColor: 'rgba(255, 208, 157, 1)'
    },
    {
      title: '青绿',
      color: 'rgb(22,22,2)',
      bgColor: 'rgba(255, 208, 157, 1)'
    },
    {
        title: '淡紫色',
        color: 'rgb(22,22,2)',
        bgColor: '#C8CCF4'
    },
    {
        title: '浅粉色',
        color: '#333333',
        bgColor: '#F7CCF7'
    },
    {
        title: '深黄色',
        color: '#333333',
        bgColor: '#FFEB9D'
    },
    /*
    {
      title: '蔚蓝',
      color: 'rgb(22,22,2)',
      bgColor: 'rgba(112,243,255,.9)'
    },
    {
      title: '紫色',
      color: 'rgb(233, 241, 246)',
      bgColor: 'rgba(141,75,187,.8)'
    },
    {
      title: '粉色',
      color: 'rgb(22,22,2)',
      bgColor: 'rgba(255,179,167,.8)'
    },
    {
      title: '胭脂色',
      color: 'rgb(233, 241, 246)',
      bgColor: 'rgba(157,41,51,.8)'
    },
    {
      title: '彤色',
      color: 'rgb(233, 241, 246)',
      bgColor: 'rgba(243,83,54,.8)'
    },
    {
      title: '殷红',
      color: 'rgb(233, 241, 246)',
      bgColor: 'rgba(190,0,47,.8)'
    }*/
  ];
  
  const animateFuns = {
    linear: {
      title: '匀速'
    },
    ease: {
      title: '慢-快-慢'
    },
    'ease-out': {
      title: '快-慢'
    },
    'ease-in': {
      title: '慢-快'
    },
    'cubic-bezier(0.2,0.4,0.8,2)': {
      title: 'cubic-bezier'
    },
    'steps(10, end)': {
      title: '步进'
    }
  };
  const getRandomAniFun = () => {
    const keys = Object.keys(animateFuns);
    let tmpKey = keys[Math.floor(Math.random() * keys.length)];
    return tmpKey;
  };

  const getRandomTheme = () => {
    const theme = themes[Math.floor(Math.random() * themes.length)]
    return {backgroundColor: theme.bgColor, color: theme.color };
  };

  const getTextSize = ()=> {
    const sizes = ['9px', '11px', '14px', '17px', '20px']
    const size = sizes[Math.floor(Math.random() * sizes.length)]
    return size
  }

  export { getRandomTheme, getRandomAniFun, getTextSize };

