/**
 * @description 父亲节相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';


const activityCode = 'fathersDay'
class Service {

    /**
     * @description 父亲节初始化接口
     * @interface 101327
     * @param {activityCode: 活动编码} params
     * @param { 是否显示加载loading } showLoading
     * @returns {*}
    */
    static getInitData = async (showLoading=true)=> {
        const code = '101327'
        try {
            const params = {
                activityCode: activityCode
            }
            const res = await Services('MainPage', { code }, params, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 获取推荐商品接口
     * @interface 101342
     * @param {*} params
     * @returns
    */
    static getRecommendShopList = async (params={})=> {
        const code = '101342'
        try {
            const body = {
                activityCode: activityCode,
                ...params,
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 父亲节点赞记录接口
     * @interface 101337
     * @param { activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getThumbsUpList = async (params={})=>{
        const code = '101337'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 50,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 父亲节邀新列表接口
     * @interface 101340
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params={})=>{
        const code = '101340'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 20,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
     /**
     * @description 领取奖励接口
     * @interface 101341
     * @param {activityCode: 活动编码,type: highLevelProduct,middleLevelProduct,lowLevelProduct} params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
     static receiveGift = async (params={}, showLoading=true)=>{
        const code = '101341'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取记录接口
     * @interface 101329
     * @param {activityCode: 活动编码,} params
     * @returns
    */
    static getReceiveGiftList = async (params={})=>{
        const code = '101329'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }


    /**
     * @description 父亲节活动点赞接口
     * @interface 101338
     * @param { activityCode: 活动编码, thumbedPhone:给点赞用户的手机号 } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static postThumbsUp = async (params={}, showLoading=true)=>{
        const code = '101338'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    static getActivityRules = async (showLoading=false) => {
        try {
            const res = await Services('MainPage', { code: '101193' }, {
                activityCode: "fathersDay",
                codeType: "activityRule",
                code: "activityFathersDayRule"
              }, !showLoading);
            if (res.status === 1) {
                return res?.data?.activityRule;
            }
        } catch (error) {
            // !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
            return {
                otherSign: '活动规则获取失败'
            }
        }
    }

    static getBlessingWordsById = async (blessingId, showLoading=false) => {
        try {
            const res = await Services('MainPage', { code: '101343' }, {
                activityCode,
                blessingId,
              }, !showLoading);
            if (res.status === 1) {
                return res?.data;
            }
        } catch (error) {
            // !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    

      /**
     * @description 父亲节活动随机生成祝福语
     * @interface 101351
     * @param { activityCode: 活动编码} params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static getRandomBlessingWords = async (params={}, showLoading=false)=>{
      const code = '101351'
      try {
          const body = {
              activityCode: activityCode,
              ...params
          }
          const res = await Services('MainPage', { code }, body, !showLoading);
          if (res.status > 0) {
              return res;
          }else {
              throw res.message || '请求失败'
          }
      } catch (error) {
          !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
      }
    }

    /**
     * @description 父亲节分享祝福接口101352
     * @interface 101352
     * @param { activityCode, blessingWords } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static bindBlessing = async (params={}, showLoading=true)=>{
        const code = '101352'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
      }

}

Service.activityCode = activityCode;
export default Service;