import React, { useEffect, useState, useRef } from "react"
import { Mask, Toast } from "antd-mobile"
import html2canvas from "html2canvas"
import styles from "./cartoonShareDialog.module.scss";
import { QRCodeSVG } from "qrcode.react"

import icon_share_copy from "../../../../images/icon_share_copy.png";
import icon_share_down from "../../../../images/icon_share_down.png";
import icon_share_pyp from "../../../../images/icon_share_pyp.png";
import icon_share_wechat from "../../../../images/icon_share_wechat_1.jpg";


import bgImage from "../../../../images/cartoon_pic_fenxiang.png";
import Services from "../../../../services";
import { throttle } from "../../../../library/util";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Library from "../../../../library";

const { JsBridge, Util } = Library;
const CartoonShareDialog = ({ maskVisible, closeMask }) => {
    const domRef = useRef(null)
    const activityCode = 'CR2023';
    const [linkUrl, setLinkUrl] = useState('');


    useEffect(() => {
        maskVisible && getInviteCode();
    }, [maskVisible])

    const getInviteCode = () => {
        Services('MainPage', { code: 6000668 }, {}).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                let inviteCode = data.inviteCode;
                const linkUrlTem0 = `${process.env.REACT_APP_GKT_LINK}/front/inviter/registerCartoon/${inviteCode}?activityCode=${activityCode}`;
                setLinkUrl(linkUrlTem0)
            }
        });
    }

    const createShareImage = () => {
        return new Promise(async (resolve) => {
            html2canvas(domRef.current, {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0,
                backgroundColor: '#5909B0',
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch(() => {
                resolve(false);
            });
        })
    }
    // 生成分享图片
    const getShareImagePath = () => {
        return new Promise(async (resolve) => {
            if (!domRef.current) {
                resolve(false);
            }
            let base64 = await createShareImage();
            if (base64) {
                let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
                if (!res) {
                    JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                    resolve(false);
                } else {
                    resolve(res.tempFilePath);
                }
            } else {
                resolve(false);
            }
        })
    }

    // 微信分享
    const wxShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            let filePath = await getShareImagePath();
            window.JsBridge.share({
                text: '',
                icon: filePath,
                link: '',
                title: '',
                platform: 1,
            }).then(function (result) {
                shareToast && shareToast.close()
                Toast.show(result.message)
            })
        }
    }, 3000)
    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            let filePath = await getShareImagePath();
            window.JsBridge.share({
                text: '',
                icon: filePath,
                link: '',
                title: '',
                platform: 2,
            }).then(function (result) {
                shareToast && shareToast.close()
                Toast.show(result.message)
            })
        }
    }, 3000)

    // 将dom 转换为 base64
    const createCanvas = (dom) => {
        return new Promise((resolve, reject) => {
            html2canvas(dom, { useCORS: true }).then(
                (canvasUrl) => {
                    resolve(canvasUrl.toDataURL("image/png"))
                },
                (err) => {
                    reject(`生成海报:${err})`)
                }
            )
        })
    }

    // 下载图片
    const downloadImg = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        let filePath = await getShareImagePath();
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.requestPermission("requestAlbum")
            JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
                shareToast && shareToast.close()
                if (res) {
                    Toast.show("图片保存成功")
                } else {
                    Toast.show("图片保存失败")
                }
            })
        } else {
            let a = document.createElement("a")
            document.body.appendChild(a)
            a.download = `image-${new Date().getTime()}`
            a.href = filePath
            a.click()
            document.body.removeChild(a)
            shareToast && shareToast.close()
        }
    }, 3000)

    return (
        <Mask visible={maskVisible} onMaskClick={closeMask} opacity={0.8} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className={styles.share_container}>
                <div className={styles.share_body}>
                    <div className={styles.share_content} ref={domRef}>
                        <div className={styles.share_avatar}>
                            <img src={bgImage} alt="avatar" />
                        </div>
                        <div className={styles.share_detail}>
                            <QRCodeSVG
                                value={linkUrl}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.opt}>
                    <div className={styles.wechat} onClick={wxShare}>
                        <img src={icon_share_wechat} alt="" />
                        <span>微信好友</span>
                    </div>
                    <div className={styles.share} onClick={momentsShare}>
                        <img src={icon_share_pyp} alt="" />
                        <span>朋友圈</span>
                    </div>
                    <CopyToClipboard text={linkUrl}
                                     onCopy={async () => {
                                         await Toast.show('复制成功')
                                     }}>
                        <div className={styles.copy}>
                            <img src={icon_share_copy} alt="" />
                            <span>复制链接</span>
                        </div>
                    </CopyToClipboard>
                    <div className={styles.download} onClick={downloadImg}>
                        <img src={icon_share_down} alt="" />
                        <span>下载图片</span>
                    </div>
                </div>
                <div className={styles.cancel_btn} onClick={closeMask}>
                    <span>取消</span>
                </div>
            </div>
        </Mask>
    )
}

export default CartoonShareDialog
