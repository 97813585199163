import { GET_COUPON_SMS_COUNTDOWN, ADMIN_LOGIN_SMS_COUNTDOWN, JOIN_US_SMS_COUNTDOWN } from '../../../constants';
import * as Immutable from 'immutable';

const initialState = Immutable.fromJS({
    couponCounter: {
        'expire': null,
    },
    adminLoginCounter: {
        'expire': null
    },
    joinUsCounter: {
        'expire': null
    },
});

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function sms(state = initialState, action) {
    switch (action.type) {
        case GET_COUPON_SMS_COUNTDOWN:
            return state.merge({ couponCounter: action.data });

        case ADMIN_LOGIN_SMS_COUNTDOWN:
            return state.merge({ adminLoginCounter: action.data });

        case JOIN_US_SMS_COUNTDOWN:
            return state.merge({ joinUsCounter: action.data })
        default:
            return state;
    }
}
