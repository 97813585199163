/**
 * @description 充值卡详情
 * @returns /front/member/pointRecharge/pointCardDetail
*/

import React, {useEffect, useState, Fragment} from "react";
import { Swiper, Stepper, Toast } from 'antd-mobile';
import Library from 'library';
import { Header, ModalAlert } from 'components';
import Service from "../service";
import Styles from './index.module.scss';

const { JsBridge } = Library;

export default (props) => {
    const {id} = Library.Util.url.paramsToObj();

   const [dataSource, setDataSource] = useState([])
   
    const [cuttentIdx, setCuttentIdx] = useState(0)
    const [preBuy,setPreBuy] = useState([]);
    const pageRef = React.useRef();

    useEffect(()=>{
        loadData()
    },[])

    const loadData = async ()=> {
        if(id) {
            const res = await Service.getPointCardDetailData({pointCardConfigId: id}, true);
            if(res) {
                const list = (res.data?.list ||[]).map(item => {
                    item.sources = res.data?.priceList?.map((price, k) =>{
                        return {
                            id: item.cardId + '' + k,
                            title: res.data?.title,
                            theme: res.data?.theme,
                            stock: 10, //单个和总计限购10张
                            price: Number(price),
                            ...item
                        }
                    })
                    return item;
                })
                setDataSource(list);
            }
        }
    }

    const goBackListener = ()=> {
        JsBridge.addListener().then(() => {
            // 清空数据
            setPreBuy([]);
            loadData();
        });
    }

    const onBuyClick = async ()=> {
        const count = preBuy.reduce((pre,next)=> {
            return pre + next.count;
        },0)
        if(count > 0) {
            if(count > 10) {
                Toast.show('单次购买最多10张');
            }else {
                
                const res = await Service.payCardsCommit({cards: preBuy}, true);
                if(res) {
                    JsBridge.pay({
                        orderInfo: {
                            amount: res.data?.price,
                            ...(res.data || {}),
                            resource: 'PointRecharge',
                            //获取支付参数的接口code, 获取支付参数接口都不一样也是无语了
                            code: '600841',
                            title: '充值支付'
                        }
                    })
                    goBackListener();
                }
                
            }
            
        }else {
            Toast.show('请添加充值卡');
        }
        
    }

    const onIndexChange = (index)=> {
        setCuttentIdx(index);
    }

    const onChange = (count,item)=> {
        item.count = count;
        const index = preBuy.findIndex(i => i.id == item.id);
        if(index >= 0) {
            if(count == 0) {
                preBuy.splice(index,1);
            }else {
                preBuy[index].count = count;
            }
            
        }else {
            preBuy.push(item);
        }
        dataSource[cuttentIdx].sources[item.index] = item;
        setDataSource([...dataSource])
        setPreBuy([...preBuy]);
    }

    //协议
    const onProtcol = async (item) => {
        console.log(item);
        const rules = await Service.getRules({activityCode: item.type == 1 ? 'gkxy': 'gkzc'});
        if(rules) {
            const {otherSign} = rules.activityRule||{}
            ModalAlert.show({
                getContainer: pageRef.current,
                title: item.title,
                content: <div style={{lineHeight: '18px'}}>{otherSign}</div>,
                actions: [{ key: 'cancel', text: '确定'}],
                onAction: ()=> {
                    ModalAlert.hide()
                }
            })
        }else {
            Toast.show(`${item.title}内容获取失败`)
        }
       
        
    }

    const totalPrice = preBuy.reduce((pre,next)=> {
        return pre + next.price * next.count;
    },0)

    return (
        <div className={Styles.detail_page} ref={pageRef}>
            <Header midText={'充值卡'} bgColor={'transparent'} theme=''/>
            <div className={Styles.page_body}>
                <div className={Styles.bgTop}>
                    <Swiper slideSize={75.6}
                        stuckAtBoundary={false}
                        trackOffset={12.2}
                        loop={true}
                        defaultIndex={cuttentIdx}
                        onIndexChange={(index) => onIndexChange(index)}
                        indicator={() => null}>
                        {
                            dataSource.map((item,k)=> {
                                return (
                                    <Swiper.Item key={k}>
                                        <div className={Styles.sw_item}
                                            style={{transform: `scale(${cuttentIdx == k ? 1:0.9})`}}
                                        >
                                            <img src={item.imageUrl} alt="" />
                                        </div>
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper>
                    <div className={Styles.progressInfo}>
                        <div className={Styles.txtdesc}>
                            {(cuttentIdx + 1) + '/' + dataSource.length}
                        </div>
                        <div className={Styles.progress_out}>
                            <div style={{width: `${(cuttentIdx + 1)/dataSource.length * 100}%`}}></div>
                        </div>
                    </div>
                </div>
                <div className={Styles.card_box}>
                    <div className={Styles.title}>
                        <img src={require('images/pointRecharge/guard_left.png')} alt="" />
                        <span>充值卡金额</span>
                        <img src={require('images/pointRecharge/guard_right.png')} alt="" />
                    </div>
                    <div className={Styles.card_list}>
                        <div className={Styles.card_list_body}>
                        {
                            dataSource.length > cuttentIdx &&
                            dataSource[cuttentIdx]?.sources?.map((item,k)=> {
                                item.index = k;
                                return (
                                    <div className={Styles.list_item} key={k}>
                                        <div>
                                            <div>
                                                <img src={item.imageUrl} alt="" />
                                            </div>
                                            <div>
                                                <p>{item.title + (item.price*100) + '积分'}</p>
                                                <span>¥<span>{item.price.toFixed(2)}</span></span>
                                            </div>
                                        </div>
                                        <div>
                                            <Stepper inputReadOnly 
                                                min={0} 
                                                max={item.stock} 
                                                value={item.count||0}
                                                onChange={(count)=>onChange(count,item)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.bottom}>
                <div className={Styles.infos}>
                    <div className={Styles.price}>
                        <span>总金额:</span>
                        <span>¥</span>
                        <span>{totalPrice.toFixed(2)}</span>
                    </div>
                    <div className={Styles.button} onClick={onBuyClick}>
                        <span>立即购买</span>
                    </div>
                </div>
                <div className={Styles.protcol}>
                购买即视为阅读并同意{[{title: 'G优单用途预付卡购卡章程', type: 2}].map((item,i)=>{
                    return <Fragment key={i} >
                        <span onClick={()=>onProtcol(item)}>{`《${item.title}》`}</span>
                        { i==0 && <br/>}
                    </Fragment> 
                })}请点击仔细阅读后再购买。
                </div>
            </div>
        </div>
    )
}