import React, { useState, useEffect } from 'react'
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import { useHistory } from 'react-router-dom';
import styles from './inviter.module.scss'
const { JsBridge, Util } = Library;
let SwiperNc = null;
export const Inviter = (props) => {
    const [mobile, setMobile] = useState('');
    const [data, setData] = useState({ tickets: 0, startTime: '', endTime: '' });
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const history = useHistory();
    const { inviteCode, activityCode, clientId } = Util.url.paramsToObj();

    useEffect(() => {
        initial();
    }, [])

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };

    const initial = async () => {
        console.log(inviteCode, activityCode)
        let result = await Services('BindClient', 'Init', { inviteCode, activityCode });
        if (result.status > 0) {
            setData(result.data.result);
            initNC();
        }
    }

    const bindClient = async () => {
        if (!Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res && mobile && mobile.replace(/\s/g, '').length >= 11) {
                let result = await Services('BindClient', 'Bind', { inviteCode, activityCode, clientId, mobile, ...res });
                if (result.status > 0) {
                    console.log(result)
                    history.replace('/front/activities/mercedes/success')
                }
            }
        }
    }

    return <div className={styles.container}>
        <div className={styles.showBg}>
            <div className={styles.main}>
                {/*<div className={styles.subTitle}>演唱会VIP包间门票{data.tickets}张</div>*/}
                <div className={styles.time}>活动时间：{data.startTime} - {data.endTime} </div>
                <label className={styles.tel}>
                    <input type="tel" maxLength={11} value={mobile} className={styles.telInput} placeholder="输入手机号，为好友助力"
                        onChange={(event) => setMobile(event.currentTarget.value)} />
                </label>
            </div>
        </div>
        <div className={styles.safe}>
            <div className={styles.btnWrapper}>
                <div className={styles.inviteButton} onClick={() => {
                    bindClient();
                }}>立即助力</div>
            </div>
        </div>
    </div>


}
