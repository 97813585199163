import React, {useEffect, useRef, useState} from 'react';
import styles from './inviteRecord.module.scss';
import Library from "../../../library";
import {Swiper} from "antd-mobile";
import clickThrottle from "../../../library/util/clickThrottle";
import NoticeUtil from "../../../library/util/NoticeUtil";
import {InviteFriendList} from "./views/inviteFriendList";
import {MangheAwardList} from "./views/mangheAwardList";

const kClickCartoonTitle = 'kClickCartoonTitle';

const {JsBridge} = Library;
export const InviteRecord = () => { //邀新记录

    return (
        <div className={styles.invite_record_container}>
            <ContentMyHeader/>
            <ContentMyComponent/>
        </div>
    )
}

const ContentMyHeader = (props) => {
    const [selIndex, setSelIndex] = useState(0);
    const currentIndex = useRef(0);

    const clickIndex = (index) => {
        if (!clickThrottle(1000)) {
            return
        }
        if (currentIndex.current === index) {
            return;
        }
        currentIndex.current = index;
        setSelIndex(index);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            NoticeUtil.emit(kClickCartoonTitle, index);
        }, 100)
    }

    return (
        <div className={styles.invite_record_header}>
            <div className={styles.heder_title_com}
                 onClick={() => clickIndex(0)}>
                <div className={selIndex === 0 ? styles.header_title_sel : styles.header_title_dis}>邀新好友</div>
                <div className={styles.heder_title_line}
                     style={{visibility: selIndex === 0 ? 'visible' : 'hidden'}}/>
            </div>
            <div className={styles.heder_title_com}
                 onClick={() => clickIndex(1)}>
                <div className={selIndex === 1 ? styles.header_title_sel : styles.header_title_dis}>盲盒奖励</div>
                <div className={styles.heder_title_line}
                     style={{visibility: selIndex === 1 ? 'visible' : 'hidden'}}/>
            </div>
        </div>
    )
}


const ContentMyComponent = (props) => {
    const swiperRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        NoticeUtil.addListener(kClickCartoonTitle, addListener);
        return () => {
            NoticeUtil.removeListener(kClickCartoonTitle, addListener)
        }
    }, [])

    const addListener = (index) => {
        swiperRef.current.swipeTo(index);
        setCurrentIndex(index);
    }

    return (
        <div className={styles.cartoon_record_com}>
            <div className={styles.cartoon_record_com_ph}/>
            <Swiper
                direction='horizontal'
                indicator={() => null}
                ref={swiperRef}
                allowTouchMove={false}
                defaultIndex={0}>
                <Swiper.Item>
                    <InviteFriendList focusStatus={currentIndex === 0} initalIndex={0}/>
                </Swiper.Item>
                <Swiper.Item>
                    <MangheAwardList focusStatus={currentIndex === 1} initalIndex={1}/>
                </Swiper.Item>
            </Swiper>
        </div>
    )
}

