import React, { Component } from 'react'
import Library from 'library';
import styles from './inviter.module.scss'
export class Success extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.showBg}>
                    <div className={styles.successBg}></div>
                </div>
                <div className={styles.safe}>
                    <div className={styles.btnWrapper}>
                        <div className={styles.inviteButton} onClick={() => {
                            window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
                        }}>立即打开G优App</div>
                    </div>
                </div>
            </div>
        )
    }
}
