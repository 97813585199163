import React, {useEffect, useState} from 'react';
import styles from './onLineAppointment.module.scss';
import Library from "../../../library";
import {clickThrottle, isEmptyString, isIOS,} from "../../../library/util";
import Services from "../../../services";
import {Header} from "../../../components";
import {useHistory, useLocation} from "react-router-dom";

const {JsBridge, Util} = Library;
export const OnLineAppointment = () => { //在线预约
    const history = useHistory();
    // const location = useLocation();
    // const {param} = location.state || {};
    const {params} = Util.url.getPramasFromUrl(window.location.href);
    const {
        id = 0,
        userAccount = '',
        isHome,


        // activityName = '',
        // activityAddress = '',
        // activityRegistrationStartTime = '', //活动报名开始时间
        // activityRegistrationEndTime = '', //活动报名结束时间
        // participateTime = '', //参加时间
        // remainEnrollment = 0,//剩余报名人数
        // bringCompanionNum = 0,//可携带人数
        // carryingCompanion,//是否携带同伴  YES NO 字符串类型

    } = params;
    const [isSwitcgOn, setIsSwitcgOn] = useState(false)
    const [inputInfo, setInputInfo] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        loadData();
        if (!isIOS()) {
            JsBridge.addKeyboardEvent();
        }
    }, []);

    const loadData = async () => {

        const result = await Services('MainPage', {code: '500013'}, {customerAccount: userAccount, id: id}, true);
        if (result.status > 0) {
            let data = result.data || {};
            setData(result.data);

            if (data?.bringCompanionNum > 0) {
                let arr = [];
                for (let i = 0; i < data?.bringCompanionNum; i++) {
                    arr.push({companionName: '', companionPhone: ''});
                }
                setInputInfo(arr)
            }
        }
    }


    const timeFormat = (startTime) => {
        if (isEmptyString(startTime)) {
            return '';
        }
        let startDateString = startTime.replace(/-/g, '/');
        if (startDateString.indexOf('.') > -1) {
            startDateString = (startDateString.split('.'))[0];
        }
        let startTimeDate = new Date(startDateString);
        let M0 = (startTimeDate.getMonth() + 1) < 10 ? `0${(startTimeDate.getMonth() + 1)}` : `${(startTimeDate.getMonth() + 1)}`,
            D0 = startTimeDate.getDate() < 10 ? `0${(startTimeDate.getDate())}` : `${(startTimeDate.getDate())}`;
        let week = startTimeDate.getDay(), str;

        if (week == 0) {
            str = "星期日";
        } else if (week === 1) {
            str = "星期一";
        } else if (week === 2) {
            str = "星期二";
        } else if (week === 3) {
            str = "星期三";
        } else if (week === 4) {
            str = "星期四";
        } else if (week === 5) {
            str = "星期五";
        } else if (week === 6) {
            str = "星期六";
        }

        let time0 = `${startTimeDate.getFullYear()}年${M0}月${D0}日${str}`
        return `${time0}`
    }


    const submit = async () => {
        if (!clickThrottle()) {
            return
        }

        let companionInfoList = [];
        if (isSwitcgOn) {
            for (let i = 0; i < inputInfo.length; i++) {
                let item = inputInfo[i];
                if (!isEmptyString(item.companionName)) { //某一个的名字有值
                    if (!Library.Util.validate.mobile(item.companionPhone)) {
                        JsBridge.runAction('alert', 'error', [`请正确填写同伴${i + 1}的手机号`]);
                        return;
                    }
                }

                if (!isEmptyString(item.companionPhone)) { //填写了手机
                    if (Library.Util.validate.mobile(item.companionPhone)) { //电话有值且手机号有效
                        if (isEmptyString(item.companionName)) {
                            JsBridge.runAction('alert', 'error', [`请填写同伴${i + 1}的姓名`]);
                            return;
                        }
                    } else {
                        JsBridge.runAction('alert', 'error', [`请正确填写同伴${i + 1}的手机号`]);
                        return;
                    }
                }

                if (!isEmptyString(item.companionName) && !isEmptyString(item.companionPhone)) {
                    companionInfoList.push(item);
                }
            }
        }

        if (!isEmptyString(userAccount)) {//7789202022
            const result = await Services('MainPage', {code: '500009'}, {customerAccount: userAccount}, true);
            if (result.status > 0) {
                let customerAccount = result.data?.customerAccount || '';
                let customerName = result.data?.customerName || '';
                let financialPlannerName = result.data?.financialPlannerName || '';
                let params = {};
                params.customerAccount = customerAccount;
                params.customerName = customerName;
                params.financialPlannerName = financialPlannerName;
                params.activityId = id;
                params.activityName = data?.activityName;
                params.activityAddress = data?.activityAddress;
                params.activityRegistrationStartTime = data?.activityRegistrationStartTime;
                params.activityRegistrationEndTime = data?.activityRegistrationEndTime;
                params.participateTime = data?.participateTime;
                params.carryingCompanion = data?.carryingCompanion || 'NO';
                if (params.carryingCompanion === 'YES') {
                    if (isSwitcgOn && companionInfoList.length > 0) {
                        params.companionInfoList = companionInfoList;
                    }
                }

                const res = await Services('MainPage', {code: '500004'}, params);
                if (res.status > 0) {
                    const {appointActivityOrderId = 0} = res.data;

                    let paramTem = {
                        appointActivityOrderId,
                        userAccount,
                        isHome
                    };
                    const paramString = Util.url.objectToQueryParams(paramTem);
                    JsBridge.replace('CustomWeb', {uri: window.location.origin + `/front/member/appointment/appointmentSucceed?${paramString}`})


                    // history.push(`/front/member/appointment/appointmentSucceed`, {
                    //     appointActivityOrderId,
                    //     userAccount,
                    //     isHome
                    // });
                }
            }
        }
    }

    const changePartnerSwitch = () => {
        if (inputInfo.length === 0) {
            return
        }
        setIsSwitcgOn(!isSwitcgOn);
    }

    const partnerListView = () => {
        return inputInfo.map((item, index) => {
            return <div className={styles.online_aptmt_partner_list_container} key={index.toString()}>
                <p>同伴{index + 1}</p>
                <div className={styles.online_aptmt_partner_list_inputGroup}>
                    <label htmlFor='companionName'>同伴姓名</label>
                    <div className={styles.inputLine}>
                        <input
                            maxLength={20}
                            onChange={(event) => {
                                inputInfo[index].companionName = event.target.value;
                                setInputInfo([...inputInfo])
                            }} value={inputInfo[index].companionName} id='partnerName' type='text'
                            placeholder='请输入同伴姓名'/>
                    </div>
                </div>

                <div className={styles.online_aptmt_partner_list_inputGroup}>
                    <label htmlFor='companionPhone'>同伴手机号</label>
                    <div className={styles.inputLine}>
                        <input
                            maxLength={11}
                            onChange={(event) => {
                                inputInfo[index].companionPhone = event.target.value;
                                setInputInfo([...inputInfo])
                            }} value={inputInfo[index].companionPhone} id='partnerTel' type='tel'
                            placeholder='请输入同伴手机号'/>
                    </div>
                </div>

            </div>
        })
    }

    return (
        <div className={styles.online_aptmt_container}>
            <Header midText={'在线预约'}/>
            <p className={styles.online_aptmt_me}>{`还剩${data?.remainEnrollment || 0}个名额`}</p>
            <div className={styles.online_aptmt_time_container}>
                <p>预约时间</p>
                <p>{timeFormat(data?.participateTime)}</p>
            </div>
            {(!isEmptyString(data?.carryingCompanion) && data?.carryingCompanion === 'YES') ?
                <div className={styles.online_aptmt_partner_container}>
                    <p>携带同伴</p>
                    <div className={styles.online_aptmt_partner_right_container}
                         onClick={() => changePartnerSwitch()}>
                        <img
                            src={isSwitcgOn ? require('./images/icon_switch_on.png') : require('./images/icon_switch_off.png')}/>
                    </div>
                </div> : null}

            {
                isSwitcgOn ?
                    <div className={styles.online_aptmt_list_container}>{partnerListView()}</div> : null
            }
            <div className={styles.online_aptmt_bottom_container}>
                <div className={styles.online_aptmt_submit}
                     onClick={() => submit()}>立即预约
                </div>
            </div>
        </div>
    )
}
