import React, {useEffect, useMemo, useRef, useState} from 'react';
import Library from 'library';
import {useHistory, useParams} from 'react-router-dom';
import Services from 'services';
import styles from './disneyRaffle.module.scss';

import DrawComponent from './components/Draw'
import {Modal} from "./components/modal";
import as_confirm from "./images/as_confirm.png";
import TopNavigation from "../../member/family/topNavigation";

const { JsBridge, Platform, Hooks, Util } = Library;
export const DisneyRaffle = (props) => {
    const history = useHistory();
    const { activityCode } = useParams();
    const [showRule,setShowRule] = useState(false);
    const [rule,setRule] = useState('');
    const [data,setData] = useState({lotteryRecordList:[],remainTimes:0,endDate:'',endTime:'',startTime:'',inviteCode:''});
    const [visible,setVisible] = useState(false);
    const modalInfo = useRef({content:'恭喜您抽中迪士尼家庭贵宾票\n客服会在第一时间联系您进行门票兑换',title:'迪士尼五人（含儿童）家庭贵宾票',imgUrl:'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng638929d215df6b6d963c58237e1b504e191bb4ebe9986c02d79a5819030a65a5',type:0});
    const initData = async () =>{
        const result = await Services('MainPage', { code: 101315 }, { activityCode });
        if (result.status > 0) {
            let {endDate ='',startDate ='',...rest} = result.data;
            let startTime = Util.format.time(new Date(startDate.replace(/-/g, '/')),'yyyy.MM.dd');
            let endTime = Util.format.time(new Date(endDate.replace(/-/g, '/')),'yyyy.MM.dd');
            setData({startTime,endTime,endDate,...rest});
        }
    }
    const getRule = async () => {
        const result = await Services('MainPage', { code: 101313 }, {activityCode});
        if (result.status > 0) {
            let {otherSign=''} = result.data.rules[0];
            setRule(otherSign);
        }
    }
    useEffect(() => {
        getRule();
        initData();
        // document.body.style.overflow = 'hidden';
        return () => {
            // document.body.style.overflow = 'auto';
        }
    }, []);
    const Draw = useMemo(() => {
        return DrawComponent;
    }, []);

    const goBack = () => {
        if (Platform.check.isApp()) {
            JsBridge.back();
        } else {
            history.goBack();
        }
    }

    return <div className={styles.containerBg}>
        <TopNavigation title='抽奖迪士尼免费游' />
        <div className={styles.container} >
            <div className={styles.top}>
                <div className={styles.topBg}/>
                <div className={styles.dateBg}>
                    <div className={styles.dateText}>活动时间：{data.startTime} — {data.endTime}</div>
                </div>
                {/*<div className={styles.backBtn} onClick={goBack}>*/}
                {/*    <div className={styles.img}/>*/}
                {/*</div>*/}
                <div className={styles.ruleBg} onClick={()=>{
                    setShowRule(true)
                }}>活动规则</div>
            </div>
            <Draw
                onCallback={(res)=>{
                    modalInfo.current = res;
                    setVisible(true);
                    initData();
                }}
                number={data.remainTimes}
                lotteryRecordList={data.lotteryRecordList}
                activityCode={activityCode}
            />
            <div className={styles.btnArea}>
                <div className={styles.btn} onClick={()=>{
                    JsBridge.open('/front/activities/nft/drawNumList?activityCode='+activityCode);
                }}>获取次数记录</div>
                <div className={styles.btn} onClick={()=>{
                    JsBridge.open('/front/activities/nft/myRewards?activityCode='+activityCode);
                }}>抽奖记录</div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.btn1} onClick={()=>{
                    JsBridge.open('/front/activities/nft/assemble');
                }}/>
                <div className={styles.btn2} onClick={()=>{
                    history.push(`/front/activities/nft/${activityCode}/disneyRaffle/share?inviteCode=${data.inviteCode}`);
                }}/>
            </div>
            <div className={styles.slogan}/>
        </div>
        {showRule?<Rule rule={rule} toggle={()=>{
            setShowRule(false);
        }}/>:false}

        {visible?<Modal
            visible={visible}
            {...modalInfo.current}
            onOK={({value,type}) => {
                if(type == '1') {
                    JsBridge.open(`/front/activities/nft/editAddress?endDate=${encodeURIComponent(data.endDate)}&id=${value}&activityCode=${activityCode}`);
                } else {
                    JsBridge.open('/front/activities/nft/myRewards?activityCode='+activityCode);
                }
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}
        />:false}
        </div>
}

// 活动活动
const Rule = (props) => {
    const handleClose = () =>{
        props.toggle()
    }
    return <div className={styles.ruleContainer}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>活动规则</div>
                <div className={styles.modalContent}>
                    {props.rule}
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={handleClose}>我知道了</button>
                </div>
            </div>
        </div>
    </div>
}
