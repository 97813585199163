/**
 * @description 视频
*/
import React from "react";
import moment from 'moment';
import JsBridge from 'library/jsbridge';
import {ListView} from "components";
import { Slogon } from '../../../../slogon';
import styles from './index.module.scss';

export const VideoGrid = (props) => {
    
    const {list=[], hasMore, onRefresh, loadMore} = props;

    const onItem = (item)=> {
        console.log(item);
        JsBridge.push('FinancialLessonUnityDetail',{url: item.videoUrl,title:item.title})
    }

    return (
        <div className={styles.video_grid}>
            <ListView 
                hasMore={hasMore}
                onRefresh={onRefresh}
                loadMore={loadMore}
            >
                <div className={styles.body}>
                {
                    list.map((item,i)=> {
                        return (
                            <div className={styles.grid_item} key={i} onClick={()=>onItem(item)}>
                                <img className={styles.img} src={item.picUrl} />
                                <div className={styles.grid_info}>
                                    <p>{item.title}</p>
                                    <div>
                                        <span>{item.videoTime}</span>
                                        <span>{item.createTime ? moment(item.createTime).format('YYYY.MM.DD') : ''}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </ListView>
            
            <Slogon />
        </div>
    )
}