import UnityPointsLottery from "./home"
import ActivityRules from "./activityRules"
import FillInAddress from "./fillInAddress"
import PerformerInfo from "./performerInfo"
import SelectAddress from "./selectAddress"
import RealNamePro from "./performerInfo/protocol"
import LotteryRecords from "./lotteryRecords"
import Share from './share'

export default {
  UnityPointsLottery,
  ActivityRules,
  FillInAddress,
  PerformerInfo,
  SelectAddress,
  RealNamePro,
  LotteryRecords,
  Share,
}