/**
 * @description 发行日历/发售日历
 * @returns {route: /calendar/calendarHome}
*/
import React, {Fragment, useEffect, useState} from "react";
import styles from './calendarHome.module.scss';
import Library from "../../../library";
import ListView from "../../../components/ListView1";
import { Header } from 'components';
import egg_time_icon from '../../../images/egg_time_icon.png'
import egg_logo_image from '../../../images/egg_logo_image.png'
import egg_line from '../../../images/egg_line.png'
import Services from "../../../services";
import {isArray, isEmptyString} from "../../../library/util";

const {JsBridge} = Library;

export const CalendarHome = () => {
    const [data, setData] = useState([]);

    const [userInfo, setUserInfo] = useState({}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名

    useEffect(() => {
        getUserInfo();
        loadData();
        onDidFocus();
    }, []);

    const loadData = () => {
        Services('MainPage', {code: 6000673}, {}).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                setData(data)
            }
        });
    }
    const getUserInfo = () => { //获取用户信息
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            setUserInfo(res);
        })
    }
    const onDidFocus = () => {
        JsBridge.addListener().then((result) => {
            JsBridge.getDetailCurrentUserInfo().then((res) => {
                setUserInfo(res);
            })
        });
    }

    const getTags = (arrayString) => {
        if (isEmptyString(arrayString)) {
            return
        }
        let array = arrayString.split(',')
        if (isArray(array) && array.length) {
            return array.map((item, index) => {
                return <div className={styles.tag} key={index.toString()}>{item}</div>
            })
        }
    }

    const listItem = (item) => { //0:敬请期待1：活动中，2:活动已结束
        const btnTitle = (item)=> {
            const state = item.activityStatus;
            let btnTitle = '敬请期待';
            if (state === 0) {
                btnTitle = '敬请期待';
            } else if (state === 1) {
                btnTitle = '查看详情';
            } else if (state === 2) {
                btnTitle = '活动结束';
            }
            return btnTitle
        }
        
        const goJump = (item) => {
            if (item.activityStatus === 1) {
                // if (!userInfo.isLogin) {
                //     JsBridge.goLogin().then();
                //     JsBridge.addListener().then((result) => {
                //        getUserInfo();
                //     });
                //     return;
                // }

                let jumpUrl = item.jumpUrl;
                JsBridge.push('CustomWeb', {uri: jumpUrl});
            }
        }

        return (
            <div className={styles.item}>
                 <div className={styles.time}>{item.time + '年'}</div>
                 {
                    item.calendarVOList.map((subItem,i)=> {
                        return (
                            <div key={i} className={styles.item_box}>
                                <div className={styles.item_header}>
                                    <img src={egg_time_icon} className={styles.item_egg_time_icon}/>
                                    <div className={styles.item_time}>{subItem.activityDate}</div>
                                </div>
                                <div className={styles.item_container}>
                                    <img className={styles.item_logo} src={subItem.activityImg} alt=""/>
                                    <div className={styles.tag_father_view}>{getTags(subItem.activityTag)}</div>
                                    <div className={styles.title_0}>{subItem.activityName}</div>
                                    <div className={styles.btn_state_father} onClick={() => goJump(subItem)}>
                                        <div
                                            className={subItem?.activityStatus === 1 ? styles.btn_state0 : styles.btn_state}>{btnTitle(subItem)}</div>
                                    </div>
                                </div>
                                <img src={egg_line} className={styles.line}/>
                            </div>
                        )
                    })
                 }
            </div>
        )
    }

    return (
        <div className={styles.root_content}>
             <Header midText={'发行日历'} rightText={'我的藏品'} shadow
                rightClick={() => {
                    JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_NFT_LINK}/myCollection`});
                }}
             />
            <div>
                <ListView
                    isNeedRefresh={false}
                    data={data}
                    hasMore={false}
                    isLoad={false}
                    ListItem={({item}) => listItem(item)}
                />
            </div>
            <div className={styles.logo_father}>
                <img src={egg_logo_image} className={styles.logo}/>
            </div>
        </div>
    )
}




