import React, { Component } from 'react'
import Library from 'library';
import styles from './join.module.scss'

export class JoinSuccess extends Component {
    render() {
        return (
            <div className={styles.successContainer}>
                <div className={styles.showBg}>
                    <div className={styles.inviteButton} onClick={() => {
                        window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
                    }}>立即打开G优APP</div>
                </div>
            </div>
        )
    }
}
