import React, {useEffect, useRef, useState} from 'react';
import Library from "../../../library";
import styles from './appointmentRecord.module.scss'
import ListView1 from "../../../components/ListView1";
import Services from "../../../services";
import {isEmptyString} from "../../../library/util";
import {Modal} from "antd-mobile";
import Header from "../../../components/header";

const {JsBridge, Util} = Library;
export const AppointmentRecord = () => { //预约记录

    const {userAccount = ''} = Library.Util.url.paramsToObj();
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(0);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!isEmptyString(userAccount)) {
            loadData(true);
        }
    }, []);

    const loadData = async (isRefresh) => {
        if (isRefresh) {
            pageNum.current = 0;
        }
        let params = {
            'pageSize': pageSize,
            'pageNum': pageNum.current,
            customerAccount: userAccount || '',
        };
        const res = await Services('MainPage', {code: 500008}, params);
        if (res.status > 0) {
            const {appointActivityOrderVOPage = {}} = res.data;
            let newArr = [];
            let list = appointActivityOrderVOPage.content || [];
            if (pageNum.current === 0) {
                newArr = [...list];
            } else {
                newArr = [...data, ...list];
            }
            setData(newArr);
            setIsLoad(true);
            setHasMore(list.length >= 10)
        }
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData(false);
    }

    const cancelAptm = async (item) => {
        Modal.clear()
        const res = await Services('MainPage', {code: 500007}, {appointActivityOrderId: item.appointActivityOrderId});
        if (res.status > 0) {
            let index = -1;
            for (let i = 0; i < data.length; i++) {
                let itemTem = data[i];
                if (itemTem.appointActivityOrderId === item.appointActivityOrderId) {
                    index = i;
                    break;
                }
            }
            if (index > -1 && index < data.length) {
                data.splice(index, 1);
                setData([...data]);
            }
        }
    }

    const btnClick = (item) => {
        Modal.show({
            content: <div className={styles.dialog_container}>
                <div className={styles.dialog_title}>确认取消预约吗？</div>
                <div className={styles.dialog_column_line}/>
                <div className={styles.dialog_btns_container}>
                    <div className={styles.dialog_row_cancel_btn} onClick={() => Modal.clear()}>取消</div>
                    <div className={styles.dialog_row_line}/>
                    <div className={styles.dialog_row_cancel_btn} style={{color: '#CAA846'}}
                         onClick={() => cancelAptm(item)}>确认取消
                    </div>
                </div>
            </div>
        })
    }

    const cancelBtnView = (item) => {
        let registrationState = item.registrationState || '';
        if (registrationState === 'REGISTERED') {
            let startTime = item.activityRegistrationStartTime || '';
            let endTime = item.activityRegistrationEndTime || '';
            let today = item.today || '';
            if (!isEmptyString(startTime) && !isEmptyString(endTime) && !isEmptyString(today)) {
                let startDateTimeStamp = Date.parse(startTime.replace(/\./g, '/'))
                let endDateTimeStamp = Date.parse(endTime.replace(/\./g, '/'))
                let todayTimeStamp = Date.parse(today.replace(/\./g, '/'))
                let isInclude = todayTimeStamp >= startDateTimeStamp && todayTimeStamp <= endDateTimeStamp;
                if (isInclude) {
                    return <div className={styles.online_aptmt_record_list_item_btn}
                                onClick={() => btnClick(item)}>取消预约</div>
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
        return null;
    }

    const listItem = (item) => {
        return (
            <div className={styles.online_aptmt_record_des_container}>
                <div className={styles.online_aptmt_record_des_group}>
                    <span>预约活动：</span>
                    <span>{item.activityName}</span>
                </div>
                <div className={styles.online_aptmt_record_des_group}>
                    <span>参加时间：</span>
                    <span>{item.participateTime}</span>
                </div>
                <div className={styles.online_aptmt_record_des_group}>
                    <span>活动地址：</span>
                    <span>{item.activityAddress}</span>
                </div>
                <div className={styles.online_aptmt_record_des_group}>
                    <span>预约时间：</span>
                    <span>{item.appointTime}</span>
                </div>
                <div className={styles.line}/>

                {
                    item.companionInfoList && item.companionInfoList.map((list) => {
                        return <>
                            <div className={styles.online_aptmt_record_des_group}>
                                <span>同伴姓名：</span>
                                <span>{list.companionName}</span>
                            </div>
                            <div className={styles.online_aptmt_record_des_group}>
                                <span>同伴手机号：</span>
                                <span>{list.companionPhone}</span>
                            </div>
                        </>
                    })
                }
                {cancelBtnView(item)}
            </div>
        )
    }


    return (
        <div className={styles.aptmt_record_container}>
            <Header midText={'预约记录'}/>
            <ListView1
                onRefresh={() => loadData(true)}
                data={data}
                hasMore={hasMore}
                isLoad={isLoad}
                loadMore={loadMore}
                ListItem={({item}) => listItem(item)}
            />
        </div>
    )
}
