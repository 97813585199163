import React from 'react';
import { Link } from 'react-router-dom';
import styles from './guide.module.scss';
import headMain from './img/head-main.png';
import companyFlag from './img/company-flag.png';
import houseFlag from './img/house-flag.png';
import cashFlag from './img/cash-flag.png';

export class Guide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invitationCode: props.match.params.invitationCode || ''
        };
    }

    render() {
        const { invitationCode } = this.state;
        const linkUrl = '/front/inviter/register/' + invitationCode;
        return <div className={styles.registerGuide}>
            <div className={styles.guideHead}>
                <img className={styles.headMain} src={headMain} />
                <p className={styles.headMainTit}>好友邀请您一起理财</p>
                <p className={styles.headMainTip}>我正在用世界500强的绿地G优APP进行理财，</p>
                <p className={styles.headMainTip}>收益高安全有保障，推荐你也来使用</p>
            </div>
            <div className={styles.guideContent}>
                <div className={styles.title}>
                    <div className={styles.titleIcon}></div>
                    <div className={styles.titleText}>
                        <span className={styles.titleTextBold}>关于绿地G优</span>
                        <span className={styles.titleTextTip}>快速赚取高额回报</span>
                    </div>
                    <div className={`${styles.titleIcon} ${styles.titleIconReverse}`}></div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentItem}>
                        <img className={styles.flagIcon} src={companyFlag} />
                        <span className={styles.flagText}>世界500强名企</span>
                    </div>
                    <div className={styles.contentItem}>
                        <img className={styles.flagIcon} src={houseFlag} />
                        <span className={styles.flagText}>国资企业</span>
                    </div>
                    <div className={styles.contentItem}>
                        <img className={styles.flagIcon} src={cashFlag} />
                        <span className={styles.flagText}>投资高额回报</span>
                    </div>
                </div>
            </div>
            <Link to={linkUrl} className={styles.guideBtn}>立即注册</Link>
        </div>;
    }
}