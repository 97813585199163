import React, { useEffect, useState,useRef } from 'react';
import styles from './createModal.module.scss';

export const CreateTeam = (props)=>{
    const displayButtons = [{
        name: '取消', click: () => {
            props.onCancel && props.onCancel();
        },
        className: styles.cancel,
    },{
        name: '确定', click: () => {
            props.onOK && props.onOK();
        },
        className: styles.confirm,
    }];
    return <div className={`${styles.container} ${props.visible ? '' : styles.hide}`}>
        <div className={styles.contentBackground}>
            <div className={styles.titleBG}>
                <p className={styles.title}>创建我的战队</p>
            </div>
            <div className={styles.contentFirst}>
                <div className={styles.contentSecond}>
                    <div className={styles.contentline}>
                    </div>
                    <div className={styles.contentblank}>
                        <div className={styles.contentInvite}>
                            <div className={styles.elementFirst}>我的战队名称</div>
                            <div className={styles.elementSecond}>{props.teamName}</div>
                        </div>
                    </div>
                    <div className={styles.modalButton}>
                        {displayButtons.map((item, key) => {
                            return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
}
