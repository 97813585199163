import React from 'react';
import './worldCupPriceModal.scss';
import { Mask } from 'antd-mobile';
import closeImg from '../images/close.png';
import firstImg from '../images/first-price.png';
import secondImg from '../images/second-price.png';
import thirdImg from '../images/third-price.png';
import priceOne from '../images/price-one.png';
import priceTwo from '../images/price-two.png';
import priceThree from '../images/price-three.png';
import priceFouth from '../images/price-fouth.png';
import priceFifth from '../images/price-fifth.png';
import lastImg from '../images/price-top-tag.png';

const PriceModal = ({priceModalVisible, onClose, priceList}) => {
  if(!priceList?.length || !priceList) return null;
  const {type, name, amount, picUrl} = priceList[0] || {};

  return <Mask visible={priceModalVisible} className="world-cup-price-mask">
    <div className="world-cup-price-modal">
      <div className="price-modal-title">
        <span>奖品详情</span>
        <img src={closeImg} alt="" onClick={onClose}/>
      </div>
      <div className="price-modal-content">
        <div className="price-first">
          <div className="price-points">
            <span>{type} {amount}名</span>
            <img src={firstImg} alt="" />
          </div>
          <div className="first-price-content">
            <span>{name}</span>
            <div className='img-bg'><img src={picUrl} alt="" /></div>
          </div>
        </div>
        <div className="price-second-third">
          <div className="price-left">
            <div className="price-left-top price-points">
              <span>{priceList[1].type} {priceList[1].amount}名</span>
              <img src={secondImg} alt="" />
            </div>
            <div className="price-left-bottom">
              <span>{priceList[1].name}</span>
              {/* <span>及2022世界杯限量款配件数字藏品</span> */}
              <div className='img-bg'><img src={priceList[1].picUrl} alt="" /></div>
            </div>
          </div>
          <div className="price-right">
            <div className="price-right-top price-points">
              <span>{priceList[2].type} {priceList[2].amount}名</span>
              <img src={thirdImg} alt="" />
            </div>
            <div className="price-right-bottom">
              <span>{priceList[2].name}</span>
              {/* <span>及2022世界杯限量款配件数字藏品</span> */}
              <div className='img-bg'><img src={priceList[2].picUrl} alt="" /></div>
            </div>
          </div>
        </div>
        <div className="price-second-third fouth-fifth">
          <div className="price-left">
            <div className="price-left-top price-points price-tag">
              <span>{priceList[3].type} {priceList[3].amount}名</span>
              <img src={lastImg} alt="" />
            </div>
            <div className="price-left-bottom">
              <span>{priceList[3].name}</span>
              {/* <span>及2022世界杯限量款配件数字藏品</span> */}
              <div className='img-bg last-img-bg'><img src={priceList[3].picUrl} alt="" /></div>
            </div>
          </div>
          <div className="price-right">
            <div className="price-right-top price-points price-tag">
              <span>{priceList[4].type} {priceList[4].amount}名</span>
              <img src={lastImg} alt="" />
            </div>
            <div className="price-right-bottom">
              <span className='last-price'>{priceList[4].name}</span>
              {/* <span>及马克杯套装</span> */}
              <div className='img-bg last-img-bg'><img src={priceList[4].picUrl} alt="" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Mask>
}

export default PriceModal;