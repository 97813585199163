import React from 'react';
import styles from './index.module.scss';

export const ShouldOpenInWechat = (props) => {
    return <div className={styles.container}>
        <div className={styles.warn}>
            <i>i</i>
        </div>
        <h1>请在微信客户端打开链接</h1>
    </div>;
}