import React, { useState } from 'react';
import style from './modal.module.scss';
import Sun from './images/sun.png';

import Close from './images/close.png';
const Modal = (props) => {
    const { sonData, close } = props
    console.log(sonData);
    return <div className={sonData ? style.modal : style.none} onClick={close}>
        <div className={style.popup}>
            <div className={style.topBox}>
                <div className={style.sunbox}>
                    <img src={Sun} className={style.sun} alt="" />
                    <img src={sonData?.url} className={style.icon} alt="" />
                </div>
                <div className={style.title}>{sonData?.title}</div>
            </div>
            <div className={style.bottomBox}>
                <div className={style.text}>{sonData?.text}</div>
            </div>
            <img src={Close} className={style.close} alt="" />
        </div>
    </div>
}

export default Modal