import React from 'react';
import './currentGuessItem.scss';
import vsIcon from '../images/icon_vs.png';
import Progress from '../components/progress';

const CurrentGuessItem = ({matchInfo, joinInGuess}) => {
  const { 
      guessPoints, 
      gameResult, 
      loseRatio, 
      winRatio, 
      number, 
      homeTeamFlag, 
      round, 
      gameStartTime, 
      evenRatio, 
      homeTeamName, 
      visitingTeamFlag, 
      group, 
      visitingTeamName
    } = matchInfo || {};
  
  return <div className='current-guess-item'>
    <div className="current-field-time">
      {number > 48 ? <span>第{number}场 {group}</span>: <span>第{number}场 {group}组第{round}轮</span>}
      <span>{gameStartTime}开赛</span>
    </div>
    <div className="current-fields-info">
      <div className="left-rank">
        <img className='rank-avatar' src={homeTeamFlag} alt="" />
        <span className='rank-name'>{homeTeamName}</span>
      </div>
      <div className='battle'><img src={vsIcon} alt="" /></div>
      <div className="right-rank">
        <span className='rank-name'>{visitingTeamName}</span>
        <img className='rank-avatar' src={visitingTeamFlag} alt="" />
      </div>
    </div>
    <Progress successPercentage={winRatio} failPercentage={loseRatio} flatPercentage={evenRatio} />
    <div className={`guess-btn ${gameResult ? 'not-jion': ''}`} onClick={() => joinInGuess()}>
      {gameResult ? '已参与 查看详情': `参与竞猜 得${guessPoints}积分`}
    </div>
  </div>
}

export default CurrentGuessItem;