/**
 * @description 自定义支持图片的表单控件
*/
import React, { useState } from 'react'
import { Radio, Checkbox } from 'antd-mobile';
import './index.scss';

const RadioImg = (props) => {
    const { checkbox } = props
    return (
        <div className='antd-radio-img'>
            <img src={props.img} alt="" />
            {
                checkbox ?
                <Checkbox {...props}>
                    {props.children}
                </Checkbox>
                :
                <Radio {...props}>
                    {props.children}
                </Radio>
            }
        </div>
    )

}

export default RadioImg