/**
 * @description 元宇宙每日任务
*/

import React, { useState, useEffect } from 'react';
import Library from 'library';
import './index.scss';

const {JsBridge, Util} = Library;

const MapCofig = {
    '1': {img: require('images/pointTask/unity.png')},
    '2': {img: require('images/pointTask/nft.png')},
    '3': {img: require('images/pointTask/unity-store.png')},
    '4': {img: require('images/pointTask/unity-box.png')},
    '5': {img: require('images/pointTask/unity-game.png'),},
    '6': {img: require('images/pointTask/unity-cart.png')},
}

export default (props) => {
    const { dataSource=[
        {name: '登录元宇宙', awardNum: 1000, status: 2, type: 1, des: ''},
        {name: '元宇宙指定地点打卡', awardNum: 5000, status: 1, type: 2},
        {name: '元宇宙购买商品', awardNum: 30000, status: 0, type: 3},
    ] } = props

    const onJumpClick = (item) => {
        // 访问元宇宙
        JsBridge.startUnity3d({
            sceneName: 'shop'
        })
    }

    // if(dataSource.length === 0) return null;
    // status: 1已完成未领取 2已领取
    const completeTasks = dataSource.filter(a => a.status === 2);
    const completeRate = Math.floor(completeTasks.length / dataSource.length * 100) || 0;
    const totalPoints = dataSource.reduce((a,b)=>{ return  a + (b.awardNum ||0)},0);
    const energys = [totalPoints].reduce((pre, next)=>{
        if(next > pre) {
            return Number(next/pre) + 'w'
        }
        return next;
    },10000)

    const list = dataSource.sort((a, b) => {
        // 已完成排在最后
        return b.status == 2 ? -1 : 1;
    })
    return (
        <div className='unity-task'>
            <h1>每日任务</h1>
            <p>进度达到100%<i>可得{energys}能量（{Number(totalPoints / 100)}积分）</i></p>
            <div className={'progress'}>
                <div className={'current'} style={{ width: completeRate + '%' }}>
                    <span className={completeRate === 0 ? '' : 'styleLeft'}>{completeRate}%</span>
                </div>
            </div>
            <div className='item-list'> 
            {
                list.map((item, k)=> {
                    item.clientId = props.clientId
                    const exend = MapCofig['1']||{}
                    return (
                        <div className='item' key={k}>
                            <div className='left'>
                                <img src={exend.img} className='img'/>
                                <div>
                                    <div>{item.name}</div>
                                    <div>
                                        <span>{item.des || '登录元宇宙' }</span>
                                        <span>{`得${item.awardNum || 0}能量`}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                item.status === 2 ?
                                <div className={'right finished'}>
                                    <span>已完成</span>
                                </div>:
                                <div className={'right nomal'} onClick={()=>onJumpClick(item)}>
                                    <span>去完成</span>
                                </div>
                            }
                            
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}