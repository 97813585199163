import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './firstDayModal.module.scss';
import Library from 'library';
const { Platform, JsBridge } = Library;
export default (props) => {
    const history = useHistory();
    const location = useLocation();
    const { imageUrl } = location.state || props;


    const goback = ()=>{
        if(props.hasOwnProperty('visible')) {
            props.onClose();
        }else {
            if (Platform.check.isApp() && Platform.check.isAndroid()) {
                JsBridge.webviewGoback();
            } else {
                history.goBack();
            }
        }
        
    }

    if(props.hasOwnProperty('visible') && !props.visible) return null;

    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <img className={styles.img} src={imageUrl} mode='widthFix' alt=''/>
                </div>
                <div className={styles.modalFooter}>
                    <button className={styles.button} onClick={() => {
                        goback();
                    }}>开始签到</button>
                </div>
            </div>
            <div className={styles.close} onClick={()=>{
                goback();
            }}/>
        </div>
    </div>
}
