import React, { useEffect, useState } from 'react';
import Library from 'library';
import styles from './success.module.scss';
import successImage from 'images/icon/success.png';
import guIcon from 'images/icon/qrcode@2x.png';
import { useLocation } from 'react-router-dom';

const { Util, Hooks } = Library;
const downloadUrl = Util.WHITELIST_DOWNLOAD_URL;

export const RegisterSuccess = () => {
    const [countDown, setCountDown] = useState(null);
    const [update, forceUpdate] = useState(1);
    const location = useLocation();
    const caseId = location?.state?.caseId;

    useEffect(() => {
        initImageData();
    }, []);

    Hooks.useInterval(() => {
        if (update > 0 && (!caseId || countDown === null)) {
            return;
        }
        if (countDown - new Date().getTime() > 1000) {
            forceUpdate(update + 1);
        } else {
            window.location.replace(downloadUrl);
            forceUpdate(-1);
        }
    }, 300);

    const initImageData = async () => {
        const img1 = new Image();
        const img2 = new Image();
        img1.src = successImage;
        img2.src = guIcon;
        await Util.loadImage(img1);
        await Util.loadImage(img2);
        setCountDown(new Date().getTime() + 6000);
        window.forceUpdate = () => {
            forceUpdate(update + 1);
        };
    }
    return <div className={styles.container}>
        <div className={styles.successImage}>
            <img src={successImage} alt='成功' />
        </div>
        <h1>领取成功</h1>
        <p>恭喜您成功领取绿地G优会员卡</p>
        <p>下载绿地G优APP享会员权益</p>
        <div className={styles.qrcodeContainer}>
            <img className={styles.guIcon} src={guIcon} alt='二维码' />
            <div className={styles.content}>
                <h2>绿地G优尊享会</h2>
                <p>点击二维码保存，微信扫一扫识别后享受更多福利</p>
            </div>
        </div>
        <div className={styles.empty}></div>
        <div className={styles.safeArea}>
            <div className={styles.downloadWrap}>
                <a className={styles.download} target='_replace' href={downloadUrl}>{countDown !== null && caseId ? `${parseInt((countDown - new Date().getTime()) / 1000)}秒后自动跳转下载APP` : '下载APP'}</a>
            </div>
        </div>
    </div>;
};
