import React, {useState, useEffect, useRef} from 'react';
import Library from 'library';
import Services from 'services';
import { Toast } from 'antd-mobile';
import './style.scss';
import PriceModal from './worldCupPriceModal';
import RulesModal from './worldCupRulesModal';
import ShareModal from '../components/activityShareModal';
import SettleModal from './worldCupSettleModal';
import DoubleRulesModal from './worldCupDoubleRulesModal';
import CurrentGuessItem from './currentGuessItem';
import worldCupImgBg from '../images/shejiebei_headkv.png';
import backImg from '../images/goback.png';
import littleArrow from '../images/gray-arrow.png';
import questionMark from '../images/icon_wenhao.png';
import exchangge from '../images/pic_liucheng.png';
import circleImg from '../images/pic_di.png';
import shareImg from '../images/icon_fenxing.png';
import openSwitch from '../images/bth_icon_kai.png';
import closeSwitch from '../images/bth_icon_guan.png';
import football from '../images/pic_zuqiu.png';
import myGuess from '../images/c.png';
import { useHistory } from 'react-router-dom';
import ListView from 'components/ListView1';
import getListFn from './productList';
const { JsBridge, Util } = Library;


const WorldCupIndex = () => {
  const history = useHistory();
  const { show='' } = Util.url.paramsToObj() || {};
  const [isOpenRemind, setIsOpenRemind] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [shopTabIndex, setShopTabIndex] = useState(0);
  const [priceModalVisible, setPriceModalVisible] = useState(false);
  const [rulesModalVisible, setRulesModalVisible] = useState(false);
  const [shareModalVisible, setShareMoalVisible] = useState(false);
  const [settleModalVisible, setSettleModalVisible] = useState(false);
  const [doubleRulesModalVisible, setDoubleRulesModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [activityData, setActivityData] = useState({});
  const [shopProductsList, setShopProductsList] = useState([]);
  const [settlementInfo, setSettlementInfo] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [pointsRules, setPointsRules] = useState([]);
  const [signData, setSignData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    if(show === '1') {
      setShareMoalVisible(true);
    }
    pageInit();
    onDidFocus();
  }, []);

  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
      setShopTabIndex(0);
      pageInit();
      onDidFocus();
    });
  }

  const pageInit = () => {
    isSupportNotification();
    getUserInfo();
    getActivityData();
    getShopProductsList(true, shopTabIndex);
  }

  // 世界杯活动信息
  const getActivityData = async () => {
    const result = await Services('MainPage', { code: '101275' }, {});
    JsBridge.print(result)
    if(result.status > 0) {
      setActivityData(result.data);
      setIsOpenRemind(!!result?.data?.isOpenRemind);
    }
  }

  // 用户信息 是否登录
  const getUserInfo = () => {
    JsBridge.getDetailCurrentUserInfo().then((res) => {
      setUserInfo(res);
    });
  }

  const isSupportNotification = async () => {
    let isSupport = await JsBridge.nativeModules('SysModule', 'isSupportNotification');
    setIsEnable(isSupport);
  }

  // 开启/关闭提醒
  const onOpenRemind = async () => {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    if(isOpenRemind) {
        const result = await Services('MainPage', { code: '101276' }, { isOpenRemind: 0 })
        if (result.status > 0) {
          setIsOpenRemind(false);
          JsBridge.runAction('alert', 'error', ['已关闭竞猜提醒'])
        }
    }else {
      history.push({
        pathname: '/front/activities/worldCupGuess/worldCupIndex/noticeSetting',
        callback: (isEnable) => {
          setIsOpenRemind(isEnable);
          if (isEnable) {
            JsBridge.runAction('alert', 'error', ['已开启竞猜提醒'])
          }
        }
      });
    }
  }

  const myNavigate = (web, url) => {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    JsBridge.push(web, {uri: `${window.location.origin}${url}`});
  }

  // 商品列表
  const getShopProductsList = async (isRefresh, tabIndex) => {
    if(tabIndex === 0) {
      const res = await getListFn();
      setShopProductsList(res.data);
      return;
    }
    pageNum.current = isRefresh ? 1: pageNum.current + 1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      productType: tabIndex === 0 ? 'worldcupBuy': 'worldcupPoint',
    }
    let result = await Services('Mall', {code: '101253'}, params);
    JsBridge.print(result);
    if(result.status > 0) {
      const { productList = [], ...rest } = result.data;
      if (pageNum.current === 1) {
        setShopProductsList(productList);
      } else {
        setShopProductsList([...shopProductsList, ...productList]);
      }
      setSignData(rest);
      setIsLoad(true);
      setHasMore(productList?.length >= pageSize.current);
    }
  }

  const clickShopTab = (i) => {
    if(i === shopTabIndex) return;
    setIsLoad(true);
    setShopTabIndex(i);
    setShopProductsList([]);
    getShopProductsList(true, i);
  }

  // 结算数据
  const getSettlementInfo = async () => {
    const result = await Services('MainPage', { code: '101277' }, {});
    JsBridge.print(result);
    if(result.status > 0) {
      setSettlementInfo(result.data);
      setSettleModalVisible(true);
    }
  }

  // 立即结算
  const onSettlement = () =>  {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    if(!activityData?.isSettlable) return;
    getSettlementInfo();
  }

  const settlement = async () => {
    const result = await Services('MainPage', { code: '101278' }, {});
    if(result.status > 0) {
      setSettleModalVisible(false);
      Toast.show({content: '已结算', afterClose: () => pageInit()});
    }
  }

  // 奖品
  const getPriceData = async () => {
    const result = await Services('MainPage', { code: '101274' }, {});
    if(result.status > 0) {
      setPriceList(result.data);
      setPriceModalVisible(true);
    }
  }

  // 积分系数
  const getPointCountRules = async () => {
    const result = await Services('MainPage', { code: '101279' }, {});
    if(result.status > 0) {
      setPointsRules(result.data);
      setDoubleRulesModalVisible(true);
    }
  }

  const joinInGuess = async ({number}) => {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    const result = await Services('MainPage', { code: '101292' }, {number});
    JsBridge.print(result);
    if(result.status > 0) {
      JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/activities/worldCupGuess/guessDetail?number=${number}`});
    } else {
      pageInit();
    }
  }

  const {
      settlablePoints,
      matchGamesCounts,
      continueWinGamesCounts,
      rank,
      pointCoefficient,
      guessGamesList,
    } = activityData || {};

  return <div className='world-cup-guess'>
    <div className="world-cup-guess-bg">
      <img className='bg-img' src={worldCupImgBg} alt="" />
      <img className='exchange-img' src={exchangge} alt="" />
      <img className='circle-img' src={circleImg} alt="" />
      <div className="back-arrow" onClick={() => JsBridge.back()}>
        <img src={backImg} alt="" />
      </div>
      <div className="share-rules">
        <div className="common" onClick={() => setRulesModalVisible(true)}>活动规则</div>
        <RulesModal
          rulesModalVisible={rulesModalVisible}
          onClose={() => setRulesModalVisible(false)}
          rulesInfo={activityData?.rules}
        />
        <div className="share common" onClick={() => setShareMoalVisible(true)}>
          <img src={shareImg} alt="" />
          <span>活动分享</span>
        </div>
        <ShareModal
          maskVisible={shareModalVisible}
          closeMask={() => setShareMoalVisible(false)}
          type={0}
          isDonation={true}
          inviteCode={activityData?.inviteCode || ''}
        />
      </div>
      <div className="see-more-prizes" onClick={() => getPriceData()}>查看更多奖品</div>
      <PriceModal
        priceModalVisible={priceModalVisible}
        onClose={() => setPriceModalVisible(false)}
        priceList={priceList}
      />
    </div>
    <div className="world-cup-detail-container">
      <div className="middle-content">
        <div className="my-guess-remind">
          <img className="my-guess-img" src={myGuess} alt="" />
          {isEnable && <div className="guess-remind">
            <span className="remind-text">竞猜提醒</span>
            <img className='remind-switch' onClick={onOpenRemind} src={isOpenRemind ? openSwitch: closeSwitch} alt="" />
          </div>}
        </div>
        <div className="user-guess-info">
          <div
            className="see-ranking-box box-common-style"
            onClick={() => myNavigate('CustomWeb', '/front/activities/worldCupGuess/guessRankingList')}
          >
            <div className="ranking-top">
              <span>查看排名</span>
              <img src={littleArrow} alt="" />
            </div>
            <div className="ranking-bottom">
              <span>{!rank && rank !== 0 ? '--': rank > 100 ? '99+': rank}</span>
              <span>当前排名</span>
            </div>
          </div>
          <div
            className="see-more-box box-common-style"
            onClick={() => myNavigate('Web', '/front/activities/worldCupGuess/myGuess?currentIndex=0')}
          >
            <div className="more-top ranking-top">
              <span>竞猜记录</span>
              <img src={littleArrow} alt="" />
            </div>
            <div className="more-bottom">
              <div className="more-bottom-left">
                <span>{!matchGamesCounts && matchGamesCounts !== 0 ? '--': matchGamesCounts}<i>场</i></span>
                <span>猜中场数</span>
              </div>
              <div className="line"></div>
              <div className="more-bottom-right">
                <span>{!continueWinGamesCounts && continueWinGamesCounts !== 0 ? '--': continueWinGamesCounts}<i>连胜</i></span>
                <span>当前连胜</span>
              </div>
            </div>
          </div>
          <div className="see-guess-box box-common-style">
            <div className="guess-left">
              <div
                className="see-my-guess ranking-top"
                onClick={() => myNavigate('Web', '/front/activities/worldCupGuess/myGuess?currentIndex=1')}
              >
                <span>结算记录</span>
                <img src={littleArrow} alt="" />
              </div>
              <div className="can-get-points">
                <div className='points-count'>{!settlablePoints && settlablePoints !== 0 ? '--': settlablePoints}</div>
                <div className="points-multiple" onClick={() => getPointCountRules()}>
                  <span>当前连胜系数{pointCoefficient}</span>
                  <img className='question-mark' src={questionMark} alt="" />
                </div>
                <DoubleRulesModal
                  doubleRulesModalVisible={doubleRulesModalVisible}
                  onClose={() => setDoubleRulesModalVisible(false)}
                  pointsRules={pointsRules}
                />
              </div>
            </div>
            <div className={`guess-right ${activityData?.isSettlable ? '': 'not-settle'}`} onClick={() => onSettlement()}>
              <span>立即</span>
              <span>结算</span>
            </div>
            <SettleModal
              settleModalVisible={settleModalVisible}
              onClose={() => setSettleModalVisible(false)}
              settleInfo={settlementInfo}
              confirmSettle={settlement}
            />
          </div>
        </div>
      </div>
      <div className="current-guess">
        <div className="current-guess-title">
          <span>当前竞猜</span>
          <span>以常规时间比赛结果为准，竞猜次日10点公布结果</span>
        </div>
        {guessGamesList && guessGamesList?.length > 0 && guessGamesList.map((item, index) => {
          return <CurrentGuessItem key={`item-${index}`} matchInfo={item} joinInGuess={() => joinInGuess(item)}/>
        })}
        <div className="guess-btn-box">
          <div
            className="history-guess-btn"
            onClick={() => myNavigate('Web', '/front/activities/worldCupGuess/matchDetail?currentIndex=0')}
          >
            历史竞猜
          </div>
          <div
            className="future-match-btn"
            onClick={() => myNavigate('Web', '/front/activities/worldCupGuess/matchDetail?currentIndex=1')}
          >
            未来赛事
          </div>
        </div>
      </div>
      <div className="world-cup-shop">
        <div className="world-cup-shop-title">
          <div className="title-box">
            <img src={football} alt="" />
            <span>世界杯商店</span>
            <img src={football} alt="" />
          </div>
        </div>
        <div className="world-cup-shop-content">
          <div className="shop-tabs">
            <div className={`left-tab ${shopTabIndex === 0 ? 'active': ''}`} onClick={() => clickShopTab(0)}>看球必备</div>
            <div className={`right-tab ${shopTabIndex === 1 ? 'active': ''}`} onClick={() => clickShopTab(1)}>积分商店</div>
          </div>
          {shopTabIndex === 1 && <div className="gu-points">我的G优积分：{signData?.pointAmount}</div>}
          <div className="shop-list">
            <ListView
              onRefresh={() => getShopProductsList(true, shopTabIndex)}
              list={shopProductsList}
              hasMore={hasMore}
              isLoad={isLoad}
              loadMore={() => getShopProductsList(false, shopTabIndex)}
              ListItem={({item}) => {
                return shopTabIndex === 0 ? <ProductListLeftItem item={item} userInfo={userInfo} />: <ProductListRightItem item={item} userInfo={userInfo} />;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}

const ProductListRightItem = ({item, userInfo}) => {
  const {pointCost, url, productName, remainedNum, productType, pointsGoodsId, productId, source, merchant} = item;
  // 兑换
  const shopExchange = () => {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    if(remainedNum === 0) return;
    if(source === 'app') {
      JsBridge.push('Web', {uri: `${process.env.REACT_APP_GKT_LINK}/taro/subPages/user/mall/product/index?goodsId=${productId}&goodsType=${merchant === '1' ? 'limit' : 'hot'}&type=worldcupPoint&productType=${productType}`});
    } else {
      JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${productId}&pointsGoodsId=${pointsGoodsId}`});
    }
  }
  return <div className="shop-list-item">
    <div className='shop-img-box'><img src={url} alt="" /></div>
    <span className='product-title'>{productName}</span>
    <span className='my-gu-points'>{pointCost}积分</span>
    <span className={`exchange-btn ${remainedNum === 0 ? 'gray-btn': ''}`} onClick={() => shopExchange()}>
      {remainedNum === 0 ? '已抢光': '兑换'}
    </span>
  </div>
}

const ProductListLeftItem = ({item, userInfo}) => {
  const {marketPrice, url, productName, goodsInfoId} = item;
  // 兑换
  const immediatelyBuy = () => {
    if(!userInfo.isLogin) {
      JsBridge.goLogin().then();
      return;
    }
    JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?skuId=${goodsInfoId}`});
  }
  return <div className="shop-list-item">
    <div className='shop-img-box'><img src={url} alt="" /></div>
    <span className='product-title'>{productName}</span>
    <span className='my-gu-points'>￥{marketPrice}</span>
    <span className='exchange-btn' onClick={() => immediatelyBuy()}>
      立即购买
    </span>
    <div className="points-tag">返3倍积分</div>
  </div>
}

export default WorldCupIndex;
