import 'whatwg-fetch';
console.log(process.env.NODE_ENV);
let domain = '';

export default class Fetch {
    version;
    source;
    method;
    url;
    code;
    signature;
    smDeviceId;
    token;
    userCode;

    static headers = {};

    constructor({ version = '1.0.0', source = 'wh5', url, method = 'POST', code, smDeviceId, token='', userCode='' }) {
        this.version = version;
        this.source = source;
        this.url = url;
        this.method = method;
        this.code = code;
        this.smDeviceId = smDeviceId;
        this.token = token;
        this.userCode = userCode;
    }

    getHeaderParams(contentType = 'application/json;charset=utf-8') {
        const headers = {
            'Content-Type': contentType,
            'accept': 'application/json',
            'source': this.source,
            'version': 'wh5',
            'service': this.code,
            'smDeviceId': this.smDeviceId,
            'token': this.token,
            'userCode': this.userCode,
            ...Fetch.headers,
        };
        return headers;
    }

    param(obj, traditional) {
        var params = [];
        params.add = function(k, v) {
            this.push(escape(k) + '=' + encodeURI(v));
        };
        this.serialize(params, obj);
        return params.join('&').replace(/%20/g, '+');
    }

    serialize(params, obj, traditional, scope) {

        /**
         *
         * @param obj
         * @returns {boolean}
         */
        function isPlainObject(obj) {
            return typeof obj === 'object' && obj !== null && !(obj !== null && obj === obj.window);
        }

        let type;
        let array = Array.isArray(obj);
        let hash = isPlainObject(obj);
        let that = this;
        if (hash) {
            Object.keys(obj).forEach(function(key) {
                var value = obj[key];
                type = typeof value;
                if (scope) {
                    key = traditional
                        ? scope
                        : scope + '[' + (
                            hash || type === 'object' || type === 'array'
                                ? key
                                : '') + ']';
                }
                // handle data in serializeArray() format
                if (!scope && array) {
                    that.serialize(params, value, traditional, key);
                    params.add(value.name, value.value);
                } else if (type === 'array' || !traditional && type === 'object') {
                    that.serialize(params, value, traditional, key);
                } else {
                    params.add(key, value);
                }
            });
        }
    }

    beforeFetch() {
        console.log('start loading');
    }

    afterFetch() {
        console.log('end loading');
    }

    async fetch(data, headers = {}) {
        return this[this.method](data, headers);
    }

    async doFetch(url, data, method) {
        data.method = method;
        data.credentials = 'include';
        return await fetch(url, data).then(async (response) => {
            if (response.status.toString() === '200') {
                return { status: 1, data: await response.json() };
            } else {

                return { status: -1, msg: (await response.text() || '网络连接失败!') + response.status };
            }
        }, (error) => {
            if (error) {
                if (error.toString().indexOf('TypeError') > -1 || error.toString().indexOf('取消') > -1) {
                    return { status: -1000, msg: error || '网络连接失败' };
                }
                return { status: -2, msg: error.toString() || '网络连接失败' };
            }
        });

    }

    async get(params, headers) {
        const url = this.url + '?' + this.param(params);
        var data = {};
        data.headers = {
            ...headers, ...this.getHeaderParams()
        };
        return await this.doFetch(url, data, 'GET');
    }

    async post(data, headers) {
        data = {
            body: JSON.stringify(data)
        };
        data.headers = {
            ...headers, ...this.getHeaderParams()
        };
        return await this.doFetch(this.url, data, 'POST');
    }

    async put(data, headers) {
        data = {
            body: JSON.stringify(data)
        };
        data = this.setHeaders(data);
        data.headers = {
            'Content-Type': 'application/json',
            ...headers,
        };
        return await this.doFetch(this.url, data, 'PUT');
    }

}
