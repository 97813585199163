import React, {useEffect, useState, useRef, useCallback} from 'react';
import Library from 'library';
import styles from './drawNumList.module.scss';
import Services from 'services';

const { JsBridge, Hooks,Util } = Library;
let isLoading;
export const DrawNumList = (props) => {
    const { activityCode } = Util.url.paramsToObj();
    const [state, setState] = useState({ arrSource: [], pageNum: 1, isLoading: false, isLoadingAll: false });

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        let { clientHeight, scrollHeight, scrollTop } = pageView.current;
        if (scrollTop + clientHeight >= scrollHeight && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            getInitData()
            timer.current = setTimeout(() => {
                isDebounce = false;
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    Hooks.useEventListener('scroll', handler, pageView.current);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        getInitData(true);
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);


    const getInitData = async (refresh) => {
        setState({ ...state, isLoading: true });
        let currentPageNum = refresh ? 1 : state.pageNum + 1;
        const params = { pageNum: currentPageNum, pageSize: 10, activityCode };
        isLoading = true;
        const result = await Services('MainPage', { code: 101317 }, params);
        JsBridge.print(JSON.stringify(result));
        isLoading = false;
        if (result.status > 0) {
            let {list:arr = [],count = 1} = result.data;
            let arrSource = state.arrSource;
            if (currentPageNum === 1) {
                arrSource = arr;
            } else {
                arrSource = [...arrSource, ...arr];
            }
            setState({ ...state, arrSource: arrSource, pageNum: currentPageNum, isLoading: false, isLoadingAll: arrSource.length >= count });
        } else {
            setState({...state,isLoading:false})
        }
    }
    // const isEffect = pageData?.endDate && new Date(decodeURIComponent(pageData?.endDate).replace(/-/g, '/')).getTime() > new Date().getTime();
    return <div className={styles.container} ref={pageView}>
        {state.arrSource?.map((item, key) => {
            let isInivte = item.prizeType === '1';
            return <div key={key} className={styles.reward}>
                <div className={styles.content}>
                    <div className={styles.title}>{isInivte ? '邀请好友购买数字藏品':item.skuName} {isInivte?<a className={styles.mobile}>({item.invitedMobile})</a>:false}</div>
                    <div className={styles.date}>{item.createTime}</div>
                </div>
                <div className={styles.num}>+{item.lotteryTimes}</div>
            </div>;
        })}
    </div>
}
