import React from 'react'
import Library from 'library';
import styles from './anniversaryStatic.module.scss';
import anni_top from './images/anni_top.png'
import anni_bottom from './images/anni_static_bg_bottom.png'
export const AnniversaryStatic = () => {
  const handleBless = () => {
    window.location.href = Library.Util.APP_INVITER_DOWNLOAD_URL;
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <img src={anni_top} />
      </div>
      <div className={styles.bottom}>
        <img className={styles.bg} src={anni_bottom} />
        <div className={styles.content} onClick={handleBless}>
          <div className={styles.bts_wrap}>
            <div className={styles.bt} onClick={(handleBless)}>为绿地点赞</div>
            <div className={styles.bt} onClick={(handleBless)}>为绿地祝福</div>
          </div>
        </div>
      </div>
    </div>
  )
}
