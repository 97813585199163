import React, { useEffect, useState, useRef } from 'react';
import Library from 'library';
import styles from './FillAddressStyles.module.scss';
import Services from 'services';
import { isEmptyString } from "../../../../library/util";
import FriendReminder from "./FriendReminder";
import Header from "../../../../components/header";

const { JsBridge, Util } = Library;
export const FillAddress = (props) => {
    const { activityCode = 'NY2023', orderId, orderSource, id = '' } = Util.url.paramsToObj();
    const [enable, setEnable] = useState(false);
    const [maskVisible, setMaskVisible] = useState(false);
    const [inputInfo, setInputInfo] = useState({ receiverName: '', receiverMobile: '', address: '', location: [] });
    const inputRef = useRef(null);
    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        const { receiverName, receiverMobile, address, location } = inputInfo;
        if (!isEmptyString(receiverName) && !isEmptyString(receiverMobile) && !isEmptyString(address) && location.length > 0) {
            setEnable(true)
        } else {
            if (enable) {
                setEnable(false)
            }
        }
    }, [inputInfo]);

    const initData = async () => {
        let params = {
            // 'activityCode': 'newYear23',
            // id: '805',
            activityCode: activityCode,
            id: id,
            // userCode: '13817952661',
        };
        const res = await Services('MainPage', { code: 300022 }, params);
        if (res.status > 0) {
            let data = res.data;
            if (data) {
                setInputInfo({
                    receiverName: data.name || '',
                    receiverMobile: data.consigneeNumber || '',
                    address: data.deliveryAddress || '',
                    location: [data.proviceId || '', data.cityId || '', data.areaId || '']
                });
            } else {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    setMaskVisible(!maskVisible);
                }, 500);
            }
        }
    }

    const submit = async () => {
        if (!inputInfo.receiverName) {
            JsBridge.runAction('alert', 'error', ['请输入收货人姓名']);
            return;
        }

        if (!Library.Util.validate.mobile(inputInfo.receiverMobile)) {
            JsBridge.runAction('alert', 'error', ['请填写正确的手机号']);
            return;
        }
        if (inputInfo.location.length < 3) {
            JsBridge.runAction('alert', 'error', ['请选择所在地区']);
            return;
        }
        if (!inputInfo.address) {
            JsBridge.runAction('alert', 'error', ['请填写详情地址']);
            return;
        }

        const params = {
            id: id,
            // userCode: '13817952661',
            activityCode: activityCode,
            provice: inputInfo.location[0],
            city: inputInfo.location[1],
            area: inputInfo.location[2],
            deliveryAddress: inputInfo.address,
            consigneeNumber: inputInfo.receiverMobile,
            name: inputInfo.receiverName,
        };

        if (!isEmptyString(orderId)) {
            params.orderId = orderId;
        }
        if (!isEmptyString(orderSource)) {
            params.orderSource = orderSource;
        }
        const res = await Services('MainPage', { code: 300024 }, params);
        if (res.status > 0) {
            JsBridge.runAction('alert', 'error', ['保存成功！']);
            JsBridge.close();
        }
    }

    return <div className={styles.container}>
        <Header
            midText={`填写地址`}
            bgColor={'#fff'} />
        <div className={styles.container_content}>
            <div className={styles.inputGroup}>
                <label htmlFor='name'>收货人</label>
                <div className={styles.inputLine}>
                    <input ref={inputRef} onChange={(event) => {
                        inputInfo.receiverName = event.target.value;
                        setInputInfo({ ...inputInfo })
                    }} value={inputInfo.receiverName} id='name' type='text' placeholder='请输入收货人姓名' />
                </div>
                <div className={styles.line} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor='mobile'>手机号</label>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        inputInfo.receiverMobile = event.target.value;
                        setInputInfo({ ...inputInfo })
                    }} value={inputInfo.receiverMobile} id='mobile' type='tel' placeholder='请输入手机号' />
                </div>
                <div className={styles.line} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor='location'>所在地区</label>
                <div onClick={() => {
                    JsBridge.runAction('locationPicker', 'show', []).then(e => {
                        inputInfo.location = e;
                        setInputInfo({ ...inputInfo })
                    })
                }} className={styles.inputLine}>
                    <input readOnly value={inputInfo.location?.join(' ')} id='location' type='text'
                        placeholder='请选择所在地区'
                    />
                </div>
                <div className={styles.line} />
            </div>
            <div className={styles.inputGroup}>
                <label htmlFor='address'>详情地址</label>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        inputInfo.address = event.target.value;
                        setInputInfo({ ...inputInfo })
                    }} value={inputInfo.address} id='address' type='text' placeholder='请填写详情地址' />
                </div>
                <div className={styles.line} />
            </div>
        </div>
        <div className={styles.adress_button_father}>
            <div onClick={submit} className={enable ? styles.adress_button_enable : styles.adress_button}>填写地址 领取奖品
            </div>
        </div>
        <FriendReminder maskVisible={maskVisible} setMaskVisible={setMaskVisible} />
        {/*<ShareMask maskVisible={maskVisible} closeMask={() => setMaskVisible(!maskVisible)}/>*/}
    </div>
}
