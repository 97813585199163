import React from 'react';
import QRCode from 'qrcode.react'
import styles from './index.module.scss'
export const Qrcode = (props) => {
    const cardNo = props.match.params.codeNo
    return <div className={styles.mycard}>
        <div className={styles.qrcode}>
            <span className={styles.title}>使用时请出示此码</span>
            <QRCode
                value={cardNo.replace(/\s/g, '')}
                size={375}
                fgColor="#000000" />
            <div className={styles.cardNo}>
                <span className={styles.h1}>No.{cardNo}</span>
            </div>
        </div>
        <div className={styles.slogan}></div>
    </div>
}
