import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import Services from '../../services';

export default (props) => {
    const [init] = useState(false);
    const [coupon, setCoupon] = useState({});
    const couponCode = useQuery().get('couponCode');

    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init]);

    const getDetails = async () => {
        const result = await Services('Coupon','QueryWriteOffInfo',{ couponCode });
        const { status, data } = result;
        if (status > 0) {
            console.log('writeOffInfo', (data && data['writeOffInfo']) || {});
            setCoupon((data && data['writeOffInfo']) || {});
        }
        console.log('result', result);
        //setCoupon(result);
    };

    return <div className={styles.home}>
        <div className={styles.blockWhite}>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>券码</div>
                <div className={styles.itemTip}>{couponCode}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>消费券类型</div>
                <div className={styles.itemTip}>{coupon.couponTypeName}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>消费券价值</div>
                <div className={styles.itemTip}>{coupon.amount}{coupon.amount_type == '1' ? '元' : '%'}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>使用人姓名</div>
                <div className={styles.itemTip}>{coupon.name}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>使用人手机</div>
                <div className={styles.itemTip}>{coupon.mobile}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>备注</div>
                <div className={styles.itemTip}>{coupon.remarks}</div>
            </div>
            <div className={styles.homeItem}>
                <div className={styles.itemTitle}>核销时间</div>
                <div className={styles.itemTip}>{coupon.update_time}</div>
            </div>
        </div>
    </div>;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
