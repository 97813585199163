/**
 * @description 商品item
*/

import React from 'react';
import Styles from './index.module.scss';

const ShopItem = (props) => {
    const { item, onClick, onReceive, onShopInvite } = props;
    return (
        <div className={Styles.shop_item} onClick={()=>onClick(item)}>
            <div className={Styles.tagBox}>
                <img src={require('images/invite-zone/tag_img.png')} alt="" />
                <span >{`邀新${item.InvitationsNumTotal ?? 30}人可领`}</span>
            </div>
            <div className={Styles.img}>
                <img src={item.imgUrl} alt="" />
            </div>
            <div className={Styles.shop_info}>
                <span>{item.productName}</span>
                <span>{`已邀请：${item.inviteesNum ?? 0}/${item.InvitationsNumTotal ?? 30}`}</span>
                <span>截止时间：{item.collectTimeEnd}</span>
                {
                    item.collectionProgress == 2 ?
                    <div className={Styles.disable} onClick={(e)=>{e.stopPropagation()}}>
                        <span>{'已领取'}</span>
                    </div>:
                    item.collectionProgress == 1 ?
                    <div onClick={(e)=>{
                        e.stopPropagation();
                        onReceive && onReceive(item)
                    }}>
                        <span>{'立即领取'}</span>
                    </div>
                    :<div onClick={(e)=>{
                        e.stopPropagation();
                        onShopInvite && onShopInvite(item)
                    }}>
                        <span>{'立即邀请'}</span>
                    </div>
                }
            </div>
        </div>
    )
    
}

export default ShopItem;