/**
 * 每日签到 邀请好友
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import styles from './index.module.scss';
import { ShareImage }  from './shareImage';
import shareFriend from '../../imgs/icon_fenxiang.png';
import downLoadImg from '../../imgs/icon_xiaozai.png';
import signImg from '../../imgs/icon_qiandao.png';
import suipianImg from '../../imgs/icon_shuipian.png';

const { Util, JsBridge } = Library;
let timer;
export default (props) =>{
   const history = useHistory();
   const {state, onCallback} = props;
   const {endTime, serviceTime, inviteable, invitedList, activityCode, inviteCode, inviteImageUrl } = state || {};
   const [expireTime, setExpireTime] = useState(-1);
   const [diff,setDiff] = useState(0);
   const [visible, setVisible] = useState(false);
   useEffect(() => {
       if(serviceTime){
           setDiff( new Date().getTime() - new Date((serviceTime || '').replace(/-/g, '/')).getTime());
       }
   },[serviceTime]);

   useEffect(() => {
       clearInterval(timer);
       timer = setInterval(() => {
           const endLocalTime = new Date((endTime || '').replace(/-/g, '/')).getTime() + diff;
           const localTime = new Date().getTime();
           // console.log('endLocalTime',endTime,endLocalTime,localTime,diff,serviceTime);
           setExpireTime(endLocalTime - localTime)
            if(expireTime == 0) {
               clearInterval(timer);
               onCallback();
           }
       }, 1000);
       return () => {
           clearInterval(timer);
       }
   }, [endTime,expireTime]);

   const inviterListRender = ()=>{
       return new Array(inviteable).fill(null).map((item,key)=>{
           if(invitedList && invitedList.length<=0){
               return <div className={styles.headWrap} key={key}>
                   <div className={styles.singleWrap}>
                       <div className={styles.friendHead}></div>
                   </div>
               </div>
           }else{
               if(invitedList && (key < invitedList.length)){
                   const _item = invitedList[key];
                   return <div className={styles.headWrap} key={key}>
                       <div className={styles.headWrapItem}>
                           <img className={styles.inviteFriendHead} src={_item.imagePath} alt="" />
                           <div className={styles.tel}>{Util.format.mobileSecurity(_item.mobile || '')}</div>
                       </div>
                   </div>
               }else{
                   return  <div className={styles.headWrap} key={key}>
                       <div className={styles.headWrapItem}>
                           <div className={styles.friendHead}></div>
                           <div className={styles.tel}>虚位以待</div>
                       </div>
                   </div>;
               }
           }
       });
   }

   if (invitedList && invitedList.length >= inviteable || expireTime < 0) {
       return false;
   }
   if (expireTime === 0) {
        onCallback();
       return false;
   }
   return <div className={styles.inviteWrap}>
       <div className={styles.title}>邀好友助力  得兑换碎片</div>
       <div className={styles.subTitle}>好友首次注册并签到，每邀一人可得8个碎片</div>
       <div className={styles.friendsWrap}>
           {inviterListRender()}
       </div>
       <div className={styles.inviteBtn} onClick={() =>{
           setVisible(true);
       }}>立即邀请</div>
       <div className={styles.invalidTime}>任务距离失效：{Util.format.Countdown(expireTime)}</div>
       <div className={styles.shareProcedure}>
           <div className={styles.iconFlow}>
               <img src={shareFriend} alt='' />
               <div style={styles.dot}/>
               <img src={downLoadImg} alt='' />
               <div style={styles.dot}/>
               <img src={signImg} alt='' />
               <div style={styles.dot}/>
               <img src={suipianImg} alt='' />
           </div>
           <div className={styles.des}>
               <span>分享好友</span>
               <span>好友下载App并注册</span>
               <span>好友签到</span>
               <span>得8个碎片</span>
           </div>
           {/* <div className={styles.shareProcedureItem}>
             <img src={shareFriend} alt='' />
             <span>分享好友</span>
           </div>
           <div className={styles.shareProcedureItem}>
             <img src={downLoadImg} alt='' />
             <span>好友下载App并注册</span>
           </div>
           <div className={styles.shareProcedureItem}>
             <img src={signImg} alt='' />
             <span>好友签到</span>
           </div>
           <div className={styles.shareProcedureItem}>
             <img src={suipianImg} alt='' />
             <span>得8个碎片</span>
           </div> */}
       </div>
       <ShareImage visible={visible} 
            activityCode={activityCode} 
            inviteCode={inviteCode} 
            inviteImageUrl={inviteImageUrl}
            onClose={() => setVisible(false)}
        />
   </div>

}
