import * as Company from './company';
import CompanyDesc from './companyDesc';
import Survey from './survey/index';
export const Routes = [
    {
        path: '/front/doc/company/aboutUs',
        component: Company.AboutUs
    },
    {
        path: '/front/doc/company/help/list',
        component: Company.Help.List,
    },
    {
        path: '/front/doc/company/help/detail/:index',
        component: Company.Help.Detail,
    },
    {
        path: '/front/doc/company/privacy',
        component: Company.Privacy,
    },
    {
        path: '/front/doc/companyDesc',
        component: CompanyDesc,
        title:"关于联奇云"
    },
    {
        path: '/front/doc/integralRule',
        component: Company.IntegralRule,
        title: '积分规则'
    },
    {
        path: '/front/doc/survey',
        component: Survey,
        title: '问卷调查'
    }
];
