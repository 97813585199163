import { ADMIN_LOGIN_SMS_COUNTDOWN, GET_COUPON_SMS_COUNTDOWN, JOIN_US_SMS_COUNTDOWN } from '../../../constants';

export const sms = {
    countDownCoupon: (endTime = new Date().getTime()) => {
        const data = {};
        data['expire'] = endTime;
        return { type: GET_COUPON_SMS_COUNTDOWN, data: data }
    },
    countDownLogin: (endTime = new Date().getTime()) => {
        const data = {};
        data['expire'] = endTime;
        return { type: ADMIN_LOGIN_SMS_COUNTDOWN, data: data }
    },
    countDownJoinUs: (endTime = new Date().getTime()) => {
        const data = {};
        data['expire'] = endTime;
        return { type: JOIN_US_SMS_COUNTDOWN, data: data }
    }
};
