import pic from './images/pic.png';
import backgroundImage from './images/slice@2x.png'
export const Config = {
    'CXKTICKET': {
        backgroundImage: backgroundImage,
        pic: pic,
        rule: `1. 拼人气赢蔡徐坤演唱会贵宾厅门票，G优会员可通过参加拼人气得门票活动，在规定时间内前10名可得梅赛德斯演唱会包厢门票。
2. 拼人气活动每天可邀请助力好友数量不限，新会员可得500人气值，老会员可得100人气值，每个会员每天只能助力一人且一次（同一台设备、同一个手机、相同账号等情况均视为同一个会员）。
3. 活动结束后，我们将会对本次获得福利的用户实际助力情况进行审核，审核通过后我们会以短信的方式通知具体集合时间和地点，若发现异常，包括并不仅限于恶性机刷，或者通过插件、外挂等其他不正当手段完成助力，G优平台有权取消获得人的资格，福利名额取参与活动排名次之用户。
4. 门票仅限会员本人使用，如无法出席即为自愿放弃资格，为了避免现场接待时发生纠纷和混乱，对获奖人选会提前电话通知确认。用户根据收到的短信提供的时间和地点准时集合，验证身份后集体入场，此门票为参与活动发放，获得用户严禁私下交易，身份验证时，如出现不吻合情况，平台线下执行人员有权当场取消用户入场资格，如因违规操作门票（不仅限于私下交易）等造成无法入场（如实名制门票），后果自行承担。
5. 除门票外其它费用需用户自理。
6. 疫情原因如入场需持核酸检测报告，平台会提前以短信/电话的形式通知，用户需自行做好核酸检测并持有效报告入场，费用自理。
7. 前10名用户请于8月6日下午15:00前完成实名认证。
小优友情提醒：参加活动时，请友友们带好口罩，疫情期间做好个人防护。另，如因疫情导致活动取消或延迟，会另行通知！
`
    }

}