/*
	省市区街道 选择器
*/
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Cascader } from "antd-mobile";
import { useCascaderValueExtend } from 'antd-mobile/es/components/cascader-view/use-cascader-value-extend';
import Service from "routes/guapp/address/service";
import './index.scss';

const ProvinceKey = '000000'

const AddressSelect = (props) => {
	const { visible,onFinish, ...reset } = props

	const [valueToOptions, setValueToOptions] = useState({})
	const values = useRef()
	const pageRef = useRef()
	const cascaderRef = useRef()

	useEffect(() => {
		fetchOptionsForValue(ProvinceKey,0)
	}, [])
	useEffect(() => {
		if(visible && valueToOptions[ProvinceKey]) {
			fetchOptionsForValue(ProvinceKey,0)
		}
	}, [visible])

	const options = useMemo(() => {
		function generate(v) {
			const options = valueToOptions[v]
			if (options === null) {
				return undefined
			}
			if (options === undefined) {
				return Cascader.optionSkeleton
			}
			return options.map(option => ({
				...option,
				children: generate(option.value),
			}))
		}
		return generate(ProvinceKey) ?? []
	}, [valueToOptions])
	
	const generateValueExtend = useCascaderValueExtend(options, {valueName:'value', childrenName:'children'});

	const fetchOptionsForValue = async (v, level) => {
		if (v in valueToOptions) return;
		
		let data = null;
		if(level == 0) {
			data = await Service.getProvinceList(v)
		}else if(level == 1) {
			data = await Service.getCityList({provinceId: v})
		}else if(level == 2) {
			data = await Service.getAreaList({cityId: v})
		}else if(level == 3) {
			data = await Service.getStreetList({areaId: v})
		}
		//区为 ‘其他区’ 时街道为空数组
		if (level >= 4 || (data && data.length == 0)) {
			setValueToOptions(prev => ({
				...prev,
				[v]: null,
			}))
			const items = generateValueExtend(values.current).items
			onFinish && onFinish(items)
			cascaderRef.current.close() 
			return
		}

		const options =
		    !data
			? null
			: data.map(entry => ({
				value: entry.addrId,
				label: entry.addrName,
			}))
		setValueToOptions(prev => ({
		...prev,
		[v]: options,
		}))
	}

	return (
		<div className="address-select" ref={pageRef}>
			<Cascader
				confirmText={<span onClick={(e)=>{
					e.stopPropagation()
					cascaderRef.current.close() 
				}}>{'取消'}</span>}
				{...reset}
				getContainer={pageRef.current}
				ref={cascaderRef}
				options={options}
				onSelect={value => {
					values.current = value
					value.forEach((v, index) => {
					  fetchOptionsForValue(v, index + 1)
					})
				}}
				visible={visible}
				onClose={props.onClose}
			/>
		</div>
	)
}

export default AddressSelect;