import { Mask } from 'antd-mobile';
import React from 'react';
import styles from './rule.module.scss';

export default ({title, rule, clickCB }) => (
    <Mask visible={true}>
        <div className={styles.modal}>
            <div className={styles.modalTitle}>
                {title || `活动规则`}
            </div>
            <div className={styles.modalContent}>
                {rule || ''}
            </div>
            <div className={styles.modalFooter}>
                <div className={styles.button} onClick={clickCB}>
                    {`我知道了`}
                </div>
            </div>
        </div>
    </Mask>
)
