import React, { Component } from 'react'
import Library from 'library';
import styles from './inviter.module.scss'
const { JsBridge, Util } = Library;
export class Success extends Component {

    render() {
        const { teamStatus } = Util.url.paramsToObj();
        return (
            <div className={`${styles.container} ${teamStatus!=3?styles.background1:styles.background2}`}>
                <div className={teamStatus != 3?styles.successBg:styles.successBg2}/>
                <div className={styles.safe}>
                    <div className={styles.btnWrapper}>
                        <div className={styles.inviteButton} onClick={() => {
                            window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
                        }}>立即打开G优App</div>
                    </div>
                </div>
            </div>
        )
    }
}
