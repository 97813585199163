import React, { useEffect, useState, useRef } from 'react'

import styles from './draw.module.scss';
import Library from 'library';
import Services from 'services';
import { Actions } from "reduxs";

import gift1 from './images/gift1.png';
import gift2 from './images/gift2.png';
import gift3 from './images/gift3.png';
import gift4 from './images/gift4.png';
import gift5 from './images/gift5.png';
import gift6 from './images/gift6.png';
import gift7 from './images/gift7.png';
import gift8 from './images/gift8.png';

const { Hooks, JsBridge } = Library;
// 积分抽奖
const buttons = [
    { type: '0', img: gift1, name: '迪士尼五人（含儿童）家庭贵宾票', value: '恭喜您抽中迪士尼家庭贵宾票\n客服会在第一时间联系您进行门票兑换', step: 0 },
    { type: '1', img: gift2, name: '麦兜主题公交卡', value: '恭喜您抽中麦兜主题公交卡\n请填写收货地址，我们会第一时间发放奖品', step: 1 },
    { type: '2', img: gift8, name: '绿地酒店商城100元优惠券', value: '恭喜您抽中绿地酒店商城100元优惠券\n请在【我的-优惠券】查看', step: 2 },
    { type: '3', img: gift7, name: '绿地G优积分100分', value: '恭喜您抽中绿地G优积分100分\n请在【积分-我的积分】查看', step: 7 },
    { type: 'start' },
    { type: '1', img: gift4, name: '绿地无聊猿主题T恤', value: '恭喜您抽中无聊猿主题T恤\n请填写收货地址，我们会第一时间发放奖品', step: 3 },
    { type: '1', img: gift3, name: '阿狸主题公交卡', value: '恭喜您抽中阿狸主题公交卡\n请填写收货地址，我们会第一时间发放奖品', step: 6 },
    { type: '2', img: gift5, name: 'G-super100元优惠券',value:'恭喜您抽中G-super100元优惠券\n请在【我的-优惠券】查看', step: 5 },
    { type: '3', img: gift6, name: '绿地G优积分500分', value: '恭喜您抽中绿地G优积分500分\n请在【积分-我的积分】查看', step: 4 },
];

let runner;

let hasClick = false;
export default (props) => {
    const { number = 0,lotteryRecordList=[],activityCode='' } = props;
    const [status, setStatus] = useState({
        isBegin: false,
        thisStep: 0,
        endSteps: 0,
        reward: null,
        interval: 200,
    });
    const [data,setData] = useState([])
    const [radioAnimate,setRadioAnimate] = useState(lotteryRecordList.length>0);

    const getAward = async () => {
        const { isBegin, thisStep } = status;
        if (number <= 0) {
            Actions.alert.show('抽奖次数已用完');
            return;
        }
        //抽奖
        if (hasClick) {
            return;
        }
        hasClick = true;
        const fetchResult = await Services('User', { code: 101316 }, { activityCode });
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            const diff = Math.abs(8 - thisStep % 8 + (result.index));
            const endSteps = 24 + diff;
            setStatus({ ...status, isBegin: true, endSteps: endSteps, reward: result });
        }
        hasClick = false;
    }

    const startAnimation = async () => {
        let { thisStep, endSteps, interval } = status;
        if (endSteps <= 0) {
            clearTimeout(runner);
            reward();
            return;
        } else {
            endSteps = endSteps - 1;
            thisStep = thisStep + 1;
        }
        if (endSteps < 5) {
            interval = Math.min(1000, interval + 200);
        } else {
            interval = Math.max(50, interval - 200);
        }
        setStatus({ ...status, endSteps, thisStep, interval });
    }

    const radioTimerRef = useRef();
    const startRadioAnimate = () => {
        clearTimeout(radioTimerRef.current);
        setRadioAnimate(true)
        radioTimerRef.current = setTimeout(()=>{
            let arr = data;
            arr.push(data[0]);
            arr.shift();
            setData(arr);
            setRadioAnimate(false);
        },1000);
    }

    const reward = async () => {
        let { reward, isBegin, thisStep } = status;
        //结束抽奖，给出提示
        const thisItem = buttons.filter((item) => { return parseInt(reward.index) % 8 === item.step })[0];
        JsBridge.print(reward)

        props.onCallback && props.onCallback({content:thisItem.value,title:thisItem.name,imgUrl:reward.imgUrl,type:thisItem.type,id:reward.lotteryId});
        setStatus({ ...status, isBegin: false, interval: 200 });
    }


    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = startAnimation;
    });
    Hooks.useInterval(startRadioAnimate,2000);

    useEffect(()=>{
        setData([...props.lotteryRecordList]);
    },[props.lotteryRecordList])

    useEffect(() => {
        if (status.isBegin) {
            runner = setTimeout(savedCallback.current, status.interval);
        }
    }, [status])

    return (
        <div>
            <div className={styles.drawWraps}>
                <div className={styles.drawBorder}>
                    {status.isBegin ? <div className={styles.drawMask}></div> : false}
                    {buttons.map((button, key) => {
                        if (button.type === 'start') {
                            return <div className={`${styles.drawStart} ${number <= 0?styles.disable:''}`} key={key} onClick={getAward}>
                                <div className={styles.number}>剩余{number}次</div>
                            </div>
                        }
                        return <div onClick={() => {
                            if (button.click && typeof (button.click === 'function')) {
                                button.click();
                            }
                        }} className={status.thisStep % 8 === button.step ? `${styles.drawButton} ${styles.active}` : `${styles.drawButton}`} key={key}>
                            <img src={button.img} alt='奖品' />
                        </div>;
                    })}
                    <div className={styles.radio}>
                        <div className={`${styles.radioList} ${radioAnimate?styles.animate:''}`}>
                            {data.map((item,index)=>{
                                let {loginName='',prizeName=''} = item;
                                return <div className={styles.radioText}>{`恭喜用户${loginName}获得${prizeName}`}</div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
