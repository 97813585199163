import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { jobMap, envMap } from '../conf';
import topImage from '../images/top@2x.png';
import arrImg from '../images/arr@2x.png';


export default (props) => {
    const [jobIndex, setJobIndex] = useState(0);
    const [showDesc, setShowDesc] = useState(false);
    const history = useHistory();
    return <div className={styles.container}>
        <header><img src={topImage} alt='头图' /></header>
        <div className={styles.content}>
            <i className={styles.topIcon}></i>
            <h2>关于联奇云</h2>
            {showDesc ? <div className={`${styles.companyDesc} ${styles.all}`}>
                <p>杭州联奇云信息科技有限公司（以下简称“联奇云”）是绿地数字科技有限公司（以下简称“绿地数科”）旗下科技子公司，致力于为大型集团客户提供数字化转型的技术支撑。公司依托世界500强绿地集团的多元化布局，围绕绿地数科生态内的使用权交易、供应链金融和会员积分权益等数字化平台资源，以云计算技术为基础，通过建设和整合SaaS系统平台，结合行业特性，为客户提供数字化相关的咨询与集成、应用开发和系统运维外包服务。</p>
                <p>联奇云以“联奇+云”作为公司名称，概括了公司的发展战略及企业文化。公司的愿景是成为云计算时代行业领先的新型集成服务商，遵循“联手共创、守正出奇”的企业经营理念， 以专业和创新作为服务特色。公司经营管理核心团队均来自于国内外知名科技公司和金融机构，在相关领域具备深厚经验，曾携手多家金融机构开拓创新，探索构建数字化时代。</p>
            </div> : <div className={`${styles.companyDesc}`}>
                <p>杭州联奇云信息科技有限公司（以下简称“联奇云”）是绿地数字科技有限公司（以下简称“绿地数科”）旗下科技子公司，致力于为大型集团客户提供数字化转型的技术支撑。公司依托世界500强绿地集团的多元化布局，围绕绿地数科生态内的使用权交易、供应链金融和会员积分权益等数字化平台资源，以云计算技术为基础，通过建设和整合SaaS系统平台，结合行业...</p>
            </div>}

            <div onClick={() => {
                setShowDesc(!showDesc);
            }} className={`${styles.zhankai} ${showDesc ? styles.top : ''}`}>
                {showDesc ? '收起' : '展开'} <img src={arrImg} alt='展开' />
            </div>
            <h2>工作在联奇云</h2>
            <div className={styles.environmentWrap}>
                <div className={styles.environment}>
                    {envMap.map((item, key) => {
                        return <div key={key} className={styles.block}>
                            <img src={item.image} alt='环境' />
                            <h3>{item.name}</h3>
                            <p>{item.desc}</p>
                        </div>
                    })}
                </div>
            </div>
            <h2>在招岗位</h2>
            <div className={styles.jobWrap}>
                <div className={styles.nav}>
                    {jobMap.map((item, key) => {
                        return <div
                            onClick={() => { setJobIndex(key) }}
                            key={key}
                            className={`${styles.title} ${key === jobIndex ? styles.active : ''}`}>
                            {item.name}
                        </div>
                    })}
                </div>

                <div className={styles.info}>
                    {jobMap.map((item, key) => {
                        return <div key={key} className={`${key === jobIndex ? styles.active : styles.hide}`}>
                            <h3>{item.name}（{item.location}）</h3>
                            <div className={styles.desc}>{item.desc}</div>
                            <hr />
                            <div className={styles.desc}>{item.order}</div>
                        </div>
                    })}
                </div>
            </div>

            <h2>福利待遇</h2>
            <div className={styles.fuliWrap}>
                <div className={styles.fuli}>
                    富有竞争力的薪资待遇
                </div>
                <div className={styles.fuli}>
                    国企正式编制
                </div>
                <div className={styles.fuli}>
                    享受国家法定假期
                </div>
                <div className={styles.fuli}>
                    带薪年假及生日福利
                </div>
            </div>
        </div>
        <footer>
            <h2>联系我们</h2>
            <div className={styles.contact}>
                <p>
                    <span><label>电话：</label>0571-8622 5215</span>
                    <span><label>邮箱：</label>hr@lianqicloud.com</span>
                </p>
                <p><span><label>地址：</label>浙江省杭州市临平区南苑街道新丰路199号余杭商会</span></p>
                <p><span><label></label>大厦A座3A层</span></p>
            </div>
        </footer >
        <button className={styles.join} onClick={() => {
            history.push('recruit/form');
        }}>加入我们</button>
    </div >;
}
