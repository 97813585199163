import React from 'react';
import Library from 'library';
import styles from './success.module.scss';
import successIcon from 'images/icon/success.png';


const appDomain = {
    development: '//app-test.gkewang.com',
    beta: '//app-test.gkewang.com',
    uat: '//app-uat.gkewang.com',
    product: '//app.gkewang.com'
};

export class Success extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            downUrl: ''
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ downUrl: Library.Util.APP_INVITER_DOWNLOAD_URL });
    }

    render() {
        const { downUrl } = this.state;
        return <div className={styles.registerSuccess}>
            <div className={styles.successHead}>
                <img className={styles.headMain} src={successIcon} />
            </div>
            <div className={styles.successContent}>
                <div className={styles.title}>
                    <span className={styles.titleBold}> 您已提交成功 </span>
                </div>
                {/* <div className={styles.tip}>您已预约成功</div> */}
            </div>
        </div>;
    }
}