/**
 * @description 元宇宙签到-领取奖品界面
 * @returns route: /front/activities/pointTask/exchangeReward
*/
import React, { useState, useEffect, Fragment } from 'react';
import Library from 'library';
import { Header, ListView } from 'components';
import Service from '../service';
import './index.scss';
const { JsBridge, Util } = Library;

export default (props) => {

    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = React.useRef(1)

    useEffect(()=>{
        loadData()
    },[])

    //加载列表数据
    const loadData = async (loading=true) => {
        if(loading) {
            setLoading(true)
        }
        const res = await Service.queryExchangeReward();
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = [] } = res.data
            setDataList(list)
            setHasMore(list.length >= 10)
        }
        
        if(loading) {
            setLoading(false)
        }
        
    }

    const loadMore = async () => {
        const res = await Service.queryExchangeReward({pageNum: `${currentPage.current + 1}`});
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = []} = res.data
            setDataList([...dataList, ...list])
            setHasMore(list.length >= 10)
            currentPage.current = currentPage.current + 1
        }
    }

    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
          loadData();
        });
    }
    const onAddAddress = (item) => {
        if(item.address) {
            const param = Util.url.objectToQueryParams(item)
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/activities/lottery/orderDetail?${param}`});
        }else {
            const param = Util.url.objectToQueryParams({
                idf: 'commonSub',
                activityCode: Service.activityCode,
                req: item.id,
                name: item.prizeName,
                imgUrl: item.prizeImgUrl
            })
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/guapp/activityOrder?${param}`});
            onDidFocus();
        }
    }

    const emtyView = (
        <div className="view-emty">
            <img src={require('images/queshengye/pic_wushuju.png')} alt="" />
            <div className="title">{loading ? '数据加载中...': '没有更多内容'}</div>
        </div>

    )
    const renderItem = (item) => {
        return (
            <div className='list-item'>
                <div className='img'>
                    <img src={item.prizeImgUrl}/>
                </div>
                <div className='info'>
                    <span>{item?.prizeName}</span>
                    <div>
                        <span>中奖时间：{item?.createTime}</span>
                        {
                             item?.prizeType === 3 &&
                             <div className='btn' onClick={()=>onAddAddress(item)}>
                                {item.address ? '查看详情':'去填写'}
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='exchange-reward-page'>
            <Header midText={'领取奖品'}/>
            <div className='page-body'>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    emtyImg={null}
                    emtyDesc={emtyView}
                    hasMore={hasMore}
                    onRefresh={()=>{
                        console.log('onRefresh')
                        currentPage.current = 1
                        loadData(false)
                    }}
                    loadMore={loadMore}
                />
            </div>
        </div>
    )
}