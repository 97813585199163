import React, {Fragment, useEffect, useRef, useState} from 'react';
import Library from 'library';
import styles from './home.module.scss';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Services from 'services';
import vip1 from './images/vip1.png';
import vip2 from './images/vip2.png';
import vip3 from './images/vip3.png';
import level1 from './images/level1.png';
import level2 from './images/level2.png';
import level3 from './images/level3.png';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import icon4 from './images/icon4.png';
import icon5 from './images/icon5.png';
import icon6 from './images/icon6.png';
import gift1 from './images/gift1.png';
import gift2 from './images/gift2.png';
import gift3 from './images/gift3.png';
import gift4 from './images/gift4.png';
import gift5 from './images/gift5.png';
import gift6 from './images/gift6.png';
import gift7 from './images/gift7.png';
import gift8 from './images/gift8.png';
import gift9 from './images/gift9.png';
import gift10 from './images/gift10.png';
import gift11 from './images/gift11.png';
import gift12 from './images/gift12.png';
import gift13 from './images/gift13.png';
import Footer from '../shareOfficer/shareComponents/footer';
import Robbed from '../shareOfficer/images/Robbed@2x.png';
import {Modal} from "./components/modal";
const { Platform, JsBridge, Util } = Library;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
let arrHead = ['','','','','','']
export default (props) => {
    const history = useHistory();
    const { activityCode ='' } = useParams();
    const [index,setIndex] = useState(0);
    const [productList,setProductList] = useState([]);
    const [arrVip] = useState([{src:vip1,icon:level1},{src:vip2,icon:level2},{src:vip3,icon:level3}])
    const [arrCoupon] = useState([icon1,icon2,icon3,icon4,icon5,icon6]);
    const [arrGifts] = useState([[gift1,gift2,gift3,gift4],[gift5,gift6,gift7,gift8],[gift9,gift10,gift11,gift12],[gift13]]);
    const [state,setParams] = useState({arrWmGoods:[],btnMore:null,arrClient:[],levelCode:-1,remainTimes:0,isBuyBlindBox:false,isExchangeCode:false,isEnd:false});
    const [visible,setVisible] = useState(false);
    const modalInfo = useRef({content:'',title:'',confirm:'',type:0});
    const stateRef = useRef(state);
    const rulesRef = useRef([]);
    const isClicking = useRef(false);
    const setState = (params) => {
        let mergeState = {};
        Object.assign(mergeState, state, params);
        setParams(mergeState);
        stateRef.current = mergeState;
    };
    const init = async () => {
        isClicking.current = true;
        const result = await Services('MainPage', { code: '101230' }, { activityCode });
        JsBridge.print(result);
        if (result.status > 0) {
            let {beClient=[],buyBlindBox,exchangeCode,memberDayWmGoods = [],userLevel={},secondStartTime=0,secondEndTime=0,isSupport = false,...rest} = result.data;
            let arrWmGoods = [];
            let btnMore = null;
            if (memberDayWmGoods.length > 4) {
                for (let i=0;i<4;i++) {
                    arrWmGoods.push(memberDayWmGoods[i]);
                }
                btnMore = memberDayWmGoods[4];
            }
            let now = new Date();
            let endDate = new Date(secondEndTime);
            let isEnd = now.getTime() > endDate.getTime();

            let startTime = Util.format.time(new Date(secondStartTime),'MM月dd日 hh:mm');
            let endTime = Util.format.time(endDate,'MM月dd日 hh:mm');
            if (isSupport) {
                modalInfo.current = {content:'您已成功给好友助力',title:'助力成功',confirm:'确定',type:5};
                setVisible(true);
            }
            setState({arrClient:beClient,levelCode:userLevel.levelCode,arrWmGoods,btnMore,isBuyBlindBox:buyBlindBox>0,isExchangeCode:exchangeCode>0,isEnd,startTime,endTime,...rest})
        }
        isClicking.current = false;
    }

    const getRule = async ()=>{
        isClicking.current = true;
        const result = await Services('MainPage', { code: 101234 }, { activityCode });
        JsBridge.print(result)
        if (result.status > 0) {
            let {rules = []} = result.data;
            rulesRef.current = rules;
        }
        isClicking.current = false;
    }

    // 积分兑换列表
    const exchangeList = async ()=> {
        isClicking.current = true;
        const result = await Services('User', { code: 101152 }, { pageNum: 1, pageSize: 100 });
        if (result.status > 0) {
            let arr = result.data.productList;
            arr.sort(function(a, b) {
                if (a.remainedNum === 0) {
                    return 1;
                } else if (b.remainedNum === 0) {
                    return -1;
                }
                return 0;
            });
            setProductList(arr)
        }
        isClicking.current = false;
    }

    //积分购买机会和盲盒兑换
    const exchange = async ({type,exchangeCode=''})=> {
        isClicking.current = true;
        const result = await Services('MainPage', { code: 101226 }, { activityCode,type,exchangeCode });
        if (result.status > 0) {
            JsBridge.runAction('alert', 'error', [type===1?'购买成功':'兑换成功']);
            init();
        }
        isClicking.current = false;
    }

    const openBox = async ()=> {
        isClicking.current = true;
        const result = await Services('MainPage', { code: 101228 }, { activityCode });
        JsBridge.print(result);
        if (result.status > 0) {
            let {remainTimes,imgUrl,type,value} = result.data;
            modalInfo.current = {content:value,title:'',confirm:'',imgUrl,type:3}
            setVisible(true);
            setState({remainTimes})
        }
        isClicking.current = false;
    }
    useEffect(()=>{
        if (Platform.check.isApp()) {
            init();
            exchangeList();
            getRule();
        }
    },[]);
    const handleChangeIndex = index => {
        if (index < arrVip.length) {
            setIndex(index);
        }
    };

    const ExchangeItem = (productList || []).map((item, index) => {
        return <Fragment>
            <div
                key={index}
                className={styles.productWrap}
                onClick={() => {
                    if (Platform.check.isApp()) {
                        JsBridge.open(`/taro/subPages/user/mall/product/index?goodsId=${item.productId}&goodsType=${item.merchant === '1' ? 'limit' : 'hot'}`);
                    }
                }}>
                <div className={styles.thumbnail}>
                    <img src={item.url} className={styles.img} />
                     {item.remainedNum <= 0 ? <img src={Robbed} className={styles.soldOut} /> : null}
                </div>
                <div className={styles.ItemContent}>
                    <span className={styles.title}>{item.productName}</span>
                    <div className={styles.bottomView}>
                        <span className={styles.text1}>{item.pointCost}</span>
                        <span className={styles.unit}>积分</span>
                        {item.moneyCost ? <span className={styles.text2}>￥{item.moneyCost}</span> : null}
                    </div>
                </div>
            </div>
        </Fragment>
    })


    return <div className={styles.container}>
        <div className={styles.blurBg}/>
        <div className={styles.content}>
            <div className={styles.icon}/>
            <SwipeableViews
                className={styles.swiper}
                index={index}
                onChangeIndex={handleChangeIndex}
            >
                {arrVip.map((item,index)=>{
                    let {src,icon} = item;
                    return <div className={styles.imgBg} onClick={()=>{
                        let levelCode = index+1;
                        let splider = 'one';
                        if (levelCode === 2) {
                            splider = 'two';
                        } else if (levelCode === 3) {
                            splider = 'three';
                        }
                        JsBridge.navigate('CustomWeb',{uri:`${window.location.origin}/taro/subPages/user/mycard/detail/index?id=${splider}&current=${levelCode}`})
                    }}>
                        <img src={src} className={styles.img}/>
                        {state.levelCode-1===index?<img src={icon} className={styles.level}/>:false}
                    </div>
                })}
            </SwipeableViews>
            <div className={styles.dotContainer}>
                {arrVip.map((item,i)=>(
                    <div className={`${styles.dot} ${i===index?styles.active:''}`}/>
                ))}
            </div>
            <div className={styles.icon7}>
                <p className={styles.ruleText} onClick={()=>{
                    let rule = '';
                    if (rulesRef.current.length > 0) {
                        rule = rulesRef.current[0].otherSign;
                    }
                    history.push(activityCode+'/rule', { rule });
                }}>活动规则</p>
            </div>
            <div className={styles.part1Bg}>
                <div className={styles.part1}>
                    <p className={styles.dateTime}>12月24日00:00-23:59:59</p>
                    <div className={styles.couponList}>
                        {arrCoupon.map((item,index)=>{
                            return <img src={item} className={styles.img}/>
                        })}
                    </div>
                    <div className={styles.icon8}/>
                    <div className={styles.goods}>
                        {state.arrWmGoods.map((item,index)=>{
                            let {imgUrl,wmUrl} = item;
                            return <img className={styles.item} src={imgUrl} mode='widthFix' onClick={()=>{
                                JsBridge.open(decodeURIComponent(wmUrl));
                            }}/>
                        })}
                    </div>
                    <button className={styles.button} onClick={()=>{
                        if (state.btnMore) {
                            JsBridge.open(decodeURIComponent(state.btnMore.wmUrl));
                        }
                    }}>查看更多</button>
                </div>
            </div>
            <div className={styles.icon9}>
                <p className={styles.btnMyGift} onClick={()=>{
                    JsBridge.open('/front/activities/memberDay/myrewards');
                }}>我的奖品</p>
                <p className={styles.btnRule} onClick={()=>{
                    let rule = '';
                    if (rulesRef.current.length > 1) {
                        rule = rulesRef.current[1].otherSign;
                    }
                    history.push(activityCode+'/rule', { rule });
                }}>活动规则</p>
            </div>
            <div className={styles.part2Bg}>
                <div className={styles.part2}>
                    <p className={styles.dateTime}>{state.startTime}-{state.endTime}</p>
                    <div className={styles.blindBox}>
                        <div className={styles.title}>开盲盒次数剩余：{state.remainTimes}次</div>
                        <div className={styles.giftBg}>
                            <div className={styles.gift}>
                                <div className={styles.giftItem}/>
                                <div className={styles.giftItem}/>
                                <div className={styles.giftItem}/>
                                <div className={styles.giftItem}/>
                            </div>
                        </div>
                        <div className={styles.btnOpenBg}>
                            <div className={`${styles.btnOpen} ${state.isEnd?styles.disable:''}`} onClick={()=>{
                                if (state.isEnd || isClicking.current) {
                                    return;
                                }
                                if (state.remainTimes > 0) {
                                    openBox();
                                } else {
                                    modalInfo.current = {content:'暂无盲盒次数，是否获取开盲盒次数',title:'暂无盲盒次数',confirm:'去获取',type:0}
                                    setVisible(true);
                                }

                            }}>
                                <p className={styles.boxText}>{state.isEnd?'活动已结束':'开盲盒'}</p>
                            </div>
                        </div>
                        <div className={styles.title2}>盲盒奖品</div>
                        <div className={styles.giftListBg}>
                            <AutoPlaySwipeableViews>
                                {arrGifts.map((item,index)=>{
                                    return <div className={styles.giftList}>
                                        {item.map((o,i)=>{
                                            return <img src={o} className={`${styles.itemImg} ${i%4===3?styles.last:''}`} />
                                        })}
                                    </div>
                                })}
                            </AutoPlaySwipeableViews>
                        </div>
                    </div>
                    <div className={styles.inviteBg} id={'id_invite'}>
                        <div className={styles.title}>邀请好友助力 得开盲盒次数</div>
                        <p className={styles.text}>今日还可获取{state.tryAcquireTimes || '0'}次开盲盒机会</p>
                        <p className={styles.text2}>每邀请2位好友得一次开盲盒机会</p>
                        <div className={styles.inviteView}>
                            {arrHead.map((item,index)=>{
                                let client = null;
                                let text = '待邀请';
                                let img = '';
                                if (state.arrClient.length > index) {
                                    client = state.arrClient[index];
                                    let {userCode,imagePath} = client;
                                    img = imagePath;
                                    text = userCode;
                                }
                                return <div className={styles.item}>
                                    {client?<img className={styles.headImg} src={img}/>:<div className={styles.circle}>+</div>}
                                    <p className={`${styles.textPhone} ${client?styles.phone:''}`}>{text}</p>
                                </div>
                            })}
                        </div>
                        <button className={`${styles.buttonInvite} ${state.isEnd || state.arrClient.length >= 6? styles.gray:''}`} onClick={()=>{
                            if (state.isEnd || state.arrClient.length >= 6) {
                                return;
                            }
                            history.push(activityCode+'/share',{inviteCode:state.inviteCode})
                        }}>立即邀请</button>
                        <div className={styles.bottomIcon}/>
                    </div>

                    <div className={styles.inviteBg}>
                        <div className={styles.title}>下面有更多方法获取开盲盒次数哦!</div>
                        <div className={styles.viewBg}>
                            <div className={styles.item1}>
                                <button className={`${styles.button1} ${state.isBuyBlindBox || state.isEnd?styles.gray:''}`} onClick={()=>{
                                    if (state.isBuyBlindBox || state.isEnd || isClicking.current) {
                                        return;
                                    }
                                    modalInfo.current = {content:'购买盲盒次数将扣除100积分是否够购买？',title:'购买盲盒次数',confirm:'确认',type:4};
                                    setVisible(true);
                                }}>立即购买</button>
                                <button className={styles.button2} onClick={()=>{
                                    JsBridge.navigate('CustomWeb',{uri:window.location.origin+'/front/activities/dailySignin/index'})
                                }}>获取积分</button>
                            </div>
                            <div className={styles.item2}>
                                <button className={`${styles.button2}`} onClick={()=>{
                                    modalInfo.current = {content:'',title:'',confirm:'',type:2};
                                    setVisible(true);
                                }}>获取兑换码</button>
                                <button className={`${styles.button1} ${state.isExchangeCode || state.isEnd?styles.gray:''}`} onClick={()=>{
                                    if (state.isExchangeCode || state.isEnd || isClicking.current) {
                                        return;
                                    }
                                    modalInfo.current = {content:'请输入兑换码',title:'输入兑换码',confirm:'确认',type:1};
                                    setVisible(true);
                                }}>输入兑换码</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.part3Bg}>
                <div className={styles.rule3} onClick={()=>{
                    let rule = '';
                    if (rulesRef.current.length > 2) {
                        rule = rulesRef.current[2].otherSign;
                    }
                    history.push(activityCode+'/rule', { rule });
                }}/>
                <p className={styles.dateTime3}>{state.startTime}-{state.endTime}</p>
            </div>
            <div className={styles.exchangeTitle}>
                <div className={styles.line1}/>
                <p className={styles.title}>积分兑换</p>
                <div className={styles.line2}/>
            </div>
            <div className={styles.exchangeItemWrap}>{ExchangeItem}</div>
            <Footer/>
        </div>
        {visible?<Modal
            visible={visible}
            {...modalInfo.current}
            onOK={({value,type}) => {
                if (type === 0) {
                    let anchorElement = document.getElementById('id_invite').offsetTop - 20;
                    if (anchorElement) {
                        window.scrollTo(0,anchorElement);
                    }
                } else if(type === 1) {
                    exchange({type:2,exchangeCode:value})
                }else if (type === 3) {
                    JsBridge.open('/front/activities/memberDay/myrewards');
                } else if (type === 4) {
                    exchange({type:1});
                }
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}
        />:false}
    </div>
}
