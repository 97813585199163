export const reduxToStorage = ({ getState }) => {
    return (next) => {
        return (action) => {
            const returnValue = next(action);
            const nextState = getState();
            new Promise((resolve) => {

                const saveState = { user: nextState.user, sms: nextState.sms };
                localStorage.setItem('GU_STORAGE_INFO', JSON.stringify(saveState));
                resolve();
            });
            return returnValue;
        };
    };
};
