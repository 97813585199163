import React from 'react';
import './progress.scss';

const Progress = ({successPercentage, flatPercentage, failPercentage}) => {
  return <div className='world-cup-progress'>
    <div className="progress-top">
      <div className='success common-style'>{`人气${Number(successPercentage)}%`}</div>
      <div className='flat common-style'>{`人气${Number(flatPercentage)}%`}</div>
      <div className='fail common-style'>{`人气${Number(failPercentage)}%`}</div>
    </div>
    {(Number(successPercentage) === 0 && Number(flatPercentage) === 0 && Number(failPercentage) === 0) ?<div className="popularity-progress">
        <div className='progress-success' style={{flex: 1}}></div>
        <div className='progress-flat' style={{flex: 1}}>
          <div className='left-sanjiao'></div>
          <div className='right-sanjiao'></div>
        </div>
        <div className='progress-fail' style={{flex: 1}}></div>
      </div>: <div className="popularity-progress">
        <div 
          className='progress-success'
          style={{width: `${successPercentage}%`}}
        >
          {Number(flatPercentage) === 0 && Number(successPercentage) !== 100 && <div className='right-sanjiao'></div>}
        </div>
        {Number(flatPercentage) >= 5.6?<div 
          className='progress-flat' 
          style={{
            width: `${flatPercentage}%`,
            justifyContent: `${Number(failPercentage) === 0 ? 'flex-start': Number(successPercentage) === 0 ? 'flex-end': ''}`
          }}
        >
          {Number(successPercentage) !== 0 && <div className='left-sanjiao'></div>}
          {Number(failPercentage) !== 0 && <div className='right-sanjiao'></div>}
        </div>:<div 
          className='progress-flat' 
          style={{
            width: `${flatPercentage}%`,
          }}
        >
        </div>}
        <div className='progress-fail' style={{width: `${failPercentage}%`}}></div>
      </div>}
  </div>
}

export default Progress;