import 'whatwg-fetch';
import React, { useEffect, useState, useRef } from 'react';
import { Actions } from 'reduxs';
import { validate } from 'library/util';
import * as Platform from 'library/platform';
import styles from './index.module.scss';
import Services from 'services';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ShouldOpenInWechat } from 'components/shouldOpenInWechat';
import Fetch from 'library/fetch';
import topImage from './deco.png';
import bottomImage from './bottom@2x.png';
let SwiperNc = null;
export default (props) => {
    const channel = props.channel || '1';
    const [postData, setPostData] = useState({});
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const [isNcShow, setIsNcShow] = useState(false);
    const [lastSecond, setLastSecond] = useState(0);
    const history = useHistory();
    const NCRef = useRef();
    const location = useLocation();
    const countDown = useSelector((state) => {
        return state.sms.toJS().couponCounter;
    });
    useEffect(() => {
        initNC();
        window.wx.ready(async function() {
            window.wx.hideAllNonBaseMenuItem();
        });
    }, []);

    const initNC = async () => {
        import('components/swiperCaptcha').then(e => {
            // setSwiperCaptcha(e.SwiperCaptcha);
            SwiperNc = e.SwiperCaptcha();
            setIsNcLoaded(true);
        })
    }

    useEffect(() => {
        NCRef.current = async (data) => {
            // 短信验证码
            const { code } = data;
            if (code === 0) {
                Actions.loading.start();
                const result = await new Fetch({ url: '/jkt/scb/meetingSubCribe/getDynamicCode' }).post({
                    mobile: postData.mobile,
                    captcha: { ...data }
                })
                console.log(result);
                Actions.loading.end();
                if (result && result.status > 0 && result.data.success) {
                    Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
                } else {
                    Actions.alert.show(result && result.data && result.data.msg || '网络请求异常，请重试');
                }
            }

        }
    }, [postData.mobile]);
    const submit = async () => {
        postData.channel = '3';
        // postData.healthPicUrl = 'http://';
        // postData.routePicUrl = 'http://';
        postData.inviterName = '无';
        postData.certNo = '无';
        postData.dinnerAttend = 0;

        let errorMessage = '';
        if (!postData.name) {
            errorMessage = '请填写姓名';
        } else if (!postData.healthPicUrl) {
            errorMessage = '请填写公司';
        } else if (!postData.routePicUrl) {
            errorMessage = '请填写公司部门';
        } else if (!postData.mobile) {
            errorMessage = '请填写联系电话';
        } else if (!validate.mobile(postData.mobile)) {
            errorMessage = '请填写正确手机号';
        } else if (!postData.verificationCode) {
            errorMessage = '请填写短信验证码';
        }
        if (errorMessage) {
            Actions.alert.show(errorMessage);
            return;
        }
        Actions.loading.start();
        const result = await new Fetch({ url: '/jkt/scb/meetingSubCribe/insert' }).post(postData);
        Actions.loading.end();
        if (result && result.status > 0 && result.data.success) {
            // Actions.alert.show('提交成功！');
            history.push('/front/licaiInviter/success');
        } else {
            Actions.alert.show(result && result.data && result.data.msg || '网络请求异常，请重试');
        }
    };

    const sendSms = async () => {
        if (!validate.mobile(postData.mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        setIsNcShow(true)
    }

    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.button}> {lastSecond} 秒 </button>;
        } else {
            return <button onClick={sendSms} className={styles.button}>获取验证码</button>;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [countDown]);
    if (Platform.check.isH5()) {
        return <ShouldOpenInWechat />;
    }
    return <div className={styles.page}>
        <img src={topImage} />
        <h1>活动已结束</h1>

        {/* <div className={styles.container}>
            <div className={styles.inputWrap}>
                <div className={styles.inputTitle}>
                    姓名 <span className={styles.red}>*</span>
                </div>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        setPostData(Object.assign(postData, { name: event.currentTarget.value }));
                    }} type='text' placeholder='请输入' />
                </div>
            </div>
            <div className={styles.inputWrap}>
                <div className={styles.inputTitle}>
                    公司 <span className={styles.red}>*</span>
                </div>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        setPostData(Object.assign(postData, { healthPicUrl: event.currentTarget.value }));
                    }} type='text' placeholder='请输入所在公司' />
                </div>
            </div>
            <div className={styles.inputWrap}>
                <div className={styles.inputTitle}>
                    部门 <span className={styles.red}>*</span>
                </div>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        setPostData(Object.assign(postData, { routePicUrl: event.currentTarget.value }));
                    }} type='text' placeholder='请输入所在部门' />
                </div>
            </div>

            <div className={styles.inputWrap}>
                <div className={styles.inputTitle}>
                    手机号 <span className={styles.red}>*</span>
                </div>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        setPostData(Object.assign(postData, { mobile: event.currentTarget.value }));
                    }} type='tel' placeholder='请输入' />
                </div>
            </div>
            <div className={styles.inputWrap}>
                <div className={styles.inputTitle}>
                    验证码 <span className={styles.red}>*</span>
                </div>
                <div className={styles.inputLine}>
                    <input onChange={(event) => {
                        setPostData(Object.assign(postData, { verificationCode: event.currentTarget.value }));
                    }} maxLength={6} type='text' placeholder='请输入' />
                    {sendSmsRender()}
                </div>
            </div>

            <div className={styles.inputWrap}>

                <button onClick={submit} className={styles.submit}></button>
            </div>

            {isNcLoaded ? <SwiperNc visible={isNcShow} success={(e) => {
                NCRef.current(e);
            }} hide={() => {
                setIsNcShow(false)
            }} /> : false}
        </div> */}
        <img src={bottomImage} style={{ position: 'fixed', bottom: 0, width: '100%' }} />
    </div>;
}