import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import styles from './appointmentSuccess.module.scss';
import successImg from './images/success.png';
import tip from './images/tip@2x.png';
import {connect} from "react-redux";
const AppointmentSuccessHook = (props) => {
    const history = useHistory();
    const {nucalmInfo,calendarInfo} = props;
    useEffect(() => {

    }, []);

    return <div className={styles.container}>
        <img className={styles.img} src={successImg}/>
        <p className={styles.label1}>恭喜预约成功</p>
        <div className={styles.contentBG}>
            <div className={styles.content}>
                <div className={styles.rowWrap}>
                    <p className={styles.title}>预约时间：</p>
                    <p className={styles.des}>{`${calendarInfo.date} ${calendarInfo.name.substr(0,5)}:00`}</p>
                </div>
                <div className={styles.rowWrap}>
                    <p className={styles.title}>姓名：</p>
                    <p className={styles.des}>{nucalmInfo.name}</p>
                </div>
                <div className={styles.rowWrap}>
                    <p className={styles.title}>联系方式：</p>
                    <p className={styles.des}>{nucalmInfo.phone}</p>
                </div>
                <div className={styles.rowWrap}>
                    <p className={styles.title}>所在部门：</p>
                    <p className={styles.des}>{nucalmInfo.department}</p>
                </div>
            </div>
            <div className={styles.contentTip}>
                <div className={styles.row1}>
                    <img className={styles.img2} src={tip} mode='widthFix'/>
                    <p className={styles.title2}>温馨提示</p>
                </div>
                <p className={styles.text}>建议在体验当天<span style={{color:'#641F6D',fontWeight:'bold'}}>穿着宽松的衣服</span>，欢迎有睡眠问题的用户优先体验</p>
            </div>
        </div>

        <button className={styles.commit} onClick={()=>{
            history.replace('/front/activities/nucalm/recordList');
        }}>查看预约纪录</button>
    </div>;
};

export const AppointmentSuccess = connect((state) => {
    return {
         calendarInfo: state.calendar.toJS().info,
         nucalmInfo: state.user.toJS().nucalmInfo || {}
        };
})(AppointmentSuccessHook);