import React from 'react';
import styles from './trip.module.scss';
import activityEnds from './images/general-img-activityEnds@2x.png';
import Library from 'library';
const { JsBridge } = Library;
export const End = () => {
    return <div className={styles.endContainer}>
        <img className={styles.endImage} src={activityEnds} alt='活动'/>
        <div className={styles.endTip}> 活动已结束, 下次记得早点来哦~</div>
        <button className={styles.button} onClick={() => {
            JsBridge.back();
        }}>返回</button>
    </div>
}
