/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */
import { validate } from '../validate';
export const format = {
    bankCard: (card) => {
        return card.replace(/\s/g, '').replace(/(\d{4})/g, '$1' + ' ').trim();
    },
    pascal: (name) => {
        return name.replace(/^(\w)(.*?)$/g, function ($0, $1, $2) {
            return $1.toUpperCase() + $2.toLowerCase();
        });
    },
    idCard: (idCard, replace) => {
        if (idCard) {
            var _replace = replace || '*';
            idCard = idCard.replace(/\s/g, '');
            return idCard.replace(/^(\d{3})\d+?(\d{3})$/g, '$1' + _replace.repeat(idCard.length - 6) + '$2').trim();
        }
    },
    mobile: (mobile, replace) => {
        var _replace = replace || '-';
        mobile = mobile.replace(/\s/g, '');
        return mobile.replace(/(\d{3})(\d{4})/g, '$1' + _replace + '$2' + _replace).trim();
    },
    mobileSecurity: (mobile = '') => {
        mobile = (mobile || '').replace(/\s/g, '');
        return mobile.replace(/(\d{3})(\d{4})/g, '$1****').trim();
    },
    currentTime: (timeStamps) => {
        //秒差
        const diff = (new Date().getTime() - timeStamps) / 1000;
        const date = new Date(timeStamps);
        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        let year = month * 12;
        if (diff < 10 * minute) {
            return '刚刚';
        } else if (diff < hour) {
            return parseInt(diff / minute, 0) + '分钟前';
        } else if (diff < 10 * hour) {
            return parseInt(diff / hour, 0) + '小时前';
        } else if (timeStamps > new Date(format.time(new Date(), 'yyyy/MM/dd 00:00:00'))) {
            return '今天';
        } else if (timeStamps > new Date(format.time(new Date(new Date().getTime() - 3600 * 24 * 1000), 'yyyy/MM/dd 00:00:00'))) {
            return '昨天';
        } else {
            return format.time(new Date(timeStamps), 'MM-dd');
        }
    },
    time: (time, format) => {
        var map = {
            'M': time.getMonth() + 1, //月份
            'd': time.getDate(), //日
            'h': time.getHours(), //小时
            'm': time.getMinutes(), //分
            's': time.getSeconds(), //秒
            'q': Math.floor((time.getMonth() + 3) / 3), //季度
            'S': time.getMilliseconds() //毫秒
        };
        format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
            var v = map[t];
            if (v !== undefined) {
                if (all.length > 1) {
                    v = '0' + v;
                    v = v.substr(v.length - 2);
                }
                return v;
            } else if (t === 'y') {
                return (time.getFullYear() + '').substr(4 - all.length);
            }
            return all;
        });
        return format;
    },
    nextTime: (timeStamps) => {
        //秒差
        const diff = (timeStamps - new Date().getTime()) / 1000;
        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        let year = month * 12;
        if (diff < hour) {
            return '1小时内';
        } else if (diff < day) {
            return parseInt(diff / hour, 10) + '小时内';
        } else {
            return parseInt(diff / day, 10) + '天内';
        }
    },

    Countdown: (timestamp) => {
        if (!timestamp) {
            return '00:00:00'
        }
        let h = parseInt(timestamp / 1000 / 60 / 60 % 24, 10);
        let m = parseInt(timestamp / 1000 / 60 % 60, 10);
        let s = parseInt(timestamp / 1000 % 60, 10);
        h = h >= 10 ? h : '0' + h;
        m = m >= 10 ? m : '0' + m;
        s = s >= 10 ? s : '0' + s;
        return h + ':' + m + ':' + s;
    },

    //把utf16的emoji表情字符进行转码成八进制的字符
    utf16toEntities: (str) => {
        var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则  
        return str.replace(patt, function (char) {
            var H, L, code;
            if (char.length === 2) {
                H = char.charCodeAt(0); // 取出高位  
                L = char.charCodeAt(1); // 取出低位  
                code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法  
                return "[EMOJI]" + code + "[/EMOJI]";
            } else {
                return char;
            }
        });
    },

    //将编码后的八进制的emoji表情重新解码成十六进制的表情字符
    entitiesToUtf16: (str) => {
        return str.replace(/\[EMOJI\](\d+)\[\/EMOJI\]/g, function (match, dec) {
            let H = Math.floor((dec - 0x10000) / 0x400) + 0xD800;
            let L = Math.floor(dec - 0x10000) % 0x400 + 0xDC00;
            return String.fromCharCode(H, L);
        });
    },
    convertToChinese: (val) => {
        let money = val;
        //汉字的数字
        const cnNums = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');

        //基本单位
        const cnIntRadice = new Array('', '十', '百', '千');

        //对应整数部分扩展单位
        const cnIntUnits = new Array('', '万', '亿', '兆');
        //对应小数部分单位
        const cnDecUnits = new Array('角', '分', '毫', '厘');
        //最大处理的数字
        const maxNum = 999999999999999.9999;
        //整数部分
        let integerNum;
        //金额小数部分
        let decimalNum;
        //输出的中文金额字符串
        let chineseStr = '';
        //分离金额后用的数组，预定义
        let parts;
        if (money === '') { return ''; }
        money = parseFloat(money);
        if (money >= maxNum) {
            //超出最大处理数字
            return '';
        }
        if (money === 0) {
            chineseStr = cnNums[0];
            return chineseStr;
        }
        //转换为字符串
        money = money.toString();
        if (money.indexOf('.') === -1) {
            integerNum = money;
            decimalNum = '';
        } else {
            parts = money.split('.');
            integerNum = parts[0];
            decimalNum = parts[1].substr(0, 4);
        }
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
            let zeroCount = 0;
            let IntLen = integerNum.length;
            for (let i = 0; i < IntLen; i++) {
                let n = integerNum.substr(i, 1);
                let p = IntLen - i - 1;
                let q = p / 4;
                let m = p % 4;
                if (n === '0') {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseStr += cnNums[0];
                    }
                    //归零
                    zeroCount = 0;
                    
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    if(chineseStr === '一十'){
                        chineseStr = cnIntRadice[m];
                    }
                }
                if (m === 0 && zeroCount < 4) {
                    chineseStr += cnIntUnits[q];
                }

            }
        }
        //小数部分
        if (decimalNum !== '') {
            let decLen = decimalNum.length;
            for (let i = 0; i < decLen; i++) {
                let n = decimalNum.substr(i, 1);
                if (n !== '0') {
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (chineseStr === '') {
            chineseStr += cnNums[0];
        } 
        return chineseStr;
    },
    convertToChineseMoney: (val) => {
        let money = val;
        //汉字的数字
        const cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');

        //基本单位
        const cnIntRadice = new Array('', '拾', '佰', '仟');

        //对应整数部分扩展单位
        const cnIntUnits = new Array('', '万', '亿', '兆');
        //对应小数部分单位
        const cnDecUnits = new Array('角', '分', '毫', '厘');
        //整数金额时后面跟的字符
        const cnInteger = '整';
        //整型完以后的单位
        const cnIntLast = '元';
        //最大处理的数字
        const maxNum = 999999999999999.9999;
        //金额整数部分
        let integerNum;
        //金额小数部分
        let decimalNum;
        //输出的中文金额字符串
        let chineseStr = '';
        //分离金额后用的数组，预定义
        let parts;
        if (money === '') { return ''; }
        money = parseFloat(money);
        if (money >= maxNum) {
            //超出最大处理数字
            return '';
        }
        if (money === 0) {
            chineseStr = cnNums[0] + cnIntLast + cnInteger;
            return chineseStr;
        }
        //转换为字符串
        money = money.toString();
        if (money.indexOf('.') === -1) {
            integerNum = money;
            decimalNum = '';
        } else {
            parts = money.split('.');
            integerNum = parts[0];
            decimalNum = parts[1].substr(0, 4);
        }
        //获取整型部分转换
        if (parseInt(integerNum, 10) > 0) {
            let zeroCount = 0;
            let IntLen = integerNum.length;
            for (let i = 0; i < IntLen; i++) {
                let n = integerNum.substr(i, 1);
                let p = IntLen - i - 1;
                let q = p / 4;
                let m = p % 4;
                if (n === '0') {
                    zeroCount++;
                } else {
                    if (zeroCount > 0) {
                        chineseStr += cnNums[0];
                    }
                    //归零
                    zeroCount = 0;
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if (m === 0 && zeroCount < 4) {
                    chineseStr += cnIntUnits[q];
                }
            }
            chineseStr += cnIntLast;
        }
        //小数部分
        if (decimalNum !== '') {
            let decLen = decimalNum.length;
            for (let i = 0; i < decLen; i++) {
                let n = decimalNum.substr(i, 1);
                if (n !== '0') {
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                }
            }
        }
        if (chineseStr === '') {
            chineseStr += cnNums[0] + cnIntLast + cnInteger;
        } else if (decimalNum === '') {
            chineseStr += cnInteger;
        }
        return chineseStr;
    }
};
