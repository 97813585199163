/**
 * Created by z-benjamin on 20/4/22.
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
export const Share = (WrappedComponent) => {
    const HighOrderComponent = (props) => {
        const wxApiConfig = useSelector((state) => {
            return state.wxApi.toJS().config;
        });
        useEffect(() => {
            if (wxApiConfig) {
                props.share && share(props.share);
            }
        }, [props.share, wxApiConfig]);

        const share = (conf) => {
            window.wx.ready(async function() {
                if (conf) {
                    const params = {
                        title: conf.title || '测试标题', // 分享标题
                        desc: conf.desc || '测试描述', // 分享描述
                        link: conf.link || window.location.href, // 分享链接
                        imgUrl: conf.imgUrl || 'https://h5.gkewang.com/crmh5/src/images/logo-share.jpg', // 分享图标
                        success: () => {
                            console.log('share config success');
                            // window._czc.push(["_trackEvent", '五一大派送活动', '微信分享', window.location.pathname, 1, '']);
                        }
                    };
                    window.wx.updateTimelineShareData(params);
                    window.wx.updateAppMessageShareData(params);
                    
                }
            });
        };
        return (
            <WrappedComponent {...props} />
        );
    };
    return HighOrderComponent;
};
