import React, { useEffect, useRef, useState } from 'react';
import styles from './login.module.scss';
import { useSelector } from 'react-redux';
import phoneIcon from '../../activities/registionActivity/images/shoujihao@2x.png';
import Library from 'library';
import valideCode from '../../activities/registionActivity/images/yanzhengma@2x.png';
import closeButton from '../../activities/registionActivity/images/shut_down@2x.png'
import { Actions } from "../../../reduxs";
import { useHistory, useParams } from 'react-router-dom'
import Services from 'services';
let SwiperNc = null;
const { Platform, JsBridge } = Library;

const downloadUrl = Library.Util.WHITELIST_DOWNLOAD_URL;
export const Login = (props) => {
    const { inviteCode, activityCode, caseId } = Library.Util.url.paramsToObj();
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const [isSuccess, setSuccess] = useState(false);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const [isReset, setReset] = useState(false);
    const history = useHistory();
    const [isFirst, setFirst] = useState(true);
    const [swiperData, setSwiperData] = useState({});
    const { isShare, myShareImage } = history.location.state || {};
    const [showSwiper, setShowSwiper] = useState(false);
    const countDown = useSelector((state) => {
        return state.sms.toJS().adminLoginCounter;
    });
    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };

    const onChangeText = (type, e) => {
        if (type === 0 && e.currentTarget.value.replace(/\s/g, '').length <= 11) {
            setMobile(e.currentTarget.value);
        } else {
            if (e.currentTarget.value.length <= 6) {
                setCode(e.currentTarget.value)
            }
        }
    };
    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <p>{lastSecond} 秒</p>
        } else {
            if (Library.Util.validate.mobile(mobile)) {
                if (isFirst) {
                    return <p onClick={() => { sendCode() }}>{'获取'}</p>
                } else {
                    return <p onClick={() => { sendCode(true) }} className={styles.smallText}>{'重新获取'}</p>
                }
            } else {
                if (isFirst) {
                    return <p className={styles.disabled}>{'获取'}</p>
                } else {
                    return <p className={`${styles.disabled} ${styles.smallTexts}`} >{'重新获取'}</p>
                }
            }
        }
    };

    const dealSwiperCheck = (e) => {
        setSwiperData(e);
        setSuccess(true);
    };

    useEffect(() => {
        if (isReset) {
            setReset(false);
            setSuccess(false);
        }
    }, [isReset]);

    useEffect(() => {
        const timer = setInterval(() => {
            let count = parseInt((countDown.expire - new Date().getTime()) / 1000);
            if (count === 0) {
                setReset(true);
            }
            setLastSecond(count);
        }, 500);
        return () => {
            clearInterval(timer);
        };

    }, [countDown]);

    useEffect(() => {
        initNC();
    }, []);

    useEffect(() => {
        if (isSuccess) {
            if (isFirst) {
                let count = parseInt((countDown.expire - new Date().getTime()) / 1000);
                if (count <= 0) {
                    //console.log('sendCode');
                    sendCode();
                }
            }
        }
    }, [isSuccess]);

    const sendCount = async () => {
        Actions.sms.countDownLogin(new Date().getTime() + 60 * 1000);
        setTimeout(() => {
            setFirst(false);
        }, 2000);

    };
    const sendCountRepeat = async () => {
        if (!isSuccess) {
            Actions.alert.show('请向右滑动验证');
        } else {
            Actions.sms.countDownLogin(new Date().getTime() + 60 * 1000);
        }
    };

    const sendCode = async () => {
        {/* {isNcLoaded ? <SwiperNc visible={showSwiper} success={(e) => { dealSwiperCheck(e); }} isReset={isReset} /> : false} */ }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res && mobile && mobile.replace(/\s/g, '').length >= 11) {
                const dyResult = await Services('DynamicCode', 'GetDynamicCode', {
                    mobile: mobile.replace(/\s/g, ''),
                    ...res
                });
                if (dyResult.status > 0) {
                    sendCount();
                }
            }
        }
    };

    const userLogin = async () => {
        if (mobile.replace(/\s/g, '').length < 11) {
            Actions.alert.show('请先输入手机号');
            return
        }
        if (code.length < 6) {
            Actions.alert.show('请先输入验证码');
            return
        }
        let phone = mobile.replace(/\s/g, '')
        let param = inviteCode ? {
            mobile: phone,
            dynamicCode: code,
            invitationCode: inviteCode,
            activityCode: activityCode
        } :
            {
                mobile: phone,
                dynamicCode: code,
                caseId: caseId,
                activityCode: activityCode
            };
        const result = Platform.check.isH5() ? await Services('GuMember', 'RegistOrLoginForH5', param) : await Services('GuMember', 'RegistOrLogin', param);
        const { status, data } = result;
        if (status > 0) {
            history.replace('/front/member/getCardSuccess', { caseId });
        }
    };

    return <div className={styles.container}>
        <div className={styles.content}>
            <p>请先登录</p>
            <div className={styles.input_view_one}>
                <img src={phoneIcon} />
                <div className={styles.line} />
                <input type="text" placeholder={'请输入手机号'} onChange={(e) => {
                    onChangeText && onChangeText(0, e)
                }} value={Library.Util.format.mobile(mobile, ' ')} />
            </div>
            {/* {isNcLoaded ? <SwiperNc visible={showSwiper} success={(e) => { dealSwiperCheck(e); }} isReset={isReset} /> : false} */}
            <div className={styles.input_view_two}>
                <img src={valideCode} />
                <div className={styles.line} />
                <input type="text" onChange={(e) => {
                    onChangeText && onChangeText(1, e)
                }} value={code} />
                <div className={styles.rightView}>
                    <div className={styles.line} />
                    <div className={styles.textView}>
                        {sendSmsRender()}
                    </div>

                </div>
            </div>
            <div className={styles.button_view} onClick={() => {
                userLogin()
            }}>
                <p>登录</p>
            </div>
        </div>
        <img src={closeButton} className={styles.closeButton} onClick={() => {
            history.goBack();
        }} />
    </div>
};
