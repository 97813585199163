/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */
export const WHITELIST_DOWNLOAD_URL = 'https://at.umtrack.com/onelink/X15jqi';
export const APP_INVITER_DOWNLOAD_URL = 'https://at.umtrack.com/onelink/0XPH5v';
export const ACTIVITY_DOWNLOAD_URL = 'https://at.umtrack.com/onelink/r8TrCm';
export const ACTIVITY_REGISTION_INITEE_DOWNLOAD_URL = 'https://at.umtrack.com/onelink/Xva8Lf';
export const RECHARGE_CARD_DOWNLOAD_URL = 'https://at.umtrack.com/onelink/ryuqGj';
export const ORIGIN_DOWNLOAD_URL = 'https://app.gkewang.com/app';
export const url = {
    param: (name) => {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        let r = window.location.search.substr(1).match(reg);
        if (r !== null) {
            return decodeURI(r[2]);
        }
        return null;
    },
    paramsToObj: () => {
        let obj = {};
        const params = window.location.search.replace('?', '').split('&');
        params.forEach((value, key) => {
            const thisKey = value.split('=')[0];
            const thisValue = value.split('=')[1];
            obj[thisKey] = thisValue && decodeURIComponent(thisValue);
        });
        return obj;
    },
    paramsToObj1: () => {
        let obj = {};
        let search = decodeURIComponent(window.location.search)
        const params = search.replace('?', '').split('&');
        params.forEach((value, key) => {
            const thisKey = value.split('=')[0];
            const thisValue = value.split('=')[1];
            obj[thisKey] = thisValue
        });
        return obj;
    },
    getPramasFromUrl: (url) => {
        let obj = {};
        const urlArray = (url || '').split('?');
        if (urlArray.length > 1) {
            const params = urlArray[1].split('&');
            params.forEach((value, key) => {
                const thisKey = value.split('=')[0];
                const thisValue = value.split('=')[1];
                obj[thisKey] = thisValue && decodeURIComponent(thisValue);
            });
        }
        return { path: urlArray[0], params: obj };
    },
    objectToQueryParams: (obj) => {
        if (obj && typeof obj === 'object') {
            return Object.keys(obj).map(e => {
                return `${e}=${obj[e]}`
            }).join('&');
        } else {
            return '';
        }
    }
};

