import React, { useEffect, useState } from 'react';
import styles from './inviter.module.scss';
import shareModalStyles from './shareImage.module.scss';
import { useHistory, useLocation } from 'react-router-dom'
import { Slogan } from './component'
import Library from 'library';
import Services from 'services';
import QRCode from 'qrcode.react';
import { Actions } from 'reduxs';
import userIcon from './img/user@2x.png';
import codeIcon from './img/code-icon@2x.png';
import backIcon from 'images/icon/back-svg-black.svg';
import closeIcon from './img/general-btn-ovalclose@2x.png';

import QQImage from '../signActivity/images/QQ-icon@2x.png';
import qqkongjianImage from '../signActivity/images/qqkongjian-icon@2x.png';
import weiboImage from '../registionActivity/images/weibo@2x.png';
import downloadImage from '../registionActivity/images/download@2x.png';
import MomentImage from '../signActivity/images/Moments-icon@2x.png';
import WechartImage from '../signActivity/images/WeChat-icon@2x.png';


const { Platform, JsBridge } = Library;


const trackEvent = (key) => {
    window._czc && window._czc.push(["_trackEvent", '绿地员工专区', '点击', key, 1]);
}

export const Inviter = () => {
    const [status, setStatus] = useState('hide');
    const [shareModalStatus, setShareModalStatus] = useState(!!useQuery().get('isOpen'));
    const [shareImageUrl, setShareImageUrl] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [code, setCode] = useState('11111');
    const [statusBarHeight, setStatusBarHeight] = useState(Platform.check.isApp() ? null : 0);
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        initAppInfo();
        userIsRegistered();
    }, []);
    const initAppInfo = async () => {
        await JsBridge.ready();
        JsBridge.setStatusBar('setBarStyle', ['dark-content']);
        const { height } = await JsBridge.getStatusBarHeight();
        setStatusBarHeight(height);
        const res = await JsBridge.isFullScreen();
        setIsFullScreen(res.isFullScreen);
    }
    const userIsRegistered = async () => {
        const result = Platform.check.isApp() ? await Services('MainPage', { code: '101166' }, { activity: 'staffInvitation' }) : await Services('GuMember', 'IsRegisted', { activity: 'staffInvitation' });

        const { status } = result;

        if (status > 0) {
            let { msg, data } = result.data;
            if (msg === 1 || Platform.check.isApp()) {
                setPageData(data);
                setShareImageUrl(`${window.location.origin}/front/member/getCard?inviteCode=${data.inviteCode}&activityCode=staffInvitation`)
            }
        }
    };

    const shareToPlatform = async (platform) => {
        if (!shareImageUrl) {
            return;
        }
        const result = await JsBridge.share({ text: pageData.registerText, icon: pageData.registerImgUrl, link: shareImageUrl, title: pageData.registerText, platform });
        Actions.alert.show(result.message);
    }

    useEffect(() => {
        if (shareModalStatus) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [shareModalStatus])
    if (statusBarHeight === null) {
        return false;
    }
    return <div className={styles.container}>
        {statusBarHeight === 0 ? false :
            <div className={styles.header} style={{ paddingTop: (statusBarHeight) + 'px' }}>
                <div className={styles.wrap}>
                    <div className={styles.arr} onClick={() => {
                        JsBridge.back();
                    }}>
                        <img src={backIcon} />
                    </div>
                    <div className={styles.title}>绿地员工专区</div>
                    <div className={styles.right} onClick={() => {
                        JsBridge.navigate('CertificationInfo', {});
                    }}>
                        我的认证
                    </div>
                </div>
            </div>}
        {statusBarHeight === 0 ? false :
            <div className={styles.header} style={{ zIndex: 99, position: 'relative', paddingTop: (statusBarHeight) + 'px' }}> <div className={styles.wrap}></div></div>
        }

        {pageData === null ? false : <div className={styles.mainContent} >
            <div className={styles.pic}>
                {/* <div className={styles.msg}>绿地员工专享版</div> */}
                {/*<div className={styles.time}>活动时间：<ActivityTime isJump={true}/></div> */}
            </div>
            <div className={`${styles.inviteBox} ${styles.clearfix}`}>
                <div className={`${styles.top} ${styles.shadow}`}>
                    <div className={styles.title}>员工专享版介绍</div>
                    <p>邀请好友下载绿地G优APP注册领取绿地G优会员卡：</p>
                    <div className={styles.descWrap}>
                        <label>好友获得：</label>
                        <p>
                            <b>“绿地G优会员卡” 1 张</b><br />
                            享绿地购房、理财、酒店、旅游、购物、教育、汽车等专属折扣，最低6折起，样样都省钱。
                        </p>
                    </div>
                    <div className={styles.descWrap}>
                        <label>您可获得：</label>
                        <p>
                            <b>每邀请一位新用户得 {pageData.inviteNewClientPoint} G优积分</b>
                        </p>
                    </div>
                    <p>G优APP推荐官绿地员工专享版相比普通版：<br /> 1.每日推荐人数无上限；</p>
                    <p>G优积分是什么？<br /> 绿地G优积分可以在积分专区兑换各类精美礼品。</p>
                </div>
                {pageData.productList && pageData.productList.length > 0 ? <div className={`${styles.top} ${styles.shadow}`}>
                    <div className={styles.title}>热门兑换</div>
                    <p>已邀请{pageData.inviteNums}人，当前{pageData.myPoint}积分可兑换</p>
                    {pageData.productList.map((item, key) => {
                        return <><div className={styles.picList} onClick={() => {
                            trackEvent('点击热门兑换');
                            if (Platform.check.isApp()) {

                                JsBridge.open(`${window.location.origin}/taro/subPages/user/mall/product/index?goodsId=${item.productId}&goodsType=${item.merchant === '1' ? 'limit' : 'hot'}`);
                            } else {
                                setStatus('show')
                            }
                        }}>
                            <div className={styles.productHead}>
                                <img src={userIcon} />
                                <span>x{Math.ceil(parseFloat(item.pointCost) / parseFloat(pageData.inviteNewClientPoint))}</span>
                                <span>{pageData.exchangeCondition.replace(/\?/g, Math.ceil(parseFloat(item.pointCost) / parseFloat(pageData.inviteNewClientPoint)))}</span>
                            </div>
                            <div className={styles.productContent}>
                                <div className={styles.productImage}>
                                    <img src={item.bigPicUrl} />
                                </div>
                                <div className={styles.productMain}>
                                    <h2>{item.goodsName}</h2>
                                    <h3>{item.pointCost}积分</h3>
                                    <div className={styles.tabs}>
                                        {item.productTag && item.productTag.map((tag, key2) => {
                                            return <label key={key2}>{tag}</label>
                                        })}
                                    </div>
                                </div>
                                <button>去兑换</button>
                            </div>
                        </div>
                            {key < pageData.productList.length - 1 ? <div className={styles.hr} /> : false}
                        </>
                    })}

                </div> : false}

                <div className={`${styles.top} ${styles.shadow}`}>
                    <div className={styles.title}>活动流程</div>
                    <div className={styles.step}>
                        <div className={styles.num}>1</div>
                        <div className={styles.line}></div>
                        <div className={styles.num}>2</div>
                        <div className={styles.line}></div>
                        <div className={styles.num}>3</div>
                    </div>
                    <div className={styles.stepDesc}>
                        <p>分享邀请给好友</p>
                        <div className={styles.line}>&gt;</div>
                        <p>好友下载APP<br />注册成功</p>
                        <div className={styles.line}>&gt;</div>
                        <p>您得{pageData.inviteNewClientPoint}积分</p>
                    </div>
                </div>
                <Slogan className={styles.slogan} />
                <div className={`${styles.footer} ${isFullScreen ? styles.fullScreen : ''}`}>
                    <div className={styles.footerWrap}>
                        <div className={styles.footerButton} onClick={() => {
                            setShareModalStatus(true);
                        }} >
                            <img src={codeIcon} />
                            <p>展示邀请码</p>
                        </div>
                    </div>
                </div>
            </div>

            {shareModalStatus ? <div className={`${shareModalStyles.shareMask} ${isFullScreen ? shareModalStyles.fullScreen : ''}`}>
                <div onClick={() => {
                    setShareModalStatus(false);
                }} className={shareModalStyles.mask}></div>
                <div className={shareModalStyles.shareBox}>
                    <div className={shareModalStyles.shareTitle}>面对面扫码邀请</div>
                    <div className={shareModalStyles.close} onClick={() => {
                        setShareModalStatus(false);
                    }} >
                        <img src={closeIcon} />
                    </div>
                    <div className={shareModalStyles.qrcode}>
                        <QRCode
                            value={shareImageUrl}
                            size={120}
                            fgColor="#000000" />
                        <div className={shareModalStyles.codeBox}>
                            您的邀请码：{pageData.inviteCode}
                        </div>
                    </div>
                    <p className={shareModalStyles.other}>其他邀请方式</p>
                    <div className={shareModalStyles.shareInfo}>
                        <button onClick={async () => {
                            shareToPlatform(2);
                        }}><img src={MomentImage} />
                            <p>朋友圈</p>
                        </button>

                        <button onClick={async () => {
                            shareToPlatform(1);
                        }}><img src={WechartImage} />
                            <p>微信好友</p>
                        </button>
                        <button onClick={async () => {
                            shareToPlatform(4);
                        }}><img src={QQImage} />
                            <p>QQ好友</p>
                        </button>
                        <button onClick={async () => {
                            shareToPlatform(5);
                        }}><img src={qqkongjianImage} />
                            <p>QQ空间</p>
                        </button>
                    </div>
                </div></div> : false}
        </div>}




    </div>;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
