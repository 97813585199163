import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styles from './rule.module.scss';
import Library from 'library';
const { Platform, Util, JsBridge } = Library;
export const Rule = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { callback ={} } = location || {};
    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>友情提示</div>
                <div className={styles.modalContent}>我已阅读并同意 <a onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open(`${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/register`)
                    } else {
                        window.location.href = `${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/register`;
                    }
                }}>《绿地G优注册协议》、</a><a onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open(`${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/privacy`)
                    } else {
                        window.location.href = `${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/privacy`;
                    }
                }}>《绿地G优隐私政策》、</a><a onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open('/front/member/register/protocol')
                    } else {
                        window.location.href = window.location.origin+'/front/member/register/protocol';
                    }
                }}>《G优尊享会员服务免责条款》</a>，请认真阅读全部条款，接受后可开始使用我们的服务。
                </div>
                <div className={styles.modalFooter}>
                    <div className={styles.cancle} onClick={()=>{
                        history.goBack();
                        callback(false);
                    }}>不同意</div>
                    <div className={styles.line}/>
                    <div className={styles.agree} onClick={()=>{
                        history.goBack();
                        callback(true);
                    }}>同意</div>
                </div>
            </div>
        </div>
    </div>
}
