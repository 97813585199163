import {Popup} from 'antd-mobile'
import ListView from "../../../../components/ListView1";
import styles from "./eggStockRecordPop.module.scss";
import egg_bt_guanbi from "../../../../images/egg_bt_guanbi.png";
import React, {useEffect, useRef, useState} from "react";
import Services from "../../../../services";
import {isArray} from "../../../../library/util";


const EggStockRecordPop = ({maskVisible, setMaskVisible, userInfo}) => {
    const [dataSource, setDataSoure] = useState({
        totalNumber: 0,
        totalSize: 0, //总数量
        lotteryTicketRecords: [],
    });
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    let pageNum = useRef(1).current;
    let pageSize = 10;

    const btnClick = () => {
        setMaskVisible(!maskVisible);
    }

    useEffect(() => {
        maskVisible && loadData();
    }, [maskVisible, userInfo]);

    const loadData = async () => { //获取聚合页抽签劵记录
        if (userInfo.isLogin) {
            let param = {
                clientId: userInfo.clientId,
                pageNum: 1,
                pageSize: pageSize,
            }
            let res = await Services('MainPage', {code: 600684}, param)
            if (res.status > 0) {
                let data = res.data;
                let totalSize = parseInt(data.totalSize) || 0;
                if (isArray(data.lotteryTicketRecords) && data.lotteryTicketRecords.length) {
                    setDataSoure(data);
                    setIsLoad(true)
                    if (data.lotteryTicketRecords.length < totalSize) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                } else {
                    setHasMore(false)
                }
            } else {
                setHasMore(false)
            }
        }
    }

    const loadMore = async () => {
        pageNum = pageNum + 1;
        let param = {
            clientId: userInfo.clientId,
            pageNum: pageNum,
            pageSize: pageSize,
        }
        let res = await Services('MainPage', {code: 600684}, param)
        if (res.status > 0) {
            let data = res.data;
            if (isArray(data.lotteryTicketRecords) && data.lotteryTicketRecords.length) {
                let totalSize = parseInt(data.totalSize) || 0;
                let totalArr = dataSource['lotteryTicketRecords'].concat(data.lotteryTicketRecords);
                setDataSoure({
                    totalNumber: dataSource.totalNumber,
                    totalSize: dataSource.totalSize,
                    lotteryTicketRecords: totalArr
                });
                if (totalArr.length < totalSize) {
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            } else {
                setHasMore(false)
            }
        }
    }

    const listItem = (item) => {
        return (
            <div className={styles.item_container}>
                <div className={styles.item_left_container}>
                    <div className={styles.item_title}>{item.descInfo}</div>
                    <div className={styles.item_title1}>{item.getTime}</div>
                </div>
                <div className={styles.item_right_container}>
                    <span/>
                    <span>{`+${item.num}抽签劵`}</span>
                </div>
                <div className={styles.line}/>
            </div>
        )
    }

    return (
        <Popup
            visible={maskVisible}
            onMaskClick={() => btnClick()}
            bodyStyle={{
                backgroundColor: "transparent", minHeight: '60vh',
            }}>
            <div className={styles.container}>
                <div className={styles.header_father}>
                    <div className={styles.header_title_father}>
                        <span>抽签劵记录</span>
                        <img src={egg_bt_guanbi} onClick={() => btnClick()}/>
                    </div>
                    <div className={styles.count}>{`抽签劵数量: ${dataSource.totalNumber}`}</div>
                </div>
                <div className={styles.list_father}>
                    <ListView
                        onRefresh={() => loadData()}
                        list={dataSource.lotteryTicketRecords}
                        hasMore={hasMore}
                        isLoad={isLoad}
                        loadMore={loadMore}
                        ListItem={({item}) => listItem(item)}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default EggStockRecordPop;
