import Home from './home';
import Rule from './rule';
import RewardModal from './rewardModal';
import ExitModal from './exitModal';
import { Patches } from './patches';
import { Exchange } from './exchange';
import { Join } from './join';
import { JoinSuccess } from './joinSuccess';
import { InviteRecord } from './inviteRecord';
import {ExchangeSuccess} from './exchangeSuccess';
import FirstDay  from './firstDayModal';
export default [
    {
        path: '/activities/dailySignin/index',
        component: Home,
        title: '活动详情'
    },
    {
        path: '/activities/dailySignin/index/rule',
        component: Rule,
        title: '活动详情'
    }, {
        path: '/activities/dailySignin/index/reward',
        component: RewardModal,
        title: '活动详情'
    }, {
        path: '/activities/dailySignin/index/exitModal',
        component: ExitModal,
        title: '活动详情'
    },
    {
        path: '/front/activities/dailySignin/patches',
        component: Patches,
        title: '碎片记录'
    },
    {
        path: '/front/activities/dailySignin/index/exchange',
        component: Exchange,
        title: '活动详情'
    },
    {
        path: '/front/activities/dailySignin/join',
        component: Join,
        title: '连续签到领奖品'
    },
    {
        path: '/front/activities/dailySignin/joinSuccess',
        component: JoinSuccess,
        title: '连续签到领奖品'
    },
    {
        path: '/front/activities/dailySignin/inviteRecord',
        component: InviteRecord,
        title: '邀新记录'
    },
    {
        path: '/front/activities/dailySignin/index/exchangeSuccess',
        component: ExchangeSuccess,
        title: '活动详情'
    },
    {
        path: '/front/activities/dailySignin/index/firstDay',
        component: FirstDay,
        title: '活动详情'
    }
]
