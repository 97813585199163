import React from "react";
import './worldCupRulesModal.scss';
import { Mask } from 'antd-mobile';

const RulesModal = ({rulesModalVisible, onClose}) => {
  return <Mask visible={rulesModalVisible} className="world-cup-rules-mask">
    <div className="world-cup-rules-modal">
      <div className="world-cup-rules-title">
        <span>2022世界杯竞猜活动规则</span>
      </div>
      <div className="world-cup-rules-content">
        <iframe 
          src={`${process.env.REACT_APP_SERVICE_DOMAIN}/agreement?type=7&agrVersion=0.0`}
          frameborder="0" 
          className="rules-iframe"
          title="my-ifame"
        >
        </iframe>
      </div>
      <div className="rules-modal-btn" onClick={onClose}>我知道了</div>
    </div>
  </Mask>
}

export default RulesModal;