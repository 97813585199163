/**
 * @description 每日签到 签到记录
*/

import React, { useState, useEffect } from 'react';
import Library from 'library';
import { NotifySwitch } from '../notifySwitch';
import { useHistory, useLocation } from 'react-router-dom';

import bgTop from '../../imgs/pic_hei.png';
import bgBottom from '../../imgs/pic_huang.png';
import lastBgBottom from '../../imgs/pic_huang2.png';

import styles from './index.module.scss';

export const SignReports = (props) => {

    const {state, signInDay, signInArray} = props;

    return (
        <div className={styles.fragmentDesc}>
            <div className={styles.fragmentTop}>
                <span className={styles.signText}>已连续签到 <i>{signInDay ?? 0}</i> 天</span>
                <div className={styles.signTip}>
                    {state ? <NotifySwitch isNotice={state.noticeSwtich}/> : false}
                </div>
            </div>
            <div className={styles.fragmentCount}>
                {signInArray && signInArray.map((item, index) => {
                    if (item.signPrize > 0 && item.prizeType > 1) {
                        if (index === signInArray.length - 1) {
                            if (item.signInStatus === 1) {
                                return <div
                                    className={styles.lastFragItem}
                                    key={`item-${index}`}
                                >
                                    <img className={styles.bgTop} src={bgTop} alt=''/>
                                    <img className={styles.lastBgBottom} src={lastBgBottom} alt=''/>
                                    <span className={styles.lianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.prizeCount}>+{item.signPrize}</span>
                                    <span className={styles.prizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            } else {
                                return <div
                                    className={`${styles.lastItem} ${styles.weilingqu}`}
                                    key={`item-${index}`}
                                >
                                    <span className={styles.noLianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.noPrizeCount}>+{item.signPrize}</span>
                                    <span className={styles.noPrizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            }
                        } else {
                            if (item.signInStatus === 1) {
                                return <div
                                    className={styles.fragReceived}
                                    key={`item-${index}`}
                                >
                                    <img className={styles.bgTop} src={bgTop} alt=''/>
                                    <img className={styles.bgBottom} src={bgBottom} alt=''/>
                                    <span className={styles.lianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.prizeCount}>+{item.signPrize}</span>
                                    <span className={styles.prizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            } else {
                                return <div
                                    className={`${styles.fragmentCountItem} ${styles.weilingqu}`}
                                    key={`item-${index}`}
                                >
                                    <span className={styles.noLianxuQianDao}>连签{item.signOrder}天</span>
                                    <span className={styles.noPrizeCount}>+{item.signPrize}</span>
                                    <span className={styles.noPrizeText}>得到{item.signPrize}碎片</span>
                                </div>
                            }
                        }
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    )
}