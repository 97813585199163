import React, { useEffect, useState } from 'react';
import Library from 'library';
import { useHistory } from 'react-router-dom';
import Services from 'services';
import { ShouldOpenInWechat } from 'components/shouldOpenInWechat';

const { Platform } = Library;
export const IsRegisted = (props) => {
    const history = useHistory();
    const [loginResult, setLoginResult] = useState(null);

    const getLoginResult = async () => {
        if (Platform.check.isH5()) {
            return;
        }
        if (Platform.check.isWechat()) {
            const result = await Services('GuMember', 'IsRegisted', {});
            if (result.status > 0 && result.data && !result.data.msg) {
                history.replace('/front/member/register', { redirect: window.location.pathname });
            }
        }
        setLoginResult(true);
    }

    useEffect(() => {
        getLoginResult();
    }, []);
    if (Platform.check.isH5()) {
        return <ShouldOpenInWechat />;
    }
    if (loginResult === null) {
        return false;
    }
    return loginResult ? <>{props.children}</> : false;
};
