
import styles from './redTheme.module.scss';
import React from 'react';
export const Rules = () => {
    return <div className={styles.rulesContainer}>
        <div className={styles.ruleContent}>
            <p>1.活动参与方式：注册成为G优会员，在活动页面输入活动指定口令即可参与“建党百年，辉煌绿地”活动，每位参与人为党和国家送上祝福寄语后，即可抽奖。活动结束后将从祝福寄语中选出大奖；</p>
            <p>2.活动时间：即日起至2021年7月31日；大奖揭晓时间：2021年8月8日；</p>
            <p>3.活动期间，每位用户为党和国家送祝福寄语和参加抽奖的机会仅限一次；</p>
            <p>4.如出现不可抗力或情势变更的情况（包括重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动遭受严重网络攻击或因系统故障等）导致活动需要停止或调整的，我们有权终止活动而无需向用户进行赔偿或补偿；</p>
            <p>5.在参与活动过程中，如用户出现作弊等违法违规行为我们有权撤销用户参与活动与奖励使用资格，并有权收回用户已经获得的权益；</p>
            <p>6.绿地吉客智能有限公司可根据本活动实际情况对活动规则进行优化或调整；</p>
            <p>7.活动由绿地G优APP提供，与设备生产商Apple Inc.公司无关。</p>
            <p>8.关于奖品：</p>
            <p>1）海南全家游一次（机票+酒店），价值约10000元。需在2021年12月31日前完成预定及出游，如若出现无库存情况，将提供同等价值产品</p>
            <p>2）华为P40Pro+（8G+256G），价值7988元，中奖后会由客服电话联系确认收货信息</p>
            <p>3）戴森V15手持无线激光吸尘器，价值5490元，中奖后会由客服电话联系确认收货信息</p>
            <p>4）PicoNeo3 256G先锋版VR一体机，价值3099元，中奖后会由客服电话联系确认收货信息</p>
            <p>5）Nu-calm压力管理系统体验机会一次，时长50分钟，价值500元。中奖后会由客服电话联系确认体验时间</p>
            <p>6）PRECIOSA精美饰品，中奖后会由客服电话联系确认收货信息</p>
            <p>7）其他活动奖品可进入 “我的”-“优惠券” 查看使用规则</p>
        </div>
    </div>
}