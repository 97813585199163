import { WEIXIN_API_CONFIG_START, WEIXIN_API_CONFIG_END } from '../../../constants';
import * as Immutable from 'immutable';

const initialState = Immutable.fromJS({
    config: false
});

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function wxApi(state = initialState, action) {
    switch (action.type) {
        case WEIXIN_API_CONFIG_START:
            return state.merge({ config: false });

        case WEIXIN_API_CONFIG_END:
            return state.merge({ config: true })
        default:
            return state;
    }
}
