/**
 * @description 下载引导页
 * @returns  route: /front/activities/double11/inviteSuccess
*/
import React, {useState} from "react";
import styles from './index.module.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import success_download from 'images/double11/inviteSuccess.png';
import Library from 'library';
const { Util } = Library;


const appSchema = 'gktapp://com.gkewang';

const InviteSuccess = () => {
  const { type='' } = Util.url.paramsToObj() || {};
  const [maskShow, setMaskShow] = useState(false);

  const targetUrl = `${process.env.REACT_APP_GKT_LINK}/front/activities/double11`;

  const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = launchUrl;
      setTimeout(() => {
          window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
      }, 3000);
    }
  }

  return <div className={styles.guide_share}>
    <img src={success_download} alt="" />
    <div className={styles.load_btn} onClick={() => openApp()}></div>
    {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

export default InviteSuccess;
