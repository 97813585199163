/**
 * @description 邀新页面
 * @interface route: `/front/inviter/registers/${Buffer.from(JSON.stringify({activityCode,inviteCode})).toString("base64")}`
 * @example {
    // 将字符串编码为 base64
    const inputString = JSON.stringify({ac:'activityCode',ic: 'inviteCode'});
    const encodedBuffer = Buffer.from(inputString).toString("base64");
    console.log(encodedBuffer); // "eyJhY3Rpdml0eUNvZGUiOiIxMDA4NiIsImludml0ZUNvZGUiOiJ4eXp3SyJ9"

    // 将 base64 编码的字符串解码
    const decodedBuffer = Buffer.from(encodedBuffer, "base64");
    const decodedString = decodedBuffer.toString();
    console.log(decodedString); // {"ac":"activityCode","ic":"inviteCode"}
 * }
 * @param {为减少编码长度,ac:活动码;ic:邀请码 }
*/

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import loginBg from 'images/login-bg@2x.png';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Actions } from 'reduxs';
import Services from 'services';
import Library from 'library';
import { Toast } from 'antd-mobile';

const { Platform, Util } = Library;
let SwiperNc = null;

/**
 * @deprecated 在landPage替换该配置
 * 
 * @description 在这做跳转路径配置
 * @key activityCode 活动编码
 * @value routePath 路由
 * **/
const activityPathMap = {
    'child2023': '/front/activities/childrensDay/share',
    'fathersDay': '/front/activities/fathersDay/share',
    'secondaryVariantApe':'/front/inviter/guessingActy',
    valentinesDay: '/front/activities/qixiInviteSuccess',
    'midAutNationalDay': '/front/activities/inviteSuccess',
    singlesDay2023: '/front/activities/double11/inviteSuccess',
    INVITECLIE2: '/front/activities/regularShare/landPage',
};

export default (props) => {

    const [isAgree, setIsAgree] = useState(false);
    const history = useHistory();
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const [isNcLoaded, setIsNcLoaded] = useState(false);

    const countDown = useSelector((state) => {
        return state.sms.toJS().couponCounter;
    });

    const { base64 } = useParams();
    const param = useMemo(()=>{
        return base64 ? JSON.parse(decodeURIComponent(Buffer.from(base64, "base64").toString())):{}
    },[base64])

    useEffect(() => {
        // 根据思珉要求，不调用该接口各个环境保持一致
        isRegisted();
        initNC();
        console.log('=========:',param)
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [countDown]);

    //重定向
    const redirectRoute = ()=> {
        const activityCode = param?.ac
        let targetPath = activityPathMap[activityCode];
        if(!targetPath && activityCode) {
            targetPath = `/front/inviter/landPage/${activityCode}`
        }
        if (targetPath) {
            const _param = Object.assign({}, param);
            delete _param.ac;
            delete _param.ic;
            if(Object.keys(_param).length) {
                history.replace(`${targetPath}?${Library.Util.url.objectToQueryParams(_param)}`);
            }else {
                history.replace(targetPath);
            }
           
        } else {
            history.replace('/front/inviter/success');
        }
    }

    const isRegisted = async () => {
        // if(process.env.REACT_APP_MALL_ENV == 'test') return;
        const result = await Services('GuMember', 'IsRegisted', {});
        if (result && result.data && result.data.msg) {
            redirectRoute()
        }
    }
    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            // setSwiperCaptcha(e.SwiperCaptcha);
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    }

    const submit = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (!code) {
            Actions.alert.show('请输入短信验证码');
            return;
        }
        if (!isAgree) {
            Actions.alert.show('请先同意协议');
            return;
        }
        const activityCode = param?.ac
        const inviteCode = param?.ic
        // inviteZone2024: 商品邀新专区使用
        const entityId = param?.skuId
        const params = {mobile: mobile, dynamicCode: code, invitationCode: inviteCode, activityCode: activityCode, entityId};
        const result = await Services('GuMember', (Platform.check.isH5() ? 'RegistOrLoginForH5' : 'RegistOrLogin'), params);
        if (result.status > 0) {
            Toast.show(result.data.msg || '注 册 成 功');
            setTimeout(()=>{
                redirectRoute()
            },1000)
            
        }
    };

    const sendSms = async () => {
        if (!Library.Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('DynamicCode', 'GetDynamicCode', {
                    mobile,
                    ...res
                });
                if (result.status > 0) {
                    Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
                }
            }
        }
    }

    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.sms}> {lastSecond} 秒 </button>;
        } else {
            return <button onClick={sendSms} className={styles.sms}>获取</button>;
        }
    };

    return (
        <div className={styles.container}>

            <div className={styles.headers}>
                <img src={loginBg} alt='头图' />
            </div>
            <div className={styles.form}>

                <div className={styles.inputWrap}>
                    <p><label>手机</label></p>
                    <div className={styles.inputFlex}>
                        <div className={styles.input}>
                            <input onChange={(event) => {
                                setMobile(event.target.value);
                            }} type='tel' value={mobile} placeholder='请输入' maxLength='11' />
                        </div>
                    </div>
                </div>

                <div className={styles.inputWrap}>
                    <p><label>验证码</label></p>
                    <div className={styles.inputFlex}>
                        <div className={styles.input}>
                            <input onChange={(event) => {
                                setCode(event.target.value);
                            }} type='text' val={code} placeholder='请输入' maxLength='6' />
                        </div>
                        {sendSmsRender()}

                    </div>
                </div>

                <div className={styles.agreeWrap} onClick={() => {
                    setIsAgree(!isAgree);
                }}>
                    <i className={`${styles.agreeIcon} ${isAgree ? '' : styles.notAgreeIcon}`}></i>
                    <p>我已阅读并同意<Link to='/front/inviter/register/protocol'>《G优尊享会员服务免责条款》</Link></p>
                </div>
                <div onClick={submit} className={styles.button}>注册/登录并激活会员</div>
            </div>
        </div>
    )
}
