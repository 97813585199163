/**
*读取图片
*/
export function loadImage(img) {
    return new Promise((resolve, reject) => {
        console.log('on load');
        if (img.complete) {
            console.log('complete');

            resolve(img);
        } else {
            img.onload = () => {
                console.log('loaded');
                resolve(img);
            };
        }
        img.onerror = img.onabort = (e) => {
            resolve(null);
        };
    });
}
