/**
 * @description 问卷调查接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';

class Service {

    /**
     * @description 获取问卷调查信息接口
     * @code 101332
     * @param {*} params 
     * @param { 是否显示加载loading } customLoading
     * @returns 
     */
    static getQuestionInfo = async (params={}, customLoading)=> {
        const code = '101332'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 提交保存问卷调查接口
     * @code 101333
     * @param {activityCode: '活动码', voteList: [{questionNo: 题号, questionType: 0 单选 1 多选 2 文字, content: 内容, answer: 回答号}]} params 
     * @param { 是否显示加载loading } customLoading
     * @returns 
     */
    static saveCommitInfo = async (params={}, customLoading)=> {
        const code = '101333'
        try {
            const res = await Services('MainPage', { code }, params, !customLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
}

export default Service;