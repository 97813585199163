import React from 'react';
import Library from 'library';
import styles from './success.module.scss';
import successIcon from './img/success.png';
import QRCode from './img/QRCode.png';


const appDomain = {
    development: '//app-test.gkewang.com',
    beta: '//app-test.gkewang.com',
    uat: '//app-uat.gkewang.com',
    product: '//app.gkewang.com'
};

export class Success extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            downUrl: ''
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ downUrl: Library.Util.APP_INVITER_DOWNLOAD_URL });
    }

    render() {
        const { downUrl } = this.state;
        return <div className={styles.registerSuccess}>
            <div className={styles.successHead}>
                <img className={styles.headMain} src={successIcon} />
                <p className={styles.headMainTit}>注册成功</p>
            </div>
            <a href={downUrl} className={styles.downloadBtn}>下载绿地G优APP</a>
            <div className={styles.successContent}>
                <div className={styles.title}>
                    <span>—</span>
                    <span className={styles.titleBold}> 长按关注公众号 </span>
                    <span>—</span>
                </div>
                <div className={styles.tip}>长按二维码关注公众号，实时关注理财咨询</div>
                <img className={styles.QRCodeImg} src={QRCode} />
                <div className={styles.QRCodeTit}>绿地G优</div>
            </div>

        </div>;
    }
}