import React from 'react';
import imgTitle from './img/Integral_draw-text.png';
import modalBackground from './img/Lucky_draw-window-winning.png';
import styles from './lottery.module.scss';

import Library from "../../../library";
import Services from "../../../services";
import {isArray} from "../../../library/util";
import {Modal} from "antd-mobile";

const activityCode = 'Lottery23';
const {JsBridge, Util} = Library;
let timer;
let runner;

export class LotteryHome extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            stars: [],
            count: 0,
            isBegin: false,
            endSteps: 0,
            thisStep: 0,
            reward: null,
            buttons: [],
        };
        this.userInfo = {};
        this.lightChange = this.lightChange.bind(this);
        this.backHandler = this.backHandler.bind(this);
    }

    async componentDidMount() {
        JsBridge.setTitle('积分抽奖');
        this.getUserInfo();
        const stars = [];
        for (var i = 0; i < 34; i++) {
            stars.push(i);
        }
        this.setState({
            stars
        });

        timer = setInterval(this.lightChange, 1000);
        setTimeout(async () => {
            const fetchResult = await Services('MainPage', {code: 300064}, {'activityCode': activityCode});
            if (fetchResult.status > 0) {
                const result = fetchResult.data;
                let tempList = [];
                if (result.lotteryList && isArray(result.lotteryList) && result.lotteryList.length === 8) {
                    tempList = result.lotteryList.sort((a, b) => a.index - b.index);
                    tempList = tempList.map(i => {
                        let step = 0
                        i.index === 0 && (step = 0)
                        i.index === 1 && (step = 1)
                        i.index === 2 && (step = 2)
                        i.index === 3 && (step = 7)
                        i.index === 4 && (step = 3)
                        i.index === 5 && (step = 6)
                        i.index === 6 && (step = 5)
                        i.index === 7 && (step = 4)
                        return {...i, step: step}
                    })
                    tempList.splice(4, 0, {type: 'start'})
                }
                this.setState({
                    point: result.point,
                    playTimes: result.playTimes,
                    allTimes: result.allTimes,
                    costPoint: result.costPoint,
                    buttons: tempList,
                });
            }
        }, 500);

        this.backHandler();
    }

    componentWillUnmout() {
        clearInterval(timer);
        clearTimeout(runner);
        JsBridge.runAction('immersivemodal', 'hide', []);
    }

    getUserInfo() {
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            if (res.isLogin) {
                this.userInfo = res;
            }
        })
    }

    async backHandler() {
        await JsBridge.backHandler();
        if (this.state.isBegin) {

        } else {
            JsBridge.close();
        }
        this.backHandler();
    }

    lightChange() {
        let {showLight} = this.state;
        this.setState({showLight: !showLight});
    }

    async getAward(index) {
        //抽奖
        // const result = {
        //     index: parseInt(Math.random() * 8),
        //     value: 1,
        //     type: 'integral'
        // };
        let {thisStep, point, costPoint, playTimes, allTimes, hasClick} = this.state;
        if (!point && !costPoint && !playTimes && !allTimes || hasClick) {
            return;
        }
        this.setState({hasClick: true});

        point = point - costPoint;
        if (point < 0) {
            const modalResult = await JsBridge.runAction('immersivemodal', 'show', [`您可以签到做任务获取积分哦！`, '积分不足',
                [
                    {
                        name: '取消',
                        style: {color: '#999'}
                    },
                    {
                        name: '赚取积分'
                    }
                ]
            ]);
            if (modalResult === '取消') {
                this.setState({hasClick: false});
                await JsBridge.runAction('immersivemodal', 'hide', []);
            } else {
                await JsBridge.runAction('immersivemodal', 'hide', []);
                JsBridge.navigate('tabMall');
            }
            return;
        }
        if (playTimes >= allTimes) {
            await JsBridge.runAction('immersivemodal', 'show', [`今日抽奖次数已用完，请明天再来哦！`, '游戏次数不足',
                [
                    {
                        name: '确定'
                    }
                ]
            ]);
            await JsBridge.runAction('immersivemodal', 'hide', []);
            this.setState({hasClick: false});
            return;
        }

        const fetchResult = await Services('MainPage', {code: 300060}, {
            activityCode: activityCode,
            clientId: this.userInfo?.clientId || ''
        });
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            playTimes++;
            const diff = Math.abs(8 - thisStep % 8 + this.mappingIndexTostep(result.index));
            const endSteps = 24 + diff;
            this.setState({isBegin: true, endSteps: endSteps, point, playTimes, reward: result}, () => {
                runner = setTimeout(this.startAnimation.bind(this), 200);
            });
        }
        this.setState({hasClick: false});
    }

    mappingIndexTostep(index) {
        let step = index
        index === 0 && (step = 0)
        index === 1 && (step = 1)
        index === 2 && (step = 2)
        index === 3 && (step = 7)
        index === 4 && (step = 3)
        index === 5 && (step = 6)
        index === 6 && (step = 5)
        index === 7 && (step = 4)
        return step
    }

    async startAnimation(interval = 500) {
        let {thisStep, endSteps} = this.state;
        if (endSteps <= 0) {
            clearTimeout(runner);
            this.reward(thisStep);
            return;
        } else {
            endSteps = endSteps - 1;
            thisStep = thisStep + 1;
            this.setState({endSteps, thisStep});
        }
        if (endSteps < 5) {
            interval = Math.min(1000, interval + 200);
        } else {
            interval = Math.max(50, interval - 200);
        }
        runner = setTimeout(this.startAnimation.bind(this, interval), interval);
    }

    async reward(thisStep) {
        let {reward, point, buttons} = this.state;
        //结束抽奖，给出提示
        // const thisItem = buttons.filter((item) => {
        //     console.log(item);
        //     return parseInt(reward.index) % 8 === item.step
        // })[0];
      //  0积分， 3实物 ，8数字藏品，9未中奖
        if (reward.type === 9) { //未中奖
            this.showDialog(reward);
            this.setState({isBegin: false});
            return;
        }

        if (reward.type === 0) { //积分
            point = point + parseInt(reward.value, 10) || 0;
        }
        this.showDialog(reward);
        this.setState({isBegin: false, point});
    }

    closeDialog() {
        Modal.clear();
    }

    editAddress(reward) { //编辑地址
        // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/activities/lottery/views/addressMgr?id=${reward.id}`});
        const param = Util.url.objectToQueryParams({
            idf: 'lottery',
            activityCode: 'Lottery23',
            id: reward.id,
            name: reward.value,
            imgUrl: reward.imgUrl
        })
        JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/guapp/activityOrder?${param}` });

    }

    lookMyCollection() { //查看藏品
        JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_NFT_LINK}/myCollection`});
    }

    leftBtnClick(reward) {//0积分，1优惠券 3实物 ，4会员,8数字藏品，9未中奖
        let type = reward.type;
        this.closeDialog();
        let timer = setTimeout(() => {
            clearTimeout(timer);
            if (type === 8) { //跳到我的藏品
                this.lookMyCollection();
            } else if (type === 1) { //优惠券
                JsBridge.push('Web', {uri: `${process.env.REACT_APP_GKT_LINK}/taro/subPages/user/coupon/myCoupon/index`});
            }
        }, 500);
    }

    rightBtnClick(reward) {//0积分，1优惠券 3实物 ，4会员,8数字藏品，9未中奖
        let type = reward.type;
        this.closeDialog();
        let timer = setTimeout(() => {
            clearTimeout(timer);
            if (type === 3) {
                this.editAddress(reward);
            }
        }, 500);
    }

    showDialog(reward) { //0积分，1优惠券 3实物 ，4会员,8数字藏品，9未中奖
        let type = reward.type;
        let imgUrl = reward.imgUrl;
        let title = '恭喜中奖！';
        let firstTitle = '很遗憾未中奖';
        let secondTitle = '继续抽奖，获奖概率更高哦';
        let leftBtn = '取消';
        let rightBtn = '继续抽奖';
        if (type === 0) {
            title = '恭喜中奖！';
            firstTitle = reward.value;
            secondTitle = `恭喜您抽中${reward.value}\n请在【积分-我的积分】查看`;
            leftBtn = ' 我知道了';
            rightBtn = '继续抽奖';
        } else if (type === 1) {
            title = '恭喜中奖！';
            firstTitle = reward.value;
            secondTitle = `恭喜您抽中${reward.value} \n请在【我的-积分订单】查看`;
            leftBtn = '查看奖品';
            rightBtn = '继续抽奖';
        } else if (type === 3) {
            title = '恭喜中奖！';
            firstTitle = reward.value;
            secondTitle = `恭喜您抽中${reward.value} \n请填写收货地址，我们会第一时间发放奖品`;
            leftBtn = '继续抽奖';
            rightBtn = '填写地址';
        }else if (type === 4) {
            title = '恭喜中奖！';
            firstTitle = reward.value;
            secondTitle = `恭喜您抽中${reward.value}`;
            leftBtn = '我知道了';
            rightBtn = '继续抽奖';
        } else if (type === 8) {
            title = '恭喜中奖！';
            firstTitle = reward.value;
            secondTitle = `恭喜您抽中${reward.value}\n请在【我的-我的藏品】查看`;
            leftBtn = '查看藏品';
            rightBtn = '继续抽奖';
        } else if (type === 9) {
            title = '谢谢参与！';
            firstTitle = '很遗憾未中奖';
            secondTitle = '继续抽奖，获奖概率更高哦';
            leftBtn = '取消';
            rightBtn = '继续抽奖';
        }

        Modal.show({
            content: <div className={styles.dialog_container}>
                <div className={styles.dialog_title}>{title}</div>
                <div className={styles.first_title}>{firstTitle}</div>
                <img src={imgUrl} alt=""/>
                <div className={styles.second_title}>{secondTitle}</div>
                <div className={styles.btn_container}>
                    <div className={styles.left_btn} onClick={() => this.leftBtnClick(reward)}>{leftBtn}</div>
                    <div className={styles.right_btn} onClick={() => this.rightBtnClick(reward)}>{rightBtn}</div>
                </div>
            </div>,
        })
    }

    render() {
        const {stars, count, showLight, isBegin, thisStep, point, playTimes, allTimes, costPoint, buttons} = this.state;
        return <div className={styles.integral_draw_wrap}>
            <div className={styles.title}>
                <img src={imgTitle}/>
            </div>
            <div className={styles.draw_wrap}>
                {point || playTimes || allTimes ?
                    <div className={styles.draw_title}>共{point}积分可抽奖, 已抽{playTimes}/{allTimes}</div> :
                    <div className={styles.draw_title}></div>}
                <div className={showLight ? styles.draw_border : `${styles.draw_border} ${styles.light}`}>
                    {stars.map((item, key) => {
                        return <i key={key} className={styles.circle}></i>;
                    })}
                    {isBegin ? <div className={styles.draw_mask}></div> : false}
                    {buttons.map((button, key) => {
                        if (button.type === 'start') {
                            return <div className={styles.draw_start} key={key} onClick={this.getAward.bind(this, 0)}>
                                <h2>开始抽奖</h2>
                                <p>{costPoint > 0 ? '-' + costPoint : costPoint}积分</p>
                            </div>
                        }
                        return <div onClick={() => {
                            if (button.click && typeof (button.click === 'function')) {
                                button.click();
                            }
                        }}
                                    className={thisStep % 8 === button.step ? `${styles.draw_button} ${styles.active}` : styles.draw_button}
                                    key={key}>
                            <img src={button.imgUrl}/>
                            <p>{button.value}</p>
                        </div>;
                    })}

                </div>
            </div>
            <div className={styles.footer}>
                <button className={styles.award} onClick={() => {
                    if (isBegin) {
                        return;
                    }
                    // JsBridge.push('CustomWeb', {uri: `http://192.168.110.14:3000/front/activities/lottery/views/raffleRecord`});
                    JsBridge.push('CustomWeb', {uri: `${ window.location.origin}/front/activities/lottery/views/raffleRecord`});
                }}>抽奖记录
                </button>
                <button className='award' onClick={async () => {
                    if (isBegin) {
                        return;
                    }
                    JsBridge.push('Web', {uri: `${process.env.REACT_APP_GKT_LINK}/front/activities/lottery/rule`});
                }}>活动规则
                </button>
            </div>

        </div>;
    }
}

