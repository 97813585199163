/**
 * @description 母亲节点赞弹框
 * @implements 代码参考于shareHelpModal
*/

import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Mask, Toast } from "antd-mobile";
import bgImage from "images/mothersDay/mail-card.png";
import Service from "../../service";
import './index.scss';

const GiveLoveModal = ({ closeMask, activityInfo }) => {
    const [visible, setVisible] = useState(true);
    const cancel = () => {
        setVisible(false);
        closeMask();
    }
    const zanAction = async ()=> {
        const result = await Service.postThumbsUp({blessingId: activityInfo.blessingId})
        if(result) {
            cancel()
            Toast.show({
                content: '点赞成功',
                afterClose:()=>{
                    
                }
            })
        }
        
    }

    return (
        <Mask visible={visible} onMaskClick={cancel} opacity={0.7} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className="give-love-modal-content">
                <div className="give-love-share-content">
                    <div className={'share_content'} >
                        <div className="share_content_box">
                            <img className={'share_avatar'} src={bgImage} alt="avatar" />
                            <div className="abs_content">
                                <img className='mark-img' src={require('images/mothersDay/mother-me.png')} alt="" />
                                <div className='mail-content'>
                                    <span>{'@妈妈，我有话对您说:'}</span>
                                    <div className='my-blessing'>{decodeURI(activityInfo.blessingWords|| '')}</div>
                                    <div className='mail-end'>
                                        <span>{decodeURI(activityInfo.blessingMobile|| '')}</span>
                                        <span>{decodeURI(activityInfo.blessingCreateTime||'')}</span>
                                    </div>
                                </div>
                                <div className='bottom-give'>
                                    <div className='give-btn' onClick={zanAction}>
                                        <span>点 赞</span>
                                    </div>
                                    <img src={require('images/mothersDay/mothers-home-zan.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'close_botton'} onClick={cancel}>
                    <img src={require('images/mask-light-close.png')} alt="" />
                </div>
            </div>
        </Mask>
    )
}

GiveLoveModal.open = ({ activityCode, activityInfo }) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<GiveLoveModal activityCode={activityCode} activityInfo={activityInfo} closeMask={close} />, div);
}

export default GiveLoveModal;