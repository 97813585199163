import React,{useState} from 'react';
import styles from './hotel.module.scss';
import hotelTopImg from './images/hotle-top@2x.png'
import exhibitImg from './images/Exhibition-1@2x.png'
import exhibitImgOne from './images/Exhibition-2@2x.png'
import exhibitImgTwo from './images/Exhibition-3@2x.png'
import exhibitImgThree from './images/Exhibition-4@2x.png'
import apartmentImg from './images/Apartment-1@2x.png'
import tipsImg from './images/biaoti-icon@2x.png'
import partOneImg from './images/Apartment-2@2x.png'
import partTwoImg from './images/Apartment-3@2x.png'
import partThreeImg from './images/Apartment-4@2x.png'
import partFourImg from './images/Apartment-5@2x.png'
import partFiveImg from './images/Apartment-7@2x.png'
import partSixImg from './images/Apartment-8@2x.png'
import hotelOneImg from './images/Hotel-1@2x.png'
import hotelTwoImg from './images/Hotel-2@2x.png'
import hotelThreeImg  from './images/Hotel-3@2x.png'
import itemOnePic from './images/Hotel-4@2x.png'
import itemTwoPic from './images/Hotel-5@2x.png'
import itemThreePic from './images/Hotel-6@2x.png'
import itemFourPic from './images/Hotel-7@2x.png'
import itemFivePic from './images/Hotel-8@2x.png'
import {Slogon} from './slogon';
import Library from 'library';
import { umengTrack_hotel } from 'library/jsbridge/config'
const { Platform, JsBridge } = Library;
export const  Hotel = ()=>{
    const [index, setSelectedIndex] = useState(0);
    const clickSelected = (index)=>{
        setSelectedIndex(index);   
    };

    const meetingComponent = ()=>{
        return <div className={styles.meetingView}>
                    <img src={exhibitImg} className={styles.topImage}/>
                    <p className={`${styles.gap}`}>
                        集团旗下会展版块先后成立绿地汉诺威会展合资公司和绿地巴塞罗那会展合资公司，开展会展场馆运营服务业务。<br/><br/> 绿地会展版块还将与旅游、酒店板块联动，开发自有的展会活动组织、会展接待服务、商务差旅服务等专项旅游产品，形成具有竞争力的会展旅游业务。
                    </p>
                    <div className={styles.title}>
                        <img src={tipsImg} className={styles.icon}/>
                        <p>绿地会展项目</p>
                        <img src={tipsImg} className={`${styles.icon} ${styles.transfer}`}/>
                    </div>
                    <img src={exhibitImgOne} className={styles.picTwo}/>
                    <div className={styles.title_one}>
                        <p>绿地汉诺威·济南国际博览城国际会展中心</p>
                    </div>
                    <p className={`${styles.gap_one}`}>首期展馆面积：150，000平米 包括12个单层展馆和一个2万平大馆。另有10万平室外展场。<br/><br/>
                        会展区域还包括，主次登录厅，多功能厅，办公区域，会议区域，餐饮区，设备用房以及停车楼。<br/><br/>
                        基地距离机场约10公里，基地内规划设有一个轻轨站，2个ART 站点，北侧规划有城际高铁站点。</p>
                    <img src={exhibitImgTwo} className={styles.picTwo}/>
                    <div className={styles.title_one}>
                        <p>绿地汉诺威·西安空港国际会展中心</p>
                    </div>
                    <p className={styles.gap_one}>展馆净展面积：27500 平米 包括3个单层展馆<br/><br/> 会展区域还包括，3900平米的多功能厅，登录厅，公共大厅，新闻发布厅，办公区域，就餐区，以及停车场。<br/><br/> 基地会展中心项目坐落于西安空港新城，临近咸阳国际机场。</p>
                    <img src={exhibitImgThree} className={styles.picTwo}/>
                    <div className={styles.title_one}>
                        <p>绿地汉诺威·哈尔滨国际博览城会展中心</p>
                    </div>
                    <p className={styles.gap_one}>展馆净展面积：84,000 平米 包括2个单层展馆和4个双层展馆<br/><br/> 会展区域还包括，登录厅，公共大厅，办公区域，就餐区，以及停车区。<br/><br/> 基地位于哈尔滨松北核心区域</p>
               </div>
    };

    const apartmentComponent = ()=>{
        return <div className={styles.apartmentView}>
                    <img src={apartmentImg} className={styles.topImage}/>
                    <p className={styles.gap}>绿地酒店旅游集团在响应 <strong>"房子是用来住的，不是用来炒的"</strong>政策号召下，布局租赁住房；既解决市场上年轻人租住“痛点”，又实现本企业员工的职住平衡，绿地长租公寓产品针对不同的消费群体打造的四个不同的系列。</p>
                    <div className={styles.cardViews}>
                        <div className={styles.cardView}>
                            <img src={partOneImg} className={styles.icon}/>
                            <p className={styles.titleText}>塔尖级高定行政公寓品牌</p>
                            <p className={styles.descText}>终极行政公寓，为都市新贵、商务客、金领提供顶级服务</p>
                        </div>
                        <div className={`${styles.cardView} ${styles.gap_tt}`}>
                            <img src={partTwoImg} className={styles.icon}/>
                            <p className={styles.titleText}>都会主流豪华生活包厢</p>
                            <p className={styles.descText}>为城际商务客、白领提供精致豪华的异地生活方式</p>
                        </div>
                        <div className={`${styles.cardView} ${styles.gat_tt_top}`}>
                            <img src={partThreeImg} className={styles.icon}/>
                            <p className={styles.descText}>为都市青年白领提供长期居住的解决方案</p>
                        </div>
                        <div className={`${styles.cardView} ${styles.gap_tt}  ${styles.gat_tt_top}`}>
                            <img src={partFourImg} className={styles.icon}/>
                            <p className={styles.titleText}>企业首席“乐居官”</p>
                            <p className={styles.descText}>为企业解决蓝领住宿需求</p>
                        </div>
                    </div>
                    <div className={styles.titleApartment}>
                        <img src={tipsImg} className={styles.icon}/>
                        <p>绿地公寓项目</p>
                        <img src={tipsImg} className={`${styles.icon} ${styles.transfer}`}/>
                    </div>
                    <img src={partFiveImg} className={styles.pic_two}/>
                    <p className={styles.title_one}>上海虹桥绿地铂瑞公寓</p>
                    <p className={styles.title_two}>公寓均配有设施齐全的厨房、全进口的高品质厨卫电器、先进的家庭影音系统。独特设计的书吧给予您安静阅读之所及社交空间、静谧温暖的铂瑞餐厅为您提供多样早餐选择。<br/><br/><strong>预订电话:021-85664587</strong></p>
                    <img src={partSixImg} className={styles.pic_two}/>
                    <p className={styles.title_one}>上海徐汇绿地铂瑞服务式公寓</p>
                    <p className={styles.title_two}>共有97套客房，包括面积70平方米的一居室及面积90平方米的两居室两种房型。每套公寓都有优雅的设计，配有阳台、设备齐全的厨房以及私人浴室。入住公寓期间，客户还可免费享受专业礼宾、报刊订阅、预订机票、叫车服务等服务。<br/><br/><strong>预订电话:021-85664587</strong>
                    </p>
                </div>
    };

    const hotelApartment = ()=>{
        return <div className={styles.hotelView}>
                    <img src={hotelOneImg} className={styles.topImage}/>
                    <p className={styles.gap}>绿地酒店旅游集团系世界500强企业绿地集团全资子公司,全球范围内项目分布全世界5大洲14国80城，是中国国内最大酒店物业持有商和管理运营商之一。</p>
                    <img src={hotelTwoImg} className={styles.image_one} />
                    <div className={styles.hotelApartment}>
                        <img src={tipsImg} className={styles.icon}/>
                        <p>绿地酒店服务</p>
                        <img src={tipsImg} className={`${styles.icon} ${styles.transfer}`}/>
                    </div>
                    <img src={hotelThreeImg} className={styles.image_two} />
                    <p className={styles.title_service}>预订服务</p>
                    <p>您可以通过APP预订绿地酒店旅游集团旗下的4大类酒店的四种服务模式：1.酒店预订、2.餐饮预订、3.酒店+主题乐园+餐饮一站式服务、4.酒店特色产品购买等服务。</p>
                    <div className={styles.item}>
                        <p>城市酒店</p>
                        <img src={itemOnePic}/>
                    </div>
                    <div className={styles.item}>
                        <p>特色酒店</p>
                        <img src={itemTwoPic}/>
                    </div>
                    <div className={styles.item}>
                        <p>度假酒店</p>
                        <img src={itemThreePic}/>
                    </div>
                    <div className={styles.item}>
                        <p>城市酒店</p>
                        <img src={itemFourPic}/>
                    </div>
                    <img src={itemFivePic} className={styles.image_three}/>
                    <p className={`${styles.title_service} ${styles.gap_service}`}>会员服务</p>
                    <p className={styles.gap_service_one}>如果您是G优会员，将享受专属会员折扣和会员增值服务。</p>
        </div>
    };

    return <div className={styles.container}>
                <img className={styles.topImage} src={hotelTopImg}/>
                <div className={styles.tabs}>
                    <p className={`${styles.text} ${index === 0 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(0)
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_hotel.topic, '会展');
                    }    
                }
                }>会展</p>
                    <p className={`${styles.text} ${styles.gap} ${index === 1 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(1)
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_hotel.topic, '酒店');
                    }
                    }}>酒店</p>
                    <p className={`${styles.text} ${index === 2 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(2)
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_hotel.topic, '公寓');
                    }}}>公寓</p>
                </div>
                <div className={styles.content}>
                    {index === 0 ? meetingComponent() : null}
                    {index === 1 ? apartmentComponent() : null}
                    {index === 2 ? hotelApartment() : null}
                </div>
                <Slogon  />
           </div>
};
