import React, {useState} from 'react';
import './tab.scss';

const Tab = ({tabs, onClick, currentTab=0}) => {
  const [currentIndex, setCurrentIndex] = useState(currentTab);
  const tabClick = (key) => {
    setCurrentIndex(key);
    onClick && onClick(key);
  }
  return <div className='world-cup-tab'>
    {tabs.map((item, index) => {
      return <div className="tab-item" key={`tab-${index}`} onClick={() => tabClick(index)}>
        <span>{item.title}</span>
        {Number(currentIndex) === index && <span className='active'></span>}
      </div>
    })}
  </div>
}

export default Tab;