import React, { useState, useEffect, useRef, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import services from 'services';
import { CouponCell } from "../couponCell";
import { useEventListener } from "../../../../library/hooks";
import Library from 'library';
import { Actions } from 'reduxs';
const { Fetch, Platform, JsBridge } = Library;
export const MyCoupon = (props) => {
    const [index, setIndex] = useState(0);
    const [tabs, setTabs] = useState([]);
    const history = useHistory();
    const navTab = useRef();
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        getData();
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    const getData = async () => {
        let params = { pageNum: 1, pageSize: 10 };
        const result = await services('ClientCoupon', 'List', params);
        console.log('result', result);
        if (result && result.status > 0) {
            let arr = result.data;
            setTabs(arr);
        }
    }

    const divObj = navTab.current;
    useEffect(() => {
        if (divObj) {
            let width = divObj.clientWidth;
            if (index <= 2) {
                divObj.scrollTo(0, 0);
            } else if (index >= tabs.length - 3) {
                let dis = (width / 375) * 50 * (tabs.length);
                divObj.scrollTo(Math.max(dis, 0), 0);
            } else {
                let x = (width / 375) * 50 * (index - 1);
                divObj.scrollTo(Math.max(x, 0), 0);
            }
        }
    }, [index, divObj, tabs]);


    const handleChangeIndex = index => {
        if (index < tabs.length) {
            setIndex(index);
        }
    };

    return <div className={styles.container}>
        <div className={styles.nav} ref={navTab}>
            <ul>
                {tabs.map((item, key) => {
                    return <li onClick={() => {
                        setIndex(key);
                        // handleSwitch(key, 'end');
                    }} key={key} className={index.toString() === key.toString() ? styles.active : ''}>
                        <a>{`${item.typeName}(${item.count})`}</a>
                    </li>;
                })}
            </ul>
        </div>
        <SwipeableViews
            index={index}
            className={styles.swiper}
            onChangeIndex={handleChangeIndex}
        >
            {tabs.map((item, key) => {
                return <CouponList item={item} key={key} />
            })}
        </SwipeableViews>
        <a className={styles.button} onClick={() => {
            if (Platform.check.isH5()) {
                history.push('/front/member/historyCoupon');
            } else {
                history.push('/front/isRegisted/historyCoupon');
            }
        }}>查看历史优惠券</a>
    </div>;
}

const CouponList = (props) => {
    const [state, setState] = useState({ arrSource: [], pageNum: 1, isLoading: false, isLoadingAll: false });
    useEffect(() => {
        if (props.item.jktClientCouponPojoList && props.item.jktClientCouponPojoList.length > 0) {
            setState({ ...state, arrSource: props.item.jktClientCouponPojoList })
        } else if (state.arrSource.length === 0) {
            getData(true);
        }
    }, []);

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        let { clientHeight, scrollHeight, scrollTop } = pageView.current;
        if (scrollTop + clientHeight >= scrollHeight && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            getData();
            timer.current = setTimeout(() => {
                isDebounce = false;
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    useEventListener('scroll', handler, pageView.current);

    const getData = async (refresh) => {
        setState({ ...state, isLoading: true });
        let currentPageNum = refresh ? 1 : state.pageNum + 1;
        let params = { pageNum: currentPageNum, pageSize: 10 };
        if (props.item.type !== '00') {
            params['type'] = props.item.type;
        }
        const result = await services('ClientCoupon', 'List', params);
        if (result && result.status > 0) {
            let arrTab = result.data;

            let currentItem = null;
            for (let item of arrTab) {
                if (item.type === props.item.type) {
                    currentItem = item;
                    break;
                }
            }
            if (currentItem == null) {
                return;
            }

            let arr = currentItem.jktClientCouponPojoList;
            let arrSource = state.arrSource;
            if (currentPageNum === 1) {
                arrSource = arr;
            } else {
                arrSource = [...arrSource, ...arr];
            }
            setState({ ...state, arrSource: arrSource, pageNum: currentPageNum, isLoading: false, isLoadingAll: currentItem.count <= arrSource.length });
        } else {
            setState({ ...state, isLoading: false });
        }
    }

    let isFlag = false;
    return <ul className={styles.wrap} ref={pageView}>
        {state.arrSource.map((item, index) => {
            return <CouponCell key={index} item={item} onClick={() => {
                if (isFlag) {
                    return;
                }
                isFlag = true;
                if (Platform.name() === 'GuApp') {
                    switch (item.action) {
                        case '0':
                            JsBridge.navigate('ExchangeDetail', { id: item.exchangeId, merchant: item.merchant });
                            break;
                        case '1':
                            break;
                        case '2':
                            JsBridge.navigate('Web', { uri: item.jumpUrl })
                            break;
                    }
                } else {
                    Actions.modal.show('此券当前仅支持“绿地G优”APP中使用，是否前往APP？', '温馨提示', [], Library.Util.ORIGIN_DOWNLOAD_URL);
                }

                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                    isFlag = false;
                    clearTimeout(timer.current);
                }, 500);
            }} />
        })}
    </ul>
}

