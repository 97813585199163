/**
 * @description 积分充值首页
 * @returns /front/member/pointRecharge/home
*/

import React, {useEffect, useState, Fragment} from "react";
import { Tabs } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import { useHistory } from "react-router-dom";
import Library from 'library';
import { Header } from 'components';
import Recharge from "../components/recharge";
import BuyCard from "../components/buyCard";
import Service from "../service";
import Styles from './index.module.scss';

const { JsBridge } = Library;

export default (props) => {

    const [activeKey, setActiveKey] = useState('0');
    const [avatar, setAvatar] = useState('');
    const [dataSource, setDataSource] = useState({
        myPoint: 0,
        activityRule: {},
        list: [],
        themeList: []
    });

    useEffect(() => {
        Service.getAvatar().then(av => {
            setAvatar(av.avatar)
        });
        Service.getTotalPoint().then(res => {
            if(res) {
                dataSource.myPoint = res.data.point || 0;
                setDataSource({...dataSource});
            }
        })
    }, [])
    useEffect(()=>{
        // 切换tab加载数据
        initData()
    },[activeKey])

    const initData = async ()=> {
        const res = activeKey == 1 ? await Service.rechargeDataList(): await Service.getCardTheme();
        if(res) {
            if(activeKey == 1) {
                dataSource.list = res.data;
            }else {
                dataSource.themeList = res.data?.records;
            }
            
        }
        setDataSource({...dataSource});
        
    }

    const goBackListener = ()=> {
        JsBridge.addListener().then(() => {
            // 更新积分
            Service.getTotalPoint().then(res => {
                if(res) {
                    dataSource.myPoint = res.data.point || 0;
                    setDataSource({...dataSource});
                }
            })
        });
    }


    const onRecordClick = (k) => {
        if(k==0) {
            // 充值记录
            if (JsBridge.hasWebViewBridge()) {
                JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/member/pointRecharge/rechargeRecord`});
            }else {
                props.history.push('/front/member/pointRecharge/rechargeRecord')
            }
        }else {
            // 购买记录
            if (JsBridge.hasWebViewBridge()) {
                JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/member/pointRecharge/buyRecord`});
            }else {
                props.history.push('/front/member/pointRecharge/buyRecord')
            }
        }
    }

    // 去充值
    const goRecharge = async (item)=> {
        console.log(item);
        const res = await Service.payCommit({id: item.id,price: item.price}, true);
        if(res) {
            JsBridge.pay({
                orderInfo: {
                    amount: res.data?.price,
                    ...(res.data || {}),
                    resource: 'PointRecharge',
                    //获取支付参数的接口code, 获取支付参数接口都不一样也是无语了
                    code: '600837',
                    title: '充值支付'
                }
            })
            goBackListener();
        }
        
    }
    // 充值卡详情
    const onPointDetail = (item)=> {
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/member/pointRecharge/pointCardDetail?id=${item.id}`});
        }else {
            props.history.push(`/front/member/pointRecharge/pointCardDetail?id=${item.id}`);
        }
    }

    const onMyCads = () => {
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/member/pointRecharge/myPointCards`});
        }else {
            props.history.push(`/front/member/pointRecharge/myPointCards`);
        }
    }

    return (
        <div className={Styles.pointRecharge_home}>
            <Header bgColor={'transparent'} />
            <div className={Styles.Page__wraper}>
                <div className={Styles.bgTop}>
                    <div className={Styles.header_box}>
                        <div className={Styles.user}>
                            <div className={Styles.user_info}>
                                <img src={avatar} alt='' />
                                <span>{dataSource.myPoint}<span>{'积分'}</span></span>
                            </div>
                            <div className={Styles.myCards} onClick={onMyCads}><span>我的充值卡</span></div>
                        </div>
                        
                        <div className={Styles.topBtns}>
                            {
                                ['充值记录','购买记录'].map((item,k)=> {
                                    return (
                                        <div className={Styles.record_btn} key={k} 
                                            onClick={()=>onRecordClick(k)}
                                        >
                                            <span>{item}</span>
                                            <RightOutline fontSize={12} color='#7A4F25' />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Tabs
                    className={Styles.task_tabs}
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                >
                    {
                        ['购买充值卡', '积分充值'].map((item, i) => {
                            return (
                                <Tabs.Tab
                                    key={i}
                                    title={<TabHeaderItem title={item} index={i} active={activeKey == i} />}
                                >
                                    {
                                        i == 0 ? <BuyCard dataSource={dataSource} onPointDetail={onPointDetail}/>:<Recharge dataSource={dataSource} goRecharge={goRecharge}/>
                                    }
                                </Tabs.Tab>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div>
    )
}



const TabHeaderItem = (props) => {
    const { title, active, index } = props
    return (
        <div className={'headerTabItem' + ` ${active ? 'active' : ''}`}>
            {active && <img src={index == 0 ? require('images/pointRecharge/left_active.png'):require('images/pointRecharge/right_active.png')} alt=""/>}
            <div>{title}</div>
            <p className={`${active ? 'active' : ''}`}/>
        </div>
    )
}