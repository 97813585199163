import React from 'react';
import Jsbridge from 'library/jsbridge';
import styles from  './index.module.scss';
import company1 from './image/company1@2x.png';
import company2 from './image/company2@2x.png';
export class AboutUs extends React.Component {

    /**
     * async fetch demo
     * @returns {XML}
     */
    constructor(props) {
        super(props);
        this.state = {
            init: false,
        };
        this.unlisten = null;
    }

    componentWillMount() {
        // Jsbridge.setTitle('关于我们');
        Jsbridge.ready().then(e => {
            Jsbridge.setTitle('关于我们');
        });
    }

    render() {
        return <div className={styles.aboutUs}>
            <img src={company1} />
            <img src={company2} />
            {/* <img src={company3} /> */}
        </div>
    }
}
