
/**
 * @description 导航透明滑动显示
 * @returns 之前的组件移植过来为公共组件
*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Library from 'library';
import styles from './index.module.scss';
import back from 'images/icon/back@2x.png';
import blackBack from 'images/icon/back-black@2x.png';
const { JsBridge, Hooks } = Library;

export default (props) => {

    const { getContainer } = props

    const [height, setHeight] = useState(null);
    const [scrollY, setScrollY] = useState(0);


    const onDidFocus = () => {
        JsBridge.addListener().then(async () => {
            handleScroll();
        });
    }

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        onDidFocus();
    }, []);

    const initData = async () => {
        const { height } = await JsBridge.getStatusBarHeight();
        if (props.theme === 'dark') {
            JsBridge.setStatusBar('setBarStyle', ['dark-content']);
        } else {
            JsBridge.setStatusBar('setBarStyle', ['light-content']);
        }
        setHeight(height);
    }


    const handleScroll = () => {
        let scrollTop = getContainer.scrollTop || document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > 10) {
            JsBridge.setStatusBar('setBarStyle', ['dark-content']);
        } else {
            JsBridge.setStatusBar('setBarStyle', [props.theme === 'dark' ? 'dark-content' : 'light-content']);
        }
        setScrollY(scrollTop)
    }
    Hooks.useEventListener('scroll', handleScroll, getContainer||document);
    const rate = scrollY / 100;

    return <div className={styles.back_rule} style={{ paddingTop: height + 'px', background: `rgba(255,255,255,${rate})` }}>
        <div className={styles.backWrap}>
            <div className={styles.back_area} onClick={() => {
                 console.log("点击了后退按钮!!!");
                 JsBridge.back()
                  }}>
                <img src={rate > 0.5 ? blackBack : back} alt='退回' className={styles.back_icon} />
            </div>
            {rate > 0.5 ? <div className={styles.activietyRules} onClick={() => { JsBridge.open("/front/activities/shareOfficer/rules") }}>
                {props.title}
            </div> : false}

        </div>
    </div>;
}