import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import { Mask, Toast } from 'antd-mobile';
import Library from 'library';
import Service from '../../service';
const { JsBridge, Util } = Library;

const GetDrawModal = ({ onClose, callBack, prizeInfo }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {

    }, []);

    const onDidFocus = () => {
        JsBridge.addListener().then(() => {
            
        });
    }

    const handleClick = async () => {
        if(callBack) {
            const result = await callBack()
            if(!result) {
                return Toast.show({
                    content: '领取奖励失败，请重新领取',
                    afterClose: onMask
                })
            }
            const prizeInfo = result.data || {}
            const param = Util.url.objectToQueryParams({
                idf: 'commonSub',
                activityCode: Service.activityCode,
                name: prizeInfo.prizeName,
                imgUrl: prizeInfo.imageUrl,
                req: prizeInfo.req,
            });
            JsBridge.push('CustomWeb', { uri: `${window.location.origin}/front/guapp/activityOrder?${param}` });
            onDidFocus();
        }

        onClose && onClose();
        setVisible(false);
    }

    const onMask = () => {
        onClose && onClose();
        setVisible(false);
    }


    return <Mask className={styles.guessing_rule_mask} visible={visible} onMaskClick={onMask}>
        <div className={styles.rules_modal_common}>
            <div className={styles.modal_container}>
                <img src={require('images/mask-light-close.png')} alt="" className={styles.icon_close} onClick={onMask} />
                <img src={require('images/magpieFestival/qixi_jiangli_dialog.png')} alt="" className={styles.container_img} />
                <div className={styles.container}>
                    <div className={styles.modal_title}>领取奖励</div>
                    <div className={styles.modal_content}>
                    {
                        prizeInfo ?
                        `奖品只能领取一次，请确认是否领取
                        若确认领取，请尽快填写您的收获地址
                        也可以在领取记录中查看并填写地址
                        活动结束后未填写地址奖品将作废`
                        :
                        `抱歉，当前奖品已领完
                        请选择其他奖品`
                    }
                    </div>
                    {
                        prizeInfo ?
                        <div className={styles.rules_btn} onClick={handleClick}>确认并填写地址</div>
                        :<div className={styles.rules_btn} onClick={onMask}>确定</div>
                    }
                    
                </div>

            </div>
        </div>
    </Mask>
}

GetDrawModal.open = ({prizeInfo, callBack}) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<GetDrawModal onClose={close} prizeInfo={prizeInfo} callBack={callBack}/>, div);
}

export default GetDrawModal;
