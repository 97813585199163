/**
 * @description 家办会员-酒旅权益
 * @returns /front/member/family/hotelTravel/memberLevel
*/
import React, {useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import styles from './index.module.scss';

import sj_bg from "../images/sj_bg.png";
import bj_bg from "../images/bj_bg.png";
import hz_bg from "../images/hz_bg.png";
import qy_bg from "../images/qy_bg.png";
import sj_title from "../images/sj_title.png";
import bj_title from "../images/bj_title.png";
import hz_title from "../images/hz_title.png";
import qy_title from "../images/qy_title.png";

import Library from 'library';
import Services from "services";

const {JsBridge} = Library;
const vips = {
    sj: {
        id: 0,
        bg: sj_bg,
        title_img: sj_title,
        title_color: '#16517D',
        lev_bg_color: 'linear-gradient(90deg, #16517D 0%, #D7EAF9 100%)',
        title_opacity: 0.7,
        point_bg_color: 'linear-gradient(100deg, #CCE5FF 18%, #F4FFFF 98%)',
        point_color: '#16517D',
    },
    bj: {
        id: 1,
        bg: bj_bg,
        title_img: bj_title,
        title_color: '#606060',
        lev_bg_color: 'linear-gradient(90deg, #FFFFFF 0%, #F3FFFC 54%, #FFFFFF 100%)',
        title_opacity: 0.7,
        point_bg_color: 'linear-gradient(100deg, #EAF4FF 18%, #E4EDFF 98%)',
        point_color: '#3361AA',
    },
    hz: {
        id: 2,
        bg: hz_bg,
        title_img: hz_title,
        title_color: '#E8CEA0',
        lev_bg_color: 'linear-gradient(270deg, rgba(250, 226, 162, 0.02) 0%, rgba(244, 198, 134, 0.34) 99%)',
        title_opacity: 1,
        point_bg_color: 'linear-gradient(99deg, #5D5D5D 13%, #FFFBF4 98%)',
        point_color: '#FFFFFF',
    },
    qy: {
        id: 3,
        bg: qy_bg,
        title_img: qy_title,
        title_color: '#20674E',
        lev_bg_color: 'linear-gradient(90deg, #FFFFFF 1%, #C2F1E1 47%, #F3FFFB 100%)',
        title_opacity: 0.7,
        point_bg_color: ' linear-gradient(90deg, #C2F1E1 1%, #C2F1E1 47%, #C2F1E1 100%)',
        point_color: '#146964',
    },
}

const HotleCards = [
    {title: '精选酒店', info:'无门槛抵扣', bgImg: require('../images/hotel_good.png'), color: '#254465', subColor: '#052C55', type: 1},
    {title: '定制酒旅套餐', info: '' /* info:'金旅积分抵扣20%'*/, bgImg: require('../images/hotel_package.png'), color: '#5B2D02', subColor: '#3B1E03', type: 2},
]

export const MemberLevel = (props) => {
    // props.vipType || 0;//0=水晶 1=白金 2=黑砖 3=青玉
    const [vipType, setVipType] = useState(0);
    const [guHomePoint, setGuHomePoint] = useState(0);
    const [guHomeUnLimit, setGuHomeUnLimit] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const result = await Services('MainPage', {code: 600874}, {});
        if (result.status>0){
            const {customerLevelId = 0, guHomePoint = 0, guHomeUnLimit = 0} = result.data;
            setVipType(customerLevelId);
            setGuHomePoint(guHomePoint)
            setGuHomeUnLimit(guHomeUnLimit);
            console.log('re--', result)
        }
    }

    const goBack = () => {
        JsBridge.back();
    }

    const onClickItem = (item) => {
        let url;
        if(item.type == 2) {
            // 使用魔方海报页链接,在运营端删除后将不可用
            url = 'https://mall.gkewang.com/mobile/pages/package-B/x-site/page-link/index?pageType=poster&pageCode=1693984561368&storeId='
            if(['test'].includes(process.env.REACT_APP_MALL_ENV) ) {
                url = 'https://mall-test.gkewang.com/mobile/pages/package-B/x-site/page-link/index?pageType=poster&pageCode=1603162253636'
            }else if(['uat'].includes(process.env.REACT_APP_MALL_ENV)){
                url = 'https://mall-uat.gkewang.com/mobile/pages/package-B/x-site/page-link/index?pageType=poster&pageCode=1621302757054'
            }
        }else if(item.type == 1) {
            // 倾石酒旅, 环境只有生成和测试
            url = 'https://mall.wholestone.cn/mall/app/mall-GU/#/'
            if(['test', 'uat'].includes(process.env.REACT_APP_MALL_ENV) ) {
                url = 'https://mall.wholestone.cn/mall/app/mall-test/#/'
            }
        }
        if(url) {
            if(JsBridge.hasWebViewBridge()) {
                JsBridge.push('Web', {uri: url});
            }else {
                window.location.href = url;
            }
            
        }
        
    }

    const getVipObj = () => {
        let string = 'sj';
        if (vipType === 1) {
            string = 'sj';
        } else if (vipType === 2) {
            string = 'bj';
        } else if (vipType === 3) {
            string = 'hz';
        } else if (vipType === 4) {
            string = 'qy';
        }
        return vips[string];
    }
    return <div className={styles.container}>
        <img src={getVipObj()['bg']} className={styles.img_bg}/>
        <div className={styles.content}>
            <div className={styles.nav}>
                <div className={styles.back_btn} onClick={() => goBack()}></div>
                <p className={styles.title}>酒旅权益</p>
                <p className={styles.rightTitle} onClick={() => {
                    JsBridge.push('Web', {uri: window.location.origin + '/front/member/family/hotelTravel/useRecord'});
                }}>使用明细</p>
            </div>
            <div className={styles.mid}>
                <p className={styles.lev} style={{
                    background: getVipObj()['lev_bg_color'],
                    color: getVipObj()['title_color'],
                    opacity: getVipObj()['title_opacity'],
                }}>当前等级</p>
                <img src={getVipObj()['title_img']} alt="" className={styles.title_img}/>
                <div className={styles.points}>
                    <p style={{background: getVipObj()['point_bg_color'], color: getVipObj()['point_color'],}}>{guHomePoint}</p>
                    <p style={{background: getVipObj()['point_bg_color'], color: getVipObj()['point_color'],}}>{guHomeUnLimit}</p>
                </div>
                <div className={styles.hotle_box}>
                    {
                        HotleCards.map((item, index) =>{
                            return (
                                <div className={styles.hotle_card} key={index} onClick={()=>onClickItem(item)}>
                                    <img src={item.bgImg} alt="" className={styles.hotle_bg}/>
                                    <p className={styles.hotle_name} style={{color: item.color}}>{item.title}</p>
                                    <span style={{color: item.subColor}}>{item.info}</span>
                                    {
                                        // item.type == 2 && <p className={styles.hotle_warn}>{'(敬请期待)'}</p>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}
