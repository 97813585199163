/**
 * @description 母亲节问卷调查
 * @returns route: /front/activities/questionnaire?activityCode=motherDay
*/
import React, { useState, useEffect, useMemo } from 'react';
import { Form, Toast} from "antd-mobile";
import moment from 'moment';
import { Header, ModalAlert } from 'components';
import Library from "library";
import QuestionItem from '../component/questionItem';
import Service from '../service';
import { libData, QuestionType } from './config';
import './index.scss';

export default (props) => {
    // 获取参数
    const { activityCode } = Library.Util.url.paramsToObj();

    const [form] = Form.useForm()

    const [dataSource, setDataSource] = useState({})

    useEffect(()=>{
        window.JsBridge.addKeyboardEvent();
        libData.activityCode = activityCode
        loadData()
        // setDataSource(libData)
    },[])

    const loadData = async ()=> {
        const res = await Service.getQuestionInfo({activityCode: libData.activityCode},true)
        window.JsBridge.print(res)
        let source = libData
        if(res) {
            const result = res.data
            source = {...libData, ...result}
            //请求数据处理
            if(result && result.votedList && Object.keys(result.votedList).length > 0) {
                let _list = source.list.map((item,index)=>{
                const _answers = result.votedList[index+''] || []
                if(item.type == 'input') {
                    if(item.options && item.options.length > 1) {
                        item.options = item.options.map((item,index)=>{
                            if(index < _answers.length) {
                                item.content = _answers[index].content
                            }
                            return item
                        })
                        //这个reduce暂未用到
                        item.answer = _answers.reduce((pre,current)=> {
                            if(current.answer) {
                                pre.push(current.answer)
                            }
                            return pre
                        },[])
                    }else {
                        item.answer = _answers?.[0]?.answer
                    }
                }else if(item.type == 'textarea') {
                    item.answer = _answers?.[0]?.content
                }
                else if(item.multiple == 1) {
                    //单选
                    const answer = _answers?.[0]
                    if(answer != undefined && answer?.content) {
                        const answerObjc = {}
                        answerObjc.value = Number(answer.answer)
                        answerObjc.content =  answer.content
                        item.answer = answerObjc
                    }else {
                        item.answer = Number(answer?.answer)
                    }
                    
                }else if(item.multiple == 0) {
                    //多选
                    item.answer = _answers.map(item=>item.answer != undefined ? Number(item.answer): undefined)
                    const option = item.options.find(op => op.mark ==  'other')
                    if(option) {
                        option.content = _answers.find(answer => answer.content)?.content
                    }
                }
                
                return item
               })
               window.JsBridge.print(_list)
               source.list = _list
            }
            //设置数据
            setDataSource(source)
        }
        
    }

    const controllerState = useMemo(()=>{
        const ojbt = {
            btnTitle: '提交',
            enable: false
        }
        if(!dataSource.currentTime) return ojbt;

        if(moment(dataSource.currentTime).diff(moment(dataSource.endTime)) > 0) {
            ojbt.btnTitle = '已结束'
        }else {
            if(Object.keys(dataSource.votedList).length > 0) {
                ojbt.btnTitle = '已填写'
            }else {
                ojbt.enable = true
            }
        }
        return ojbt
    },[dataSource])

    const showRule = () => {
        ModalAlert.show({
            title: '活动规则',
            content: <div style={{lineHeight: '18px'}}>{libData.rule}</div>,
            actions: [{ key: 'cancel', text: '知道了'}],
            onAction: ()=> {
                ModalAlert.hide()
            }
        })
    }

    const validateFields = async ()=> {
        let reslut = {};
        try {
            reslut = await form.validateFields()
        } catch (error) {
            reslut = error
        }
        return reslut;
    }
    const onCommitAction = async () => {
        const reslut = await validateFields()
        console.log(reslut)
        if(reslut.errorFields) {
            return Toast.show(reslut.errorFields[0].errors[0])
        }
        const values = form.getFieldsValue()
        //一个答案为一条数据
        let list = [];
        dataSource.list.forEach((item,index) => {
            const newItem = {
                questionNo: index, 
                questionType: QuestionType[item.type + item.multiple],
            }
            
            const value = values[`question${index}`]
            if(Array.isArray(value)) {
               const _list = value.map(v => {
                    if(typeof v == 'object') {
                        //多个输入框的
                        newItem.content = v.content
                        newItem.answer = v.idf
                    }else {
                        newItem.answer = v
                        //多选+有输入的
                        newItem.content = values[`content-${index}-${v}`]
                    }
                    return {...newItem}
                })
                list = list.concat(_list)
            }else {
                if(['input', 'textarea'].includes(item.type)) {
                    newItem.content = value
                }else {
                    newItem.answer = value
                    //单选+有输入的
                    newItem.content = values[`content-${index}-${value}`]
                }
                
                list.push({...newItem})
            }
            
        });

        const param = {
            activityCode: libData.activityCode,
            voteList: list
        }
        console.log(param)
        ModalAlert.show({
            title: '确认提交',
            content: '提交后结果将不可更改，确认提交当前选项吗？',
            onAction: async (item, index) => {
                ModalAlert.hide()
                if (item.key === 'confirm') {
                    const res = await Service.saveCommitInfo(param, true);
                    if(res) {
                        // Toast.show('感谢您的参与,已收到投票!');
                        window.JsBridge.runAction('alert', 'error', ['感谢您的参与,已收到投票!']);
                        let timer = setTimeout(() => {
                            clearTimeout(timer);
                            window.JsBridge.back();
                        }, 2000);
                    }
                    
                }
            },
            actions: [
                { key: 'cancel', text: '取消' },
                { key: 'confirm', text: '确认提交' }
            ]
        })
    }

    return (
        <div className="questionnaire_page">
            <Header midText={dataSource.title || '问卷调查'} bgColor={'#fff'} />
            <div className='page_body'>
                {
                    dataSource.endTime &&
                    <>
                        <div className="top-info">
                            <div>
                                <img src={require('images/icon_endtime.png')}/>
                                <span>截止时间：</span>
                                <span>{ moment(dataSource.endTime).format('M月D日') }</span>
                            </div>
                            <div onClick={showRule} style={{display: 'none'}}>
                                <img src={require('images/icon_rule.png')}/>
                                <span>{'活动规则 >'}</span>
                            </div>
                        </div>
                        <div className='give-span'>{'完成问卷调查赠送300积分'}</div>
                    </>
                }
                <div className='form-box'>
                    <Form requiredMarkStyle='none' form={form} disabled={!controllerState.enable}>
                        {
                            dataSource.list &&
                            dataSource.list.map((item, i)=> {
                                item.index = i
                                return <QuestionItem key={i} item={item} form={form}/>
                            })
                        }
                    </Form>
                </div>
            </div>
            <div className='placeholder-area'>
                <div className="bottom-fixed">
                    <div className={`commit-btn ${controllerState.enable ? 'enable':''}`} 
                        onClick={()=>{ 
                            controllerState.enable && onCommitAction()
                        }}>
                        <span>{controllerState.btnTitle}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}