import React from 'react';
import Library from 'library';
import { Link } from 'react-router-dom';
import styles from './success.module.scss';
import successImage from 'images/icon/success.png';
import guIcon from 'images/icon/qrcode@2x.png';
import logoIcon from 'images/G-logo@2x.png';
import sloganIcon from 'images/G-slogan@2x.png';

export const RegisterSuccess = () => {
    const downloadUrl = Library.Util.WHITELIST_DOWNLOAD_URL;
    return <div className={styles.container}>

        <div className={styles.successImage}>
            <img src={successImage} alt='成功' />
        </div>
        <h1>领取成功</h1>
        <p>恭喜您成为绿地G优会员</p>
        <div className={styles.qrcodeContainer}>
            <img className={styles.guIcon} src={guIcon} alt='二维码' />
            <div className={styles.content}>
                <h2>绿地G优尊享会</h2>
                <p>点击二维码保存，微信扫一扫识别后享受更多福利</p>
            </div>
        </div>
        <div className={styles.empty}></div>
        <div className={styles.safeArea}>
            <div className={styles.downloadWrap}>
                <img className={styles.logo} src={logoIcon} />
                <div className={styles.slogan} >
                    <img src={sloganIcon} />
                </div>
                <a className={styles.download} href={downloadUrl}>下载APP</a>
            </div>
        </div>
    </div>;
};
