import React, {useState} from "react";
import './worldCupSettleModal.scss';
import { Mask } from 'antd-mobile';
import closeArrow from '../images/close-arrow.png';
import openArrow from '../images/open-arrow.png';

const SettleModal = ({settleModalVisible, onClose, settleInfo, confirmSettle}) => {
  if(!settleInfo) return null;
  const {
    totalPoint,
    numberMap,
    pointCoefficient,
    list,
  } = settleInfo || {};

  return <Mask visible={settleModalVisible} className="world-cup-settle-mask">
    <div className="world-cup-settle-modal">
      <div className="world-cup-settle-title">
        <span>积分结算</span>
      </div>
      <div className="world-cup-settle-content">
        <div className='settle-modal-item'>
          <div className="item-top common-flex">
            <span>结算场次</span>
            <span>第{numberMap?.startNumber}场-第{numberMap?.endNumber}场</span>
          </div>
          <div className="points-detail-box">
            {list?.length > 0 && list.map((item, index) => {
              return <PoisntsDetailItem item={item} key={`item-${index}`}/>
            })} 
          </div>
          <div className="item-bottom common-flex">
            <div className="double-count">连胜系数：<i>{pointCoefficient}</i></div>
            <div className="total-points">结算总积分：<i>{totalPoint}</i></div>
          </div>
        </div>
      </div>
      <div className="settle-modal-btn" onClick={onClose}>
        <span className="cancel-settle btn-common" onClick={onClose}>取消</span>
        <span className="confirm-settle btn-common" onClick={confirmSettle}>确认结算</span>
      </div>
    </div>
  </Mask>
}

const PoisntsDetailItem = ({item}) => {
  const [isPointsShow, setIsPointsShow] = useState(false);
  const { invitePoints, number, perGamePoint, guessPoints, showPoints } = item || {};

  const showPointsDetail = () => {
    setIsPointsShow((pre) => !pre);
  }

  return <div className="field-points" onClick={() => showPointsDetail()}>
    <div className="field-points-title common-flex">
      <span>第{number}场</span>
      <div className='field-points-title-right'>
        <span>{perGamePoint}</span>
        <img
          className={`${isPointsShow ? 'open-arrow-img': 'close-arrow-img'}`}
          src={`${isPointsShow ? openArrow: closeArrow}`}
          alt=""
        />
      </div>
    </div>
    <div className={`field-points-detail ${isPointsShow ? 'points-detail-show': 'points-detail-hide'}`}>
      <div className="field-points-detail-item common-flex">
        <span>竞猜得积分：</span>
        <span>{guessPoints}</span>
      </div>
      <div className="field-points-detail-item common-flex">
        <span>分享得积分：</span>
        <span>{showPoints}</span>
      </div>
      <div className="field-points-detail-item common-flex">
        <span>邀新得积分：</span>
        <span>{invitePoints}</span>
      </div>
    </div>
  </div>
}

export default SettleModal;