import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {Actions} from 'reduxs';
import { Header } from 'components';
import styles from './index.module.scss';
import {BottomButton} from "../kangyangju/components/bottomButton";
import {IntentionCommit} from '../kangyangju/components/intentionCommit';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const  BaseDetail = ()=>{
    const [visible,setVisible] = useState(false);
    const [arr,setArr] = useState([]);
    const url = useQuery().get('url');
    const strArr = useQuery().get('arr');
    useEffect(()=>{
        if(strArr) {
            let urls = JSON.parse(strArr);
            if (urls) {
                setArr(urls);
            }
        } else if (url) {//为了兼容之前传递url
            setArr([url]);
        }

    },[]);
    return <div className={styles.container}>
        <Header midText={'基地详情'}/>
        <div className={styles.content}>
            {arr.map((item,index)=>{
                return <img className={styles.img} src={item} key={index} draggable={false}/>
            })}
        </div>
        <BottomButton onClick={()=>{
            setVisible(true);
        }}/>
        <IntentionCommit visible={visible} onHide={(isSuccess)=>{
            setVisible(false);
            if (isSuccess) {
                Actions.alert.show('提交意向成功');
            }
        }}/>
    </div>
};

