/**
 * @description 统一下载引导页
 * @returns  route: /front/inviter/landPage/:activityCode
*/
import React, {useState} from "react";
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import Library from 'library';
const { Util } = Library;

const appSchema = 'gktapp://com.gkewang';

/**
 * @description 引导页点击跳转路由指定页面配置
 * @key activityCode 活动编码
 * @path 路由
 * @bgImg 背景图
*/
const LandPaths = {
  INVITECLIE2: {
    path: '/front/activities/nft-official',
    bgImg: require('images/nft-official/inviteSuccess.png')
  },
  doubleZero2023: {
    path: '/front/activities/newYear',
    bgImg: require('images/newYear/inviteSuccess.png')
  },
  inviteZone2024: {
    path: '/front/activities/regularShare/invite-zone',
    bgImg: require('images/invite-zone/inviteSuccess.png')
  },
  MoutaiSale: {
    path: '/front/activities/regularShare/invite-zone',
    bgImg: require('images/invite-zone/inviteSuccess.png')
  }
}

export const LandPage = () => {
  const params = Util.url.paramsToObj() || {};
  const { activityCode='' } = useParams();
  const [maskShow, setMaskShow] = useState(false);

  const targetUrl = `${process.env.REACT_APP_GKT_LINK}${LandPaths[activityCode]?.path}?${Util.url.objectToQueryParams(params)}`;

  const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

  const openApp = () => {
    if (Library.Platform.check.isWechat()) {
      setMaskShow(true);
    } else {
      window.location.href = launchUrl;
      setTimeout(() => {
          window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
      }, 3000);
    }
  }

  return <div className={styles.guide_share}>
    <img src={LandPaths[activityCode]?.bgImg} alt="" />
    <div className={`${styles.load_btn} ${styles[activityCode]}`} onClick={() => openApp()}></div>
    {maskShow && <div className={styles.mask} onClick={() => setMaskShow(false)}>
      <img src={gotoBrowserImg} alt=""/>
    </div>}
  </div>
}

