
/**
 * @description 次级无聊猿竞猜分享链接页面

*/
import React, { useState } from "react";
import gotoBrowserImg from 'images/goto-browser.png';
import Library from 'library';
import styles from './guessingActy.module.scss';

const appSchema = 'gktapp://com.gkewang';

export default () => {
    // 获取链接携带参数
    const params = Library.Util.url.paramsToObj();

    const [maskShow, setMaskShow] = useState(false);
    const targetUrl = `${process.env.REACT_APP_NFT_LINK}/guessingActy/home?${Library.Util.url.objectToQueryParams(params)}`;

    const launchUrl = `${appSchema}/screen/CustomWeb?uri=${encodeURIComponent(targetUrl)}`;

    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setMaskShow(true);
        } else {
            window.location.href = launchUrl;
            setTimeout(() => {
                window.location.href = Library.Util.ORIGIN_DOWNLOAD_URL;
            }, 3000);

        }
    }

    return (
        <div className={styles.share_download_app_page}>
            <img src={require('./img/img_guessing_bg_xz.png')} alt="" />
            <div className={styles.download_btn} onClick={() => openApp()}>下载/打开绿地G优APP</div>
            {
                maskShow &&
                <div className={styles.mask} onClick={() => setMaskShow(false)}>
                    <img src={gotoBrowserImg} alt="" />
                </div>
            }
        </div>
    )
}
