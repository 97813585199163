import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import {Mask, Toast} from 'antd-mobile';
import Library from "../../../../../library";

const {JsBridge} = Library;
const ShiMingModal = ({onClose, data, callback}) => {
    const [visible, setVisible] = useState(true);

    const handleClick = async (type) => {
        setVisible(false);
        onClose && onClose();
        if (type === 0) {

        } else if (type === 1) {
            JsBridge.navigate('Verify');
        }
    }


    return <Mask className={styles.compound_dialog} visible={visible}>
        <div className={styles.put_modal_container}>
            <div className={styles.btns}>
                <img src={require('../../imgs/btn_qx.png')} alt="" onClick={() => handleClick(0)}/>
                <img src={require('../../imgs/btn_sm.png')} alt="" onClick={() => handleClick(1)}/>
            </div>
        </div>
    </Mask>
}

ShiMingModal.open = (data, callback) => {
    let div = null;
    if (!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<ShiMingModal onClose={close} data={data} callback={callback}/>, div);
}

export default ShiMingModal;
