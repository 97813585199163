const sequenceKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J','K','L','M','N']
const QuestionType = {
    'choice1': 0, //单选
    'choice0': 1, //多选
    'input': 2,
    'input0': 2, //文字
    'input1': 2, //文字
    'textarea': 2,
    'textarea0': 2, //文字
    'textarea1': 2, //文字
    'img': 3, //图片
}

const pic_a = require('./pic_a.png')

const libData = {
    questionId: 22,
    filledFlag: 0, //0:未填写 1:已填写
    currentTime: '2023-05-04 09:10:01',
    //问卷信息
    questionnaireInfo:{
        id: '',
        questionName:'调查问卷',
        reward: 200, //积分奖励
        // rule:`<p><span></span></p><div>
        // <div>选择添加单选题/多选题，如图4，选择添加图片答案显示如图4-1，选择添加文本答案如图4-2中C答案，选择添加答案，如图4-2中A、B答案；答案数量不设上限和下限 </div>
        // </div>`,
        rule: `1、问卷调查活动时间内，用户完成后赠送300积分
    2、每人限填一次，不可重复填写
    3、积分发放完成后，积分明细页面会新增一条积分增加记录，并且发放方式列显示为问卷调查`,
        questionBeginTime: undefined,
        questionEndTime: '2023-05-17 15:10:00',
        isValid: true, //是否生效，该字段目前没用上
    }, 
    stemVOList: [
        {
            id: 0, //题干id
            stemNo:1, //题干序号:如1,2,3...
            questionId: undefined, //关联的问卷id
            stemText: '画风以图片形式进行选择：', //题干内容
            questionType:0, //题型（0单选，1多选，2填空，3简答）
            isAnswer: true, //是否必答（0否，1是）
            type: 'img', //类型: choice: 选择,img:图片, input:单行输入,textarea: 多行输入, date: 日期选择
            answer: [2,3],
            optionList: [
                { 
                    id: 0,
                    stemId:0, //题目表主键id
                    optionNo:'A', //选项编号，如A,B,C
                    optionText: '潮流国风', 
                    optionImg:pic_a, 
                    optionType:2, //选项类型(0普通选项，1文本选项，2图片选项)
                    isAnswer:0, // 是否必填（0否，1是），只有文本选项需要设置是否必填
                },
                { optionNo:'B', optionText: '厚涂', optionImg: pic_a, id: 1,  optionType:2 },
                { optionNo:'C', optionText: '扁平插画', optionImg: pic_a, id: 2,  optionType:2 },
                { optionNo:'D', optionText: '像素风', optionImg: pic_a, id: 3,  optionType:2 },
                { optionNo:'E', optionText: '可爱卡通', optionImg: pic_a, id: 4,  optionType:2 }
            ]
        },
       {
            id: 1,
            stemNo:2,
            stemText: '您的性别？', //标题
            isAnswer: true, //是否必选
            questionType: 0, //能选几个: 0单选，1: 多选，x: 可选x个
            answer: undefined, //用于回显的数据，例如：0则显示'25以下'
            optionList: [
                { optionNo:'A', optionText: '男士', id: 0},
                { optionNo:'B', optionText: '女士', id: 1},
            ]
        },
        //多选
        {
            id: 2,
            stemNo:3,
            stemText: '您的年龄是？', 
            isAnswer: true,  
            questionType: 1, 
            answer: undefined,
            optionList: [
                { optionNo:'A', optionText: '25以下', id: 0},
                { optionNo:'B', optionText: '25-35岁',  id: 1},
                { optionNo:'C', optionText: '35-45岁', id: 2},
                { optionNo:'D', optionText: '45–55岁', id: 3},
                { optionNo:'E', optionText: '55岁以上', id: 4}
            ]
        },
        // 单选+输入
        {
            id: 3,
            stemNo:4,
            stemText: '您选购母亲节礼物优先考虑哪个方面', 
            isAnswer: true,  
            questionType: 0, 
            answer: {value: undefined, content: undefined },
            optionList: [
                { optionNo:'A', optionText: '性价比', id: 0},
                { optionNo:'B', optionText: '品牌', id: 1},
                { optionNo:'C', optionText: 'DIY定制专属', id: 2},
                { optionNo:'D', optionText: '其它（填空项）', id: 3, optionType: 1, isAnswer: true },
            ]
        },
        // 多选+输入
        {
            id: 4,
            stemNo:5,
            stemText: '您偏好的母亲节礼物类型是？', 
            isAnswer: true,  
            questionType: 1, 
            answer: undefined,
            optionList: [
                { optionNo:'A', optionText: '饰品（包包、戒指、吊坠等）', id: 0},
                { optionNo:'B', optionText: '电子产品（手机、平板、投影仪等）', id: 1},
                { optionNo:'C', optionText: '服装（丝巾、衣服、鞋子等）', id: 2},
                { optionNo:'D', optionText: '红包', id: 3},
                { optionNo:'E', optionText: '其它（填空项）', id: 4, optionType: 1, isAnswer: true, content: undefined},
            ]
        },
        // 多输入
        {
            id: 5,
            stemNo:6,
            stemText: '按照您上题选择的品类，您希望选择具体何种商品作为母亲节礼物？', 
            isAnswer: true, 
            questionType: 2,
            answer: [],
            optionList: [
                {id: 0, isAnswer: true, content: undefined },
                {id: 1,  content: undefined},
                {id: 2,  content: undefined},
            ]
        },
        //单个输入
        {
            id: 6,
            stemNo:7,
            stemText: '您对G优母亲节活动还有哪些建议吗？（填空）', 
            isAnswer: true, 
            questionType: 2, 
            answer: undefined,
            optionList:[]
        },
        // 日期选择
        {
            id: 7,
            stemNo:8,
            stemText: '请选择日期', 
            isAnswer: true, 
            questionType: 4,
            answer: '2023-05-06',
            optionList:[]
        },
        // 时间选择
        {
            id: 8,
            stemNo:9,
            stemText: '请选择时间', 
            isAnswer: true, 
            questionType: 5,
            answer: '2023-05-06 09:42:36',
            optionList:[]
        },
        // 多行输入
        {
            id: 9,
            stemNo:10,
            stemText: '您对G优母亲节活动还有哪些建议吗？（填空）', 
            isAnswer: true, 
            questionType: 3,  
            answer: undefined,
            optionList: [
                {id: 0},
            ]
        },
    ]
   
}

export {
    sequenceKeys,
    QuestionType,
    libData
}