import React, {useEffect, useRef, useState} from 'react';
import Library from "../../../library";
import styles from './publicActyList.module.scss'
import ListView1 from "../../../components/ListView1";
import Services from "../../../services";
import {isEmptyString} from "../../../library/util";

const {JsBridge} = Library;
export const PublicActyList = (props) => { //预约活动列表
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(0);
    const [data, setData] = useState([]);
    const [initStatus, setInitStatus] = useState(false);
    const {focusStatus} = props;

    useEffect(() => {
        if (!initStatus && focusStatus) {
            setInitStatus(true);
            !isEmptyString(props.userAccount) && loadData();
        }
    }, [initStatus, focusStatus, props.userAccount])
    const loadData = async (isRefresh) => {
        if (isRefresh) {
            pageNum.current = 0;
        }
        let params = {
            'pageSize': pageSize,
            'pageNum': pageNum.current,
            customerAccount: props.userAccount || '',
        };
        const res = await Services('MainPage', {code: 500015}, params);
        if (res.status > 0) {
            const {guHomeOfficePublicActivityVOPage = {}} = res.data;
            let newArr = [];
            let list = guHomeOfficePublicActivityVOPage?.content || [];
            if (pageNum.current === 0) {
                newArr = [...list];
            } else {
                newArr = [...data, ...list];
            }
            setData(newArr);
            setIsLoad(true);
            setHasMore(list.length >= pageSize);
        }
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData(false);
    }

    const itemClick = (item) => { //以往的活动
        // JsBridge.push('CustomWeb', {uri: window.location.origin + `/front/member/appointment/goAppointment?activityId=${item.id}&activityName=${item.activityName}&userAccount=${props.userAccount}&isNowActy=0`});
        let data = {
            activityId: item.id,
            activityName: item.activityName,
            userAccount: props.userAccount,
        }
        JsBridge.push('GoAppointment', data)
    }

    const listItem = (item) => {
        return (
            <div className={styles.aptmt_acty_selection_list_item} style={{marginLeft: '3%'}}>
                <img src={item.activityPic || require('./images/pic_moren.png')}/>
                <div className={styles.aptmt_acty_selection_list_item_content}>
                    <p>{item?.activityName || ''}</p>
                    <p>{item?.contentValidity || ''}</p>
                </div>
                <div className={styles.aptmt_acty_selection_list_item_btn}
                     onClick={() => itemClick(item)}>查看详情
                </div>
            </div>
        )
    }

    return (
        <div className={styles.apty_public_container}>
            <ListView1
                onRefresh={() => loadData(true)}
                data={data}
                hasMore={hasMore}
                isLoad={isLoad}
                loadMore={loadMore}
                ListItem={({item}) => listItem(item)}
            />
        </div>
    )
}

