import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import Tab from '../components/tab';
import SettlementItem from './settlementItem';
import GuessRecordItem from './guessRecordItem';
import noData from '../../../../images/noCollection.png';
// import mockFn from './mock';
import Library from 'library';
import Services from 'services';

const { Util, JsBridge } = Library;

const tabs = [{title: '竞猜记录', key: 1}, {title: '结算记录', key: 2}];

const MyGuess = () => {
  const { currentIndex } = Util.url.paramsToObj() || {};
  const [tabIndex, setTabIndex] = useState(Number(currentIndex));
  const [recordList, setRecordList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const pageNum = useRef(1);
  const pageSize = useRef(10);

  useEffect(() => {
    getRecordList(tabs[tabIndex].key, true);
  }, [tabIndex])

  const getRecordList = async (key, isRefresh) => {
    // const res = await mockFn(0);
    // if(res.code === '000000') {
    //   setRecordList(res.data)
    // }
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      type: key
    }
    const result = await Services('MainPage', { code: '101291' }, params);
    JsBridge.print(result);
    setIsLoad(true);
    if(result.status > 0) {
      setRecordList(result.data?.list || []);
    }
  }

  const clickTab = async (i) => {
    setTabIndex(i);
    setRecordList([]);
    setIsLoad(false);
    await getRecordList(tabs[i].key, true);
    document.getElementsByClassName('my-guess')[0].scrollTop = 0 + 'px';
  }

  return <div className='my-guess'>
    <Tab tabs={tabs} onClick={clickTab} currentTab={currentIndex}/>
    {recordList?.length > 0 ? recordList.map((item, index) => {
      return tabIndex === 0 ? <GuessRecordItem guessInfo={item} key={`item-${index}`} />: <SettlementItem sattleInfo={item} key={`item-${index}`}/>
    }): isLoad ? <div className='no-data'>
      <img src={noData} alt="" />
      <span>暂无数据</span>
    </div>: null}
  </div>
}

export default MyGuess;