import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cardPic from '../card/images/card-pic@2x.png';
import experienceQrcode from '../card/images/experience-qr@2x.png';
import vipQuestion from '../card/images/general-icon-btn-question@2x.png';
import { vipConfig } from '../card/conf';
import { Actions } from 'reduxs';
import services from 'services';
import Library from 'library';

export * from './login';
export * from './success';
const downloadUrl = Library.Util.WHITELIST_DOWNLOAD_URL;
export const GetCard = () => {
    const { inviteCode, activityCode, caseId } = Library.Util.url.paramsToObj();
    const [vipLevel, setVipLevel] = useState(null);
    const [privilege, setPrivilege] = useState(vipConfig.base.privileges);
    const [cardNo, setCardNo] = useState('');
    const history = useHistory();

    useEffect(() => {
        fetchMemberInfo();
    }, []);

    const fetchMemberInfo = async () => {
        if (caseId) {
            const result = await services('GuMember', 'GetScheme', { caseId });
            if (result.status > 0) {
                window.location.href = result.data.scheme;
            }
        } else {
            const result = await services('GuMember', 'IsRegisted', {});
            if (result && result.data && result.data.msg) {
                history.replace('/front/member/getCardSuccess', { caseId });
            } else {
                initMemberInfo({ guCardNo: '***********' });
            }
        }
    };

    const initMemberInfo = (info) => {
        const {
            guCardNo,   //gu会员卡卡号，体验卡不显示卡号
            guCardType,  //gu卡类型：0-尊享卡，1-体验卡
            guCardStatus, //gu卡状态:0-有效,1-失效
            // guActivateTime, //gu会员卡激活时间
            guValidTime    //gu会员卡有效期
        } = info;
        if (guCardType !== null) {
            setVipLevel(guCardType === 1 ? 'temp' : 'vip');
            setCardNo(guCardNo);
        }
    };

    useEffect(() => {
        if (vipLevel && vipLevel !== 'base') {
            setPrivilege(vipConfig[vipLevel].privileges.concat(vipConfig.base.privileges));
        }
    }, [vipLevel]);

    const getTempCard = async () => {
        let params = [];
        if (inviteCode) {
            params.push(`inviteCode=${inviteCode}`);
        }
        if (caseId) {
            params.push(`caseId=${caseId}`);
        }
        if (activityCode) {
            params.push(`activityCode=${activityCode}`);
        }
        let paramsCode = params.join('&');
        history.push(`/front/member/getCard/login?${paramsCode}`);
    }

    const Other = (props) => {
        const formattedPrivilege = [];
        privilege.sort((a, b) => {
            return a.sort - b.sort
        })
        privilege.forEach((item, key) => {
            const thisKey = parseInt(key / 2)
            if (!formattedPrivilege[thisKey]) {
                formattedPrivilege[thisKey] = [];
            }
            formattedPrivilege[thisKey].push(item);
        })

        const renderItem = (a) => {
            if (a) {
                if (a.disabled) {
                    return <div className={styles.item}>
                        <div className={styles.left}>
                            <span className={styles.title}>{a.title || a.tag}</span>
                            <span className={styles.subTitle}>{a.sub}</span>
                            <span className={styles.desc}>{a.name}</span>
                        </div>
                        <div className={styles.right}>
                            <img mode='widthFix' src={a.icon} alt='pic' />
                        </div>

                    </div>
                }
                return <Link className={styles.item} to={`/front/member/${a.link}`}>
                    <div className={styles.left}>
                        <span className={styles.title}>{a.title || a.tag}</span>
                        <span className={styles.subTitle}>{a.sub}</span>
                        <span className={styles.desc}>{a.name}</span>
                    </div>
                    <div className={styles.right}>
                        <img mode='widthFix' src={a.icon} alt='pic' />
                    </div>

                </Link>
            } else {
                return false;
            }
        }
        return <div className={styles.other}>
            {formattedPrivilege.map((arr, key) => {
                return <div key={key} className={styles.row}>
                    {renderItem(arr[0])}
                    <div className={styles.split}></div>
                    {renderItem(arr[1])}
                </div>;
            })}
        </div>
    }
    if (caseId) {

        return false;
    }
    if (!cardNo) {
        return false;
    }
    return <div className={styles.container}>
        <div className={styles.scroll}>
            <div className={styles.header}>
                <div className={`${styles.cardWrap} ${styles.vipCardWrap}`}>
                    <div className={styles.cardLeft}>
                        {/* <h1>G优尊享会员卡 {vipLevel === 'temp' ? <i>体验卡</i> : false}</h1> */}
                        <div className={styles.leftTitle}>
                            <img src={cardPic} alt='cardPic' className={styles.cardPic}></img>
                            <div className={styles.msg}>
                                <h1>G优会员卡</h1>
                                <p className={styles.companyTitle}>绿地控股集团</p>
                            </div>
                        </div>
                        <h3 className={styles.companyVipNum}>NO. ************</h3>
                        {/*<p onClick={() => {*/}
                        {/*    history.push(`/front/member/${vipLevel === 'vip' ? 'vipDesc' : 'tempDesc'}`)*/}
                        {/*}}>会员说明 <img className={styles.question} src={vipQuestion} alt='question' /></p>*/}
                    </div>

                    <div className={styles.qrcode} >
                        <img src={experienceQrcode} alt='qrcode' />
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <h1>会员特权</h1>
                <h3>TOP 11 PLATINUM INTERESTS</h3>
                <Other />
            </div>
        </div>
        <footer className={styles.footer}>
            <div className={styles.footerWrap}>
                <button onClick={getTempCard}>立即领卡享特权</button>
            </div>
        </footer>
    </div>;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
