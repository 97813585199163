export let globalData = {
    isMobile: false,//是否为移动端
    openIn: '',//页面打开的app
    openInType: '',//移动端设备的类型 ios an
    isHW: false,//是否为华为浏览器
}
var browser = {
    versions: function () {
        var u = navigator.userAgent, app = navigator.appVersion;
        return {         //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('safari') == -1, //是否web应该程序，没有头部与底部
            //DF:u.indexOf('DF'),//是否是mmc app端
        };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
}
if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
    globalData.isMobile = true;
    var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
    console.log(ua + '=============ua对象');
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信中打开
        globalData.openIn = 'weixin';
    }
    if (ua.match(/WeiBo/i) == "weibo") {
        //在新浪微博客户端打开
        globalData.openIn = 'weibo';
    }
    if (ua.match(/ qq\//i) == " qq/") {
        //在QQ空间打开
        globalData.openIn = 'qq';
    }
    if (ua.match(/huawei/i) == 'huawei') {
        globalData.isHW = true;
    }
    if (browser.versions.ios) {
        //是否在IOS浏览器打开
        globalData.openInType = 'ios';
    }
    if (browser.versions.android) {
        //是否在安卓浏览器打开
        globalData.openInType = 'android';
    }
} else {
    //否则就是PC浏览器打开
    globalData.isMobile = false;
}
