
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Services from 'services';
import Library from 'library';
import styles from './index.module.scss';
import sloganImage from 'images/GPremium-brand.svg';
import { Actions } from 'reduxs';
import soldOut from 'images/icon/Robbed@2x.png';
export const Mall = (props) => {
    const [mall, setMall] = useState(null);
    const history = useHistory();
    const getMallInfo = async () => {
        const result = await Services('IntegralMall','MyList');
        if (result.status > 0) {
            setMall(result.data);
        }
    }
    useEffect(() => {
        getMallInfo();
    }, []);
    if (mall) {
        return <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <strong>{mall.pointAmount}</strong>
                    <small>积分</small>
                </div>
            </div>

            <div className={styles.nav}>
                <div className={styles.navItem}>
                    <div className={styles.navText}>精选商品</div>
                </div>

            </div>

            <div className={styles.productList}>
                {mall.list.map((item, key) => {
                    return <div key={key} className={styles.productWrap}
                        onClick={() => {
                            Actions.modal.show('此券当前仅支持“绿地G优”APP中使用，是否前往APP？', '温馨提示', [], Library.Util.ORIGIN_DOWNLOAD_URL);
                        }}
                    >
                        <div className={styles.thumbnail}>
                            <img src={item.tinyPicUrl} />
                            {item.stock <= 0 ? <img className={styles.soldOut} src={soldOut} alt='图片' /> : false}
                        </div>
                        <div className={styles.content}>
                            <h3>{item.goodsName}</h3>
                            <p>
                                <em>{item.pointCost}</em><small>积分</small>
                                <del>¥{item.moneyCost}</del>
                            </p>
                        </div>
                    </div>;
                })}


            </div>
            <div className={styles.slogan}>
                <img src={sloganImage} />
            </div>
        </div >
    } else {
        return false;
    }
}