import React, { useState, useEffect, useRef, useCallback } from 'react';
import TopNavigation from '../topNavigation';
import styles from './index.module.scss';
import small1 from './images/small1.png';
import small2 from './images/small2.png';
import small3 from './images/small3.png';
import small4 from './images/small4.png';
import small5 from './images/small5.png';
import small6 from './images/small6.png';
import rightArrow from './images/rightArrow.png';
import botSlogan from './images/botSlogan.png';
import leftArrowImg from './images/leftArrowImg.png';
import Library from 'library';
const { JsBridge } = Library;


export const FamilyInherit = (props) => {

    useEffect(() => {
        JsBridge.setStatusBar('setBarStyle', ['dark-content']);
    }, []);
   

    return <div className={styles.container}>
        <TopNavigation title='家族传承'/>
        <div className={styles.header}>
            <div className={styles.headerFirstPart}>
                <div className={styles.firstMainCtn}>
                    <div className={styles.modalBox}>
                        <img src={small1} className={styles.small1} />
                        <div className={styles.smallText}>财富安全</div>
                    </div>
                    <div className={styles.modalBox}>
                        <img src={small2} className={styles.small2} />
                        <div className={styles.smallText}>资产管理</div>
                    </div>
                    <div className={styles.modalBox}>
                        <img src={small3} className={styles.small3} />
                        <div className={styles.smallText}>婚姻保护</div>
                    </div>
                    <div className={styles.modalBox}>
                        <img src={small4} className={styles.small3} />
                        <div className={styles.smallText}>债务隔离</div>
                    </div>
                    <div className={styles.modalBox}>
                        <img src={small5} className={styles.small3} />
                        <div className={styles.smallText}>税务规划</div>
                    </div>
                    <div className={styles.modalBox}>
                        <img src={small6} className={styles.small3} />
                        <div className={styles.smallText}>个性化传承</div>
                    </div>


                </div>
            </div>
        </div>
        <div className={styles.secondFFPart}>
            <div className={styles.secondPartHead}>
                <span className={styles.learnMoreText}>了解更多</span>
            </div>
            <div className={styles.secondPartContent}>
                <div className={styles.secondPartContentModal} onClick={()=>{
                    JsBridge.open('https://mp.weixin.qq.com/s?__biz=MzI3NjI5MzU4Ng==&mid=2247485894&idx=1&sn=4cac3a98089dc325187eee7e485dba66&chksm=eb76fb7bdc01726dadbe4cacf76bb06cae61d7cf9d2dd7604b34bdce872092480b2ccb18e385')
                }}>
                    <div className={styles.InnerModalLeft}>
                        1、什么是家族信托?
                    </div>
                    <div className={styles.InnerModalRight}>
                        <img src={rightArrow} className={styles.rightArrow} />
                    </div>
                </div>

                <div className={styles.secondPartContentModal} onClick={() => {
                    JsBridge.open('https://mp.weixin.qq.com/s?__biz=MzI3NjI5MzU4Ng==&mid=2247488590&idx=1&sn=340e7497f241316cfe21fc34d016c30c&chksm=eb76e6f3dc016fe5ccfdf83c92265f326c3a57f2918f7202de1e2336be4f73e93f6ffb0e5195')
                }}>
                    <div className={styles.InnerModalLeft}>
                        2、慈善基金 | 家族财富的善用之举
                    </div>
                    <div className={styles.InnerModalRight}>
                        <img src={rightArrow} className={styles.rightArrow} />
                    </div>
                </div>

                <div className={styles.secondPartContentModal} onClick={() => {
                    JsBridge.open('https://mp.weixin.qq.com/s?__biz=MzI3NjI5MzU4Ng==&mid=2247488762&idx=1&sn=dad90107c612ede64d9865c41e7c69b5&chksm=eb76e647dc016f5106d0ed5a1a63418ebc9b3d9ce824860e33c656556527a96c4e488c667ff1')
                }}>
                    <div className={styles.InnerModalLeft}>
                        3、关于保险金的信托
                    </div>
                    <div className={styles.InnerModalRight}>
                        <img src={rightArrow} className={styles.rightArrow} />
                    </div>
                </div>


            </div>
        </div>
        <div className={styles.thirdPartCtn}>
            <img src={botSlogan} className={styles.botSlogan} />
        </div>


    </div>
}