import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './login.module.scss';
import loginBg from './images/login-bg@2x.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Actions } from 'reduxs';
import Services from 'services';
import Library from 'library';
const { Platform, Util, JsBridge } = Library;
let SwiperNc = null;
export const Login = () => {
    const [isAgree, setIsAgree] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [lastSecond, setLastSecond] = useState(0);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const [token, setToken] = useState(null);
    const [enabled,setEnabled] = useState(false);

    const redirect = useQuery().get('redirect');
    const isRegisted = async () =>{
        const result = await Services('GuMember', 'IsRegisted', {});
        if (result.status > 0) {
            if (result.data && result.data.msg) {
                return true;
            } else {
                return true;
            }
        }
        return false;
    }
    const isGetToken = async () => {
        if (Platform.check.isApp()) {
            const result = await Services('MainPage', {code:'600066'}, {source:'app'});
            if (result.status > 0) {
                JsBridge.print(redirect);
                let {token} = result.data;
                window.location.replace(`${redirect}${redirect.indexOf('?') > -1 ? '&' : '?'}t=${token}`);
            }
        } else {
            if (await isRegisted()) {
                const result = await Services('GuMember', 'GetNftToken', {source:Platform.check.isWechat()?'wh5':'h5'});
                if (result.status > 0) {
                    if (result.data && result.data.data) {
                        window.location.replace(`${redirect}${redirect.indexOf('?') > -1 ? '&' : '?'}t=${result.data.data}`);
                    } else {
                        setToken('');
                    }
                }
            } else {
                setToken('');
            }

        }

    }

    const countDown = useSelector((state) => {
        return state.sms.toJS().couponCounter;
    });

    const sendSms = async () => {
        if (!enabled) {
            return;
        }
        Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('DynamicCode', 'GetDynamicCode', {
                    mobile,
                    ...res
                });
                if (result.status > 0) {
                    Actions.sms.countDownCoupon(new Date().getTime() + 60 * 1000);
                }
            }
        }

    }



    const sendSmsRender = () => {
        if (lastSecond > 0) {
            return <button disabled className={styles.sms2}> 重新发送({lastSecond}s) </button>;
        } else {
            return <button disabled={!enabled} onClick={sendSms} className={styles.sms}>发送验证码</button>;
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setLastSecond(parseInt((countDown.expire - new Date().getTime()) / 1000));
        }, 500)
        return () => {
            clearInterval(timer);
        }
    }, [countDown]);

    const submit = () => {
        if (!enabled || !code) {
            return;
        }

        if (!isAgree) {
            history.push({pathname:'/front/activities/nft/login/rule',callback: (flag) => {
                    if (flag) {
                        login();
                    }
                }});
            return;
        }
        login();
    };

    const login = async () => {
        if (Platform.check.isApp()) {

        } else {
            const params = { mobile, dynamicCode: code };
            const result = await Services('GuMember', 'RegistOrLoginForH5', params);
            if (result.status > 0) {
                isGetToken();
            }
        }


    }

    useEffect(() => {
        isGetToken();
        initNC();
    }, []);

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    }

    if (token === null) {
        return false;
    }
    return <div className={styles.container}>

        <div className={styles.headers}>
            <img src={loginBg} alt='头图' />
        </div>
        <div className={styles.form}>

            <div className={styles.inputWrap}>
                <p><label>手机</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setMobile(event.target.value);
                            setEnabled(Library.Util.validate.mobile(event.target.value));
                        }} type='tel' value={mobile} placeholder='请输入' maxLength='11' />
                    </div>
                </div>
            </div>

            <div className={styles.inputWrap}>
                <p><label>验证码</label></p>
                <div className={styles.inputFlex}>
                    <div className={styles.input}>
                        <input onChange={(event) => {
                            setCode(event.target.value);
                        }} type='text' val={code} placeholder='请输入' maxLength='6' />
                    </div>
                    {sendSmsRender()}

                </div>
            </div>

            <div onClick={submit} className={`${styles.button} ${!enabled || !code ? styles.disable:''}`}>登录</div>
            <div className={styles.agreeWrap}>
                <i onClick={()=>{
                    setIsAgree(!isAgree);
                }} className={`${styles.agreeIcon} ${isAgree ? '' : styles.notAgreeIcon}`}/>
                <p>
                    <a className={styles.a} onClick={()=>{
                        setIsAgree(!isAgree);
                    }}>我已阅读并同意</a>
                    <a className={styles.active} onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open(`${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/register`)
                    } else {
                        window.location.href = `${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/register`;
                    }
                }}>《绿地G优注册协议》、</a>
                    <a className={styles.active} onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open(`${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/privacy`)
                    } else {
                        window.location.href = `${process.env.REACT_APP_CRM_H5_LINK}/crmh5/#/jkt/doc/company/privacy`;
                    }
                }}>《绿地G优隐私政策》、</a>
                    <a className={styles.active} onClick={()=>{
                    if (Platform.check.isApp()) {
                        JsBridge.open('/front/member/register/protocol')
                    } else {
                        window.location.href = window.location.origin+'/front/member/register/protocol';
                    }
                }}>《G优尊享会员服务免责条款》</a></p>
            </div>

        </div>
    </div>;
}
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
