import React, {useState, useRef, useEffect} from 'react';
import './style.scss';
import Header from 'components/header';
import vsIcon from '../images/icon_vs.png';
import closeArrow from '../images/close-arrow.png';
import openArrow from '../images/open-arrow.png';
import Progress from '../components/progress';
import successTag from '../images/icon_caizhon.png';
import failTag from '../images/icon_weicaizhon.png';
import guanbi from "../images/close.png";
import shengli from '../images/shengli.png';
import { Popup } from 'antd-mobile';
import ListView from 'components/ListView1';
import Library from 'library';
import Services from 'services';
import {isIOS} from '../../../../library/util/publicFun';

const { JsBridge, Util } = Library;

const RecordDetail = () => {
  const { number='' } = Util.url.paramsToObj() || {};
  const [isShowPointsDetail, setIsShowPointsDetail] = useState(false);
  const [invitePopupVisible, setInvitePopupVisible] = useState(false);
  const [inviteRecord, setInviteRecord] = useState([]);
  const [inviteCount, setInviteCount] = useState(0);
  const [recordDetail, setRecordDetail] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const pageNum = useRef(1);
  const pageSize = useRef(10);
  const isIos = isIOS();

  const {
    group,
    round,
    homeTeamRegularScore,
    visitingTeamRegularScore,
    homeTeamFinalScore,
    visitingTeamFinalScore,
    scoreStatus,
    gameResult,
    homeTeamName,
    visitingTeamName,
    homeTeamFlag,
    visitingTeamFlag,
    winRatio,
    evenRatio,
    loseRatio,
    guessResult,
    worldCupNumberOfSettle,
    myGuessResult
   } = recordDetail || {};
   const {
    guessPoints,
    showPoints,
    totalPoints,
    invitePoints
  } = worldCupNumberOfSettle || {};

  useEffect(() => {
    getRecordDetail();
  }, []);

  const getRecordDetail = async () => {
    const result = await Services('MainPage', { code: '101293' }, {number});
    if(result.status > 0) {
      setRecordDetail(result.data);
    }
  }

  const getInviteRecord = async (isRefresh) => {
    pageNum.current = isRefresh ? 1: pageNum.current+1;
    const params = {
      pageNum: pageNum.current,
      pageSize: pageSize.current,
      number: number
    }
    const result = await Services('MainPage', { code: '101270' }, params);
    JsBridge.print(result);
    if(result.status > 0) {
      if(pageNum.current === 1) {
        setInviteRecord(result.data?.InviteRecord || []);
      } else {
        setInviteRecord([...inviteRecord, ...(result.data?.InviteRecord || [])]);
      }
      setHasMore(result.data?.InviteRecord?.length >= pageSize.current);
      setInviteCount(result.data?.count);
    }
    setIsLoad(true);
    setInvitePopupVisible(true);
  }
  if(!recordDetail) return null;
  return <div className='guess-record-detail'>
    <Header midText={`${homeTeamName}-${visitingTeamName}`} />
    <div className='guess-record-item'>
      {guessResult && scoreStatus=== '2' && <div className="guess-result-tag"><img src={guessResult === '2' ? successTag: failTag} alt="" /></div>}
      <div className="guess-record-item-title">
        {number > 48 ? <span>第{number}场 {group}</span>: <span>第{number}场 {group}组第{round}轮</span>}
        <span>{scoreStatus=== '2'? '已结束': '已结束 待揭晓'}</span>
      </div>
      {gameResult === "3" && <img className={isIos ? 'shengli-left-tag': 'android-shengli-left-tag'} src={shengli} alt="" />}
      {gameResult === "0" && <img className={isIos ? 'shengli-right-tag': 'android-shengli-right-tag'} src={shengli} alt="" />}
      <div className="battle-info">
        <div className="battle-info-top">
          <span>{homeTeamName}</span>
          <img className='country-flag' src={homeTeamFlag} alt="" />
        </div>
        <div className="battle-info-middle">
          <div className="battle-info-middle-score">
            {scoreStatus=== '2' && <span>{homeTeamFinalScore}</span>}
            <img src={vsIcon} alt="" />
            {scoreStatus=== '2' && <span>{visitingTeamFinalScore}</span>}
          </div>
        </div>
        <div className="battle-info-top">
          <span>{visitingTeamName}</span>
          <img className='country-flag' src={visitingTeamFlag} alt="" />
        </div>
        {number > 48 && scoreStatus=== '2' && <div className="routine-time-score">
          常规比赛时间比分：{homeTeamRegularScore}:{visitingTeamRegularScore}
        </div>}
      </div>
      <div className="pop-progress">
        <Progress successPercentage={winRatio} failPercentage={loseRatio} flatPercentage={evenRatio} />
      </div>
      <div className="guess-points-box">
        <div className="guess-points-title" onClick={() => setIsShowPointsDetail((pre) => !pre)}>
          <span className='my-guess-name'>我猜{myGuessResult ==='3'? `${homeTeamName}胜`: myGuessResult==='1'? '平局': `${visitingTeamName}胜`}</span>
          <div className='guess-points-title-right'>
            <span>{scoreStatus === '2'? '本场竞猜获得基础积分：': '本场猜对预计获得基础积分：'}<i>{totalPoints}</i></span>
            <img
              src={`${isShowPointsDetail ? openArrow: closeArrow}`}
              alt=""
              className={`${isShowPointsDetail ? 'open-arrow-img': 'close-arrow-img'}`}
            />
          </div>
        </div>
        <div className={`guess-points-content ${isShowPointsDetail ? 'points-detail-show': 'points-detail-hide'}`}>
          <div className="guess-points-content-item">
            <span>{scoreStatus === '2'? '竞猜得积分：': '竞猜得积分（待发放）：'}</span>
            <span>{guessPoints}</span>
          </div>
          <div className="guess-points-content-item">
            <span>{scoreStatus === '2'? '分享得积分：': '分享得积分（待发放）：'}</span>
            <span>{showPoints}</span>
          </div>
          <div className="guess-points-content-item" onClick={() => getInviteRecord(true)}>
            <span>{scoreStatus === '2'? '邀新得积分：': '邀新得积分（待发放）：'}</span>
            <span>{`${invitePoints}`}&gt;</span>
            <Popup
              visible={invitePopupVisible}
              onMaskClick={() => setInvitePopupVisible(false)}
              bodyStyle={{ borderRadius: "10px 10px 0 0" }}
            >
              <div className='invite-container'>
                <div className="invite-title">
                  <span>助威记录</span>
                  <img onClick={() => setInvitePopupVisible(false)} src={guanbi} alt="" />
                </div>
                <div className="current-match-info">
                  <span className='left-span'>{homeTeamName}</span>
                  <div className="vs-img"><img src={vsIcon} alt="" /></div>
                  <span className='right-span'>{visitingTeamName}</span>
                </div>
                <div className="invite-counts"> ——已有{inviteCount || 0}人帮你助威——</div>
                <div className="invite-user-info">
                  <ListView
                    onRefresh={() => getInviteRecord(true)}
                    list={inviteRecord}
                    hasMore={hasMore}
                    isLoad={isLoad}
                    loadMore={() => getInviteRecord(false)}
                    ListItem={({item}) => <InviteItem item={item}/>}
                  />
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const InviteItem = ({item}) => {
  const {imagePath, createTime, loginName} = item || {};
  return <div className="invite-info-item">
    <div className="info-item-left">
      <img src={imagePath} alt="" />
      <span>用户{loginName}帮你助威</span>
    </div>
    <div className="invite-time">{createTime?.slice(5)}</div>
  </div>
}

export default RecordDetail;
