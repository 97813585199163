import Home from './home';
import Form from './form';

export const Routes = [{
    path: '/recruit',
    component: Home,
    title: '联奇云招聘',
}, {
    path: '/recruit/form',
    component: Form,
    title: '联奇云招聘'
}]