import React, { useState, useEffect, useRef, useCallback, } from 'react';
import styles from './index.module.scss';

import extraImage1 from './images/extraImage1.png';
import extraImage2 from './images/extraImage2.png';
import extraImage4 from './images/extraImage4.png';
import extraImage5 from './images/daily_bao.png';
import TopNavigation from '../topNavigation';
import innerOpacityBg from './images/innerOpacityBg.png';
import { useParams, useHistory } from 'react-router-dom';
import Services from 'services';
import Library from 'library';
const { JsBridge, Hooks, Platform, Util } = Library;

export const HotInformation = (props) => {

    const [pageNum, setPageNum] = useState(1);
    const wrapRef = useRef();
    const loadingRef = useRef();

    const { tabIndex } = useParams();
    const [hotLook, setHotLook] = useState(tabIndex);

    const [noMore, setNoMore] = useState(false);

    const [homeOfficeNewsList, setHomeOfficeNewsList] = useState([]);
    const [topBanner, setTopBanner] = useState("");

    const specialDivRef = useRef(null);

    useEffect(() => {
        if (hotLook == 4) {
            // specialDivRef.current.scrollLeft = "140";
        } else {
            // specialDivRef.current.scrollLeft = "0";
        }
    }, []);

    const onScroll = (event,) => {

        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop + document.body.clientHeight >= document.documentElement.scrollHeight - 30) {
            getInitList();
        }
    }

    const handler = useCallback(onScroll);

    Hooks.useEventListener('scroll', handler, document);

    useEffect(() => {
        getInitList(true);
    }, [hotLook]);


    const setMyType = async (type) => {
        console.log('type---',type)
        setPageNum(1);
        if (type == 4) {
            // specialDivRef.current.scrollLeft = "140";
            setHomeOfficeNewsList([]);
            setHotLook(type);
            // this.specialDivRef.current.scrollLeft='0';
        } else {
            // specialDivRef.current.scrollLeft = "0";
            setHomeOfficeNewsList([]);
            setHotLook(type);
        }

        console.log("打印下当前的hotLook", hotLook);
    }
    const getInitList = async (isRefresh) => {
        if (loadingRef.current || (!isRefresh && noMore)) {
            return;
        }
        loadingRef.current = true;

        const result = await Services('MainPage', { code: '100009 ' },
            {
                pageNum: isRefresh ? 1 : pageNum,
                pageSize: 10,
                type: hotLook
            });
        loadingRef.current = false;
        if (result.status > 0) {
            const temp = result.data.guHomeOfficeNewsList;
            const nowList = pageNum === 1 ? temp : [...homeOfficeNewsList, ...temp]
            // console.log(nowList)
            setHomeOfficeNewsList(nowList);
            setPageNum(isRefresh ? 2 : pageNum + 1);
            setNoMore(temp.length < 10);
            setTopBanner(result.data.advertisement.tinyPicUrl);
        }
    }

    const renderMainCtn = () => {
        return homeOfficeNewsList.map((item, index) => {
            let extraImage
            if (hotLook == 1) {
                extraImage = getImage(item.thumbUrl);
            } else if (hotLook == 2) {
                extraImage = extraImage1;
            } else if (hotLook == 3) {
                extraImage = extraImage2;
            }else if (hotLook == 5) {
                extraImage = extraImage5;
            } else {
                extraImage = extraImage4;
            }
            return <div className={styles.modalParagraph} key={index} onClick={() => {
                //JsBridge.push('Web', { uri: item.url });
                //
                if (hotLook == 1 || hotLook == 2 || hotLook == 3|| hotLook == 5) {
                    JsBridge.push('Web', { uri: item.url });
                } else {
                    JsBridge.push('Web', { uri: `${window.location.origin}/front/member/family/messageDetail?id=${item.id}&type=4` });
                }

            }}>
                <div className={styles.modalParagraphLeft}>
                    <div className={styles.leftTopText}>{item.title}</div>
                    <div className={styles.leftBottomText}>{item.source} {item.articleUpdateTime?.substring(5, 16)}</div>
                </div>
                <div className={styles.modalParagraphRight}>
                    <img src={extraImage} className={styles.rightImg} referrerpolicy="no-referrer" />
                </div>
            </div>
        })
    }

    const getImage = (url) => {
        return url.replace(/^(http)[s]*(\:\/\/)/,'https://images.weserv.nl/?url=')
    }

    return <div className={styles.hotContainer} >
        <TopNavigation title='热门资讯' style={{ zIndex: "20" }} />
        <div className={styles.wrapperOpacity} style={tabIndex ? { display: "none" } : { display: 'block' }}>

            <img src={innerOpacityBg} className={styles.innerOpacityBg} />
        </div>
        {/* //backgroundImage: `url(${Background})`  */}
        <div className={styles.header}>
            {/* <div className={styles.header}> */}
            {/* <img src={topBanner} className={styles.headBg}/> */}
            <div className={styles.titleText}>
                最新资讯 每日更新
            </div>
            {/* <div className={styles.botRigText}>
                 绿地g优尊享会
            </div> */}
        </div>
        <div className={styles.secondPart} ref={specialDivRef}>
            <span className={styles.fontWeightLine1} style={hotLook == 5 ? { opacity: '1' } : { opacity: '0' }}>&nbsp;</span>
            <span className={styles.seTitleText} style={hotLook == 5 ? { color: '#CAA846' } : { color: '' }} onClick={() => { setMyType(5) }}>每日早报</span>

            <span className={styles.fontWeightLine1} style={hotLook == 1 ? { opacity: '1' } : { opacity: '0' }}>&nbsp;</span>
            <span className={styles.seTitleText} style={hotLook == 1 ? { color: '#CAA846' } : { color: '' }} onClick={() => { setMyType(1) }}>热点观察</span>

            <span className={styles.fontWeightLine1} style={hotLook == 2 ? { opacity: '1' } : { opacity: '0' }}>&nbsp;</span>
            <span className={styles.seTitleText} style={hotLook == 2 ? { color: '#CAA846' } : { color: '' }} onClick={() => { setMyType(2) }}>市场聚焦</span>

            <span className={styles.fontWeightLine1} style={hotLook == 3 ? { opacity: '1' } : { opacity: '0' }}>&nbsp;</span>
            <span className={styles.seTitleText} style={hotLook == 3 ? { color: '#CAA846' } : { color: '' }} onClick={() => { setMyType(3) }}>ESG每周关注</span>

            <span className={styles.fontWeightLine1} style={hotLook == 4 ? { opacity: '1' } : { opacity: '0' }}>&nbsp;</span>
            <span className={styles.seTitleText} style={hotLook == 4 ? { color: '#CAA846' } : { color: '' }} onClick={() => { setMyType(4) }}>股市资讯</span>
        </div>

        <div className={styles.thirdPart} ref={wrapRef}>
            {renderMainCtn()}
        </div>


    </div>
}
