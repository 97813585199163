const ua = navigator.userAgent.toLowerCase();

export const check = {
    isWechat: () => {
        return ua.indexOf('micromessenger') > -1;
    },
    isApp: () => {
        return !!window.ReactNativeWebView;
    },
    isH5: () => {
        return !check.isWechat() && !check.isApp();
    },
    isAndroid: () => {
        return ua.indexOf('android') > -1 || ua.indexOf('linux') > -1;
    },
    isMiniprogram: () => {
        //navigator.userAgent 从微信7.0开始支持
        return window.__wxjs_environment === 'miniprogram' || /miniprogram/i.test(navigator.userAgent)
    }
}

export const name = () => {
    if (check.isWechat()) {
        return 'Wechat';
    } else if (check.isApp()) {
        return 'GuApp';
    } else {
        return 'Wap';
    }
}
