import React, {useState} from 'react';
import './settlementItem.scss';
import closeArrow from '../images/close-arrow.png';
import openArrow from '../images/open-arrow.png';

const SettlementItem = ({sattleInfo}) => {
  const {
    settleNum,
    settleTotalPoints,
    pointCoefficient,
    singleNumSettleList
  } = sattleInfo || {};

  return <div className='settlement-item'>
    <div className="item-top common-flex">
      <span>结算场次</span>
      <span>{settleNum}</span>
    </div>
    <div className="points-detail-box">
      {singleNumSettleList?.length > 0 && singleNumSettleList.map((fItem, index) => {
        return <PoisntsDetailItem key={`field-${index}`} item={fItem} />
      })}
    </div>
    <div className="item-bottom common-flex">
      <div className="double-count">连胜系数：<i>{pointCoefficient}</i></div>
      <div className="total-points">结算总积分：<i>{settleTotalPoints}</i></div>
    </div>
  </div>
}

const PoisntsDetailItem = ({item}) => {
  const [isPointsShow, setIsPointsShow] = useState(false);
  const { number, guessPoints, showPoints, invitePoints, settleSingleTotalPoints } = item || {};

  const showPointsDetail = () => {
    setIsPointsShow((pre) => !pre);
  }

  return <div className="field-points" onClick={() => showPointsDetail()}>
    <div className="field-points-title common-flex">
      <span>第{number}场</span>
      <div className='field-points-title-right'>
        <span>{settleSingleTotalPoints}</span>
        <img
          className={`${isPointsShow ? 'open-arrow-img': 'close-arrow-img'}`}
          src={`${isPointsShow ? openArrow: closeArrow}`}
          alt=""
        />
      </div>
    </div>
    <div className={`field-points-detail ${isPointsShow ? 'points-detail-show': 'points-detail-hide'}`}>
      <div className="field-points-detail-item common-flex">
        <span>竞猜得积分：</span>
        <span>{guessPoints}</span>
      </div>
      <div className="field-points-detail-item common-flex">
        <span>分享得积分：</span>
        <span>{showPoints}</span>
      </div>
      <div className="field-points-detail-item common-flex">
        <span>邀新得积分：</span>
        <span>{invitePoints}</span>
      </div>
    </div>
  </div>
}

export default SettlementItem;