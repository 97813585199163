/**
 * @description NFT-超级分享官
 * @returns /front/activities/regularShare/nft-official
*/
import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons'
import { Header, RulesModal } from 'components';
import Service from './service';
import ShareModal from '../component/shareModal';
import PopupModal from '../component/popupModal';
import ReceiveModal from '../component/receiveModal';
import {Slogan} from 'components/slogan';
import Styles from './index.module.scss';
import JsBridge from 'library/jsbridge';

const NFTOfficial = (props) => {

    const [activityInfo, setActivityInfo] = useState({})

    useEffect(()=>{
        refeshData(true)
    },[])

    const refeshData = async (showLoading = false)=> {
        const result = await Service.getInitData(showLoading)
        
        if(result?.data) {
            setActivityInfo(result.data)
        }
        return result?.data

    }

    // 活动规则
    const onRuleClick =()=>{
        RulesModal.open({
            btnStyle: {background: 'linear-gradient(180deg, #FF94DE 16%, #FF00B1 100%)'},
            activityCode: Service.activityCode,
            codeType: 'activityRule',
            code: Service.activityCode
        });
    }

    // 邀新
    const onInvite = ()=> {
        const isEnd = new Date(activityInfo?.systemTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if(isEnd >= 0) {
          Toast.show('活动已结束');
          return;
        }
        ShareModal.open({
            activityCode: Service.activityCode, 
            inviteCode: activityInfo.inviteCode,
            bgImg: require('images/nft-official/shareBg.png')
        })
        
    }
    // 我的鹅积分
    const onMyPoint = ()=> {
        JsBridge.navigate('tabMall')
    }
    // 邀新记录
    const onInviterRecord = () => {
        PopupModal.open(Service.activityCode);
    }
    //领取奖励
    const onReceive = async () => {
        const isEnd = new Date(activityInfo?.systemTime?.replace(/-/g, '/')).getTime() - new Date(activityInfo?.endTime?.replace(/-/g, '/')).getTime();
        if (isEnd >= 0) {
            Toast.show('活动已结束');
            return;
        }
        // 领取奖励
        const res = await Service.receivePrize()
        if (res) {
            ReceiveModal.open({
                prizeInfo: {}
            })
            setTimeout(() => {
                refeshData()
            }, 500)
        } else {
            Toast.show({
                content: '领取奖励失败，请重新领取',
            })
        }

    }

    return (
        <div className={Styles.nft_official}>
            <Header bgColor={'transparent'} theme='' />
            <div className={Styles.body_wraper}>
                <div className={Styles.activityRule} onClick={onRuleClick}>活动规则</div>
                <div className={Styles.bgTop}>
                    <div>
                        <div className={Styles.btnBox}>
                            <div className={Styles.leftBtn} onClick={onMyPoint}>
                                <div>{activityInfo.myPoint||0}</div>
                                <span>我的积分</span>
                            </div>
                            <div className={Styles.rightBtn} onClick={onInviterRecord}>
                                <div>邀请记录</div>
                                <RightOutline color='#431179' fontSize={15}/>
                            </div>
                        </div>
                        <div className={Styles.centerBtn} onClick={onInvite}>
                            <div>立即邀请</div>
                            <span>{`邀请成功${activityInfo.successCount||0}人`}</span>
                        </div>
                    </div>
                </div>
                <div className={Styles.bgBottom}>
                    {
                        // flag: 0 未达到条件 1 达到条件未领取 2 已领取 
                        activityInfo.flag == 2?
                        <span>已领取</span>:
                        activityInfo.flag == 1 ?
                        <div className={Styles.enable} onClick={onReceive}></div>
                        : <div/>
                    }
                    
                    
                </div>
                <Slogan className={Styles.slogan} src={require('images/icon/logo-white.png')}/>
            </div>
        </div>
    )
}

export {
    NFTOfficial
}