/**
 * @description 2024茅台活动分享-邀新
 * @returns /front/activities/activityShare/invite-zone
 */
import React, { useState, useEffect, useRef } from "react";
import { RightOutline } from "antd-mobile-icons";
import { Toast } from "antd-mobile";
import { Header, RuleModal, RecordModal } from "components";
import Library from "library";
import Service from "./service";
import ActivityShareModal from "components/activityShareModal";
import Interceptor from "../component/interceptor";
import Styles from "./index.module.scss";
import "./styles.css";
import logo from "images/activityShare/get-logo.png";
import logo1 from "images/activityShare/get-logo1.png";
import logo2 from "images/activityShare/get-logo2.png";
import btn6 from "images/activityShare/btn6.png";
import btn7 from "images/activityShare/btn7.png";
import Services from "../../../../services";
import { isEmptyString } from "../../../../library/util";

const { JsBridge, Util } = Library;

const InviteZone = (props) => {
  const [activityInfo, setActivityInfo] = useState({});
  const [inventInfo, setInventInfo] = useState({});

  const PageRef = useRef();

  const scroeArr = [
    {
      id: 1,
      title: "方式一",
      img: logo1,
      renderSubtitle: (point)=> {
        return `邀新得${point}积分/人`
      },
      btnText: () => {
        return "去邀新";
      },
      btn: () => {
        const cuttentTime = inventInfo?.systemTime
          ? new Date(inventInfo?.systemTime?.replace(/-/g, "/"))
          : new Date();
        const isEnd =
          cuttentTime.getTime() -
          new Date(inventInfo?.endTime?.replace(/-/g, "/")).getTime();
        if (isEnd >= 0) {
          return btn6;
        } else {
          return btn7;
        }
      },
    },
    {
      id: 2,
      title: "方式二",
      img: logo2,
      subTitle: "积分充值",
      btnText: () => {
        return "去充值";
      },
      btn: () => btn7,
    },
  ];
  const getArr = [
    {
      id: 1,
      title: "方式一",
      img: logo,
      renderSubtitle: (num, count) => {
        return (
          <div>
            已邀新：
            <span style={{ color: "#FF1515" }}>
              {num}/{count}
            </span>
          </div>
        );
      },
      btnText: (c) => {
       if (c > 0) {
          return `待领取${c}次`;
        } 
        return "去邀新";
      },
      btn: () => {
        const cuttentTime = activityInfo?.systemTime
          ? new Date(activityInfo?.systemTime?.replace(/-/g, "/"))
          : new Date();
        const isEnd =
          cuttentTime.getTime() -
          new Date(activityInfo?.activityEndTime?.replace(/-/g, "/")).getTime();
        if (isEnd >= 0) {
          return btn6;
        } else {
          return btn7;
        }
      },
    },
    {
      id: 2,
      title: "方式二",
      img: logo,
      subTitle: "GU Land钻石抽奖",
      btnText: () => {
        return "去抽奖";
      },
      btn: () => {
        const cuttentTime = activityInfo?.systemTime
          ? new Date(activityInfo?.systemTime?.replace(/-/g, "/"))
          : new Date();
        const isEnd =
          cuttentTime.getTime() -
          new Date(activityInfo?.activityEndTime?.replace(/-/g, "/")).getTime();
        if (isEnd >= 0) {
          return btn6;
        } else {
          return btn7;
        }
      },
    },
  ];
  useEffect(() => {
    refeshData(true);
    refeshInventData(true);
    onDidFocus();
  }, []);
  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
      refeshData(true);
      refeshInventData(true);
    });
  };

  const refeshData = async (showLoading = false) => {
    const result = await Service.getInitData(showLoading);
    if (result?.data) {
      setActivityInfo(result.data);
    }
    return result?.data;
  };
  const refeshInventData = async (showLoading = false) => {
    const result = await Service.getInventData(showLoading);
    if (result?.data) {
      setInventInfo(result.data);
    }
    return result?.data;
  };

  const receivePrize = async () => {
    try {
      const res = await Service.receivePrize({ cycle: activityInfo?.cycle });
      console.log("res", res);
      if (res?.status > 0) {
        // 领取成功刷新界面
        setTimeout(() => {
          refeshData(true);
        }, 500);
        Toast.show("领取成功");
      }
    } catch (err) {
      console.log(err);
    }
  };
  // 开奖
  const onRecordClick = () => {
    RecordModal.open({
      title: "开奖记录",
      btnStyle: {
        display: "none",
        background: "linear-gradient(90deg, #FF431F 0%, #FF1858 100%)",
      },
      params: {
        activityCode: Service.activityCode,
      },
    });
  };
  // 活动规则
  const onRuleClick = () => {
    RuleModal.open({
      title: "活动规则",
      btnStyle: {
        display: "none",
        background: "linear-gradient(90deg, #FF431F 0%, #FF1858 100%)",
      },
      codeType: "activityRule",
      activityCode: "MoutaiSaleRule",
      code: Service.activityCode,
    });
  };
  // 邀新记录
  const onInviterRecord = () => {
    const param = Util.url.objectToQueryParams({
      activityCode: Service.activityCode,
      activityCycleDetailId: activityInfo?.activityCycleDetailId,
      cycle: activityInfo?.cycle,
    });
    if (JsBridge.hasWebViewBridge()) {
      JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}/front/activities/activityShare/invite-record?${param}`,
      });
    } else {
      props.history.push("/front/activities/activityShare/invite-record");
    }
  };
  //我的抽奖券
  const onTicketRecord = () => {
    if (JsBridge.hasWebViewBridge()) {
      JsBridge.push("CustomWeb", {
        uri: `${window.location.origin}/front/activities/activityShare/ticket-record`,
      });
    } else {
      props.history.push("/front/activities/activityShare/ticket-record");
    }
  };
  //去抽奖
  const onRaffClick = () => {
    const cuttentTime = activityInfo?.systemTime
      ? new Date(activityInfo?.systemTime?.replace(/-/g, "/"))
      : new Date();
    const isEnd =
      cuttentTime.getTime() -
      new Date(activityInfo?.activityEndTime?.replace(/-/g, "/")).getTime();
    if (isEnd >= 0) {
      Toast.show("当期活动已结束");
      return;
    }
    Services("MainPage", { code: 600066 }, { source: "app" }).then((res) => {
      const { status, data } = res;
      if (status > 0) {
        let token = data.token;
        if (!isEmptyString(token)) {
          let uri = `${process.env.REACT_APP_NFT_LINK}/landGame/jewelLand?isPortrait=1&t=${token}`;
          JsBridge.push("CustomWeb", { uri: uri });
        }
      }
    });
  };
  //充值
  const onTopUpClick = () => {
    JsBridge.verifyRealName("积分充值需先进行实名认证, 请点击前往认证").then(
      (res) => {
        if (res.result) {
          JsBridge.push("CustomWeb", {
            uri: `${window.location.origin}/front/member/pointRecharge/home`,
          });
        }
      }
    );
  };

  // 邀新送积分
  const onInvite = () => {
    const cuttentTime = inventInfo?.systemTime
      ? new Date(inventInfo?.systemTime?.replace(/-/g, "/"))
      : new Date();
    const isEnd =
      cuttentTime.getTime() -
      new Date(inventInfo?.endTime?.replace(/-/g, "/")).getTime();
    if (isEnd >= 0) {
      Toast.show("活动已结束");
      return;
    }
    const param = {
      ic: activityInfo.inviteCode,
      ac: Service.inviteCode,
    };
    const shareUrl = `${
      window.location.origin
    }/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString(
      "base64"
    )}`;
    ActivityShareModal.open({
      shareUrl,
      qrcodeConfig: { size: 65 },
      children: (
        <img
          className={Styles.shareInvite}
          src={require("images/activityShare/shareBg.png")}
          alt="bg"
        />
      ),
    });
  };

  // 活动邀新
  const onActivityInvite = () => {
    const cuttentTime = activityInfo?.systemTime
      ? new Date(activityInfo?.systemTime?.replace(/-/g, "/"))
      : new Date();
    const isEnd =
      cuttentTime.getTime() -
      new Date(activityInfo?.activityEndTime?.replace(/-/g, "/")).getTime();
    if (isEnd >= 0) {
      Toast.show("当期活动已结束");
      return;
    }
    const param = {
      ic: activityInfo.inviteCode,
      ac: Service.activityCode,
    };
    const shareUrl = `${
      window.location.origin
    }/front/inviter/registers/${Buffer.from(JSON.stringify(param)).toString(
      "base64"
    )}`;
    ActivityShareModal.open({
      className: "activityShareCode",
      shareUrl,
      qrcodeConfig: { size: 54 },
      children: (
        <img
          className={Styles.shareInvite2}
          src={require("images/activityShare/shareBg2.png")}
          alt="bg"
        />
      ),
    });
  };

  return (
    <div className={Styles.invite_zone} ref={PageRef}>
      {JsBridge.hasWebViewBridge() && (
        <Header bgColor={"transparent"} theme={"light"} />
      )}
      <div className={Styles.body_wraper}>
        <div className={Styles.bgTop}>
          <div className={Styles.activityBtns}>
            <div
              onClick={() => {
                onRuleClick();
              }}
            >
              规则
            </div>
            <div onClick={onInviterRecord}>邀新记录</div>
            <div onClick={onTicketRecord}>我的抽奖券</div>
          </div>
        </div>
        <div className={Styles.bgCenter}>
          <div
            className={`${
              activityInfo?.activityStatus === 2
                ? Styles.bgCenterTop
                : Styles.bgCenterTop1
            }`}
          >
            <div className={Styles.info}>
              <div className={Styles.title}>{`第${
                (activityInfo?.cycle < 10
                  ? "0" + activityInfo?.cycle
                  : activityInfo?.cycle) || "-"
              }期`}</div>
              <div className={Styles.bottom}>
               { activityInfo?.activityStatus !== 2 && <div>
                  我的奖券
                  <span className={Styles.special}>
                    {activityInfo?.ticketsNum || "0"}
                  </span>
                  张
                </div>}
                <div
                  onClick={() => {
                    onRecordClick();
                  }}
                >
                  开奖记录
                  <RightOutline />
                </div>
              </div>

              <div className={Styles.infoBox}>
                {activityInfo?.activityStatus === 2 && (
                  <>
                    <div className={Styles.infoBoxTitle}></div>
                    <div className={Styles.infoBoxNum}>
                      {(activityInfo?.winNum || [])
                        .map((item, index) => {
                          return (
                            <div key={index} className={Styles.num}>
                              {item}
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
                {activityInfo?.activityStatus !== 2 && (
                  <>
                    <div className={Styles.infoBoxTitle}></div>
                    <div className={Styles.infoBoxNum}>
                      <CountDownComponent
                        className={Styles.ownCountDown}
                        sysTime={activityInfo?.systemTim}
                        endTime={activityInfo?.activityEndTime}
                        des=""
                        refreshData={refeshData}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.bgCenterMiddle}>
            <div className={Styles.title}></div>
            <div className={Styles.info}>
              {getArr.map((item, index) => {
                return (
                  <div key={index} className={Styles.infoBox}>
                    <div className={Styles.infoBoxTitle}>{`第${
                      (activityInfo?.cycle < 10
                        ? "0" + activityInfo?.cycle
                        : activityInfo?.cycle) || "-"
                    }期`}</div>
                    <div className={Styles.subTitle}>{item?.title}</div>
                    <div className={Styles.infoImg}>
                      <img src={item?.img} alt="" />
                    </div>
                    <div className={Styles.thirdTitle}>
                      {item?.subTitle ||
                        item?.renderSubtitle(
                          activityInfo?.inviteNum || 0,
                          activityInfo?.inviteNewArrivals || 100
                        )}
                    </div>
                    <div
                      className={Styles.opt}
                      onClick={() => {
                        if (item?.id === 1) {
                          if (activityInfo?.remainCount > 0) {
                            receivePrize();
                          } else {
                            onActivityInvite();
                          }
                        } else {
                          onRaffClick();
                        }
                      }}
                    >
                      <img src={item?.btn()} alt="" />
                      <div className={Styles.optText}>{item?.btnText(activityInfo?.remainCount || 0)}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={Styles.bgCenterBottom}>
            <div className={Styles.title}></div>
            <div className={Styles.subTitle}>积分不够看这里</div>

            <div className={Styles.info}>
              {scroeArr.map((item, index) => {
                return (
                  <div key={index} className={Styles.infoBox}>
                    <div className={Styles.title}>{item?.title}</div>
                    <div className={Styles.infoImg}>
                      <img src={item?.img} alt="" />
                    </div>
                    <div className={Styles.subTitle}>{item?.subTitle || item?.renderSubtitle(inventInfo?.givePoint ?? 0)}</div>
                    <div
                      className={Styles.opt}
                      onClick={() => {
                        if (item?.id === 1) {
                          onInvite();
                        } else {
                          onTopUpClick();
                        }
                      }}
                    >
                      <img src={item?.btn()} alt="" />
                      <div className={Styles.optText}>{item?.btnText()}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* 底部logo */}
        <div className={Styles.footer_logo}>
          <img src={require("images/activityShare/bottom-logo.png")} alt="" />
        </div>
        <Interceptor />
      </div>
    </div>
  );
};
//倒计时
const CountDownComponent = ({
  sysTime,
  endTime,
  des,
  refreshData,
  className,
}) => {
  const initalTime = { day: "00", hour: "00", minute: "00", second: "00" };
  const [countTime, setCountTime] = useState(initalTime);
  const timeStampCurrent = useRef(null);
  const timerCurrent = useRef(null);

  const clearAllTimer = () => {
    //清除所有的定时器
    let end = setInterval(function () {}, 3000);
    for (let i = 1; i <= end; i++) {
      i && clearInterval(i);
    }
  };

  const countDown = (currentTime, endTime, callBack) => {
    if (!currentTime || !endTime) {
      callBack && callBack();
      return initalTime;
    }
    const currentTimeStamp = Date.parse(currentTime.replace(/-/g, "/"));
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp;
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, "/"));
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      callBack && callBack();
      return initalTime;
    }
    let remianAllSeconds = Math.floor(distanceStamp / 1000);
    let day = Math.floor(remianAllSeconds / (60 * 60 * 24));
    let hour = Math.floor((remianAllSeconds / (60 * 60)) % 24);
    let minute = Math.floor((remianAllSeconds / 60) % 60);
    let second = Math.floor(remianAllSeconds % 60);

    day = day >= 10 ? day : "0" + day;
    hour = hour >= 10 ? hour : "0" + hour;
    minute = minute >= 10 ? minute : "0" + minute;
    second = second >= 10 ? second : "0" + second;
    return { day: day, hour: hour, minute: minute, second: second };
  };

  const timerStart = () => {
    timerCurrent.current && clearInterval(timerCurrent.current);
    if (!sysTime || !endTime) return;
    const currentTimeStamp = Date.parse(sysTime.replace(/-/g, "/"));
    if (timeStampCurrent.current == null) {
      timeStampCurrent.current = currentTimeStamp;
    }
    const endTimeStamp = Date.parse(endTime.replace(/-/g, "/"));
    const distanceStamp = endTimeStamp - timeStampCurrent.current;
    if (distanceStamp <= 0) {
      // 活动结束
      return;
    }


    timerCurrent.current = setInterval(() => {
      if (timeStampCurrent.current) {
        timeStampCurrent.current = timeStampCurrent.current + 1000;
      }
      let obj = countDown(sysTime, endTime, () => {
        timerCurrent.current && clearInterval(timerCurrent.current);
        refreshData && refreshData();
      });
      setCountTime(obj);
    }, 1000);
  };

  const visibilitychange = () => {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        clearAllTimer();
      } else {
        refreshData && refreshData();
      }
    });
  };

  useEffect(() => {
    visibilitychange();
  }, []);

  useEffect(() => {
    timerStart();
    return () => {
      timerCurrent.current && clearInterval(timerCurrent.current); //先清空之前的定时器
    };
  }, [sysTime, endTime]);
  const { day, hour, minute, second } = countTime;
  return (
    <div className={className}>
      <span>{des}</span>
      <span>{day}</span>
      <span>天</span>
      <span>{hour}</span>
      <span>时</span>
      <span>{minute}</span>
      <span>分</span>
      <span>{second}</span>
      <span>秒</span>
    </div>
  );
};

export { InviteZone };
