import React, { useState, useEffect, useRef, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
import services from 'services';
import Animated from "animated/lib/targets/react-dom";
import { CouponCell } from "../couponCell";
import { useEventListener } from "../../../../library/hooks";
import Library from 'library';
export const HistoryCoupon = (props) => {
    const [index, setIndex] = useState(0);
    const [tabs, setTabs] = useState([{ title: '已使用', status: 2 }, { title: '已过期', status: 3 }]);
    const history = useHistory();
    const navTab = useRef();
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    const handleChangeIndex = index => {
        if (index < tabs.length) {
            setIndex(index);
        }
    };

    return <div className={styles.container}>
        <div className={styles.nav} ref={navTab}>
            <ul>
                {tabs.map((item, key) => {
                    return <li onClick={() => {
                        setIndex(key);
                        // handleSwitch(key, 'end');
                    }} key={key} className={index.toString() === key.toString() ? styles.active : ''}>
                        <a>{item.title}</a>
                    </li>;
                })}
            </ul>
        </div>
        <SwipeableViews
            index={index}
            className={styles.swiper}
            onChangeIndex={handleChangeIndex}
        >
            {tabs.map((item, key) => {
                return <CouponList item={item} key={key} />
            })}
        </SwipeableViews>
    </div>;
}

const CouponList = (props) => {
    const [state, setState] = useState({ arrSource: [], pageNum: 1, isLoading: false, isLoadingAll: false });
    useEffect(() => {
        if (state.arrSource.length === 0) {
            getData(true);
        }
    }, []);

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        let { clientHeight, scrollHeight, scrollTop } = pageView.current;
        if (scrollTop + clientHeight >= scrollHeight && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            getData();
            timer.current = setTimeout(() => {
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    useEventListener('scroll', handler, pageView.current);

    const getData = async (refresh) => {
        setState({ ...state, isLoading: true });
        let currentPageNum = refresh ? 1 : state.pageNum + 1;
        let params = { status: props.item.status, pageNum: currentPageNum, pageSize: 10 };
        const result = await services('ClientCoupon', 'HistoryList', params);
        if (result && result.status > 0) {
            let { list, count } = result.data;
            let arrSource = state.arrSource;
            if (currentPageNum === 1) {
                arrSource = list;
            } else {
                arrSource = [...arrSource, ...list];
            }
            setState({ ...state, arrSource: arrSource, pageNum: currentPageNum, isLoading: false, isLoadingAll: count <= arrSource.length });
        } else {
            setState({ ...state, isLoading: false });
        }
    }
    return <ul className={styles.wrap} ref={pageView}>
        {state.arrSource.map((item, index) => {
            return <CouponCell key={index} item={item} btnText={props.item.title} disabled onClick={() => {
                console.log('点击使用')
            }} />
        })}
    </ul>
}

