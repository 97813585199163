import React, { useEffect, useState } from "react";
import styles from "./raffleRecord.module.scss";
import Library from "../../../../library";
import Services from "../../../../services";
import no_data from '../../../../images/noCollection.png'
import { PullToRefresh, InfiniteScroll, List, Empty, Toast } from 'antd-mobile'
import Header from "components/header";
const { JsBridge, Util } = Library;

export const RaffleRecord = () => {
    const [pageNum, setPageNum] = useState(1)
    const [dataList, setDataList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    useEffect(() => {
        loadData();
    }, []);

    let loadData = async () => {
        setPageNum(1)
        setIsLoading(true)
        const res = await Services('MainPage', { code: 300061 }, {
            activityCode: "Lottery23",
            pageNum: `1`,
            pageSize: "10"
        });
        setIsLoading(false)
        if (!!res && res?.status == 1 && !!res?.data) {
            let { list = [],count = 0 } = res.data
            setDataList(list)
            setHasMore(list.length >= 10)
        }
    }
    let loadMore = async () => {
        const res = await Services('MainPage', { code: 300061 }, {
            activityCode: "Lottery23",
            pageNum: `${pageNum + 1}`,
            pageSize: "10"
        });
        if (!!res && res?.status == 1 && !!res?.data) {
            let { list = [] ,count = 0} = res.data
            setDataList([...dataList, ...list])
            setHasMore(list.length >= 10)
            setPageNum(pageNum + 1)
        }
    }

    const addOrChangeAddress = (item) => {
        // JsBridge.push('CustomWeb', {uri: `http://192.168.110.14:3000/front/activities/lottery/views/addressMgr?id=${item.id}`});
        if(item.address) {
            const param = Util.url.objectToQueryParams(item)
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/activities/lottery/orderDetail?${param}`});
        }else {
            const param = Util.url.objectToQueryParams({
                idf: 'lottery',
                activityCode: 'Lottery23',
                id: item.id,
                name: item.prizeName,
                imgUrl: item.prizeImgUrl
            })
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/guapp/activityOrder?${param}`});
            JsBridge.addListener().then((result) => {
                loadData(true).then();
            });
        }
        
    }

    return (
        <div className={styles.raffle_record_root}>
            <Header midText={`抽奖记录`} bgColor={'#fff'} />
            <div className={styles.container}>
                {!isLoading && (!dataList || dataList?.length == 0) &&
                    <Empty style={{ width: "100%", paddingTop: ' 30%' }} image={<img src={no_data} />} description="暂无数据" />
                }
                {!!dataList && !!dataList.length &&
                    <PullToRefresh 
                      onRefresh={loadData}
                      headHeight={70}
                      threshold={100}
                      pullingText={
                        <img 
                          src={require('../../../../images/queshengye/new-loading.gif')}
                          style={{height: 50}} alt=''
                        />
                      }
                      refreshingText={
                        <img
                          src={require('../../../../images/queshengye/new-loading.gif')}
                          style={{height: 50}} alt=''
                        />
                      }
                      canReleaseText={
                        <img
                          src={require('../../../../images/queshengye/new-loading.gif')}
                          style={{height: 50}} alt=''
                          />
                        }
                      renderText={null}
                    >
                        <div className={styles.listContainer}>
                            {dataList?.map((item, index) => (
                                <div className={styles.itemContainer} key={`${index} - ${JSON.stringify(item)}`}>
                                    <div className={styles.itemLeft}>
                                        <div className={styles.itemTop}>
                                            <div className={styles.itemTopTitle}>
                                                {`${item?.prizeName}`}
                                            </div>
                                        </div>
                                        <div className={styles.itemTime}>
                                            {`${item?.createTime}`}
                                        </div>
                                    </div >
                                    {item?.prizeType === 3 && 
                                        <div className={styles.itemAddressBtn}>
                                            <div className={styles.itemAddressBtnTitle} 
                                                onClick={() => {addOrChangeAddress(item)}}>
                                                {item.address ? `查看领取奖品详细信息` : `填写地址领取奖品`}
                                            </div>
                                        </div>
                                    }
                                </div >
                            ))}
                        </div>
                        {!isLoading &&
                            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
                        }
                    </PullToRefresh>
                }
            </div>
        </div>
    )
}
