import React, {useCallback, useEffect, useRef, useState} from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import styles from './recordList.module.scss';
import {useEventListener} from "../../../library/hooks";
import {Actions} from 'reduxs';
import {NucalmModal} from './components/nucalmModal';
export const RecordList = (props) => {
    const [pageNum,setPageNum] = useState(1);
    const [state, setState] = useState({ arrData: [],  isLoading: false, isLoadingAll: false });
    const [visible, setVisible] = useState(false);
    const [currentId,setCurrentId] = useState('');
    useEffect(() => {
        getList()
    }, [pageNum]);

    const getList = async ()=> {
        setState({...state,isLoading:true});
        let res = await Services('NuCalm','RecordList',{page:pageNum,pageSize:10});
        if (res.status > 0) {
            let arr = res.data.list;
            let arrData = [];
            if (pageNum === 1) {
                arrData = arr;
            } else {
                arrData = [...state.arrData,...arr];
            }
            setState({...state,isLoading:false,arrData,isLoadingAll: arr.length < 10});
        }
    }

    const refresh = async ()=>{
        if (pageNum === 1) {
            getList();
        }else {
            setPageNum(1);
        }
    }

    const cancel = async (orderId)=>{
        let res = await Services('NuCalm','Cancel',{orderId});
        if (res.status > 0) {
            refresh();
        }
    }

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop + document.body.clientHeight >= document.documentElement.scrollHeight - 30 && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            setPageNum(pageNum+1);
            timer.current = setTimeout(() => {
                isDebounce = false;
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    useEventListener('scroll', handler, document);

    return <div className={styles.container}>
        {state.arrData.map((item,index)=>{
            let {type,promotionTime,status,id} = item;
            let intStatus = parseInt(status,10);
            return <div className={styles.item}>
                <div className={styles.labelContent}>
                    <p className={styles.label1}>{type}</p>
                    <p className={styles.label2}>{promotionTime}</p>
                </div>
                {intStatus === 1 ?<button className={styles.btn1}>已预约</button> : null}
                {intStatus === 1 ?<button className={styles.btn2} onClick={()=>{
                    setCurrentId(id);
                    setVisible(true);
                }}>取消预约</button> : null}
                {intStatus === 2 ?<button className={styles.btn3}>已使用</button> : null}
                {intStatus === 3 ?<button className={styles.btn3}>已过期</button> : null}
                {intStatus === 0 ?<button className={styles.btn3}>已取消</button> : null}
            </div>
        })}
        <NucalmModal
            visible={visible}
            content={'确定要取消预约？'}
            onOK={()=>{
                cancel(currentId);
                setVisible(false);
            }}
            onCancel={()=>{
                setVisible(false);
            }}
        />
    </div>;
};
