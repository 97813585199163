import React from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import styles from './exitModal.module.scss';
import exitHeadImage from './imgs/bg_tanchuang1.png';
import exitGiftImage from './imgs/icon_liwuhe.png';

const { JsBridge } = Library;
export default (props) => {
    const history = useHistory();
    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>
                    <img src={exitHeadImage} alt='蝴蝶结' />
                    <h1>距离心仪的礼物又近了一步<br />确定要离开吗？</h1>
                </div>
                <div className={styles.modalContent}>
                    <img src={exitGiftImage} alt='碎片' />
                </div>
                <div className={styles.modalFooter}>
                    <button className={styles.disable} onClick={() => {
                        JsBridge.close();
                    }}>忍痛离去</button>
                    <button onClick={() => {
                        history.goBack();
                    }}>立即去签到</button>
                </div>
            </div>
        </div>
    </div>
}