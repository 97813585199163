/**
 * @description 2023元旦嘉年华活动
*/
import React, { useEffect, useState, } from 'react';
import { useHistory, useParams, } from 'react-router-dom';
import styles from './HomeStyles.module.scss';
import Library from '../../../../library';
import Services from '../../../../services';
import wishBg from '../images/wishBg.png';
import topBg from '../images/topBg.png';
import inviteBtnBg from '../images/inviteBtnBg.gif';
import logo from '../images/logo.png';
import { Draw } from '../components/Draw';
import { Divider, Popup, Swiper, } from 'antd-mobile';
import Rule from '../components/Rule';
import enterMeta from '../images/enterMeta.gif'
import ShareMask from '../components/ShareModal';
import { isEmptyString } from 'library/util';



const { JsBridge } = Library;

export const Home = () => {
  const history = useHistory();
  const { } = useParams();

  // data
  const [inviteRecordData, setInviteRecordData] = useState();
  const [wishData, setWishData] = useState();
  const [winnerListData, setWinnerListData] = useState();
  const [drawLotteryListData, setDrawLotteryListData] = useState()
  const [drawTimesData, setDrawTimesData] = useState()
  const [ruleData, setRuleData] = useState()

  const [userInfo, setUserInfo] = useState({}); //用户信息  isLogin 用户已经登录 否则没有登录 ID不为空已经实名
  const [ruleVisible, setRuleVisible] = useState(false)
  const [sharemaskVisible, setSharemaskVisible] = useState(false);
  const [inviteRecordVisible, setInviteRecordVisible] = useState(false);
  const [selectedWish, setSelectedWish] = useState('');

  useEffect(async () => {
    initData(true);
  }, []);
  const initData = async (isShowMask) => {
    let userInfoRes = await JsBridge.getDetailCurrentUserInfo()
    if (!userInfoRes.isLogin) {
      JsBridge.goLogin()
      JsBridge.addListener().then(() => { initData() })
      return
    }
    setUserInfo({...userInfoRes})
    await Promise.all([
      initWishData(),
      initWinnerListData(),
      initDrawLotteryListData(),
      initDrawTimesData(),
    ])
    !!isShowMask && setTimeout(() => { setSharemaskVisible(true) })
  }
  const initWishData = async () => {
    // 初始化愿望
    const res = await Services('MainPage', { code: '300020' }, {
      activityCode: "NY2023",
      // userCode: "13817952661",
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      setWishData(res?.data)
    }
  }
  const initWinnerListData = async () => {
    // 初始化中奖名单
    const res = await Services('MainPage', { code: '300025' }, {
      activityCode: "NY2023",
      // userCode: "13817952661",
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      setWinnerListData(res?.data)
    }
  }
  const initDrawLotteryListData = async () => {
    // 初始化抽奖奖品列表
    const res = await Services('MainPage', { code: '300029' }, {
      activityCode: "NY2023",
    });
    if (!!res && res?.status == 1 && !!res?.data && !!res?.data?.lotteryList && !!res?.data?.lotteryList?.length && res?.data?.lotteryList.length == 8) {
      let tempList = res?.data?.lotteryList?.filter(i => !!i)?.sort((a, b) => a.index - b.index)
      tempList = tempList.map(i => {
        let step = 0
        i.index == 1 && (step = 0)
        i.index == 2 && (step = 1)
        i.index == 3 && (step = 2)
        i.index == 4 && (step = 7)
        i.index == 5 && (step = 3)
        i.index == 6 && (step = 6)
        i.index == 7 && (step = 5)
        i.index == 8 && (step = 4)
        return { ...i, step: step }
      })
      tempList.splice(4, 0, { type: 'start' })
      setDrawLotteryListData(tempList)
    }
  }
  const initDrawTimesData = async () => {
    // 初始化抽奖次数
    const res = await Services('MainPage', { code: '300030' }, {
      activityCode: "NY2023",
      clientId: userInfo.clientId,
      // userCode: "19965361762" 
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      setDrawTimesData(res?.data)
    }
  }
  const inviteRecordBtnClick = async () => {
    //邀新记录
    const res = await Services('MainPage', { code: '300026' }, {
      activityCode: "NY2023",
      // userCode: "13764899253",
      pageNum: "1",
      pageSize: "999"
    });
    if (!!res && res?.status == 1 && !!res?.data) {
      setInviteRecordData(res?.data)
      setInviteRecordVisible(true)
    }
  }
  const publishWish = async () => {
    //发布愿望
    await Services('MainPage', { code: '300023' }, {
      activityCode: "NY2023",
      blessingType: "4",
      content: selectedWish || "平安喜乐",
      deptName: selectedWish || "平安喜乐",
      defaultStatus: "0",
      // userCode: "15601667707"
    });
    initDrawTimesData()
    initWishData()
  }
  const jumpYYZ = () => { //跳转到元宇宙
    if (userInfo.isLogin) {
      JsBridge.startUnity3d();
    }
  }
  const ruleBtnClick = async () => {
    if (!!ruleData) {
      setRuleVisible(true)
      return
    }
    const res = await Services('MainPage', { code: '101193' }, {
      activityCode: 'NY2023',
      codeType: 'activityRule',
      code: 'NYActivityRule',
    })
    if (!!res && res?.status == 1 && !!res?.data && !!res?.data?.activityRule) {
      setRuleData(res?.data?.activityRule)
      setRuleVisible(true)
    }
  }
  return (
    <div className={styles.root}>
      <div className={styles.backBtn} onClick={() => JsBridge.back()}></div>
      <div className={styles.container}>
        <img className={styles.topBg} src={topBg} />
        <div className={styles.tipRight1Btn} onClick={() => {
          ruleBtnClick()
        }}>
          {'活动规则'}
        </div>
        {!!userInfo && !!userInfo.isLogin && isEmptyString(userInfo?.ID) &&
          <div className={styles.tipRight2Btn} onClick={() => {
            JsBridge.navigate('Verify')
            JsBridge.addListener().then(() => { initData() })
          }}>
            {'实名认证'}
          </div>
        }
        <div className={styles.metaBtn} onClick={() => jumpYYZ()}>
          <img className={styles.metaBtnImg} src={enterMeta} />
        </div>
        <div className={styles.flexContainer}>
          {/* 邀请 */}
          <div className={styles.inviteContainer}>
            <div className={styles.inviteBtn} onClick={() => {
              setSharemaskVisible(true)
            }}>
              <img className={styles.inviteBtnBg} src={inviteBtnBg} />
            </div>
            <div className={styles.inviteRecordBtn} onClick={() => {
              inviteRecordBtnClick()
            }}>
              {'邀新记录 >'}
            </div>
          </div>

          {/* 愿望 */}
          {!!wishData && wishData?.blessingFlag == false &&
            <div className={styles.wishContainer}>
              <img className={styles.wishBg} src={wishBg} />
              <div className={styles.wishTitle}>
                {`新年愿望`}
              </div>
              <div className={`${styles.wishInput} ${!!selectedWish ? styles.wishInputSelected : ``}`}>
                {!!selectedWish ? `${selectedWish}` : `许下你的新年愿望吧`}
              </div>
              <div className={styles.wishSelectTitle}>
                {`选择你的心愿`}
              </div>
              <div className={styles.wishSelectContainer}>
                {['平安喜乐', '攒够首付', '换辆新车', '顺遂无忧', '出国旅游', '工作顺利'].map(item => (
                  <div className={styles.wishSelectCell} key={item} onClick={() => {
                    setSelectedWish(`${item}`)
                  }}>
                    {`${item}`}
                  </div>
                ))}
              </div>
              <div className={styles.wishBtn} onClick={() => {
                publishWish()
              }}>
                <div className={styles.wishBtnTitle}>
                  {`点击发布愿望 >`}
                </div>
                <div className={styles.wishBtnSubTitle}>
                  {`许愿得100积分+1次抽奖机会`}
                </div>
              </div>
            </div>
          }

          {/* 我的心愿 */}
          {!!wishData && wishData?.blessingFlag == true &&
            <div className={styles.myWishContainer}>
              <div className={styles.myWishListContent}>
                <div className={styles.myWishListHeaderContainer}>
                  <img className={styles.myWishListHeaderIcon} src={wishData?.blessingPojo?.imagePath}/>
                  <div className={styles.myWishListHeaderTitle}>
                    {`我的心愿`}
                  </div>
                  <div className={styles.myWishListHeaderSubTitle}>
                    {`${wishData?.blessingPojo?.content || ''}`}
                  </div>
                </div>
                <Swiper
                  className={styles.myWishList}
                  direction={'vertical'}
                  allowTouchMove={false}
                  stuckAtBoundary={false}
                  autoplay={true}
                  loop={true}
                  autoplayInterval={2000}
                  slideSize={23}
                  indicator={() => null}
                >
                  {!!wishData?.allList && wishData?.allList?.map((item, index) => (
                    <Swiper.Item className={styles.myWishListCell} key={`${index} - ${JSON.stringify(item)}`}>
                      <div className={styles.myWishListCellTitle}>
                        {`${item?.mobile || '-'}:`}
                      </div>
                      <div className={styles.myWishListCellSubTitle}>
                        {`${item?.content || '-'}`}
                      </div>
                    </Swiper.Item>
                  ))}
                </Swiper>
                
              </div>
              <div className={styles.myWishListMask} />
            </div>
          }

          {/* 抽奖 */}
          <Draw
            drawLotteryListData={drawLotteryListData}
            drawTimesData={drawTimesData}
            drawActionCB={initDrawTimesData}
            userInfo={userInfo}
          />
          <div className={styles.drawBtnsContainer}>
            <div className={styles.drawBtn} onClick={() => {
              JsBridge.push('CustomWeb', { uri: window.location.origin + '/front/activities/carnival/drawRecord' });
            }}>
              {`抽奖记录 >`}
            </div>
            <div className={styles.drawBtn} onClick={() => {
              JsBridge.push('CustomWeb', { uri: window.location.origin + '/front/activities/carnival/drawTimesRecord' });
            }}>
              {`次数获取记录 >`}
            </div>
          </div>

          {/* 中奖名单 */}
          <div className={styles.winnerListContainer}>
            <div className={styles.winnerListContent}>
              <div className={styles.winnerListTitle}>
                {`中奖名单`}
              </div>
              <Swiper
                className={styles.winnerList}
                direction={'vertical'}
                allowTouchMove={false}
                stuckAtBoundary={false}
                autoplay={true}
                loop={true}
                autoplayInterval={2000}
                slideSize={20}
                indicator={() => null}
              >
                {!!winnerListData && winnerListData?.map((item, index) => (
                  <Swiper.Item className={styles.winnerListCell} key={`${index} - ${JSON.stringify(item)}`}>
                    <div className={styles.winnerListCellTitle}>
                      {`${item?.loginName}:`}
                    </div>
                    <div className={styles.winnerListCellSubTitle}>
                      {`${item?.prizeName}`}
                    </div>
                  </Swiper.Item>
                ))}
              </Swiper>
              <div className={styles.winnerListMask} />
            </div>
          </div>

          {/* logo */}
          <img className={styles.logo} src={logo} />
        </div>
      </div>

      {/* 弹窗 */}
      {!!ruleVisible && !!ruleData && 
        <Rule
          title={ruleData?.codeAlias}
          rule={ruleData?.otherSign}
          clickCB={() => setRuleVisible(false)}
        />
      }
      {!!inviteRecordVisible &&
        <InviteRecordPopComponent
          data={inviteRecordData}
          onMaskClick={() => {
            setInviteRecordVisible(false)
          }}
        />
      }

      {
        <ShareMask
          maskVisible={!!sharemaskVisible}
          closeMask={() => setSharemaskVisible(false)}
        />
      }
    </div>
  )
}


// SubComponent 
const InviteRecordPopComponent = ({
  data,
  onMaskClick,
}) => (
  <Popup
    visible={true}
    bodyClassName={styles.inviteRecordContainer}
    onMaskClick={onMaskClick}
  >
    <div className={styles.inviteRecordTitle}>
      {`邀新记录`}
    </div>
    <div className={styles.inviteRecordSubTitle}>
        {`——— 已成功邀请${data?.count}人 ———`}
    </div>
    <div className={styles.inviteRecordListContainer}>
      {data?.count == 0 &&
        <Divider style={{ color: '#960002', borderColor: 'transparent', margin:30}}>
          {`暂无数据`}
        </Divider>
      }
      {!!data?.list && data?.list.map(item => (
        <div className={styles.inviteRecordListItemContainer}>
          <img className={styles.inviteRecordListItemIcon} src={item?.imagePath} />
          <div className={styles.inviteRecordListItemPhone}>
            {`${item?.mobile || '-'}`}
          </div>
          <div className={styles.inviteRecordListItemFlex} />
          <div className={styles.inviteRecordListItemTime}>
            {`${item?.createTime || '-'}`}
          </div>
        </div>
      ))}
    </div>
  </Popup>
)