/**
 * @description 领奖记录页
 * @returns route: /front/activities/newYear/giftRecord
*/

import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'antd-mobile';
import { Header, ListView } from 'components';
import Library from "library";
import Service from '../service';
import './index.scss';

const { JsBridge, Util } = Library;

const GiftRecord = ()=> {

    const currentPage = useRef(1)
    const [dataList, setDataList] = useState([])
    const [addressFilledItem, setAddressFilledItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    useEffect(() => {
        loadData();
    }, []);

    const onDidFocus = () => {
      JsBridge.addListener().then(() => {
        loadData();
      });
    }

    const loadData = async () => {
        currentPage.current = 1;
        setIsLoading(true)
        const res = await Service.getReceiveGiftList({pageNum: currentPage.current});
        setIsLoading(false)
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = [], addressFilledItem } = res.data;
            setAddressFilledItem(addressFilledItem)
            setDataList(list)
            setHasMore(list.length >= 20)
        }
    }
    const loadMore = async () => {
        const res = await Service.getReceiveGiftList({pageNum: `${currentPage.current + 1}`});
        if (!!res && res?.status === 1 && !!res?.data) {
            let { list = []} = res.data
            setDataList([...dataList, ...list])
            setHasMore(list.length >= 20)
            currentPage.current = currentPage.current + 1
        }
    }

    const addOrChangeAddress = (item) => {
        item = {...item, ...addressFilledItem}
        item.hide = 1;
        if(item.address) {
            const param = Util.url.objectToQueryParams(item)
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/activities/lottery/orderDetail?${param}`});
        }else {
            const param = Util.url.objectToQueryParams({
                idf: 'commonSub',
                activityCode: Service.activityCode,
                req: item.id,
                name: item.prizeName,
                imgUrl: item.prizeImgUrl,
                hide: item.hide
            })
            JsBridge.push('CustomWeb', { uri: window.location.origin + `/front/guapp/activityOrder?${param}`});
            onDidFocus();
        }
    }

    const renderItem = (item)=> {

        return (
            <div className='record-itemContainer'>
                <div className={'itemLeft'}>
                    <div className={'itemTop'}>
                        <div className={'itemTopTitle'}>
                            {`${item?.prizeName}`}
                        </div>
                    </div>
                    <div className={'itemTime'}>
                        {`${item?.createTime}`}
                    </div>
                </div >
            </div>
        )
    }

    return (
        <div className='gift-record'>
            <Header midText={'中奖记录'} shadow/>
            <div className='container'>
                <ListView 
                    dataSource={dataList}
                    renderItem={renderItem}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    onRefresh={()=>{
                        loadData(false)
                    }}
                    loadMore={loadMore}
                />
            </div>
            {
                dataList.length > 0 &&
                <div className={'itemAddressBtn'}>
                    <div className={'itemAddressBtnTitle'} 
                        // 因分页问题，可能无法查询到实物
                        onClick={()=>addOrChangeAddress(dataList.find(item => item.prizeType === 3) || dataList[0])}>
                        {addressFilledItem && addressFilledItem.address ? `查看领取奖品详细信息` : `填写地址领取奖品`}
                    </div>
                </div>
            }
            
        </div>
    )
}

export {
    GiftRecord
};