import React, { useEffect, useState,useRef } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
const wechat = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
function wxOpenTagSupport() {
    if (wechat) {
        let judge = wechat[1].split('.')
        if ((judge[0] >= 7) && (judge[1] >= 0) && (judge[2] >= 12)) {
            return true;
        }
    }
    return false;
}
const ModalComponent = (props) => {
    const { title, status, content, buttons, jumpUrl} = props.modal;
    // let listenerLaunch = useRef();
    // let listenerError = useRef();
    // useEffect(()=>{
    //     listenerLaunch.current && listenerLaunch.remove();
    //     listenerError.current && listenerError.remove();
    //     let btn = document.getElementById('launch-btn');
    //     if (btn) {
    //         listenerLaunch.current = btn.addEventListener('launch', function (e) {
    //             Actions.modal.hide();
    //         });
    //         listenerError.current = btn.addEventListener('error', function (e) {
    //             window.location.href = jumpUrl;
    //             Actions.modal.hide();
    //             window._czc.push(["_trackEvent","OpenAPP","Click","button",JSON.stringify(e.detail)]);
    //         });
    //     }
    //
    // },[jumpUrl]);
    if (jumpUrl) {
        return <div className={`${styles.modal} ${status === 'SHOW' ? styles.show : styles.hide}`}>
            <div className={styles.modalWrap2}>
                <div className={styles.title}>{title || '温馨提示'}</div>
                <div className={styles.content}>{content}</div>
                <div className={styles.buttonBG}>
                    <div className={styles.button} onClick={()=>{
                        Actions.modal.hide();
                    }}>
                        <div className={styles.textLeft}>取消</div>
                    </div>
                    <div className={styles.button}>
                        {/*<wx-open-launch-app*/}
                        {/*    id="launch-btn"*/}
                        {/*    appid={process.env.REACT_APP_APP_KEY || 'wxe87950ee4eecd239'}*/}
                        {/*    extinfo={`{appId:${process.env.REACT_APP_APP_KEY || 'wxe87950ee4eecd239'}`}>*/}
                        {/*    <script type="text/wxtag-template">*/}
                        {/*        <div style={{height:44,width: '100%', lineHeight:44,textAlign: 'center', fontSize: 14, color: '#333333'}} >打开app</div>*/}
                        {/*    </script>*/}
                        {/*</wx-open-launch-app>*/}
                        <div className={styles.textRight} onClick={()=>{
                            window.location.href = jumpUrl;
                            Actions.modal.hide();
                        }}>打开app</div>
                    </div>
                </div>
            </div>
        </div>
    } else {
        const displayButtons = () => {
            if (!buttons) {
                return [{
                    name: '确定', click: () => {
                        Actions.modal.hide();
                    },
                    className: styles.full,
                }]
            }
            if (buttons.length <= 1) {
                return buttons.map((item) => {
                    item.className = styles.full;
                    return item;
                });
            } else {
                return buttons.map((item, key) => {
                    if (key % 2 === 0) {
                        item.className = styles.light;
                    } else {
                        item.className = styles.deep;
                    }
                    return item;
                });
            }
        }
        return <div className={`${styles.modal} ${status === 'SHOW' ? styles.show : styles.hide}`}>
            <div className={styles.modalWrap}>
                <div className={styles.modalTitle}>{title || '温馨提示'}</div>
                <div className={styles.modalContent}>{content || ''}</div>
                <div className={styles.modalButton}>
                    {displayButtons().map((item, key) => {
                        return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                    })}
                </div>
            </div>
        </div>
    }

};

export const Modal = connect((state) => {
    return { modal: state.modal.toJS() };
})(ModalComponent);
