import React, {useEffect, useRef, useState} from 'react';
import styles from './inviteRankList.module.scss';
import icon_back from "../../../images/icon/back@2x.png";
import bg_cartoon_paihangbang from "../../../images/cartoon_rank_bg.png";
import Library from "../../../library";
import ListView from "../../../components/ListView1";
import Services from "../../../services";
import {isArray} from "../../../library/util";
import NoticeUtil from "../../../library/util/NoticeUtil";

const originalHeight = 70;
const {JsBridge} = Library;
const activityCode = 'CR2023'
const kGetDataNot = 'kGetDataNot';
export const InviteRankList = () => { //邀新排行榜


    return (
        <div className={styles.cartoon_invite_rank_container}>
            {InviteTopComponent()}
            <div className={styles.cartoon_list_com}>
                <div className={styles.cartoon_quan}>
                    <img src={require('../../../images/cartoon_tuoyuan.png')}/>
                </div>
                <div className={styles.cartoon_header}>
                    <span className={styles.cartoon_header_pm}>排名</span>
                    <span className={styles.cartoon_header_yh}>用户</span>
                    <span className={styles.cartoon_header_yq}>购买数量</span>
                </div>
                {RankListComponent()}
            </div>
            <InviteBottom/>
        </div>
    )
}


const InviteTopComponent = () => {

    const [ranks, setRanks] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        // let params = {'activityCode': activityCode};
        Services('MainPage', {code:  300040}, {}).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                let list = data.list || [];
                setRanks(list);
                NoticeUtil.emit(kGetDataNot, data);
            }
        });
    }

    const rankPMViews = (index) => {
        switch (index) {
            case 0:
                return <img src={require('../../../images/icon_pm_0.png')} className={styles.list_item_img_pm_rank}/>
                break
            case 1:
                return <img src={require('../../../images/icon_pm_1.png')} className={styles.list_item_img_pm_rank}/>
                break
            case 2:
                return <img src={require('../../../images/icon_pm_2.png')} className={styles.list_item_img_pm_rank}/>
        }
    }

    const rankListViews = () => {
        let arr = ranks.slice(0, 3);
        return arr.map((item, index) => {
            return <div className={styles.cartoon_rank_list_1_3_item} key={index.toString()}>
                <img src={item.picUrl}/>
                <div>{item.type}</div>
                <div>{item.name}</div>
                {rankPMViews(index)}
            </div>
        })
    }

    const rankListViews1 = () => {
        let arr = ranks.slice(3);
        return arr.map((item, index) => {
            return <div className={styles.cartoon_rank_list_4_10_item} key={index.toString()}>
                <img src={item.picUrl}/>
                <div>{item.type}</div>
                <div>{item.name}</div>
            </div>
        })
    }


    return (
        <div className={styles.cartoon_rank_list_com}>
            <InviteHeader/>
            <img src={bg_cartoon_paihangbang} className={styles.cartoon_bg_img}/>
            <div className={styles.cartoon_rank_list_1_3_com}>
                {rankListViews()}
            </div>
            <div className={styles.cartoon_rank_list_4_10_com}>
                {rankListViews1()}
            </div>
            <div className={styles.cartoon_des}>{`原邀新排行榜发现不正当邀新行为，因此取消邀新排行榜奖励\n 原定奖励发给购买排行榜用户`}</div>
        </div>
    )
}

const InviteHeader = () => {
    const [height, setHeight] = useState(0);
    useEffect(() => {
        JsBridge.getStatusBarHeight().then(({height}) => {
            setHeight(height);
        });
    }, []);

    return (
        <div className={styles.header_container} style={{height: originalHeight + height}}>
            <div className={styles.left}>
                <img src={icon_back} onClick={() => JsBridge.back()}/>
            </div>
            <span className={styles.middle}>购买排行榜</span>
            <span className={styles.right}/>
        </div>
    )
}

const RankListComponent = () => {
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(1);
    const currentPage = useRef(0);
    const initalRef = useRef(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        currentPage.current = 0;
        pageNum.current = 1;
        initalRef.current = 0;
        loadData();
    }, [])


    const loadData = (isRefresh) => {
        // let params = {'activityCode': activityCode};
        Services('MainPage', {code: 300039}, {}).then((res) => {
            if (res.status > 0) {
                let data = res.data;
                if (isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        let item = data[i];
                        item.index = i + 1;
                    }
                    setData([...data]);
                    setHasMore(false);
                }
                setIsLoad(true);
            }
        });
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData();
    }

    const returnImgOrText = (item) => {
        switch (item.index) {
            case 1:
                return <img src={require('../../../images/icon_pm_0.png')} className={styles.list_item_img_pm}/>
                break
            case 2:
                return <img src={require('../../../images/icon_pm_1.png')} className={styles.list_item_img_pm}/>
                break
            case 3:
                return <img src={require('../../../images/icon_pm_2.png')} className={styles.list_item_img_pm}/>
                break

            default:
                return <div
                    className={styles.list_item_img_pm_txt}>{item.index >= 10 ? item.index : '0' + item.index}</div>
                break

        }

    }


    const listItem = (item) => {
        return (
            <div className={styles.list_item}>
                {returnImgOrText(item)}
                <div className={styles.img_tel_com}>
                    <img src={item.imagePath} className={styles.list_item_img}/>
                    <div>{item.mobile}</div>
                </div>
                <div className={styles.list_item_invited_num}>{item.count}</div>
            </div>
        )
    }


    return (
        <div
            style={{
                height: '100%',
                width: '90%',
                overflowY: 'scroll',
                position: 'relative',
                background: 'white',
                // marginTop: '-6px',
            }}>
            <ListView
                onRefresh={() => loadData()}
                list={data}
                hasMore={hasMore}
                isLoad={isLoad}
                loadMore={loadMore}
                ListItem={({item}) => listItem(item)}
            />
        </div>
    )
}

const InviteBottom = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        NoticeUtil.addListener(kGetDataNot, addListener);
        return () => {
            NoticeUtil.removeListener(kGetDataNot, addListener);
        }
    }, []);

    const addListener = (data) => {
        setData(data);
    }

    return <div className={styles.cartoon_bottom}>
        <img src={data.imagePath}/>
        <span className={styles.cartoon_bottom_tel}>{data.mobile}</span>
        <div className={styles.cartoon_bottom_pm}>
            <span className={styles.cartoon_bottom_pm_count}>{data.rank}</span>
            <span>我的排名</span>
        </div>
        <div className={styles.cartoon_bottom_yq}>
            <span className={styles.cartoon_bottom_pm_count}>{data.count}</span>
            <span>购买个数</span>
        </div>
    </div>
}
