
/**
 * @description 弹幕墙组件
 * @url  From this: https://github.com/zerosoul/rc-bullets
*/

import StyledBullet from './StyledBullet';
import BulletScreen from './BulletScreen';

export { StyledBullet };
export default BulletScreen;
