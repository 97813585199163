import React, {useState} from 'react';
import styles from "./travel.module.scss";
import travelTopImg from "./images/travel-top@2x.png";
import travelLiveImg from './images/travel-live@2x.png';
import travelPicOne from './images/travel-travel@2x.png';
import travelPicTwo from './images/travel-travel1@2x.png'
import travelPicThree from './images/travel-travel2 2@2x.png'
import signImg from './images/travel-visa@2x.png'
import signOneImg from './images/travel-1@2x.png';
import signTwoImg from './images/travel-2@2x.png';
import signThreeImg from './images/travel-3@2x.png';
import signFourImg from './images/travel-4@2x.png';
import signFiveImg from './images/travel-5@2x.png';
import signSixImg from './images/travel-6@2x.png';
import {Slogon} from "./slogon";
import Library from 'library';
import { umengTrack_trip } from 'library/jsbridge/config'
const { Platform, JsBridge } = Library;
export const Travel = ()=>{
    const [index, setSelectedIndex] = useState(0);

    const lifeComponent = ()=>{
        return <div className={styles.life}>
                    <img src={travelLiveImg}/>
                    <p>将为您提供旅行生活的购物服务，满足您的旅行与生活需求。</p>
               </div>
    };

    const tourComponent = ()=>{
        return <div className={styles.travel}>
                    <img src={travelPicOne} className={styles.imgOne}/>
                    <p>未来您可以通过旅游板块预订规划好的跟团行程，也可以通过自己规划行程预订酒店、购买门票、用车等旅行服务。</p>
                    <img src={travelPicTwo} className={styles.imgTwo}/>
                    <p className={styles.title}>跟团行程预订</p>
                    <p>您可以预订出境游、境内游、周边游、邮轮、目的地参团等跟团行程。</p>
                    <img src={travelPicThree} className={styles.imgTwo}/>
                    <p className={styles.titleOne}>自定行程</p>
                    <p>您也可以根据自己规划的行程预订酒店、购买门票、租用车辆等。</p>
                </div>
    };

    const signComponent = ()=>{
        return <div className={styles.sign}>
                    <img src={signImg} className={styles.imgOne}/>
                    <p className={styles.label}>将联合您提供上海始发的种签证服务</p>
                    <div className={styles.images}>
                        <div>
                            <img src={signOneImg} className={styles.img}/>
                            <p className={styles.text}>俄罗斯个签证</p>
                        </div>
                        <div>
                            <img src={signTwoImg} className={styles.img}/>
                            <p className={styles.text}>韩国个签</p>
                        </div>
                        <div>
                            <img src={signThreeImg} className={styles.img}/>
                            <p className={styles.text}>入台证</p>
                        </div>
                        <div>
                            <img src={signFourImg} className={styles.img}/>
                            <p className={styles.text}>加拿大签证</p>
                        </div>
                        <div>
                            <img src={signFiveImg} className={styles.img}/>
                            <p className={styles.text}>缅甸个签</p>
                        </div>
                        <div>
                            <img src={signSixImg} className={styles.img}/>
                            <p className={styles.text}>申根签证</p>
                        </div>
                    </div>
               </div>
    };

    return <div className={styles.container}>
                <img className={styles.topImage} src={travelTopImg}/>
                <div className={styles.tabs}>
                    <p className={`${styles.text} ${index === 0 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(0);
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_trip.topic, '生活');
                    }   
                    }}>生活</p>
                    <p className={`${styles.text} ${styles.gap} ${index === 1 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(1);
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_trip.topic, '旅游');
                    }   
                    }}>旅游</p>
                    <p className={`${styles.text} ${index === 2 ? styles.selected : styles.unSelected}`} onClick={()=>{setSelectedIndex(2);
                    if (Platform.check.isApp()) {
                        JsBridge.UmengTrack(umengTrack_trip.topic, '签证');
                    }   
                    }}>签证</p>
                </div>
                <div className={styles.content}>
                    {index === 0 ? lifeComponent() : null}
                    {index === 1 ? tourComponent() : null}
                    {index === 2 ? signComponent() : null}
                </div>
                <Slogon  />
           </div>
};
