
/**
 * @description 签到提醒开关组件
*/
import React, {useEffect, useState} from 'react';
import Library from 'library';
import Services from 'services';
import styles from './index.module.scss';
import { NoticeSetting }  from '../../noticeSetting';
import signTipGold from '../../imgs/sign-tip-gold-btn.png';
import signTipDis from '../../imgs/sign-tip-btn.png';

const {JsBridge} = Library;

export const NotifySwitch = (props) => {
    const [isOpen, setIsOpen] = useState(props.isNotice);
    const [isEnable, setIsEnable] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        getIsSupportNotification();
    }, []);

    const getIsSupportNotification = async () => {
        const isSupport = await JsBridge.nativeModules('SysModule', 'isSupportNotification');
        setIsEnable(isSupport);
    }

    const onSwitchClick = async () => {
        if (isOpen) {
            const result = await Services('MainPage', {code: '101213'}, {notice: 0})
            if (result.status > 0) {
                setIsOpen(false);
                JsBridge.runAction('alert', 'error', ['已关闭签到提醒'])
            }

        } else {
            setVisible(true);
        }
    }

    const callback = (isEnable) => {
        setIsOpen(isEnable);
        if (isEnable) {
            JsBridge.runAction('alert', 'error', ['已开启签到提醒'])
        }
    }

    if (isEnable) {
        return <div onClick={onSwitchClick} className={styles.notifySwitch}>
            <div className={styles.switch}>
                <span>签到提醒</span>
                <img src={isOpen ? signTipGold : signTipDis} mode='widthFix' alt=''/>
            </div>
            <NoticeSetting visible={visible} callback={callback} 
                onClose={()=>setVisible(false)}
            />
        </div>
    } else {
        return false;
    }
}