import React from "react"
import {InfiniteScroll, PullToRefresh, DotLoading, List} from "antd-mobile"
import "./index.scss"
import EmptyView from "../emptyView";

const InfiniteScrollContent = ({hasMore}) => {
    return (
        <>
            {hasMore ? (
                <>
                    <span>Loading</span>
                    <DotLoading/>
                </>
            ) : null}
        </>
    )
}

const ListView = ({data = [], ListItem, onRefresh, loadMore, hasMore, isLoad, isNeedRefresh = true}) => {
    return (
        <>
            {
                isNeedRefresh ? <PullToRefresh 
                  onRefresh={onRefresh}
                  headHeight={70}
                  threshold={100}
                  pullingText={
                    <img 
                      src={require('../../images/queshengye/new-loading.gif')}
                      style={{height: 50}} alt=''
                    />
                  }
                  refreshingText={
                    <img
                      src={require('../../images/queshengye/new-loading.gif')}
                      style={{height: 50}} alt=''
                    />
                  }
                  canReleaseText={
                    <img
                      src={require('../../images/queshengye/new-loading.gif')}
                      style={{height: 50}} alt=''
                      />
                    }
                  renderText={null}
                >
                    <List style={{flex: 1}}>
                        {data?.length ? (
                            data.map((item, index) => <ListItem key={index} item={item}/>)
                        ) : (
                            isLoad ?
                                <EmptyView height={'80vh'}/> : null
                        )
                        }
                    </List>
                    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                        <InfiniteScrollContent hasMore={hasMore}/>
                    </InfiniteScroll>
                </PullToRefresh> : <>{data.length ? (
                    data.map((item, index) => <ListItem key={index} item={item}/>)
                ) : (
                    isLoad ? <EmptyView height={'80vh'}/> : null
                )
                }
                </>
            }
        </>
    )
}

export default ListView
