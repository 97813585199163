import React, {useEffect, useRef, useState} from 'react'
import styles from './styles.module.scss'
import CountDown from "../countDown";
import {clickThrottle} from "../../../../../library/util";
import Services from "../../../../../services";
import {CARBONPOINT} from "../../../../../library/util/constant";

const EnergyBall = ({item, refreshData}) => {

    const [isExist, setIsExist] = useState(item.isExist)
    const [isClickItem, setIsClickItem] = useState(false)


    const itemClick = async () => {
        if (!clickThrottle()) {
            return
        }
        let res = await Services('MainPage', {code: 600853}, {
            activityCode: CARBONPOINT,
            taskId: item.id,
            taskType: item.taskType
        });
        console.log('600853---', res);
        if (res.status > 0) {
            setIsClickItem(true);
            let timer = setTimeout(() => {
                clearTimeout(timer);
                setIsExist(false);
            }, 500)
            let timer1 = setTimeout(() => {
                clearTimeout(timer1);
                refreshData && refreshData()
            }, 1000)
        }
    }

    const getCountDown = () => {
        if (!item?.systemTime || !item?.effectiveTime) {
            return null;
        }
        // console.log('item?.systemTime--',item?.systemTime);
        // console.log('item?.effectiveTime--',item?.effectiveTime)

        const date1 = (new Date(item?.systemTime.replace(/-/g, '/'))).getTime();
        const date2 = (new Date(item?.effectiveTime.replace(/-/g, '/'))).getTime();
        // console.log('date1',date1)
        // console.log('date2',date2)
        if ((date2 - date1) <= 0) {
            return null;
        }
        let total = (date2 - date1) / 1000;
        let endTime = parseInt((total / (60 * 60)) + '');

        if (endTime > 24) {
            return null
        } else {
            return <CountDown sysTime={item?.systemTime} endTime={item?.effectiveTime}
                              isOpacity={item?.completeStatus === 3}
                              refreshData={() => {
                                  refreshData && refreshData()
                              }}/>
        }
    }

    if (!isExist) {
        return <div className={styles.energy_ball_not_exist}/>
    }

    return <div
        className={isClickItem ? `${styles.energy_ball} ${styles.hidden}` : `${styles.energy_ball} ${styles.isExit}`}>
        {item?.completeStatus === 3 ? <img src={require('../../imgs/icon_50.png')} alt=""/> :
            <img src={require('../../imgs/icon_nlq.png')} alt=""/>}
        <div className={styles.content} onClick={() => {
            if (item?.completeStatus === 2) {
                itemClick()
            }
        }}>
            <p className={`${styles.title} ${item?.completeStatus === 3 ? styles.disable : ''} ${getCountDown() ? null : styles.hasTime}`}>{item?.carbonPoints}g</p>
            <p className={`${styles.des} ${item?.completeStatus === 3 ? styles.disable : ''}`}>{item?.taskName}</p>
            {getCountDown()}
        </div>
    </div>
}

export default EnergyBall
