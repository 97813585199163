import React from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import styles from './rule.module.scss';

export default (props) => {
    const history = useHistory();
    const location = useLocation();
    const { rule } = location.state || {};
    return <div className={styles.container}>
        <div className={styles.mask}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>活动规则</div>
                <div className={styles.modalContent}>
                    {rule}
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={() => {
                        history.goBack();
                    }}>我知道了</button>
                </div>
            </div>
        </div>
    </div>
}
