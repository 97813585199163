import env1Img from './images/env1@2x.png';
import env2Img from './images/env2@2x.png';
import env3Img from './images/env3@2x.png';

export const envMap = [{
    name: '办公环境',
    desc: '清新空气和优美的景观，在工作之余，你可以享受自然、放松心情，休息一下吧。',
    image: env1Img
}, {
    name: '职场环境',
    desc: '在联奇云，你可以有宽敞舒适的工位，人体工程学椅，轻松舒适办公。',
    image: env2Img
}, {
    name: '团队氛围',
    desc: '在联奇云，就像一个轻松和谐的大家庭，重视伙伴关系，让工作更纯粹，共同努力。',
    image: env3Img
}];

export const jobMap = [{
    name: '软件开发方向',
    location: '杭州',
    desc: `职位描述:
1. 参与开发应用系统软件功能模块程序编码，确保业务需求的实现。
2. 根据项目需求分析及设计文档，完成应用系统开发阶段任务。
3. 根据应用架构、数据架构、技术架构，做好领域技术标准的实施。
4. 负责应用系统的优化和完善，更好地满足用户需求。`,
    order: `任职要求：
1. 计算机及相关专业本科以上学历。
2. 熟练掌握至少1门及以上主流编程语言，如：C++、JAVA、Python等。
3. 了解应用软件的开发与发布流程，对终端开发环境较熟悉。
4. 积极，主动，敬业，对开发有浓厚兴趣、有良好的自学能力和沟通能力。`
}, {
    name: '云平台管理方向',
    location: '杭州',
    desc: `职位描述:
1. 负责PaaS平台系统部署、管理及优化。
2. 负责PaaS平台日常运行状态的监控与管理以及安全事件的分析诊断及应急处置。
3. 负责云本地容器仓库的搭建和维护管理。
4. 负责容器相关开源技术的持续跟踪引进，丰富 PaaS平台能力。`,
    order: `任职要求：
1. 计算机及相关专业本科以上学历。
2. 了解Linux操作系统和容器化部署（docker,  K8S架构），熟悉操作系统相关性能参数收集和调整。
3. 了解jenkins、mysql、redis、kafka、spark等组件之一。
4. 了解 DevOps 流程，了解使用开源 CI/CD 技术工具。`
}, {
    name: '项目管理方向',
    location: '杭州',
    desc: `职位描述:
1. 开展需求调研，完成业务需求收集并形成规范的文档。
2. 进行客户的业务需求分析，完成相应咨询方案的制定。
3. 负责项目监督，及时了解项目进度，定期形成进度汇报材料向客户汇报进度情况。
4. 项目资料整理，对项目过程中形成的各类文档进行收集、整理、归档。`,
    order: `任职要求：
1. 计算机及相关专业本科以上学历。
2. 对项目管理方法和管理工具有一定了解，熟练使用办公软件。
3. 具备较强的分析、决策和解决问题的能力。
4. 积极，主动，敬业，有良好的自学能力和沟通能力。`
}]