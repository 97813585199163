import React, {useState, useEffect, useRef, useCallback} from 'react';
import Services from 'services';
import Library from 'library';
import styles from './inviteRecord.module.scss'
import {isEmptyString} from "../../../library/util";

const InviteStatus = {
    SUCCESS: 'SUCCESS', //邀请成功
    FAIL: 'FAIL', //邀请失败
    UNDERWAY: 'UNDERWAY', //邀请进行中
}

const {JsBridge, Util, Hooks} = Library;
export const InviteRecord = (props) => {
    const [state, setState] = useState({arrSource: [], pageNum: 1, isLoading: false, isLoadingAll: false});
    const {
        activityCode = "",
    } = Library.Util.url.paramsToObj();

    useEffect(() => {
        // console.log('activityCode---',activityCode)
        document.body.style.overflow = 'hidden';
        getData(true);
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    const timer = useRef();
    let isDebounce = false;
    const onScroll = (event) => {
        let {clientHeight, scrollHeight, scrollTop} = pageView.current;
        if (scrollTop + clientHeight >= scrollHeight && !state.isLoading && !state.isLoadingAll && !isDebounce) {
            isDebounce = true;
            getData();
            timer.current = setTimeout(() => {
                isDebounce = false;
                clearTimeout(timer.current);
            }, 500);
        }
    }

    const pageView = useRef();
    const handler = useCallback(onScroll);
    Hooks.useEventListener('scroll', handler, pageView.current);

    const getData = async (refresh) => {
        setState({...state, isLoading: true});
        let currentPageNum = refresh ? 1 : state.pageNum + 1;
        let params = {pageNum: currentPageNum, pageSize: 10};
        if (!isEmptyString(activityCode)) {
            params.activityCode = activityCode;
        }
        //调接口获取
        const result = await Services('MainPage', {code: '101222'}, params)
        if (result && result.status > 0) {
            let {jktActivityRewardLogList: arr = [], count = 1} = result.data;
            let arrSource = state.arrSource;
            if (currentPageNum === 1) {
                arrSource = arr;
            } else {
                arrSource = [...arrSource, ...arr];
            }
            setState({
                ...state,
                arrSource: arrSource,
                pageNum: currentPageNum,
                isLoading: false,
                isLoadingAll: arrSource.length >= count
            });
        } else {
            setState({...state, isLoading: false});
        }
    }

    const inviteStatusView = (item) => {
        let {inviteStatus, type, failReason, prizeSize = 0} = item;
        if (inviteStatus === InviteStatus.SUCCESS) {
            let unit = type == 1 ? '积分' : '个碎片';
            let number = prizeSize > 0 ? prizeSize : '--';
            return <>
                <div className={styles.number}>{number}</div>
                <div className={styles.unit}>{unit}</div>
            </>
        }else if (inviteStatus === InviteStatus.FAIL){
            return  <>
                <div className={styles.yaoxin_state}>邀新失败</div>
                <div className={styles.yaoxin_state_fail}>{failReason}</div>
            </>
        }else if (inviteStatus === InviteStatus.UNDERWAY){
            return  <>
                <div className={styles.yaoxin_state}>邀新进行中</div>
                <div className={styles.yaoxin_state_fail}>{failReason}</div>
            </>
        }
    }


    return <div className={styles.container}>
        <ul className={styles.recordList} ref={pageView}>
            {state.arrSource.map((item, index) => {
                let {mobile, activityCode, createTime, type, failReason, prizeSize = 0} = item;
                let unit = type == 1 ? '积分' : '个碎片';
                let number = prizeSize > 0 ? prizeSize : '--';
                return <li className={styles.listItem}>
                    <div className={styles.left}>
                        <div className={styles.tel}>{mobile}</div>
                        <div className={styles.label}>{activityCode}</div>
                        <div className={styles.label}>{createTime}</div>
                    </div>
                    <div className={styles.right}>
                        {inviteStatusView(item)}
                    </div>
                </li>
            })}
            <div className={styles.show}>——— 仅显示21年09月29日之后邀新记录 ———</div>
        </ul>

    </div>
}
