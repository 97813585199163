import React, { useState, useEffect } from 'react'
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import { useHistory } from 'react-router-dom';
import styles from './inviter.module.scss'
import inviteBg from './images/inviteBg.png';
import inviteBg2 from './images/inviteBg2.png';
const { JsBridge, Util } = Library;
let SwiperNc = null;
export const Inviter = (props) => {
    const [mobile, setMobile] = useState('');
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const history = useHistory();
    const { inviteCode='', activityCode='',teamInviteCode='',teamStatus } = Util.url.paramsToObj();//teamStatus:0:加入战队，3:邀请助力
    useEffect(() => {
        initNC();
    }, [])

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };


    const bindClient = async () => {
        if (!Util.validate.mobile(mobile)) {
            Actions.alert.show('请输入正确的手机号');
            return;
        }
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res && mobile && mobile.replace(/\s/g, '').length >= 11) {
                let result = await Services('BindClient', 'BindDisney', { inviteCode, activityCode,teamInviteCode,teamStatus, mobile, ...res });
                if (result.status > 0) {
                     history.replace(`/front/activities/disney/success?teamStatus=${teamStatus}`)
                }
            }
        }
    }

    return <div className={`${styles.container} ${teamStatus != 3?styles.background1:styles.background2}`}>
        <div className={styles.content}>
            <img src={teamStatus != 3?inviteBg:inviteBg2} className={styles.img}/>
            <label className={styles.tel}>
                <input type="tel" maxLength={11} value={mobile} className={styles.telInput} placeholder={teamStatus != 3?'输入手机号，加入战队':'输入手机号，为好友助力'}
                       onChange={(event) => setMobile(event.currentTarget.value)} />
            </label>
        </div>
        <div className={styles.safe}>
            <div className={styles.btnWrapper}>
                <div className={styles.inviteButton} onClick={() => {
                    bindClient();
                }}>{teamStatus != 3?'立即加入战队':'立即助力'}</div>
            </div>
        </div>
    </div>


}
