
import React from "react";
import Library from 'library';
import styles from './index.module.scss';
import { umengTrack_healthcare } from 'library/jsbridge/config';
import icon3 from "images/kangyang/general-btn-phone@2x.png";
const { Fetch, Platform, JsBridge } = Library;

export const BottomButton = (props) => {
    return <div className={`${styles.bottomButton} ${window.pageConfig && window.pageConfig.isFullscreen ? styles.active : ''}`}>
        <div className={styles.content}>
            <div className={styles.tel} onClick={() => {
                if (Platform.name() === 'GuApp') {
                    JsBridge.tel('021-53011225,816');
                } else {
                    window.location.href = 'tel:021-53011225,816';
                }
                if (Platform.check.isApp()) {
                    JsBridge.UmengTrack(umengTrack_healthcare.phone);
                }
            }}>
                <img className={styles.telIcon} src={icon3} />
                <a className={styles.telText}>电话</a>
            </div>
            {/* 改了括号 */}
            <button className={styles.button} onClick={() => {
                props.onClick();
                if (Platform.check.isApp()) {
                    JsBridge.UmengTrack(umengTrack_healthcare.submit);
                }
            }}>提交意向</button>
        </div>
    </div>
}