import React, {useEffect, useRef, useState} from 'react';
import styles from './mangheAward.module.scss';
import Library from "../../../../library";
import Services from "../../../../services";
import {isEmptyString} from "../../../../library/util";
import {Toast} from "antd-mobile";
import ListView1 from "../../../../components/ListView1";


const {JsBridge} = Library;
const activityCode = 'CR2023';
export const MangheAwardList = (props) => { //盲盒奖励
    const [hasMore, setHasMore] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const pageSize = 10;
    const pageNum = useRef(1);
    const initalRef = useRef(props.initalIndex);
    const [data, setData] = useState([]);
    const [mhCount, setMhCount] = useState(0); //盲盒数量
    const [initStatus, setInitStatus] = useState(false);
    const userInfo = useRef({});
    const {focusStatus} = props;

    useEffect(() => {
        if (!initStatus && focusStatus) {
            setInitStatus(true);
            loadData(true).then();
            if (props.initalIndex === 1) {
                getUserInfo();
            }
            return;
        }
    }, [initStatus, focusStatus])

    const loadData = async (isRefresh) => {
        if (isRefresh) {
            pageNum.current = 1;
        }
        let params = {
            'activityCode': activityCode,
            'pageSize': pageSize,
            'pageNum': pageNum.current,
        };
        const res = await Services('MainPage', {code: 300032}, params);
        if (res.status > 0) {
            const {list = [], count = 0, collectionCount = 0} = res.data;
            let newArr = [];
            if (pageNum.current === 1) {
                let i = 0;
                for (const item of list) {
                    item.index = i;
                    i++;
                }
                newArr = [...list];
            } else {
                newArr = [...data, ...list];
            }
            setData(newArr);
            setMhCount(collectionCount);
            setIsLoad(true);
            setHasMore(count > newArr.length);
        }
    }

    const getUserInfo = () => {
        JsBridge.getDetailCurrentUserInfo().then((res) => {
            userInfo.current = res;
        })
    }

    const loadMore = async () => {
        pageNum.current++;
        await loadData(false);
    }

    const kaiMH = () => {
        if (mhCount > 0) {
            let params = {
                'activityCode': activityCode,
                'num': mhCount > 6 ? 6 : mhCount,
                'clientId': userInfo.current.clientId || '',
            };
            Services('MainPage', {code: 300050}, params).then((res) => {
                if (res.status > 0) {
                    let data = res.data;
                    const {code = 0, req = ''} = data;
                    if (!isEmptyString(req)) {
                        let uri = `${process.env.REACT_APP_NFT_LINK}/salePaySuccess?code=${code}&req=${req}`;
                        JsBridge.push('Web', {uri: uri});
                    } else {
                        Toast.show('获取req为空!')
                    }
                }
            });

            JsBridge.addListener().then(() => { //开盲盒回来回来
                loadData(true).then();
            });
        }
    }

    const listItem = (item) => {
        let resultAmount = item.resultAmount || 0;
        if (resultAmount > 0) {
            resultAmount = '+' + resultAmount;
        }
        return (
            <div className={styles.list_item} style={{marginTop: item.index === 0 ? '0px' : '10px'}}>
                <div className={styles.list_item_left}>
                    <div className={styles.list_item_name}>{item.resultDesc}</div>
                    <div className={styles.list_item_time}>{item.createTime}</div>
                </div>
                <div className={styles.list_item_right}>
                    <div className={styles.list_item_state_title}
                         style={{marginBottom: '15px'}}>{resultAmount}</div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.invite_friend_container}>
            <div
                style={{height: '90vh', overflowY: 'scroll', position: 'relative'}}>
                <ListView1
                    onRefresh={() => loadData(true)}
                    data={data}
                    hasMore={hasMore}
                    isLoad={isLoad}
                    loadMore={loadMore}
                    ListItem={({item}) => listItem(item)}
                />
            </div>
            <div className={styles.cartoon_kai_mh} onClick={() => kaiMH()}>
                <div className={styles.cartoon_kai_mh_btn}>{`开盲盒（共${mhCount}个）`}</div>
                <div className={styles.cartoon_kai_mh_des}>每次最多开6个</div>
            </div>
        </div>
    )
}
