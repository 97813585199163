import React, { useEffect, useState } from 'react';
import Library from 'library';
import styles from './index.module.scss';
import gotoBrowserImg from 'images/goto-browser.png';
import operationImg from 'images/download/pilotOperation.png';
import downloadImg from 'images/download/download.png';
import buttonOpenImg from 'images/download/buttonopen.png';
import iPhoneSafaritipsImg from 'images/download/iPhoneSafaritips.png';
import androidBrowserImg from 'images/download/Androidbrowsertips.png';


const appSchema = 'gktapp://com.gkewang';
const downloadUrl = 'https://app.gkewang.com/index';
export default () => {
    const { fromUrl, appScreen, ...otherParams } = Library.Util.url.paramsToObj();
    const [goToBrowserMask, setGoToBrowserMask] = useState(false);

    const openApp = () => {
        if (Library.Platform.check.isWechat()) {
            setGoToBrowserMask(true);
        } else {
            let luanchUrl;
            if (fromUrl) {
                luanchUrl = `${appSchema}/screen/Web?uri=${encodeURIComponent(decodeURIComponent(fromUrl))}${Library.Util.url.objectToQueryParams(otherParams)}`;
            } else if (appScreen) {
                luanchUrl = `${appSchema}/screen/${appScreen}?${Library.Util.url.objectToQueryParams(otherParams)}`;
            } else {
                luanchUrl = `${appSchema}`;
            }
            window.location.href = luanchUrl;
            setTimeout(() => {
                window.location.href = downloadUrl;
            }, 3000);
        }
    }

    if (Library.Platform.check.isWechat()) {
        return <div className={styles.container}>
            <img src={operationImg} />
            <div className={styles.guide}>
                <p>已安装APP请点击右上角</p>
                <p>{Library.Platform.check.isAndroid() ? '选择在浏览器中打开' : '选择在Safari中打开'}</p>

                <p className={styles.uninstall}>未安装请下载APP <button onClick={() => {
                    window.location.href = downloadUrl;
                }}>下载APP</button></p>


                <div className={styles.guidImg}>
                    {Library.Platform.check.isAndroid() ? <img src={androidBrowserImg} alt='pic' /> : <img src={iPhoneSafaritipsImg} alt='pic' />}
                </div>
            </div>

        </div>
    }
    return <div className={styles.container}>
        <img src={downloadImg} />
        <button className={styles.openApp} onClick={openApp}>
            <img src={buttonOpenImg} />
        </button>

        {goToBrowserMask ? <div className={styles.mask} onClick={() => {
            setGoToBrowserMask(false);
        }}>
            <img src={gotoBrowserImg} />
        </div> : false}

    </div>
}