import React, {useEffect, useState} from 'react';
import styles from './signSuccessModal.module.scss';
import cancelImg from '../imgs/icon-cancel.png';

const SignSuccessModal = ({modalVisible, signData, onClose}) => {
  const [signFragmeng, setSignFragmeng] = useState({});
  const { signInArray, signInDay } = signData || {};
  
  useEffect(() => {
    if(signData && signData.signInArray?.length > 0) {
      signInArray.forEach((item) => {
        if(item.signOrder === signInDay) {
          setSignFragmeng(item);
        }
      })
    }
  }, [signData]);
  if(!modalVisible) return null;
  return <div className={styles.signSuccessModal}>
    <div className={styles.signModalContent}>
      <span className={styles.signModalTitle}>签到成功!</span>
      <div className={styles.signGetPoints}>
        <span>{signData?.points}<i>积分</i></span>
        {signFragmeng.signPrize > 0 && signFragmeng.prizeType > 1 ? <span className={styles.addFlag}>+</span>: null}
        {signFragmeng.signPrize > 0 && signFragmeng.prizeType > 1 ? <span>{signFragmeng?.signPrize}<i>碎片</i></span>: null}
      </div>
      <span className={styles.continuitySign}>连续签到可再得碎片</span>
      <div className={styles.signConfirmBtn} onClick={onClose}>确认</div>
      <img src={cancelImg} alt="" onClick={onClose} />
    </div>
  </div>
}

export default SignSuccessModal;