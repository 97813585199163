import React from 'react';
import styles from './index.module.scss';
export const Protocol = (props) => {
    return <div className={styles.container} {...props}>
        <h2>G优会员服务条款</h2>
        <p>G优尊享会员是由绿地控股集团（以下简称绿地集团）发起提供。请您在注册成为G优会员前，务必仔细阅读本须知，一旦您注册成为G优会员即表示您已经阅读、同意并接受本须知中所含的所有条款和条件。我们保留随时修改本须知的权利并在当地法律允许的范围内对于会员卡项目的申请与审核保有解释权。所有协议内容在发布之日后自动生效。当会员违背会员项目的条约和条件时，绿地集团有权中止或取消该会员所享有的会员卡优惠，并撤销该会员的会员卡。</p>
        <p>一、个人信息安全免责条款：</p>
        <p>1、我们会按照国家相关法律法规的规定保护您的个人信息安全可靠，不被非法收集、使用和分享。</p>
        <p>2、考虑到信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。虽然我们持续致力于提升和加强安全措施，以保护您的数据及个人信息免遭意外或破坏，但仍无法始终保证您的数据及个人信息的百分之百安全，烦请您务必做好数据的线下备份措施。</p>
        <p>二、绿地酒店旅游集团会员权益免责条款：</p>
        <p>（一）绿地酒店会员权益免责条款：</p>
        <p>1、会员福利及优惠仅限所有自营酒店使用；所有票券使用规则均以券面标注为准。</p>
        <p>2、使用权益时请向酒店员工出示您的绿地集团业主卡（G优会员卡），无法提供以上业主卡的用户将无法享用所有会员权益及福利。</p>
        <p>3、绿地酒店旅游集团享有对会员制度、权益及福利制度随时调整的权利，详情请咨询客服中心或关注微信公众号咨询。</p>
        <p>4、受疫情影响，会员权益条款与服务提供方式有部分调整，请提前致电酒店咨询或关注微信公众号“绿地酒店旅游”留言咨询；</p>
        <p>5、所有会员权益仅限会员本人使用，不可与其他福利、折扣、优惠、积分等同时使用，通过第三方渠道（携程、艺龙、去哪儿等）或由第三方代理（旅行社等）的消费不享有会员福利和权益。</p>
        <p>6、会员权益与福利仅限个人使用，不可用于团队消费，公司餐饮、宴会、第三方代理、旅行社代理的消费。</p>
        <p>7、详情请致电绿地酒店旅游集团客服中心咨询：400-920-8855.</p>
        <p>（二）上航旅游会员权益免责条款：</p>

        <p>1、因近期受冠状病毒疫情影响，全国旅行社暂停经营团队旅游及“机票+酒店”旅游产品，并且部分领馆对发放的签证政策有所变化，故预定产品时以实际情况为准。</p>
        <p>2、以上优惠仅限预订上航旅游官网产品使用。</p>
        <p>3、使用权益时请向上航旅游客服出示您的绿地集团业主卡（G优会员卡），无法提供以上业主卡的用户将无法享用所有会员权益及福利。</p>
        <p>4、所有会员权益仅限会员本人携带其成员共同出行使用，不可与其他福利、折扣、优惠、积分等同时使用。</p>
        <p>5、非上航旅游官网预订上航旅游产品将不享有会员福利及权益。</p>
        <p>6、上航旅游享有对会员制度、权益及福利制度随时调整的权利，详情请咨询客服中心或关注微信公众号。上航旅游官方网站：www.satrip.com、上航旅游客服热线：021-962008</p>

        <p>三、绿地金融会员权益免责条款：</p>
        <p>1、	会员福利中所涉及到的优先认购权产品仅限绿地金融及其旗下子公司发行的类固收产品或私募股权类产品。</p>
        <p>2、	使用权益时请向绿地金融吉客体验中心门店人员出示您的绿地集团业主卡（G优会员卡），无法提供以上业主卡的用户将无法享用所有会员权益及福利。</p>
        <p>3、	绿地金融享有对会员制度、权益及福利制度随时调整的权利，详情请咨询绿地金融服务热线或关注微信公众号咨询。</p>
        <p>4、	受疫情影响，绿地金融会员服务提供方式有部分调整，请提前致电绿地金融服务热线咨询或关注微信公众号“绿地金融吉客体验中心”留言咨询。</p>
        <p>5、	所有会员权益仅限会员本人使用，不可与其他福利、折扣、优惠、积分等同时使用。</p>
        <p>6、	详情请致电绿地金融服务热线咨询：021-63356876</p>
        <p></p>
        <p>四、绿地汽车集团免责条款：</p>
        <p>1、车主需要到对应的品牌4S店享受绿地G优的权益，不对应品牌的4S店无法提供相应的服务。</p>
        <p>2、一车对应一张绿地G优，如绿地房产业主名下有几辆车的，需提供房屋产证、户口本、车辆产权证等有效证明，能证明该车辆是户主所有，方能享受绿地G优相应的服务。</p>
        <p>3、卡主拥有的车辆，在车辆发生交易过户后，该车不再享受绿地G优所有权益及服务。</p>
        <p></p>
        <p>五、绿地贸易港集团会员权益免责条款：</p>
        <p>1、会员福利及优惠仅限绿地贸易港集团旗下购物中心、G-super（仅限线下门店）、绿地全球贸易港G-Hub虹桥项目（包括保税展示展销场所和绿地钻石直销中心）使用。</p>
        <p>2、使用权益时请出示您的绿地集团业主卡（G优会员卡），无法提供以上业主卡的用户将无法享用所有会员权益及福利。</p>
        <p>3、绿地贸易港集团享有对会员制度、权益及福利制度随时调整的权利，详情请咨询贸易港集团联系电话或关注微信公众号咨询。</p>
        <p>4、受疫情影响，会员权益条款与服务提供方式会有部分调整，请提前致电咨询或关注微信公众号“绿地贸易港”留言咨询。</p>
        <p>5、会员权益与福利仅限会员本人使用，不可用于团队消费，不可与其他福利、折扣、优惠、积分等同时使用，不得通过第三方渠道或由第三方代理。</p>
        <p>6、详情请致电绿地贸易港集团咨询：021-33386200。</p>
        <p></p>
        <p>六、绿地健康产业集团会员权益免责条款：</p>
        <p>1.	本公司对《绿地健康产业集团提供集团业主客户金卡权益产品明细》(“本明细”)所列明之服务内容、提供服务形式等涉及享有金卡权益的自然人或企业(以下合称“金卡客户”)享有的权益内容及其对应优惠价格保留最终解释权。金卡客户享有的具体权益及相关费用金额以金卡客户签署的具体协议(“相关协议”)约定为准。</p>
        <p>2.	本公司或本公司雇佣的任何人员所出具或签署的与相关协议有关的任何文件，均应在本公司予以书面明确授权的情况下方对本公司具有约束力。本公司所作的广告、宣传资料、口头介绍、解释 及互联网信息或其它与相关协议有关的资料，仅为宣传目的而设立或提供，是否列为相关协议的附件或组成部分，以相关协议约定为准。</p>
        <p>3.	金卡业主客户享受本明细所列权益的行为被视为(1)已阅读、理解并同意其不依赖于本公司的任何声明、代表、保证或担保(包括但不限于广告、宣传资料、口头介绍、解释及互联网信息);及(2)确认承担本明细所列服务/权益/产品(包括但不限于海外、国内医疗服务)的任何后果。为免歧义，本公司对金卡业主客户享受本明细所列服务/权益/产品(包括但不限于海外、国内医疗服务)之后的结果不承担任何责任。</p>
        <p>4.	本公司有权自主调整或制定本明细所涉的服务/权益/产品。</p>
        <p>5.	本公司尽力满足金卡客户依据相关协议享受各项已公示的金卡客户权益，但(因包括但不限于所属资源有限或发生变更而引致)部分权益无法提供时，如根据相关协议约定实际服务提供方为本公司，则除依据相关协议约定的方式处理外，本公司还可调整或暂停提供相应权益。</p>
        <p></p>
        <p>七、体验会员特别约定：体验会员不享受除购房以外的其他权益</p>

    </div>
}