/**
 * @description 首次进入就会加载该页面，用于初始化一些东西
*/
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import GUComponents from 'components';
import { useSelector } from 'react-redux';
export const Main = (props) => {
    const history = useHistory();

    const wxApiConfig = useSelector((state) => {
        return state.wxApi.toJS().config;
    });

    useEffect(() => {


        initJsBridge();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initJsBridge = async () => {
        if (Library.Platform.name() === 'GuApp') {
            Actions.wxApi.start();
            await Library.JsBridge.ready();
            if (window.pageConfig && window.pageConfig.isFullscreen) {
                document.getElementById('root').className = 'fullScreen';
            }
            //通知app 路由变化
            history.listen((location) => {
                Library.JsBridge.updateHistory(history);
            });
            Actions.wxApi.end();
        } else {
            jsApiOauth();
            history.listen(async (location) => {
                jsApiOauth();
            });
        }
    }


    const jsApiOauth = async () => {
        console.log('Library.Platform.name()', Library.Platform.name());
        if (Library.Platform.name() === 'Wechat取消sdk') {
            Actions.wxApi.start();
            console.log('Services', Services);
            let signatureResult = await Services('Jktwx', 'GetWxSignatureForJkt', {
                url: window.signLink || window.location.href,
            });
            const result = signatureResult.data || {};
            let params = {};
            params.debug = false;
            params.appId = process.env.REACT_APP_APP_ID || 'wxee9506d2a9828af5';
            params.nonceStr = result.noncestr;
            params.signature = result.signature;
            params.timestamp = result.timestamp;
            params.jsApiList = [
                'updateTimelineShareData',
                'updateAppMessageShareData',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareWeibo',
                'onMenuShareQZone',
                'scanQRCode',
                'hideOptionMenu',
                'showOptionMenu',
                'getLocation',
                'chooseImage',
                'previewImage',
                'getLocalImgData',
                'showMenuItems',
                'hideMenuItems',
                'hideAllNonBaseMenuItem',
                'showAllNonBaseMenuItem',
                'checkJsApi',
            ];
            params.openTagList = ['wx-open-launch-app']

            window.wx.config(params);
            // window.wx.ready(async function() {
            //     const params = {
            //         title: '绿地G优尊享会',
            //         desc: '绿地G优50亿消费券大派送，2万消费券等您拿！',
            //         imageUrl: 'https://jkt-icons-uat.obs.cn-east-2.myhuaweicloud.com/jkt/activity/coupon/e38f0928196abacf47a62fc078b9e09.jpg',
            //         link: window.location.origin + '/jkt/coupon/wxentrance?state=%2Ffront%2Fcustom%2Fcoupons'
            //     };
            //     window.wx.updateTimelineShareData(params);
            //     window.wx.updateAppMessageShareData(params);
            // });
            window.wx.ready(function() {
                Actions.wxApi.end();
            });

        } else {
            Actions.wxApi.start();
            Actions.wxApi.end();
        }
    };
    if (Library.Platform.name() === 'GuApp' && !wxApiConfig) {
        return false;
    }
    return <>
        {props.children}
        <GUComponents.Alert />
        <GUComponents.Modal />
        <GUComponents.Loading />
    </>;
    // if (init) {
    //     return <>
    //         {props.children}
    //         <GUComponents.Alert />
    //         <GUComponents.Loading />
    //     </>;
    // } else {
    //     return false;
    // }
};
