/**
 * @description 活动规则弹框
 * @param {activityCode: 活动编码, codeType: 'activityRule'固定值, code: 活动编码规则, btnStyle: 按钮样式,onClose: function }
*/
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './rulesModal.scss';
import { Mask } from 'antd-mobile';
import Services from 'services';

const RulesModal = ({onClose, btnStyle={}, activityCode, codeType, code}) => {
  const [visible, setVisible] = useState(true);
  const [activityRules, setActivityRules] = useState(null);

  useEffect(() => {
    visible && getActivityRules();
  }, [visible]);

  const handleClick = () => {
    setVisible(false);
    onClose && onClose();
  }

  const getActivityRules = async () => {
    const ServiceModule = window.JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
    const res = await Services(ServiceModule, { code: '101193' }, {
      activityCode,
      codeType,
      code,
    });
    if (!!res && res?.status === 1 && !!res?.data && !!res?.data?.activityRule) {
      setActivityRules(res?.data?.activityRule);
    }
    if (!!res && res?.status === 1 && !!res?.data && res?.data?.result) {
      setActivityRules(res.data.result.activityRule);
    }

  }

  return <Mask visible={visible} onMaskClick={() => handleClick()}>
    <div className='goddess-rules-modal'>
      <div className="goddess-modal-container">
        <div className="goddess-modal-title">活动规则</div>
        <div className="goddess-modal-content">
          {activityRules?.otherSign}
        </div>
        <div className="goddess-rules-btn" style={{...btnStyle}} onClick={handleClick}>我知道了</div>
      </div>
    </div>
  </Mask>
}

RulesModal.open = ({btnStyle={}, activityCode, codeType, code}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<RulesModal 
    btnStyle={btnStyle} 
    onClose={close}
    activityCode={activityCode}
    codeType={codeType}
    code={code}
  />, div);
}

export default RulesModal;