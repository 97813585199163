import React from 'react';
import styles from './score.module.scss'

export const Rules = () => {
    return <div className={styles.ruleContainer}>
        <div className={styles.content} >
            <div className={styles.text}>1.活动参与方式：注册成为G优会员，即可参与G优超级分享官活动，分享专属邀请海报给好友，每邀请一位好友，邀请人即可获得500邀请积分及一次抽奖机会，被邀请人需为新用户且使用未安装过绿地G优APP的手机下载并注册登录；</div>
            <div className={styles.text}>2.活动时间：即日起至2021年6月30日；</div>
            <div className={styles.text}>3.活动期间，每位用户每天邀请会员人数不限，但每日可获得的邀请积分上限是5000积分，积分可在绿地G优APP的积分商城中兑换精美礼品，每位邀请人每日邀新奖励抽奖机会上限为5次，抽奖次数仅限当日使用，抽奖次数次日清零；</div>
            <div className={styles.text}>4.如出现不可抗力或情势变更的情况（包括重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动遭受严重网络攻击或因系统故障等）导致活动需要停止或调整的，我们有权终止活动而无需向用户进行赔偿或补偿；</div>
            <div className={styles.text}>5.在参与活动过程中，如用户出现作弊等违法违规行为我们有权撤销用户参与活动与奖励使用资格，并有权收回用户已经获得的权益； </div>
            <div className={styles.text}>6.绿地吉客智能有限公司可根据本活动实际情况对活动规则进行优化或调整；</div>
            <div className={styles.text}>7.活动由绿地G优APP提供，与设备生产商Apple Inc.公司无关。</div>
            <div className={styles.text}>8.关于奖品：</div>
            <div className={styles.text}>1）绿地铂瑞酒店总统套房为上海三甲港绿地铂瑞酒店总统套房一晚，中奖后会由客服电话联系确认入住体验时间，仅限非国家法定节假日使用，实际可选入住时间以酒店为准；
</div>
            <div className={styles.text}>2）HUAWEI Mate X2 5G 全网通 256GB颜色版本随机，中奖后会由客服电话联系确认收货信息
</div>
            <div className={styles.text}>3）关于三只松鼠满300减205优惠券 </div>
            <div className={styles.text}>一、使用流程 </div>
            <div className={styles.text}>&#12288;&#12288;a.下载多点APP-点击我的-登录/注册</div>
            <div className={styles.text}>&#12288;&#12288;b.输入手机号-验证码登录 </div>
            <div className={styles.text}>&#12288;&#12288;c.点击优惠券-右上角添加-左下角手动输入 </div>
            <div className={styles.text}>&#12288;&#12288;d.输入或粘贴兑换码-点击确定 </div>
            <div className={styles.text}>&#12288;&#12288;e.在优惠券列表内找到三只松鼠205元优惠券 </div>
            <div className={styles.text}>&#12288;&#12288;f.点击去使用-加购商品满300元 </div>
            <div className={styles.text}>&#12288;&#12288;g.点击右上角购物车-结算。 </div>
            <div className={styles.text}>&#12288;&#12288;h.输入收货信息-选择三只松鼠205元优惠券 </div>
            <div className={styles.text}>&#12288;&#12288;i.确认下单-支付</div>
            <div className={styles.text}>二、注意事项 </div>
            <div className={styles.text}>&#12288;&#12288;a.此券不限多点新老用户，每次限用一张 </div>
            <div className={styles.text}>&#12288;&#12288;b.全国可配送，偏远地区除外（新疆，西藏，青海等），具体以多点APP配送区域为主 </div>
            <div className={styles.text}>&#12288;&#12288;c.有任何问题可咨询多点客服10100818 </div>

            <br />
        </div>
    </div>;
}






















