import { Toast } from "antd-mobile";
import Services from 'services';

class Service {

    /**
     * @description 积分抽奖实物订单提交接口
     * @interface 300063
     * @param {*} params
     * @returns
     */
    static lotteryOrderSubmit = async (params = {}) => {
        const body = {
            id: params.id,
            activityCode: params.activityCode,
            provice: params.provinceName,
            city: params.cityName,
            area: params.areaName,
            street: params.streetName,
            streetId: params.streetId,
            deliveryAddress: params.deliveryAddress,
            consigneeNumber: params.consigneeNumber,
            name: params.consigneeName,
        };
        try {
            const res = await Services('MainPage', {code: 300063}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }

    }

    /**
     * @description 女神节/母亲节实物订单提交接口
     * @interface 101330
     * @param {type: 编辑类型, req: id} params
     * @returns
     */
    static festivalOrderSubmit = async (params = {}) => {
        const {
            req,
            activityCode,
            provinceName,
            cityName,
            areaName,
            streetName,
            consigneeName,
            consigneeNumber,
            deliveryAddress,
        } = params;
        const body = {
            type: 1,
            req: req,
            activityCode: activityCode,
            province: provinceName,
            city: cityName,
            area: areaName,
            street: streetName,
            receiverName: consigneeName,
            receiverMobile: consigneeNumber,
            address: deliveryAddress
        };
        try {
            const res = await Services('MainPage', {code: 101330}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }

    }

    /**
     * @description 消息发放实物查询地址接口
     * @interface 101335
     * @param {activityCode: 活动编码, productId: 商品id} params
     *
     */
    static getMessageThingsInfo = async (params) => {

        const body = {
            activityCode: params.activityCode,
            productId: params.productId
        }
        try {
            const res = await Services('MainPage', {code: 101335}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 消息发放实物保存地址接口
     * @interface 101334
     * @param {
     *  activityCode: 活动编码,
     *  productId: 商品id,
     *  province: 省份,
     *  city: 城市,
     *  area: 区县,
     *  street: 街道,
     *  address: 详细地址,
     *  receiverName: 收货人姓名,
     *  receiverMobile: 收货人手机号
     * } params
     *
     */
    static saveMessageThingsInfo = async (params) => {
        window.JsBridge.print(params)
        const body = {
            productId: params.productId,
            activityCode: params.activityCode,
            province: params.provinceName,
            city: params.cityName,
            area: params.areaName,
            street: params.streetName,
            address: params.deliveryAddress,
            receiverName: params.consigneeName,
            receiverMobile: params.consigneeNumber
        }
        try {
            const res = await Services('MainPage', {code: 101334}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 消息发放实物保存地址接口
     * @interface 600827
     * @param {
     *  appMessageId: 站内信id,
     *  consigneeName: 收货人姓名,
     *  deliveryAddress: 收货人地址,
     * } params
     *
     */
    static submitAddress = async (params) => {
        window.JsBridge.print(params)
        const body = {
            appMessageId: params.appMessageId,
            customerAccount: params.consigneeNumber,
            consigneeName: params.consigneeName,
            deliveryAddress: `${params.provinceName}${params.cityName}${params.areaName}${params.streetName}${params.deliveryAddress}`,
        }
        try {
            const res = await Services('MainPage', {code: 600827}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 碳积分实物订单提交接口
     * @interface 101330
     * @param {type: 编辑类型, req: id} params
     * @returns
     */
    static exchangeProduct = async (params = {}) => {
        const {
            productId,
            activityCode,
            provinceName,
            cityName,
            areaName,
            streetName,
            consigneeName,
            consigneeNumber,
            deliveryAddress,
        } = params;
        const body = {
            productId: productId,
            activityCode: activityCode,
            province: provinceName,
            city: cityName,
            area: areaName,
            street: streetName,
            receiverName: consigneeName,
            receiverMobile: consigneeNumber,
            address: deliveryAddress
        };
        console.log('body---',body)
        try {
            const res = await Services('MainPage', {code: 600855}, body);
            if (res.status > 0) {
                return res
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
}
export default Service;
