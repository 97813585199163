/**
 * 每日签到 签到时间线
 */
import React, { useState, useEffect, useMemo } from 'react';
import Library from 'library';
import { useHistory, useLocation } from 'react-router-dom';
import {SignModal} from '../signModal';
import SignSuccessModal from '../signSuccessModal';
import Services from 'services';
import styles from './index.module.scss';

import iconPrice from '../../imgs/icon_liwu.png';
import inviteNewImg from '../../imgs/icon_yaoxing.png';
import exchangeImg from '../../imgs/icon_jilu.png';
import signBtnDis from '../../imgs/btn_dis_qiandao.png';
import signBtn from '../../imgs/btn_qiandao.png';

const {JsBridge} = Library;

let rewardTimer;
export const SignTimeLine = (props)=> {
    const history = useHistory();

    const {state, signInDay, onRefesh, onUpdateState} = props;

    const [signModalVisible, setSignModalVisible] = useState(false);

    useEffect(()=>{

        return () => {
            clearTimeout(rewardTimer);
        };
    },[])

    const {signArray, num} = useMemo(() => {
        const {signInDay, signInArray} = state;
        let newSignArray = [];
        let num = 0;
        
        if (signInDay <= 7) {
            newSignArray = signInArray.slice(1, 8);
        } else if (signInDay > 7 && signInDay <= 14) {
            newSignArray = signInArray.slice(8, 15);
            num = 7;
        } else if (signInDay > 14 && signInDay <= 21) {
            newSignArray = signInArray.slice(15, 22);
            num = 14;
        } else if (signInDay > 21) {
            newSignArray = signInArray.slice(22);
            num = 21;
        }
        return {signArray: newSignArray, num: num};
    }, [state.signInArray])

    //签到
    const onSignIn = async () => {
        const data = Object.assign({}, state);
        if (data.isSigned) {
            //已签到 抛出提示
            JsBridge.runAction('alert', 'error', ['明日可继续签到'])
        } else {
            //未签到 走签到流程
            if (data.amount >= data.maxAmount) {
                //已获取碎片数>=最多可获取碎片数 需要先去兑换产品
                history.push({
                    pathname: '/front/activities/dailySignin/index/exchange', 
                    callback: () => {
                        onRefesh && onRefesh();
                    },
                    maxAmount: data.maxAmount
                });
                return;
            }
            
            const signResult = await Services('MainPage', {code: '101212 '}, {});
            
            if (signResult.status > 0) {
                const _signResult = signResult.data;
                data.signInDay = _signResult.signInDay;
                data.isSigned = true;
                data.points = _signResult.points;
                onUpdateState && onUpdateState(data);
                rewardTimer = setTimeout(() => {
                    clearTimeout(rewardTimer);
                    if (_signResult.fragment) {
                        data.amount = data.amount + _signResult.fragment;
                        onUpdateState && onUpdateState(data);
                    }
                    setSignModalVisible(true);
                }, 1000);
            }
        }
    };

    return (
        <div className={styles.signTimeLine}>
            <div className={styles.signProgressWrap}>
                <div className={styles.signDaysFlag}>
                    <div
                        className={styles.signProgress}
                        style={{width: (signInDay - num) / signArray.length * 100 + '%'}}
                    ></div>
                </div>
                <div className={`${styles.continuitySignDays} ${signInDay > 21 ? styles.dayLeft : ''}`}>
                    {
                        signArray.map((item, index) => {
                            return <div className={styles.continuitySignDaysItem} key={`item-${index}`}>
                                {item.signPrize > 0 && item.prizeType > 1 && item.signOrder > signInDay ?
                                    <img src={iconPrice} alt=''/> :
                                    <div className={styles.daysFlag}><span className={styles.signProgressInnerItem}></span>
                                    </div>}
                                <span>{item.signOrder}天</span>
                            </div>
                        })
                    }
                </div>
            </div>
            <SignOperation state={state} onSignIn={onSignIn}/>
            <SignSuccessModal
                modalVisible={signModalVisible}
                signData={state}
                onClose={() => {
                    setSignModalVisible(false);
                    onRefesh && onRefesh();
                }}
            />
        </div>
        
    )

}

export const SignOperation = (props) => {
    const {state, onSignIn} = props;
    return <div className={styles.signOperation}>
        <button className={styles.commonBtnStyle} onClick={() => {
            JsBridge.open('/front/activities/dailySignin/inviteRecord?activityCode=CLIENTSIGN');
        }}>
            <img src={inviteNewImg} alt=''/>
            <span>邀新记录</span>
        </button>
        {
            state?.isSigned ? 
            <button className={`${styles.signBtn}`} onClick={onSignIn}>
                <img src={signBtnDis} alt=''/>
            </button> : 
            <button className={styles.signBtn} onClick={onSignIn} disabled={!state?.signInArray} >
                <img className={styles.signBtnText} src={signBtn} alt=''/>
            </button>
        }
        <button className={styles.commonBtnStyle} onClick={() => {
            JsBridge.open('/front/activities/dailySignin/patches');
        }}>
            <img src={exchangeImg} alt=''/>
            <span>碎片记录</span>
        </button>
    </div>;
}