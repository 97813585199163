/**
 * @description 七夕节相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const {JsBridge, Platform} = Library;

const activityCode = 'valentinesDay';

class Service {

    /**
    * @description 七夕节初始化接口
    * @interface 600817
    * @param {activityCode: 活动编码} params
    * @param { 是否显示加载loading } showLoading
    * @returns {
    * 
   */
    static getInitData = async (showLoading = true) => {
        const code = '600817'
        try {
            const params = {
                activityCode: activityCode
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, !showLoading);
            if (res.status > 0) {
                return { data: res.data?.result||res.data };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 查询活动分享配置
     * @interface 101194
     * @param { code:活动编码 } params
     * @returns
    */
    static getShareConfig = async ()=> {
        const code = '101194'
        try {
            const params = {
                code: activityCode
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, true);
            
            if (res.status > 0) {
                return { data: ServiceModule === 'Common' ? res.data?.result : res.data };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 邀新列表接口
     * @interface 101340
     * @param {activityCode:活动编码, pageNum, pageSize } params
     * @returns
    */
    static getInviteList = async (params={})=>{
        const code = '101340'
        try {
            const body = {
                activityCode: activityCode,
                pageSize: 100,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 领取记录接口
     * @interface 101329
     * @param {activityCode: 活动编码,} params
     * @returns
    */
    static getReceiveGiftList = async (params={})=>{
        const code = '101329'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, body, true);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 查询奖励商品库存接口
     * @interface 600819
     * @param {activityCode: 活动编码,skuId: 商品skuId } params
     * @returns
    */
    static queryGiftStock = async (params={}, showLoading=true)=>{
        const code = '600819'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取奖励接口
     * @interface 600818
     * @param {activityCode: 活动编码,skuId: 商品skuId } params
     * @param { 是否显示加载loading } showLoading
     * @returns
    */
    static receiveGift = async (params={}, showLoading=true)=>{
        const code = '600818'
        try {
            const body = {
                activityCode: activityCode,
                ...params
            }
            
            const res = await Services('MainPage', { code }, body, !showLoading);
            if (res.status > 0) {
                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 获取推荐商品接口
     * @interface 101342
     * @param {*} params
     * @returns
    */
    static getRecommendShopList = async (params={})=> {
        const code = '101342'
        try {
            const body = {
                activityCode: activityCode,
                ...params,
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, body, true);
            if (res.status > 0) {
                return {data: res.data?.result||res.data };
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

       /**
     * @description 分享活动统计次数接口
     * @interface 600112
     * @param {activityCode : 活动code, clientId: 用户ID}
     * @returns
    */
       static shareActivityTask =  async (params={}) => {
        // 仅在微信浏览器调用
        if(!Platform.check.isWechat()) return;
        const code = '600112'
        if(Service.shareTask) {
            console.log('code:',code)
            return;
        }
        try {
            const param = {
                activityCode: activityCode,
                clientId: params.clientId
            }
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, param, true);
            if (res.status > 0) {
                Service.shareTask = res.data;
                return res;
            }else {
                throw res.message || '请求失败'
            }
            
        } catch (error) {
            console.log(error);
        }
    }

}

Service.activityCode = activityCode;
export default Service;