import Bargain from './bargain';
import logo from 'images/icon/logo@2x.png';

const shareInfo = {
    title: '邀请您加入世界500强绿地旗下G优生活平台',
    desc: '享受安全、便捷、贴心的城市生活优质增值服务',
    imgUrl: logo,
    link: window.location.href
};

export default [
    {
        path: '/activities/emall/bargain',
        component: Bargain,
        title: '砍价活动',
        share: shareInfo
    },
]