import { useEffect, useState } from 'react';

export const Admin = (props) => {
    const [init, setInit] = useState(false);

    const getUserInfo = async () => {
        // const result = await Services.jktWeixin.isBind();
        // console.log(result);
    };

    useEffect(() => {
        if (!init) {
            getUserInfo();
            window.wx.hideMenuItems({
                menuList: ['menuItem:share:appMessage',
                    'menuItem:share:timeline',
                    'menuItem:share:qq',
                    'menuItem:openWithQQBrowser',
                    'menuItem:share:email']
            });
            setInit(true);
        }
    }, [init]);

    return (props.children);
};
