/**
 * @description 介绍-了解更多
*/
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from 'components';
import Styles from './index.module.scss'

const Dick = {
    "0": {name: '颐身·健康保健服务', img: require('images/kangyang/introduce_more0.png')},
    "1": {name: '颐年·医疗保障服务', img: require('images/kangyang/introduce_more1.png')},
    "2": {name: '颐家·智能安康服务', img: require('images/kangyang/introduce_more2.png')},
    "3": {name: '颐贤·生活照料服务', img: require('images/kangyang/introduce_more3.png')},
    "4": {name: '颐神·娱乐社交服务', img: require('images/kangyang/introduce_more4.png')},
    "5": {name: '颐志·文化教育服务', img: require('images/kangyang/introduce_more5.png')},
}

export function IntroduceMore() {
    
    const params = new URLSearchParams(useLocation().search);
    const type = params.get('type');
    return (
        <div className={Styles.introduce_more}>
            <Header midText={Dick[type]?.name ?? '颐尚居'}/>
            <img src={Dick[type]?.img} alt="" draggable={false}/>
        </div>
    )
}