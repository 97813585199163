/**
 * @description 我的充值卡记录
 * @returns /front/member/pointRecharge/myPointCardRecord
 */
import React, {useEffect, useState} from "react";
import {Tabs} from 'antd-mobile';
import {Header, ListView} from 'components';
import Service from '../service';
import Styles from './index.module.scss';
import Library from "../../../../library";

const {JsBridge,} = Library;
export default (props) => {

    const [activeKey, setActiveKey] = useState('0');
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const currentPage = React.useRef(1);

    useEffect(() => {
        onRefresh(true);
    }, [activeKey])

    //加载列表数据
    const onRefresh = async (loading = false) => {
        currentPage.current = 1;
        if (loading) {
            setLoading(true)
        }
        const res = await Service.myPointCardsRecord({
            currentPage: currentPage.current,
            type: Number(activeKey) + 1
        }, loading);
        if (loading) {
            setLoading(false)
        }
        console.log('res', res)
        if (res?.data) {
            const {records = []} = res.data;
            setDataList(records);
            setHasMore(records.length >= Service.PageSize);
        }
    }

    const loadMore = async () => {
        const res = await Service.myPointCardsRecord({
            currentPage: currentPage.current + 1,
            type: Number(activeKey) + 1
        }, loading);
        if (res?.data) {
            const {records = []} = res.data;
            setDataList([...dataList, ...records]);
            setHasMore(records.length >= Service.PageSize)
            currentPage.current = currentPage.current + 1;
        }
    }
//rUserCode:接受人  userCode:发送人/发起 inOut:1=接受
    const renderItem = (item) => {
        return (
            <div className={Styles.list_item}>
                <div className={Styles.top_idf}>
                    <span>充值卡号：{item.pointCardNo}</span>
                </div>
                <div className={Styles.img_container}>
                    <img src={item.imageUrl} alt=""/>
                    <span className={Styles.time}>{item.expiredTime && `有效期至：${item.expiredTime.split(' ')[0]}`}</span>
                    <span className={Styles.point}>{item.rechargePoint || '--'}积分</span>
                </div>
                <div className={Styles.content}>
                    <div>
                        {/*<p>G优充值卡</p>*/}
                        {
                            activeKey == 1 ? <p>G优充值卡-{item.inOut === 1 ? '转出' : '转入'}</p> : <p>G优充值卡</p>
                        }
                        <span>{item.rechargeTime || item.transferTime || item.refundTime}</span>
                    </div>
                    {
                        activeKey == 0 ?
                            <span className={Styles.activeKey0}>+{item.rechargePoint || '--'}积分</span> : null
                    }
                    {
                        activeKey == 1 ? <span className={Styles.activeKey23}>{item.inOut === 1? item.rUserCode:item.userCode}</span> : null
                    }
                    {
                        // pointCardStatus: 积分卡状态3退款中,4退款完成
                        activeKey == 2 ?
                            <span className={Styles[item.pointCardStatus == 4 ? 'activeKey2' : 'activeKey22']}>
                            {item.pointCardStatus == 4 ? '已退款' : '退款中'}
                        </span> : null
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={Styles.myPointCardRecord_page}>
            <Header midText={'充值卡记录'}/>
            <div className={Styles.page_body}>
                <Tabs
                    className={Styles.myPointCardRecord_tabs}
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                >
                    {
                        ['充值记录', '转赠记录', '退卡记录'].map((item, i) => {
                            return (
                                <Tabs.Tab
                                    key={i}
                                    title={<TabHeaderItem title={item} index={i} active={activeKey == i}/>}
                                >
                                    <ListView
                                        dataSource={dataList}
                                        renderItem={renderItem}
                                        emtyDesc={loading ? '数据加载中...' : '暂无数据'}
                                        hasMore={hasMore}
                                        onRefresh={onRefresh}
                                        loadMore={loadMore}
                                    />
                                </Tabs.Tab>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div>
    )
}

/**
 * @class TabHeaderItem
 */
const TabHeaderItem = (props) => {
    const {title, active, index} = props
    return (
        <div className={'topTabItem' + ` ${active ? 'active' : ''}`}>
            <div>{title}</div>
            <p className={`${active ? 'active' : ''}`}/>
        </div>
    )
}
