import React,{useState} from 'react';
import picture1 from 'images/kangyang/picture-1@2x.png';
import picture2 from 'images/kangyang/picture-2@2x.png';
import picture3 from 'images/kangyang/picture-3@2x.png';
import picture4 from 'images/kangyang/picture-4@2x.png';
import picture5 from 'images/kangyang/picture-5@2x.png';
import picture6 from 'images/kangyang/picture-6@2x.png';
import picture7 from 'images/kangyang/picture-7@2x.png';
import picture8 from 'images/kangyang/picture-8@2x.png';
import picture9 from 'images/kangyang/picture-9@2x.png';
import picture10 from 'images/kangyang/picture-10@2x.png';
import picture11 from 'images/kangyang/picture-11@2x.png';
import picture12 from 'images/kangyang/picture-12@2x.png';
import picture13 from 'images/kangyang/shenghuozhaoliao@2x.png';
import title1Img from 'images/kangyang/title-1@2x.png';
import title2Img from 'images/kangyang/title-2@2x.png';
import title3Img from 'images/kangyang/title-3@2x.png';
import title4Img from 'images/kangyang/title-4@2x.png';
import swiperImg1 from 'images/kangyang/ysjp1@2x.png';
import swiperImg2 from 'images/kangyang/ysjp2@2x.png';
import swiperImg3 from 'images/kangyang/ysjp3@2x.png';
import swiperImg4 from 'images/kangyang/ysjp4@2x.png';
import ysjWechat from 'images/kangyang/ysjwechat@2x.png';
import mapImg from 'images/kangyang/map@2x.png';
import icon from 'images/kangyang/icon@2x.png';
import icon2 from 'images/kangyang/icon2@2x.png';
import styles from './index.module.scss';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {Slogon} from '../../slogon';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const  YSJDetail = ()=>{
    const [arr] = useState(['24小时\n呼叫服务','24小时生活\n助理服务','室内\n保洁服务','床上用品\n清洗服务','窗帘窗纱\n清洗服务','垃圾\n清运服务',
        '定期社保\n检查服务','物业\n维修服务','会员特殊\n提醒服务','温馨问候','出行服务','陪同超市\n购物服务',
        '亲情沟通','访客接待\n与引导服务','代办服务','意外保险','房屋托管','消防演习'
    ])
    const [arr2] = useState([swiperImg1,swiperImg2,swiperImg3,swiperImg4]);
    const [visible,setVisible] = useState(false);
    const [index, setIndex] = useState(0);

    const handleChangeIndex = index => {
        if (index < arr2.length) {
            setIndex(index);
        }
    };

    return <div className={styles.container}>
        <div className={styles.content}>
            <img className={styles.title1Img} src={title1Img}/>
            <img src={picture1}/>
            <p>绿地国际康养城是由绿地康养产业集团依托母公司世界500强雄厚资源，投资29.35亿筑造的集“医、康、养、旅、产、融”六大产业模式于一体的国际化综合性全龄康养社区。作为集团的标杆示范项目，绿地国际康养城结合国内市场需求、借鉴国外先进模式，为长者带来颐动颐静的全新退休生活体验。</p>
            <img style={{marginTop:20}} src={ysjWechat}/>
            <p>绿地国际康养城坐落于上海市青浦区朱家角这一交通便捷的休闲度假养生地，依托周边丰富文旅资源以及中山医院青浦分院、朱家角人民医院、德颐护理院等优质医疗资源，打造出一处自然与人文兼具的生态天然养心居。</p>
            <a className={styles.titleSwiper}>颐尚居项目展示</a>
            <div className={styles.swiper}>
                <AutoPlaySwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                    {arr2.map((item,index)=>{
                        return <img src={item} key={index}/>
                    })}
                </AutoPlaySwipeableViews>
                <div className={styles.dotContainer}>
                    {arr2.map((item,i)=>(
                        <div className={`${styles.dot} ${i===index?styles.active:''}`} key={i}/>
                    ))}
                </div>
            </div>
            <p>项目共分三期开发，其中一期颐尚居定位为会籍制康养社区，涵盖约70~108㎡的颐养公寓和配套商业。二期规划打造康养居酒店、颐养公寓和配套商业，是一期的延伸和拓展。三期规划建设长租式公寓，计划引进幼儿园等教育配套。</p>
            <img className={styles.title2Img} src={title2Img}/>
            <p>绿地国际康养城一期颐尚居定位为会籍制康养社区,涵盖颐养公寓、邻里中心及配套商业,以“颐”文化为价值内核,从颐身、颐年、颐家、颐贤、颐神、颐志六个方面构建服务体系。</p>
            <p>颐尚居通过健康助理、生活助理、快乐助理三大助理,提供108项专业化服务,给每位会员以家人般温暖的关爱呵护,为会员打造一个健康快乐的颐养生活家。项目更全面采用了56项适老化配置,贴心细致,安全舒适,让会员乐养于此,幸福所居。</p>
            <p>颐尚居有颐尊卡和颐悦卡可供选择，满足长者不同的康养需求。购买一张会员卡就能获得双重会籍享受，既拥有颐尚居会员的权益及服务，又能在遍布全国的康养居酒店享受“候鸟式”的旅居生活。</p>
            <img style={{marginTop:20}} src={picture2}/>
            <img className={styles.title3Img} src={title3Img}/>
            <h2>一、颐身：健康保健服务</h2>
            <img src={picture3}/>
            <h2>二、颐年：医疗保障服务</h2>
            <img src={picture4}/>
            <h2>三、颐家：智能安康服务</h2>
            <img src={picture5}/>
            <h2>四、颐贤：生活照料服务</h2>
            <div className={styles.view}>
                <div className={styles.div}>
                    {arr.map((item,index)=>(
                        <div className={styles.item} key={index}>
                            <p className={styles.text}>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
            <h2>五、颐神：娱乐社交服务</h2>
            <img src={picture6}/>
            <h2>六、颐志：文化教育服务</h2>
            <img src={picture7}/>
            <img className={styles.title4Img} src={title4Img}/>
            <h2>一、颐身：健康保健服务</h2>
            <img src={icon}/>
            <h2>二、颐年：医疗保障服务</h2>
            <img src={icon2}/>
            <h2>三、颐家：智能安康服务</h2>
            <div className={styles.view2}>
                <div className={styles.div2}>
                    <img className={styles.imgIcon} src={picture8}/>
                    <img className={styles.imgIcon} src={picture9}/>
                </div>
            </div>
            <h2>四、颐贤：生活照料服务</h2>
            <img src={picture13}/>
            <h2>五、颐神：娱乐社交服务</h2>
            <img src={picture10}/>
            <h4>六、颐志：文化教育服务</h4>
            <img src={picture11}/>
            <img style={{marginTop:29}} src={picture12}/>
            <img style={{marginTop:20}} src={mapImg}/>
            <p className={styles.remark}>备注：</p>
            <p className={styles.remark}>1.增值服务价格以社区当期公示为准</p>
            <p className={styles.remark}>2.增值服务价格每年将根据国家统计局公布的CPI指数进行调整</p>
            <Slogon/>
        </div>
    </div>
};
