/**
 * @description 日常任务
*/
import React, { useState, useEffect } from 'react';
import Service from '../../service';
import './index.scss';
import JsBridge from 'library/jsbridge';

const DicMapList = {
    'metavense_visit': {img: require('images/pointTask/unity.png'), unit: 'min'},
    'collection_trade': {img: require('images/pointTask/nft.png'), unit: '次'},
    'goods_share': {img: require('images/pointTask/shop_share.png'), unit: '人'},
    'activity_share': {img: require('images/pointTask/actvity_share.png'), unit: '人'},
    'goods_buy': {img: require('images/pointTask/shop_buy.png'), unit: '单'},
    'goods_positive_evaluate': {img: require('images/pointTask/pingjia.png'), unit: '次'},
    'activity_goods_buy': {img: require('images/pointTask/activity_shop_buy.png'), unit: '单'},
    'points_goods_exchange': {img: require('images/pointTask/point_shop.png'), unit: '次'},
}

export default (props) => {

    const { dataSource=[] } = props

    const onJumpClick = (item) => {
        const type = item.taskType;
        switch (type) {
            case 'metavense_visit':
                // 访问元宇宙
                JsBridge.startUnity3d({
                    sceneName: 'shop'
                })
                break;
            case 'collection_trade':
                // 数字藏品购买/合成
                JsBridge.push('CustomWeb', {uri: `${process.env.REACT_APP_NFT_LINK}/myCollection`})
                break;
            case 'goods_share':
                // 商品分享 - 商城首页
                JsBridge.navigate('tabLife');
                break;
            case 'activity_share':
                // 活动分享
                console.log(item.link)
                const {path, params} = getPramasFromUrl(item.link);
                // 活动商品购买
                path && JsBridge.push(path, params)
                break;
            case 'goods_buy':
                // 商品购买 - 商城首页
                JsBridge.navigate('tabLife');
                break;
            case 'goods_positive_evaluate':
                // 商品好评 - 待评价界面
                JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-A/customer/evaluate-center/index`})
                break;
            case 'activity_goods_buy':
                {
                    const {path, params} = getPramasFromUrl(item.link);
                    // 活动商品购买
                    path && JsBridge.push(path, params)
                }
                break;
            case 'points_goods_exchange':
                // 积分商城兑换
                // JsBridge.back();
                JsBridge.navigate('tabMall')
                break;
            default:
                break;
        }
        
    }

    const getPramasFromUrl = (url) => {
        let obj = {};
        const urlArray = (url || '').split('?');
        if (urlArray.length > 1) {
            const params = urlArray[1].split('&');
            params.forEach((value, key) => {
                const thisKey = value.split('=')[0];
                const thisValue = value.split('=')[1];
                obj[thisKey] = thisValue && decodeURIComponent(thisValue);
            });
        }
        return { path: urlArray[0], params: obj };
    }

    const onReciveClick = async (item)=> {
        const result = await Service.receiveNormalTask(item);
        if (result) {
            props.callBack && props.callBack({
                visible: true,
                title: '领取成功',
                content: `恭喜你完成任务！成功领取 ${item.taskPoint} 积分，再接再厉哦~`
            });

        }
    }

    const handleData = (item)=> {
        let text = ''
        if(item.taskType == 'metavense_visit'){
            //访问元宇宙
            text = `${item.completeAmount||0}/${item.visitTime}`
        }else if(item.taskType == 'collection_trade'){
            //数字藏品购买/合成
            text = `${item.completeAmount||0}/${item.buyUpgradeCount}`
        }else if(item.taskType == 'points_goods_exchange'){
            //积分商城兑换
            text = `${item.completeAmount||0}/${item.orderCount}`
        }else if(item.taskType == 'activity_share'){
            //活动分享
            text = `${item.completeAmount||0}/${item.shareCount}`
        }else if(item.taskType == 'goods_share'){
            //商品分享
            text = `${item.completeAmount||0}/${item.shareCount}`
        }else if(item.taskType == 'goods_positive_evaluate'){
            //商品好评
            text = `${(item.completeAmount||0) > 0 ? 1:0}/1`
        }else if(item.taskType == 'activity_goods_buy'){
            //活动商品购买
            text = `${item.completeAmount||0}/${item.orderCount}`
        }else if(item.taskType == 'goods_buy'){
            // 商品购买
            text = `${item.completeAmount||0}/${item.orderCount}`
        }

        return text;
    }

    const list = dataSource.sort((a, b) => {
        // 已完成并已送积分排在最后
        return b.completeStatus === 1 && b.pointSendStatus == 1 ? -1 : 1;
    })
    
    return (
        <div className='daily-task'>
            <h1>日常任务</h1>
            <div className='item-list'>
                {
                    list.map((item, k)=> {
                        item.clientId = props.clientId
                        const exend = DicMapList[item.taskType]||{}
                        return (
                            <div className='item' key={k}>
                                <div className='left'>
                                    <img src={exend.img} className='img'/>
                                    <div>
                                        <div>{item.taskName}</div>
                                        <div>
                                            <span>{`${handleData(item)}${exend.unit}`}</span>
                                            <span>{`得${item.taskPoint || 0}积分`}</span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    item.completeStatus === 1 && item.pointSendStatus == 1 ?
                                    <div className={'right finished'}>
                                        <span>已完成</span>
                                    </div>:
                                    item.completeStatus === 1 ?
                                    <div className={'right nomal'} onClick={()=>onReciveClick(item)}>
                                        <span>领取积分</span>
                                    </div>:
                                    <div className={'right nomal'} onClick={()=>onJumpClick(item)}>
                                        <span>去完成</span>
                                    </div>
                                }
                                
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}