/**
 * @description 积分任务相关接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
const { JsBridge, Platform, Fetch } = Library;
class Service {

    static activityCode = 'metaverseActivity';

    /**
     * @description 获取用户信息
     * @interface MainPage/ 108002
     * @returns {clientId, nftLevel, avatar}
    */

    static getAvatar = async () => {
        // 获取用户信息方式1
        //    const result = await JsBridge.getCurrentUserInfo();
        //    console.log(JSON.stringify(result))
        // 获取用户信息方式2
        // const result = await Services('MainPage', {code: '108002 '}, {});
        // console.log(JSON.stringify(result))
        const result = await JsBridge.runAction('user', 'getAvatar', []);
        let avatar = ''
        if (result.status > 0) {
            avatar = result.data.imagePath || ''
        }
        return { avatar }
    }

    /**
     * @description 获取基础任务数据和签到数据
     * @interface 使用签到界面接口中的数据字段pointTaskList
    */
    static mock = false;
    static getBaseTask = async () => {
        try {
            let res;
            if(this.mock) {
                res = require('./mock').guSignData;
            }else {
                res = await Services('MainPage', { code: '101211 ' }, {})
            }
             
            if (res.status > 0) {
                const {pointTaskList, ...other} = res.data ?? {}
                return { 
                    baseTasks: pointTaskList || [],
                    ...other
                };
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取基础任务奖励接口
     * @interface 101032
     * @param {taskType : 任务类型}
     * @returns
    */
    static receiveBaseTask = async (params = {}) => {
        try {
            const param = {
                taskType: params.type
            }
            const result = await JsBridge.runAction('user', 'submitPointTask', [param]);

            if (result.status > 0) {
                return result;
            } else {
                throw result.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 日常积分任务接口
     * @interface 600110
     * @param { 是否显示加载loading } showLoading
     * @returns {
     * }
    */
    static getNormalTasksData = async (showLoading = true) => {
        const code = '600110'
        try {
            const params = {}
            const res = await Services('MainPage', { code }, params, !showLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 领取日常任务奖励接口
     * @interface 600111
     * @param {taskType : 任务类型, clientId, taskId: 任务id }
     * @returns
    */
    static receiveNormalTask = async (params = {}, showLoading = true) => {
        const code = '600111'
        try {
            const param = {
                taskType: params.taskType,
                taskId: params.id,
                clientId: params.clientId
            }
            const res = await Services('MainPage', { code }, param, !showLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }

        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 查询元宇宙签到数据
     * @param {clientId}
     * @interface 600115
    */
    static querySignData = async (params = {}, showLoading = true) => {
        const code = '600115'
        try {
            const param = {
                ...params
            }
            const res = await Services('MainPage', { code }, param, !showLoading);
            if (res.status > 0) {
                return JSON.parse(res.data);
            } else {
                throw res.message || '请求失败'
            }

        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 查询元宇宙每日任务
     * @interface 600116
     * @param {clientId}
     */
    static queryEveryDayTask = async (params = {}, showLoading = true) => {
        const code = '600116'
        try {
            const param = {...params}
            const res = await Services('MainPage', { code }, param, !showLoading);
            if (res.status > 0) {
                return JSON.parse(res.data);
            } else {
                throw res.message || '请求失败'
            }

        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 查询签到获奖结果列表数据
     * @interface 101329
     * @param {activityCode: 活动编码 }
     * @interface
    */
    static queryExchangeReward = async (params = {}, showLoading = true) => {
        const code = '101329'
        try {
            const param = {
                activityCode: Service.activityCode,
                ...params
            }
            const res = await Services('MainPage', { code }, param, !showLoading);
            if (res.status > 0) {
                return res;
            } else {
                throw res.message || '请求失败'
            }

        } catch (error) {
            !window.JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 分享活动统计次数接口 (可参考七夕节活动activities/magpieFestival)
     * @interface 600112
     * @param {activityCode : 活动code, clientId: 用户ID}
     * @returns
    */
    static shareActivityTask = async (params = {}) => {
        // 仅在微信浏览器调用该接口
        if (Platform.check.isWechat()) {
            const code = '600112'
            if (Service.shareTask) {
                console.log('code:', code)
                return;
            }
            try {
                const param = {
                    activityCode: 'valentinesDay',
                    clientId: '70e7728325d442df9e8af8346d49ae86'
                }
                const ServiceModule = window.JsBridge.hasWebViewBridge() ? 'MainPage' : 'Common'
                const res = await Services(ServiceModule, { code }, param, true);
                if (res.status > 0) {
                    Service.shareTask = res.data;
                    return res;
                } else {
                    throw res.message || '请求失败'
                }

            } catch (error) {
                console.log(error);
            }

        }

    }

}

export default Service;