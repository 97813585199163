/**
 * @description 地址管理接口
*/
import { Toast } from "antd-mobile";
import Services from 'services';
import Library from 'library';
import {
    provinces,
    citys,
    areas,
    streets,
    mockList
}  from './mock';
const { JsBridge } = Library;

const isMock = false
class Service {

    /**
     * @description 地址缓存key
    */
    static kLocalSelectAddressKey = 'jkt::LocalSelectAddressKey';

    /**
     * @description 地址列表查询接口
     * @code 101294
     * @param {*} params 
     * @param { 是否显示加载loading } customLoading
     * @returns 
     */
    static queryAddressList = async (params={}, customLoading)=> {
        const code = '101294'
        if(isMock) {
            const res = await Promise.resolve(mockList)
            if(res.status > 0) {
                return res
            }else {
                console.log(res.msg);
            }
            return undefined;
        }
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, !customLoading);
            if (res.status > 0) {
                return { data: res.data?.result||res.data };
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
        
    }
     /**
     * @description 查询常用地址 
     * @param {*} 
     * @returns 
     */
     static queryCommonAddress = async ()=> {
        const result = await this.queryAddressList()
        let list = []
        let defaltAddress;
        if(result) {
            list = result.data || []
        }
        //查找默认地址
        defaltAddress = list.find(item => item.isDefaltAddress == 1)
        if(!defaltAddress && list.length>0) {
            defaltAddress = list[0]
        }

        return defaltAddress

     }

    /**
     * @description 新增/编辑地址接口
     * @code 101296/101295
     * @param {*} params 
     * @returns 
     */
   static editSaveAddress = async (params={}) => {
        let code = params.deliveryAddressId ? '600888' : '600889';
        if(JsBridge.hasWebViewBridge()) {
            code = params.deliveryAddressId ? '101295' : '101296';
        }
        const body = {
            consigneeName: params.consigneeName,
            consigneeNumber: params.consigneeNumber,
            provinceId: params.provinceId,
            cityId: params.cityId,
            areaId: params.areaId,
            streetId: params.streetId ? params.streetId : 0,
            deliveryAddress: params.deliveryAddress,
            deliveryAddressId: params.deliveryAddressId,
            isDefaltAddress: params.isDefaltAddress ? 1 : 0
        }
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, body);
            JsBridge.print(res)
            if (res.status > 0) {
                return { data: res.data?.result||res.data };
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
   }

    /**
     * @description 删除地址接口
     * @code 101297
     * @param {deliveryAddressId: ''} params 
     * @returns 
     */
   static deleteAddress = async (params={}) => {
        const code = JsBridge.hasWebViewBridge() ? '101297': '600896'
        
        const body = {
            deliveryAddressId: params.deliveryAddressId
        }
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, body);
            if (res.status > 0) {
                if (JsBridge.hasWebViewBridge()) {
                    const res = await JsBridge.getStorage({key:this.kLocalSelectAddressKey})
                    const localAddress = res.result
                    if(localAddress && localAddress.deliveryAddressId == body.deliveryAddressId) {
                        JsBridge.removeStorage({key:this.kLocalSelectAddressKey})
                    }
                    
                }else {
                    const localAddressStr = localStorage.getItem(this.kLocalSelectAddressKey)
                    if(localAddressStr) {
                        try {
                            const localAddress = JSON.parse(localAddressStr)
                            if(localAddress.deliveryAddressId == body.deliveryAddressId) {
                                localStorage.removeItem(this.kLocalSelectAddressKey)
                            }
                            
                        } catch (error) {
                            console.log(error)
                        }
                        
                        
                    }
                    
                }

                return res;
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
   }
   
    /**
     * @description 设置默认地址
     * @code 101321
     * @param {deliveryAddressId: ''} params 
     * @returns 
     */
    static defaltAddressSetting = async (params={}) => {
        const code = JsBridge.hasWebViewBridge() ? '101321': '600897'
        
        const body = {
            deliveryAddressId: params.deliveryAddressId
        }
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, body);
            if (res.status > 0) {
                return { data: res.data?.result||res.data };
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * @description 查询省接口
     * @code 101298 
     * @returns {list}
     */
    static getProvinceList = async ()=> {
        const code = '101298'

        if(isMock) {
            const res = await Promise.resolve(provinces)
            if(res.code === '000000') {
                return res.result || []
            }else {
                console.log(res.msg);
            }
            return undefined;
        } 
        
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, {}, true);
            JsBridge.print(res)
            if (res.status > 0) {
                return res.data?.result|| res.data || []
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 查询市接口 
     * @code 101299
     * @param {provinceId: 省id } params
     * @returns {list}
     */
    static getCityList = async (params={})=> {
        const code = '101299'

        if(isMock) {
            const res = await Promise.resolve(citys)
            if(res.code === '000000') {
                return res.result || []
            }else {
                console.log(res.msg);
            }
            return undefined;
        }
        
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, true);
            if (res.status > 0) {
                return res.data?.result|| res.data || [];
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 查询区/县接口 
     * @code 101300
     * @param {cityId: 城市id} params
     * @returns {list}
     */
    static getAreaList = async (params={})=> {
        const code = '101300'

        if(isMock) {
            const res = await Promise.resolve(areas)
            if(res.code === '000000') {
                return res.result || []
            }else {
                console.log(res.msg);
            }
            return undefined;
        }
        
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, true);
            if (res.status > 0) {
                return res.data?.result|| res.data || [];
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }
    /**
     * @description 查询街道接口 
     * @code 101320
     * @param {areaId: 区县id} params
     * @returns {list}
     */
    static getStreetList = async (params={})=> {
        const code = '101320'
        if(isMock) {
            const res = await Promise.resolve(streets)
            if(res.code === '000000') {
                //其他区
                if(params.areaId == '130186') {
                    return []
                }else {
                    return res.result || []
                }
            }else {
                console.log(res.msg);
            }
            return undefined;
        }
        
        try {
            const ServiceModule = JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
            const res = await Services(ServiceModule, { code }, params, true);
            if (res.status > 0) {
                return res.data?.result|| res.data || [];
            }else {
                throw res.message || '请求失败'
            }
        } catch (error) {
            !JsBridge.hasWebViewBridge() && Toast.show(error || '请求失败')
        }
    }

    /**
     * 商城H5中请求方法测试事例
    */
    loadData = async () => {
        let token = ''
        const header = {
            'Content-Type': 'application/json; charset=utf-8',
            terminal: 'H5',
            Authorization: 'Bearer ' + token, //SSO登录信息校验，必传
            'distribute-channel': JSON.stringify({channelType: 1}),
          };
        const url = 'https://mall-test.gkewang.com/mbff/customer/addressList'
        try {
            const data = {
                method: 'GET',
                headers: header,
                // body:''//POST
            }
            const response = await fetch(url, data)
            if(response.status == 200) {
                const reslut = await response.json()
                console.log(reslut)
                if(reslut?.context){
                    const list = (reslut?.context || [])
                    console.log(list)
                }else {
                    throw reslut.message || '请求失败'
                }
            }else {
                const msg = await response.text()
                debugger
                throw msg || '请求失败'
            }
            

        } catch (error) {
            debugger
            console.log(error)
        }
        
    }

}

export default Service;