import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './receiveModal.scss';
import { Mask } from 'antd-mobile';
import Library from 'library';
const { JsBridge, Util } = Library;

const ReceiveModal = ({onClose, callBack, prizeInfo}) => {
  const [visible, setVisible] = useState(true);

  const onDidFocus = () => {
    JsBridge.addListener().then(() => {
      callBack && callBack();
    });
  }
  
  const handleClick = () => {
    const param = Util.url.objectToQueryParams({
      idf: 'goddessFes',
      activityCode: 'goddessFes',
      name: prizeInfo.prizeName,
      imgUrl: prizeInfo.imageUrl,
      req: prizeInfo.req,
    });
    JsBridge.push('CustomWeb', {uri: `${window.location.origin}/front/guapp/activityOrder?${param}`});
    onDidFocus();
    onClose && onClose();
    setVisible(false);
  }

  const onMask = () => {
    callBack && callBack();
    onClose && onClose();
    setVisible(false);
  }

  return <Mask visible={visible} onMaskClick={onMask}>
    <div className='goddess-receive-modal'>
      <div className="goddess-modal-container">
        <div className="goddess-modal-title">领取奖励</div>
        <div className="goddess-modal-content">
          <div className="receive-status">恭喜您！领取成功！</div>
          <div className="wenxin-tip"> 请尽快填写您的收获地址，也可以在领取记录中查看并填写地址，活动结束后未填写地址奖品将作废</div>
        </div>
        <div className="goddess-receive-btn" onClick={handleClick}>填写地址</div>
      </div>
    </div>
  </Mask>
}

ReceiveModal.open = ({callBack, prizeInfo}) => {
  let div = null;
  if(!div) {
    div = document.createElement('div');
  }
  document.body.appendChild(div);
  const close = () => {
    div.remove();
  }
  ReactDOM.render(<ReceiveModal callBack={callBack} prizeInfo={prizeInfo} onClose={close}/>, div);
}

export default ReceiveModal;