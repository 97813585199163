import React, { useState, useEffect } from "react"
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import back from '../imgs/back.png'
import infoTitle from '../imgs/info.png'
import warning from '../imgs/warning.png'
import { Form, Input, Toast } from 'antd-mobile'
import weigouxuan from '../imgs/weigouxuan.png'
import gouxuan from '../imgs/gouxuan.png'
import ConfirmModal from "./confirmModal"
import { getDataInUnity } from "../../../../services/serviceUnity"
import Library from "library"
const { Util } = Library

const PerformerInfo = () => {
  const { isWrite='0', activityCode='', clientId='', id='' } = Util.url.paramsToObj()
  const history = useHistory()
  const [checkStatus, setCheckStatus] = useState(false)
  const [performerInfo, setPerformerInfo] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    if (isWrite === '1') {
      getData()
    }
  }, [])

  const validateFields = async ()=> {
    let reslut = {};
    try {
      reslut = await form.validateFields()
    } catch (error) {
      reslut = error
    }
    return reslut;
  }

  const getData = async () => {
    try {
      const res = await getDataInUnity(600815, {activityCode, clientId})
      console.log(res)
      setPerformerInfo(res.result || {})
    } catch (error) {
    }
  }

  const onConfirm = async () => {
    const reslut = await validateFields()
    const values = form.getFieldsValue()
    if(reslut.errorFields) {
      return Toast.show(reslut.errorFields[0].errors[0])
    }
    if (!Util.validate.idcard(values.attendeeCardNo)) {
      return Toast.show('请填写正确的身份证号')
    }
    if (!checkStatus) {
      return Toast.show('请勾选协议')
    }
    ConfirmModal.open({
      cancelText: '取消',
      confirmText: '确认提交',
      content: '确认提交当前信息？提交后不可更改',
      onConfirm: async () => {
        const values = form.getFieldsValue()
        const params = {
          activityCode,
          clientId,
          attendeeCardNo: values.attendeeCardNo,
          attendeeContactNum: values.attendeeContactNum,
          attendeeName: values.attendeeName,
          id,
        }
        console.log(params)
        try {
          const res = await getDataInUnity(600816, params)
          if (res.code === '000000') {
            Toast.show({
              content: '信息已提交',
              afterClose: () => history.goBack(),
              duration: 1500,
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  return <div className={styles.performer_info}>
    <div className={styles.main_container}>
      <div className={styles.info_top}>
        <img className={styles.back} src={back} alt="" onClick={() => history.goBack()}/>
        <img className={styles.title} src={infoTitle} alt="" />
        <div className={styles.back}></div>
      </div>
      <div className={styles.info_form_container}>
        <div className={styles.tip_text}>
          <img src={warning} alt="" />
          <span>特别提示：实名制入场需本人持填写时的有效证件入场</span>
        </div>
        <Form className={styles.form_style} form={form} autoComplete="off">
          <div className={styles.form_item}>
            <div className={styles.label}>真实姓名：</div>
            {isWrite === '0' ? <div className={styles.input}>
              <Form.Item 
                name="attendeeName" 
                noStyle
                validateTrigger="blur" 
                rules={[
                  { required: true, message: '请填写观演人姓名' },
                ]}
              >
                <Input placeholder='请填写观演人姓名' className={styles.input_color}/>
              </Form.Item>
            </div> : <div className={styles.fulled_in}>{performerInfo.attendeeName || ''}</div>}
          </div>
          <div className={styles.form_item}>
            <div className={styles.label}>身份证号：</div>
            {isWrite === '0' ? <div className={styles.input}>
              <Form.Item 
                name="attendeeCardNo" 
                noStyle 
                validateTrigger="blur"
                rules={[
                  { required: true, message: '请填写证件号码' },
                ]}
              >
                <Input placeholder='请填写证件号码' className={styles.input_color}/>
              </Form.Item>
            </div> : <div className={styles.fulled_in}>{performerInfo.attendeeCardNo || ''}</div>}
          </div>
          <div className={styles.form_item}>
            <div className={styles.label}>联系方式：</div>
            {isWrite === '0' ? <div className={styles.input}>
              <Form.Item 
                name="attendeeContactNum" 
                noStyle
                validateTrigger="blur"
                rules={[
                  { required: true, message: '请填写联系方式' },
                  { pattern: /^1[3-9]\d{9}$/, message: '请填写正确的手机号' },
                ]}
              >
                <Input type='tel' maxLength={11} placeholder='请填写联系方式' className={styles.input_color}/>
              </Form.Item>
            </div> : <div className={styles.fulled_in}>{performerInfo.attendeeContactNum || ''}</div>}
          </div>
        </Form>
        {isWrite === '0' && <div className={styles.btn_protocol}>
          <div className={styles.protocol_box}>
            <img className={styles.check_img} src={checkStatus ? gouxuan: weigouxuan} onClick={() => setCheckStatus((pre) => !pre)} alt="" />
            <span>我已阅读并同意</span>
            <span className={styles.protocol_name} onClick={() => history.push('/front/activities/unityPointsLottery/realNamePro')}>《实名须知》</span>
          </div>
          <div className={styles.confirm_btn} onClick={onConfirm}>确认</div>
        </div>}
      </div>
    </div>
  </div>
}

export default PerformerInfo