import React, {useEffect, useState} from 'react';
import Library from "../../../library";
import styles from './appointmentSucceed.module.scss';
import {Header} from "../../../components";
import Services from "../../../services";
import {Modal, Toast} from "antd-mobile";
import {hideLoading, showLoading} from "../../../components/loading1";
import {useLocation} from "react-router-dom";

const {JsBridge, Util} = Library;
export const AppointmentSucceed = () => { //预约成功
    // const location = useLocation();
    // const {appointActivityOrderId = '', orderType, userAccount, isHome} = location.state || {};
    const {params} = Util.url.getPramasFromUrl(window.location.href);
    const {appointActivityOrderId = '', orderType, userAccount, isHome} = params;
    const [orderStatus, setOrderStatus] = useState(0); //0=预约成功 1=取消预约 2=再次预约
    const [data, setData] = useState({});


    useEffect(() => {
        console.log(JSON.stringify(params));
        let orderTypeTem = parseInt(orderType) || 0
        if (orderTypeTem >= 0) {
            setOrderStatus(orderTypeTem);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        const res = await Services('MainPage', {code: 500005}, {appointActivityOrderId: appointActivityOrderId});
        if (res.status > 0) {
            setData(res.data || {})
        }
    }

    const cancelAptm = async () => { //取消预约
        Modal.clear();
        const res = await Services('MainPage', {code: 500007}, {appointActivityOrderId: appointActivityOrderId});
        if (res.status > 0) {
            setOrderStatus(2);
        }
    }

    const lookOrder = () => {//0=预约成功 1=取消预约 2=再次预约
        if (orderStatus === 0) {
            showLoading()
            let timer = setTimeout(() => {
                clearTimeout(timer);
                setOrderStatus(1);
                hideLoading();
            }, 1500)
        } else if (orderStatus === 1) { //取消预约
            Modal.show({
                content: <div className={styles.dialog_container}>
                    <div className={styles.dialog_title}>确认取消预约吗？</div>
                    <div className={styles.dialog_column_line}/>
                    <div className={styles.dialog_btns_container}>
                        <div className={styles.dialog_row_cancel_btn} onClick={() => Modal.clear()}>取消</div>
                        <div className={styles.dialog_row_line}/>
                        <div className={styles.dialog_row_cancel_btn} style={{color: '#CAA846'}}
                             onClick={() => cancelAptm()}>确认取消
                        </div>
                    </div>
                </div>
            })
        } else if (orderStatus === 2) {
            if (isHome && isHome === '1') {
                JsBridge.replace('Web', {uri: window.location.origin + `/front/member/appointment/activitySelection?userAccount=${userAccount}`});
            } else {
                window.JsBridge.back()
            }
        }
    }

    let navTitle = '预约成功', btnTitle = '查看预约订单', desTitle = '预约成功';
    if (orderStatus === 0) {
        navTitle = '预约成功';
        btnTitle = '查看预约订单';
        desTitle = '预约成功';
    } else if (orderStatus === 1) {
        navTitle = '预约订单详情';
        btnTitle = '取消预约';
        desTitle = '预约成功';
    } else if (orderStatus === 2) {
        navTitle = '取消预约';
        btnTitle = '再次预约';
        desTitle = '已取消';
    }

    return (
        <div className={styles.online_aptmt_succeed_container}>
            <Header midText={navTitle}/>
            <img src={require('./images/icon_aptmt_succeed.png')} className={styles.online_aptmt_succeed_logo}/>
            <p className={styles.online_aptmt_succeed_des}>{desTitle}</p>
            {orderStatus === 2 ? <p className={styles.online_aptmt_succeed_des1}>{`您已取消预约\n
如您需要再次预约可点击“再次预约”重新发起预约`}</p> : null}
            <div className={styles.online_aptmt_succeed_des_container}>
                <div className={styles.online_aptmt_succeed_des_group}>
                    <span>预约活动：</span>
                    <span>{data.activityName}</span>
                </div>
                <div className={styles.online_aptmt_succeed_des_group}>
                    <span>参加时间：</span>
                    <span>{data.participateTime}</span>
                </div>
                <div className={styles.online_aptmt_succeed_des_group}>
                    <span>活动地址：</span>
                    <span>{data.activityAddress}</span>
                </div>
                <div className={styles.online_aptmt_succeed_des_group}>
                    <span>预约时间：</span>
                    <span>{data.appointTime}</span>
                </div>
                <div className={styles.line}/>
                {
                    data.companionInfoList && data.companionInfoList.map((item) => {
                        return (
                            <div key={item.companionPhone}>
                                <div className={styles.online_aptmt_succeed_des_group}>
                                    <span>同伴姓名：</span>
                                    <span>{item.companionName}</span>
                                </div>
                                <div className={styles.online_aptmt_succeed_des_group}>
                                    <span>同伴手机号：</span>
                                    <span>{item.companionPhone}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.online_aptmt_bottom_container}>
                <div className={styles.online_aptmt_submit}
                     onClick={() => lookOrder()}>{btnTitle}</div>
            </div>
        </div>
    )
}
