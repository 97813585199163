import styles from './index.module.scss'
import React, {useState} from "react";
import smallCloseIcon from "../../routes/member/family/respectRights/images/smallCloseIcon.png";
import {Mask} from "antd-mobile";
import Library from "../../library";

const {JsBridge,} = Library;
export const TelDialog = ({isVisible, setIsVisible}) => {

    return (
        <Mask visible={isVisible} opacity={0.8}>
            <div onClick={() => {
                setIsVisible(false)
            }} className={styles.botModal} style={isVisible ? {display: 'block'} : {display: 'none'}}>
                <div className={styles.botModalInner} onClick={(event) => {
                    event.stopPropagation();
                }}>
                    <img src={smallCloseIcon}
                         className={styles.smallCloseIcon}
                         onClick={() => {
                             setIsVisible(false);
                         }}/>
                    <span className={styles.smallElement1}>
                    联系我们
                </span>
                    <span className={styles.smallElement2}>
                    总机号码：021-53011225
                </span>
                    <span className={styles.smallElement3}>
                    根据语音提示请拨分机号816
                </span>
                    <span className={styles.smallElement4} onClick={() => {
                        JsBridge.tel('021-53011225');
                    }}>
                    拨打电话
                </span>
                </div>
            </div>
        </Mask>
    )
}
