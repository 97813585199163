import React, { useEffect, useState } from 'react'

import styles from './draw.module.scss';
import Library from 'library';
import Services from 'services';
import { Actions } from "reduxs";

import icon_zongtaofang from './images/icon-zongtaofang@2x.png';
import icon_sanzhisongshu from './images/icon-sanzhisongshu@2x.png';
import JF1000 from './images/icon-1000jifen@2x.png';
import icon_promax from './images/icon-12pro max@2x.png';
import icon_watsons from './images/icon-watsons@2x.png';
import JF100 from './images/icon-100jifen.png';
import icon_haagendazs from './images/icon-haagendazs.png';
import icon_Gsuper from './images/icon-Gsuper@2x.png';
import modalBackground from './images/draw-window-winning@2x.png';
import lotteryBackground from './images/lottery.png';

const { Hooks, JsBridge } = Library;
// 积分抽奖
const buttons = [
    { type: 'gift', img: icon_Gsuper, name: '300元购物卡', value: '1', step: 0 },
    { type: 'gift', img: icon_sanzhisongshu, name: '满300减205', value: '35', step: 1 },
    { type: 'gift', img: icon_watsons, name: '30元代金券', value: '10', step: 2 },
    { type: 'gift', img: icon_haagendazs, name: '精美饰品', value: '48', step: 7 },
    { type: 'start' },
    { type: 'integral', img: JF1000, name: '500积分', step: 3 },
    { type: 'integral', img: JF100, name: '优酷VIP月卡', value: '18', step: 6 },
    { type: 'gift', img: icon_Gsuper, name: '50元购物卡', value: '888', step: 5 },
    { type: 'gift', img: icon_promax, name: '华为Mate X2 5G', value: '2', step: 4 },
];

let runner;

const stars = [];
for (var i = 0; i < 34; i++) {
    stars.push(i);
}
let hasClick = false;
export default (props) => {
    const { isPlayed } = props;
    const [showLight, setShowLight] = useState(false);
    const [status, setStatus] = useState({
        isBegin: false,
        thisStep: 0,
        endSteps: 0,
        reward: null,
        interval: 200,
        isPlayed: false
    });



    const getAward = async () => {
        const { isBegin, thisStep, isPlayed } = status;
        if (isPlayed) {
            Actions.alert.show('抽奖的机会仅限一次，您已经参与过抽奖了！');
            return;
        }
        //抽奖
        if (hasClick) {
            return;
        }
        hasClick = true;
        const fetchResult = await Services('User', { code: 101191 }, { activityCode: 'SEVENONE' });
        // const fetchResult = { status: 1, data: { index: 2, imgUrl: 'https://img.alicdn.com/imgextra/i4/O1CN01l8nEvB1j72yuThXaw_!!6000000004500-1-tps-160-120.gif' } };
        if (fetchResult.status > 0) {
            const result = fetchResult.data;
            const diff = Math.abs(8 - thisStep % 8 + (result.index));
            const endSteps = 24 + diff;
            setStatus({ ...status, isBegin: true, endSteps: endSteps, reward: result });
        }
        hasClick = false;
    }
    const getScoreRecord = () => {
        JsBridge.navigate('IntegralDetail');
    }
    const getRewardRecord = () => {
        JsBridge.open(`/taro/subPages/user/coupon/myCoupon/index`);
    }
    const startAnimation = async () => {
        let { thisStep, endSteps, interval } = status;
        if (endSteps <= 0) {
            clearTimeout(runner);
            reward();
            return;
        } else {
            endSteps = endSteps - 1;
            thisStep = thisStep + 1;
        }
        if (endSteps < 5) {
            interval = Math.min(1000, interval + 200);
        } else {
            interval = Math.max(50, interval - 200);
        }
        setStatus({ ...status, endSteps, thisStep, interval });
    }
    const reward = async () => {
        let { reward, isBegin, thisStep } = status;
        //结束抽奖，给出提示
        const thisItem = buttons.filter((item) => { return parseInt(reward.index) % 8 === item.step })[0];
        if (thisItem.type === 'none') {
            JsBridge.runAction('alert', 'error', ['很遗憾，这次没有中奖，请再试试吧！']);
            setStatus({ ...status, isBegin: false, interval: 200 });
            return;
        }
        let drawName = ''
        if (thisItem.type === 'gift') {
            drawName = `恭喜您中得${thisItem.name}\r\n可以在兑换记录中查看`
        } else {
            drawName = `恭喜您中得${thisItem.name}\r\n可以在积分明细中查看`
        }
        const result = await JsBridge.runAction('immersivemodal', 'show', [`${drawName}`, { background: window.location.origin + modalBackground, backIcon: reward.imgUrl },
        [{
                name: '查看详情',
                style: { color: '#999' }
        }]]);
        if (result === '查看详情') {
            JsBridge.runAction('immersivemodal', 'hide', []);

            if (reward.type === 'integral') {
                JsBridge.navigate('IntegralDetail');
            } else {
                getRewardRecord();
            }
        } else {
            JsBridge.runAction('immersivemodal', 'hide', []);
        }
        setStatus({ ...status, isBegin: false, interval: 200, isPlayed: true });
    }

    useEffect(() => {
        if (isPlayed) {
            setStatus({ ...status, isPlayed: isPlayed });
        }
    }, [isPlayed]);

    const savedCallback = React.useRef();
    useEffect(() => {
        savedCallback.current = startAnimation;
    });

    Hooks.useInterval(() => {
        setShowLight(!showLight);
    }, 1000);

    useEffect(() => {
        if (status.isBegin) {
            setTimeout(savedCallback.current, status.interval);
        }
    }, [status])

    return (
        <div>
            <div className={styles.drawWraps}>
                <div className={showLight ? `${styles.drawBorder}` : `${styles.drawBorder} ${styles.light}`}>
                    {stars.map((item, key) => {
                        return <i key={key} className={styles.circle}></i>;
                    })}
                    {status.isBegin ? <div className={styles.drawMask}></div> : false}
                    {buttons.map((button, key) => {
                        if (button.type === 'start') {
                            return <div className={styles.drawStart} key={key} onClick={getAward}>
                                <h2>开始抽奖</h2>
                            </div>
                        }
                        return <div onClick={() => {
                            if (button.click && typeof (button.click === 'function')) {
                                button.click();
                            }
                        }} className={status.thisStep % 8 === button.step ? `${styles.drawButton} ${styles.active}` : `${styles.drawButton}`} key={key}>
                            <img src={button.img} alt='奖品' />
                            <p>{button.name}</p>
                        </div>;
                    })}

                </div>
                <div className={styles.clickArea}>
                    <div className={styles.click} onClick={getScoreRecord}></div>
                    <div className={styles.click} onClick={getRewardRecord}></div>
                </div>
            </div>
        </div>
    )
}
