import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory } from 'react-router-dom';
import styles from './calendar.module.scss';
import back from '../images/back_normal.png'
import backDisable from '../images/back_disable.png';
import next from '../images/next_normal.png';
import nextDisable from '../images/next_disable.png';
const { Util } = Library;
const arrWeek = ['日','一','二','三','四','五','六']
const now = new Date();
export const Calendar = (props) => {
    const [arrDay,setArrDay] = useState([]);
    const [currentMonth,setCurrentMonth] = useState(now.getMonth());
    const [currentDay,setCurrentDay] = useState('');
    useEffect(() => {
        getArr();
    }, [currentMonth,props.data]);

    const getArr = ()=>{
        let arrData = props.data;
        let date = new Date(now.getFullYear(),currentMonth);
        let days = new Date(now.getFullYear(),currentMonth+1,0).getDate();
        let day = date.getDay();//星期0-6对应日-六
        let arr = [];
        for (let i=0;i<days+day;i++) {
            if (i < day) {
                arr.push({day:'',status:0})
            } else {
                let dayInt = i-day+1;
                let item = {day:dayInt+''};
                let status = 0;
                for (let data of arrData) {
                    let {date:strDate,meal = []} = data;
                    let d = parseInt(strDate.substr(8,2),10);
                    let m = parseInt(strDate.substr(5,2),10);
                    if (m === currentMonth + 1 && d === dayInt) {
                        let isFull = true;
                        status = 1;
                        item.date = strDate;
                        for (let o of meal) {
                            if (o.appoint) {
                                isFull = false;
                                break;
                            }
                        }
                        if (isFull) {
                            status = 2;
                        }
                        break;
                    }
                }
                item.status = status;
                arr.push(item);
            }
        }
        setArrDay(arr);
    }
    const dateSelect = new Date(now.getFullYear(),currentMonth);
    const isCurrentMonth = currentMonth === now.getMonth();//是否当月
    return <div className={styles.container}>
        <div className={styles.header}>
            <div className={styles.monthHeader}>
                <div className={styles.btnPrevious} onClick={()=>{
                    if (!isCurrentMonth) {
                        setCurrentMonth(currentMonth - 1);
                    }
                }}>
                    <img className={styles.imgBack} src={isCurrentMonth?backDisable:back}/>
                    <p className={`${styles.btnText} ${isCurrentMonth?styles.disable:''}`}>上一个月</p>
                </div>
                <p className={styles.monthText}>{dateSelect.getMonth()+1}月</p>
                <div className={styles.btnNext} onClick={()=>{
                    if (isCurrentMonth) {
                        setCurrentMonth(currentMonth + 1);
                    }
                }}>
                    <p className={`${styles.btnText} ${!isCurrentMonth?styles.disable:''}`}>下一个月</p>
                    <img className={styles.imgNext} src={isCurrentMonth?next:nextDisable}/>
                </div>
            </div>
            <div className={styles.weekHeader}>
                {arrWeek.map((item,index)=>{
                    return <p key={index} className={`${styles.weekText} ${index===0 || index === 6?styles.disable:''}`}>{item}</p>
                })}
            </div>
        </div>
        <p className={styles.dateText}>{Util.format.time(dateSelect,'yyyy-MM')}</p>
        <ul className={styles.dayWrap}>
            {arrDay.map((item,index)=>{
                let {day,status,date=''} = item;
                let statusStyle = '';
                if (status === 1) {
                    statusStyle = styles.enable;
                    if (day.length > 0 && day === currentDay) {
                        statusStyle = styles.select;
                    }
                } else if(status === 2) {
                    statusStyle = styles.disable;
                }

                return (
                    <li key={index} className={styles.day} onClick={()=>{
                        if (status === 1) {
                            setCurrentDay(day);
                            props.onSelect && props.onSelect(date);
                        }
                    }}>
                        <div className={`${styles.content} ${statusStyle}`}>
                            <p className={`${styles.dayText} ${statusStyle}`}>{day}</p>
                        </div>
                        {status > 0?<p className={styles.tip}>{status === 1?'可约':'约满'}</p>:null}
                    </li>
                )
            })}
        </ul>
    </div>;
};
