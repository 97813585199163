import configureStore, { history } from './configureStore';
import configureActions from './bindActionCreators.js';

let storageStatus = {};
try {
    storageStatus = JSON.parse(localStorage.getItem('GU_STORAGE_INFO'));
} catch (ex) {
    console.warn('读取storage数据异常');
}

const initialState = storageStatus || window.initialState || {};
export const Store = configureStore(initialState);
export const Actions = configureActions(Store.dispatch);
export const History = history;
