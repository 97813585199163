/**
 * 每日签到 兑换商品
 */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';
import Library from 'library';
import { SignModal } from '../signModal';
import {isEmptyString} from "library/util";

const { Platform, JsBridge, Util } = Library;
export const Exchange = (props) => {
    const history = useHistory();

    const { maxAmount = 90 } = props.state || {};
    const [arrSource, setArrSource] = useState([{},{},{},{},{}]);
    const [isMust, setIsMust] = useState(false);//是否必须兑换
    const [num, setNum] = useState(0);
    const [visible, setVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const getList = async () => {
        const result = await Services('MainPage', { code: '101221' }, {});
        if (result.status > 0) {
            let { fragmentAmount, pointLotteryList } = result.data;
            setIsMust(fragmentAmount >= (result.data.maxAmount ?? maxAmount));
            setNum(fragmentAmount);
            setArrSource(pointLotteryList);
        }
    }

    useEffect(() => {
        getList()
    }, []);

    const shiWuChange=(item)=>{ //实物兑换
        if (isEmptyString(item.goodsId)){
            return;
        }
        const params = {
            pointsGoodsId: item.goodsId,
            num: 1,
        };
        const stringParams = JSON.stringify(params);
        JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-A/customer/user/points-confirm/index?params=${stringParams}`});
    }

    const onExchange = async (item) => {
        if (item.goodsType===0){ //实物
            // 判断是否是实物
            shiWuChange(item);
            return;
        }
        const noCaptcha = await JsBridge.runAction('noCaptchaModal', 'show', [{}]).catch(() => {
            return false;
        });
        if (noCaptcha) {
            let captchaResult = JSON.parse(noCaptcha);
            let res = !captchaResult.captcha ? { captcha: JSON.stringify(captchaResult) } : captchaResult;
            let { goodsId, lotteryId, productMerchant } = item;
            
            let result = productMerchant === 1 ? await Services('MainPage', { code: '101177' }, { productId: goodsId, exchangeMethod: 2, activityId: lotteryId, ...res })
                : await Services('MainPage', { code: '101176' }, { goodsId: goodsId, exchangeMethod: 2, activityId: lotteryId, ...res })
            if (result.status > 0) {
                
                let nftJumpUrl = result.data?.nftJumpUrl || ''
                if (!isEmptyString(nftJumpUrl)){
                    history.push(`/front/activities/dailySignin/index/exchangeSuccess?nftJumpUrl=${nftJumpUrl}`);
                }else {
                    history.push('/front/activities/dailySignin/index/exchangeSuccess');
                }
            }
        }

    }
    return <div className={styles.exchange_container}>
        <div className={styles.contentBg}>
            <div className={styles.header}>
                <div className={styles.topTitle}>
                    礼物兑换
                    <span className={styles.debris}>可用碎片{num}个</span>
                </div>
                {
                    isMust &&
                    <div className={styles.tip}>{'兑换完方可继续签到哦'}</div>
                }
            </div>
            <div className={styles.wrap}>
                <ul className={styles.list}>
                    {arrSource.map((item, index) => {
                        let { fragment, imgUrl, goodsName } = item;
                        return <li key={index} className={styles.itemWrap}>
                            <div className={styles.item}>
                                <div className={styles.imgBg}>
                                    <img src={imgUrl} className={styles.img} alt='产品图片' />
                                </div>
                                <p className={styles.text}>{goodsName}</p>
                                <button className={styles.btn} onClick={() => {
                                    //source  商品来源：gu：gu自营  emall：商城; goodsType 商品类型，0：实体商品，1：虚拟商品
                                    // 碎片规格切换改版
                                    JsBridge.print(item)
                                    if(item.source === 'emall'){
                                        // 商城商品
                                        const params = {
                                            skuId: item.productId,
                                            pointsGoodsId: item.goodsId,
                                            fragment: item.fragment //碎片数量，用于判断是否为碎片商品
                                        }
                                        JsBridge.push('Web', {uri: `${process.env.REACT_APP_MALL_LINK}/mobile/pages/package-B/goods/goods-details/index?${Util.url.objectToQueryParams(params)}`});
                                    }else {
                                        // gu商品
                                        if (fragment > num) {
                                            JsBridge.runAction('alert', 'error', ['兑换碎片不足，无法兑换'])
                                            return;
                                        }
                                        setCurrentItem(item);
                                        setVisible(true)
                                    }
                                    
                                }}>
                                    <p className={styles.textNum}>{fragment}碎片</p>
                                    <p className={styles.exchange}>兑换</p>
                                </button>
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>
        <SignModal
            visible={visible}
            content={currentItem ? `是否用${currentItem.fragment}兑换碎片兑换${currentItem.goodsName}？` : ''}
            title={'确认兑换'}
            buttons={[{
                name: '不兑换', click: () => {
                    setVisible(false);
                },
                className: 'cancel',
            }, {
                name: '确认兑换', click: () => {
                    onExchange(currentItem);
                    setVisible(false);
                },
                className: 'confirm',
            }]}
        />
    </div>
}
