
import React, { Fragment, useMemo, useState } from 'react';
import { Form, Radio, Checkbox, Space, TextArea, Input } from "antd-mobile";
import { sequenceKeys } from '../../index/config'
import './index.scss';

const InputLimt = 10
//控制input框是否显示输入数量限制
const InputShowCount = false

const QuestionItem = (props) => {

    const { form, item } = props

    const selectOptionValue = Form.useWatch(`question${item.index}`, form)

    const question = useMemo(() => {
        let title = `${item.index + 1}. ${item.title}`
        if (item.multiple == 1 && ['input','textarea'].includes(item.type)==false) {
            title += '（单选）'
        } else if (item.multiple == 0 && ['input','textarea'].includes(item.type)==false) {
            title += '（多选）'
        }
        return title
    },[])

    const renderOptions = () => {
        let elment = null
        if (item.type == 'input') {
            if (item.options && item.options.length > 1) {
                elment = (
                    <Space direction='vertical'>
                        <Form.Array
                            name={`question${item.index}`}
                            initialValue={item.options}
                        >
                            {
                                fields => fields.map(({ index }) => {
                                    const option = item.options[index]
                                    return (
                                        <Form.Item
                                            name={[index, 'content']}
                                            noStyle
                                            rules={[{ required: option.required, message: `第${item.index + 1}题有必填项您还没填写` }]}
                                        >
                                            {
                                                InputShowCount ?
                                                <TextArea className='input-textarea' maxLength={InputLimt} rows={1} showCount placeholder={option.required ? '必填' : '选填'} />
                                                :<Input placeholder={option.required ? '必填' : '选填'} />
                                            }
                                        </Form.Item>
                                    )

                                })
                            }
                        </Form.Array>
                    </Space>
                )
            }else {
                elment = InputShowCount ?
                    <TextArea className='input-textarea' maxLength={InputLimt} rows={1} showCount placeholder={'请输入'} />
                    :<Input placeholder={'请输入'} />
            }
            
        } else if (item.type == 'textarea') {
            elment = (
                <TextArea maxLength={100}
                    rows={4}
                    showCount
                    placeholder={'请输入：'}
                    autoSize={true}
                />
            )
        } else if (item.multiple == 1) {
            //单选
            elment = (
                <Radio.Group>
                    <Space direction='vertical'>
                        {
                            item.options &&
                            item.options.map((option, i) => {
                                return (
                                    <Fragment key={i}>
                                        <Radio
                                            icon={() => <></>}
                                            value={option.idf}
                                        >
                                            {`${sequenceKeys[i]}、${option.title}`}
                                        </Radio>
                                        {
                                            option.mark == 'other' && 
                                            (selectOptionValue == option.idf || item.answer?.content) &&
                                            <Form.Item
                                                noStyle
                                                name={`content-${item.index}-${i}`}
                                                initialValue={item.answer?.content}
                                                rules={[{ required: option.required, message: `第${item.index + 1}题有必填项您还没填写` }]}
                                            >
                                                {
                                                    InputShowCount ?
                                                    <TextArea className='other-textarea' maxLength={InputLimt} rows={1} showCount placeholder={option.required ? '必填' : '选填'} />
                                                    :<Input className='other-input' placeholder={option.required ? '必填' : '选填'}></Input>
                                                }
                                            </Form.Item>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </Space>
                </Radio.Group>
            )
        } else if (item.multiple == 0) {
            //多选
            elment = (
                <Checkbox.Group>
                    <Space direction='vertical'>
                        {
                            item.options &&
                            item.options.map((option, i) => {
                                return (
                                    <Fragment key={i}>
                                        <Checkbox
                                            icon={() => <></>}
                                            value={option.idf}
                                        >
                                            {`${sequenceKeys[i]}、${option.title}`}
                                        </Checkbox>
                                        {
                                            option.mark == 'other' && selectOptionValue?.includes(option.idf) &&
                                            <Form.Item
                                                noStyle
                                                name={`content-${item.index}-${i}`}
                                                initialValue={option.content}
                                                rules={[{ required: option.required, message: `第${item.index + 1}题有必填项您还没填写` }]}
                                            >
                                                <Input className='other-input' placeholder={option.required ? '必填' : '选填'}></Input>
                                            </Form.Item>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </Space>
                </Checkbox.Group>
            )
        }
        return elment
    }

    const inputOption = item.type == 'input' && item.options?.length > 1
    const initValue = ()=> {
        if(Object.prototype.toString.call(item.answer) == '[object Object]') {
            return item.answer.value == null ? undefined: item.answer.value
        }else {
            return item.answer == null ? undefined: item.answer
        }
    }
    return (
        <div className="question-item">
            <Form.Item
                name={inputOption ? undefined: `question${item.index}`}
                label={<span className={`question-title ${item.required ? 'require' : ''}`}>{question}</span>}
                hasFeedback={false}
                initialValue={ inputOption ? undefined: initValue() }
                rules={[
                    {
                        required: inputOption ? false : item.required,
                        message: `第${item.index + 1}题为${['input', 'textarea'].includes(item.type) ? '必填' : '必选'},您还没有${['input', 'textarea'].includes(item.type) ? '填写' : '选择'}`
                    },
                ]}
            >
                {renderOptions()}
            </Form.Item>
        </div>
    )
}

export default QuestionItem;