import { ChangeTitle } from './title';
// import { Captcha } from './captcha';
// import { TxCaptcha as Captcha} from './txCaptcha';
import { Share } from './share';
import { Loading } from './loading';
import { Alert } from './alert';
import { Modal } from './modal';
import { ShouldOpenInWechat } from './shouldOpenInWechat';
import { Slogan } from './slogan';
import Header from './header/index';
import TopNavigation from './topNavigation';
import ListView from './AntListView/index';
import ModalAlert from './ModalAlert';
import RulesModal from './activityRuleModal/rulesModal';
import RuleModal from './ruleModal/rulesModal';
import RecordModal from './recordModal/recordModal';

export default {
    ChangeTitle, Share, Loading, Alert, Modal, ShouldOpenInWechat, Slogan
};

export {
    Header,
    TopNavigation,
    ListView,
    ModalAlert,
    RulesModal,
    RuleModal,
    RecordModal
}
