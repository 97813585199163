import React, {useEffect, useRef, useState} from "react";
import egg_right_btn from "../../../../images/egg_right_btn.png";
import styles from "./eggBaomingContentComponent.module.scss";
import EggBaomingDialog from "./eggBaomingDialog";
import egg_bt_lijibaoming_liang from "../../../../images/egg_bt_lijibaoming.png";
import egg_bt_lijibaoming_hui from 'images/egg_bt_lijibaoming_hui.png';
//立即报// 名 灰色 大按钮
import Library from "../../../../library";
import Services from "../../../../services";
import {Toast} from "antd-mobile";
import {isEmptyString} from "../../../../library/util";
import EggStockRecordPop from "./eggStockRecordPop";


const {JsBridge} = Library;


// const currentState = 0;// 0=活动未开始 1=报名阶段 2=报名截止,未开始第一轮抽签 3=第一轮抽签 4=第一轮抽签结束,未开始第二轮抽签, 5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
// const currentOperate = 0 //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 5.第一轮中签超时未支付 100=影藏款直接领取
//报名按钮的父类 总是动态变化 所以要抽出来
export const EggBaomingContentComponent = ({userInfo, jieduanInfo, refreshData}) => {
    const [baoMingShow, setBaoMingShow] = useState(false);
    const [isActivate, setIsActivate] = useState(false);

    let {
        currentState,
        currentOperate,
        remainingPurch = 0,
        lotteries = 0,
        nftPetPeriod = {},
        sysTime,
        stock
    } = jieduanInfo; //lotteries当前用户还有多少劵
    let {isLogin, ID} = userInfo;
    let {
        randomStr, //随机字符串
        regStartTime, //活动开始时间
        regEndTime, //报名结束时间
        lotteryStartTime, //第一轮中签开始时间
        lotteryEndTime, //第一轮中签结束时间
        secondLotteryStartTime, //第二轮开始时间
        secondLotteryEndTime,//第二轮结束时间
        publicSaleStartTime,//公开售开始时间
        publicSaleEndTime //公开售结束时间
    } = nftPetPeriod;

    // currentState = 0;
    // sysTime = '2022-09-22 16:02:00'
    // regStartTime = '2022-09-22 16:03:00';
    // regEndTime = '2022-09-22 16:04:00';
    // isLogin = true;
    // currentState = 1;
    // currentOperate = 0;
    // remainingPurch = 3; didBlur

    const goLogin = async () => {
        JsBridge.goLogin().then();
        JsBridge.addListener().then((result) => {
            refreshData && refreshData();
        });
    }
    const goBaoMing = () => { //去报名
        if (!isLogin) {
            JsBridge.goLogin().then();
            JsBridge.addListener().then((result) => {
                refreshData && refreshData();
            });
            return;
        }

        if (isEmptyString(userInfo.ID)) { //未实名
            JsBridge.addListener().then(() => { //实名认证回来
                refreshData && refreshData();
            });
            JsBridge.navigate('Verify');
            return;
        }

        let param = {
            clientId: userInfo.clientId,
            randomStr: jieduanInfo.randomStr,
        }
        Services('MainPage', {code: '600685'}, param).then((res) => {
            if (res.status > 0) {
                Toast.show('报名成功!')
                refreshData && refreshData();
                setBaoMingShow(!baoMingShow);
            }
        });
    }
    const goBuy = () => { //去购买
        JsBridge.addListener().then(() => { //购买回来
            refreshData && refreshData();
        });
        Services('MainPage', {code: 600066}, {source: 'app'}).then((res) => {
            const {status, data} = res;
            if (status > 0) {
                let token = data.token;
                if (!isEmptyString(token)) {
                    let uri = `${process.env.REACT_APP_NFT_LINK}/apeWorldSaleDetail?currentState=${currentState}&currentOperate=${currentOperate}&remainingPurch=${remainingPurch}&t=${token}&stock=${stock}&isLogin=1`;
                    JsBridge.push('CustomWeb', {uri: uri});
                }
            }
        });
    }

    const baomingBtnView0 = () => { //未登录
        if (currentState === 0) { //活动未开始
            return activityNotStartView();
        } else if (currentState === 1) { //1=报名阶段
            return (
                <>
                    <div className={styles.choujuan_count_father}></div>
                    <div className={styles.btn_baoming_father} onClick={() => goLogin()}>
                        <img src={egg_bt_lijibaoming_liang}/>
                        <div className={styles.btn_baoming_title_father}>
                            <div className={styles.btn_baoming_title0}>立即报名</div>
                            <div className={styles.btn_baoming_title1}>报名用户即送Ape World 纪念藏品一款</div>
                        </div>
                    </div>
                    <CountDownComponent sysTime={sysTime} endTime={regEndTime} des={'后报名活动结束'}
                                        refreshData={refreshData}/>
                </>
            )
        } else { //其他状态都是去登陆 除非是公开售阶段
            return (
                <>
                    <div className={styles.choujuan_count_father}></div>
                    <div className={styles.btn_baoming_father} onClick={() => goLogin()}>
                        <img src={egg_bt_lijibaoming_hui}/>
                        <div className={styles.btn_baoming_title_father}>
                            <div className={styles.btn_baoming_title0_hui}>去登陆</div>
                        </div>
                    </div>
                    <div className={styles.go_login_des}>登录APP方可参加活动购买数字藏品</div>
                </>
            )
        }
    }

    const baomingBtnView1 = () => { //已登录
        if (currentState === 0) { //活动未开始
            return activityNotStartView();
        } else if (currentState === 1) { //1=报名阶段 (用户已登录的情况下 用户就两个状态(用户报名和未报名))
            if (currentOperate === 0) { //用户未报名
                return (
                    <>
                        <div className={styles.choujuan_count_father}></div>
                        <div className={styles.btn_baoming_father} onClick={() => goBaoMing()}>
                            <img src={egg_bt_lijibaoming_liang}/>
                            <div className={styles.btn_baoming_title_father}>
                                <div className={styles.btn_baoming_title0}>立即报名</div>
                                <div className={styles.btn_baoming_title1}>报名用户即送Ape World 纪念藏品一款</div>
                            </div>
                        </div>
                        <CountDownComponent sysTime={sysTime} endTime={regEndTime} des={'后报名活动结束'}
                                            refreshData={refreshData}/>
                    </>
                )
            } else if (currentOperate === 1) {  //1：已报名 等待首轮中签结果
                return userLoginBMWaitFirstResultView()
            }

        } else if (currentState === 2) {  // 报名截止,未开始第一轮抽签
            if (currentOperate === 0) { //用户未报名 等待公开售
                return userLoginNotBMWaitPublicSaleView();
            } else if (currentOperate === 1) {//用户已报名 等待开始首轮中签结果
                return userLoginBMWaitFirstResultView();
            }
        } else if (currentState === 3) { //第一轮抽签阶段
            if (currentOperate === 0) { //用户未报名 待开启公开售
                return userLoginNotBMWaitPublicSaleView();
            } else if (currentOperate === 2) { // 2未中签，
                return userLoginNotZhongQianWaitSecondResultView()
            } else if (currentOperate === 3 || currentOperate === 4) { // 3中签未支付，4中签已支付
                return userLoginZhongQianBuyStateView(currentState);
            }
        } else if (currentState === 4) { //4=第一轮抽签结束,未开始第二轮抽签
            if (currentOperate === 0) { //用户未报名
                return userLoginNotBMWaitPublicSaleView();
            } else if (currentOperate === 2) { //用户已经报名 没中签
                return userLoginNotZhongQianWaitSecondResultView();
            } else if (currentOperate === 3) { //已中签 3中签未支付(公开售)，4中签已支付 (公开售)
                return userLoginZhongQianNotPayWaitPublicView();
            } else if (currentOperate === 4) {
                return userLoginZhongQianNotPayOutTimeWaitPublicView();
            } else if (currentOperate === 5) { //超时未支付 去等待公开售
                return userLoginZhongQianNotPayWaitPublicView();
            }
        } else if (currentState === 5) {  //5=第二轮抽签 6第二轮抽签结束,未开始公开售 7=公开售阶段 8=活动结束
            if (currentOperate === 0) { //用户未报名
                return userLoginNotBMWaitPublicSaleView();
            } else if (currentOperate === 2) { //用户已经报名 没中签 等待公开售
                // const currentOperate = 0 //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 5.第一轮中签超时未支付 100=影藏款直接领取
                return (
                    <>
                        <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                        <div className={styles.btn_baoming_father}>
                            <img src={egg_bt_lijibaoming_hui}/>
                            <div className={styles.btn_baoming_title_father}>
                                <div className={styles.btn_baoming_title0_hui}>待开启公开售</div>
                            </div>
                        </div>
                        <div className={styles.not_baoming_des}>很遗憾！您未中奖!</div>
                        <CountDownComponent sysTime={sysTime} endTime={publicSaleStartTime} des={'后开启公开售'}
                                            refreshData={refreshData}/>
                    </>
                )
            } else if (currentOperate === 3 || currentOperate === 4) { //已中签 3中签未支付，4中签已支付
                return userLoginZhongQianBuyStateView(currentState);
            } else if (currentOperate === 5) { //第一轮已中签 但是超时没支付 不能参加第二轮中签 直接等待公开售
                return userLoginZhongQianNotPayWaitPublicView()
            }
        } else if (currentState === 6) { //直接公开售
            if (currentOperate === 0) { //用户未报名
                return userLoginNotBMWaitPublicSaleView();
            } else if (currentOperate === 2) { //用户已经报名 没中签 等待公开售
                // const currentOperate = 0 //0:未报名，1：已报名2，未中签，3中签未支付，4中签已支付 5.第一轮中签超时未支付 100=影藏款直接领取
                return (
                    <>
                        <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                        <div className={styles.btn_baoming_father}>
                            <img src={egg_bt_lijibaoming_hui}/>
                            <div className={styles.btn_baoming_title_father}>
                                <div className={styles.btn_baoming_title0_hui}>待开启公开售</div>
                            </div>
                        </div>
                        <div className={styles.not_baoming_des}>很遗憾！您未中奖!</div>
                        <CountDownComponent sysTime={sysTime} endTime={publicSaleStartTime} des={'后开启公开售'}
                                            refreshData={refreshData}/>
                    </>
                )
            } else if (currentOperate === 3) { //已中签 3中签未支付，4中签已支付
                return userLoginZhongQianNotPayWaitPublicView();
            } else if (currentOperate === 4) { //4中签已支付 置灰状态
                return userLoginZhongQianNotPayOutTimeWaitPublicView();
            } else if (currentOperate === 5) { //第一轮已中签 但是超时没支付 不能参加第二轮中签 直接等待公开售
                return userLoginZhongQianNotPayWaitPublicView()
            }
        } else {
            return null;
        }
    }

    const userLoginZhongQianNotPayOutTimeWaitPublicView = () => { // 中签未支付 超时,待开启公开售 新状态
        return (
            <>
                <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}
                             style={{letterSpacing: '2px'}}>待开启公开售
                        </div>
                    </div>
                </div>
                <div className={styles.not_baoming_des}/>
                <CountDownComponent sysTime={sysTime} endTime={publicSaleStartTime} des={'后开启公开售'}
                                    refreshData={refreshData}/>
            </>
        )

    }

    const userLoginZhongQianNotPayWaitPublicView = () => { //登录 中签 超时未支付
        return (
            <>
                <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}
                             style={{letterSpacing: '2px'}}>待开启公开售
                        </div>
                    </div>
                </div>
                <div className={styles.not_baoming_des}>很遗憾！中签超时未支付</div>
                <CountDownComponent sysTime={sysTime} endTime={publicSaleStartTime} des={'后开启公开售'}
                                    refreshData={refreshData}/>
            </>
        )

    }


    const userLoginBMWaitFirstResultView = () => { //用户已经登录 已经报名 在报名期间 等待公布首轮中签结果
        return (
            <>
                <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}
                             style={{letterSpacing: '2px'}}>待公布首轮中签结果
                        </div>
                    </div>
                </div>
                <CountDownComponent sysTime={sysTime} endTime={lotteryStartTime} des={'后公布首轮中奖结果'}
                                    refreshData={refreshData}/>
            </>
        )
    }

    const userLoginNotBMWaitPublicSaleView = () => { //用户已登录 未报名 在第一轮抽签阶段 等待公开售
        return (
            <>
                <div className={styles.choujuan_count_father}></div>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}
                             style={{letterSpacing: '2px'}}>待开启公开售
                        </div>
                    </div>
                </div>
                <div className={styles.not_baoming_des}>很遗憾！您未参与报名!</div>
                <CountDownComponent sysTime={sysTime} endTime={publicSaleStartTime} des={'后开启公开售'}
                                    refreshData={refreshData}/>
            </>
        )
    }

    //用户第一轮 第二轮中签立即购买的状态 只能买3个 剩余购买次数remainingPurch 上限之后按钮要变灰
    const userLoginZhongQianBuyStateView = (currentStateTem) => {
        let endTime = 0;
        if (currentStateTem === 3) { //第一轮
            endTime = lotteryEndTime;
        } else if (currentStateTem === 5) { //第二轮
            endTime = secondLotteryEndTime;
        }
        return (
            <>
                <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                <div className={styles.btn_baoming_father} onClick={() => remainingPurch > 0 ? goBuy() : null}>
                    <img src={remainingPurch > 0 ? egg_bt_lijibaoming_liang : egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div
                            className={remainingPurch > 0 ? styles.btn_baoming_title0 : styles.btn_baoming_title0_hui}>{remainingPurch > 0 ? '立即购买' : '购买次数已上限'} </div>
                        <div
                            className={remainingPurch > 0 ? styles.btn_baoming_title1 : styles.btn_baoming_title1_hui}>每人仅一次中签机会
                        </div>
                    </div>
                </div>
                <div className={styles.not_baoming_des}>{`恭喜您！已中签!(剩余购买数${remainingPurch}次)`}</div>
                <CountDownComponent sysTime={sysTime} endTime={endTime} des={'后特价购结束'} refreshData={refreshData}/>
            </>
        )
    }

    const userLoginNotZhongQianWaitSecondResultView = () => { //用户第一轮未中签 等待待公布第二轮中奖结果
        return (
            <>
                <ChouQianJuanView jieduanInfo={jieduanInfo} userInfo={userInfo}/>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}
                             style={{letterSpacing: '2px'}}>待公布第二轮中奖结果
                        </div>
                    </div>
                </div>
                <div className={styles.not_baoming_des}>很遗憾！您未中奖!</div>
                <CountDownComponent sysTime={sysTime} endTime={secondLotteryStartTime} des={'后公布第二轮中奖结果'}
                                    refreshData={refreshData}/>
            </>
        )
    }

    const activityNotStartView = () => { //活动未开始,敬请期待
        return (
            <>
                <div className={styles.choujuan_count_father}></div>
                <div className={styles.btn_baoming_father}>
                    <img src={egg_bt_lijibaoming_hui}/>
                    <div className={styles.btn_baoming_title_father}>
                        <div className={styles.btn_baoming_title0_hui}>敬请期待</div>
                        <div className={styles.btn_baoming_title1_hui}>报名用户即送Ape World 纪念藏品一款</div>
                    </div>
                </div>
                <CountDownComponent sysTime={sysTime} endTime={regStartTime} des={'后报名活动开始'}
                                    refreshData={refreshData}/>
            </>
        )
    }


    const baomingBtnStateView = () => { //报名按钮
        if (!isLogin) { //未登录
            return baomingBtnView0();
        } else { //用户已登录
            return baomingBtnView1();
        }
    }

    if (currentState === 7 || currentState === 8) {
        return null;
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.title_des}>报名集抽签券得特价藏品购买资格</div>
                <div className={styles.title_des1}>拥有越多抽签券，中签概率越大</div>
                {baomingBtnStateView()}
                <EggBaomingDialog maskVisible={baoMingShow} setMaskVisible={setBaoMingShow}/>
            </div>
        </>
    )
}

//倒计时
const CountDownComponent = ({sysTime, endTime, des, refreshData}) => {
    const initalTime = {day: '00', hour: '00', minute: '00', second: '00'};
    const [countTime, setCountTime] = useState(initalTime);
    let timeStampCurrent = useRef(null).current;
    let timerCurrent = useRef().current;
    let oldIsActivateRef = useRef(true).current; //默认在前台

    const countDown = (currentTime, endTime, callBack) => {
        // JsBridge.print('countDown')
        if (!currentTime || !endTime) {
            callBack && callBack()
            return initalTime;
        }
        const currentTimeStamp = Date.parse(currentTime.replace(/-/g, '/'))
        if (timeStampCurrent == null) {
            timeStampCurrent = currentTimeStamp
        }
        const endTimeStamp = Date.parse(endTime.replace(/-/g, '/'))
        const distanceStamp = endTimeStamp - timeStampCurrent;
        if (distanceStamp <= 0) {
            callBack && callBack()
            return initalTime;
        }
        let remianAllSeconds = Math.floor(distanceStamp / 1000);//剩余总秒数
        let day = Math.floor(remianAllSeconds / (60 * 60 * 24));//剩余天数
        let hour = Math.floor(remianAllSeconds / (60 * 60) % 24);//剩余小时数
        let minute = Math.floor(remianAllSeconds / 60 % 60);//剩余分钟数
        let second = Math.floor(remianAllSeconds % 60);//剩余秒数

        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;
        return {day: day, hour: hour, minute: minute, second: second};
    }

    const timerStart = () => {
        timerCurrent && clearInterval(timerCurrent); //先清空之前的定时器
        // console.log(timerCurrent)
        timerCurrent = setInterval(() => {
            // Jsbridge.print('setInterval---')
            // console.log(JSON.stringify(timerCurrent))
            if (timeStampCurrent) {
                timeStampCurrent = timeStampCurrent + 1000;
            }
            let obj = countDown(sysTime, endTime, () => {
                timerCurrent && clearInterval(timerCurrent);
                refreshData && refreshData();
            });
            setCountTime(obj);
        }, 1000)
    }

    const visibilitychange = () => {
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) { //进入后台
                // timerCurrent && clearInterval(timerCurrent); //先清空之前的定时器
                clearAllTimer();
                // Jsbridge.print('进入了后台')
            } else { //进入前台
                // Jsbridge.print('进入了前台')
                refreshData && refreshData();
            }
        });
    }

    useEffect(() => {
        visibilitychange();
    }, [])

    useEffect(() => {
        timerStart();
        return () => {
            timerCurrent && clearInterval(timerCurrent); //先清空之前的定时器
        }
    }, [sysTime, endTime])
    const {day, hour, minute, second} = countTime;
    return (
        <div className={styles.countdown_time_father}>
            <span className={styles.time_span}>{day}</span>
            <span className={styles.time_span_des}>天</span>
            <span className={styles.time_span}>{hour}</span>
            <span className={styles.time_span_des}>时</span>
            <span className={styles.time_span}>{minute}</span>
            <span className={styles.time_span_des}>分</span>
            <span className={styles.time_span}>{second}</span>
            <span className={styles.time_span_des}>{`秒${des}`}</span>
        </div>
    )
}
const ChouQianJuanView = ({jieduanInfo, userInfo}) => { //jieduanInfo
    const [showChouQianView, setShowChouQianView] = useState(false);
    const {lotteries} = jieduanInfo;
    return (
        <>
            <div className={styles.choujuan_count_father} onClick={() => setShowChouQianView(!showChouQianView)}>
                <span className={styles.choujuan_count_des}>您有</span>
                <span className={styles.choujuan_count}>{lotteries}</span>
                <span className={styles.choujuan_count_des}>张抽签劵</span>
                <img src={egg_right_btn} alt=""/>
            </div>
            <EggStockRecordPop maskVisible={showChouQianView} setMaskVisible={setShowChouQianView} userInfo={userInfo}/>
        </>
    )
}

const clearAllTimer = () => { //清除所有的定时器
    let end = setInterval(function () {
    }, 3000);
    for (let i = 1; i <= end; i++) {
        i && clearInterval(i);
    }
}
