import React from "react";
import styles from './styles.module.scss';

export default ({
  img = '',
  text = '',
  count = 20,
}) => (
  <div className={styles.container}>
    <img src={img} />
    <div className={styles.box}>
      <span>集赞{count}得：</span>
      <span>小黄人折叠购物袋</span>
    </div>
  </div>
)