import React, { useEffect, useRef, useState } from 'react';
import Library from 'library';
import Services from 'services';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Config } from './config';
import styles from './home.module.scss';
import imageBg from './images/home_bg@2x.png';
import arrow from './images/arrow.png';
import rank1 from './images/rank1.png';
import rank2 from './images/rank2.png';
import rank3 from './images/rank3.png';
import rank4 from './images/rank4.png';
import rank5 from './images/rank5.png';
import rank6 from './images/rank6.png';
import rank7 from './images/rank7.png';
import rank8 from './images/rank8.png';
import rank9 from './images/rank9.png';
import rank10 from './images/rank10.png';
import rank11 from './images/rank11.png';
import rank12 from './images/rank12.png';
import crown from './images/crown.png';
import crown2 from './images/crown2.png';
import crown3 from './images/crown3.png';

const { Util, JsBridge } = Library;
const rankSrc = [rank1, rank2, rank3, rank4, rank5, rank6, rank7, rank8, rank9, rank10, rank11, rank12];
const crownSrc = [crown, crown2, crown3];
let SwiperNc = null;
export const Home = (props) => {
    const history = useHistory();
    const { activityCode } = useParams();
    const [disable, setDisable] = useState(false);
    const [isNcLoaded, setIsNcLoaded] = useState(false);
    const [state, setParams] = useState({
        rankList: [],
        inviteCode: '',
        supportList: [],
        inviteClientPopValue: '',
        inviteClientPoint: '',
        startTime: '',
        endTime: '',
        inviteClientValue: '',
        activityTitle: '',
        tickets: 10
    });
    const [hasSupport, setHasSupport] = useState(false);
    const stateRef = useRef();
    const setState = (params) => {
        let mergeState = {};
        Object.assign(mergeState, state, params);
        setParams(mergeState);
        stateRef.current = mergeState;
    };

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === `/front/activities/mercedes/home/${activityCode}`) {
            getData();
        }
    }, [location])

    const initNC = async () => {
        import('components/txCaptcha').then(e => {
            SwiperNc = e.TxCaptcha;
            setIsNcLoaded(true);
        })
    };
    const getPic = (name) => {
        let p = Config?.[activityCode]?.[name] || '';
        return p;
    }
    const abs = (amount) => {
        if (amount >= 10000) {
            return (amount / 10000).toFixed(2) + '万';
        } else {
            return amount + '';
        }
    }

    const getData = async () => {
        const result = await Services('MainPage', { code: '101216' }, { activityCode })
        JsBridge.print(result);
        if (result.status > 0) {
            let { supportList = [], startTime, endTime } = result.data;
            let flag = false;
            for (let item of supportList) {
                if (item.status === 1) {
                    flag = true;
                    break;
                }
            }
            setHasSupport(flag);

            let now = new Date();
            let endDate = new Date(endTime.replace(/\./g, '/'));
            if (now.getTime() > endDate.getTime()) {
                setDisable(true);
            }

            setState(result.data);
        }
    }

    const support = async (supportedUserCode) => {
        if (isNcLoaded) {
            const res = await SwiperNc();
            if (res) {
                const result = await Services('MainPage', { code: '101218' }, { activityCode, supportedUserCode, ...res });
                JsBridge.print(result);
                if (result.status > 0) {
                    getData();
                }
            }
        }

    }

    useEffect(() => {
        document.body.style.background = '#12064F';
        initNC();
        // getData();
        return () => {
            document.body.style.background = 'transparent';
        };
    }, [])
    //邀请攻略
    const renderStrategy = () => {
        return <div className={styles.contentInivte}>
            <div className={styles.item}>
                <div className={styles.textBg}>
                    <p className={styles.text}>新用户助力</p>
                    <div className={styles.bar} />
                </div>
                <div className={styles.rowContent}>
                    <img className={styles.arrow} src={arrow} />
                    <div className={styles.div}>
                        <div className={styles.icon} />
                        <p className={styles.des}>好友注册登录
                            APP助力</p>
                    </div>
                    <div className={styles.div}>
                        <div className={styles.icon2} />
                        <p className={styles.des}>获得{state.inviteClientPopValue}人气值
                            和{state.inviteClientPoint}积分</p>
                    </div>
                </div>

            </div>

            <div className={styles.line} />
            <div className={styles.item}>
                <div className={styles.textBg}>
                    <p className={styles.text}>老用户助力</p>
                    <div className={styles.bar} />
                </div>
                <div className={styles.rowContent}>
                    <img className={styles.arrow} src={arrow} />
                    <div className={styles.div}>
                        <div className={styles.icon} />
                        <p className={styles.des}>好友登录APP
                            助力</p>
                    </div>
                    <div className={styles.div}>
                        <div className={styles.icon2} />
                        <p className={styles.des}>获得{state.inviteClientValue}人气值</p>
                    </div>
                </div>

            </div>
        </div>
    }


    return <div className={styles.container}>
        <img className={styles.bg} src={imageBg} mode='widthFix' />
        <div className={styles.content}>
            <img className={styles.pic} src={getPic('pic')} mode='widthFix' />
            <p className={styles.date}>活动时间：{state.startTime}-{state.endTime} </p>
            <div className={styles.titleBG}>
                <p className={styles.title}>邀请攻略</p>
            </div>
            {renderStrategy()}
            <div className={styles.titleBG}>
                <p className={styles.title}>人气排名情况</p>
            </div>
            <div className={styles.rankBg}>
                <div className={styles.borderBgContent}>
                    <div className={styles.borderBg_before} />
                    <div className={styles.borderBg_mid} />
                    <div className={styles.borderBg_after} />
                </div>
                <div className={styles.contentRank}>
                    <p className={styles.rankTitle}>{state.activityTitle}</p>
                    <p className={styles.rankText}>我的排名：{state.myInfoMap && state.myInfoMap.rank || ''}</p>
                    <div className={styles.contentWrap}>
                        {state.rankList.map((item, index) => {
                            let { amount, imagePath, loginName, rank } = item;
                            return <div className={styles.cell}>
                                <div className={styles.headBg}>
                                    <img className={styles.rankIcon} src={rankSrc[index]} />
                                    <img className={styles.imgPortrait} src={imagePath} />
                                    {index < 3 ? <img className={styles.crownIcon} src={crownSrc[index]} /> : null}
                                </div>
                                <p className={styles.phoneNum}>{loginName}</p>
                                <p className={styles.des}>{abs(amount)}人气值</p>
                            </div>
                        })}
                    </div>
                </div>
            </div>

            {state.supportList.length > 0 ? <div className={styles.titleBG}>
                <p className={styles.title}>助力好友</p>
                <p className={styles.des}>每人每天限制助力一次</p>
            </div> : null}

            {state.supportList.length > 0 ? <div className={styles.contentHelp}>
                {state.supportList.map((item, index) => {
                    let { amount, imagePath, loginName, status } = item;
                    let phone = loginName;
                    if (phone.length === 11) {
                        phone = loginName.replace(/(\d{3})(\d{4})/g, '$1****')
                    }
                    return <div className={styles.item}>
                        <img className={styles.img} src={imagePath} />
                        <div className={styles.textContent}>
                            <p className={styles.text1}>{phone}</p>
                            <p className={styles.text2}>人气值：{abs(amount)}</p>
                        </div>
                        <button className={`${styles.btnHelp} ${disable || hasSupport ? styles.disable : ''}`} onClick={() => {
                            if (disable || hasSupport) {
                                return;
                            }
                            support(loginName);
                        }}>{status === 1 ? '已助力' : '为他助力'}</button>
                    </div>
                })}
            </div> : null}
        </div>
        {state.myInfoMap ? <div className={styles.bottomBtn}>
            <div className={styles.bottomContent}>
                <img className={styles.headImg}
                    src={state.myInfoMap.imagePath} />
                <div className={styles.textContent} onClick={() => {
                    history.push(activityCode + '/modal');
                }}>
                    <p className={styles.text1}>我的排名：{state.myInfoMap.rank}</p>
                    <div className={styles.Popularity}>
                        <p className={styles.text2}>人气值：{abs(state.myInfoMap.amount)}</p>
                        <div className={styles.imgUp} />
                    </div>
                </div>
                <div className={styles.fill} />
                <button className={`${styles.btn} ${disable ? styles.disable : ''}`} onClick={() => {
                    if (disable) {
                        return;
                    }
                    history.push(activityCode + '/share', { clientId: state.clientId, inviteCode: state.inviteCode, activityCode, startTime: state.startTime, endTime: state.endTime, tickets: state.tickets });
                }}>邀好友助力
                </button>
            </div>
        </div> : null}
        <div className={styles.rule} onClick={() => {
            history.push(activityCode + '/rule');
        }}>
            <p className={styles.text}>活动规则</p>
        </div>
    </div>
};
