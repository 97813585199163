import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Store, History } from 'reduxs';
// import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';
import { RouteRender } from './routes';
import './scss/index.scss';
import Library from "library"
const { Util, Platform } = Library


function App() {
  const params = Util.url.paramsToObj()
  const { isUnity = '0', userInfo } = params;
  console.log('APP.js:', params)

  const [pageStatus, setPageStatus] = useState(false)

  useEffect(() => {
    if (isUnity === '1') {
      window.addEventListener('message', async (event) => {
        if (typeof event.data == 'string' && event.data == 'UnityFinshed') {
          if (event.data === 'UnityFinshed') {
            if (window.Unity) {
              console.log('window.Unity.message---', window.Unity.message)
              setPageStatus(true)
            }
          }
        }
      }, false);
    } else if (Platform.check.isMiniprogram()) {
      const fn = async () => {
        let _userInfo
        if (userInfo) {
          _userInfo = userInfo
        } else {
          // window.userInfo 通过mini-program的injectScript注入
          const res = await window.getIframeScript();
          if (res) {
            console.log('window.userInfo---', window.userInfo);
          }
          _userInfo = window.userInfo;
        }
        const user_info = typeof _userInfo == 'string' ? JSON.parse(_userInfo) : _userInfo;
        console.log('isMiniprogram:', user_info);
        if (user_info) {
          localStorage.setItem('user_info', JSON.stringify(user_info));
        }
        setPageStatus(true);
      }
      fn();
    }
  }, [])

  if (!pageStatus && (isUnity === '1' || Platform.check.isMiniprogram())) return null

  return <Provider store={Store}>
    <Router history={History}>
      <RouteRender />
    </Router>
  </Provider>;
}

export default App;
